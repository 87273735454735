import { COMMON_LINE_STROKE_WIDTH } from '@/shared/constants';

export interface DefaultHandleProps {
  x?: number;
  y?: number;
  scale: number;
  outsideView?: boolean;
}

export const getDashArray = (scale: number, outside: boolean) =>
  outside ? [Math.min(5, 2 / scale), 2] : undefined;

export const getStrokeWidth = (scale: number) =>
  Math.min(5, COMMON_LINE_STROKE_WIDTH / scale);

export const OPERABLE_WINDOW_HANDLE_COLOR = '#8D8B8F';
