import React from 'react';
import {
  HeaderPanelItemProps,
  NodeType,
  UserBuildingCoordinatesObject,
} from '@/models';
import BrowserPanelItem from '../BrowserPanelItem';
import FloorsPanelItem from './FloorsPanelItem';
import { IntusCollapse } from '@/shared/elements';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';

interface BlocksPanelItem extends HeaderPanelItemProps {
  building: UserBuildingCoordinatesObject;
}

export const BlocksPanelItem = ({
  isParentSelected,
  isParentLocked,
  isParentHidden,
  building,
  keys,
  onCollapseChange,
  depthLevel,
}: BlocksPanelItem) => {
  const userBuildingUtils = useUpdateUserBuildingData();
  const handleRenameBlock = (blockGUID: string, name: string) => {
    userBuildingUtils.renameUserBuildingBlock({
      buildingGUID: building.guid,
      blockGUID,
      name,
    });
  };
  const { isIsolateModeEnabled } = useIsolationHandlers();
  const handleShowHide = (blockGUID: string, previousValue: boolean) => {
    userBuildingUtils.updateUserBuildingBlockUserData({
      buildingGUID: building.guid,
      blockGUID,
      key: 'isHidden',
      value: !previousValue,
    });
  };
  const handleLockUnlock = (blockGUID: string, previousValue: boolean) => {
    userBuildingUtils.updateUserBuildingBlockUserData({
      buildingGUID: building.guid,
      blockGUID,
      key: 'isLocked',
      value: !previousValue,
    });
  };

  const handleDelete = (guid: string) => {
    userBuildingUtils.deleteSelectedConstructions([
      {
        guid,
        nodeType: NodeType.Block,
      },
    ]);
  };

  const getBlocks = () =>
    building.blocks.map((block) => {
      return {
        key: block.guid,
        label: (
          <BrowserPanelItem
            text={block.name}
            elementGUID={block.guid}
            nodeType={NodeType.Block}
            isParentSelected={isParentSelected}
            isHidden={!!block.userData?.isHidden}
            isLocked={!!block.userData?.isLocked}
            isParentLocked={isParentLocked}
            isParentHidden={isParentHidden}
            deleteAction={() => handleDelete(block.guid)}
            showHideAction={
              !isIsolateModeEnabled
                ? () => {
                    handleShowHide(block.guid, !!block.userData?.isHidden);
                  }
                : undefined
            }
            lockUnlockAction={() =>
              handleLockUnlock(block.guid, !!block.userData?.isLocked)
            }
            renameAction={(name) => handleRenameBlock(block.guid, name)}
            depthLevel={depthLevel}
          />
        ),
        children: (
          <FloorsPanelItem
            onCollapseChange={onCollapseChange}
            keys={keys}
            block={block}
            depthLevel={depthLevel + 1}
            building={building}
            isParentSelected={isParentSelected}
            parentBlockGUID={block.guid}
            isParentLocked={isParentLocked || !!block.userData?.isLocked}
            isParentHidden={isParentHidden || !!block.userData?.isHidden}
          />
        ),
      };
    });

  return (
    <IntusCollapse
      items={getBlocks()}
      collapsible={'icon'}
      onChange={onCollapseChange}
      activeKey={keys}
      destroyInactivePanel={true}
    />
  );
};
