import React from 'react';
import Spin from 'antd/es/spin';
import { SpinProps } from 'antd/es/spin';
import './AntdSpin.scss';

const IntusSpin: React.FC<SpinProps> = ({ ...restProps }) => {
  return <Spin rootClassName={'intus-spin'} {...restProps} />;
};

export default IntusSpin;
