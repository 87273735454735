import React, { useEffect, useMemo, useState } from 'react';
import { Line3, Vector3 } from 'three';
import { ThreeEvent } from '@react-three/fiber';

import {
  DistanceInput,
  FlatVector3,
  UserBuildingBlock,
  UserBuildingSurface,
} from '@/models';
import {
  convertFlatVector3ToVector,
  MIN_WALL_DISTANCE,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import CutSurface from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutSurface';

import {
  getProcessingEntity,
  setDirectionalInputValues,
} from '@/store/slices/canvasExternalElementsSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  findFacadePointsHelper,
  generateTopBorderArray,
  generateWallArrayFromBlock,
  getExtendedMouseVector,
} from '@/routes/dashboard/projects/project/UserBuilding/helpers/editing-tools.helpers';
import useMouseOutsideMesh from '@/shared/hooks/useMouseOutsideMesh';
import { useSplitCutDirectionalInput } from '@/routes/dashboard/projects/project/UserBuilding/helpers/useSplitCutDirectionalInput';
import { getIsCameraRotating } from '@/store/slices/canvasCamerasSlice';
import { disposeNode } from '@/shared/helpers/canvas';
import { ExtendedMinMaxCoordinatesPairs } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { useParams } from 'react-router';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import SplitFaces from '@/routes/dashboard/projects/project/UserBuilding/components/SplitTool/SplitFaces';

interface CutToolProps {
  block: UserBuildingBlock;
  buildingGUID: string;
}

const CutTool: React.FC<CutToolProps> = ({ block, buildingGUID }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();

  const processingEntity = useAppSelector(getProcessingEntity);
  const isCameraRotating = useAppSelector(getIsCameraRotating);
  const multiplyRate = useAppSelector(getMultiplyRate(id!));

  const [mousePosition, setMousePosition] = useState<Vector3 | null>(null);
  const [currentWall, setCurrentWall] = useState<UserBuildingSurface | null>(
    null
  );
  const [cutFaceWallPoints, setCutFaceWallPoints] = useState<FlatVector3[]>();
  const [isAllowedToCut, setIsAllowedToCut] = useState(false);
  const [isMouseOnWall, setIsMouseOnWall] = useState(false);
  const [minMax, setMinMax] = useState<ExtendedMinMaxCoordinatesPairs>();

  const wallArray = useMemo(() => {
    return generateWallArrayFromBlock(block);
  }, [block]);

  const handleMove = (position: Vector3, wall?: UserBuildingSurface) => {
    if (processingEntity?.active || isCameraRotating) return;
    !isAllowedToCut && setIsAllowedToCut(true);

    if (wall) {
      const pos = position.clone();
      pos.setY(0);
      const left = convertFlatVector3ToVector(wall.points[1]);
      left.setY(0);
      const right = convertFlatVector3ToVector(wall.points[0]);
      right.setY(0);

      if (
        pos.distanceTo(left) < MIN_WALL_DISTANCE ||
        pos.distanceTo(right) < MIN_WALL_DISTANCE
      ) {
        return;
      }

      const extendedVector = getExtendedMouseVector(
        wall.points,
        position.clone().setY(left.y),
        multiplyRate
      );

      position.copy(extendedVector);
    }

    wall && setCurrentWall(wall);
    wall && findFacadeWalls(wall);
    setMousePosition(position);
  };

  const findFacadeWalls = (wall: UserBuildingSurface) => {
    if (!mousePosition) return;
    const facadeData = findFacadePointsHelper(mousePosition, block, wall);

    if (!facadeData) return;

    const { points, minMax, farthestRightWall, farthestLeftWall } = facadeData;

    setMinMax({
      max: {
        ...minMax.max,
        y: block.storeys[block.storeys.length - 1].ceiling.points[0][1],
      },
      min: {
        ...minMax.min,
        y: block.storeys[0].floor.points[0][1],
      },
    });
    setCutFaceWallPoints(points);
    const firstPoint = convertFlatVector3ToVector(farthestLeftWall);
    const secondPoint = convertFlatVector3ToVector(farthestRightWall);
    const maxDistance = firstPoint.distanceTo(secondPoint);

    const maxDistanceInMillimeters = (
      Number(convertMetersToMillimeters(maxDistance / multiplyRate)) - 1
    ).toFixed(0);
    //Max distance shouldn't be equal to facade width

    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          min: 1,
          max: Number(maxDistanceInMillimeters),
          validationMessage: 'Invalid value',
        },
        {
          type:
            processingEntity?.type === DistanceInput.LeftEdgeDistance
              ? DistanceInput.RightEdgeDistance
              : DistanceInput.LeftEdgeDistance,
          min: 1,
          max: Number(maxDistanceInMillimeters),
          validationMessage: 'Invalid value',
        },
      ])
    );
  };

  const handleTopBorderArrayPointerMove = (
    wall: UserBuildingSurface,
    mouse: Vector3
  ) => {
    if (processingEntity?.active) return;
    const start = convertFlatVector3ToVector(wall.points[0]);
    const end = convertFlatVector3ToVector(wall.points[1]);

    const closestPoint = new Vector3();
    new Line3(start, end).closestPointToPoint(mouse, true, closestPoint);
    handleMove(closestPoint, wall);
  };

  const borderEdgeArray = useMemo(
    () => generateTopBorderArray(block, isMouseOnWall),
    [block, isMouseOnWall]
  );

  useEffect(() => {
    return () => {
      borderEdgeArray.forEach((edge) => disposeNode(edge.border));
    };
  }, [block, isMouseOnWall]);

  const borderArray = useMemo(() => {
    return borderEdgeArray.map((edge) => (
      <primitive
        object={edge.border}
        onPointerMove={(e: ThreeEvent<PointerEvent>) => {
          e.stopPropagation();
          edge.wall && handleTopBorderArrayPointerMove(edge.wall, e.point);
        }}
        onPointerEnter={() => setIsAllowedToCut(true)}
        key={edge.border.id}
        position={[0, 0.001, 0]}
        visible={false}
      />
    ));
  }, [block, processingEntity, isCameraRotating]);

  useMouseOutsideMesh({
    meshes: [...wallArray, ...borderEdgeArray.map((a) => a.border)],
    onMouseNotIntersectMeshes: () =>
      !isCameraRotating && setIsAllowedToCut(false),
  });

  useMouseOutsideMesh({
    meshes: [...wallArray],
    onMouseNotIntersectMeshes: () =>
      !isCameraRotating && setIsMouseOnWall(false),
    onMouseIntersectMeshes: () => !isCameraRotating && setIsMouseOnWall(true),
  });

  useSplitCutDirectionalInput({
    facePoints: cutFaceWallPoints,
    mousePosition,
    isAllowed: isAllowedToCut,
    setMousePosition,
  });

  useEffect(() => {
    return () => {
      borderEdgeArray.forEach((edge) => disposeNode(edge.border));
      wallArray.forEach((wall) => disposeNode(wall));
    };
  }, [borderEdgeArray, wallArray]);

  return (
    <group>
      {borderArray.map((edge) => edge)}
      {wallArray.map((edge) => (
        <primitive
          object={edge}
          onPointerMove={(event: ThreeEvent<PointerEvent>) => {
            event.stopPropagation();
            const position = event.pointOnLine ?? event.point;
            handleMove(position, event.object.userData.wallData);
          }}
          key={edge.uuid}
          onPointerEnter={() => setIsAllowedToCut(true)}
          visible={false}
        />
      ))}
      {isAllowedToCut && mousePosition && minMax && cutFaceWallPoints && (
        <>
          <CutSurface
            block={block}
            mousePosition={mousePosition}
            currentWall={currentWall}
            buildingGUID={buildingGUID}
            isAllowedToCut={isAllowedToCut}
            isCameraRotating={isCameraRotating}
            cutFaceWallPoints={cutFaceWallPoints}
          />
          <SplitFaces
            mousePosition={mousePosition}
            minMaxCoordinates={minMax}
            wallPoints={cutFaceWallPoints}
            isBuildingClockwise={false}
          />
        </>
      )}
    </group>
  );
};

export default CutTool;
