import React from 'react';

import { Trap } from '@/components/SvgDesigner/shared';

import {
  CustomOperableWindow,
  LineColor,
} from '@/components/SvgDesigner/models';
import { WINDOW_GLASS_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { LINE_WIDTH } from '../../constants';

const SvgFixedWindow: React.FC<CustomOperableWindow> = ({ points }) => (
  <Trap
    points={points}
    stroke={LineColor.DEFAULT}
    fill={{ hex: WINDOW_GLASS_COLOR, hasGlare: false }}
    strokeWidth={LINE_WIDTH}
  />
);

export default SvgFixedWindow;
