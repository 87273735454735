import React, { useEffect, useState } from 'react';
import { Canvas } from '@react-three/fiber';
import './select-box.scss';
import * as THREE from 'three';

import ProjectCanvas from '@/routes/dashboard/projects/project/ProjectCanvas';
import { CANVAS_PROJECT_ZOOM } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { CanvasActionsModes, ProjectStructure } from '@/models';

import { IntusLoader } from '@/shared/elements';
import { useLoadMap } from '@/shared/hooks/useLoadMap';
import CanvasExternalElements from '@/routes/dashboard/projects/project/CanvasExternalElements/CanvasExternalElements';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import RotateCursor from '@/images/RotateCursor.svg';
import ResizeCursor from '@/images/ResizeCursor.svg';
import { useAppSelector } from '@/store/hooks';
import { getCanvasMode } from '@/store/slices/canvasModesSlice';
import { subscribe, unsubscribe } from '@/core/events';
import { MAKE_SCREENSHOT } from '@/core/event-names';
import { makeScreenshotDelay } from '@/shared/helpers/camera';
import { sleep } from '@/shared/helpers/sleep';
import { getExtrudeData } from '@/store/slices/canvasBuildingSlice';
import { isCopyingProperties } from '@/store/slices/copyPropertiesSlice';

const ProjectScene = ({ project }: { project: ProjectStructure }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const currentCanvasMode = useAppSelector(getCanvasMode);
  const isExtrudeMode = !!useAppSelector(getExtrudeData);
  const isCopyingProcessing = useAppSelector(isCopyingProperties);
  const { map, isFetching } = useLoadMap(project.id);

  const raycaster = new THREE.Raycaster();
  raycaster.params.Line = { threshold: 0.01 };

  const getCursorStyle = () => {
    if (isExtrudeMode) {
      return `url('${ResizeCursor}'), auto`;
    }

    if (isCopyingProcessing) {
      return 'crosshair';
    }

    switch (currentCanvasMode) {
      case CanvasActionsModes.move:
        return 'grab';
      case CanvasActionsModes.rotate:
        return `url('${RotateCursor}'), auto`;
      default:
        return 'unset';
    }
  };

  useEffect(() => {
    subscribe(MAKE_SCREENSHOT, toggleAnimation);
    return () => {
      unsubscribe(MAKE_SCREENSHOT, toggleAnimation);
    };
  }, []);

  const toggleAnimation = async () => {
    setIsAnimating(true);
    await sleep(makeScreenshotDelay);
    setIsAnimating(false);
  };

  return (
    <div
      className="h-full relative gradient"
      style={{
        background:
          'linear-gradient(316.51deg, rgba(251, 251, 251, 0.6) 48.78%, rgba(232, 232, 232, 0.6) 100%), ' +
          'linear-gradient(314.92deg, rgba(118, 118, 118, 0.6) 0%, rgba(248, 248, 248, 0.6) 50.07%), ' +
          'linear-gradient(0deg, #FFFFFF, #FFFFFF)',
      }}
    >
      {isFetching ? (
        <IntusLoader />
      ) : (
        <>
          <Canvas
            shadows
            raycaster={raycaster}
            orthographic
            camera={{ zoom: CANVAS_PROJECT_ZOOM, position: [20, 20, 20] }}
            id={PROJECT_CANVAS_ID}
            style={{
              cursor: getCursorStyle(),
            }}
          >
            <ProjectCanvas map={map} project={project} />
          </Canvas>
          <CanvasExternalElements />
        </>
      )}
      {isAnimating && (
        <div className="absolute inset-0 bg-white z-50 animate-fadeInOut" />
      )}
    </div>
  );
};
export default ProjectScene;
