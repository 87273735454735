import { useEffect, useState } from 'react';

export const useHighlightedStatus = (createdAt: string) => {
  const [isHighlighted, setIsHighlighted] = useState(false);
  const [shouldCheckHighlightStatus, setShouldCheckHighlightStatus] =
    useState(true);

  const disableHighlightStatus = () => {
    setIsHighlighted(false);
    setShouldCheckHighlightStatus(false);
  };

  useEffect(() => {
    if (!shouldCheckHighlightStatus) return;
    const checkHighlightStatus = () => {
      const timeDiff =
        (new Date().getTime() - new Date(createdAt).getTime()) / 1000;

      if (timeDiff <= 30) {
        setIsHighlighted(true);
      } else {
        setIsHighlighted(false);
      }
    };

    checkHighlightStatus();
    const interval = setInterval(checkHighlightStatus, 1000);

    return () => clearInterval(interval);
  }, [createdAt, shouldCheckHighlightStatus]);

  useEffect(() => {
    isHighlighted &&
      document.addEventListener('mousedown', disableHighlightStatus);

    return () => {
      isHighlighted &&
        document.removeEventListener('mousedown', disableHighlightStatus);
    };
  }, [isHighlighted]);

  return { isHighlighted };
};
