import React, { useEffect, useState, useRef } from 'react';
import { createPortal } from 'react-dom';
import { useParams } from 'react-router';
import { uuidv7 } from 'uuidv7';
import { Color } from 'three';
import axios from 'axios';
import jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
import 'svg2pdf.js';

import QuoteWrapper from './Pages/QuoteWrapper';
import QuoteBuilding from './Pages/QuoteBuilding/QuoteBuilding';
import { SvgFacade } from '../SvgDesigner/components';
import QuoteFacade from './Pages/QuoteFacades/QuoteFacade';
import SvgCanvas from '../SvgDesigner/SvgCanvas';

import { publish, subscribe, unsubscribe } from '@/core/events';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import {
  setQuoteRequestStatus,
  setQuoteRequestErrorMessage,
} from '@/store/slices/quoteSlice';
import { useSendDataForQuoteMutation } from '@/store/apis/blueScreenApi';
import { useAppDispatch } from '@/store/hooks';
import { useAIGeneration } from '@/shared/hooks/useAIGeneration';
import { useSvgData } from '../SvgDesigner/hooks/useSvgData';
import { blobToBase64 } from '@/shared/helpers/format-data';

import { QuoteRequestData, QuoteStatus } from '@/models/quote.model';
import { Side } from '@/models/camera.model';
import {
  COVER_HEIGHT,
  COVER_WIDTH,
  REPORT_BACKGROUND_COLOR,
} from '@/shared/constants';
import {
  GENERATE_CAMERA_SNAPSHOTS,
  GENERATE_CAMERA_SNAPSHOTS_SUCCESS,
} from '@/core/event-names';
import { PDF_HEIGHT, PDF_WIDTH } from '@/shared/constants';

interface QuoteProps {
  setPercent: (value: number) => void;
}

export const Quote: React.FC<QuoteProps> = ({ setPercent }) => {
  const { id } = useParams();
  const dispatch = useAppDispatch();

  const projectData = useFetchProjectQuery(id!).data!;
  const [sendDataForQuote] = useSendDataForQuoteMutation();

  const { getFacadesData, getUnitsData } = useSvgData();
  const facadesData = getFacadesData({
    useAllUnits: false,
    isNumbering: false,
    isUnitNumbering: true,
  });
  const unitsData = getUnitsData({
    isNumbering: true,
    isUnitNumbering: false,
  });

  const { getProcessData, checkProgress } = useAIGeneration();

  const [buildingSnapshots, setBuildingSnapshots] = useState<string[]>([]);
  const [unitsSvgSnapshots, setUnitsSvgSnapshots] = useState<{
    [panelId: string]: string;
  }>();
  const [isSnapshotsReady, setIsSnapshotsReady] = useState(false);

  const reportRef = useRef<HTMLDivElement>(null!);
  const svgFacadesRef = useRef<HTMLDivElement>(null!);
  const svgUnitsRef = useRef<HTMLDivElement>(null!);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const generateAiImages = async () => {
    const taskIds = await getProcessData(
      [
        {
          side: Side.AI,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
      ],
      [
        'public building, city centers, squares, urban landscape, cars, people, streets, reality, day time, summer',
        'public building, city centers, squares, urban landscape, cars, people, streets, reality, night, summer',
        'public building, city centers, squares, urban landscape, cars, people, streets, reality, day time, winter',
        'public building, city centers, squares, urban landscape, cars, people, streets, reality, night, winter',
      ]
    ).then((data) =>
      data.map((process) => {
        if (!process.success) throw new Error(process.error_message);

        return process.data.task_id;
      })
    );

    if (!taskIds.length) throw new Error('No ID generated for AI cover');

    const images = await Promise.all(
      taskIds.map(async (taskId) => await checkProgress(taskId))
    );

    return await Promise.all(
      images.map((imageUrl) =>
        fetch(imageUrl)
          .then((res) => res.blob())
          .then(blobToBase64)
      )
    );
  };

  const createReport = async () => {
    const offsetX = 10;
    const offsetY = 30;

    const doc = new jsPDF({
      format: [PDF_WIDTH, PDF_HEIGHT],
      unit: 'px',
      orientation: 'landscape',
      compress: true,
    });

    const imgWidth = doc.internal.pageSize.getWidth();
    const imgHeight = doc.internal.pageSize.getHeight();
    let facadeWrapper: string = '';

    const children = Array.from(reportRef.current.children).slice(
      1
    ) as HTMLDivElement[];

    let i = 0;
    for (const child of children) {
      const canvas = await html2canvas(child, {
        useCORS: true,
        scale: 5,
        ignoreElements: (element) => {
          return !(
            element.contains(child) ||
            child.contains(element) ||
            child === element ||
            element.nodeName == 'HEAD' ||
            element.nodeName == 'STYLE' ||
            element.nodeName == 'META' ||
            element.nodeName == 'LINK'
          );
        },
      });

      const imgData = canvas.toDataURL('image/png', 1);

      if (i === children.length - 1) {
        facadeWrapper = imgData;
        continue;
      }

      doc.addImage(imgData, 'PNG', 0, 0, imgWidth, imgHeight);
      doc.addPage();
      i = i + 1;
    }

    const facades = svgFacadesRef.current.children;

    for (let i = 0; i < facades.length; i++) {
      doc.addImage(facadeWrapper, 'PNG', 0, 0, imgWidth, imgHeight);
      doc.text(`Facade ${i + 1}`, offsetX, PDF_HEIGHT - 40);

      await doc.svg(facades[i], {
        x: offsetX,
        y: offsetY,
        width: PDF_WIDTH - offsetX * 2,
        height: PDF_HEIGHT - 110.01,
      });

      if (i !== facades.length - 1) doc.addPage();
    }

    return doc.output('blob');
  };

  const makeSnapshots = async () => {
    setPercent(10);
    if (!svgUnitsRef.current) return;

    const uniqUnitsSnapshots: { [unitId: string]: string } = {};
    const svgUnits = svgUnitsRef.current.children;

    for (let i = 0; i < svgUnits.length; i++) {
      const id = svgUnits[i].getAttribute('id') ?? i;
      const svgString = new XMLSerializer().serializeToString(svgUnits[i]);
      const utf8Encoder = new TextEncoder();
      const encodedData = `data:image/svg+xml;base64,${btoa(
        String.fromCharCode(...utf8Encoder.encode(svgString))
      )}`;
      uniqUnitsSnapshots[id] = encodedData;
    }
    setUnitsSvgSnapshots(uniqUnitsSnapshots);

    // const generatedAiImages = (await generateAiImages()) as string[];
    // const cover = await cropCover(generatedAiImages[0]);

    setPercent(15 + facadesData.length * 3 + 5);
    setIsSnapshotsReady(true);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const cropCover = async (img: string): Promise<string> => {
    return new Promise((resolve, reject) => {
      const imgElement = document.createElement('img');
      imgElement.src = img;

      imgElement.onload = () => {
        const targetWidth = COVER_WIDTH;
        const targetHeight = COVER_HEIGHT;

        const imgWidth = imgElement.width;
        const imgHeight = imgElement.height;

        const scale = Math.max(
          targetWidth / imgWidth,
          targetHeight / imgHeight
        );

        const scaledWidth = imgWidth * scale;
        const scaledHeight = imgHeight * scale;

        const offsetX = (targetWidth - scaledWidth) / 2;
        const offsetY = (targetHeight - scaledHeight) / 2;

        const canvas = document.createElement('canvas');
        canvas.width = targetWidth;
        canvas.height = targetHeight;

        const ctx = canvas.getContext('2d')!;
        ctx.fillStyle = REPORT_BACKGROUND_COLOR;
        ctx.fillRect(0, 0, targetWidth, targetHeight);

        ctx.drawImage(imgElement, offsetX, offsetY, scaledWidth, scaledHeight);

        const imageURL = canvas.toDataURL('image/png', 1);

        canvas.remove();
        imgElement.remove();

        resolve(imageURL);
      };

      imgElement.onerror = () => {
        reject(new Error('Error when cropping the image'));
      };
    });
  };

  useEffect(() => {
    const processId = uuidv7();

    const handleSaveSnapshots = ({
      detail: { urls, processId: eventProcessId },
    }: {
      detail: { urls: string[]; processId: string };
    }) => {
      if (eventProcessId === processId) {
        setBuildingSnapshots([...urls]);
      }
    };

    subscribe(GENERATE_CAMERA_SNAPSHOTS_SUCCESS, handleSaveSnapshots);

    publish(GENERATE_CAMERA_SNAPSHOTS, {
      processId,
      filters: [
        {
          side: Side.FRONT,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
        {
          side: Side.LEFT,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
        {
          side: Side.RIGHT,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
        {
          side: Side.BACK,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
      ],
    });

    return () => {
      unsubscribe(GENERATE_CAMERA_SNAPSHOTS_SUCCESS, handleSaveSnapshots);
    };
  }, []);

  const generateQuote = async () => {
    try {
      const takeoff = (await createReport().then((blob) =>
        blobToBase64(blob)
      )) as string;

      setPercent(98);

      const data: QuoteRequestData = {
        panelImages: unitsSvgSnapshots!,
        buildingImages: buildingSnapshots,
        coverImage: null,
        takeoff,
      };

      const draftId = projectData.draftId!.toString();

      await sendDataForQuote({ draftId, data }).unwrap();
      dispatch(setQuoteRequestStatus(QuoteStatus.READY));
    } catch (error) {
      const errorMessage =
        (axios.isAxiosError(error) &&
          (error.response?.data?.error_message || error.message)) ||
        (error instanceof Error && error.message) ||
        (error as { error?: string }).error;

      dispatch(setQuoteRequestErrorMessage(errorMessage));
      dispatch(setQuoteRequestStatus(QuoteStatus.ERROR));
    }
  };

  useEffect(() => {
    if (buildingSnapshots.length) {
      makeSnapshots();
    }
  }, [buildingSnapshots]);

  useEffect(() => {
    isSnapshotsReady && unitsSvgSnapshots && generateQuote();
  }, [isSnapshotsReady, unitsSvgSnapshots]);

  return createPortal(
    <div
      className={`left-0 right-0 top-0 mr-auto ml-auto w-fit z-[-9999] fixed bg-light-gray-20`}
      ref={reportRef}
    >
      <div className="absolute h-full w-full -z-10  hidden">
        <div ref={svgFacadesRef}>
          {facadesData.map((facade, index) => (
            <SvgFacade key={index} {...facade} />
          ))}
        </div>
        <div ref={svgUnitsRef}>
          {unitsData.map((element, index) => (
            <SvgCanvas key={index} elementData={element} />
          ))}
        </div>
      </div>
      <QuoteWrapper>
        <QuoteBuilding snapshots={buildingSnapshots} />
      </QuoteWrapper>
      <QuoteWrapper>
        <QuoteFacade />
      </QuoteWrapper>
    </div>,
    document.body
  );
};
