import { FlatVector2 } from '@/models/canvas.model';
import { FlatVector2Axis } from '@/components/WindowCreator/models/konva-model';
import { FillerType } from '@/components/UTC/models';

export interface WindowColor {
  name: RALColors;
  description: string;
  hex: string;
}

export interface OperationTypeDescription {
  name: OperationType;
  description: string;
  swing: string;
  side: string;
}

export interface PanelMaterial {
  name: string;
  description: string;
  additionalInfo: string;
}

export interface FillerTypeInfo {
  name: FillerType;
  description: string;
  additionalInfo: string;
}

export interface PanelConfig {
  sideWidth: number;
  topWidth: number;
  bottomWidth: number;
  horizontalOffset: number;
  verticalOffset: number;
  operableFrame: {
    sashWidth: number;
    windowFrameWidth: number;
  };
  glass: {
    insertedFixed: number;
    insertedOperable: number;
  };
  mullion: {
    width: number;
  };
}

export interface BaseUnit {
  cornerName: string;
  cornerWidth: number;
  fillerType: FillerType;
  height: number;
  insideColor: RALColors;
  name: string;
  operationWindowType: OperationType;
  outsideColor: RALColors;
  width: number;
  iguId: number;
}

export interface WindowConfigurator {
  colors: WindowColor[];
  glazing: {
    name: string;
    description: string;
  }[];
  operationTypes: OperationTypeDescription[];
  operableWindowTypes: OperationTypeDescription[];
  panelMaterials: PanelMaterial[];
  fillerTypes: FillerTypeInfo[];
  baseUnit: BaseUnit;
  baseWindow: {
    name: string;
    distanceToFloor: number;
    glazing: string;
    height: number;
    innerColor: string;
    operationType: OperationType;
    outerColor: string;
    width: number;
  };
  panel: PanelConfig;
  limitations: {
    outerFrame: {
      minWidth: number;
      maxWidth: number;
      minHeight: number;
      maxHeight: number;
    };
    operableFrame: {
      minWidth: number;
      maxWidth: number;
      minHeight: number;
      maxHeight: number;
    };
    temperedGlassUnit: {
      bottomHeight: number;
      topHeight: number;
      area: number;
    };
    temperedGlassWindow: {
      bottomHeight: number;
      topHeight: number;
      area: number;
    };
    iguThickness: {
      max: number;
      min: number;
    };
  };
}
export enum OperationType {
  Fixed = 'Fixed',
  DualActionLeftTop = 'DualActionLeftTop',
  DualActionRightTop = 'DualActionRightTop',
  CasementLeft = 'CasementLeft',
  CasementRight = 'CasementRight',
  Hopper = 'Hopper',
  Awning = 'Awning',
}

export interface MullionData {
  points: FlatVector2Axis;
}
export interface InnerWindowData {
  points: FlatVector2[];
  operationType: OperationType;
  iguId: number | null;
  iguArea?: number | null;
}

export interface WindowFrame {
  name: string;
  points: FlatVector2[];
  innerColor: string;
  outerColor: string;
  distanceToFloor: number;
  image: string;

  innerFrames: InnerWindowData[];
  mullions: MullionData[];
}

export enum PanelMaterials {
  Aluminium = 'Aluminium',
  Glass = 'Glass',
  EnamelGlass = 'EnamelGlass',
}

export enum RALColors {
  RAL9016 = 'RAL9016',
  RAL9006 = 'RAL9006',
  RAL7039 = 'RAL7039',
  RAL9004 = 'RAL9004',
  RAL9005 = 'RAL9005',
  RAL7016 = 'RAL7016',
  MultipleColors = 'multipleColors',
}
