import React, { useEffect, useMemo, useState } from 'react';
import {
  convertBufferGeometryTo3DVectorList,
  createLine2,
  getCenterFromFlatVectorsArray,
  getPerpendicularVectorToVectors,
  getXYZ,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { ExtrudeHandlerData } from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import { BufferGeometry } from 'three';
import { C_FatLineBorderMaterial, C_FloorMaterial } from '@/shared/materials';
import ExtrudeWall, {
  UpdatedWallsCallbackData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeWall';
import {
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
  getEdgePoints,
  updateGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import * as THREE from 'three';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { isEqual } from 'lodash';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import {
  CanvasActionsModes,
  DistanceInput,
  DrawModes,
  FlatVector3,
  UserBuildingWall,
} from '@/models';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { isLeftClick } from '@/shared/helpers';
import {
  compareNumbersWithPrecision,
  getAlphabetIndex,
} from '@/shared/helpers/format-data';
import { uuidv7 } from 'uuidv7';
import { setMode } from '@/store/slices/canvasModesSlice';
import { resetExtrudeData } from '@/store/slices/canvasBuildingSlice';
import {
  resetExternalElementsState,
  setDirectionalInputValues,
} from '@/store/slices/canvasExternalElementsSlice';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useAppDispatch } from '@/store/hooks';

interface ExtrudeStoreyProps {
  storeyGuid: string;
  buildingGuid: string;
  blockGuid: string;
  extrudeData: ExtrudeHandlerData;
  facadeWallsGuids: string[];
  wallsAffectedByExtrude: Set<string>;
  cursorPosition?: THREE.Vector3;
  maxNegativeDistance?: number;
  setMaxNegativeDistance?: (value: number) => void;
  maxPositiveDistance?: number;
  setMaxPositiveDistance?: (value: number) => void;
  allowSave: boolean;
  handleExtrudeHandlerPosition?: (distance: number) => void;
}

const PRECISION_FOR_WALL_COMPARING = 1e-3;

const ExtrudeStorey: React.FC<ExtrudeStoreyProps> = ({
  extrudeData,
  storeyGuid,
  facadeWallsGuids,
  buildingGuid,
  blockGuid,
  wallsAffectedByExtrude,
  maxNegativeDistance,
  maxPositiveDistance,
  setMaxNegativeDistance,
  setMaxPositiveDistance,
  cursorPosition,
  handleExtrudeHandlerPosition,
  allowSave,
}) => {
  const dispatch = useAppDispatch();

  const [floorShape, setFloorShape] = useState<BufferGeometry>();
  const [contour, setContour] = useState<Line2>();

  const { findDataForStorey } = useFindNodeData();
  const { updateUserBuildingStoreyData, updateUserBuildingDrawMode } =
    useUpdateUserBuildingData();

  const storey = useMemo(() => findDataForStorey(storeyGuid)!, [storeyGuid]);

  const changedWallsData = useMemo(
    (): {
      [wallGUID: string]: UpdatedWallsCallbackData;
    } => ({}),
    []
  );

  const floorPoints = useMemo(() => {
    const edges = getEdgePoints(storey);
    return storey.floor.points.flatMap((point, i) => {
      return edges.every((edgePoint) => edgePoint !== point) &&
        i !== storey.floor.points.length - 1
        ? [point, point]
        : [point];
    });
  }, [storey]);

  const updateFloorShape = (
    [p1, p2]: [THREE.Vector3, THREE.Vector3],
    wall: UserBuildingWall
  ) => {
    const index = floorPoints.findIndex((floorPoint) =>
      isEqual(floorPoint, wall?.points[0])
    );
    if (index === -1) return;

    const incrementValue = isEqual(floorPoints[index + 1], wall?.points[0])
      ? 1
      : 0;

    if (floorShape) {
      const position = convertBufferGeometryTo3DVectorList(
        floorShape,
        floorShape.getAttribute('position').count
      );
      position[index + incrementValue] = p2;
      position[index + incrementValue + 1] = p1;
      if (index === 0) {
        position[position.length - 1] = p2;
      }
      if (index + incrementValue + 1 === position.length - 1) {
        position[0] = p1;
      }
      updateGeometryFromVectorList(floorShape, position, 'horizontal');
      contour &&
        updateLine2Position(contour, position.map((p) => getXYZ(p)).flat());
    }
  };
  const validateWall = (points: FlatVector3[]) => {
    const updatedWallsData = Object.values(changedWallsData).flat();
    const data = updatedWallsData.find((data) => {
      return (
        compareNumbersWithPrecision(
          data.points[0].x,
          points[0][0],
          PRECISION_FOR_WALL_COMPARING
        ) &&
        compareNumbersWithPrecision(
          data.points[0].z,
          points[0][2],
          PRECISION_FOR_WALL_COMPARING
        ) &&
        compareNumbersWithPrecision(
          data.points[1].x,
          points[1][0],
          PRECISION_FOR_WALL_COMPARING
        ) &&
        compareNumbersWithPrecision(
          data.points[1].z,
          points[1][2],
          PRECISION_FOR_WALL_COMPARING
        ) &&
        !data.isFlat
      );
    });
    return storey.walls.find((wall) => wall.guid === data?.wallGUID);
  };

  const finishExtruding = (event?: PointerEvent) => {
    event?.stopPropagation();
    if (event && !isLeftClick(event)) return;
    if (!floorShape) return;

    const newFloorPoints: THREE.Vector3[] = convertBufferGeometryTo3DVectorList(
      floorShape,
      floorShape.getAttribute('position').count
    )
      .map((point) => point.setY(storey.floor.points[0][1]))
      .filter(
        (point, i, arr) => i === 0 || point.distanceTo(arr[i - 1]) > 0.001
      );

    const newCeilingPoints = newFloorPoints.map((point) =>
      point.clone().setY(storey.ceiling.points[0][1])
    );

    const newWalls: UserBuildingWall[] = [];

    for (let i = 0; i < newFloorPoints.length - 1; i++) {
      const wallPoints: FlatVector3[] = [
        getXYZ(newFloorPoints[i]),
        getXYZ(newFloorPoints[i + 1]),
        getXYZ(newCeilingPoints[i + 1]),
        getXYZ(newCeilingPoints[i]),
      ];

      const existingData = storey.walls.find(
        (wall) =>
          compareNumbersWithPrecision(
            wall.points[0][0],
            wallPoints[0][0],
            PRECISION_FOR_WALL_COMPARING
          ) &&
          compareNumbersWithPrecision(
            wall.points[0][2],
            wallPoints[0][2],
            PRECISION_FOR_WALL_COMPARING
          ) &&
          compareNumbersWithPrecision(
            wall.points[1][0],
            wallPoints[1][0],
            PRECISION_FOR_WALL_COMPARING
          ) &&
          compareNumbersWithPrecision(
            wall.points[1][2],
            wallPoints[1][2],
            PRECISION_FOR_WALL_COMPARING
          )
      );

      const modifiedData = validateWall(wallPoints);

      const wall: UserBuildingWall = {
        guid: uuidv7(),
        name: `Extruded Wall ${getAlphabetIndex(i)}`,
        gridLines: [],
        windowPlacements: [],
        wallPanelErrors: [],
        userData: {},
        ...existingData,
        ...modifiedData,
        points: wallPoints,
        wallPanels: [],
      };

      newWalls.push(wall);
    }

    updateUserBuildingStoreyData({
      buildingGuid,
      blockGuid,
      storeyGuid: storey.guid,
      newStorey: {
        ...storey,
        ceiling: {
          ...storey.ceiling,
          points: newCeilingPoints.map((point) => getXYZ(point)),
        },
        floor: {
          ...storey.floor,
          points: newFloorPoints.map((point) => getXYZ(point)),
        },
        walls: newWalls,
      },
      saveStoreysLocally: true,
      updateBlock: allowSave,
    });

    updateUserBuildingDrawMode({
      buildingGuid,
      drawMode: DrawModes.FreeDraw,
    });

    resetExtrudeMode();
  };

  const resetExtrudeMode = () => {
    dispatch(setMode(CanvasActionsModes.selection));
    dispatch(resetExtrudeData());
    dispatch(resetExternalElementsState());
    dispatch(
      setDirectionalInputValues([
        { type: DistanceInput.Distance, processing: true },
      ])
    );
  };

  const keydownEvent = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        finishExtruding();
        break;
      }
      case 'Escape': {
        resetExtrudeMode();
        break;
      }
    }
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    canvas?.addEventListener('pointerdown', finishExtruding);
    document.addEventListener('keydown', keydownEvent);
    return () => {
      document.removeEventListener('keydown', keydownEvent);
      canvas?.removeEventListener('pointerdown', finishExtruding);
    };
  }, [storey, extrudeData, cursorPosition]);

  useEffect(() => {
    if (!floorShape) {
      setFloorShape(
        createGeometryFromVectorList(
          convertFlatVector3ToVectors(floorPoints),
          'horizontal'
        )
      );
    }
    if (!contour) {
      setContour(
        createLine2(storey.floor.points.flat(), C_FatLineBorderMaterial)
      );
    }
    return () => {
      floorShape?.dispose();
      contour?.geometry.dispose();
    };
  }, [storey]);

  return (
    <>
      {floorShape && contour && (
        <mesh
          geometry={floorShape}
          material={C_FloorMaterial}
          onPointerMove={(e) => e.stopPropagation()}
        >
          <primitive object={contour} />
          <mesh
            geometry={floorShape}
            material={C_FloorMaterial}
            position={[
              0,
              storey.ceiling.points[0][1] - storey.floor.points[0][1],
              0,
            ]}
          >
            <primitive object={contour} />
          </mesh>
        </mesh>
      )}
      {floorShape &&
        facadeWallsGuids?.map((facadeWall, i) => {
          const wall = storey?.walls.find((wall) => wall.guid === facadeWall);

          if (!wall) return null;

          const wallCenter = getCenterFromFlatVectorsArray(wall.points);
          const perpendicular = getXYZ(
            getPerpendicularVectorToVectors(
              convertFlatVector3ToVectors(wall.points),
              true
            )
          );

          const extrudeDataObject: ExtrudeHandlerData = {
            perpendicularDirection: perpendicular,
            defaultCenter: [wallCenter.x, wallCenter.y, wallCenter.z],
            facadeWallsGuids: [wall.guid],
          };

          return (
            <ExtrudeWall
              extrudedWallGuid={wall.guid}
              storey={storey}
              extrudeData={extrudeDataObject}
              restExtrudedWallsGuids={extrudeData.facadeWallsGuids}
              cursorPosition={cursorPosition}
              key={`${storey.guid}_${facadeWall}`}
              maxNegativeDistance={maxNegativeDistance}
              setMaxNegativeDistance={setMaxNegativeDistance}
              maxPositiveDistance={maxPositiveDistance}
              setMaxPositiveDistance={setMaxPositiveDistance}
              affectedWallsByExtrude={wallsAffectedByExtrude}
              updateFloor={(newPoints) => updateFloorShape(newPoints, wall)}
              updateWalls={(newWallsData) => {
                changedWallsData[wall.guid] = newWallsData;
              }}
              handleExtrudeHandlerPosition={
                i === facadeWallsGuids.length - 1
                  ? handleExtrudeHandlerPosition
                  : undefined
              }
            />
          );
        })}
    </>
  );
};

export default ExtrudeStorey;
