import React from 'react';
import { Measurement } from '@/shared/components/MeasurementLine';
import {
  FlatVector2Axis,
  MeasurementElementType,
  MeasurementUpdateData,
  WindowCreatorFormData,
} from '@/components/WindowCreator/models/konva-model';
import {
  generateFrameMeasurements,
  generateMullionMeasurements,
  generateOffsetMeasurement,
} from '@/components/WindowCreator/helpers';
import { MeasurementsModel } from '@/models/measurements.model';

interface WindowCreatorMeasurementsProps extends MeasurementsModel {
  data: WindowCreatorFormData;
  onMeasurementSubmit: (data: MeasurementUpdateData) => void;
}
export const WindowCreatorMeasurements = ({
  data,
  scale,
  onMeasurementSubmit,
}: WindowCreatorMeasurementsProps) => {
  const frameMeasurementPoints: [FlatVector2Axis, FlatVector2Axis] = [
    ...generateFrameMeasurements(data),
  ];
  const offsetPoints = generateOffsetMeasurement(data);

  const mullionHorizontalMeasurements = generateMullionMeasurements(
    data.points,
    data.mullions,
    'x'
  );
  const hasInnerHorizontalMeasurements =
    mullionHorizontalMeasurements.length > 1;

  const mullionVerticalMeasurements = generateMullionMeasurements(
    data.points,
    data.mullions,
    'y'
  );
  const hasInnerVerticalMeasurements = mullionVerticalMeasurements.length > 1;

  return (
    <>
      <Measurement
        type={MeasurementElementType.Frame}
        units={data.units}
        scale={scale}
        points={frameMeasurementPoints[0]}
        level={hasInnerHorizontalMeasurements ? 2 : 1}
        onMeasurementSubmit={onMeasurementSubmit}
      ></Measurement>
      <Measurement
        type={MeasurementElementType.Frame}
        units={data.units}
        scale={scale}
        points={frameMeasurementPoints[1]}
        level={hasInnerVerticalMeasurements ? 2 : 1}
        onMeasurementSubmit={onMeasurementSubmit}
      ></Measurement>

      {hasInnerHorizontalMeasurements &&
        mullionHorizontalMeasurements.map((points, idx) => (
          <Measurement
            type={MeasurementElementType.Mullion}
            units={data.units}
            scale={scale}
            key={`measurement_horizontal_mullion_${idx}`}
            points={points}
            onMeasurementSubmit={onMeasurementSubmit}
          ></Measurement>
        ))}

      {hasInnerVerticalMeasurements &&
        mullionVerticalMeasurements.map((points, idx) => (
          <Measurement
            type={MeasurementElementType.Mullion}
            units={data.units}
            scale={scale}
            key={`measurement_vertical_mullion_${idx}`}
            points={points}
            onMeasurementSubmit={onMeasurementSubmit}
          ></Measurement>
        ))}

      <Measurement
        type={MeasurementElementType.Offset}
        units={data.units}
        scale={scale}
        key={`measurement_offset`}
        points={offsetPoints}
        forcedAngleDeg={90}
        level={hasInnerVerticalMeasurements ? 2 : 1}
        onMeasurementSubmit={onMeasurementSubmit}
      ></Measurement>
    </>
  );
};
