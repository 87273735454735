import {
  FacadeDesignerPlacementType,
  getDragNode,
  getFacadeDesignerMode,
  getHoveredGridLine,
  getHoveredPlacedWindow,
  getHoveredWall,
  getMeasurementActiveWall,
  getPlacementErrors,
  getSelectedGridlines,
  resetPlacementErrors,
  resetSelectedGridLines,
  resetSelectedWindowFromLibrary,
  setDragNode,
  setGridPlacementAbsoluteOffset,
  setHoveredGridLine,
  setHoveredPlacedWindow,
  setSelectedGridLines,
  setSelectedPlacedWindow,
  setSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { RootState } from '@/store';
import { KonvaEventObject } from 'konva/lib/Node';
import { round } from 'mathjs';
import { useKey } from 'react-use';
import { MutableRefObject, useMemo, useRef } from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { GridLineData, WindowPlacementData } from '@/models';
import { useParams } from 'react-router';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { useStore } from 'react-redux';
import { Stage } from 'konva/lib/Stage';
import { FacadeDesignerModes } from '@/models/shared.model';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { useMeasureWallsFacadeDesigner } from '@/components/FacadeDesigner/hooks/useMeasureWallsFacadeDesigner';
import { ExtendedMinMaxCoordinatesPairs } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { GridLineDataForFD } from '@/components/FacadeDesigner/models';

interface UseFdDragHandlersProps {
  stageRef: MutableRefObject<Stage>;
  wallsData: WallSearchResults[];
  isProjectLocked: boolean;
  startPoint: { x: number; y: number; z: number };
  minMaxWallsCoordinates: ExtendedMinMaxCoordinatesPairs;
  handleSaveWallPlacements: (
    prop: {
      wallGUID: string;
      gridLines?: GridLineData[];
      windowPlacements?: WindowPlacementData[];
    }[]
  ) => void;
  generateCustomGrids: (prop: {
    wallsData: WallSearchResults[];
    startPoint: { x: number; z: number };
  }) => GridLineDataForFD[];
}

export const useFdDragHandlers = ({
  stageRef,
  wallsData,
  minMaxWallsCoordinates,
  startPoint,
  isProjectLocked,
  handleSaveWallPlacements,
  generateCustomGrids,
}: UseFdDragHandlersProps) => {
  const { id } = useParams();
  const store = useStore();
  const measurementActiveWall = useAppSelector(getMeasurementActiveWall);
  const timeoutForGrabRef = useRef<NodeJS.Timeout | null>(null);
  const grabbedWindowRef = useRef<WindowPlacementData | null>(null);
  const windowsData = useFetchWindowsQuery(id!).data!;
  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;
  const dispatch = useAppDispatch();
  const { getMeasureWall } = useMeasureWallsFacadeDesigner();

  const isOnlyOneGridLineSelected = useMemo(() => {
    const selectedPlacedGridLines = getSelectedGridlines(
      store.getState() as RootState
    );
    const values = selectedPlacedGridLines.reduce((acc, curr) => {
      return { ...acc, [curr.absoluteOffset]: 1 };
    }, {});
    return Object.keys(values).length < 2;
  }, []);

  const activateDrag = (
    type: FacadeDesignerPlacementType,
    callback: () => void
  ) => {
    timeoutForGrabRef.current = setTimeout(() => {
      dispatch(setDragNode(type));
      callback();
      stageRef.current.container().style.cursor = 'grabbing';
    }, 300);
  };

  const handleStartWindowDrag = () => {
    const hoveredWindow = getHoveredPlacedWindow(store.getState() as RootState);

    hoveredWindow &&
      activateDrag(FacadeDesignerPlacementType.Window, () => {
        dispatch(resetSelectedGridLines());
        dispatch(setSelectedPlacedWindow([hoveredWindow]));
        grabbedWindowRef.current = hoveredWindow;
        const windowFromLibrary = windowsData.find(
          (window) => hoveredWindow.windowId === window.id
        );

        windowFromLibrary &&
          dispatch(setSelectedWindowFromLibrary(windowFromLibrary));
        dispatch(
          setGridPlacementAbsoluteOffset(hoveredWindow.offsetFromLeftEdge)
        );
      });

    return;
  };

  const handleStartGridLineDrag = () => {
    if (!isOnlyOneGridLineSelected) return;
    const hoveredGridLine = getHoveredGridLine(store.getState() as RootState);
    hoveredGridLine &&
      activateDrag(FacadeDesignerPlacementType.GridLine, () => {
        dispatch(setSelectedPlacedWindow([]));
        dispatch(
          setGridPlacementAbsoluteOffset(hoveredGridLine!.absoluteOffset)
        );
      });

    return;
  };

  const handleDragStart = (e: KonvaEventObject<PointerEvent>) => {
    if (
      !isSelectionMode ||
      e.evt.shiftKey ||
      measurementActiveWall ||
      isProjectLocked
    )
      return;

    if (getHoveredPlacedWindow(store.getState() as RootState)) {
      handleStartWindowDrag();
    }

    if (getHoveredGridLine(store.getState() as RootState)) {
      handleStartGridLineDrag();
    }
  };

  const handleGridLineDragEnd = () => {
    const selectedPlacedGridLines = getSelectedGridlines(
      store.getState() as RootState
    );
    // Can't happen, however for additional validation added here, to not break out the code
    if (!selectedPlacedGridLines.length) return;

    const hoveredWall = getHoveredWall(store.getState() as RootState);

    const absoluteOffset = selectedPlacedGridLines[0].absoluteOffset;

    const relevantWalls = wallsData.filter((wall) => {
      const { wallOffset } = getMeasureWall(
        wall,
        startPoint,
        minMaxWallsCoordinates
      );

      return wall.gridLines.some(
        (line) =>
          round(line.offsetFromLeftEdge + wallOffset.x, 2) === absoluteOffset &&
          !line.cornerAlign
      );
    });

    const newGridLines = generateCustomGrids({
      wallsData: relevantWalls.map((wallData) => ({
        ...wallData,
        gridLines: wallData.gridLines.filter(
          (gridLine) =>
            !selectedPlacedGridLines.some((line) => gridLine.guid === line.guid)
        ),
      })),
      startPoint,
    });
    const mainSelectedGridLine = newGridLines?.find(
      (line) => line.wallGUID === hoveredWall
    );

    const updatedHoveredGridLine = newGridLines?.find(
      (ngl) => ngl.wallGUID === hoveredWall
    );
    updatedHoveredGridLine &&
      dispatch(setHoveredGridLine(updatedHoveredGridLine));
    mainSelectedGridLine
      ? dispatch(setSelectedGridLines([mainSelectedGridLine]))
      : dispatch(setSelectedGridLines(newGridLines));
    handleDragReset();
  };

  const handleWindowDragEnd = (newOffset: number) => {
    const hoveredWall = getHoveredWall(store.getState() as RootState);
    if (!grabbedWindowRef.current || !hoveredWall) {
      return handleDragReset();
    }

    const wall = wallsData.find((wall) =>
      wall.windowPlacements.find(
        (window) => window.guid === grabbedWindowRef.current?.guid
      )
    );

    const hoveredWallPlacement = wallsData
      .find((wall) => wall.guid === hoveredWall)
      ?.windowPlacements.filter(
        (window) => window.guid !== grabbedWindowRef.current?.guid
      );

    if (!hoveredWallPlacement) return;
    const newWindowData = {
      wallGUID: hoveredWall,
      windowId: grabbedWindowRef.current.windowId,
      guid: grabbedWindowRef.current.guid,
      offsetFromLeftEdge: newOffset,
    };
    handleSaveWallPlacements([
      {
        wallGUID: hoveredWall,
        windowPlacements: [...hoveredWallPlacement, newWindowData],
      },
      {
        wallGUID: wall?.guid ?? '',
        windowPlacements: wall?.windowPlacements.filter(
          (window) => window.guid !== grabbedWindowRef.current?.guid
        ),
      },
    ]);
    dispatch(setSelectedPlacedWindow([newWindowData]));
    handleDragReset();
  };

  const handleDragEnd = () => {
    if (!isSelectionMode) return;
    const placementErrors = getPlacementErrors(store.getState() as RootState);
    if (Object.values(placementErrors).some((error) => error.state)) {
      handleDragReset(true);
      return;
    }
    if (
      getDragNode(store.getState() as RootState) ===
      FacadeDesignerPlacementType.GridLine
    ) {
      handleGridLineDragEnd();
    }

    handleDragReset();
  };

  const handleDragReset = (discardSelected: boolean = false) => {
    timeoutForGrabRef.current && clearTimeout(timeoutForGrabRef.current);
    timeoutForGrabRef.current = null;
    grabbedWindowRef.current = null;
    const dragNode = getDragNode(store.getState() as RootState);

    dragNode && dispatch(setDragNode(null));
    dispatch(resetPlacementErrors());
    dispatch(setGridPlacementAbsoluteOffset(null));
    dispatch(resetSelectedWindowFromLibrary());

    if (dragNode && discardSelected) {
      if (dragNode === FacadeDesignerPlacementType.Window) {
        dispatch(setSelectedPlacedWindow([]));
        dispatch(setHoveredPlacedWindow(null));
      }
      if (dragNode === FacadeDesignerPlacementType.GridLine) {
        dispatch(resetSelectedGridLines());
        dispatch(setHoveredGridLine(null));
      }

      stageRef.current.container().style.cursor = 'default';
    }

    if (
      (getHoveredGridLine(store.getState() as RootState) ||
        getHoveredPlacedWindow(store.getState() as RootState)) &&
      isOnlyOneGridLineSelected
    ) {
      stageRef.current.container().style.cursor = 'pointer';
    }
  };

  useKey('Escape', () => handleDragReset(true), { event: 'keydown' }, []);

  return {
    handleDragStart,
    handleDragEnd,
    handleWindowDragEnd,
  };
};
