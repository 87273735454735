import React from 'react';
import { UserBuildingPanel } from '@/models';
import PanelMetricsTable from './PanelMetricsTable/PanelMetricTable';
import InnerFrameTable, {
  GroupedInnerFrameData,
} from '@/components/Report/Pages/PanelMetrics/InnerFrameTable/InnerFrameTable';

interface PanelMetricsProps {
  panelData: UserBuildingPanel;
  panelIndex: number;
  panelCount: {
    [key: number]: number;
  };
  innerFramesData: GroupedInnerFrameData[];
  isSplittedTable: boolean;
}

const ReportPanelMetrics: React.FC<PanelMetricsProps> = ({
  panelData,
  panelIndex,
  panelCount,
  innerFramesData,
  isSplittedTable,
}) => {
  return (
    <div className="h-full w-full flex flex-col">
      <div className="px-3 py-1 flex gap-6 text-[11px] leading-4 bg-light-gray-10 border-0 border-b border-solid border-light-gray-20">
        <div className="text-light-green-100"># {panelIndex + 1}</div>
        <div className="to-dark-gray-100">P {panelData.id}</div>
        <div className="to-dark-gray-100">
          {panelData.cornerSide ? 'Corner UCW Unit' : 'Straight UCW Unit'}
        </div>
      </div>
      <div className="h-[209px] flex gap-5 items-center mb-5">
        <div
          className="py-[37px] box-border  w-[209px] shrink-0 h-full flex items-center justify-center overflow-hidden"
          style={{
            backgroundColor: '#ECEEEE',
          }}
        />
        <div className="text-[10px] leading-4 grow pr-3">
          <div className="pt-6 flex flex-col gap-2">
            <div className="font-medium uppercase">UCW Unit metrics </div>
            <PanelMetricsTable panelData={panelData} panelCount={panelCount} />
          </div>
        </div>
      </div>
      <div className="flex flex-col px-3">
        <InnerFrameTable
          panelData={panelData}
          innerFramesData={innerFramesData}
          isSplittedTable={isSplittedTable}
          includeFrameAndMullions
        />
      </div>
    </div>
  );
};

export default ReportPanelMetrics;
