import React from 'react';

import { Trap } from '@/components/SvgDesigner/shared';

import { Color, LineColor } from '@/components/SvgDesigner/models';
import { LINE_WIDTH } from '@/components/SvgDesigner/constants';
import { FlatVector2 } from '@/models';

interface SvgWindowBorderProps {
  color: Color;
  borders: FlatVector2[][];
}

const SvgWindowBorders: React.FC<SvgWindowBorderProps> = ({
  borders,
  color,
}) => {
  return (
    <>
      {borders.map((borderPoints, index) => (
        <Trap
          key={index}
          points={borderPoints}
          fill={color}
          strokeWidth={LINE_WIDTH}
          stroke={LineColor.DEFAULT}
        />
      ))}
    </>
  );
};

export default SvgWindowBorders;
