import React from 'react';

import { Dimensions } from '../../models';
import { TEXT_PADDING, TEXT_SIZE_FRAME } from '../../constants';

interface TextProps extends Omit<Dimensions, 'width' | 'height'> {
  color: string;
  text: string;
}

const Text: React.FC<TextProps> = ({ x, y, color, text }) => {
  return (
    <text
      x={x + TEXT_PADDING}
      y={y - TEXT_PADDING}
      fill={color}
      fontSize={TEXT_SIZE_FRAME}
    >
      {text}
    </text>
  );
};

export default Text;
