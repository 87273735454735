import { IGU } from '@/models/webcalc';
import { WindowConfigurator } from '@/models/window-configurator.model';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import React, { createContext, useMemo } from 'react';

interface ConfigProviderValue {
  IGUData: IGU[];
  config: WindowConfigurator;
}

export const ProjectConfigContext = createContext<ConfigProviderValue | null>(
  null
);

const ProjectConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const IGUData = useFetchIguQuery().data!;
  const config = useFetchWindowConfigQuery().data!;

  const providerValue = useMemo(() => ({ IGUData, config }), [IGUData, config]);

  return (
    <ProjectConfigContext.Provider value={providerValue}>
      {children}
    </ProjectConfigContext.Provider>
  );
};

export default ProjectConfigProvider;
