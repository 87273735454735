import { MeasurementProps } from '@/models/measurements.model';
import { FlatVector2Axis } from '@/components/WindowCreator/models';
import { Measurement } from '@/shared/components/MeasurementLine';

import React, { useState } from 'react';
import { useKey, useUnmount } from 'react-use';
import { FlatVector2 } from '@/models';
import { round } from 'mathjs';

export interface MultiMeasurementProps extends MeasurementProps {
  multiPoints: FlatVector2Axis[];
  onSubmit?: () => void;
  onChange?: (points: FlatVector2Axis[]) => void;
  onActiveStatusChange?: (isActive: boolean) => void;
  onEscape?: () => void;
  horizontal?: boolean;
  customErrorMessage?: string;
}
const MultiMeasurementLine = ({
  // That functionality will only work for sorted points from left to right, and from top to bottom
  multiPoints,
  scale,
  units,
  type,
  onActiveStatusChange,
  onChange,
  onSubmit,
  onEscape,
  horizontal = true,
  customErrorMessage,
}: MultiMeasurementProps) => {
  const [activeMeasurementIdx, setActiveMeasurementIdx] = useState<number>(-1);
  const xSpace = round(multiPoints[1][0][0] - multiPoints[0][1][0], 2);
  // const ySpace = round(multiPoints[1][0][1] - multiPoints[0][1][1], 2);

  const handleEscape = () => {
    onEscape && onEscape();
    onActiveStatusChange && onActiveStatusChange(false);
    setActiveMeasurementIdx(-1);
  };

  const handleChange = (distance: number) => {
    const firstValuePair: [FlatVector2, FlatVector2] = [...multiPoints[0]];
    const secondValuePair: [FlatVector2, FlatVector2] = [...multiPoints[1]];
    // No handling for vertical change, where ySpace exist.
    if (horizontal) {
      if (activeMeasurementIdx === 0) {
        firstValuePair[1] = [
          round(firstValuePair[0][0] + distance, 2),
          firstValuePair[1][1],
        ];
        secondValuePair[0] = [
          round(firstValuePair[1][0] + xSpace, 2),
          firstValuePair[1][1],
        ];
      } else {
        secondValuePair[0] = [
          round(secondValuePair[1][0] - distance, 2),
          secondValuePair[0][1],
        ];
        firstValuePair[1] = [
          round(secondValuePair[0][0] - xSpace, 2),
          secondValuePair[0][1],
        ];
      }
    } else {
      if (activeMeasurementIdx === 0) {
        firstValuePair[1] = [
          firstValuePair[1][0],
          round(firstValuePair[0][1] + distance, 2),
        ];
        secondValuePair[0] = firstValuePair[1];
      } else {
        secondValuePair[0] = [
          secondValuePair[1][0],
          round(secondValuePair[1][1] - distance, 2),
        ];
        firstValuePair[1] = secondValuePair[0];
      }
    }

    onChange && onChange([firstValuePair, secondValuePair]);
  };

  const handleSubmit = () => {
    onSubmit && onSubmit();
    onActiveStatusChange && onActiveStatusChange(false);
  };

  const getLimits = () => {
    const maxHorizontal = round(
      multiPoints[1][1][0] -
        multiPoints[0][0][0] -
        (multiPoints[1][0][0] - multiPoints[0][1][0]),
      2
    );
    return horizontal
      ? {
          min: 0,
          max: maxHorizontal,
        }
      : {
          min: 0,
          max: round(
            multiPoints[1][1][1] -
              multiPoints[0][0][1] -
              (multiPoints[1][0][1] - multiPoints[0][1][1]),
            2
          ),
        };
  };

  const handleTab = (e: KeyboardEvent) => {
    e.preventDefault();
    e.stopPropagation();
    setActiveMeasurementIdx(
      activeMeasurementIdx === multiPoints.length - 1
        ? 0
        : activeMeasurementIdx + 1
    );
    onActiveStatusChange && onActiveStatusChange(true);
  };

  useKey('Escape', handleEscape, { event: 'keydown' }, []);

  useKey('Tab', handleTab, { event: 'keydown' }, [
    multiPoints,
    activeMeasurementIdx,
  ]);

  const showCustomErrorMessage = (idx: number): boolean => {
    if (idx === activeMeasurementIdx) {
      return true;
    } else {
      if (activeMeasurementIdx === -1) {
        return idx === 0;
      }
      return false;
    }
  };

  useUnmount(() => {
    onActiveStatusChange && onActiveStatusChange(false);
  });

  return (
    <>
      {multiPoints.map((points, idx) => (
        <Measurement
          limits={getLimits()}
          key={idx}
          points={points}
          scale={scale}
          units={units}
          type={type}
          level={0}
          forceActive={idx === activeMeasurementIdx}
          listening={false}
          onEscape={handleEscape}
          onChange={handleChange}
          onMeasurementSubmit={handleSubmit}
          customErrorMessage={
            showCustomErrorMessage(idx) ? customErrorMessage : undefined
          }
        ></Measurement>
      ))}
    </>
  );
};

export default MultiMeasurementLine;
