import React, { useMemo } from 'react';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { FlatVector2, NodeType, UnitSystemTypes } from '@/models';
import {
  convertMetersToMillimeters,
  convertMillimetersToFtInch,
} from '@/shared/helpers/distance';
import {
  addSpacesToThousands,
  roundIfWithinTolerance,
} from '@/shared/helpers/format-data';
import { Group } from 'react-konva';
import KonvaDashedLine from '@/shared/elements/KonvaDashedLine/KonvaDashedLine';
import { PanelConfig } from '@/models/window-configurator.model';
import { getSelectedPanelGuidForEditing } from '@/store/slices/windowsReducer/UTCSlice';
import { Measurement } from '@/shared/components/MeasurementLine';
import { MeasurementElementType } from '@/components/WindowCreator/models';
import { generateHeightMeasurements } from '@/components/UTC/helpers/generators';
import { MEASUREMENT_PADDING } from '@/components/WindowCreator/constants';
import KonvaHeightMark from '@/shared/components/KonvaHeightMark';
import {
  CEILING_HEIGHT_MARK_NAME,
  FLOOR_HEIGHT_MARK_NAME,
} from '@/shared/names';
import { KONVA_UTC_DEFAULT_LEVEL_WIDTH } from '@/shared/constants';

interface UTCUnitFloorLinesProps {
  scale: number;
  framePoints: FlatVector2[];
  panelConfig: PanelConfig;
  hasInnerVerticalMeasurements: boolean;
  units: UnitSystemTypes;
}

const UTCUnitFloorLines: React.FC<UTCUnitFloorLinesProps> = ({
  scale,
  framePoints,
  panelConfig,
  hasInnerVerticalMeasurements,
  units,
}) => {
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const selectedPanelGuid = useAppSelector(getSelectedPanelGuidForEditing);
  const isImperialUnits = units === UnitSystemTypes.Imperial;

  const { findDataForWallInfoViaPanel, findDataForStorey, findDataForBlock } =
    useFindNodeData();

  const elevationLineWidth = 200;

  const getFormattedElevationLevel = (
    point1Y: number,
    point2Y: number
  ): string => {
    const elevationLevelInMillimeters = Number(
      convertMetersToMillimeters((point1Y - point2Y) / multiplyRate)
    );

    return addSpacesToThousands(
      isImperialUnits
        ? convertMillimetersToFtInch(elevationLevelInMillimeters)
        : roundIfWithinTolerance(+elevationLevelInMillimeters).toString(),
      isImperialUnits
    );
  };

  const getWallInfo = () => {
    if (!selectedPanelGuid) return {};
    const wallData = findDataForWallInfoViaPanel({
      panelGuid: selectedPanelGuid,
    })!;

    const storeyData = findDataForStorey(
      wallData.getParentNode(NodeType.Storey)!.guid
    );
    const blockData = findDataForBlock(
      wallData.getParentNode(NodeType.Block)!.guid
    );
    return { blockData, storeyData };
  };

  const formattedElevationLevelForTopLevelLine = useMemo(() => {
    if (!selectedPanelGuid) {
      return CEILING_HEIGHT_MARK_NAME;
    }
    const wallInfo = getWallInfo();

    const floorName =
      wallInfo.blockData!.storeysCount === wallInfo.storeyData!.storeyNumber
        ? 'Roof'
        : `Floor ${wallInfo.storeyData!.storeyNumber + 1}`;

    return `${floorName} - ${getFormattedElevationLevel(
      wallInfo.storeyData!.ceiling.points[0][1],
      wallInfo.blockData!.storeys[0].floor.points[0][1]
    )}`;
  }, [selectedPanelGuid, units]);

  const formattedElevationLevelForBottomLevelLine = useMemo(() => {
    if (!selectedPanelGuid) {
      return FLOOR_HEIGHT_MARK_NAME;
    }
    const wallInfo = getWallInfo();

    return `Floor ${wallInfo.storeyData!.storeyNumber} - ${getFormattedElevationLevel(
      wallInfo.storeyData!.floor.points[0][1],
      wallInfo.blockData!.storeys[0].floor.points[0][1]
    )}`;
  }, [selectedPanelGuid, units]);

  const generateStaticElevation = (elevationLevel: number, offsetY: number) => {
    const STATIC_LINE_WIDTH =
      KONVA_UTC_DEFAULT_LEVEL_WIDTH + MEASUREMENT_PADDING / scale;
    const staticElevationWidth =
      framePoints[1][0] - framePoints[0][0] + STATIC_LINE_WIDTH;
    return (
      <Group
        x={framePoints[0][0] - STATIC_LINE_WIDTH}
        y={elevationLevel + offsetY}
      >
        <KonvaDashedLine
          points={[
            [0, 0],
            [staticElevationWidth, 0],
          ]}
        />
      </Group>
    );
  };

  const generateDynamicElevation = (
    elevationLevel: number,
    offsetY: number,
    elevationElementName: string
  ) => (
    <>
      {generateStaticElevation(elevationLevel, offsetY)}
      <KonvaHeightMark
        width={elevationLineWidth}
        name={elevationElementName}
        xOffset={framePoints[1][0]}
        yOffset={elevationLevel + offsetY}
        scale={scale}
      />
    </>
  );

  return (
    <Group>
      {generateDynamicElevation(
        framePoints[0][1],
        -panelConfig.verticalOffset / 2,
        formattedElevationLevelForTopLevelLine
      )}
      {generateDynamicElevation(
        framePoints[3][1],
        panelConfig.verticalOffset / 2,
        formattedElevationLevelForBottomLevelLine
      )}
      <Measurement
        type={MeasurementElementType.Unit}
        units={units}
        scale={scale}
        points={generateHeightMeasurements(
          framePoints,
          panelConfig.verticalOffset / 2
        )}
        level={hasInnerVerticalMeasurements ? 3 : 2}
        disabled
      />
    </Group>
  );
};

export default UTCUnitFloorLines;
