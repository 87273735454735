import React from 'react';

const SystemUnitIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M11.7729 7.54415L8.00061 9M8.00061 9L4.08398 7.58441M8.00061 9L8.0076 13.5843M14.7729 13.3981L11.7729 11.7731M4.08398 11.7329L1.08398 13.3578M8.01837 2.40625V5.78127M8.34421 5.86309L11.3442 7.18418C11.707 7.34395 11.9412 7.70295 11.9412 8.09938V11.2179C11.9412 11.6096 11.7126 11.9652 11.3562 12.1277L8.35624 13.4963C8.09261 13.6166 7.78978 13.6166 7.52615 13.4963L4.52615 12.1277C4.16983 11.9652 3.94119 11.6096 3.94119 11.2179V8.09938C3.94119 7.70295 4.17537 7.34395 4.53818 7.18418L7.53818 5.86309C7.79496 5.75002 8.08743 5.75002 8.34421 5.86309Z"
        stroke="#414042"
        strokeLinecap="round"
      />
      <path
        d="M11.7729 7.54415L8.00061 9M8.00061 9L4.08398 7.58441M8.00061 9L8.0076 13.5843M14.7729 13.3981L11.7729 11.7731M4.08398 11.7329L1.08398 13.3578M8.01837 2.40625V5.78127M8.34421 5.86309L11.3442 7.18418C11.707 7.34395 11.9412 7.70295 11.9412 8.09938V11.2179C11.9412 11.6096 11.7126 11.9652 11.3562 12.1277L8.35624 13.4963C8.09261 13.6166 7.78978 13.6166 7.52615 13.4963L4.52615 12.1277C4.16983 11.9652 3.94119 11.6096 3.94119 11.2179V8.09938C3.94119 7.70295 4.17537 7.34395 4.53818 7.18418L7.53818 5.86309C7.79496 5.75002 8.08743 5.75002 8.34421 5.86309Z"
        stroke="black"
        strokeOpacity="0.2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SystemUnitIcon;
