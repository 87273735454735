import { FlatVector2 } from '@/models';
import {
  FlatVector2Axis,
  View,
} from '@/components/WindowCreator/models/konva-model';
import {
  isHorizontal,
  isPointOnContourPoints,
} from '@/components/WindowCreator/helpers/direction.helpers';
import { get2DCenter, roundKonvaValue } from '@/shared/helpers/konva';
import { OperationType } from '@/models/window-configurator.model';

export enum EDGE_POSITION {
  TOP = 0,
  RIGHT = 1,
  BOTTOM = 2,
  LEFT = 3,
}

export enum POINT_POSITION {
  TopLeft = 0,
  TopRight = 1,
  BottomRight = 2,
  BottomLeft = 3,
}

export const OUTER_BORDER_THICKNESS = 60;

export const generateFramePoints = (
  points: FlatVector2[],
  index: number,
  frameWidth: number
): number[] => {
  const outerPoints = [...points];

  const innerPoints = generateInnerFramePoints(points, index, frameWidth);

  return [...outerPoints, ...innerPoints].flat();
};

// TODO: refactor this function and instead of EDGE_POSITION use POINT_POSITION, contact developer who wrote this function and clarify the reason of using EDGE_POSITION
export const generateInnerFramePoints = (
  points: FlatVector2[],
  index: number,
  frameWidth: number
): FlatVector2[] => {
  let innerPoints: FlatVector2[] = [];
  switch (index) {
    case EDGE_POSITION.TOP: {
      innerPoints = [
        [points[1][0] - frameWidth, points[1][1] + frameWidth],
        [points[0][0] + frameWidth, points[0][1] + frameWidth],
      ];
      break;
    }
    case EDGE_POSITION.RIGHT: {
      innerPoints = [
        [points[1][0] - frameWidth, points[1][1] - frameWidth],
        [points[0][0] - frameWidth, points[0][1] + frameWidth],
      ];
      break;
    }

    case EDGE_POSITION.BOTTOM: {
      innerPoints = [
        [points[1][0] + frameWidth, points[1][1] - frameWidth],
        [points[0][0] - frameWidth, points[0][1] - frameWidth],
      ];
      break;
    }

    case EDGE_POSITION.LEFT: {
      innerPoints = [
        [points[1][0] + frameWidth, points[1][1] + frameWidth],
        [points[0][0] + frameWidth, points[0][1] - frameWidth],
      ];
      break;
    }
  }
  return innerPoints;
};

export const calculateRealWindowGlassPoints = (
  points: FlatVector2,
  index: number,
  offset: number
): FlatVector2 => {
  let borderPoints: FlatVector2 = [0, 0];

  switch (index) {
    case EDGE_POSITION.TOP: {
      borderPoints = [points[0] + offset, points[1] - offset];
      break;
    }

    case EDGE_POSITION.RIGHT: {
      borderPoints = [points[0] + offset, points[1] + offset];
      break;
    }

    case EDGE_POSITION.BOTTOM: {
      borderPoints = [points[0] - offset, points[1] + offset];
      break;
    }

    case EDGE_POSITION.LEFT: {
      borderPoints = [points[0] - offset, points[1] - offset];
      break;
    }
  }
  return borderPoints;
};

export const generateWindowSashPoints = (
  windowFramePoints: FlatVector2[][],
  windowGlassPoints: FlatVector2[][]
): FlatVector2[][] => {
  return windowFramePoints.map((windowFramePoint, idx) => [
    windowFramePoint[0],
    windowFramePoint[1],
    windowGlassPoints[idx][1],
    windowGlassPoints[idx][0],
  ]);
};

export const generateClosedContourPointsFromPointArray = (
  points: FlatVector2[]
): FlatVector2Axis[] => {
  const contour: FlatVector2Axis[] = [];
  for (let i = 0; i < points.length; i++) {
    contour.push([points[i], points[i === points.length - 1 ? 0 : i + 1]]);
  }

  return contour;
};

export const extractPointsFromClosedContour = (
  contour: FlatVector2Axis[]
): FlatVector2[] => {
  return contour.map(([start]) => start);
};

export const generateAwningView = (
  windowPoints: FlatVector2[][]
): FlatVector2[] => {
  const bottomLine = windowPoints[EDGE_POSITION.BOTTOM];
  const topLine = windowPoints[EDGE_POSITION.TOP];

  const topCenter: FlatVector2 = get2DCenter(topLine[0], topLine[1]);
  return [bottomLine[0], topCenter, bottomLine[1]];
};

export const generateHopperView = (
  windowPoints: FlatVector2[][]
): FlatVector2[] => {
  const bottomLine = windowPoints[EDGE_POSITION.BOTTOM];
  const topLine = windowPoints[EDGE_POSITION.TOP];

  const bottomCenter: FlatVector2 = get2DCenter(bottomLine[0], bottomLine[1]);
  return [topLine[0], bottomCenter, topLine[1]];
};

export const generateCasementLeftView = (
  windowPoints: FlatVector2[][]
): FlatVector2[] => {
  const leftLine = windowPoints[EDGE_POSITION.LEFT];
  const rightLine = windowPoints[EDGE_POSITION.RIGHT];

  const leftCenter: FlatVector2 = get2DCenter(leftLine[0], leftLine[1]);

  return [rightLine[0], leftCenter, rightLine[1]];
};

export const generateCasementRightView = (
  windowPoints: FlatVector2[][]
): FlatVector2[] => {
  const leftLine = windowPoints[EDGE_POSITION.LEFT];
  const rightLine = windowPoints[EDGE_POSITION.RIGHT];

  const rightCenter: FlatVector2 = get2DCenter(rightLine[0], rightLine[1]);

  return [leftLine[0], rightCenter, leftLine[1]];
};

export const generateDualActionLeftTopView = (
  windowPoints: FlatVector2[][]
): FlatVector2[][] => {
  const first = generateCasementLeftView(windowPoints);
  const second = generateHopperView(windowPoints);
  return [first, second];
};

export const generateDualActionRightTopView = (
  windowPoints: FlatVector2[][]
): FlatVector2[][] => {
  const first = generateCasementRightView(windowPoints);
  const second = generateHopperView(windowPoints);
  return [first, second];
};

export const calculateOperableWindowPoint = (
  points: FlatVector2,
  index: number,
  isPointOnMullionX: boolean,
  isPointOnMullionY: boolean,
  mullionThickness: number
): FlatVector2 => {
  let newPoints: FlatVector2 = [0, 0];

  const thicknessX = isPointOnMullionY
    ? roundKonvaValue(mullionThickness / 2)
    : OUTER_BORDER_THICKNESS;

  const thicknessY = isPointOnMullionX
    ? roundKonvaValue(mullionThickness / 2)
    : OUTER_BORDER_THICKNESS;

  switch (index) {
    case POINT_POSITION.TopLeft: {
      newPoints = [points[0] + thicknessX, points[1] + thicknessY];
      break;
    }

    case POINT_POSITION.TopRight: {
      newPoints = [points[0] - thicknessX, points[1] + thicknessY];
      break;
    }

    case POINT_POSITION.BottomRight: {
      newPoints = [points[0] - thicknessX, points[1] - thicknessY];
      break;
    }

    case POINT_POSITION.BottomLeft: {
      newPoints = [points[0] + thicknessX, points[1] - thicknessY];
      break;
    }
  }
  return newPoints;
};

export const calculateInnerWindowPoints = (
  windowPoints: FlatVector2[],
  framePoints: FlatVector2[],
  mullionThickness: number
) => {
  const maxX = Math.max(...framePoints.map((wp) => wp[0]));
  const minX = Math.min(...framePoints.map((wp) => wp[0]));

  const maxY = Math.max(...framePoints.map((wp) => wp[1]));
  const minY = Math.min(...framePoints.map((wp) => wp[1]));

  const contourPoints = windowPoints.map((point, idx) => {
    const isPointAtHorizontalEdge = point[1] === minY || point[1] === maxY;

    const isPointAtVerticalEdge = point[0] === minX || point[0] === maxX;

    return calculateOperableWindowPoint(
      point,
      idx,
      !isPointAtHorizontalEdge,
      !isPointAtVerticalEdge,
      mullionThickness
    );
  });
  return generateClosedContourPointsFromPointArray(contourPoints);
};

export const generateMullionWithOffset = (
  axis: FlatVector2Axis,
  framePoints: FlatVector2[],
  mullionThickness: number
) => {
  const fistPointOnContour = isPointOnContourPoints(axis[0], framePoints);
  const secondPointOnContour = isPointOnContourPoints(axis[1], framePoints);
  const updatedAxis: FlatVector2Axis = isHorizontal(axis)
    ? [
        [
          fistPointOnContour
            ? axis[0][0] + OUTER_BORDER_THICKNESS
            : roundKonvaValue(axis[0][0] + mullionThickness / 2),
          axis[0][1],
        ],
        [
          secondPointOnContour
            ? axis[1][0] - OUTER_BORDER_THICKNESS
            : roundKonvaValue(axis[1][0] - mullionThickness / 2),
          axis[1][1],
        ],
      ]
    : [
        [
          axis[0][0],
          fistPointOnContour
            ? axis[0][1] + OUTER_BORDER_THICKNESS
            : roundKonvaValue(axis[0][1] + mullionThickness / 2),
        ],
        [
          axis[1][0],
          secondPointOnContour
            ? axis[1][1] - OUTER_BORDER_THICKNESS
            : roundKonvaValue(axis[1][1] - mullionThickness / 2),
        ],
      ];

  const points: FlatVector2[] = isHorizontal(axis)
    ? [
        [
          updatedAxis[0][0],
          roundKonvaValue(updatedAxis[0][1] - mullionThickness / 2),
        ],
        [
          updatedAxis[1][0],
          roundKonvaValue(updatedAxis[1][1] - mullionThickness / 2),
        ],
        [
          updatedAxis[1][0],
          roundKonvaValue(updatedAxis[0][1] + mullionThickness / 2),
        ],
        [
          updatedAxis[0][0],
          roundKonvaValue(updatedAxis[1][1] + mullionThickness / 2),
        ],
      ]
    : [
        [
          roundKonvaValue(updatedAxis[0][0] - mullionThickness / 2),
          updatedAxis[0][1],
        ],
        [
          roundKonvaValue(updatedAxis[0][0] + mullionThickness / 2),
          updatedAxis[0][1],
        ],
        [
          roundKonvaValue(updatedAxis[1][0] + mullionThickness / 2),
          updatedAxis[1][1],
        ],
        [
          roundKonvaValue(updatedAxis[1][0] - mullionThickness / 2),
          updatedAxis[1][1],
        ],
      ];
  return points;
};

export const getWindowOperationTypeViewPoints = (
  windowGlassPoints: FlatVector2[][],
  operationType: OperationType,
  windowView: View
): FlatVector2[][] | null => {
  switch (operationType) {
    case OperationType.Awning: {
      return [generateAwningView(windowGlassPoints)];
    }
    case OperationType.Hopper: {
      return [generateHopperView(windowGlassPoints)];
    }
    case OperationType.CasementLeft: {
      return windowView === View.Outside
        ? [generateCasementLeftView(windowGlassPoints)]
        : [generateCasementRightView(windowGlassPoints)];
    }
    case OperationType.CasementRight: {
      return windowView === View.Outside
        ? [generateCasementRightView(windowGlassPoints)]
        : [generateCasementLeftView(windowGlassPoints)];
    }
    case OperationType.DualActionLeftTop: {
      return windowView === View.Outside
        ? generateDualActionLeftTopView(windowGlassPoints)
        : generateDualActionRightTopView(windowGlassPoints);
    }
    case OperationType.DualActionRightTop: {
      return windowView === View.Outside
        ? generateDualActionRightTopView(windowGlassPoints)
        : generateDualActionLeftTopView(windowGlassPoints);
    }
    default:
      return null;
  }
};
