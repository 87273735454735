import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { UnitSystemTypes } from '@/models';
import { Group, Label, Line, Tag, Text } from 'react-konva';
import {
  ACTIVE_ELEMENT_COLOR,
  DEFAULT_ELEMENT_COLOR,
  DISABLED_ELEMENT_COLOR,
  ERROR_ELEMENT_COLOR,
  STATIC_SCALE,
  MEASUREMENT_PADDING,
} from '@/components/WindowCreator/constants';
import Konva from 'konva';
import {
  get2DAngle,
  get2DCenter,
  get2DDistance,
  rotateAroundPoint,
} from '@/shared/helpers/konva';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import { isLeftClick } from '@/shared/helpers';
import { KonvaEventObject } from 'konva/lib/Node';

import {
  FlatVector2Axis,
  MeasurementElementType,
  MeasurementUpdateData,
} from '@/components/WindowCreator/models/konva-model';
import { degToRad } from 'three/src/math/MathUtils';
import {
  addSpacesToThousands,
  convertInputValueToMm,
  removeSpacesFromThousands,
} from '@/shared/helpers/format-data';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getWindowCreatorMode,
  resetSelectedArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { WindowCreatorModes } from '@/models/shared.model';
import { MeasurementInput } from './MeasurementInput';
import { MeasurementArrow } from './MeasurementArrow';
import { MeasurementProps } from '@/models/measurements.model';
import { WINDOW_GLASS_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { getMeasurementLimits } from '@/shared/components/MeasurementLine/form.validators';
import { Html } from 'react-konva-utils';
import Form from 'antd/es/form';
import { useForm, useWatch } from 'antd/es/form/Form';

import {
  ALLOWED_ERRORS_TO_UPDATE_INPUT,
  limitsValidator,
} from '@/shared/form/validators';
import { Rule } from 'antd/es/form';
import Vector2d = Konva.Vector2d;
import { useUnmount } from 'react-use';
import { setMeasurementErrors } from '@/store/slices/sharedSlice';
import useHandleError from '@/shared/hooks/useHandleError';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';

const TEXT_PADDING = 4;
const FONT_SIZE = 12;

export interface SingleMeasurementProps extends MeasurementProps {
  points: FlatVector2Axis;
  onMeasurementSubmit?: (data: MeasurementUpdateData) => void;
  onEscape?: () => void;
  onChange?: (distance: number) => void;
  forceActive?: boolean;
  listening?: boolean;
  limits?: { min: number; max: number };
  customErrorMessage?: string;
}
export const Measurement = ({
  points,
  scale,
  disabled,
  units,
  type,
  onMeasurementSubmit,
  level = 1,
  forcedAngleDeg,
  forceActive,
  listening = true,
  limits,
  onEscape,
  onChange,
  customErrorMessage,
}: SingleMeasurementProps) => {
  const dispatch = useAppDispatch();
  const [hasError, setHasError] = useState(false);

  const selectionMode =
    useAppSelector(getWindowCreatorMode) === WindowCreatorModes.Selection;
  const [measurementPoints, setMeasurementPoints] =
    useState<FlatVector2Axis>(points);
  const measurementRef = useRef<Konva.Group>(null!);
  const [measurementPadding, setMeasurementPadding] =
    useState(MEASUREMENT_PADDING);
  const { handleError } = useHandleError();
  const labelRef = useRef<Konva.Label>(null!);
  const [hovered, setHovered] = useState(false);
  const [active, setActive] = useState(false);
  const [absolute, setAbsolute] = useState<Vector2d>({ y: 0, x: 0 });
  const [distance, setDistance] = useState<number>(get2DDistance(...points));
  const isImperialUnits = units === UnitSystemTypes.Imperial;
  const config = useFetchWindowConfigQuery(undefined, {
    skip: !!disabled,
  }).data;

  useEffect(() => {
    if (forceActive === false) {
      resetMeasurementLine();
    }
    if (forceActive) {
      handleActiveLabel();
    }
  }, [forceActive]);

  useUnmount(() => {
    dispatch(setMeasurementErrors([]));
  });

  useEffect(() => {
    setMeasurementPadding(
      measurementPadding > 0
        ? MEASUREMENT_PADDING * level
        : -MEASUREMENT_PADDING * level
    );
  }, [level]);

  const measurementLineCenter = useMemo(
    () => get2DCenter(...measurementPoints),
    [measurementPoints]
  );

  useEffect(() => {
    setDistance(get2DDistance(...points));
  }, [points]);
  const [form] = useForm<{ size: string }>();
  useWatch('size', form);

  useEffect(() => {
    labelRef?.current &&
      (absolute.x !== labelRef?.current?.absolutePosition().x ||
        absolute.y !== labelRef?.current?.absolutePosition().y) &&
      setAbsolute(labelRef?.current?.absolutePosition());
  }, [labelRef?.current?.absolutePosition()]);

  const [labelParams, setLabelParams] = useState({
    x: 0,
    y: 0,
  });

  useEffect(() => {
    points[0][1] > points[1][1] && setMeasurementPadding(-measurementPadding);
  }, []);

  const measurementRotationAngle = useMemo(() => {
    return (
      forcedAngleDeg ??
      get2DAngle(points[0], points[1], [points[1][0] + 100, points[1][1]])
    );
  }, [points]);

  useEffect(() => {
    const paddingMultiplierXCoefficient = Math.round(
      Math.sin(degToRad(measurementRotationAngle))
    );
    const paddingMultiplierYCoefficient = 1 - paddingMultiplierXCoefficient;

    const updatedMeasurementPoints: FlatVector2Axis = [
      [
        points[0][0] +
          (measurementPadding / scale) * paddingMultiplierXCoefficient,
        points[0][1] -
          (measurementPadding / scale) * paddingMultiplierYCoefficient,
      ],
      [
        points[1][0] +
          (measurementPadding / scale) * paddingMultiplierXCoefficient,
        points[1][1] -
          (measurementPadding / scale) * paddingMultiplierYCoefficient,
      ],
    ];

    setMeasurementPoints(updatedMeasurementPoints);
  }, [measurementRotationAngle, measurementPadding, points]);

  const textRotationAngle = useMemo(() => {
    const angle = measurementRotationAngle;
    if (angle >= 0 && angle < 90) {
      return angle;
    }
    if (angle >= 90 && angle < 180) {
      return 360 - angle;
    }
    if (angle >= 180 && angle < 270) {
      return 180 - angle;
    }
    return angle;
  }, [measurementRotationAngle]);

  const onMouseEnter = () => {
    !disabled && !hovered && selectionMode && setHovered(true);
  };

  const handleActiveLabel = () => {
    dispatch(resetSelectedArea());
    setHovered(false);

    rotateAroundPoint(
      // Label being inherited from Shape, however seems types cannot track it properly
      labelRef.current as unknown as Konva.Shape,
      0,
      [measurementLineCenter[0], measurementLineCenter[1]],
      labelParams.x,
      labelParams.y
    );
    setActive(true);
  };

  const onMouseClick = (event: KonvaEventObject<MouseEvent>) => {
    if (disabled || active || !isLeftClick(event.evt) || !selectionMode) return;
    //must be before setActive to avoid losing focus in measurement input
    handleActiveLabel();
  };

  const onMouseLeave = () => {
    setHovered(false);
  };

  useEffect(() => {
    setHasError(!!customErrorMessage || form.getFieldError('size').length > 0);
  }, [customErrorMessage, form.getFieldsError()]);

  useEffect(() => {
    if (!active) {
      form.setFieldValue(
        'size',
        addSpacesToThousands(
          !isImperialUnits
            ? distance.toString()
            : convertMillimetersToFtInch(distance),
          isImperialUnits
        )
      );
    }
    form.validateFields();
  }, [distance, isImperialUnits, active]);

  useLayoutEffect(() => {
    if (labelRef.current) {
      setLabelParams({
        x: measurementLineCenter[0] - (labelRef.current?.getWidth() || 0) / 2,
        y: measurementLineCenter[1] - (labelRef.current?.getHeight() || 0) / 2,
      });
    }
  }, [scale, form.getFieldValue('size'), measurementLineCenter]);

  const resetInputToInitialState = () => {
    resetMeasurementLine();
    form.setFieldValue(
      'size',
      addSpacesToThousands(
        !isImperialUnits
          ? distance.toString()
          : convertMillimetersToFtInch(distance),
        isImperialUnits
      )
    );
  };

  useEffect(() => {
    if (labelRef.current && labelParams.x !== 0 && !active) {
      rotateMeasurementLabel(textRotationAngle);
    }
  }, [labelParams]);

  const lineColor = useMemo(() => {
    if (!active) return DEFAULT_ELEMENT_COLOR;
    if (hasError) return ERROR_ELEMENT_COLOR;
    if (disabled) return DISABLED_ELEMENT_COLOR;
    return ACTIVE_ELEMENT_COLOR;
  }, [active, disabled, hasError]);

  const textColor = useMemo(() => {
    if (disabled) return DISABLED_ELEMENT_COLOR;
    return DEFAULT_ELEMENT_COLOR;
  }, [disabled]);

  const handleEscape = () => {
    resetInputToInitialState();
    dispatch(setMeasurementErrors([]));
    onEscape && onEscape();
  };

  const handleMeasurementChange = async (val: string) => {
    try {
      form.setFieldValue('size', val);
      await form.validateFields();
      dispatch(setMeasurementErrors([]));
      onChange && onChange(convertDistance(val));
    } catch (err: unknown) {
      if (
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-expect-error
        (err?.errorFields?.[0].errors as string[])?.every((err) =>
          ALLOWED_ERRORS_TO_UPDATE_INPUT.some(
            (allowedError) => allowedError === err
          )
        )
      ) {
        onChange && onChange(convertDistance(val));
      } else {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        const measurementErrors = // @ts-expect-error
          (err?.errorFields?.[0].errors as string[]).filter(
            (err) => !ALLOWED_ERRORS_TO_UPDATE_INPUT.includes(err)
          );

        dispatch(setMeasurementErrors(measurementErrors));
        setHasError(true);
        handleError(err);
      }
    }
  };

  const rotateMeasurementLabel = (angle: number) => {
    rotateAroundPoint(
      // Label being inherited from Shape, however seems types cannot track it properly
      labelRef.current as unknown as Konva.Shape,
      angle,
      [measurementLineCenter[0], measurementLineCenter[1]],
      labelParams.x,
      labelParams.y
    );
  };

  const resetMeasurementLine = () => {
    setHovered(false);
    setActive(false);
    rotateMeasurementLabel(textRotationAngle);
  };

  const getDefaultTagColor = (): string => {
    switch (type) {
      case MeasurementElementType.MullionPlacement:
        return WINDOW_GLASS_COLOR;
      case MeasurementElementType.WindowDistance:
      case MeasurementElementType.GridDistance:
        return '#FFFFFF';

      default:
        return '#F2F3F3';
    }
  };

  const convertDistance = (textDistance: string) => {
    const distance = removeSpacesFromThousands(
      textDistance,
      units === UnitSystemTypes.Imperial
    );
    return convertInputValueToMm(distance, units === UnitSystemTypes.Imperial);
  };

  const handleSubmit = () => {
    if (!hasError) {
      onMeasurementSubmit &&
        onMeasurementSubmit({
          pointsToUpdate: points,
          distance: convertDistance(form.getFieldValue('size')),
          type,
        });

      resetMeasurementLine();
    }
  };

  const getRules = () => {
    const rules: Rule[] = [];
    if (type === MeasurementElementType.Information) return rules;
    if (!limits && config) {
      rules.push(
        limitsValidator({
          ...getMeasurementLimits(points, type, config),
          isImperialUnits,
          decimalPlaces: 2,
        })
      );
    } else if (limits) {
      rules.push(
        limitsValidator({
          min: limits.min,
          max: limits.max,
          isImperialUnits,
          decimalPlaces: 2,
        })
      );
    }

    customErrorMessage &&
      rules.push({ validator: () => Promise.reject(customErrorMessage) });
    return rules;
  };

  return (
    <Group
      onPointerEnter={onMouseEnter}
      onPointerLeave={onMouseLeave}
      onClick={onMouseClick}
      listening={listening}
      ref={measurementRef}
    >
      <MeasurementArrow
        scale={scale}
        color={lineColor}
        angle={measurementRotationAngle}
        point={measurementPoints[0]}
      />
      <MeasurementArrow
        scale={scale}
        angle={measurementRotationAngle}
        color={lineColor}
        point={measurementPoints[1]}
      />
      {!disabled && active && (
        <Html
          divProps={{
            style: {
              position: 'absolute',
              top: `${absolute.y}px`,
              left: `${absolute.x}px`,
              scale: 1 / scale,
              transform: `translate(0px, 0px) rotate(0deg) scaleX(${scale}) scaleY(${scale})`,
              width: `${(labelRef.current?.getWidth() || 0) * scale}px`,
            },
          }}
        >
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item name="size" noStyle rules={getRules()}>
              {active && (
                <MeasurementInput
                  onEscape={handleEscape}
                  units={units}
                  onMeasurementChange={handleMeasurementChange}
                ></MeasurementInput>
              )}
            </Form.Item>
          </Form>
        </Html>
      )}

      <Line
        hitStrokeWidth={FONT_SIZE}
        points={[...measurementPoints[0], ...measurementPoints[1]].flat()}
        stroke={lineColor}
        strokeScaleEnabled={false}
        strokeWidth={STATIC_SCALE * 2}
      ></Line>
      <Label
        ref={labelRef}
        x={labelParams.x}
        y={labelParams.y}
        opacity={active ? 0 : 1}
      >
        <Tag
          strokeScaleEnabled={false}
          strokeWidth={1}
          stroke={hovered || active ? '#65BD51' : ''}
          cornerRadius={6 / scale}
          fill={getDefaultTagColor()}
        ></Tag>
        <Text
          fontFamily="Rubik"
          fill={textColor}
          padding={TEXT_PADDING / scale}
          text={form.getFieldValue('size')}
          lineHeight={(FONT_SIZE / 2) * STATIC_SCALE}
          fontSize={FONT_SIZE / scale}
        ></Text>
      </Label>
    </Group>
  );
};
