import React, { useEffect } from 'react';
import { useParams } from 'react-router';

import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { setBuildingFacadeData } from '@/store/slices/canvasBuildingSlice';

import { IntusLoader } from '@/shared/elements';
import SvgPage from '@/components/SvgDesigner/SvgPage';

import { useAppDispatch } from '@/store/hooks';
import { useFacadeData } from '@/shared/hooks/useFacadeData';

const SvgDesignerPage = () => {
  const { id } = useParams();

  if (!id) return <></>;

  const areUnitsFetching = useGetAllPanelsQuery(id).isFetching;
  const isConfigFetching = useFetchWindowConfigQuery().isFetching;
  const { data: project, isFetching: isProjectFetching } =
    useFetchProjectQuery(id);

  const dispatch = useAppDispatch();
  const { getBuildingFacadesData } = useFacadeData();

  useEffect(() => {
    const buildingFacadesData = getBuildingFacadesData();
    buildingFacadesData && dispatch(setBuildingFacadeData(buildingFacadesData));
  }, [project]);

  const isProjectLoading =
    areUnitsFetching || isConfigFetching || isProjectFetching;

  return (
    <>
      {!isProjectLoading && <SvgPage />}
      <IntusLoader loading={isProjectLoading} />
    </>
  );
};

export default SvgDesignerPage;
