import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const UTCStraightIcon: React.FC<BaseIconProps> = ({ fill = '#fff' }) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20098_2051)">
        <path
          d="M12.3376 10.9536L4.93762 14.7741C4.73795 14.8772 4.5 14.7322 4.5 14.5075V4.31292C4.5 4.20069 4.56265 4.09784 4.66238 4.04635L12.0624 0.225933C12.2621 0.122847 12.5 0.267789 12.5 0.492502V10.6871C12.5 10.7993 12.4374 10.9021 12.3376 10.9536Z"
          stroke={fill}
          strokeLinejoin="round"
        />
        <path
          d="M12.3376 10.9536L4.93762 14.7741C4.73795 14.8772 4.5 14.7322 4.5 14.5075V4.31292C4.5 4.20069 4.56265 4.09784 4.66238 4.04635L12.0624 0.225933C12.2621 0.122847 12.5 0.267789 12.5 0.492502V10.6871C12.5 10.7993 12.4374 10.9021 12.3376 10.9536Z"
          stroke={fill}
          strokeOpacity="0.2"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
