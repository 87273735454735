import React, { useState } from 'react';
import IntusPopover from '@/shared/elements/Popover/Popover';
import InfoIcon from '@/shared/icons/InfoIcon';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { UnitSystemTypes, UserBuildingPanel } from '@/models';
import IguConfiguratorView from '../IguConfiguratorView';
import { useUnitProperties } from '@/shared/hooks/useUnitProperties';
import {
  convertMillimetersToFtInch,
  convertSquareMetersToSquareFeet,
} from '@/shared/helpers/distance';
import { getAreaUnit } from '@/shared/helpers/format-data';
import { getMaterialImage } from '@/components/WindowCreator/helpers/config';
import IntusTooltip from '../Tooltip/Tooltip';
import { FillerType } from '@/components/UTC/models';
import { UI_INDEX } from '@/shared/constants';
import { TooltipPlacement } from 'antd/es/tooltip';
import IntusIconButton from '../IconButton/IconButton';

interface UnitCardPopoverProps {
  unitData: UserBuildingPanel;
  disabled: boolean;
  popoverPlacement: TooltipPlacement;
}

const UnitCardPopover: React.FC<UnitCardPopoverProps> = ({
  unitData,
  disabled,
  popoverPlacement,
}) => {
  const { id } = useParams();
  const [isOpened, setIsOpened] = useState(false);
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const {
    unitUsageAmount,
    sideUnitWidth,
    unitHeight,
    unitWidth,
    unitArea,
    sideUnitArea,
    iguArea,
    sideIguArea,
    innerColor,
    sideInnerColor,
    insideFillerColorsData,
    outerColor,
    sideOuterColor,
    outsideFillerColorsData,
    usedIguIds,
    outsideWindowFrameColorsData,
    insideWindowFrameColorsData,
    filler,
  } = useUnitProperties(unitData);
  const isCorner = unitData.cornerSide;

  const popoverContent = (
    <div>
      <div className=" box-content p-3 min-w-70 max-h-100 overflow-auto flex flex-col gap-1">
        <div className="flex justify-between">
          <span className="text-xs font-light leading-5">
            Instances in the model
          </span>
          <span className="text-xs font-normal leading-5">
            {unitUsageAmount}
          </span>
        </div>
        <div className="flex justify-between">
          <span className="text-xs font-light leading-5">Height</span>
          <div className="flex gap-1">
            <span className="text-xs font-normal leading-5">
              {isImperialUnits
                ? convertMillimetersToFtInch(unitHeight)
                : unitHeight}
            </span>
            {!isImperialUnits ? (
              <span className="font-light text-black-40 leading-5">mm</span>
            ) : null}
          </div>
        </div>
        {unitData.cornerSide && (
          <div className="flex justify-between">
            <span className="text-xs font-light leading-5">
              Right wing width
            </span>
            <div className="flex gap-1">
              <span className="text-xs font-normal leading-5">
                {isImperialUnits
                  ? convertMillimetersToFtInch(sideUnitWidth)
                  : sideUnitWidth}
              </span>
              {!isImperialUnits ? (
                <span className="font-light text-black-40 leading-5">mm</span>
              ) : null}
            </div>
          </div>
        )}
        <div className="flex justify-between">
          <span className="text-xs font-light leading-5">
            {unitData.cornerSide ? 'Left wing width' : 'Width'}
          </span>
          <div className="flex gap-1">
            <span className="text-xs font-normal leading-5">
              {isImperialUnits
                ? convertMillimetersToFtInch(unitWidth)
                : unitWidth}
            </span>
            {!isImperialUnits ? (
              <span className="font-light text-black-40 leading-5">mm</span>
            ) : null}
          </div>
        </div>
        <div className="flex justify-between">
          <span className="text-xs font-light leading-5">Area</span>
          <div className="flex gap-1">
            {isCorner && sideUnitArea ? (
              <span className="text-xs font-normal leading-5">
                {isImperialUnits
                  ? convertSquareMetersToSquareFeet(sideUnitArea + unitArea)
                  : (sideUnitArea + unitArea).toFixed(2)}
              </span>
            ) : (
              <span className="text-xs font-normal leading-5">
                {isImperialUnits
                  ? convertSquareMetersToSquareFeet(unitArea)
                  : unitArea.toFixed(2)}
              </span>
            )}
            <span className="font-light text-black-40 leading-5">
              {getAreaUnit(isImperialUnits)}
            </span>
          </div>
        </div>
        <div className="flex justify-between">
          <span className="text-xs font-light leading-5">IGU Area</span>
          <div className="flex gap-1">
            {isCorner && sideIguArea ? (
              <span className="text-xs font-normal leading-5">
                {isImperialUnits
                  ? convertSquareMetersToSquareFeet(sideIguArea + iguArea)
                  : (sideIguArea + iguArea).toFixed(2)}
              </span>
            ) : (
              <span className="text-xs font-normal leading-5">
                {isImperialUnits
                  ? convertSquareMetersToSquareFeet(iguArea)
                  : iguArea.toFixed(2)}
              </span>
            )}
            <span className="font-light text-black-40 leading-5">
              {getAreaUnit(isImperialUnits)}
            </span>
          </div>
        </div>
        <div className="flex items-center justify-between gap-1">
          <div className="text-xs font-light leading-5 mb-1">
            {isCorner
              ? 'Outside frame color  - Left wing'
              : 'Outside frame color'}
          </div>
          <IntusTooltip
            placement={'bottom'}
            title={outerColor.description}
            zIndex={UI_INDEX.OVERLAY_TOOLTIP}
          >
            <div
              className={`rounded-full w-6 h-6 outline outline-solid outline-light-gray-20 ml-[-12px] hover:z-100 hover:outline-light-green-100`}
              style={{ backgroundColor: outerColor.hex }}
            />
          </IntusTooltip>
        </div>
        {isCorner && sideOuterColor && (
          <div className="flex items-center justify-between gap-1">
            <div className="text-xs font-light leading-5 mb-1">
              Outside frame color - Right wing
            </div>
            <IntusTooltip
              placement={'bottom'}
              title={sideOuterColor.description}
              zIndex={UI_INDEX.OVERLAY_TOOLTIP}
            >
              <div
                className={`rounded-full w-6 h-6 outline outline-solid outline-light-gray-20 ml-[-12px] hover:z-100 hover:outline-light-green-100`}
                style={{ backgroundColor: sideOuterColor.hex }}
              />
            </IntusTooltip>
          </div>
        )}
        <div className="flex items-center justify-between gap-1">
          <div className="text-xs font-light leading-5 mb-1">
            {isCorner
              ? 'Inside frame color  - Left wing'
              : 'Inside frame color'}
          </div>
          <IntusTooltip
            placement={'bottom'}
            title={innerColor.description}
            zIndex={UI_INDEX.OVERLAY_TOOLTIP}
          >
            <div
              className={`rounded-full w-6 h-6 outline outline-solid outline-light-gray-20 ml-[-12px] hover:z-100 hover:outline-light-green-100`}
              style={{ backgroundColor: innerColor.hex }}
            />
          </IntusTooltip>
        </div>
        {isCorner && sideInnerColor && (
          <div className="flex items-center justify-between gap-1">
            <div className="text-xs font-light leading-5">
              Inside frame color - Right wing
            </div>
            <IntusTooltip
              placement={'bottom'}
              title={sideInnerColor.description}
              zIndex={UI_INDEX.OVERLAY_TOOLTIP}
            >
              <div
                className={`rounded-full w-6 h-6 outline outline-solid outline-light-gray-20 ml-[-12px] hover:z-100 hover:outline-light-green-100`}
                style={{ backgroundColor: sideInnerColor.hex }}
              />
            </IntusTooltip>
          </div>
        )}
        {!!outsideFillerColorsData.length && (
          <div className="flex items-center justify-between gap-1">
            <div className="text-xs font-light leading-5">
              Outside filler color
            </div>
            <div className="flex items-center">
              {outsideFillerColorsData.map((color, index) => (
                <IntusTooltip
                  key={index}
                  placement={'bottom'}
                  title={color[1]}
                  zIndex={UI_INDEX.OVERLAY_TOOLTIP}
                >
                  <div
                    className={`rounded-full w-6 h-6 outline outline-solid outline-light-gray-20 ml-[-12px] hover:z-100 hover:outline-light-green-100`}
                    style={{ backgroundColor: color[0] }}
                  />
                </IntusTooltip>
              ))}
            </div>
          </div>
        )}
        {!!insideFillerColorsData.length && (
          <div className="flex items-center justify-between gap-2">
            <div className="text-xs font-light leading-5">
              Inside filler color
            </div>
            <div className="flex items-center">
              {insideFillerColorsData.map((color, index) => (
                <IntusTooltip
                  key={index}
                  placement={'bottom'}
                  title={color[1]}
                  zIndex={UI_INDEX.OVERLAY_TOOLTIP}
                >
                  <div
                    className={`rounded-full w-6 h-6 outline outline-solid outline-light-gray-20 ml-[-12px] hover:z-100 hover:outline-light-green-100`}
                    style={{ backgroundColor: color[0] }}
                  />
                </IntusTooltip>
              ))}
            </div>
          </div>
        )}
        {!!outsideWindowFrameColorsData.length && (
          <div className="flex items-center justify-between gap-2">
            <div className="text-xs font-light leading-5">
              Outside window frame color
            </div>
            <div className="flex items-center">
              {outsideWindowFrameColorsData.map((color, index) => (
                <IntusTooltip
                  key={index}
                  placement={'bottom'}
                  title={color[1]}
                  zIndex={UI_INDEX.OVERLAY_TOOLTIP}
                >
                  <div
                    className={`rounded-full w-6 h-6 outline outline-solid outline-light-gray-20 ml-[-12px] hover:z-100 hover:outline-light-green-100`}
                    style={{ backgroundColor: color[0] }}
                  />
                </IntusTooltip>
              ))}
            </div>
          </div>
        )}
        {!!insideWindowFrameColorsData.length && (
          <div className="flex items-center justify-between gap-1">
            <div className="text-xs font-light leading-5">
              Inside window frame color
            </div>
            <div className="flex items-center">
              {insideWindowFrameColorsData.map((color, index) => (
                <IntusTooltip
                  key={index}
                  placement={'bottom'}
                  title={color[1]}
                  zIndex={UI_INDEX.OVERLAY_TOOLTIP}
                >
                  <div
                    className={`rounded-full w-6 h-6 outline outline-solid outline-light-gray-20 ml-[-12px] hover:z-100 hover:outline-light-green-100`}
                    style={{ backgroundColor: color[0] }}
                  />
                </IntusTooltip>
              ))}
            </div>
          </div>
        )}
        <div className="flex flex-col gap-1 mb-1">
          <div className="text-xs font-light leading-5">Filler type</div>
          <div className="flex items-center gap-2 py-1">
            {getMaterialImage(filler.type)}
            <p className="font-normal text-xs leading-5 m-0">
              {filler.description}
            </p>
          </div>
        </div>
        {usedIguIds.length > 0 && filler.type !== FillerType.Aluminium && (
          <div className={'text-xs -mx-3'}>
            <IguConfiguratorView
              iguIds={usedIguIds}
              paddingValue={3}
              placement={'left'}
            />
          </div>
        )}
      </div>
    </div>
  );

  const popoverTitle = (
    <div className="py-2 pl-3">
      <span className="text-[11px] font-medium leading-[16px]">
        Unit properties
      </span>
    </div>
  );

  const handleOnOpenChange = (state: boolean) => {
    if (disabled) return;

    setIsOpened(state);
  };

  return (
    <IntusPopover
      arrow={true}
      placement={popoverPlacement}
      title={popoverTitle}
      content={popoverContent}
      open={isOpened}
      onOpenChange={handleOnOpenChange}
    >
      <div
        className={`flex justify-center items-center p-0 m-0 border-0 bg-transparent`}
      >
        <IntusIconButton
          simplified
          transparent
          id="unit-card__popover-info"
          size="middle"
          className={`p-0! w-3! ${!disabled ? 'cursor-default!' : 'cursor-not-allowed!'}`}
          icon={<InfoIcon fill={isOpened ? '#65BD51' : '#CCCDCE'} />}
        />
      </div>
    </IntusPopover>
  );
};

export default UnitCardPopover;
