import { configureStore } from '@reduxjs/toolkit';
import { projectsApi } from '@/store/apis/projectsApi';
import { setupListeners } from '@reduxjs/toolkit/query';
import { userApi } from '@/store/apis/userApi';
import { canvasReducer } from '@/store/slices';
import {
  apiStatusMiddleware,
  rtkQueryErrorHandler,
} from '@/middlewares/error-handler';
import { windowApi } from '@/store/apis/windowApi';
import { windowsReducer } from '@/store/slices/windowsReducer';
import { blueScreenApi } from '@/store/apis/blueScreenApi';
import { undoBuildingDelete } from '@/middlewares/undoBuildingDelete';
import { webCalcApi } from '@/store/apis/webCalcApi';
import { unitApi } from '@/store/apis/unitApi';
import { createReduxEnhancer } from '@sentry/react';
import { sharedApi } from '@/store/apis/sharedApi';

const sentryReduxEnhancer = createReduxEnhancer();

const store = configureStore({
  reducer: {
    [projectsApi.reducerPath]: projectsApi.reducer,
    [userApi.reducerPath]: userApi.reducer,
    [windowApi.reducerPath]: windowApi.reducer,
    [unitApi.reducerPath]: unitApi.reducer,
    [webCalcApi.reducerPath]: webCalcApi.reducer,
    [blueScreenApi.reducerPath]: blueScreenApi.reducer,
    [sharedApi.reducerPath]: sharedApi.reducer,
    canvasReducer: canvasReducer,
    windowsReducer: windowsReducer,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(projectsApi.middleware)
      .concat(userApi.middleware)
      .concat(windowApi.middleware)
      .concat(unitApi.middleware)
      .concat(webCalcApi.middleware)
      .concat(blueScreenApi.middleware)
      .concat(sharedApi.middleware)
      .concat(undoBuildingDelete.middleware)
      .concat(apiStatusMiddleware)
      .concat(rtkQueryErrorHandler),

  enhancers: (getDefaultEnhancers) =>
    getDefaultEnhancers().concat(sentryReduxEnhancer),
});

setupListeners(store.dispatch);

export { store };

export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
