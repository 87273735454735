import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import ConfigProvider from 'antd/es/config-provider';
import { StyleProvider } from '@ant-design/cssinjs';

import { store } from '@/store';
import { Provider } from 'react-redux';
import { initMapBox } from '@/store/actions/initActions';
import Auth0ProviderWithRedirectHistory from '@/core/Auth0ProviderWithRedirectHistory';
import { BrowserRouter } from 'react-router-dom';
import { initSentry } from '@/core/sentry-init';

store.dispatch(initMapBox());

initSentry();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <Auth0ProviderWithRedirectHistory>
      <StyleProvider layer>
        <ConfigProvider
          theme={{ hashed: false, token: { fontFamily: 'Rubik' } }}
          wave={{ disabled: true }}
        >
          <Provider store={store}>
            <App />
          </Provider>
        </ConfigProvider>
      </StyleProvider>
    </Auth0ProviderWithRedirectHistory>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
