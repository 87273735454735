import React, { MutableRefObject } from 'react';

import useCopyPasteHandlers from '@/components/FacadeDesigner/elements/handlers/useCopyPasteHandlers';
import { useDragNDropCopyHandlers } from '@/components/FacadeDesigner/elements/handlers/useDragNDropCopyHandlers';
import { useParams } from 'react-router';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import { useAppSelector } from '@/store/hooks';
import {
  getIsOnlyOnePanelSelected,
  getSelectedPlacedPanels,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { Stage } from 'konva/lib/Stage';
import { isEqual } from 'lodash';

interface FacadeDesignerActionHandlersProps {
  stageRef: MutableRefObject<Stage>;
}

const FacadeDesignerActionHandlers: React.FC<
  FacadeDesignerActionHandlersProps
> = ({ stageRef }) => {
  const { id } = useParams();

  const { data: unitsData } = useGetAllPanelsQuery(id!);

  const isOnlyOnePanelSelected = useAppSelector(getIsOnlyOnePanelSelected);
  const selectedPanels = useAppSelector(getSelectedPlacedPanels);
  const selectedPanel = Object.values(selectedPanels)[0];

  useCopyPasteHandlers({
    unitsData,
    selectedPanels: Object.values(selectedPanels),
    isOnlyOnePanelSelected,
  });

  useDragNDropCopyHandlers({
    isOnlyOnePanelSelected,
    selectedUnit: selectedPanel,
    unitsData,
    stageRef,
  });

  return <></>;
};

export default React.memo(FacadeDesignerActionHandlers, isEqual);
