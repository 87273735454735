import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { SavedWindow } from '@/components/WindowCreator/models/konva-model';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  GridLineDataForFD,
  PanelPlacementDataForFD,
  WindowPlacementDataForFD,
} from '@/components/FacadeDesigner/models';

export enum FacadeDesignerPlacementType {
  Window = 'window',
  GridLine = 'gridLine',
}

interface PlacementError {
  state: boolean;
  message?: string;
}

export interface SnapZone {
  snapZone: number[];
  snapTo: number;
}

interface FacadeDesignerState {
  modifiedWalls: string[]; // GUID's
  selectedWindowFromLibrary?: SavedWindow;
  selectedWindowPosition?: { x: number; y: number };
  facadeDesignerMode: FacadeDesignerModes;
  selectedPlacedWindow: WindowPlacementDataForFD[];
  hoveredPlacedWindow: WindowPlacementDataForFD | null;
  selectedGridLines: GridLineDataForFD[];
  hoveredGridLine: GridLineDataForFD | null;
  selectedPlacedPanels: { [guid: string]: PanelPlacementDataForFD };
  hoveredPlacedPanels: { [guid: string]: PanelPlacementDataForFD };

  draggedWindowFromLibrary?: SavedWindow;
  gridPlacementOffset?: number | null;
  // Wall, which currently has Active MultiMeasurement input (Active === user can input something)
  measurementActiveWall: string | null;
  // Wall, which is currently hovered by user
  hoveredWall: string | null;

  placementErrors: {
    // Key: WallGUID
    [key: string]: PlacementError;
  };

  dragNode: FacadeDesignerPlacementType | null;
  dragCopyOffset?: number;
  gridSnapZones: SnapZone[];
}

const initialState: FacadeDesignerState = {
  modifiedWalls: [],
  selectedPlacedWindow: [],
  hoveredPlacedWindow: null,
  hoveredWall: null,
  hoveredGridLine: null,
  selectedGridLines: [],
  selectedPlacedPanels: {},
  hoveredPlacedPanels: {},
  measurementActiveWall: null,
  facadeDesignerMode: FacadeDesignerModes.Selection,
  placementErrors: {},
  dragNode: null,
  gridSnapZones: [],
};

export const facadeDesignerSlice = createSlice({
  name: 'facadeDesigner',
  initialState,
  reducers: {
    setSelectedWindowFromLibrary: (
      state,
      action: PayloadAction<SavedWindow>
    ) => {
      state.selectedWindowFromLibrary = action.payload;
    },
    resetFacadeDesignerSlice: () => initialState,
    resetSelectedWindowFromLibrary: (state) => {
      state.selectedWindowFromLibrary = undefined;
    },
    addSelectedPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementDataForFD>
    ) => {
      state.selectedPlacedWindow.push(action.payload);
    },
    removeFromSelectedPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementDataForFD>
    ) => {
      state.selectedPlacedWindow = state.selectedPlacedWindow.filter(
        (window) => window.guid !== action.payload.guid
      );
    },
    setSelectedPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementDataForFD[]>
    ) => {
      state.selectedPlacedWindow = action.payload;
    },
    setModifiedWalls: (state, action: PayloadAction<string[]>) => {
      state.modifiedWalls = action.payload;
    },
    setHoveredWall: (state, action: PayloadAction<string | null>) => {
      state.hoveredWall = action.payload;
    },
    setHoveredPlacedWindow: (
      state,
      action: PayloadAction<WindowPlacementDataForFD | null>
    ) => {
      state.hoveredPlacedWindow = action.payload;
    },
    setFacadeDesignerMode: (
      state,
      action: PayloadAction<FacadeDesignerModes>
    ) => {
      state.facadeDesignerMode = action.payload;
    },
    setDraggedWindowFromLibrary: (
      state,
      action: PayloadAction<SavedWindow | undefined>
    ) => {
      state.draggedWindowFromLibrary = action.payload;
    },

    setGridPlacementAbsoluteOffset: (
      state,
      action: PayloadAction<number | null>
    ) => {
      state.gridPlacementOffset = action.payload;
    },
    setSelectedGridLines: (
      state,
      action: PayloadAction<GridLineDataForFD[]>
    ) => {
      state.selectedGridLines = action.payload;
    },
    addToSelectedGridLines: (
      state,
      action: PayloadAction<GridLineDataForFD>
    ) => {
      state.selectedGridLines.push(action.payload);
    },
    removeFromSelectedGridLines: (
      state,
      action: PayloadAction<GridLineDataForFD>
    ) => {
      state.selectedGridLines = state.selectedGridLines.filter(
        (gridLine) => gridLine.absoluteOffset !== action.payload.absoluteOffset
      );
    },
    resetSelectedGridLines: (state) => {
      state.selectedGridLines = [];
    },
    setMeasurementActiveWall: (state, action: PayloadAction<string | null>) => {
      state.measurementActiveWall = action.payload;
    },

    setPlacementError: (
      state,
      action: PayloadAction<{ key: string; state: boolean; message?: string }>
    ) => {
      state.placementErrors[action.payload.key] = {
        state: action.payload.state,
        message: action.payload.message,
      };
    },
    resetPlacementErrors: (state) => {
      state.placementErrors = {};
    },

    setHoveredGridLine: (
      state,
      action: PayloadAction<GridLineDataForFD | null>
    ) => {
      state.hoveredGridLine = action.payload;
    },
    setSelectedPlacedPanel: (
      state,
      { payload }: PayloadAction<PanelPlacementDataForFD>
    ) => {
      state.selectedPlacedPanels = { [payload.guid]: payload };
    },
    addToSelectedPlacedPanel: (
      state,
      { payload }: PayloadAction<PanelPlacementDataForFD>
    ) => {
      state.selectedPlacedPanels = {
        ...state.selectedPlacedPanels,
        [payload.guid]: payload,
      };
    },
    updateSelectedPanelId: (
      state,
      { payload }: PayloadAction<{ panelGuid: string; panelId: number }>
    ) => {
      if (state.selectedPlacedPanels[payload.panelGuid]) {
        state.selectedPlacedPanels[payload.panelGuid] = {
          ...state.selectedPlacedPanels[payload.panelGuid],
          panelId: payload.panelId,
        };
      }
    },
    removeFromSelectedPlacedPanels: (
      state,
      { payload }: PayloadAction<string>
    ) => {
      delete state.selectedPlacedPanels[payload];
    },
    resetSelectedPlacedPanels: (state) => {
      state.selectedPlacedPanels = {};
    },
    setHoveredPlacedPanels: (
      state,
      { payload }: PayloadAction<PanelPlacementDataForFD | null>
    ) => {
      if (!(state.dragCopyOffset && !payload)) {
        state.hoveredPlacedPanels = payload ? { [payload.guid]: payload } : {};
      }
    },
    addToHoveredPlacedPanels: (
      state,
      { payload }: PayloadAction<PanelPlacementDataForFD>
    ) => {
      state.hoveredPlacedPanels = {
        ...state.hoveredPlacedPanels,
        [payload.guid]: payload,
      };
    },
    setDragNode: (
      state,
      { payload }: PayloadAction<FacadeDesignerPlacementType | null>
    ) => {
      state.dragNode = payload;
    },
    setGridSnapZones: (state, { payload }: PayloadAction<SnapZone[]>) => {
      state.gridSnapZones = payload;
    },
    setDragCopyOffset: (
      state,
      { payload }: PayloadAction<number | undefined>
    ) => {
      state.dragCopyOffset = payload;
    },
  },
});

export const {
  setSelectedWindowFromLibrary,
  resetSelectedWindowFromLibrary,
  resetFacadeDesignerSlice,
  setSelectedPlacedWindow,
  setHoveredPlacedWindow,
  addSelectedPlacedWindow,
  setModifiedWalls,
  setFacadeDesignerMode,
  setDraggedWindowFromLibrary,
  setGridPlacementAbsoluteOffset,
  setSelectedGridLines,
  addToSelectedGridLines,
  setHoveredGridLine,
  setMeasurementActiveWall,
  setHoveredWall,
  resetSelectedGridLines,
  setPlacementError,
  resetPlacementErrors,
  setSelectedPlacedPanel,
  addToSelectedPlacedPanel,
  setHoveredPlacedPanels,
  resetSelectedPlacedPanels,
  removeFromSelectedPlacedPanels,
  setDragNode,
  removeFromSelectedPlacedWindow,
  removeFromSelectedGridLines,
  setGridSnapZones,
  setDragCopyOffset,
  addToHoveredPlacedPanels,
  updateSelectedPanelId,
} = facadeDesignerSlice.actions;

const fdSlice = (state: RootState) => state.windowsReducer.facadeDesigner;

export const getSelectedWindowFromLibrary = (state: RootState) =>
  fdSlice(state).selectedWindowFromLibrary;

export const isWindowFromLibrarySelected = (id: number) => (state: RootState) =>
  fdSlice(state).selectedWindowFromLibrary?.id === id;

export const getSelectedPlacedWindows = (state: RootState) =>
  fdSlice(state).selectedPlacedWindow;

export const getHoveredPlacedWindow = (state: RootState) =>
  fdSlice(state).hoveredPlacedWindow;

export const getModifiedWalls = (state: RootState) =>
  fdSlice(state).modifiedWalls;

export const getDraggedWindowFromLibrary = (state: RootState) =>
  fdSlice(state).draggedWindowFromLibrary;

export const getIsDraggedWindowFromLibrary = (state: RootState) =>
  !!fdSlice(state).draggedWindowFromLibrary;

export const getFacadeDesignerMode = (state: RootState) =>
  fdSlice(state).facadeDesignerMode;

export const getGridPlacementAbsoluteOffset = (state: RootState) =>
  fdSlice(state).gridPlacementOffset;

export const getSelectedGridlines = (state: RootState) =>
  fdSlice(state).selectedGridLines;

export const isGridLineSelected = (guid: string) => (state: RootState) =>
  fdSlice(state).selectedGridLines.some((line) => guid === line.guid);

export const isItemSelectedOnWall =
  (wallGUID: string) => (state: RootState) => {
    const { selectedGridLines, selectedPlacedWindow } = fdSlice(state);

    return [...selectedGridLines, ...selectedPlacedWindow].some(
      (item) => wallGUID === item.wallGUID
    );
  };

export const getHoveredGridLine = (state: RootState) =>
  fdSlice(state).hoveredGridLine;

export const getMeasurementActiveWall = (state: RootState) =>
  fdSlice(state).measurementActiveWall;

export const isWallMeasurementActive = (state: RootState) =>
  !!fdSlice(state).measurementActiveWall;

export const getHoveredWall = (state: RootState) => fdSlice(state).hoveredWall;

export const getPlacementErrors = (state: RootState) =>
  fdSlice(state).placementErrors;

export const getIsPanelHovered = (guid: string) => (state: RootState) =>
  !!fdSlice(state).hoveredPlacedPanels?.[guid];

export const getHoveredPanels = (state: RootState) =>
  fdSlice(state).hoveredPlacedPanels;

export const getIsPanelSelected = (guid: string) => (state: RootState) =>
  !!fdSlice(state).selectedPlacedPanels?.[guid];

export const getSelectedPlacedPanels = (state: RootState) =>
  fdSlice(state).selectedPlacedPanels;

export const getDragNode = (state: RootState) => fdSlice(state).dragNode;

export const getIsSomePanelHovered = (state: RootState) =>
  !!fdSlice(state).hoveredPlacedPanels;

export const getIsSomeGridLinesSelected = (state: RootState) =>
  fdSlice(state).selectedGridLines.length > 0;

export const getIsSomeWindowSelected = (state: RootState) =>
  fdSlice(state).selectedPlacedWindow.length > 0;

export const getIsOnlyOneWindowSelected = (state: RootState) =>
  fdSlice(state).selectedPlacedWindow.length === 1;

export const getIsOnlyOnePanelSelected = (state: RootState) =>
  Object.values(fdSlice(state).selectedPlacedPanels).length === 1;

export const getGridSnapZones = (state: RootState) =>
  fdSlice(state).gridSnapZones;

export const getDragCopyOffset = (state: RootState) =>
  fdSlice(state).dragCopyOffset;
