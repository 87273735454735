import { FlatVector2, UnitSystemTypes } from '@/models';
import { FlatVector2Axis, View } from '@/components/WindowCreator/models';
import { OperationType, RALColors } from '@/models/window-configurator.model';

export interface UTCMullionData {
  points: FlatVector2Axis;
}

export enum FillerType {
  Aluminium = 'Aluminium',
  Glass = 'Glass',
  EnamelGlass = 'EnamelGlass',
  OperableWindow = 'OperableWindow',
  Multiple = 'Multiple',
}

export interface UnitInnerFrame {
  points: FlatVector2[];
  operableWindowType: OperationType | null;

  //Not added yet, presented only in BE model
  image?: string;
  number: string;
  fillerType: FillerType;
  outsideColor: RALColors;
  insideColor: RALColors;
  iguId?: number;
}

export interface UTCData {
  id?: number;
  points: FlatVector2[];
  name: string;
  innerFrames: UnitInnerFrame[];
  mullions: UTCMullionData[];
  outsideColor: RALColors;
  insideColor: RALColors;
}

export interface UTCFormData extends UTCData {
  units: UnitSystemTypes;
  view: View;

  //Not added yet, presented only in BE model
  glazingBeadsColor?: number;
}
