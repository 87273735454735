import React, { useEffect, useState } from 'react';
import { Resizable } from 're-resizable';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { setMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes, NodeType, SelectedNode } from '@/models';
import { IntusIconButton } from '@/shared/elements';
import { CrossIcon, CursorIcon } from '@/shared/icons';
import { FacadeDesignerModes } from '@/models/shared.model';
import WindowPlacementIcon from '@/shared/elements/EditToolbar/icons/WindowPlacementIcon';
import FacadeDesignerContainer from '@/components/FacadeDesigner/FacadeDesignerContainer';

import TextTooltip from '@/shared/elements/TextTooltip/TextTooltip';
import escapeIcon from '@/images/EscapeIcon.svg';
import {
  getFacadeDesignerMode,
  getModifiedWalls,
  getSelectedPlacedWindows,
  resetFacadeDesignerSlice,
  setFacadeDesignerMode,
  setGridPlacementAbsoluteOffset,
  setModifiedWalls,
  setMeasurementActiveWall,
  resetPlacementErrors,
  getDragNode,
  setDragNode,
  resetSelectedWindowFromLibrary,
  setHoveredPlacedPanels,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useKey, useMount, useUnmount, useWindowSize } from 'react-use';
import { resetExternalElementsState } from '@/store/slices/canvasExternalElementsSlice';
import ShortcutTooltip from '@/shared/elements/ShortcutTooltip/ShortcutTooltip';
import shiftIcon from '@/images/shift-icon.svg';
import clickIcon from '@/images/click-icon.svg';
import tabIcon from '@/images/tab-icon.svg';
import CursorClickIcon from '@/shared/icons/CursorClickIcon';
import GridLinePlacementModeIcon from '@/shared/icons/GridLinePlacementModeIcon';
import CleanUpButton from './externalElements/CleanUpButton';
import { isEqual } from 'lodash';
import AutoGridPlacementPopover from './externalElements/AutoGridLinePlacementPopover';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { useGridLines } from '@/components/FacadeDesigner/hooks/useGridLines';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { useBuildingInfo } from '@/shared/hooks/useBuildingInfo';
import { LEFT_PANEL_DEFAULT_WIDTH } from '@/shared/constants';

interface FacadeDesignerProps {
  selectedWalls: SelectedNode[];
}

const FacadeDesigner: React.FC<FacadeDesignerProps> = ({ selectedWalls }) => {
  const dispatch = useAppDispatch();
  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const { id } = useParams();

  // as we already have selected walls guids -> user buildings should be available at the start
  const projectData = useFetchProjectQuery(id!).data!;
  const userBuildings = projectData.buildings!;

  const modifiedWalls = useAppSelector(getModifiedWalls);
  const savedPanelWidth = useFetchProjectQuery(id!).data?.layout
    ?.leftPanelWidth;
  const dragNode = useAppSelector(getDragNode);
  const leftPanelWidth = savedPanelWidth ?? LEFT_PANEL_DEFAULT_WIDTH;
  const { findDataForWall, findDataForBuilding } = useFindNodeData();
  const { generateCornerGrids, updateBuildingPlacements } = useGridLines(
    userBuildings[0].guid
  );
  const { hasCustomPlacedElements } = useBuildingInfo(userBuildings[0].guid);

  const verifyGridPlacement = (): void => {
    const building = userBuildings[0];
    const buildingWallsList = findDataForBuilding(
      building.guid
    )!.childNodes.filter((node) => node.type === NodeType.Wall);
    const hasBuildingGridLines = buildingWallsList.some(
      (wall) => !!findDataForWall(wall.guid)?.gridLines?.length
    );

    !hasBuildingGridLines &&
      generateCornerGrids(findDataForBuilding(building.guid)!);
  };

  useEffect(() => {
    dispatch(setGridPlacementAbsoluteOffset(null));
    dispatch(resetPlacementErrors());
    dispatch(setMeasurementActiveWall(null));
  }, [facadeDesignerMode]);

  // We expect building to always have corner grid lines, while we're in facade designer.
  // That's initial check on mounting the first view in facade designer
  useMount(() => verifyGridPlacement());

  useEffect(() => {
    !selectedWalls.length &&
      dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection));
    const walls = selectedWalls.map((wall) => wall.guid);
    if (isEqual(walls, modifiedWalls)) return;
    dispatch(setModifiedWalls(walls));
  }, [selectedWalls, userBuildings]);

  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const { height } = useWindowSize();

  const headerHeight: number =
    document.getElementById('project-header')?.clientHeight || 0;

  const defaultHeight = (height - headerHeight) / 2;
  const maxHeight = ((height - headerHeight) / 3) * 2;
  const minHeight = (height - headerHeight) / 3;

  const [placementHeight, setPlacementHeight] = useState(defaultHeight);

  const placementWidth = window.innerWidth - leftPanelWidth - 220;

  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      if (facadeDesignerMode !== FacadeDesignerModes.Selection) {
        return dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection));
      }
      if (dragNode) return;

      dispatch(setMode(CanvasActionsModes.selection));
      dispatch(resetFacadeDesignerSlice());
    }
  };

  const getIconFillColor = (expectedMode: FacadeDesignerModes): string => {
    return expectedMode === facadeDesignerMode ? '#fff' : '#414042';
  };

  useUnmount(() => {
    if (!hasCustomPlacedElements()) {
      updateBuildingPlacements({
        buildingGUID: userBuildings[0].guid,
        clearAll: true,
      });
    }
    dispatch(resetFacadeDesignerSlice());
  });

  useKey('Escape', onKeydown, { event: 'keydown' }, [
    facadeDesignerMode,
    selectedPlacedWindows,
    userBuildings,
    dragNode,
  ]);

  const closeFacadeDesigner = () => {
    dispatch(setMode(CanvasActionsModes.selection));
    dispatch(resetExternalElementsState());
    dispatch(resetFacadeDesignerSlice());
  };

  const isLocked = projectData.locked;

  const handlePointerOut = () => {
    if (dragNode) {
      dispatch(setDragNode(null));
      dispatch(resetSelectedWindowFromLibrary());
    }
    dispatch(setHoveredPlacedPanels(null));
  };

  return (
    <>
      <div
        className={`absolute top-0 z-10 border border-solid border-light-gray-20 border-t-0 overflow-hidden`}
        style={{
          left: leftPanelWidth,
          width: placementWidth,
        }}
        onPointerOut={handlePointerOut}
      >
        <Resizable
          enable={{
            top: false,
            right: false,
            bottom: true,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          minHeight={minHeight}
          defaultSize={{
            width: 'auto',
            height: defaultHeight,
          }}
          maxHeight={maxHeight}
          onResize={(_, __, elementRef) =>
            setPlacementHeight(elementRef.offsetHeight)
          }
        >
          <CleanUpButton selectedNodes={selectedWalls} disabled={isLocked} />
          <div className="flex justify-center absolute top-2 left-0 right-0 ml-auto mr-auto w-fit z-50">
            <IntusIconButton
              disabled={!selectedWalls[0]}
              type="default"
              className={'mx-1'}
              onClick={() =>
                dispatch(setFacadeDesignerMode(FacadeDesignerModes.Selection))
              }
              icon={
                <CursorIcon
                  fill={getIconFillColor(FacadeDesignerModes.Selection)}
                />
              }
              isActive={FacadeDesignerModes.Selection === facadeDesignerMode}
              id="facade-designer__selectionMode-button"
            />
            <IntusIconButton
              disabled={!selectedWalls[0] || isLocked}
              type="default"
              className={'mx-1'}
              onClick={() =>
                dispatch(
                  setFacadeDesignerMode(FacadeDesignerModes.WindowPlacement)
                )
              }
              icon={
                <WindowPlacementIcon
                  fill={getIconFillColor(FacadeDesignerModes.WindowPlacement)}
                />
              }
              isActive={
                FacadeDesignerModes.WindowPlacement === facadeDesignerMode
              }
              id="facade-designer__windowPlacementMode-button"
            />
            <IntusIconButton
              disabled={!selectedWalls[0] || isLocked}
              type="default"
              className={'mx-1'}
              onClick={() =>
                dispatch(
                  setFacadeDesignerMode(FacadeDesignerModes.GridLinePlacement)
                )
              }
              icon={
                <GridLinePlacementModeIcon
                  fill={getIconFillColor(FacadeDesignerModes.GridLinePlacement)}
                />
              }
              isActive={
                FacadeDesignerModes.GridLinePlacement === facadeDesignerMode
              }
              id="facade-designer__gridLinePlacementMode-button"
            />
            <AutoGridPlacementPopover
              disabled={isLocked || selectedWalls.length === 0}
              iconFillColor={getIconFillColor(
                FacadeDesignerModes.AutoGridLinePlacement
              )}
              facadeDesignerMode={facadeDesignerMode}
              selectedWalls={selectedWalls}
            />
          </div>
          <div className="absolute top-2 right-6 z-50">
            <IntusIconButton
              className="ml-auto text-white"
              id="facade-designer__close-button"
              icon={<CrossIcon />}
              onClick={closeFacadeDesigner}
            >
              Save
            </IntusIconButton>
          </div>
          <div className={'bg-light-gray-10 w-full h-full min-h-[250px]'}>
            {!!selectedWalls?.length && (
              <FacadeDesignerContainer
                isProjectLocked={isLocked}
                buildingGUID={userBuildings[0].guid}
                selectedWalls={selectedWalls}
                placementHeight={placementHeight}
                placementWidth={placementWidth}
              />
            )}
          </div>

          <div className="absolute bottom-0 right-0">
            <TextTooltip
              text={<TooltipText icon={escapeIcon} text="to exit" />}
              visible={facadeDesignerMode === FacadeDesignerModes.Selection}
            />
            <TextTooltip
              text={<TooltipText icon={tabIcon} text="to input" />}
              visible={
                facadeDesignerMode === FacadeDesignerModes.WindowPlacement ||
                facadeDesignerMode === FacadeDesignerModes.GridLinePlacement
              }
            />
          </div>
        </Resizable>
      </div>
      <ShortcutTooltip
        showOnlyOnCanvas
        hints={[
          <div key="wallsSelect" className="flex items-center gap-1 h-5">
            <img src={shiftIcon} alt="Shift icon" />
            <img src={clickIcon} alt="Click icon" />
            <span className="font-normal text-xs leading-5">
              to select multiple walls
            </span>
          </div>,
          <div key="facadeSelect" className="h-5 flex items-center gap-1">
            <img src={tabIcon} alt="Tab icon" />
            <img src={clickIcon} alt="Click icon" />
            <span className="font-normal text-xs leading-5">
              to select the facade
            </span>
          </div>,
        ]}
        footerHints={[
          <div key="selectSeparateWall" className="flex gap-1">
            <CursorClickIcon />
            <span className="text-xs leading-5 font-normal">
              Click to select separate wall
            </span>
          </div>,
        ]}
      />
    </>
  );
};

const TooltipText = ({ icon, text }: { icon: string; text: string }) => (
  <div className="py-1 px-2 flex items-center bg-white-50">
    <img src={icon} alt="tooltip-icon" className="pr-1" />
    <span className="text-xs text-dark-gray-80 leading-5">{text}</span>
  </div>
);

export default FacadeDesigner;
