import React from 'react';

const UTCInnerCornerUnitSettings = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2549 0.569855C10.1097 0.483792 9.93082 0.476825 9.77934 0.551328L2.94694 3.91164C2.67333 4.0462 2.5 4.3246 2.5 4.62951V15.0215C2.5 15.6139 3.12147 16.0008 3.65307 15.7393L7.24631 13.9721C7.13553 13.652 7.05987 13.3154 7.02395 12.9671L3.5 14.7002V4.75403L9.5 1.80311V8.46776C9.81426 8.31159 10.1494 8.19107 10.5 8.11151V1.8775L12.5 3.06269V8.11151C12.8507 8.19108 13.1858 8.31159 13.5 8.46776V2.94873C13.5 2.66616 13.3509 2.40455 13.1078 2.2605L10.2549 0.569855Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2549 0.569855C10.1097 0.483792 9.93082 0.476825 9.77934 0.551328L2.94694 3.91164C2.67333 4.0462 2.5 4.3246 2.5 4.62951V15.0215C2.5 15.6139 3.12147 16.0008 3.65307 15.7393L7.24631 13.9721C7.13553 13.652 7.05987 13.3154 7.02395 12.9671L3.5 14.7002V4.75403L9.5 1.80311V8.46776C9.81426 8.31159 10.1494 8.19107 10.5 8.11151V1.8775L12.5 3.06269V8.11151C12.8507 8.19108 13.1858 8.31159 13.5 8.46776V2.94873C13.5 2.66616 13.3509 2.40455 13.1078 2.2605L10.2549 0.569855Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54064 9.89734C9.78472 10.8176 9.26483 11.7699 8.38927 12.0065L8.01823 12.1067C8.00618 12.2361 8 12.3673 8 12.5001C8 12.6598 8.00893 12.8173 8.02628 12.9719L8.3076 13.0422C9.2085 13.2675 9.74447 14.2481 9.47943 15.1862L9.37775 15.5461C9.58473 15.7191 9.80985 15.8673 10.0495 15.9869L10.311 15.694C10.9466 14.9822 12.0176 14.9828 12.6525 15.6953L12.924 16C13.1594 15.8852 13.381 15.7427 13.5854 15.5764L13.4598 15.1027C13.2157 14.1824 13.7356 13.2301 14.6111 12.9935L14.9818 12.8934C14.9938 12.7641 15 12.6329 15 12.5001C15 12.3403 14.9911 12.1827 14.9737 12.028L14.6928 11.9578C13.7919 11.7325 13.2559 10.7519 13.521 9.81377L13.6225 9.45433C13.4155 9.2813 13.1904 9.13297 12.9507 9.01337L12.6894 9.30598C12.0538 10.0178 10.9828 10.0172 10.3479 9.30468L10.0764 9C9.84105 9.11481 9.61942 9.25721 9.41494 9.42344L9.54064 9.89734ZM10.6952 12.5001C10.6952 12.9702 11.0555 13.3513 11.5 13.3513C11.9445 13.3513 12.3048 12.9702 12.3048 12.5001C12.3048 12.03 11.9445 11.6489 11.5 11.6489C11.0555 11.6489 10.6952 12.03 10.6952 12.5001Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.54064 9.89734C9.78472 10.8176 9.26483 11.7699 8.38927 12.0065L8.01823 12.1067C8.00618 12.2361 8 12.3673 8 12.5001C8 12.6598 8.00893 12.8173 8.02628 12.9719L8.3076 13.0422C9.2085 13.2675 9.74447 14.2481 9.47943 15.1862L9.37775 15.5461C9.58473 15.7191 9.80985 15.8673 10.0495 15.9869L10.311 15.694C10.9466 14.9822 12.0176 14.9828 12.6525 15.6953L12.924 16C13.1594 15.8852 13.381 15.7427 13.5854 15.5764L13.4598 15.1027C13.2157 14.1824 13.7356 13.2301 14.6111 12.9935L14.9818 12.8934C14.9938 12.7641 15 12.6329 15 12.5001C15 12.3403 14.9911 12.1827 14.9737 12.028L14.6928 11.9578C13.7919 11.7325 13.2559 10.7519 13.521 9.81377L13.6225 9.45433C13.4155 9.2813 13.1904 9.13297 12.9507 9.01337L12.6894 9.30598C12.0538 10.0178 10.9828 10.0172 10.3479 9.30468L10.0764 9C9.84105 9.11481 9.61942 9.25721 9.41494 9.42344L9.54064 9.89734ZM10.6952 12.5001C10.6952 12.9702 11.0555 13.3513 11.5 13.3513C11.9445 13.3513 12.3048 12.9702 12.3048 12.5001C12.3048 12.03 11.9445 11.6489 11.5 11.6489C11.0555 11.6489 10.6952 12.03 10.6952 12.5001Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default UTCInnerCornerUnitSettings;
