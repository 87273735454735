import React from 'react';

const CasementRightIcon = ({ size = '40' }: { size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 4H4V36H36V4ZM32.8 7.2H7.2V32.8H32.8V7.2Z"
        fill="white"
      />
      <path d="M7.2 7.2H32.8V32.8H7.2V7.2Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 4H4V36H36V4ZM32.8 7.2H7.2V32.8H32.8V7.2Z"
        stroke="#B3B2B4"
      />
      <path d="M7.2 7.2H32.8V32.8H7.2V7.2Z" stroke="#B3B2B4" />
      <path d="M7 7L31.5 20L7 33" stroke="#054D80" />
      <path
        d="M7 7H33V20V33H20H7V7Z"
        fill="#CAD7FF"
        fillOpacity="0.3"
        stroke="#B3B2B4"
      />
    </svg>
  );
};

export default CasementRightIcon;
