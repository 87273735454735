import React, { forwardRef, Ref } from 'react';
import './IntusCollapse.scss';
import Collapse, { CollapseProps } from 'antd/es/collapse';
import Input, { InputRef } from 'antd/es/input';
import { ExpandIcon } from '@/shared/icons';
import { InputProps } from 'antd/es/input';

const IntusCollapse = forwardRef<HTMLDivElement, CollapseProps>(
  ({ ...rest }, ref: Ref<HTMLDivElement>) => {
    return <Collapse ref={ref} expandIcon={ExpandIcon} {...rest} />;
  }
);
IntusCollapse.displayName = 'Intus Collapse';

export default IntusCollapse;

const IntusInput = forwardRef<InputRef, InputProps>(
  ({ ...rest }, ref: Ref<InputRef>) => {
    return <Input ref={ref} rootClassName={'intus-input'} {...rest} />;
  }
);

IntusInput.displayName = 'Intus Input';
