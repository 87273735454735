import React from 'react';

import Gradients from './shared/Gradients/Gradients';
import { SvgUnit, SvgElement } from './components';

import { ElementData } from './models';

interface SvgCanvasProps {
  elementData: ElementData;
  scale?: number;
}

const SvgCanvas: React.FC<SvgCanvasProps> = ({ elementData, scale }) => (
  <SvgElement
    id={elementData.id}
    key={elementData.id}
    height={elementData.height}
    scale={scale}
    width={elementData.parentWidth}
  >
    <Gradients />
    <SvgUnit {...elementData} />
  </SvgElement>
);

export default SvgCanvas;
