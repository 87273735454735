import React, { useEffect, useMemo } from 'react';
import { Group, Rect, Line } from 'react-konva';
import { FlatVector2 } from '@/models';
import { SAFE_AREA_WIDTH } from '../constants';
import { get2DDistance } from '@/shared/helpers/konva';

interface MullionSafeAreaProps {
  glassPoints: FlatVector2[];
  scale: number;
  isMullionVertical: boolean;
  mullionConfigWidth: number;
  onSafeAreaMerge: (value: boolean) => void;
}

function renderDiagonalLines(
  x: number,
  y: number,
  width: number,
  height: number,
  scale: number
) {
  const strokeWidth = 6 / scale;
  // Distance between diagonal lines at 45°.
  const step = strokeWidth * 2 * Math.SQRT2;
  const lines: JSX.Element[] = [];

  for (let offset = -height; offset < width; offset += step) {
    lines.push(
      <Line
        key={offset}
        points={[
          x + width - offset,
          y,
          x + width - offset - height,
          y + height,
        ]}
        stroke="#E6CBCD"
        strokeWidth={strokeWidth}
        listening={false}
      />
    );
  }

  return lines;
}

const MullionSafeArea: React.FC<MullionSafeAreaProps> = ({
  glassPoints,
  scale,
  isMullionVertical,
  mullionConfigWidth,
  onSafeAreaMerge,
}) => {
  const safeAreaSize = SAFE_AREA_WIDTH + mullionConfigWidth / 2;
  const distanceBetweenSides = useMemo(
    () =>
      isMullionVertical
        ? get2DDistance(glassPoints[0], glassPoints[1])
        : get2DDistance(glassPoints[2], glassPoints[3]),
    [glassPoints, isMullionVertical]
  );

  const safeAreaMergeLimit = safeAreaSize * 2;
  const topLeftX = glassPoints[0][0];
  const topLeftY = glassPoints[0][1];
  const bottomRightX = glassPoints[2][0];
  const bottomRightY = glassPoints[3][1];
  const glassWidth = get2DDistance(glassPoints[0], glassPoints[1]);
  const glassHeight = get2DDistance(glassPoints[2], glassPoints[3]);

  useEffect(() => {
    onSafeAreaMerge(distanceBetweenSides < safeAreaMergeLimit);
  }, [distanceBetweenSides, safeAreaMergeLimit]);

  if (distanceBetweenSides < safeAreaMergeLimit) {
    return (
      <Group
        clipFunc={(ctx) => {
          ctx.beginPath();
          ctx.rect(topLeftX, topLeftY, glassWidth, glassHeight);
          ctx.closePath();
        }}
      >
        <Rect
          x={topLeftX}
          y={topLeftY}
          width={glassWidth}
          height={glassHeight}
          fill="#E8DCDE"
        />
        {renderDiagonalLines(
          topLeftX,
          topLeftY,
          glassWidth,
          glassHeight,
          scale
        )}
      </Group>
    );
  }

  const safeAreaWidth = isMullionVertical
    ? safeAreaSize
    : get2DDistance(glassPoints[0], glassPoints[1]);
  const safeAreaHeight = isMullionVertical
    ? get2DDistance(glassPoints[2], glassPoints[3])
    : safeAreaSize;

  return (
    <>
      <Group
        clipFunc={(ctx) => {
          ctx.beginPath();
          ctx.rect(topLeftX, topLeftY, safeAreaWidth, safeAreaHeight);
          ctx.closePath();
        }}
      >
        <Rect
          x={topLeftX}
          y={topLeftY}
          width={safeAreaWidth}
          height={safeAreaHeight}
          fill="#E8DCDE"
        />
        {renderDiagonalLines(
          topLeftX,
          topLeftY,
          safeAreaWidth,
          safeAreaHeight,
          scale
        )}
      </Group>
      <Group
        clipFunc={(ctx) => {
          ctx.beginPath();
          ctx.rect(bottomRightX, bottomRightY, -safeAreaWidth, -safeAreaHeight);
          ctx.closePath();
        }}
      >
        <Rect
          x={bottomRightX}
          y={bottomRightY}
          width={-safeAreaWidth}
          height={-safeAreaHeight}
          fill="#E8DCDE"
        />
        {renderDiagonalLines(
          bottomRightX - safeAreaWidth,
          bottomRightY - safeAreaHeight,
          safeAreaWidth,
          safeAreaHeight,
          scale
        )}
      </Group>
    </>
  );
};

export default MullionSafeArea;
