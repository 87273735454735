import React, { memo, useMemo } from 'react';
import Surface from '@/routes/dashboard/projects/project/UserBuilding/components/Surface';
import Wall from '@/routes/dashboard/projects/project/UserBuilding/components/Wall';
import { EditNode, FlatVector3, NodeType, UserBuildingStorey } from '@/models';
import * as THREE from 'three';
import {
  getCenterFromFlatVectorsArray,
  getPerpendicularVectorToVectors,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import ExtrudeDotHandler, {
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import { isEqual } from 'lodash';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getIsNodeEdited,
  getIsNodeHovered,
  getIsNodeIsolated,
  getIsNodeSelected,
  setExtrudeData,
} from '@/store/slices/canvasBuildingSlice';
import { GenericChildSurface } from '@/models/building-nodes.model';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { isCopyingProperties } from '@/store/slices/copyPropertiesSlice';

interface StoreyProps extends GenericChildSurface {
  storey: UserBuildingStorey;
  isolateMode: boolean;
  isProjectLocked: boolean;
  buildingGUID: string;
  blockGUID: string;
  isEditToolsAvailable: boolean;
  isSingleNodeSelected: boolean;
  multiplyRate: number;
  isDesignerMode: boolean;
  isWindowCardDragging: boolean;
  isSingleNodeIsolated: boolean;
  editedNode?: EditNode;
}

const Storey: React.FC<StoreyProps> = ({
  blockGUID,
  storey,
  isProjectLocked,
  isParentSelected,
  isParentLocked,
  isolateMode,
  isParentEdited,
  buildingGUID,
  isEditToolsAvailable,
  isSingleNodeSelected,
  isSingleNodeIsolated,
  isWindowCardDragging,
  isDesignerMode,
  multiplyRate,
  editedNode,
}) => {
  const dispatch = useAppDispatch();
  const isStoreyHovered = useAppSelector(getIsNodeHovered(storey.guid));
  const isStoreySelected = useAppSelector(getIsNodeSelected(storey.guid));
  const isStoreyIsolated = useAppSelector(getIsNodeIsolated(storey.guid));
  const isStoreyEdited = useAppSelector(getIsNodeEdited(storey.guid));
  const isCopyingProcessing = useAppSelector(isCopyingProperties);

  const isExtrudeEnabled =
    isStoreySelected &&
    !storey.userData?.isLocked &&
    !isParentLocked &&
    !isProjectLocked &&
    isEditToolsAvailable &&
    isSingleNodeSelected &&
    (!isolateMode || (isolateMode && isStoreyIsolated));

  const isSelected = isStoreySelected || isParentSelected;

  const isSomeWallHidden = storey.walls?.some(
    (wall) => wall.userData?.isHidden
  );

  const handleSelectWallForExtrude = (node: ExtrudeHandlerData) => {
    dispatch(setExtrudeData(node));
  };

  const storeyFacedCenters = useMemo((): ExtrudeHandlerData[] | null => {
    const storeyWalls: {
      point: THREE.Vector3;
      coordinates: FlatVector3[];
      guid: string;
    }[] = [];

    storey.walls.forEach((wall) => {
      const center = getCenterFromFlatVectorsArray(wall.points);
      storeyWalls.push({
        point: center,
        coordinates: wall.points,
        guid: wall.guid,
      });
    });

    return storeyWalls.map((storeyWall) => {
      const perpendicular = getPerpendicularVectorToVectors(
        convertFlatVector3ToVectors(storeyWall.coordinates),
        true
      );
      return {
        defaultCenter: [
          storeyWall.point.x,
          storeyWall.point.y,
          storeyWall.point.z,
        ],
        perpendicularDirection: getXYZ(perpendicular),
        facadeWallsGuids: [storeyWall.guid],
      };
    });
  }, [storey]);

  const showSurfaces =
    (!isolateMode || (isolateMode && isStoreyIsolated)) &&
    !storey.userData?.isHidden &&
    !isSomeWallHidden;

  const getIsWallShouldBeHighlighted = (wallGuid: string) =>
    !!editedNode?.childrenEditedNodes?.some((guid) => guid === wallGuid);

  return (
    <group
      key={storey.guid}
      userData={{
        ...storey.userData,
        nodeType: NodeType.Storey,
        guid: storey.guid,
      }}
    >
      {showSurfaces && (
        <>
          <Surface
            data={storey.floor}
            key={storey.floor.guid}
            isParentSelected={isSelected}
            buildingGUID={buildingGUID}
            blockGUID={blockGUID}
          />
          <Surface
            data={storey.ceiling}
            key={storey.ceiling.guid}
            isParentSelected={isSelected}
            isParentLocked={isParentLocked}
            isCeiling
            buildingGUID={buildingGUID}
            blockGUID={blockGUID}
          />
        </>
      )}
      {storey.walls
        .filter((wall) => !wall.userData?.isHidden)
        .map((wall) => (
          <Wall
            isCopyingProcessing={isCopyingProcessing}
            data={wall}
            key={wall.guid}
            isProjectLocked={isProjectLocked}
            isolateMode={isolateMode}
            isParentSelected={isParentSelected || isStoreySelected}
            isSingleNodeSelected={isSingleNodeSelected}
            isStoreySelected={isStoreySelected}
            multiplyRate={multiplyRate}
            isSingleNodeIsolated={isSingleNodeIsolated}
            isStoreyHovered={isStoreyHovered}
            isDesignerMode={isDesignerMode}
            isParentEdited={isParentEdited || isStoreyEdited}
            isEditToolsAvailable={isEditToolsAvailable}
            isParentLocked={isParentLocked || !!storey.userData?.isLocked}
            storeyGUID={storey.guid}
            buildingGUID={buildingGUID}
            blockGUID={blockGUID}
            isWindowCardDragging={isWindowCardDragging}
            highlightBorders={getIsWallShouldBeHighlighted(wall.guid)}
          />
        ))}
      {isExtrudeEnabled &&
        storeyFacedCenters?.map((handlerPoint, i) => {
          return (
            <ExtrudeDotHandler
              extrudeHandlerData={handlerPoint}
              key={i}
              clickAction={() => handleSelectWallForExtrude(handlerPoint)}
            />
          );
        })}
    </group>
  );
};

export default memo(Storey, isEqual);
