import React from 'react';
import { getTranslatedVector } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import * as THREE from 'three';
import { FlatVector3 } from '@/models';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { Html } from '@react-three/drei';
import { calculationMatrix2D } from '@/shared/helpers/matrix';
import { CopyPropertiesIcon } from '@/shared/icons/CopyPropertiesIcon';

export interface CopyPropertiesHandlerData {
  defaultCenter: FlatVector3;
  perpendicularDirection: FlatVector3;
  wallGUID: string;
}

interface CopyPropertiesHandlersProps {
  copyPropertiesHandlerData: CopyPropertiesHandlerData;
  handleClick: () => void;
}

const CopyPropertiesHandler: React.FC<CopyPropertiesHandlersProps> = ({
  copyPropertiesHandlerData,
  handleClick,
}) => {
  const { defaultCenter, perpendicularDirection } = copyPropertiesHandlerData;

  const getPosition = (): FlatVector3 | THREE.Vector3 => {
    const normal = convertFlatVector3ToVector(perpendicularDirection);
    return getTranslatedVector(
      convertFlatVector3ToVector(defaultCenter),
      -0.002,
      normal
    );
  };

  const handlePropertyCopyClick = (event: React.PointerEvent) => {
    event.stopPropagation();
    handleClick();
  };

  return (
    <Html
      center
      occlude={false}
      position={getPosition()}
      zIndexRange={[0, 0]}
      className="select-none"
      calculatePosition={calculationMatrix2D(0, 0)}
    >
      <button
        id={'copy-properties-button'}
        className={`border-none bg-transparent outline-hidden select-none p-0 cursor-crosshair`}
        onPointerDown={(event) => handlePropertyCopyClick(event)}
        onPointerMove={(event) => event.stopPropagation()}
      >
        <CopyPropertiesIcon className={`w-[${8}px] h-[${8}px]`} />
      </button>
    </Html>
  );
};

export default CopyPropertiesHandler;
