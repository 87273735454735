import {
  FlatVector2Axis,
  MeasurementElementType,
  MeasurementUpdateData,
} from '@/components/WindowCreator/models';
import { FlatVector2 } from '@/models';
import { FormInstance } from 'antd/es/form';
import { isEqual, sortBy } from 'lodash';
import { generateMullion } from '@/components/WindowCreator/helpers';
import {
  FillerType,
  UnitInnerFrame,
  UTCFormData,
} from '@/components/UTC/models';
import { generateSplitFrames } from '@/components/UTC/helpers/generators';
import {
  BaseUnit,
  MullionData,
  OperationType,
} from '@/models/window-configurator.model';
import { get2DCenter, get2DDistance } from '@/shared/helpers/konva';
import {
  getExtendedPointByPercentage,
  isHorizontal,
  isLineOnLine,
  isLinesIntersect,
  isPointIntersectLine,
} from '@/components/WindowCreator/helpers/direction.helpers';
import { generateClosedContourPointsFromPointArray } from '@/components/WindowCreator/elements/creator-windows.helpers';
import Clipping from 'polygon-clipping';

export const addUTCMullion = (
  detail: { axis: FlatVector2Axis; framePoints: FlatVector2[] },
  form: FormInstance<UTCFormData>
) => {
  let mullions = form.getFieldValue('mullions');
  const innerFrames: UnitInnerFrame[] = form.getFieldValue('innerFrames');

  const windowToReplaceIdx = innerFrames.findIndex((frame) =>
    isEqual(frame.points, detail.framePoints)
  );
  if (windowToReplaceIdx === -1) return;

  const generatedFrames = generateSplitFrames(
    detail.axis,
    innerFrames[windowToReplaceIdx]
  );

  innerFrames.splice(windowToReplaceIdx, 1, ...generatedFrames);
  mullions = [...mullions, generateMullion(detail.axis)];

  form.setFieldsValue({
    innerFrames,
    mullions,
  });
};

export const updateUTCFormData = ({
  form,
  updateData,
}: {
  form: FormInstance<UTCFormData>;
  updateData: MeasurementUpdateData;
}) => {
  const formData: UTCFormData = form.getFieldsValue(true);
  let updatedFramePoints: FlatVector2[] = [...formData.points];
  let updatedMullions: MullionData[] = [...formData.mullions];
  let updatedFillers: UnitInnerFrame[] = [...formData.innerFrames];

  // Vertical updates are going backwards, as we have point [0,0] in the top left corner (Konva approach for coordinates)
  switch (updateData.type) {
    // Here we currently support only rectangle-like frame. For diagonal/non-rectangle shapes we will need to change the logic
    case MeasurementElementType.Unit:
      // Vertical frame size changed
      if (updateData.pointsToUpdate[0][0] === updateData.pointsToUpdate[1][0]) {
        // In case of vertical frame change
        // We're increasing its height and only top points of frame (and all rest elements, which connected to top frame border)
        const zeroPoint = updateData.pointsToUpdate.reduce((prev, cur) =>
          prev[1] > cur[1] ? prev : cur
        );
        const percentageIncrease =
          updateData.distance / get2DDistance(...updateData.pointsToUpdate);

        updatedFramePoints = updatedFramePoints.map((point) =>
          getExtendedPointByPercentage(
            point,
            zeroPoint,
            percentageIncrease,
            'y'
          )
        );
        updatedMullions = updatedMullions.map((mullion) => ({
          ...mullion,
          points: mullion.points.map((point) =>
            getExtendedPointByPercentage(
              point,
              zeroPoint,
              percentageIncrease,
              'y'
            )
          ) as FlatVector2Axis,
        }));
        updatedFillers = updatedFillers.map((filler) => ({
          ...filler,
          points: filler.points.map((point) =>
            getExtendedPointByPercentage(
              point,
              zeroPoint,
              percentageIncrease,
              'y'
            )
          ),
        }));

        form.setFieldsValue({
          points: updatedFramePoints,
          innerFrames: updatedFillers,
          mullions: updatedMullions,
        });
      } else if (
        updateData.pointsToUpdate[0][1] === updateData.pointsToUpdate[1][1]
      ) {
        const percentageIncrease =
          updateData.distance / get2DDistance(...updateData.pointsToUpdate);
        const sizeCenterPoint = get2DCenter(...updateData.pointsToUpdate);

        updatedFramePoints = updatedFramePoints.map((point) =>
          getExtendedPointByPercentage(
            point,
            sizeCenterPoint,
            percentageIncrease,
            'x'
          )
        );
        updatedMullions = updatedMullions.map((mullion) => ({
          ...mullion,
          points: mullion.points.map((point) =>
            getExtendedPointByPercentage(
              point,
              sizeCenterPoint,
              percentageIncrease,
              'x'
            )
          ) as FlatVector2Axis,
        }));
        updatedFillers = updatedFillers.map((window) => ({
          ...window,
          points: window.points.map((point) =>
            getExtendedPointByPercentage(
              point,
              sizeCenterPoint,
              percentageIncrease,
              'x'
            )
          ),
        }));

        form.setFieldsValue({
          points: updatedFramePoints,
          innerFrames: updatedFillers,
          mullions: updatedMullions,
        });
      }
      break;

    case MeasurementElementType.Mullion:
      // Here we're checking measurement points. For vertical mullion, there are 2 X-axis based points (X----X)
      // For Horizontal Mullion those points will be allocated from top ( mullion/frame) to bottom (mullion/frame)
      if (isHorizontal(updateData.pointsToUpdate)) {
        const isLastPointOnFrame =
          updateData.pointsToUpdate[1][0] === updatedFramePoints[1][0];

        if (isLastPointOnFrame) {
          const xPosition =
            updateData.pointsToUpdate[1][0] - updateData.distance;
          updatedMullions = updatedMullions.map((mullion) => ({
            ...mullion,
            points: mullion.points.map((point) =>
              point[0] === updateData.pointsToUpdate[0][0]
                ? [xPosition, point[1]]
                : point
            ) as FlatVector2Axis,
          }));
          updatedFillers = updatedFillers.map((filler) => ({
            ...filler,
            points: filler.points.map((point) =>
              point[0] === updateData.pointsToUpdate[0][0]
                ? [xPosition, point[1]]
                : point
            ),
          }));
        } else {
          const xPosition =
            updateData.pointsToUpdate[0][0] + updateData.distance;
          updatedMullions = updatedMullions.map((mullion) => ({
            ...mullion,
            points: mullion.points.map((point) =>
              point[0] === updateData.pointsToUpdate[1][0]
                ? [xPosition, point[1]]
                : point
            ) as FlatVector2Axis,
          }));
          updatedFillers = updatedFillers.map((filler) => ({
            ...filler,
            points: filler.points.map((point) =>
              point[0] === updateData.pointsToUpdate[1][0]
                ? [xPosition, point[1]]
                : point
            ),
          }));
        }
      } else {
        const isLastPointOnFrame =
          updateData.pointsToUpdate[1][1] === updatedFramePoints[2][1];

        if (isLastPointOnFrame) {
          const yPosition =
            updateData.pointsToUpdate[1][1] - updateData.distance;
          updatedMullions = updatedMullions.map((mullion) => ({
            ...mullion,
            points: mullion.points.map((point) =>
              point[1] === updateData.pointsToUpdate[0][1]
                ? [point[0], yPosition]
                : point
            ) as FlatVector2Axis,
          }));
          updatedFillers = updatedFillers.map((filler) => ({
            ...filler,
            points: filler.points.map((point) =>
              point[1] === updateData.pointsToUpdate[0][1]
                ? [point[0], yPosition]
                : point
            ),
          }));
        } else {
          const yPosition =
            updateData.pointsToUpdate[0][1] + updateData.distance;
          updatedMullions = updatedMullions.map((mullion) => ({
            ...mullion,
            points: mullion.points.map((point) =>
              point[1] === updateData.pointsToUpdate[1][1]
                ? [point[0], yPosition]
                : point
            ) as FlatVector2Axis,
          }));
          updatedFillers = updatedFillers.map((filler) => ({
            ...filler,
            points: filler.points.map((point) =>
              point[1] === updateData.pointsToUpdate[1][1]
                ? [point[0], yPosition]
                : point
            ),
          }));
        }
      }

      form.setFieldsValue({
        mullions: updatedMullions,
        innerFrames: updatedFillers,
      });
      break;
  }
};

export const deleteMullion = (
  mullionData: MullionData,
  form: FormInstance<UTCFormData>,
  config: BaseUnit
) => {
  let mullions: MullionData[] = form.getFieldValue('mullions');
  let innerFrames: UnitInnerFrame[] = form.getFieldValue('innerFrames');
  const framePoints: FlatVector2[] = form.getFieldValue('points');
  const frameContour: FlatVector2Axis[] =
    generateClosedContourPointsFromPointArray(framePoints);

  const removeMullion = (points: FlatVector2Axis) =>
    (mullions = mullions.filter((mullion) => !isEqual(mullion.points, points)));

  removeMullion(mullionData.points);

  let hasUnconnectedMullion = true;
  const clearUnconnectedMullions = () => {
    mullions.forEach((mullion) => {
      const frameIntersectionAmount = frameContour.filter((line) =>
        isLinesIntersect(line, mullion.points)
      ).length;

      // 1. We need to filter mullions to exclude current one, to not compare mullion with itself
      // 2. After we're checking if one of mullion points intersect any other mullion
      // 3. If there is amount of intersections with frame 2 or more => Mullion is connected from both sides and shouldn't be removed
      // This method runs recursively as deleting one mullion may make another mullion unconnected
      const anotherMullionConnections = mullions
        .filter((_m) => {
          return !isEqual(_m.points, mullion.points);
        })
        .filter(
          (_m) =>
            isPointIntersectLine(mullion.points[0], _m.points, true) ||
            isPointIntersectLine(mullion.points[1], _m.points, true)
        ).length;

      hasUnconnectedMullion =
        frameIntersectionAmount + anotherMullionConnections < 2;

      if (hasUnconnectedMullion) {
        removeMullion(mullion.points);
        clearUnconnectedMullions();
      } else {
        return;
      }
    });
  };

  const mergeEntities = () => {
    const entityList = innerFrames.reduce(
      (
        acc: {
          entitiesToMerge: UnitInnerFrame[];
          entitiesToKeep: UnitInnerFrame[];
        },
        curEntity
      ) => {
        const entityEdges = generateClosedContourPointsFromPointArray(
          curEntity.points
        );
        const isEntityNotConnectedToAnySideElement = entityEdges.some(
          (entityEdge) => {
            return (
              !frameContour.some((frameEdge) =>
                isLineOnLine(entityEdge, frameEdge)
              ) &&
              !mullions.some((mullion) =>
                isLineOnLine(entityEdge, mullion.points)
              )
            );
          }
        );
        if (isEntityNotConnectedToAnySideElement) {
          acc.entitiesToMerge.push(curEntity);
        } else {
          acc.entitiesToKeep.push(curEntity);
        }

        return acc;
      },
      { entitiesToMerge: [], entitiesToKeep: [] }
    );

    const combinedWindow = Clipping.union(
      entityList.entitiesToMerge.map((w) => [[...w.points, w.points[0]]])
    )[0][0];

    // this line is needed, as union adding 1st point as a last element in array, while our windows have 4 points only
    combinedWindow.pop();

    innerFrames = [
      ...entityList.entitiesToKeep,
      {
        points: combinedWindow,
        fillerType: config.fillerType,
        insideColor: config.insideColor,
        outsideColor: config.outsideColor,
        number: '1.1',
        operableWindowType: null,
      },
    ];
  };

  clearUnconnectedMullions();
  mergeEntities();

  form.setFieldValue('mullions', mullions);
  form.setFieldValue('innerFrames', innerFrames);
};

export const handleChangeFillerType = ({
  fillerPoints,
  type,
  form,
  config,
  defaultIGU,
}: {
  fillerPoints: FlatVector2[];
  type: FillerType;
  form: FormInstance<UTCFormData>;
  config: BaseUnit;
  defaultIGU: number | null;
}) => {
  const existingFillers: UnitInnerFrame[] = form.getFieldValue('innerFrames');

  const newFillers = existingFillers.map((filler) =>
    isEqual(filler.points, fillerPoints)
      ? {
          ...filler,
          fillerType: type,
          operableWindowType:
            type === FillerType.OperableWindow
              ? config.operationWindowType
              : undefined,
          outsideColor:
            type === FillerType.Glass
              ? config.outsideColor
              : filler.outsideColor,
          insideColor:
            type === FillerType.Glass
              ? config.outsideColor
              : filler.outsideColor,
          iguId: filler.iguId ?? defaultIGU,
        }
      : filler
  );
  form.setFieldValue('innerFrames', newFillers);
};

export const handleChangeFillerTypeForMultipleInnerFrames = ({
  fillerPoints,
  type,
  form,
  config,
  defaultIGU,
}: {
  fillerPoints: FlatVector2[][];
  type: FillerType;
  form: FormInstance<UTCFormData>;
  config: BaseUnit;
  defaultIGU: number | null;
}) => {
  const existingFillers: UnitInnerFrame[] = form.getFieldValue('innerFrames');

  const newFillers = existingFillers.map((filler) =>
    fillerPoints.some((points) => isEqual(filler.points, points))
      ? {
          ...filler,
          fillerType: type,
          operableWindowType:
            type === FillerType.OperableWindow
              ? config.operationWindowType
              : undefined,
          outsideColor:
            type === FillerType.Glass
              ? config.outsideColor
              : filler.outsideColor,
          insideColor:
            type === FillerType.Glass
              ? config.outsideColor
              : filler.outsideColor,
          iguId: filler.iguId ?? defaultIGU,
        }
      : filler
  );
  form.setFieldValue('innerFrames', newFillers);
};

export const handleChangeWindowType = (
  fillerPoints: FlatVector2[],
  type: OperationType,
  form: FormInstance<UTCFormData>
) => {
  const existingFillers: UnitInnerFrame[] = form.getFieldValue('innerFrames');

  const newFillers = existingFillers.map((filler) =>
    isEqual(filler.points, fillerPoints)
      ? {
          ...filler,
          operableWindowType: type,
        }
      : filler
  );
  form.setFieldValue('innerFrames', newFillers);
};

export const handleChangeWindowIGU = (
  innerFramePoints: FlatVector2[],
  iguId: number,
  form: FormInstance<UTCFormData>
) => {
  const existingInnerFrames: UnitInnerFrame[] =
    form.getFieldValue('innerFrames');

  const newInnerFrames = existingInnerFrames.map((innerFrame) => {
    if (isEqual(innerFrame.points, innerFramePoints)) {
      return { ...innerFrame, iguId };
    }
    return innerFrame;
  });

  form.setFieldValue('innerFrames', newInnerFrames);
};

export function getNumberedInnerFrames(innerFrames: UnitInnerFrame[]) {
  return sortBy(innerFrames, [
    (frame) => frame.points[0][1],
    (frame) => frame.points[0][0],
  ]).map((frame, index, sortedFrames) => ({
    ...frame,
    number: frame.operableWindowType
      ? `1.${sortedFrames.filter((f, i) => i < index && f.operableWindowType).length + 1}.1`
      : `1.${sortedFrames.filter((f, i) => i < index && !f.operableWindowType).length + 1}`,
  }));
}
