import { UserBuildingPanel } from '@/models';
import { GroupedInnerFrameData } from '@/components/Report/Pages/PanelMetrics/InnerFrameTable/InnerFrameTable';
import {
  getInnerFrameHeight,
  getInnerFrameWidth,
  getSideCornerPanel,
} from '@/shared/helpers';
import { PanelConfig } from '@/models/window-configurator.model';

export const getGroupedInnerFrameData = (
  panelData: UserBuildingPanel,
  panelConfig: PanelConfig,
  panelsData: UserBuildingPanel[]
): GroupedInnerFrameData[] => {
  const innerFrames = [...panelData.innerFrames];

  if (panelData.cornerSide) {
    innerFrames.push(...getSideCornerPanel(panelData, panelsData).innerFrames);
  }

  const data = innerFrames.reduce(
    (acc: { [index: string]: GroupedInnerFrameData }, obj, index) => {
      const objIndex = Object.values(acc).findIndex(
        (el) =>
          el.insideColor === obj.insideColor &&
          el.outsideColor === obj.outsideColor &&
          getInnerFrameWidth(el, panelData, panelConfig) ===
            getInnerFrameWidth(obj, panelData, panelConfig) &&
          getInnerFrameHeight(el, panelData, panelConfig) ===
            getInnerFrameHeight(obj, panelData, panelConfig) &&
          el.operableWindowType === obj.operableWindowType
      );
      const existingIndex = Number(Object.keys(acc)[objIndex]);

      if (existingIndex > -1) {
        acc[existingIndex] = {
          ...acc[existingIndex],
          names: acc[existingIndex].names
            ? acc[existingIndex].names + `, ${obj.number}`
            : obj.number,
        };
      } else {
        acc[index] = { ...obj, names: obj.number };
      }
      return acc;
    },
    {}
  );

  return Object.values(data);
};

export const getGroupedInnerFrameIguConfiguration = (
  panelData: UserBuildingPanel
) => {
  return Object.values(
    panelData.innerFrames.reduce(
      (acc: { [index: string]: GroupedInnerFrameData }, obj, index) => {
        const objIndex = Object.values(acc).findIndex(
          (el) => el.iguId === obj.iguId
        );

        const existingIndex = Number(Object.keys(acc)[objIndex]);

        if (existingIndex > -1) {
          acc[existingIndex] = {
            ...acc[existingIndex],
            names: acc[existingIndex].names + `, ${obj.number}`,
          };
        } else {
          acc[index] = { ...obj, names: obj.number };
        }
        return acc;
      },
      {}
    )
  );
};
