import React from 'react';
import { round } from 'mathjs';

import { SCALE, SVG_ID } from '../constants';

interface SvgElementProps {
  width: number;
  height: number;
  scale?: number;
  x?: number;
  y?: number;
  id?: number;
  children: React.ReactNode;
}

const SvgElement: React.FC<SvgElementProps> = ({
  id,
  children,
  height,
  width,
  scale = SCALE,
  x,
  y,
}) => {
  return (
    <svg
      id={`${id ?? SVG_ID}`}
      x={x}
      y={y}
      xmlns="http://www.w3.org/2000/svg"
      width={round(width / scale, 2)}
      height={round(height / scale, 2)}
      viewBox={`0 0 ${width} ${height}`}
    >
      {children}
    </svg>
  );
};

export default SvgElement;
