import { FillerType } from '@/components/UTC/models';
import { FillerTypeInfo, PanelMaterial } from './window-configurator.model';

export const getMaterialAdditionalInfo = (
  materialName: string,
  materials: PanelMaterial[] | FillerTypeInfo[]
) => {
  return materials.find((material) => material.name === materialName)
    ?.additionalInfo;
};

export const getMaterialDescription = (
  materialName: string,
  materials: PanelMaterial[] | FillerTypeInfo[]
) => {
  if (materialName === 'Multiple') return 'Multiple';

  return materials.find((material) => material.name === materialName)
    ?.description;
};

export const getFillerData = (
  filler: FillerType,
  fillersConfig: FillerTypeInfo[]
) => fillersConfig.find((config) => config.name === filler);
