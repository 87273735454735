import { DrawModes, FlatVector2, FlatVector3 } from '@/models/canvas.model';
import { OperationType, RALColors } from './window-configurator.model';
import { FlatVector2Axis } from '@/components/WindowCreator/models';
import { UnitInnerFrame } from '@/components/UTC/models';

export interface CommonConstructionData {
  guid: string;
  name: string;
  userData?: CustomUserData | null;
}

export interface CreateStoreyCoordinates {
  storeyNumber: number;
  floor: FlatVector3[];
  ceiling: FlatVector3[];
  walls: FlatVector3[][];
}

export interface CreateUserBuildingData {
  drawMode: DrawModes;
  storeys: CreateStoreyCoordinates[];
  centerLineCoordinates?: FlatVector3[];
  centerLineWidth?: number;
}

export interface CustomUserData {
  isHidden?: boolean;
  isLocked?: boolean;
  isIsolated?: boolean;
}

export interface UserBuildingSurface extends CommonConstructionData {
  points: FlatVector3[];
}

export enum CornerGridAlign {
  Left = 'Left',
  Right = 'Right',
}
export interface GridLineData {
  guid: string;
  offsetFromLeftEdge: number;
  cornerAlign: CornerGridAlign | null;
}

export interface UserBuildingPanel {
  id: number;
  name: string;
  points: FlatVector2[];
  outsideColor: RALColors;
  insideColor: RALColors;
  cornerSide: CornerGridAlign | null;
  cornerSideUnitId: number | null;
  isInnerCorner: boolean;
  createdAt: string;
  updatedAt: string;
  isCustomUnit: boolean;
  mullions: { points: FlatVector2Axis }[];
  model: string;
  innerFrames: UnitInnerFrame[];
}

export interface PanelWindowData {
  id: number;
  glazing: string;
  outsideColor: string;
  insideColor: string;
  panelInnerFrames: {
    operationType: OperationType;
    glassWidth: number;
    glassHeight: number;
  }[];
  glassArea: number;
  offsetFromLeftEdge: number;
}

export interface WindowPlacementData {
  offsetFromLeftEdge: number;
  windowId: number;
  guid: string;
}

export interface PanelPlacementData {
  isInitialCorner?: boolean;
  offsetFromLeftEdge: number;
  guid: string;
  panelId: number;
}

export interface WallPanelError {
  message: string;
  offsetFromLeftEdge: number;
  value: number;
  width: number;
}

export interface UserBuildingWall extends UserBuildingSurface {
  windowPlacements: WindowPlacementData[];
  gridLines: GridLineData[];
  wallPanels: PanelPlacementData[];
  wallPanelErrors: WallPanelError[];
}

export interface UserBuildingStorey extends CommonConstructionData {
  storeyNumber: number;
  floor: UserBuildingSurface;
  ceiling: UserBuildingSurface;
  walls: UserBuildingWall[];
}

export interface UserBuildingBlock extends CommonConstructionData {
  storeys: UserBuildingStorey[];
}

export interface UserBuildingCenterLineCoordinates
  extends CommonConstructionData {
  points: FlatVector3[];
  width: number;
}

export interface UserBuildingCoordinatesObject extends CommonConstructionData {
  projectId: number;
  drawMode: DrawModes;
  blocks: UserBuildingBlock[];

  centerLineCoordinates?: UserBuildingCenterLineCoordinates;
}
