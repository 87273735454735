import React from 'react';
import './AntdButton.scss';
import Button, { ButtonProps } from 'antd/es/button';

interface IntusButtonProps extends ButtonProps {
  isActive?: boolean;
  small?: boolean;
  borderless?: boolean;
  transparent?: boolean;
  outline?: boolean;
  extraStyles?: string;
}

const IntusButton: React.FC<IntusButtonProps> = ({
  isActive,
  disabled,
  small,
  borderless = true,
  transparent = false,
  type = 'primary',
  children,
  outline = false,
  // use this to add classNames and not override previous styles
  extraStyles,
  ...rest
}) => {
  return (
    <Button
      className={`
      intus-button
      flex items-center 
      ${disabled ? '' : isActive ? 'bg-light-green-100! hover:bg-dark-green-100! text-white hover:text-white!' : 'text-black hover:text-black'} 
      ${small ? 'py-[6px] px-2' : ''}
      ${borderless ? 'border-0!' : ''}
      ${transparent ? 'bg-transparent!' : ''}
      ${outline ? 'bg-white! text-light-green-100 border! border-light-green-100! hover:text-dark-green-100!' : ''}
      ${extraStyles}
      `}
      type={type}
      disabled={disabled}
      {...rest}
    >
      {children}
    </Button>
  );
};

export default IntusButton;
