import React from 'react';

import { Trap } from '../../shared';

import { FlatVector2 } from '@/models';
import { MullionData, PanelConfig } from '@/models/window-configurator.model';
import { Color } from '../../models';
import { generateUTCMullion } from '@/components/UTC/helpers/generators';
import { normalizeSvgPoints } from '../../helpers';
import { LINE_WIDTH } from '../../constants';

interface SvgMullionProps {
  unitPoints: FlatVector2[];
  mullionData: MullionData;
  color: Color;
  config: PanelConfig;
}

const SvgMullion: React.FC<SvgMullionProps> = ({
  color,
  mullionData,
  unitPoints,
  config,
}) => {
  const points = normalizeSvgPoints(
    generateUTCMullion(mullionData.points, unitPoints, config),
    unitPoints[0]
  );

  return (
    <Trap
      points={points}
      fill={{ ...color, hasGlare: false }}
      stroke={color.hex}
      strokeWidth={LINE_WIDTH}
    />
  );
};

export default SvgMullion;
