import React from 'react';
import { Route } from 'react-router-dom';
import ProtectedRoute from '@/core/ProtectedRoute';
import DashboardProjectsView from '@/routes/dashboard/projects/DashboardProjectsView';
import CreateProject from '@/routes/dashboard/projects/create/CreateProject';
import Project from '@/routes/dashboard/projects/project/Project';
import ReportPage from '@/routes/dashboard/projects/project/ReportPage/ReportPage';
import SvgDesignerPage from '@/routes/dashboard/projects/project/SvgDesignerPage/SvgDesignerPage';

export const InternalRoutes = [
  <Route
    key={'/dashboard/projects/'}
    path={'/dashboard/projects/'}
    element={<ProtectedRoute hasHeader component={DashboardProjectsView} />}
  />,
  <Route
    key={'/dashboard/projects/create'}
    path={'/dashboard/projects/create'}
    element={<ProtectedRoute hasHeader component={CreateProject} />}
  />,
  <Route
    key={'/dashboard/projects/:id'}
    path={'/dashboard/projects/:id'}
    element={<ProtectedRoute component={Project} />}
  />,
  <Route
    key={'/dashboard/projects/:id/report'}
    path={'/dashboard/projects/:id/report'}
    element={<ProtectedRoute component={ReportPage} />}
  />,
  <Route
    key={'/dashboard/projects/:id/svg'}
    path={'/dashboard/projects/:id/svg'}
    element={<ProtectedRoute component={SvgDesignerPage} />}
  />,
];
