import * as THREE from 'three';
import { flatten, isEqual } from 'lodash';

import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { C_WallMaterial } from '@/shared/materials';
import {
  FlatVector3,
  UserBuildingBlock,
  UserBuildingSurface,
  UserBuildingWall,
} from '@/models';
import {
  getCenterFromVectorsArray,
  getExtendedVector,
  getMinMaxCoordinatesAtVector3,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  isPointInsideArea,
  isPointsInOneLine,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { compareNumbersWithPrecision } from '@/shared/helpers/format-data';
import {
  convertMetersToMillimeters,
  convertMillimetersToMeters,
} from '@/shared/helpers/distance';

export const translateWallPointsFromBuildingCenter = (
  wallCoordinates: THREE.Vector3[],
  floorPoints: FlatVector3[]
) => {
  const normal = getPerpendicularVectorToVectors(wallCoordinates, true);
  const newPoints = wallCoordinates.map((point) =>
    getTranslatedVector(point, 0.00001, normal)
  );

  const wallCenter = getCenterFromVectorsArray(newPoints);
  const isPointInside = isPointInsideArea(
    wallCenter,
    convertFlatVector3ToVectors(floorPoints)
  );

  if (isPointInside) {
    return wallCoordinates.map((point) =>
      getTranslatedVector(point, -0.00001, normal)
    );
  } else {
    return newPoints;
  }
};

export const findWallInStoreyByPoints = (
  block: UserBuildingBlock,
  points: FlatVector3[],
  storeyIndex: number
) =>
  block.storeys[storeyIndex].walls.find(
    (wall) =>
      wall.points.reduce((acc, curr) => {
        const isPointExist = points.find((point) =>
          isEqual([curr[0], curr[2]], [point[0], point[2]])
        );
        return isPointExist ? acc + 1 : acc;
      }, 0) === 4
  );

export const generateWallMesh = (
  wallData: UserBuildingWall,
  floorPoints: FlatVector3[]
) => {
  const material = C_WallMaterial.clone();
  material.color = new THREE.Color('#00ffff');
  const position = translateWallPointsFromBuildingCenter(
    convertFlatVector3ToVectors(wallData.points),
    floorPoints
  );

  const mesh = new THREE.Mesh(
    createGeometryFromVectorList(position, 'vertical'),
    material
  );

  mesh.userData = {
    wallData,
  };
  return mesh;
};

export const generateWallArrayFromBlock = (
  block: UserBuildingBlock
): THREE.Mesh[] => {
  const result: THREE.Mesh[][] = [];

  for (let i = 0; i < block.storeys.length; i++) {
    const floorPoints = block.storeys[i].floor.points;
    const edges = block.storeys[i].walls.map((wall) =>
      generateWallMesh(wall, floorPoints)
    );
    result.push(edges);
  }
  return result.flat();
};

export const generateTopBorderSurface = (
  startPoint: FlatVector3,
  endPoint: FlatVector3,
  block: UserBuildingBlock,
  storeyIndex: number,
  nextStoreyCeilingPoints?: FlatVector3[],
  withoutOutsideBorder?: boolean
) => {
  const borderHalfSize = 0.015;

  const start = convertFlatVector3ToVector(startPoint);
  const end = convertFlatVector3ToVector(endPoint);

  const nextStoreyPoints = nextStoreyCeilingPoints
    ? convertFlatVector3ToVectors(nextStoreyCeilingPoints)
    : [];

  const isWallUnderStartExist =
    nextStoreyPoints.length && isPointInsideArea(start, nextStoreyPoints);

  const isWallUnderEndExist =
    nextStoreyPoints.length && isPointInsideArea(end, nextStoreyPoints);

  if (isWallUnderEndExist && isWallUnderStartExist) return;

  const outerStart = getExtendedVector(start, end, -borderHalfSize);
  const innerStart = getExtendedVector(start, end, borderHalfSize);

  const outerEnd = getExtendedVector(end, start, -borderHalfSize);
  const innerEnd = getExtendedVector(end, start, borderHalfSize);

  const perpendicular = getPerpendicularVectorToVectors([start, end], true);
  const leftStart = getTranslatedVector(
    innerStart,
    borderHalfSize,
    perpendicular
  );
  const rightStart = getTranslatedVector(
    outerStart,
    -borderHalfSize,
    perpendicular
  );

  const leftEnd = getTranslatedVector(innerEnd, borderHalfSize, perpendicular);
  const rightEnd = getTranslatedVector(
    outerEnd,
    -borderHalfSize,
    perpendicular
  );

  const points = withoutOutsideBorder
    ? [leftStart, leftEnd, end, start, leftStart]
    : [leftStart, leftEnd, rightEnd, rightStart, leftStart];

  return {
    border: new THREE.Mesh(
      createGeometryFromVectorList(points, 'horizontal'),
      C_WallMaterial
    ),
    wall: findWallInStoreyByPoints(block, [startPoint, endPoint], storeyIndex),
  };
};

export const generateTopBorderArray = (
  block: UserBuildingBlock,
  withoutOutsideBorder?: boolean
) => {
  const borderArray: {
    border: THREE.Mesh;
    wall?: UserBuildingWall;
  }[] = [];

  block.storeys.forEach((storey, storeyIndex) => {
    const points = storey.ceiling.points;
    for (let i = 0; i < points.length - 1; i++) {
      const edge = generateTopBorderSurface(
        points[i],
        points[i + 1],
        block,
        storeyIndex,
        block.storeys[storeyIndex + 1]?.ceiling?.points,
        withoutOutsideBorder
      );
      if (!edge) continue;

      borderArray.push(edge);
    }
  });

  return borderArray;
};

export const findFacadePointsHelper = (
  mousePosition: THREE.Vector3,
  block: UserBuildingBlock,
  currentWall: UserBuildingSurface,
  selectedWallGuids?: string[]
) => {
  const facadeWalls: {
    left: FlatVector3[];
    right: FlatVector3[];
  }[] = [];
  for (let i = 0; i < block.storeys.length; i++) {
    const storey = block.storeys[i];
    if (
      selectedWallGuids &&
      !storey.walls.some((wall) =>
        selectedWallGuids?.some(
          (selectedWallGuid) => selectedWallGuid === wall.guid
        )
      )
    ) {
      continue;
    }
    const index = storey.walls.findIndex(
      (wall) =>
        isPointsInOneLine(
          [wall.points[0][0], wall.points[0][2]],
          [mousePosition.x, mousePosition.z],
          [wall.points[1][0], wall.points[1][2]]
        ) && checkIsWallsAreParallel(currentWall, wall)
    );

    if (index === -1) continue;

    let leftSideWall = storey.walls[index];

    //collect all the left side walls from the active wall
    for (let j = index; j < storey.walls.length; j++) {
      const wall = storey.walls[j];
      const nextWallPoints = wall.points;
      const isNextWallInLine = isPointsInOneLine(
        [currentWall.points[0][0], currentWall.points[0][2]],
        [nextWallPoints[0][0], nextWallPoints[0][2]],
        [nextWallPoints[1][0], nextWallPoints[1][2]]
      );

      const isShouldBeIncludedInFacade = selectedWallGuids
        ? selectedWallGuids.some((guid) => guid === wall.guid)
        : true;

      if (
        isNextWallInLine &&
        checkIsWallsAreParallel(currentWall, wall) &&
        isShouldBeIncludedInFacade
      ) {
        leftSideWall = wall;
      }
    }
    let rightSideWall = storey.walls[index];
    //collect all the right side walls from the active wall
    for (let j = index; j >= 0; j--) {
      const wall = storey.walls[j];
      const nextWallPoints = wall.points;
      const isNextWallInLine = isPointsInOneLine(
        [currentWall.points[1][0], currentWall.points[1][2]],
        [currentWall.points[0][0], currentWall.points[0][2]],
        [nextWallPoints[0][0], nextWallPoints[0][2]]
      );

      const isShouldBeIncludedInFacade = selectedWallGuids
        ? selectedWallGuids.some((guid) => guid === wall.guid)
        : true;
      if (
        isNextWallInLine &&
        checkIsWallsAreParallel(currentWall, wall) &&
        isShouldBeIncludedInFacade
      ) {
        rightSideWall = wall;
      }
    }

    facadeWalls.push({
      left: [leftSideWall.points[1], leftSideWall.points[2]],
      right: [rightSideWall.points[0], rightSideWall.points[3]],
    });
  }

  if (!facadeWalls.length) return;

  const leftPoints: FlatVector3[] = facadeWalls.map((wall) => wall.left[0]);

  //find farthest left point
  const farthestLeftWall: FlatVector3 = leftPoints.reduce((acc, curr) => {
    const leftDistance = convertFlatVector3ToVector(curr).distanceTo(
      mousePosition.setY(curr[1])
    );

    return leftDistance >
      convertFlatVector3ToVector([acc[0], curr[1], acc[2]]).distanceTo(
        mousePosition.setY(curr[1])
      )
      ? curr
      : acc;
  }, leftPoints[0]);

  const rightPoints: FlatVector3[] = facadeWalls.map((wall) => wall.right[0]);

  //find farthest right point
  const farthestRightWall: FlatVector3 = rightPoints.reduce((acc, curr) => {
    const rightDistance = convertFlatVector3ToVector(curr).distanceTo(
      mousePosition.setY(curr[1])
    );

    return rightDistance >
      convertFlatVector3ToVector([acc[0], curr[1], acc[2]]).distanceTo(
        mousePosition.setY(curr[1])
      )
      ? curr
      : acc;
  }, rightPoints[0]);

  const minMax = getMinMaxCoordinatesAtVector3(
    convertFlatVector3ToVectors(
      flatten(facadeWalls.map((wall) => [...wall.left, ...wall.right]))
    )
  );

  const points: FlatVector3[] = [
    [farthestRightWall[0], minMax.min.y, farthestRightWall[2]],
    [farthestLeftWall[0], minMax.min.y, farthestLeftWall[2]],
    [farthestLeftWall[0], minMax.max.y, farthestLeftWall[2]],
    [farthestRightWall[0], minMax.max.y, farthestRightWall[2]],
  ];

  return { points, minMax, farthestRightWall, farthestLeftWall };
};

export const checkIsWallsAreParallel = (
  wall1: UserBuildingSurface,
  wall2: UserBuildingSurface
) => {
  const PRECISION = 1e-1;

  const wall1Direction = new THREE.Vector3()
    .subVectors(
      convertFlatVector3ToVector(wall1.points[0]),
      convertFlatVector3ToVector(wall1.points[1])
    )
    .normalize();

  const wall2Direction = new THREE.Vector3()
    .subVectors(
      convertFlatVector3ToVector(wall2.points[0]),
      convertFlatVector3ToVector(wall2.points[1])
    )
    .normalize();

  wall1Direction.x < 0 && wall1Direction.setX(wall1Direction.x * -1);
  wall1Direction.z < 0 && wall1Direction.setZ(wall1Direction.z * -1);
  wall2Direction.x < 0 && wall1Direction.setX(wall1Direction.x * -1);
  wall2Direction.z < 0 && wall1Direction.setZ(wall1Direction.z * -1);

  return (
    compareNumbersWithPrecision(
      wall1Direction.x,
      wall2Direction.x,
      PRECISION
    ) &&
    compareNumbersWithPrecision(wall1Direction.z, wall2Direction.z, PRECISION)
  );
};

export const getExtendedMouseVector = (
  wallPoints: FlatVector3[],
  mousePosition: THREE.Vector3,
  multiplyRate: number
) => {
  const leftDistance = convertFlatVector3ToVector([
    wallPoints[1][0],
    0,
    wallPoints[1][2],
  ]).distanceTo(mousePosition.clone().setY(0));

  // We need additional conversion to reduce accuracy.
  const distance = convertMetersToMillimeters(
    leftDistance / multiplyRate
  ).toFixed(0);

  const firstPoint = convertFlatVector3ToVector(wallPoints[1]);
  const secondPoint = convertFlatVector3ToVector(wallPoints[0]);

  const distanceMeters = convertMillimetersToMeters(distance) * multiplyRate;

  const extendedVector = getExtendedVector(
    firstPoint,
    secondPoint,
    distanceMeters
  );

  return extendedVector;
};
