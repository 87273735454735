import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/middlewares/http-interceptors';

const sharedApi = createApi({
  reducerPath: 'shared',
  baseQuery,
  endpoints: (build) => ({
    get3DJson: build.query<unknown, number>({
      query: (draftId) => ({
        url: `/3d/${draftId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGet3DJsonQuery } = sharedApi;

export { sharedApi };
