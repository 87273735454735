import React from 'react';

import { Dimensions, LineColor } from '../../models';
import { HANDLE_OFFSET, HANDLE_SCALE } from '../../constants';

interface HandleProps extends Dimensions {
  isHorizontal?: boolean;
  scale?: number;
}

const Handle: React.FC<HandleProps> = ({
  x,
  y,
  height,
  width,
  scale = HANDLE_SCALE,
  isHorizontal = false,
}) => {
  const svgWidth = isHorizontal ? height : width;
  const svgHeight = isHorizontal ? width : height;
  const offsetX = isHorizontal ? x - HANDLE_OFFSET : x;
  const offsetY = isHorizontal ? y : y + HANDLE_OFFSET;

  const horizontalPath = (
    <path
      d="M122 25C128.627 25 134 19.6274 134 13C134 6.37258 128.627 1 122 1H87C80.3726 1 75 6.37258 75 13C75 19.6274 80.3726 25 87 25H122ZM105.5 18C107.985 18 110 15.9853 110 13.5C110 11.0147 107.985 9 105.5 9H5.5C3.01472 9 1 11.0147 1 13.5C1 15.9853 3.01472 18 5.5 18H105.5Z"
      stroke={LineColor.DEFAULT}
      strokeDasharray="2 2"
    />
  );

  const verticalPath = (
    <path
      d="M24.5 12.5C24.5 5.87258 19.1274 0.5 12.5 0.5C5.87258 0.5 0.5 5.87258 0.5 12.5V47.5C0.5 54.1274 5.87258 59.5 12.5 59.5C19.1274 59.5 24.5 54.1274 24.5 47.5V12.5ZM17.5 29C17.5 26.5147 15.4853 24.5 13 24.5C10.5147 24.5 8.5 26.5147 8.5 29V129C8.5 131.485 10.5147 133.5 13 133.5C15.4853 133.5 17.5 131.485 17.5 129V29Z"
      stroke={LineColor.DEFAULT}
      strokeDasharray="2 2"
    />
  );

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={svgWidth * scale}
      height={svgHeight * scale}
      x={offsetX}
      y={offsetY}
      viewBox={`0 0 ${svgWidth} ${svgHeight}`}
      fill="none"
    >
      {isHorizontal ? horizontalPath : verticalPath}
    </svg>
  );
};

export default Handle;
