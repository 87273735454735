import React from 'react';

import { Trap } from '../../shared';

import { Color, LineColor } from '../../models';
import { FlatVector2 } from '@/models';
import { PanelConfig } from '@/models/window-configurator.model';
import { LINE_WIDTH } from '../../constants';
import {
  generateCornerGlazingBeadsPoints,
  generateGlazingBeadsPoints,
} from '@/shared/helpers';

interface SvgGlazingBeadsProps {
  points: FlatVector2[];
  color: Color;
  config: PanelConfig;
  unitHeight: number;
  unitWidth: number;
  offset: number;
  isCorner: boolean;
  isSideCorner: boolean;
}

const SvgGlazingBeads: React.FC<SvgGlazingBeadsProps> = ({
  points,
  color,
  config,
  unitHeight,
  unitWidth,
  offset,
  isSideCorner,
  isCorner,
}) => {
  const glazingBeads = isCorner
    ? generateCornerGlazingBeadsPoints(
        unitHeight,
        unitWidth,
        offset,
        points,
        config,
        isSideCorner
      )
    : generateGlazingBeadsPoints(unitHeight, points, config);

  return (
    <>
      {glazingBeads.map((bead, index) => (
        <Trap
          key={index}
          points={bead}
          fill={color}
          strokeWidth={LINE_WIDTH}
          stroke={LineColor.DEFAULT}
        />
      ))}
    </>
  );
};

export default SvgGlazingBeads;
