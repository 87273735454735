import React, { useRef } from 'react';

import SvgCanvas from './SvgCanvas';
import { IntusButton } from '@/shared/elements';
import SvgFacade from './components/Facade/SvgFacade';

import { useSvgData } from './hooks/useSvgData';
import { downloadFile } from '@/shared/helpers';

const SvgPage: React.FC = () => {
  const parentRef = useRef<HTMLDivElement>(null);
  const { getFacadesData, getUnitsData } = useSvgData();

  const facadesData = getFacadesData({
    useAllUnits: true,
    isUnitNumbering: true,
    isReport: true,
    isNumbering: false,
  });
  const unitsData = getUnitsData({
    isNumbering: true,
    isUnitNumbering: false,
    useAllUnits: true,
  });

  const handleDownload = () => {
    if (parentRef.current) {
      const svgBlob = new Blob([parentRef.current.outerHTML], {
        type: 'image/svg+xml;charset=utf-8',
      });

      downloadFile(svgBlob, 'svg.svg');
    }
  };

  return (
    <div className="h-full flex flex-col gap-5">
      <IntusButton
        onClick={handleDownload}
        className="self-start"
        id="svg__download-button"
      >
        Download
      </IntusButton>
      <div
        className="flex overflow-auto flex-wrap gap-2 p-2 bg-gray-400"
        ref={parentRef}
      >
        {unitsData.map((element) => (
          <SvgCanvas elementData={element} key={element.id} />
        ))}
        {facadesData.map((facade, index) => (
          <SvgFacade key={index} {...facade} />
        ))}
      </div>
    </div>
  );
};

export default SvgPage;
