import React from 'react';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import TargetIcon from '@/components/Toolbar/icons/TargetIcon';
import { useAppDispatch } from '@/store/hooks';
import { CanvasCameraCenterMode } from '@/models';
import { setCameraCenterPosition } from '@/store/slices/canvasCamerasSlice';

const MoveToTheSite = () => {
  const dispatch = useAppDispatch();

  //TODO: delete this after fix it IGIG-135

  const onClick = () => {
    dispatch(setCameraCenterPosition(CanvasCameraCenterMode.site));
  };

  const tooltipText = 'Move to the site';

  return (
    <ToolbarButton
      id="toolbar-button-move-to-the-site"
      icon={<TargetIcon />}
      tooltipText={tooltipText}
      onClick={onClick}
    />
  );
};

export default MoveToTheSite;
