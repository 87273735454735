import { FlatVector2 } from '@/models';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import {
  checkSafeAreasIntersection,
  generateMullionSafeArea,
} from '../helpers';
import { MULLION_CROSS_SAFE_AREA } from '@/shared/form/validators';

const useValidateWindowErrors = () => {
  const { panel: panelsConfig } = useFetchWindowConfigQuery().data!;

  const validateWindowErrors = ({
    innerFramePoints,
    outerFramePoints,
  }: {
    innerFramePoints: FlatVector2[];
    outerFramePoints: FlatVector2[];
  }) => {
    const verticalSafeAreas = generateMullionSafeArea({
      innerFramePoints,
      outerFramePoints,
      mullionWidth: panelsConfig.mullion.width,
      isVerticalDirection: true,
    });

    const horizontalSafeAreas = generateMullionSafeArea({
      innerFramePoints,
      outerFramePoints,
      mullionWidth: panelsConfig.mullion.width,
      isVerticalDirection: false,
    });

    const hasMullionInSafeArea =
      checkSafeAreasIntersection(
        verticalSafeAreas[0],
        verticalSafeAreas[1],
        true
      ) ||
      checkSafeAreasIntersection(
        horizontalSafeAreas[0],
        horizontalSafeAreas[1],
        false
      );

    return [hasMullionInSafeArea ? MULLION_CROSS_SAFE_AREA : []].flat();
  };

  return { validateWindowErrors };
};

export default useValidateWindowErrors;
