import React from 'react';
import { round } from 'mathjs';

import { Handle, Path } from '@/components/SvgDesigner/shared';
import SvgOperableWindow from './elements/SvgOperableWindow';

import { CustomOperableWindow } from '@/components/SvgDesigner/models';
import {
  WINDOW_HANDLE_HEIGHT,
  WINDOW_HANDLE_WIDTH,
} from '@/components/WindowCreator/constants';
import { OPERABLE_WINDOW_TYPE_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { STROKE_DASH } from '../../constants';

const SvgDualActionRightTopWindow: React.FC<CustomOperableWindow> = ({
  operableConfig,
  handleScale,
  offset,
  points,
  color,
}) => {
  const width = round(points[1][0] - points[0][0], 2);
  const height = round(points[2][1] - points[0][1], 2);

  const linePoints = [
    {
      x: round(points[1][0] - offset, 2),
      y: round(points[1][1] + offset, 2),
    },
    {
      x: round(points[0][0] + width / 2, 2),
      y: round(points[2][1] - offset, 2),
    },
    {
      x: round(points[0][0] + offset, 2),
      y: round(points[1][1] + offset, 2),
    },
    {
      x: round(points[1][0] - offset, 2),
      y: round(points[0][1] + height / 2, 2),
    },
    {
      x: round(points[0][0] + offset, 2),
      y: round(points[2][1] - offset, 2),
    },
  ];

  return (
    <>
      <SvgOperableWindow
        color={color}
        points={points}
        operableConfig={operableConfig}
      />
      <Path
        points={linePoints}
        stroke={OPERABLE_WINDOW_TYPE_COLOR}
        strokeDasharray={STROKE_DASH}
      />
      <Handle
        width={WINDOW_HANDLE_WIDTH}
        height={WINDOW_HANDLE_HEIGHT}
        scale={handleScale}
        x={round(
          points[0][0] + operableConfig.outerWidth + WINDOW_HANDLE_WIDTH / 2,
          2
        )}
        y={round(
          points[2][1] - height / 2 - (WINDOW_HANDLE_HEIGHT * handleScale) / 2,
          2
        )}
      />
    </>
  );
};

export default SvgDualActionRightTopWindow;
