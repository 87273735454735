import React, { useEffect, useMemo } from 'react';
import { useAppSelector } from '@/store/hooks';
import {
  FacadeDesignerPlacementType,
  getDragNode,
  getGridPlacementAbsoluteOffset,
  getSelectedGridlines,
  SnapZone,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import FacadeDesignerGridLine from '@/components/FacadeDesigner/elements/FacadeDesignerGridLine';
import { inRange } from 'lodash';
import { UnitSystemTypes } from '@/models';
import { useFDElementValidation } from '@/components/FacadeDesigner/hooks/useFDElementValidation';
import { round } from 'mathjs';
import { FacadeDesignerModes } from '@/models/shared.model';

interface FacadeDesignerGridPlacementProps {
  scale: number;
  wallWidth: number;
  wallHeight: number;
  wallData: WallSearchResults;
  xWallOffset: number;
  unitSystem: UnitSystemTypes;
  facadeDesignerMode: FacadeDesignerModes;
  snapZones: SnapZone[];
}
const FacadeDesignerGridPlacement = ({
  facadeDesignerMode,
  scale,
  wallHeight,
  wallWidth,
  wallData,
  xWallOffset,
  unitSystem,
  snapZones,
}: FacadeDesignerGridPlacementProps) => {
  const gridPlacementAbsoluteOffset = useAppSelector(
    getGridPlacementAbsoluteOffset
  );

  const selectedPlacedGridLines = useAppSelector(getSelectedGridlines);
  const isDragElementActive =
    useAppSelector(getDragNode) === FacadeDesignerPlacementType.GridLine;

  const allowPlacement = useMemo(() => {
    if (!isDragElementActive) return true;

    return wallData.gridLines.some((gridLine) =>
      selectedPlacedGridLines.some(
        (selectedGridLine) => selectedGridLine.guid === gridLine.guid
      )
    );
  }, [isDragElementActive, selectedPlacedGridLines, wallData.gridLines]);

  const offsetFromLeftEdge = useMemo(
    () =>
      gridPlacementAbsoluteOffset
        ? round(gridPlacementAbsoluteOffset - xWallOffset, 2)
        : -1,
    [gridPlacementAbsoluteOffset, xWallOffset]
  );

  const { anyWallHasPlacementError, validateGridPlacement } =
    useFDElementValidation(wallData, wallWidth, []);

  useEffect(() => {
    const isGridOnWall = wallData.gridLines.some((grid) =>
      selectedPlacedGridLines.some((sgl) => sgl.guid === grid.guid)
    );

    (isGridOnWall ||
      facadeDesignerMode === FacadeDesignerModes.GridLinePlacement) &&
      validateGridPlacement(offsetFromLeftEdge);
  }, [offsetFromLeftEdge]);

  const isPlacementValid =
    !!gridPlacementAbsoluteOffset &&
    allowPlacement &&
    inRange(gridPlacementAbsoluteOffset, xWallOffset, xWallOffset + wallWidth);

  const isGridLineInSnapZone = useMemo(() => {
    return snapZones.some(
      (snapZone) => snapZone.snapTo === gridPlacementAbsoluteOffset
    );
  }, [snapZones, gridPlacementAbsoluteOffset]);

  return (
    <>
      {isPlacementValid && (
        <FacadeDesignerGridLine
          hasError={anyWallHasPlacementError}
          gridPlacement={true}
          gridLine={{
            offsetFromLeftEdge,
            guid: '',
            cornerAlign: null,
          }}
          scale={scale}
          wallHeight={wallHeight}
          wallWidth={wallWidth}
          unitSystem={unitSystem}
          wallGUID={wallData.guid}
          isHoveredOnLine={false}
          hasBeenSnapped={!anyWallHasPlacementError && isGridLineInSnapZone}
        />
      )}
    </>
  );
};

export default FacadeDesignerGridPlacement;
