import React, { memo, useEffect, useMemo, useState } from 'react';
import { GridLineData, UnitSystemTypes } from '@/models';
import { Group, Line } from 'react-konva';
import {
  GridLineConfig,
  GridLineSettings,
} from '@/components/FacadeDesigner/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getFacadeDesignerMode,
  getSelectedGridlines,
  isGridLineSelected,
  setHoveredGridLine,
  setMeasurementActiveWall,
  isWallMeasurementActive,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';
import { useFacadeDesignerSelection } from '@/components/FacadeDesigner/hooks/useFacadeDesignerSelection';
import { KonvaEventObject } from 'konva/lib/Node';
import { round } from 'mathjs';
import MultiMeasurementLine from '@/shared/components/MultiMeasurementLine/MultiMeasurementLine';
import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models';
import {
  useCustomElementPlacement,
  useFDElementValidation,
} from '@/components/FacadeDesigner/hooks';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { inRange, uniqBy } from 'lodash';
import { isEqualForMemo, isLeftClick } from '@/shared/helpers';
import { useParams } from 'react-router';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';

interface FacadeDesignerGridLineProps {
  gridLine: GridLineData;
  scale: number;
  unitSystem: UnitSystemTypes;
  wallHeight: number;
  wallWidth?: number;
  gridPlacement?: boolean;
  isHidden?: boolean;
  hasError?: boolean;
  wallOffset?: number;
  wallGUID?: string;
  wallData?: WallSearchResults;
  isHoveredOnLine: boolean;
  hasBeenSnapped?: boolean;
  isDragCopyInProcess?: boolean;
  isDragElementActive?: boolean;
  isSomeWindowSelected?: boolean;

  onMeasurementChange?: (offsetChange: number) => void;
  onMeasurementEscape?: () => void;
  onMeasurementSubmit?: () => void;
}

const FacadeDesignerGridLine = ({
  gridLine,
  scale,
  wallHeight,
  wallWidth,
  unitSystem,
  gridPlacement,
  isHidden,
  wallOffset,
  wallData,
  hasError,
  onMeasurementChange,
  onMeasurementEscape,
  onMeasurementSubmit,
  isHoveredOnLine,
  hasBeenSnapped,
  isDragElementActive,
  isDragCopyInProcess,
  isSomeWindowSelected,
}: FacadeDesignerGridLineProps) => {
  const absoluteOffset = round(
    (wallOffset ?? 0) + gridLine.offsetFromLeftEdge,
    2
  );

  const [measurementPoints, setMeasurementPoints] = useState<FlatVector2Axis[]>(
    []
  );
  const { id } = useParams();
  const projectData = useFetchProjectQuery(id!).data!;
  const dispatch = useAppDispatch();
  const selectedGridLines = useAppSelector(getSelectedGridlines);
  const isSelected = useAppSelector(isGridLineSelected(gridLine.guid));
  const isMeasurementsShown = selectedGridLines[0]?.guid === gridLine.guid;
  const isInMoveMode = isDragElementActive && isSelected;
  const isMeasurementActive = useAppSelector(isWallMeasurementActive);

  const [localMeasurementActive, setLocalMeasurementActive] = useState(false);

  const cachedData = selectedGridLines.find((l) => l.guid === gridLine.guid);

  const isOnlyOneGridLineSelected = useMemo(() => {
    const values = selectedGridLines.reduce((acc, curr) => {
      return { ...acc, [curr.absoluteOffset]: 1 };
    }, {});
    return Object.keys(values).length < 2;
  }, [selectedGridLines]);

  const isSelectionMode =
    useAppSelector(getFacadeDesignerMode) === FacadeDesignerModes.Selection;

  const { handleSelectGridLine } = useFacadeDesignerSelection();

  const isAvailableToSelect =
    !gridLine.cornerAlign &&
    isSelectionMode &&
    !isDragElementActive &&
    !isDragCopyInProcess;

  const elementValidation =
    wallData && wallWidth
      ? useFDElementValidation(wallData, wallWidth)
      : undefined;

  const actualConfig = useMemo((): GridLineSettings => {
    if (gridPlacement) {
      if (isHidden) {
        return GridLineConfig.placingEmpty;
      }
      if (hasError) {
        return GridLineConfig.error;
      }
      if (isDragElementActive) {
        return GridLineConfig.placedSelected;
      }
      return GridLineConfig.placing;
    }
    if (isSelected) {
      if (elementValidation?.anyWallHasPlacementError) {
        return GridLineConfig.error;
      }
      return GridLineConfig.placedSelected;
    }
    if (isHoveredOnLine) {
      return GridLineConfig.placedHover;
    }

    if (gridLine.cornerAlign) {
      return GridLineConfig.corner;
    }

    return GridLineConfig.placed;
  }, [
    isHidden,
    isSelected,
    isHoveredOnLine,
    hasError,
    elementValidation?.anyWallHasPlacementError,
    isInMoveMode,
  ]);

  const isSomeSelectedOnLine = useMemo(() => {
    if (isNaN(Number(wallOffset)) || gridLine.cornerAlign) return false;

    return selectedGridLines.some(
      (line) => line.absoluteOffset === absoluteOffset
    );
  }, [gridLine, wallOffset, absoluteOffset, selectedGridLines]);

  useEffect(() => {
    if (!isAvailableToSelect || isSelected) return;
    isSomeSelectedOnLine && selectGridLine(true);
  }, [selectedGridLines, isSelected, isAvailableToSelect, absoluteOffset]);

  const selectGridLine = (multiSelect: boolean) => {
    if (!isAvailableToSelect || isMeasurementActive) return;

    handleSelectGridLine({
      data: { ...gridLine, absoluteOffset, wallGUID: wallData!.guid },
      multiSelect,
      isSelected,
    });
  };
  const handleHover = (isHovered: boolean) => {
    if (isDragElementActive || isMeasurementActive) return;

    isHovered
      ? dispatch(
          setHoveredGridLine({
            ...gridLine,
            absoluteOffset,
            wallGUID: wallData!.guid,
          })
        )
      : dispatch(setHoveredGridLine(null));
  };

  const handleMouseEnter = (e: KonvaEventObject<MouseEvent>) => {
    if (!isAvailableToSelect || isDragElementActive) return;
    if (
      !e.evt.shiftKey ||
      (selectedGridLines.length === 0 && !isSomeWindowSelected)
    ) {
      const stage = e.target.getStage();
      stage!.container().style.cursor = 'pointer';
    }
    handleHover(true);
  };

  const handleMouseLeave = (e: KonvaEventObject<MouseEvent>) => {
    if (!isAvailableToSelect || isDragElementActive) return;
    const stage = e.target.getStage();
    stage!.container().style.cursor = 'default';
    handleHover(false);
  };
  const handleGridMovePlacement = wallData
    ? useCustomElementPlacement({
        wallData,
        yPosition: wallHeight / 2,
      }).handleGridMovePlacement
    : undefined;

  const handlePointerDown = (event: KonvaEventObject<MouseEvent>) => {
    if (!isLeftClick(event.evt)) return;
    selectGridLine(event.evt.shiftKey);
    handleGridMovePlacement &&
      setMeasurementPoints(
        handleGridMovePlacement(gridLine.offsetFromLeftEdge, true)
      );

    if (isOnlyOneGridLineSelected) {
      const stage = event.target.getStage();
      stage!.container().style.cursor = 'default';
    }
  };

  useEffect(() => {
    if (measurementPoints?.length > 0) {
      if (!(uniqBy(selectedGridLines, 'absoluteOffset').length === 1)) {
        setMeasurementPoints([]);
      }
      if (
        absoluteOffset !== selectedGridLines[0]?.absoluteOffset &&
        !localMeasurementActive
      ) {
        setMeasurementPoints([]);
      }
    }
  }, [
    selectedGridLines,
    absoluteOffset,
    gridLine.offsetFromLeftEdge,
    measurementPoints,
    isSomeWindowSelected,
    localMeasurementActive,
  ]);

  const handleStatusChange = (isActive: boolean) => {
    if (!wallData?.guid) return;
    setLocalMeasurementActive(isActive);
    dispatch(setMeasurementActiveWall(isActive ? wallData?.guid : null));
  };

  const handleMeasurementChange = (points: FlatVector2Axis[]) => {
    setMeasurementPoints(points);

    cachedData &&
      onMeasurementChange &&
      onMeasurementChange(
        round(
          (wallOffset ?? 0) + points[1][0][0] - cachedData.absoluteOffset,
          2
        )
      );
  };

  const handleMeasurementEscape = () => {
    setMeasurementPoints([]);
    dispatch(setMeasurementActiveWall(null));
    onMeasurementEscape && onMeasurementEscape();
  };

  const handleMeasurementSubmit = () => {
    setMeasurementPoints([]);
    dispatch(setMeasurementActiveWall(null));
    onMeasurementSubmit && onMeasurementSubmit();
  };

  useEffect(() => {
    if (isSelected && !isDragElementActive && isMeasurementsShown) {
      handleGridMovePlacement &&
        setMeasurementPoints(
          handleGridMovePlacement(gridLine.offsetFromLeftEdge, true)
        );
    }
  }, [isDragElementActive, isSelected, isMeasurementsShown]);

  useEffect(() => {
    elementValidation?.validateGridPlacement(gridLine.offsetFromLeftEdge);
  }, [gridLine.offsetFromLeftEdge]);

  const isGridLineValid = inRange(
    absoluteOffset,
    wallOffset ?? 0,
    (wallOffset ?? 0) + (wallWidth ?? 0)
  );

  if (!isGridLineValid && !gridPlacement) return null;

  return (
    <Group>
      {!isInMoveMode && (
        <Line
          points={[
            gridLine.offsetFromLeftEdge,
            0,
            gridLine.offsetFromLeftEdge,
            wallHeight,
          ]}
          listening={isAvailableToSelect}
          dash={
            hasBeenSnapped ? undefined : actualConfig.dash?.map((v) => v / 2)
          }
          stroke={actualConfig.stroke}
          shadowForStrokeEnabled={false}
          strokeWidth={2.3}
          strokeScaleEnabled={false}
          hitStrokeWidth={10}
          onPointerDown={handlePointerDown}
          onMouseEnter={handleMouseEnter}
          onPointerLeave={handleMouseLeave}
        />
      )}
      {!!measurementPoints?.length &&
        wallData &&
        isMeasurementsShown &&
        isAvailableToSelect &&
        !isSomeWindowSelected &&
        !projectData.locked &&
        !isDragElementActive && (
          <MultiMeasurementLine
            multiPoints={measurementPoints}
            scale={scale}
            units={unitSystem}
            type={MeasurementElementType.GridDistance}
            onActiveStatusChange={handleStatusChange}
            onChange={handleMeasurementChange}
            onEscape={handleMeasurementEscape}
            onSubmit={handleMeasurementSubmit}
            customErrorMessage={
              elementValidation?.placementErrors[wallData.guid]?.message
            }
          />
        )}
    </Group>
  );
};

export default memo(FacadeDesignerGridLine, isEqualForMemo);
