import React, { forwardRef, Ref } from 'react';
import Input, { InputRef } from 'antd/es/input';
import './AntdInput.scss';
import { InputProps } from 'antd/es/input';

const IntusInput = forwardRef<InputRef, InputProps>(
  ({ size = 'middle', ...rest }, ref: Ref<InputRef>) => {
    return (
      <Input
        ref={ref}
        rootClassName={`intus-input intus-input-${size}`}
        size={size}
        autoComplete="off"
        {...rest}
      />
    );
  }
);

IntusInput.displayName = 'Intus Input';

export default IntusInput;
