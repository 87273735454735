import { DEFAULT_UNIT_HEIGHT } from '@/components/UTC/helpers/constants';

export const STAGE_WIDTH_RATIO = 0.83;
export const STAGE_HEIGHT_RATIO = 0.85;
export const STAGE_WIDTH = window.innerWidth * STAGE_WIDTH_RATIO;
export const STAGE_HEIGHT = window.innerHeight * STAGE_HEIGHT_RATIO;
export const INITIAL_SCALE = (STAGE_HEIGHT * 0.4) / 1000;
export const INITIAL_UTC_SCALE = (STAGE_HEIGHT * 0.2) / 1000;
export const STATIC_SCALE = 0.25;
export const SCALE_SPEED = 1.02;
export const SAFE_AREA_WIDTH = 300;

export const WINDOW_HANDLE_WIDTH = 25;
export const WINDOW_HANDLE_HEIGHT = 134;

export const STAGE_BOTTOM_PADDING_TO_FLOOR_0 = 12;

export const MEASUREMENT_PADDING = 30;

export const BORDERLINE_DEFAULT_POINTS: number[][] = [
  [-10000, (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE],
  [10000, (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE],
];

export const CEILING_LINE_DEFAULT_POINTS: number[][] = [
  [
    -10000,
    (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE -
      DEFAULT_UNIT_HEIGHT,
  ],
  [
    10000,
    (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE -
      DEFAULT_UNIT_HEIGHT,
  ],
];

export const MAN_CONTOUR_PARAMS = {
  offsetFromWindowFrame: 150,
  width: (190 * INITIAL_SCALE) / STATIC_SCALE,
  height:
    (1750 + STAGE_BOTTOM_PADDING_TO_FLOOR_0 / INITIAL_SCALE) * INITIAL_SCALE,
};

export const ACTIVE_ELEMENT_COLOR = '#65BD51';
export const PROHIBITED_ELEMENT_COLOR = '#D23736';
export const DEFAULT_ELEMENT_COLOR = '#414042';
export const DISABLED_ELEMENT_COLOR = '#B3B2B4';
export const ERROR_ELEMENT_COLOR = '#D23736';

export const INVALID_IGU_CONFIG_TITLE = 'Invalid IGU configuration.';
