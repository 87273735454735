import React from 'react';
import { TextAreaProps } from 'antd/es/input/TextArea';
import './AntdTextArea.scss';
import Input from 'antd/es/input';

const IntusTextArea: React.FC<TextAreaProps> = ({ ...rest }) => {
  return <Input.TextArea rootClassName={'intus-textarea'} {...rest} />;
};

export default IntusTextArea;
