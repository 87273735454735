import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/middlewares/http-interceptors';
import { WindowConfigurator } from '@/models/window-configurator.model';
import {
  SavedWindow,
  WindowCreatorFormData,
} from '@/components/WindowCreator/models/konva-model';

import { projectsApi } from '@/store/apis/projectsApi';

const windowApi = createApi({
  reducerPath: 'window',
  tagTypes: ['Windows', 'Config'],
  baseQuery,
  endpoints: (build) => ({
    fetchWindowConfig: build.query<WindowConfigurator, void>({
      providesTags: ['Config'],
      query: () => ({
        url: '/projects/configurations',
        method: 'GET',
      }),
    }),
    fetchWindows: build.query<SavedWindow[], string>({
      query: (id) => ({
        url: `/projects/${id}/windows`,
        method: 'GET',
      }),
      providesTags: ['Windows'],
    }),
    updateWindow: build.mutation<null, { data: SavedWindow }>({
      query: ({ data }) => ({
        url: `/projects/windows`,
        method: 'PUT',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Windows']),
    }),
    deleteWindow: build.mutation<void, { windowId: number }>({
      query: ({ windowId }) => ({
        url: `/projects/windows/${windowId}`,
        method: 'DELETE',
      }),
      onQueryStarted(arg, api): Promise<void> | void {
        api.queryFulfilled.then(() =>
          // TODO: Refactor (rename) to specific tag, when this name tag and approach will be changed in 'projects Api'
          api.dispatch(projectsApi.util?.invalidateTags(['PR']))
        );
      },
      invalidatesTags: (result, error) => (error ? [] : ['Windows']),
    }),
    duplicateExistingWindow: build.mutation<SavedWindow, { windowId: number }>({
      query: ({ windowId }) => ({
        url: `/projects/windows/duplicate/${windowId}`,
        method: 'POST',
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Windows']),
    }),
    setWindow: build.mutation<
      SavedWindow,
      { projectId: string; data: WindowCreatorFormData }
    >({
      query: ({ projectId, data }) => ({
        url: `/projects/${projectId}/windows`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (result, error) => (error ? [] : ['Windows']),
    }),
  }),
});

export const {
  useFetchWindowConfigQuery,
  useFetchWindowsQuery,
  useSetWindowMutation,
  useDeleteWindowMutation,
  useDuplicateExistingWindowMutation,
  useUpdateWindowMutation,
} = windowApi;

export { windowApi };
