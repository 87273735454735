import React, { memo, useMemo } from 'react';
import { FlatVector3, UserBuildingBlock } from '@/models';
import { C_FatLineBorderMaterial } from '@/shared/materials';
import { Color, Vector3 } from 'three';
import { POLYGON_OFFSET_FACTOR_LEVELS } from '@/shared/constants';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import FatLine from '@/shared/components/FatLine';
import { isEqual } from 'lodash';
import { ThreeEvent } from '@react-three/fiber';
import { Line2 } from 'three/examples/jsm/lines/Line2';

interface BlockSnapSkeletonProps {
  block: UserBuildingBlock;
  ignoredWallGuids?: string[];
  onSnapIntersection?: (points: Vector3) => void;
  onSnapIntersectionStop: (points: Vector3) => void;
}

const BlockSnapSkeleton: React.FC<BlockSnapSkeletonProps> = ({
  block,
  ignoredWallGuids,
  onSnapIntersection,
  onSnapIntersectionStop,
}) => {
  const snapLineMaterial = useMemo(() => {
    const material = C_FatLineBorderMaterial.clone();
    material.polygonOffset = false;
    material.visible = false;
    return material;
  }, []);

  const snapLineMaterialVisible = useMemo(() => {
    const material = C_FatLineBorderMaterial.clone();
    material.color = new Color('#ff0000');
    material.polygonOffsetFactor = POLYGON_OFFSET_FACTOR_LEVELS.HIGH;
    return material;
  }, []);

  const edgesGeometries = useMemo(() => {
    const walls = block.storeys.flatMap((storey) =>
      storey.walls.flatMap((wall) => wall)
    );

    const edges: [FlatVector3, FlatVector3][] = [];
    walls.forEach((wall) => {
      if (!ignoredWallGuids?.some((guid) => wall.guid === guid)) {
        edges.push([wall.points[0], wall.points[1]]);
        edges.push([wall.points[1], wall.points[2]]);
        edges.push([wall.points[2], wall.points[3]]);
        edges.push([wall.points[3], wall.points[0]]);
      }
    });
    return edges;
  }, [block, ignoredWallGuids]);

  const handlePointerMove = (
    e: ThreeEvent<PointerEvent>,
    lineInstance: Line2
  ) => {
    e.stopPropagation();
    lineInstance.material = snapLineMaterialVisible;
    onSnapIntersection && onSnapIntersection(e.pointOnLine!);
  };

  const handlePointerLeave = (
    e: ThreeEvent<PointerEvent>,
    lineInstance: Line2
  ) => {
    e.stopPropagation();
    lineInstance.material = snapLineMaterial;
    onSnapIntersectionStop && onSnapIntersectionStop(e.point);
  };

  return (
    <>
      <group>
        {edgesGeometries.map((edge, i) => {
          return (
            <FatLine
              key={`${edge.toString()}_${i}`}
              lineMaterial={snapLineMaterial}
              startPoint={convertFlatVector3ToVector(edge[0])}
              endPoint={convertFlatVector3ToVector(edge[1])}
              onPointerMove={handlePointerMove}
              onPointerLeave={handlePointerLeave}
            />
          );
        })}
      </group>
    </>
  );
};

export default memo(
  BlockSnapSkeleton,
  (prevProps, nextProps) =>
    isEqual(prevProps.ignoredWallGuids, nextProps.ignoredWallGuids) ||
    isEqual(prevProps.block, nextProps.block)
);
