import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import React, { useMemo } from 'react';
import {
  FlatVector2,
  NodeType,
  SelectedNode,
  WindowPlacementData,
} from '@/models';
import { round } from 'mathjs';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import {
  convert2DPointsToDistanceInMeters,
  getWallHeight,
} from '@/shared/helpers/metrics';
import { useAppSelector } from '@/store/hooks';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import {
  ExtendedMinMaxCoordinatesPairs,
  getMinMaxCoordinatesAtVector3,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import WallElevations from '@/components/FacadeDesigner/elements/WallElevations';
import GridLinesInformationMeasurements from '@/components/FacadeDesigner/elements/GridLinesInformationMeasurements';
import WindowsInformationMeasurements from '@/components/FacadeDesigner/elements/WindowsInformationMeasurements';

interface FacadeDesignerMeasurementsProps {
  projectId: string;
  wallsData: WallSearchResults[];
  startPoint: { x: number; z: number };
  scale: number;
  minMaxWallsCoordinates: ExtendedMinMaxCoordinatesPairs;
  selectedWalls: SelectedNode[];
  wallsTotalWidth: number;
  wallsTotalHeight: number;
}

const FacadeDesignerMeasurements = ({
  projectId,
  wallsData,
  startPoint,
  scale,
  minMaxWallsCoordinates,
  selectedWalls,
  wallsTotalWidth,
  wallsTotalHeight,
}: FacadeDesignerMeasurementsProps) => {
  const multiplyRate = useAppSelector(getMultiplyRate(projectId!));
  const distanceToRightEdge = useMemo(() => {
    return Math.max(
      ...wallsData.map((wall) => {
        const wallBottomRightPoint: FlatVector2 = [
          wall.points[3][0],
          wall.points[3][2],
        ];
        return round(
          Number(
            convertMetersToMillimeters(
              convert2DPointsToDistanceInMeters(
                wallBottomRightPoint,
                [startPoint.x, startPoint.z],
                multiplyRate
              )
            )
          ),
          2
        );
      })
    );
  }, [wallsData, startPoint, multiplyRate]);

  const uniqueWallsByY = wallsData.reduce(
    (acc, wall) =>
      acc.some((w) => w.points[0][1] === wall.points[0][1])
        ? acc
        : [...acc, wall],
    [] as WallSearchResults[]
  );

  const allPlacedWindows: WindowPlacementData[] = useMemo(() => {
    return wallsData.flatMap((wall) => wall.windowPlacements || []);
  }, [selectedWalls]);

  return (
    <>
      {uniqueWallsByY.map((wall) => {
        const wallHeight = Number(
          convertMetersToMillimeters(getWallHeight(wall.points, multiplyRate))
        );
        const wallCoordinates = getMinMaxCoordinatesAtVector3(
          convertFlatVector3ToVectors(wall.points)
        );
        const wallOffsetY = round(
          Number(
            convertMetersToMillimeters(
              (wallCoordinates.max.y - minMaxWallsCoordinates.max.y) /
                multiplyRate
            )
          ),
          2
        );
        return (
          <WallElevations
            key={wall.guid}
            offsetY={wallOffsetY}
            distanceToRightEdge={distanceToRightEdge}
            wallHeight={wallHeight}
            scale={scale}
            selectedWalls={selectedWalls}
            parentStoreyGuid={wall.getParentNode(NodeType.Storey)!.guid}
            parentBlockGuid={wall.getParentNode(NodeType.Block)!.guid}
          />
        );
      })}
      <GridLinesInformationMeasurements
        wallsData={wallsData}
        scale={scale}
        wallsTotalHeight={wallsTotalHeight}
        wallsTotalWidth={wallsTotalWidth}
        startPoint={startPoint}
      />
      {allPlacedWindows?.length > 0 && (
        <WindowsInformationMeasurements
          wallsData={wallsData}
          scale={scale}
          startPoint={startPoint}
          wallsTotalWidth={wallsTotalWidth}
        />
      )}
    </>
  );
};

export default FacadeDesignerMeasurements;
