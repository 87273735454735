import React, { useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import { flatten, isEqual } from 'lodash';
import { booleanClockwise, lineString } from '@turf/turf';
import { uuidv7 } from 'uuidv7';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  DistanceInput,
  FlatVector3,
  MetricLimits,
  NodeType,
  UserBuildingStorey,
  UserBuildingWall,
} from '@/models';
import { ExtrudeHandlerData } from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  BUILDING_SELECTED_CONTOUR_COLOR,
  C_FatLineBorderMaterial,
  C_WallMaterial,
  C_WallSelectedMaterial,
} from '@/shared/materials';
import {
  checkLineIntersection,
  createLine2,
  getDistanceBetweenInfiniteLineAndVector,
  getExtendedVector,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  getXYZ,
  isPointsInOneLine,
  isVectorLeftSide,
  triangulateGeometryAndUpdate,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  getProcessingEntity,
  setDirectionalInputValues,
  setShowDirectionalInput,
} from '@/store/slices/canvasExternalElementsSlice';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import {
  convertMetersToMillimeters,
  convertMillimetersToMeters,
} from '@/shared/helpers/distance';
import { subscribe, unsubscribe } from '@/core/events';
import {
  DIRECTIONAL_INPUT__SET,
  DIRECTIONAL_INPUT__UPDATE,
} from '@/core/event-names';
import { hideSelectionBoxArea } from '@/shared/helpers';

import { POLYGON_OFFSET_FACTOR_LEVELS } from '@/shared/constants';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import { useUnmount } from 'react-use';
import { disposeNode } from '@/shared/helpers/canvas';
import { ThreeEvent } from '@react-three/fiber';

export type UpdatedWallsCallbackData = {
  wallGUID: string;
  points: THREE.Vector3[];
  isFlat?: boolean;
}[];

interface ExtrudeStoreyProps {
  extrudedWallGuid: string;
  storey: UserBuildingStorey;
  extrudeData: ExtrudeHandlerData;
  restExtrudedWallsGuids?: string[];
  cursorPosition?: THREE.Vector3;
  maxNegativeDistance?: number;
  setMaxNegativeDistance?: (value: number) => void;
  maxPositiveDistance?: number;
  setMaxPositiveDistance?: (value: number) => void;
  updateFloor?: (value: [p1: THREE.Vector3, p2: THREE.Vector3]) => void;
  updateWalls?: (value: UpdatedWallsCallbackData) => void;
  affectedWallsByExtrude?: Set<string>;
  handleExtrudeHandlerPosition?: (distance: number) => void;
}

const ExtrudeWall: React.FC<ExtrudeStoreyProps> = ({
  extrudedWallGuid,
  storey,
  extrudeData,
  restExtrudedWallsGuids,
  cursorPosition,
  maxNegativeDistance,
  maxPositiveDistance,
  setMaxNegativeDistance,
  setMaxPositiveDistance,
  updateFloor,
  updateWalls,
  affectedWallsByExtrude,
  handleExtrudeHandlerPosition,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const processingEntity = useAppSelector(getProcessingEntity)!;
  const isDirectionalInputActive = processingEntity.active;

  const [firstDynamicWall, setFirstDynamicWall] = useState<THREE.Mesh>(null!);
  const [secondDynamicWall, setSecondDynamicWall] = useState<THREE.Mesh>(null!);
  const [extrudeDynamicWall, setExtrudeDynamicWall] = useState<THREE.Mesh>(
    null!
  );

  const floorPoints = storey.floor.points;
  const floorHeight = storey.ceiling.points[0][1];

  const extrudedWallData = storey.walls.find(
    (wall) => wall.guid === extrudedWallGuid
  );

  const restWallsPoints = useMemo(
    () => storey.walls.filter((wall) => wall.guid !== extrudedWallGuid),
    [storey, extrudeData]
  );

  const storeyWalls = useMemo(() => [...storey.walls], [storey]);

  const firstClosestWall = useMemo(() => {
    const extrudedWallIndex = storeyWalls.findIndex(
      (wall) => wall.guid === extrudedWallGuid
    );
    const index =
      extrudedWallIndex === storeyWalls.length - 1 ? 0 : extrudedWallIndex + 1;

    return storeyWalls[index];
  }, [restWallsPoints, extrudedWallData, storey, storeyWalls]);

  const showFirstDynamicWall = useMemo(() => {
    return restExtrudedWallsGuids
      ? !restExtrudedWallsGuids?.some((guid) => guid === firstClosestWall.guid)
      : true;
  }, [firstClosestWall, restExtrudedWallsGuids]);

  const secondClosestWall = useMemo(() => {
    const extrudedWallIndex = storeyWalls.findIndex(
      (wall) => wall.guid === extrudedWallGuid
    );
    const index =
      extrudedWallIndex === 0 ? storeyWalls.length - 1 : extrudedWallIndex - 1;
    return storeyWalls[index];
  }, [restWallsPoints, extrudedWallData, storey, storeyWalls]);

  const showSecondDynamicWall = useMemo(() => {
    return restExtrudedWallsGuids
      ? !restExtrudedWallsGuids?.some((guid) => guid === secondClosestWall.guid)
      : true;
  }, [secondClosestWall, restExtrudedWallsGuids]);

  const getIntersectionPointsForWall = (wall: UserBuildingWall) => {
    if (!extrudedWallData) return null;
    const intersectionPoint = convertFlatVector3ToVector(
      extrudedWallData.points.find((point) =>
        wall.points.find((closestWallPoint) => isEqual(closestWallPoint, point))
      )!
    );

    const directionPoint = convertFlatVector3ToVector(
      wall.points.find(
        (point) =>
          point[1] === intersectionPoint.y &&
          point[0] !== intersectionPoint.x &&
          point[2] !== intersectionPoint.z
      )!
    );

    const extrudeWallPoints = extrudedWallData!.points.find(
      (point) =>
        point[1] === intersectionPoint.y &&
        point[0] !== intersectionPoint.x &&
        point[2] !== intersectionPoint.z
    )!;

    const isFlatDirection = isPointsInOneLine(
      [directionPoint.x, directionPoint.z],
      [intersectionPoint.x, intersectionPoint.z],
      [extrudeWallPoints[0], extrudeWallPoints[2]]
    );

    const perpendicularDirectionNormalized = getPerpendicularVectorToVectors(
      [
        convertFlatVector3ToVector(extrudedWallData!.points[0]),
        convertFlatVector3ToVector(extrudedWallData!.points[1]),
      ],
      !isBuildingClockwise
    );
    const perpendicularDirection = getTranslatedVector(
      directionPoint,
      1000000,
      perpendicularDirectionNormalized
    );

    perpendicularDirection.setY(intersectionPoint.y);

    const isIncludedInMultipleExtrude =
      restExtrudedWallsGuids?.some((guid) => guid === wall.guid) ||
      affectedWallsByExtrude?.has(wall.guid);

    if (isFlatDirection && !isIncludedInMultipleExtrude) {
      restWallsPoints.push({ ...wall, guid: uuidv7() });
    }

    return {
      directionPoint: isFlatDirection ? perpendicularDirection : directionPoint,
      intersectionPoint,
      staticPoint: directionPoint,
      isFlatDirection,
    };
  };

  const isBuildingClockwise = useMemo(() => {
    const clockwiseRing = lineString(
      floorPoints.map((point) => [point[0], point[2]])
    );
    return booleanClockwise(clockwiseRing);
  }, [floorPoints]);

  const intersectedPointsForFirstWall = useMemo(
    () => getIntersectionPointsForWall(firstClosestWall),
    [firstClosestWall, extrudedWallData]
  );

  const intersectedPointsForSecondWall = useMemo(
    () => getIntersectionPointsForWall(secondClosestWall),
    [secondClosestWall, extrudedWallData]
  );

  const perpendicularDirectionForWallPoints = useMemo(() => {
    if (!extrudedWallData) return new THREE.Vector3();

    const min = Math.min(...extrudedWallData.points.map((c) => c[1]));
    const bottomCoordinates = extrudedWallData.points.filter(
      (points) => points[1] === min
    );
    return getPerpendicularVectorToVectors(
      convertFlatVector3ToVectors(bottomCoordinates),
      isBuildingClockwise
    );
  }, [extrudedWallData, isBuildingClockwise]);

  const startExtruding = () => {
    hideSelectionBoxArea();
    dispatch(
      setShowDirectionalInput({
        isShow: true,
      })
    );
    dispatch(
      setDirectionalInputValues([
        {
          type: DistanceInput.Distance,
          display: true,
          processing: true,
        },
      ])
    );
  };
  const extrudeWall = () => {
    if (!isDirectionalInputActive) {
      if (
        !cursorPosition ||
        (cursorPosition?.x === 0 &&
          cursorPosition?.y === 0 &&
          cursorPosition?.z === 0)
      )
        return;
      if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
        return;

      cursorPosition.setY(intersectedPointsForFirstWall.intersectionPoint.y);

      const distanceBetweenMouseAndWall =
        getDistanceBetweenInfiniteLineAndVector(
          new THREE.Line3(
            intersectedPointsForFirstWall.intersectionPoint,
            intersectedPointsForSecondWall.intersectionPoint
          ),
          cursorPosition
        );

      const isCursorFromLeftSide = isVectorLeftSide(
        intersectedPointsForFirstWall.intersectionPoint,
        intersectedPointsForSecondWall.intersectionPoint,
        cursorPosition
      );

      const distanceFromCursor = isCursorFromLeftSide
        ? distanceBetweenMouseAndWall
        : -distanceBetweenMouseAndWall;

      const distance = !isBuildingClockwise
        ? distanceFromCursor
        : -distanceFromCursor;

      if (maxPositiveDistance && distance > maxPositiveDistance) {
        fixIsMaxDistanceNotApplied(maxPositiveDistance);
        return;
      }
      if (maxNegativeDistance && distance < maxNegativeDistance) {
        fixIsMaxDistanceNotApplied(maxNegativeDistance);
        return;
      }

      updateDirectionalInputValues(distance);
      extrudeFloor(distance);
    } else {
      if (processingEntity.type === DistanceInput.Distance) {
        extrudeFloor(
          convertMillimetersToMeters(processingEntity.value) * multiplyRate
        );
      }
    }
  };

  const calculateDistance = (
    directionPoint: THREE.Vector3,
    intersectionPoint: THREE.Vector3,
    distance: number
  ) => {
    const direction = new THREE.Vector3()
      .subVectors(intersectionPoint, directionPoint)
      .normalize();

    const angleToNormal =
      perpendicularDirectionForWallPoints.angleTo(direction);

    const restrictedDistance = getRestrictedDistance(distance);
    return angleToNormal > 1.57 ? restrictedDistance : -restrictedDistance;
  };

  const intersectPointForLines = useMemo(() => {
    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return new THREE.Vector3();
    const firstLineEnd = getExtendedVector(
      intersectedPointsForFirstWall.intersectionPoint,
      intersectedPointsForFirstWall.directionPoint,
      -1000
    );
    const secondLineEnd = getExtendedVector(
      intersectedPointsForSecondWall.intersectionPoint,
      intersectedPointsForSecondWall.directionPoint,
      -1000
    );
    const firstLineStart = getExtendedVector(
      intersectedPointsForFirstWall.intersectionPoint,
      intersectedPointsForFirstWall.directionPoint,
      1000
    );
    const secondLineStart = getExtendedVector(
      intersectedPointsForSecondWall.intersectionPoint,
      intersectedPointsForSecondWall.directionPoint,
      1000
    );

    const intersect = checkLineIntersection(
      firstLineStart,
      firstLineEnd,
      secondLineStart,
      secondLineEnd
    );

    return new THREE.Vector3(
      intersect.x ?? Infinity,
      secondLineEnd.y,
      intersect.y ?? Infinity
    );
  }, [intersectedPointsForSecondWall, intersectedPointsForFirstWall]);

  const getMaxDistanceForWallExtrude = (
    intersectionPoint: THREE.Vector3,
    directionPoint: THREE.Vector3
  ) => {
    const vector = getExtendedVector(intersectionPoint, directionPoint, 0);
    return intersectPointForLines.distanceTo(vector);
  };

  const geometryVectorPositions = useMemo(() => {
    // if (!extrudeFloorShape) return null;
    const position = [...floorPoints];
    const firstWall: number[] = [];
    const secondWall: number[] = [];
    for (let i = 0; i < position.length; i++) {
      const indexForFirstWall = new THREE.Vector3(
        position[i][0],
        position[i][1],
        position[i][2]
      ).equals(
        intersectedPointsForFirstWall?.intersectionPoint ?? new THREE.Vector3()
      );
      indexForFirstWall && firstWall.push(i);
      const indexForSecondWall = new THREE.Vector3(
        position[i][0],
        position[i][1],
        position[i][2]
      ).equals(
        intersectedPointsForSecondWall?.intersectionPoint ?? new THREE.Vector3()
      );
      indexForSecondWall && secondWall.push(i);
    }

    return {
      firstWall,
      secondWall,
    };
  }, []);

  const getRestrictedDistance = (distance: number) => {
    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return distance;

    const isExtrudeIn = distance < 0;

    const isFirstWallDirectionPointBehind =
      isVectorLeftSide(
        intersectedPointsForFirstWall.intersectionPoint,
        intersectedPointsForSecondWall.intersectionPoint,
        intersectedPointsForFirstWall.directionPoint
      ) == isBuildingClockwise;

    const isSecondWallDirectionPointBehind =
      isVectorLeftSide(
        intersectedPointsForFirstWall.intersectionPoint,
        intersectedPointsForSecondWall.intersectionPoint,
        intersectedPointsForSecondWall.directionPoint
      ) === isBuildingClockwise;

    const firstWallDistance = isFirstWallDirectionPointBehind
      ? -intersectedPointsForFirstWall.directionPoint.distanceTo(
          intersectedPointsForFirstWall.intersectionPoint
        )
      : -999;

    const secondWallDistance = isSecondWallDirectionPointBehind
      ? -intersectedPointsForSecondWall.directionPoint.distanceTo(
          intersectedPointsForSecondWall.intersectionPoint
        )
      : -999;

    setMaxNegativeDistance &&
      setMaxNegativeDistance(Math.max(firstWallDistance, secondWallDistance));

    return isExtrudeIn
      ? Math.max(
          distance,
          ...(maxNegativeDistance
            ? [maxNegativeDistance]
            : [firstWallDistance, secondWallDistance])
        )
      : distance;
  };

  const calculateTranslatedVector = (
    intersectionPoint: THREE.Vector3,
    directionPoint: THREE.Vector3,
    distance: number
  ) => {
    const translatedVector = getExtendedVector(
      intersectionPoint,
      directionPoint,
      calculateDistance(directionPoint, intersectionPoint, distance)
    );

    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return translatedVector;

    const maxDistance = getMaxDistanceForWallExtrude(
      intersectionPoint,
      directionPoint
    );

    const isExtrudeOutDirection =
      isVectorLeftSide(
        intersectedPointsForSecondWall.intersectionPoint,
        intersectedPointsForFirstWall.intersectionPoint,
        translatedVector
      ) === isBuildingClockwise;

    const isIntersectionPointAtCurrentDirection = isVectorLeftSide(
      intersectedPointsForFirstWall.intersectionPoint,
      intersectedPointsForSecondWall.intersectionPoint,
      intersectPointForLines
    );

    const isPointIntersected =
      maxDistance < distance &&
      isExtrudeOutDirection &&
      isIntersectionPointAtCurrentDirection;

    setMaxPositiveDistance && setMaxPositiveDistance(maxDistance);

    return isPointIntersected ? intersectPointForLines : translatedVector;
  };

  const addExtraPointForFlatWall = (
    position: FlatVector3[],
    index: number,
    point: THREE.Vector3,
    isRightSidePoint: boolean
  ) => {
    const idx = isRightSidePoint ? index : index + 1;
    return [...position.slice(0, idx), getXYZ(point), ...position.slice(idx)];
  };

  const extrudeFloor = (distance: number) => {
    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return;

    const firstTranslatedVector = calculateTranslatedVector(
      intersectedPointsForFirstWall.intersectionPoint,
      intersectedPointsForFirstWall.directionPoint,
      distance
    );

    const secondTranslatedVector = calculateTranslatedVector(
      intersectedPointsForSecondWall.intersectionPoint,
      intersectedPointsForSecondWall.directionPoint,
      distance
    );

    let position = [...floorPoints];

    geometryVectorPositions?.firstWall.forEach((index) => {
      position[index] = [
        firstTranslatedVector.x,
        firstTranslatedVector.y,
        firstTranslatedVector.z,
      ];
    });
    geometryVectorPositions?.secondWall.forEach((index) => {
      position[index] = [
        secondTranslatedVector.x,
        secondTranslatedVector.y,
        secondTranslatedVector.z,
      ];
    });

    //add point for flat extruded walls
    if (
      intersectedPointsForFirstWall.isFlatDirection &&
      geometryVectorPositions
    ) {
      position = addExtraPointForFlatWall(
        position,
        geometryVectorPositions.firstWall[0],
        intersectedPointsForFirstWall.intersectionPoint,
        false
      );
    }

    //add point for flat extruded walls
    if (
      intersectedPointsForSecondWall.isFlatDirection &&
      geometryVectorPositions
    ) {
      position = addExtraPointForFlatWall(
        position,
        geometryVectorPositions.secondWall[0],
        intersectedPointsForSecondWall.intersectionPoint,
        true
      );
    }

    //remove a point from a contour when extruding out, and extruded wall compressed at one point
    if (firstTranslatedVector.distanceTo(secondTranslatedVector) === 0) {
      const mergedPointIndex = position.findIndex((point) =>
        isEqual(point, getXYZ(firstTranslatedVector))
      );
      position.splice(mergedPointIndex, 1);
    }

    //remove a point from a contour when extruding in

    if (
      firstTranslatedVector.distanceTo(
        intersectedPointsForFirstWall.directionPoint
      ) < 0.000001
    ) {
      const mergedPointIndex = position.findIndex((point) =>
        isEqual(point, getXYZ(firstTranslatedVector))
      );
      position.splice(mergedPointIndex, 1);
    }

    //remove a point from a contour when extruding in
    if (
      secondTranslatedVector.distanceTo(
        intersectedPointsForSecondWall.directionPoint
      ) < 0.000001
    ) {
      const mergedPointIndex = position.findIndex((point) =>
        isEqual(point, getXYZ(secondTranslatedVector))
      );
      position.splice(mergedPointIndex, 1);
    }

    //contour adjustment when the double point has shrunk and the contour remains unclosed
    if (isEqual(position[position.length], position[position.length])) {
      position.splice(position.length - 1, 1);
      position.push(position[0]);
    }

    updateFloor && updateFloor([firstTranslatedVector, secondTranslatedVector]);

    generateDynamicWalls(firstTranslatedVector, secondTranslatedVector);
    handleExtrudeHandlerPosition && handleExtrudeHandlerPosition(distance);
  };

  const generateDynamicWalls = (
    firstTranslatedVector: THREE.Vector3,
    secondTranslatedVector: THREE.Vector3
  ) => {
    if (!intersectedPointsForFirstWall || !intersectedPointsForSecondWall)
      return;

    const staticPointForFirstWall =
      intersectedPointsForFirstWall.isFlatDirection
        ? intersectedPointsForFirstWall.intersectionPoint
        : intersectedPointsForFirstWall.staticPoint;

    const staticPointForSecondWall =
      intersectedPointsForSecondWall.isFlatDirection
        ? intersectedPointsForSecondWall.intersectionPoint
        : intersectedPointsForSecondWall.staticPoint;

    const pointForFirstWall = [
      firstTranslatedVector,
      staticPointForFirstWall,
      staticPointForFirstWall.clone().setY(floorHeight),
      firstTranslatedVector.clone().setY(floorHeight),
    ];
    const pointForSecondWall = [
      staticPointForSecondWall,
      secondTranslatedVector,
      secondTranslatedVector.clone().setY(floorHeight),
      staticPointForSecondWall.clone().setY(floorHeight),
    ];

    const pointForCenterWall = [
      secondTranslatedVector,
      firstTranslatedVector,
      firstTranslatedVector.clone().setY(floorHeight),
      secondTranslatedVector.clone().setY(floorHeight),
    ];

    updateWalls &&
      updateWalls([
        {
          wallGUID: firstClosestWall.guid,
          points: pointForFirstWall,
          isFlat: intersectedPointsForFirstWall.isFlatDirection,
        },
        {
          wallGUID: secondClosestWall.guid,
          points: pointForSecondWall,
          isFlat: intersectedPointsForSecondWall.isFlatDirection,
        },
        { wallGUID: extrudedWallGuid, points: pointForCenterWall },
      ]);

    handleDynamicWall(
      firstDynamicWall,
      pointForFirstWall,
      setFirstDynamicWall,
      true
    );
    handleDynamicWall(
      secondDynamicWall,
      pointForSecondWall,
      setSecondDynamicWall,
      true
    );
    handleDynamicWall(
      extrudeDynamicWall,
      pointForCenterWall,
      setExtrudeDynamicWall,
      true,
      true
    );
  };

  const handleDynamicWall = (
    wall: THREE.Mesh,
    points: THREE.Vector3[],
    setter: (value: React.SetStateAction<THREE.Mesh>) => void,
    withBorders?: boolean,
    highlightWall?: boolean
  ) => {
    const closedPoints = [...points, points[0]];

    if (!wall) {
      const geometry = createGeometryFromVectorList(points, 'vertical');
      const material = highlightWall
        ? C_WallSelectedMaterial.clone()
        : C_WallMaterial.clone();
      const borderMaterial = C_FatLineBorderMaterial.clone();
      if (highlightWall) {
        borderMaterial.color = BUILDING_SELECTED_CONTOUR_COLOR;
        borderMaterial.polygonOffset = true;
        borderMaterial.polygonOffsetFactor =
          POLYGON_OFFSET_FACTOR_LEVELS.MEDIUM;
        material.polygonOffset = true;
        material.polygonOffsetFactor = POLYGON_OFFSET_FACTOR_LEVELS.MEDIUM;
      }

      const mesh = new THREE.Mesh(geometry, material);
      withBorders &&
        mesh.add(
          createLine2(
            flatten(closedPoints.map((point) => [point.x, point.y, point.z])),
            borderMaterial
          )
        );
      setter(mesh);
    } else {
      wall.geometry.setFromPoints(points);
      triangulateGeometryAndUpdate(wall.geometry, points, 'vertical');
      withBorders &&
        updateLine2Position(
          wall.children[0] as Line2,
          flatten(closedPoints.map((point) => [point.x, point.y, point.z]))
        );
    }
  };

  const fixIsMaxDistanceNotApplied = (distance: number) => {
    if (
      convertMetersToMillimeters(
        (distance / multiplyRate).toFixed(2).toString()
      ).toString() !== processingEntity.value
    ) {
      updateDirectionalInputValues(distance);
      extrudeFloor(distance);
    }
  };

  const updateDirectionalInputValues = (distance: number) => {
    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          value: convertMetersToMillimeters(
            (distance / multiplyRate).toString()
          ).toString(),
        },
      ])
    );
  };

  const onInputSet = (evt: CustomEvent) => {
    const distance = convertMillimetersToMeters(evt.detail) * multiplyRate;
    extrudeFloor(distance);
  };
  const onInputUpdate = (evt: CustomEvent) => {
    const distance = convertMillimetersToMeters(evt.detail) * multiplyRate;
    extrudeFloor(distance);
  };

  useEffect(() => {
    cursorPosition && extrudeWall();
  }, [cursorPosition]);

  useEffect(() => {
    subscribe(DIRECTIONAL_INPUT__SET, onInputSet);
    subscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    return () => {
      unsubscribe(DIRECTIONAL_INPUT__SET, onInputSet);
      unsubscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    };
  }, [
    storey,
    extrudeData,
    intersectPointForLines,
    firstDynamicWall,
    secondDynamicWall,
    extrudeDynamicWall,
    isDirectionalInputActive,
    processingEntity,
    multiplyRate,
    intersectedPointsForSecondWall,
    intersectedPointsForFirstWall,
  ]);

  useEffect(() => {
    if (maxNegativeDistance) {
      const distanceInMillimeters = Number(
        convertMetersToMillimeters(maxNegativeDistance / multiplyRate)
      );
      const maxNegativeDistanceInMillimeters = Number(
        distanceInMillimeters.toFixed(0)
      );

      dispatch(
        setDirectionalInputValues([
          {
            ...processingEntity,
            min: maxNegativeDistanceInMillimeters,
            max: MetricLimits.WidthLengthMax,
          },
        ])
      );
    }
  }, [maxNegativeDistance]);

  useEffect(() => {
    startExtruding();
    extrudeFloor(0);
  }, []);

  useEffect(() => {
    if (affectedWallsByExtrude) {
      !intersectedPointsForFirstWall?.isFlatDirection &&
        affectedWallsByExtrude.add(firstClosestWall.guid);
      !intersectedPointsForSecondWall?.isFlatDirection &&
        affectedWallsByExtrude.add(secondClosestWall.guid);
    }
  }, [
    firstClosestWall,
    secondClosestWall,
    intersectedPointsForSecondWall,
    intersectedPointsForFirstWall,
  ]);

  useUnmount(() => {
    disposeNode(firstDynamicWall);
    disposeNode(secondDynamicWall);
    disposeNode(extrudeDynamicWall);
  });

  return (
    <group
      key={`group-${storey.guid}_${extrudedWallData?.guid}`}
      userData={{
        ...storey.userData,
        nodeType: NodeType.Storey,
      }}
    >
      {firstDynamicWall && showFirstDynamicWall && (
        <primitive object={firstDynamicWall} />
      )}
      {secondDynamicWall && showSecondDynamicWall && (
        <primitive object={secondDynamicWall} />
      )}
      {extrudeDynamicWall && (
        <primitive
          object={extrudeDynamicWall}
          onPointerMove={(e: ThreeEvent<PointerEvent>) => {
            e.cancelBubble = true;
            e.stopPropagation();
          }}
        />
      )}
    </group>
  );
};

export default ExtrudeWall;
