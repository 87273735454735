import React from 'react';
import PanelView from '@/components/FacadeDesigner/elements/PanelView';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import ErrorPanel from '@/components/FacadeDesigner/elements/ErrorPanel';
import { UnitSystemTypes } from '@/models';
import { useUnmount } from 'react-use';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getDragCopyOffset,
  removeFromSelectedPlacedPanels,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useParams } from 'react-router';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import { getPanelWidth } from '@/shared/helpers';
import { PanelConfig } from '@/models/window-configurator.model';
import { isEqual } from 'lodash';

interface FacadeDesignerWallPlacementViewProps {
  scale: number;
  wallData: WallSearchResults;
  wallHeight: number;
  wallOffset: number;
  unitSystem: UnitSystemTypes;
  config: PanelConfig;
  isProjectLocked: boolean;
}

const FacadeDesignerWallPanelsView: React.FC<
  FacadeDesignerWallPlacementViewProps
> = ({
  scale,
  wallData,
  wallHeight,
  unitSystem,
  config,
  wallOffset,
  isProjectLocked,
}) => {
  const dispatch = useAppDispatch();
  const dragCopyOffset = useAppSelector(getDragCopyOffset);

  useUnmount(() => {
    wallData.wallPanels.forEach((panel) =>
      dispatch(removeFromSelectedPlacedPanels(panel.guid))
    );
  });

  const { id } = useParams();
  const allPanels = useGetAllPanelsQuery(id!).data;
  return (
    <>
      {wallData.wallPanels.map((panel, i) => {
        const panelData = allPanels?.find(
          (panelData) => panelData.id === panel.panelId
        );
        if (!panelData) return null;
        return (
          <PanelView
            config={config}
            panelPlacementData={panel}
            key={`${panel.panelId}_${i}`}
            wallGUID={wallData.guid}
            scale={scale}
            panelInfo={panelData}
            panelWidth={getPanelWidth(panelData)}
            dragCopyOffset={dragCopyOffset}
            wallOffset={wallOffset}
            isProjectLocked={isProjectLocked}
          />
        );
      })}

      {wallData.wallPanelErrors.map((error, i) => (
        <ErrorPanel
          wallGUID={wallData.guid}
          errorData={error}
          scale={scale}
          wallHeight={wallHeight}
          key={`${wallData.guid}_panel_error_${i}`}
          unitSystem={unitSystem}
        />
      ))}
    </>
  );
};

export default React.memo(FacadeDesignerWallPanelsView, isEqual);
