import React, { useMemo } from 'react';
import { EditModes, NodeType, UserBuildingBlock } from '@/models';
import Storey from '@/routes/dashboard/projects/project/UserBuilding/components/Storey';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getEditedNode,
  getIsEditToolsAvailable,
  getIsNodeEdited,
  getIsNodeHovered,
  getIsNodeIsolated,
  getIsNodeSelected,
  getIsSingleNodeSelected,
  isSingleNodeIsolated as isSingleNodeIsolatedSelector,
  setExtrudeData,
} from '@/store/slices/canvasBuildingSlice';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { GenericChildSurface } from '@/models/building-nodes.model';
import ExtrudeDotHandler, {
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import { Vector3 } from 'three';
import { flatten } from 'lodash';
import {
  getCenterFromFlatVectorsArray,
  getPerpendicularVectorToVectors,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  getEditMode,
  getIsFacadeDesignerModeEnabled,
} from '@/store/slices/canvasModesSlice';
import CutTool from '@/routes/dashboard/projects/project/UserBuilding/components/CutTool/CutTool';
import BlockContour from '@/routes/dashboard/projects/project/UserBuilding/components/BlockContour';
import SplitTool from '@/routes/dashboard/projects/project/UserBuilding/components/SplitTool/SplitTool';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { getIsDraggedWindowFromLibrary } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import MultipleExtrudeHandlers from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/MultipleExtrudeHandlers';
import { isCopyingProperties } from '@/store/slices/copyPropertiesSlice';

interface BlockProps extends GenericChildSurface {
  block: UserBuildingBlock;
}

const Block: React.FC<BlockProps> = ({
  block,
  isParentSelected,
  isParentLocked,
  isParentHovered,
  buildingGUID,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const projectData = useFetchProjectQuery(id!).data!;
  const isNodeSelected = useAppSelector(getIsNodeSelected(block.guid));
  const isBlockIsolated = useAppSelector(getIsNodeIsolated(block.guid));
  const isCopyingProcessing = useAppSelector(isCopyingProperties);
  const editMode = useAppSelector(getEditMode);
  const editedNode = useAppSelector(getEditedNode);
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const isDesignerMode = useAppSelector(getIsFacadeDesignerModeEnabled);
  const isWindowCardDragging = useAppSelector(getIsDraggedWindowFromLibrary);
  const isSingleNodeIsolated = useAppSelector(isSingleNodeIsolatedSelector);
  const isBlockHovered = useAppSelector(getIsNodeHovered(block.guid));
  const isNodeEdited = useAppSelector(getIsNodeEdited(block.guid));
  const isEditToolsAvailable = useAppSelector(
    getIsEditToolsAvailable(buildingGUID)
  );
  const isSingleNodeSelected = useAppSelector(getIsSingleNodeSelected);

  const { getNodeData } = useFindNodeData();
  const { facadesData } = useFacadeData();

  const { isIsolateModeEnabled } = useIsolationHandlers();

  const isExtrudeAvailable =
    !projectData.locked &&
    !block.userData?.isHidden &&
    !block.userData?.isLocked &&
    !isParentLocked &&
    isEditToolsAvailable &&
    (!isIsolateModeEnabled || (isIsolateModeEnabled && isBlockIsolated)) &&
    !isDesignerMode;

  const blockFacedCentersData = useMemo((): ExtrudeHandlerData[] => {
    const extrudeHandlerPointsData: {
      point: Vector3;
      perpendicular: Vector3;
      facadeWallsGuids: string[];
    }[] = [];

    const blockFacades = facadesData.filter((facade) =>
      facade.some(
        (wall) =>
          getNodeData({
            guid: wall.guid,
            nodeType: NodeType.Wall,
          })?.getParentNode(NodeType.Block)?.guid === block.guid
      )
    );
    blockFacades.forEach((walls) => {
      const allPoints = flatten(walls.map((w) => w.points));
      const center = getCenterFromFlatVectorsArray(allPoints);
      const directionPoints = convertFlatVector3ToVectors(walls[0].points);

      const perpendicular = getPerpendicularVectorToVectors(
        directionPoints,
        true
      );

      extrudeHandlerPointsData.push({
        point: center,
        facadeWallsGuids: walls.map((wall) => wall.guid),
        perpendicular,
      });
    });

    return extrudeHandlerPointsData.map((pointData) => ({
      perpendicularDirection: getXYZ(pointData.perpendicular),
      defaultCenter: [pointData.point.x, pointData.point.y, pointData.point.z],
      facadeWallsGuids: pointData.facadeWallsGuids,
    }));
  }, [block, facadesData]);

  const handleSelectWallForExtrude = (node: ExtrudeHandlerData) => {
    dispatch(setExtrudeData(node));
  };

  const showContour =
    !isCopyingProcessing &&
    (isParentSelected || isParentHovered || isBlockHovered || isNodeSelected);

  const isSplitAvailable =
    editedNode &&
    (getNodeData({
      guid: block.guid,
      nodeType: NodeType.Block,
    })?.childNodes.some((node) => node.guid === editedNode?.guid) ||
      editedNode.guid === block.guid) &&
    editMode === EditModes.Split;

  return (
    <>
      <group
        key={block.guid}
        userData={{
          ...block.userData,
          nodeType: NodeType.Block,
          guid: block.guid,
        }}
      >
        {block.storeys
          .filter((storey) => !storey.userData?.isHidden)
          .map((storey) => {
            return (
              <Storey
                blockGUID={block.guid}
                isolateMode={isIsolateModeEnabled}
                storey={storey}
                isProjectLocked={projectData.locked}
                isParentEdited={isNodeEdited}
                isParentSelected={isParentSelected || isNodeSelected}
                isParentHovered={isBlockHovered || isParentHovered}
                isParentLocked={block.userData?.isLocked || isParentLocked}
                key={storey.guid}
                buildingGUID={buildingGUID}
                isSingleNodeSelected={isSingleNodeSelected}
                isEditToolsAvailable={isEditToolsAvailable}
                multiplyRate={multiplyRate}
                isDesignerMode={isDesignerMode}
                isWindowCardDragging={isWindowCardDragging}
                isSingleNodeIsolated={isSingleNodeIsolated}
                editedNode={editedNode}
              />
            );
          })}
        {isExtrudeAvailable &&
          isNodeSelected &&
          isSingleNodeSelected &&
          blockFacedCentersData.map((handlerPoint, i) => {
            return (
              <ExtrudeDotHandler
                extrudeHandlerData={handlerPoint}
                key={i}
                clickAction={() => handleSelectWallForExtrude(handlerPoint)}
              />
            );
          })}
        <BlockContour block={block} isInteracted={showContour} />
      </group>

      {editMode === EditModes.Cut && editedNode?.guid === block.guid && (
        <CutTool block={block} buildingGUID={buildingGUID} />
      )}
      {isSplitAvailable && (
        <SplitTool block={block} buildingGUID={buildingGUID} />
      )}
      {isExtrudeAvailable && <MultipleExtrudeHandlers />}
    </>
  );
};

export default Block;
