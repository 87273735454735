import React from 'react';
import UTCMullion from '@/components/UTC/components/UTCMullion';
import { FlatVector2 } from '@/models';
import { MullionData } from '@/models/window-configurator.model';

interface UTCMullionsProps {
  framePoints: FlatVector2[];
  mullionData: MullionData[];
  color: string;
}

const UTCMullions = ({ framePoints, mullionData, color }: UTCMullionsProps) => {
  return (
    <>
      {mullionData.map((mullion, index) => {
        return (
          <UTCMullion
            key={index}
            framePoints={framePoints}
            mullionData={mullion}
            color={color}
          />
        );
      })}
    </>
  );
};

export default UTCMullions;
