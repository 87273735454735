import {
  IntusButton,
  IntusDropdown,
  IntusIconButton,
  IntusLoader,
  IntusModal,
  IntusSwitcher,
} from '@/shared/elements';
import React, { useEffect, useState } from 'react';

import { CustomModalProps, UnitTypeCreatorModes } from '@/models/shared.model';

import { useUnmount, useWindowSize } from 'react-use';

import {
  STAGE_HEIGHT_RATIO,
  STAGE_WIDTH_RATIO,
} from '@/components/WindowCreator/constants';
import {
  FlatVector2Axis,
  MeasurementUpdateData,
  View,
} from '@/components/WindowCreator/models';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import {
  useCreateUnitMutation,
  useUpdateUnitMutation,
} from '@/store/apis/unitApi';
import { CustomError, FlatVector2, UnitSystemTypes, UTCModes } from '@/models';
import Form from 'antd/es/form';
import {
  ArrowLeftIcon,
  CreateMullionIcon,
  CursorIcon,
  MirrorWindowIcon,
  RotateIcon,
  VectorDownIcon,
} from '@/shared/icons';
import EditableName from '@/shared/components/EditableNameInput/EditableName';
import { useForm } from 'antd/es/form/Form';
import { useWatch } from 'antd/es/form/Form';
import UTCModalContainer from '@/components/UTC/UTCModalContainer';
import { UTCData, UTCFormData } from '@/components/UTC/models';
import {
  generateDefaultFrame,
  generateDefaultUnit,
  generateMirroredStraightUnit,
} from '@/components/UTC/helpers/generators';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getUTCMode } from '@/store/slices/canvasModesSlice';
import {
  DEFAULT_UNIT_HEIGHT,
  DEFAULT_UNIT_WIDTH,
} from '@/components/UTC/helpers/constants';
import {
  getInitialData,
  getUnitTypeCreatorMode,
  resetMullionArea,
  resetUTCSlice,
  setUnitTypeCreatorMode,
  clearSelectRelatedData,
  getSomeFillerHaveValidationErrors,
} from '@/store/slices/windowsReducer/UTCSlice';
import { subscribe, unsubscribe } from '@/core/events';
import {
  CREATE_UTC_MULLION,
  DELETE_UTC_MULLION,
  UTC_MEASUREMENT_SUBMIT,
} from '@/core/event-names';
import {
  addUTCMullion,
  deleteMullion,
  getNumberedInnerFrames,
  updateUTCFormData,
} from '@/components/UTC/helpers/form-data';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { MullionData } from '@/models/window-configurator.model';
import { MenuProps } from 'antd/es/menu';
import { setFormErrorFromServer } from '@/shared/form/error-handlers';
import GlobalUnitSettings from './externalElements/GlobalUnitSettings';

interface UTCModalProps extends CustomModalProps {
  projectId: string;
}

const UTCModal = ({ isOpen, setIsOpen, projectId }: UTCModalProps) => {
  const dispatch = useAppDispatch();
  const { width, height } = useWindowSize();
  const utcMode = useAppSelector(getUTCMode);
  const config = useFetchWindowConfigQuery().data!;
  const [form] = useForm<UTCFormData>();
  const mode = useAppSelector(getUnitTypeCreatorMode);

  const [isLoading, setIsLoading] = useState(false);
  const [createUnit] = useCreateUnitMutation();
  const [updateUnit] = useUpdateUnitMutation();

  const handleKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      event.stopPropagation();

      setIsOpen(false);
    }
  };

  const handleSwitchView = () => {
    const mirroredUnitFormData: UTCData = generateMirroredStraightUnit(
      form.getFieldsValue(true),
      false
    );
    form.setFieldsValue({
      ...mirroredUnitFormData,
      view:
        form.getFieldValue('view') === View.Outside
          ? View.Inside
          : View.Outside,
    });
  };

  const handleCreateMullion = ({
    detail,
  }: {
    detail: { axis: FlatVector2Axis; framePoints: FlatVector2[] };
  }) => addUTCMullion(detail, form);

  const handleMeasurementSubmit = ({
    detail,
  }: {
    detail: MeasurementUpdateData;
  }) => {
    dispatch(clearSelectRelatedData());
    dispatch(resetMullionArea());
    updateUTCFormData({
      form,
      updateData: detail,
    });
  };

  const handleDeleteMullion = ({ detail }: { detail: MullionData }) => {
    deleteMullion(detail, form, config.baseUnit);
  };

  useEffect(() => {
    subscribe(CREATE_UTC_MULLION, handleCreateMullion);
    subscribe(DELETE_UTC_MULLION, handleDeleteMullion);
    subscribe(UTC_MEASUREMENT_SUBMIT, handleMeasurementSubmit);
    return () => {
      unsubscribe(CREATE_UTC_MULLION, handleCreateMullion);
      unsubscribe(DELETE_UTC_MULLION, handleDeleteMullion);
      unsubscribe(UTC_MEASUREMENT_SUBMIT, handleMeasurementSubmit);
    };
  }, []);

  useUnmount(() => {
    dispatch(resetUTCSlice());
  });

  const getIconFillColor = (expectedMode: UnitTypeCreatorModes): string => {
    return expectedMode === mode ? '#fff' : '#414042';
  };

  const handleModeChange = (mode: UnitTypeCreatorModes) => {
    dispatch(setUnitTypeCreatorMode(mode));
    dispatch(clearSelectRelatedData());
  };

  const projectData = useFetchProjectQuery(projectId).data!;
  const initialMeasurementSystem: UnitSystemTypes = projectData.unitSystem;

  const initialUnitPoints = generateDefaultUnit(
    DEFAULT_UNIT_WIDTH,
    DEFAULT_UNIT_HEIGHT
  );

  const initialFormData = useAppSelector(getInitialData);
  const isSomeFillerHasValidationError = useAppSelector(
    getSomeFillerHaveValidationErrors
  );

  const formInitialValues: UTCFormData = initialFormData || {
    name: utcMode === UTCModes.Straight ? 'UCW unit' : 'UCW corner unit',
    mullions: [],
    units: initialMeasurementSystem,
    view: View.Outside,
    innerFrames: [generateDefaultFrame(initialUnitPoints, config.baseUnit)],
    points: initialUnitPoints,
    insideColor: config.baseUnit.insideColor,
    outsideColor: config.baseUnit.outsideColor,
  };

  const handleUnitSubmit = async (actionType: 'create' | 'edit') => {
    try {
      const data: UTCData =
        form.getFieldValue('view') === View.Outside
          ? form.getFieldsValue(true)
          : generateMirroredStraightUnit(form.getFieldsValue(true), false);

      const unitData: UTCData = {
        points: data.points,
        name: data.name,
        mullions: data.mullions,
        innerFrames: getNumberedInnerFrames(data.innerFrames),
        outsideColor: data.outsideColor,
        insideColor: data.insideColor,
        id: data.id,
      };
      if (actionType === 'create') {
        await createUnit({ projectId, data: unitData }).unwrap();
      } else {
        await updateUnit({ data: unitData }).unwrap();
      }
      setIsOpen(false);
    } catch (error) {
      setFormErrorFromServer(form, error as CustomError);
    } finally {
      setIsLoading(false);
    }
  };

  const handleMirrorUnit = () => {
    const mirroredUnit = generateMirroredStraightUnit(
      form.getFieldsValue(true)
    );
    form.setFieldsValue(mirroredUnit);
  };

  const saveMenuItems: MenuProps['items'] = [
    {
      label: <span className="text-xs font-normal">Create new type</span>,
      key: 'createNewType',
      onClick: () => handleUnitSubmit('create'),
    },
    {
      label: <span className="text-xs font-normal">Update existing type</span>,
      key: 'updateNewType',
      onClick: () => handleUnitSubmit('edit'),
    },
  ];

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useWatch(['units'], form);
  useWatch(['view'], form);
  useWatch(['points'], form);
  useWatch(['mullions'], form);
  useWatch(['innerFrames'], form);

  const handleUnitsChange = (units: UnitSystemTypes) => {
    form.setFieldValue('units', units);
  };

  return (
    <IntusModal
      styles={{ body: { padding: 0 } }}
      footer={null}
      open={isOpen}
      centered
      width={width * STAGE_WIDTH_RATIO}
      closable={false}
      keyboard={false}
    >
      <IntusLoader loading={isLoading}>
        <div
          className="relative overflow-hidden"
          style={{
            height: height * STAGE_HEIGHT_RATIO,
          }}
        >
          <Form form={form} initialValues={formInitialValues}>
            <div className={'relative bg-light-gray-10 '}>
              <div className="max-h-8 flex items-center absolute top-4 left-6 pointer-events-auto z-10">
                <IntusIconButton
                  simplified
                  size="small"
                  transparent
                  icon={<ArrowLeftIcon />}
                  onClick={() => setIsOpen(false)}
                  id="UTCModal__back-button"
                />
                <EditableName form={form} id={'utc-modal__edit-name-button'} />
              </div>
              <div className="flex justify-center absolute top-3 left-0 right-0 ml-auto mr-auto w-fit z-10">
                <IntusIconButton
                  id="windowModal__selection-mode-button"
                  type="default"
                  className={'mx-1'}
                  onClick={() =>
                    handleModeChange(UnitTypeCreatorModes.Selection)
                  }
                  icon={
                    <CursorIcon
                      fill={getIconFillColor(UnitTypeCreatorModes.Selection)}
                    />
                  }
                  isActive={UnitTypeCreatorModes.Selection === mode}
                />
                <IntusIconButton
                  id="windowModal__mullion-creator-mode-button"
                  type="default"
                  className={'mx-1'}
                  onClick={() =>
                    handleModeChange(UnitTypeCreatorModes.MullionCreator)
                  }
                  icon={
                    <CreateMullionIcon
                      fill={getIconFillColor(
                        UnitTypeCreatorModes.MullionCreator
                      )}
                    />
                  }
                  isActive={UnitTypeCreatorModes.MullionCreator === mode}
                />
                <IntusIconButton
                  id="windowModal__mirror-button"
                  type="default"
                  className="mx-1"
                  icon={<MirrorWindowIcon />}
                  onClick={handleMirrorUnit}
                />
              </div>
              <div className="absolute top-3 right-6 z-10">
                {initialFormData ? (
                  <IntusDropdown
                    customMenuProps={{
                      items: saveMenuItems,
                    }}
                    placement="bottomRight"
                  >
                    <IntusButton
                      className=" ml-auto text-white px-2 w-[72px]"
                      id="windowModal__save-button"
                      disabled={isSomeFillerHasValidationError}
                    >
                      <div className="flex gap-2 items-center">
                        Save
                        <VectorDownIcon stroke={'#FFFFFF'} />
                      </div>
                    </IntusButton>
                  </IntusDropdown>
                ) : (
                  <IntusButton
                    id="windowModal__save-button"
                    className="ml-auto text-white px-5"
                    onClick={() => handleUnitSubmit('create')}
                    disabled={isSomeFillerHasValidationError}
                  >
                    Save
                  </IntusButton>
                )}
              </div>

              {/*Required to trigger konva changes*/}
              <Form.Item name="points" className="m-0">
                <Form.Item name="mullions" className="m-0">
                  <Form.Item name="units" className="m-0">
                    <Form.Item name="innerFrames" className="m-0">
                      <UTCModalContainer mode={mode} form={form} />
                    </Form.Item>
                  </Form.Item>
                </Form.Item>
              </Form.Item>

              <Form.Item name="innerFrames" className="m-0">
                <Form.Item name="baseColor" className="m-0">
                  <div className="absolute left-6 bottom-6">
                    <GlobalUnitSettings mode={utcMode} />
                  </div>
                </Form.Item>
              </Form.Item>

              <div className="bg-light-gray-20 rounded-lg absolute left-0 right-0 ml-auto mr-auto w-fit bottom-6">
                <Form.Item className="m-0" name="units">
                  <IntusSwitcher
                    onChange={(val) =>
                      handleUnitsChange(val as UnitSystemTypes)
                    }
                    data={[
                      { name: 'ft inch', value: UnitSystemTypes.Imperial },
                      { name: 'mm', value: UnitSystemTypes.Metric },
                    ]}
                  />
                </Form.Item>
              </div>
              <div className="flex items-center absolute right-6 bottom-6 z-10">
                <span className="font-light mr-2">{`${form.getFieldValue('view')} view`}</span>
                <Form.Item className="m-0" name="view">
                  <IntusIconButton
                    type="default"
                    icon={<RotateIcon />}
                    onClick={handleSwitchView}
                    id="utc-modal__switch-view-button"
                  />
                </Form.Item>
              </div>
            </div>
          </Form>
        </div>
      </IntusLoader>
    </IntusModal>
  );
};

export default UTCModal;
