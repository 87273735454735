import gsap from 'gsap';
import { useEffect, RefObject } from 'react';
import { getConstructionSiteCenter } from '@/store/slices/canvasMapSlice';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib/controls/OrbitControls';
import { useUpdateEffect } from 'react-use';
import {
  CanvasCamera,
  CanvasCameraCenterMode,
  CanvasCameraPolarAngles,
  CanvasCameraType,
} from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getAppliedCamera,
  getCameraCenterPosition,
  getCameraPolarAngle,
  getCameraType,
  setCameraType,
} from '@/store/slices/canvasCamerasSlice';
import { useThree } from '@react-three/fiber';
import { extractCameraTypeFromCanvas } from '../helpers/camera';
import * as THREE from 'three';

interface CameraTweenObject {
  positionX: number;
  positionY: number;
  positionZ: number;
  zoom: number;
  targetX: number;
  targetY: number;
  targetZ: number;
  fov?: number;
}

const ANIMATION_DURATION = 0.7;

const CHANGE_CAMERA_ANIMATION_DURATION = 1;

export const useCameraAnimations = (
  cameraControlRef: RefObject<OrbitControlsImpl>
) => {
  const currentCamera = useAppSelector(getCameraType);
  const cameraPolarAngle = useAppSelector(getCameraPolarAngle);
  const cameraCenter = useAppSelector(getCameraCenterPosition);
  const appliedCamera = useAppSelector(getAppliedCamera);
  const constructionSiteCenter = useAppSelector(getConstructionSiteCenter);
  const dispatch = useAppDispatch();

  const { get } = useThree();

  const setPolarAngle = (angleTo: number): gsap.TweenTarget | undefined => {
    const tweenObject = { angle: cameraControlRef.current!.getPolarAngle() };
    gsap
      .to(tweenObject, {
        angle: angleTo,
        duration: ANIMATION_DURATION,
        onUpdate(value) {
          cameraControlRef.current!.setPolarAngle(value.angle);
        },
        onUpdateParams: [tweenObject],
      })
      .play();
    return tweenObject;
  };

  const setTarget = (x: number, y: number, z: number) => {
    const camera = cameraControlRef.current!.object;
    const { target } = cameraControlRef.current!;
    const offset = camera.position.clone().sub(target);
    const spherical = new THREE.Spherical().setFromVector3(offset);
    const newTargetPos = new THREE.Vector3(x, y, z);
    // Helps to avoid shaking when the camera is in the top view
    if (cameraPolarAngle === CanvasCameraPolarAngles.topView)
      spherical.phi = 0.00001;
    // Calculate new camera position based on the new target position and the current spherical coordinates
    const newCameraPos = new THREE.Vector3()
      .setFromSpherical(spherical)
      .add(newTargetPos);

    const tweenObj = {
      cameraX: camera.position.x,
      cameraY: camera.position.y,
      cameraZ: camera.position.z,
      targetX: target.x,
      targetY: target.y,
      targetZ: target.z,
    };

    return gsap.to(tweenObj, {
      cameraX: newCameraPos.x,
      cameraY: newCameraPos.y,
      cameraZ: newCameraPos.z,
      targetX: newTargetPos.x,
      targetY: newTargetPos.y,
      targetZ: newTargetPos.z,
      duration: ANIMATION_DURATION,
      onUpdate(values) {
        camera.position.set(values.cameraX, values.cameraY, values.cameraZ);
        cameraControlRef.current!.target.set(
          values.targetX,
          values.targetY,
          values.targetZ
        );
        camera.lookAt(cameraControlRef.current!.target);
        camera.updateProjectionMatrix();
      },
      onUpdateParams: [tweenObj],
    });
  };

  const waitForCameraUpdateAfterChangingCameraType = () => {
    return new Promise<void>((resolve) => {
      const check = () => {
        if (appliedCamera?.type === extractCameraTypeFromCanvas(get)) {
          resolve();
        } else {
          setTimeout(check, 250);
        }
      };
      check();
    });
  };

  const setAppliedCamera = async (appliedCamera: CanvasCamera) => {
    if (currentCamera !== appliedCamera.type) {
      dispatch(setCameraType(appliedCamera.type));
      await waitForCameraUpdateAfterChangingCameraType();
    }

    if (appliedCamera?.type === CanvasCameraType.Perspective) {
      const perspectiveCamera = cameraControlRef.current
        ?.object as THREE.PerspectiveCamera;

      const cameraProxy = { fov: perspectiveCamera.fov };

      gsap.to(cameraProxy, {
        duration: CHANGE_CAMERA_ANIMATION_DURATION,
        fov: appliedCamera.fov,
        onUpdate: function () {
          perspectiveCamera.fov = cameraProxy.fov;
          get().camera.updateProjectionMatrix();
        },
      });
    }

    const tweenObject: CameraTweenObject = {
      positionX: cameraControlRef.current!.object.position.x,
      positionY: cameraControlRef.current!.object.position.y,
      positionZ: cameraControlRef.current!.object.position.z,
      zoom: cameraControlRef.current!.object.zoom,
      targetX: cameraControlRef.current!.target.x,
      targetY: cameraControlRef.current!.target.y,
      targetZ: cameraControlRef.current!.target.z,
    };

    gsap
      .to(tweenObject, {
        positionX: appliedCamera.position[0],
        positionY: appliedCamera.position[1],
        positionZ: appliedCamera.position[2],
        ...(appliedCamera.type === CanvasCameraType.Orthographic && {
          zoom: appliedCamera.zoom,
        }),
        targetX: appliedCamera.target[0],
        targetY: appliedCamera.target[1],
        targetZ: appliedCamera.target[2],
        duration: CHANGE_CAMERA_ANIMATION_DURATION,
        onUpdate(value) {
          cameraControlRef.current!.object.position.set(
            value.positionX,
            value.positionY,
            value.positionZ
          );
          cameraControlRef.current!.target.set(
            value.targetX,
            value.targetY,
            value.targetZ
          );
          cameraControlRef.current!.object.zoom = value.zoom;
          cameraControlRef.current!.object.updateProjectionMatrix();
        },
        onUpdateParams: [tweenObject],
      })
      .play();

    return tweenObject;
  };

  useUpdateEffect(() => {
    if (cameraPolarAngle === CanvasCameraPolarAngles.customView) return;
    const tweenTarget = setPolarAngle(cameraPolarAngle);
    return () => {
      tweenTarget && gsap.killTweensOf(tweenTarget);
    };
  }, [cameraPolarAngle]);

  useEffect(() => {
    if (cameraCenter !== CanvasCameraCenterMode.site || !constructionSiteCenter)
      return;
    const tweenTarget = setTarget(...constructionSiteCenter);

    return () => {
      tweenTarget && gsap.killTweensOf(tweenTarget);
    };
  }, [cameraCenter, constructionSiteCenter]);

  useEffect(() => {
    if (!appliedCamera) return;

    const tweenTarget = setAppliedCamera(appliedCamera);

    return () => {
      tweenTarget && gsap.killTweensOf(tweenTarget);
    };
  }, [appliedCamera]);
};
