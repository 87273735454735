import { ModalProps } from 'antd/es/modal';

export enum HeaderModes {
  Default = 'Default',
  Project = 'Project',
}

export enum WindowCreatorModes {
  Selection = 'Selection',
  MullionCreator = 'MullionCreator',
}

export enum UnitTypeCreatorModes {
  Selection = 'Selection',
  MullionCreator = 'MullionCreator',
}

export enum FacadeDesignerModes {
  Selection = 'Selection',
  WindowPlacement = 'WindowPlacement',
  UnitPlacement = 'UnitPlacement',
  GridLinePlacement = 'GridLinePlacement',
  AutoGridLinePlacement = 'AutoGridLinePlacement',
}

export enum CleanUpOptions {
  Windows = 'windows',
  GridLines = 'gridLines',
  UcwUnits = 'UcwUnits',
}

export enum CleanUpArea {
  SelectedArea = 'selectedArea',
  FullBuilding = 'fullBuilding',
}

export interface CustomModalProps extends ModalProps {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}

export interface PointerPosition {
  x: number;
  y: number;
}
