import React, { useEffect } from 'react';
import Checkbox, { CheckboxProps, CheckboxRef } from 'antd/es/checkbox';
import './AntdCheckbox.scss';

const IntusCheckbox: React.FC<CheckboxProps> = ({
  className = '',
  indeterminate,
  children,
  ...rest
}) => {
  const checkboxRef = React.useRef<CheckboxRef>(null);

  useEffect(() => {
    const innerCheckbox = checkboxRef?.current?.nativeElement?.children.item(1);
    if (innerCheckbox) {
      indeterminate
        ? innerCheckbox.setAttribute(
            'style',
            `background-color: var(--color-light-green-100) !important; border-color: var(--color-light-green-100) !important`
          )
        : innerCheckbox.removeAttribute('style');
    }
  }, [indeterminate]);

  return (
    <Checkbox
      indeterminate={indeterminate}
      className={`intus-checkbox ${className}`}
      ref={checkboxRef}
      {...rest}
    >
      {children}
    </Checkbox>
  );
};

export default IntusCheckbox;
