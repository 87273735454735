import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const UTCOuterCornerIcon: React.FC<BaseIconProps> = ({
  fill = '#fff',
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20098_694)">
        <path
          d="M6.5 14.7671L3.64706 13.0765C3.5559 13.0225 3.5 12.9244 3.5 12.8184L3.5 2.45525C3.5 2.2228 3.75296 2.07866 3.95294 2.19716L6.5 3.70653M6.5 14.7671L13.3324 11.4068C13.435 11.3563 13.5 11.2519 13.5 11.1376V0.74566C13.5 0.523505 13.2669 0.378413 13.0676 0.476456L6.5 3.70653M6.5 14.7671V3.70653"
          stroke={fill}
          strokeLinejoin="round"
        />
        <path
          d="M6.5 14.7671L3.64706 13.0765C3.5559 13.0225 3.5 12.9244 3.5 12.8184L3.5 2.45525C3.5 2.2228 3.75296 2.07866 3.95294 2.19716L6.5 3.70653M6.5 14.7671L13.3324 11.4068C13.435 11.3563 13.5 11.2519 13.5 11.1376V0.74566C13.5 0.523505 13.2669 0.378413 13.0676 0.476456L6.5 3.70653M6.5 14.7671V3.70653"
          stroke={fill}
          strokeOpacity="0.2"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
