import React from 'react';
import { FlatVector2 } from '@/models';
import { Html } from 'react-konva-utils';
import { IntusTooltip } from '@/shared/elements';
import { useAppDispatch } from '@/store/hooks';
import {
  resetHoverArea,
  setHoverArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { MULLION_CROSS_SAFE_AREA } from '@/shared/form/validators';
import { FlatVector2Axis } from '../../models';

const ERROR_SIZE = 36;

const PlacementElementError = ({
  position,
  errorList,
  hoverArea,
  scale,
  errorsTitle,
}: {
  position: FlatVector2;
  errorList?: string[];
  hoverArea?: FlatVector2Axis[];
  scale: number;
  errorsTitle?: string;
}) => {
  const dispatch = useAppDispatch();

  const errorsText = errorList ? (
    <div className={'text-sm'}>
      {errorsTitle && errorList.length > 1 && (
        <div className={'p-0 m-0 flex items-center font-medium leading-5'}>
          {errorsTitle}
        </div>
      )}
      {errorList.map((error) => (
        <div className={'p-0 m-0 flex items-center'} key={error}>
          <div
            className={'w-1 h-1 bg-red-80 rounded-full ml-2 mr-3 leading-5'}
          />
          {errorList.length === 1 &&
            errorsTitle &&
            error !== MULLION_CROSS_SAFE_AREA}{' '}
          {error}
        </div>
      ))}
    </div>
  ) : null;

  return (
    <Html
      groupProps={{
        x: position[0] - ERROR_SIZE / scale / 2,
        y: position[1],
        scaleX: 1 / scale,
        scaleY: 1 / scale,
      }}
    >
      <IntusTooltip
        title={errorsText}
        placement={'right'}
        classNames={{
          root: 'white',
        }}
        styles={{
          body: {
            background: 'white',
            backgroundColor: 'white',
            color: '#414042',
            padding: 8,
            borderRadius: 4,
          },
          root: {
            maxWidth: '100%',
          },
        }}
      >
        <div
          className={`text-white bg-red-80 hover:bg-red w-4 h-4 rounded-full border-2 
            border-white border-solid flex items-center justify-center font-sans select-none`}
          onPointerEnter={() => dispatch(resetHoverArea())}
          onPointerLeave={() => hoverArea && dispatch(setHoverArea(hoverArea))}
        >
          !
        </div>
      </IntusTooltip>
    </Html>
  );
};

export default PlacementElementError;
