import React, { memo, useEffect, useMemo, useRef, useState } from 'react';
import {
  C_FatLineBorderMaterial,
  C_FatLineSelectedBorderMaterial,
  C_WallMaterial,
  C_WallSelectedMaterial,
} from '@/shared/materials';
import {
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  CanvasActionsModes,
  CustomUserData,
  NodeType,
  SelectedNodeSource,
  UserBuildingWall,
} from '@/models';
import * as THREE from 'three';
import {
  createLine2,
  getCenterFromFlatVectorsArray,
  getCenterFromVectorsArray,
  getPerpendicularVectorToVectors,
  getXYZ,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  getIsNodeHovered,
  getIsNodeIsolated,
  getIsNodeSelected,
  selectOneNode,
  setExtrudeData,
} from '@/store/slices/canvasBuildingSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { GenericChildSurface } from '@/models/building-nodes.model';
import ExtrudeDotHandler, {
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import { setMode } from '@/store/slices/canvasModesSlice';
import { POLYGON_OFFSET_FACTOR_LEVELS } from '@/shared/constants';
import { useUnmount } from 'react-use';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { disposeNode } from '@/shared/helpers/canvas';
import WallPlacementComponents from '@/routes/dashboard/projects/project/UserBuilding/components/PanelizationItems/WallPlacementComponents';
import { isEqual } from 'lodash';

import { getIsNodeAffectedByCopying } from '@/store/slices/copyPropertiesSlice';

const STOREY_SELECTOR_WIDTH_AREA = 0.002;

export interface WallUserData extends CustomUserData {
  nodeType: NodeType.Wall;
  guid: string;
  isLocked: boolean;
  isSelected: boolean;
  originalBuildingBlock: {
    guid: string;
  };
}

interface WallProps extends GenericChildSurface {
  data: UserBuildingWall;
  blockGUID: string;
  isParentSelected: boolean;
  isStoreySelected: boolean;
  isParentLocked: boolean;
  isProjectLocked: boolean;
  isSingleNodeSelected: boolean;
  isSingleNodeIsolated: boolean;
  isStoreyHovered: boolean;
  isDesignerMode: boolean;
  isolateMode: boolean;
  multiplyRate: number;
  isEditToolsAvailable: boolean;
  isWindowCardDragging: boolean;
  storeyGUID: string;
  buildingGUID: string;
  isCopyingProcessing: boolean;
  highlightBorders: boolean;
}

const Wall: React.FC<WallProps> = ({
  data,
  isCopyingProcessing,
  isParentSelected,
  isStoreySelected,
  isParentLocked,
  isProjectLocked,
  isSingleNodeSelected,
  isSingleNodeIsolated,
  isStoreyHovered,
  multiplyRate,
  isDesignerMode,
  isolateMode,
  storeyGUID,
  isEditToolsAvailable,
  blockGUID,
  isWindowCardDragging,
  highlightBorders,
}) => {
  const dispatch = useAppDispatch();

  const meshRef = useRef<THREE.Mesh>(null);
  const isWallSelected = useAppSelector(getIsNodeSelected(data.guid));
  const isWallIsolated = useAppSelector(getIsNodeIsolated(data.guid));
  const isNodeHovered = useAppSelector(getIsNodeHovered(data.guid));
  const isNodeAffectedByCopying = useAppSelector(
    getIsNodeAffectedByCopying(data.guid)
  );

  const [wallBorders, setWallBorders] = useState<Line2>();

  const isExtrudeAvailable =
    isWallSelected &&
    isSingleNodeSelected &&
    !data.userData?.isLocked &&
    !isParentLocked &&
    (!isWallIsolated || isolateMode) &&
    !isSingleNodeIsolated &&
    !isDesignerMode &&
    !isProjectLocked &&
    isEditToolsAvailable;

  const wallCoordinates = useMemo(
    () => convertFlatVector3ToVectors(data.points),
    [data.points]
  );

  const extrudeHandlerData = useMemo((): ExtrudeHandlerData => {
    const center = getCenterFromFlatVectorsArray(data.points);
    const perpendicularDirection = getPerpendicularVectorToVectors(
      wallCoordinates,
      true
    );
    return {
      defaultCenter: [center.x, center.y, center.z],
      perpendicularDirection: getXYZ(perpendicularDirection),
      facadeWallsGuids: [data.guid],
    };
  }, [data]);

  const handleSelectWallForExtrude = (node: ExtrudeHandlerData) => {
    dispatch(setExtrudeData(node));
  };

  const wallGeometry = useMemo(
    () => createGeometryFromVectorList(wallCoordinates, 'vertical'),
    [wallCoordinates]
  );

  const storeySelectorMesh = useMemo(() => {
    const mesh = new THREE.Mesh(
      new THREE.CylinderGeometry(
        STOREY_SELECTOR_WIDTH_AREA,
        STOREY_SELECTOR_WIDTH_AREA,
        wallCoordinates[0].distanceTo(wallCoordinates[3])
      ),
      new THREE.MeshBasicMaterial({})
    );

    mesh.position.copy(
      getCenterFromVectorsArray([wallCoordinates[0], wallCoordinates[3]])
    );
    mesh.visible = false;
    mesh.userData = {
      nodeType: NodeType.Storey,
      guid: storeyGUID,
      isLocked: isParentLocked,
      isSelected: isParentSelected,
    };
    return mesh;
  }, [wallCoordinates]);

  const isSelected = isParentSelected || isWallSelected;

  const isDisplay = !isolateMode || (isolateMode && isWallIsolated);

  const isSelectedContour = isCopyingProcessing
    ? isNodeAffectedByCopying
    : isWallSelected ||
      isNodeHovered ||
      isStoreyHovered ||
      isStoreySelected ||
      isSelected ||
      highlightBorders;

  const wallUserData: WallUserData = {
    ...data.userData,
    nodeType: NodeType.Wall,
    guid: data.guid,
    isLocked: !!data.userData?.isLocked,
    isSelected,
    originalBuildingBlock: {
      guid: blockGUID,
    },
  };
  const showPlacements =
    data.wallPanels.length > 0 ||
    data.windowPlacements.length > 0 ||
    data.gridLines.length > 0;

  const handleMouseUp = () => {
    if (isNodeHovered) {
      dispatch(
        selectOneNode({
          guid: data.guid,
          type: NodeType.Wall,
          userData: data.userData,
          source: SelectedNodeSource.Viewer,
        })
      );
      dispatch(setMode(CanvasActionsModes.facadeDesigner));
    }
  };

  useEffect(() => {
    if (isWindowCardDragging) {
      window.addEventListener('mouseup', handleMouseUp);
    }
    return () => {
      window.removeEventListener('mouseup', handleMouseUp);
    };
  }, [isWindowCardDragging, isNodeHovered]);

  useEffect(() => {
    if (!meshRef.current) return;
    if (isSelected && !isDesignerMode) {
      (meshRef.current.material as THREE.MeshBasicMaterial) =
        C_WallSelectedMaterial;
    } else {
      (meshRef.current.material as THREE.MeshBasicMaterial) = C_WallMaterial;
    }
  }, [isSelected, isDesignerMode, meshRef.current]);

  useEffect(() => {
    if (!wallBorders) return;
    if (isSelectedContour) {
      const material = C_FatLineSelectedBorderMaterial.clone();
      material.polygonOffsetFactor = POLYGON_OFFSET_FACTOR_LEVELS.HIGH;
      wallBorders.material = material;
    } else {
      wallBorders.material = C_FatLineBorderMaterial;
    }
  }, [isSelectedContour]);

  useEffect(() => {
    if (!wallBorders) {
      const line = createLine2(data.points.flat(), C_FatLineBorderMaterial);
      setWallBorders(line);
    } else {
      updateLine2Position(wallBorders, data.points.flat());
    }
    return () => {};
  }, [data, isSelectedContour]);

  useUnmount(() => {
    disposeNode(storeySelectorMesh);
    wallGeometry.dispose();
    wallBorders && disposeNode(wallBorders);
  });

  if (!isDisplay) return null;

  return (
    <>
      {<primitive object={storeySelectorMesh} />}
      <mesh
        geometry={wallGeometry}
        material={C_WallMaterial}
        ref={meshRef}
        userData={wallUserData}
      />
      {wallBorders && <primitive object={wallBorders} />}
      {showPlacements && (
        <WallPlacementComponents
          panels={data.wallPanels}
          multiplyRate={multiplyRate}
          wallUserData={wallUserData}
          wallPoints={data.points}
          windows={data.windowPlacements}
          gridLines={data.gridLines}
          wallGuid={data.guid}
          meshRef={meshRef}
        />
      )}
      {isExtrudeAvailable && (
        <ExtrudeDotHandler
          extrudeHandlerData={extrudeHandlerData}
          clickAction={() => handleSelectWallForExtrude(extrudeHandlerData)}
        />
      )}
    </>
  );
};

export default memo(Wall, isEqual);
