import React from 'react';

import './Switcher.scss';
import { IntusButton } from '@/shared/elements';

interface SwitcherValue<T> {
  name: string;
  value: T;
}
interface SwitcherProps<T> {
  data: SwitcherValue<T>[];
  value?: T;
  disabled?: boolean;
  onChange?: (val: T) => void;
}
const Switcher = ({
  data,
  value,
  disabled,
  onChange,
}: SwitcherProps<number | string>) => {
  return (
    <div className="h-full flex intus-switcher">
      <div className="flex h-full gap-1 justify-center items-center h-6 p-1 rounded-lg font-medium">
        {data.map((v, idx) => (
          <span key={v.value}>
            <IntusButton
              disabled={disabled}
              rootClassName={`border-none px-2 w-[54px] ${v.value === value && 'bg-white!'} text-dark-gray-100`}
              size="small"
              type="default"
              onClick={() => onChange && onChange(v.value)}
              id={`switcher-button-${idx}`}
            >
              {v.name}
            </IntusButton>
            {idx < data.length - 1 && <div className="w-1"></div>}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Switcher;
