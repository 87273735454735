export interface BaseIconProps {
  fill?: string;
  stroke?: string;
  className?: string;
}

export interface HeaderPanelItemProps extends PanelItemProps {
  onCollapseChange: (ids: string | string[]) => void;
  keys: string[];
}

// TODO: Temporary became empty, need to investigate which common parameters are used
export interface PanelItemProps {
  depthLevel: number;
  isParentSelected?: boolean;
  isParentLocked?: boolean;
  isParentHidden?: boolean;
}

export enum TextColor {
  LIGHT = '#FFFFFF',
  DARK = '#414042',
  GRAY = '#676569',
}
