import React, { useEffect, useRef, useState } from 'react';
import { Group, Layer, Stage } from 'react-konva';
import { WindowManContour } from '@/components/WindowCreator/elements';
import Konva from 'konva';
import KonvaEventObject = Konva.KonvaEventObject;
import { WindowCreatorMeasurements } from '@/components/WindowCreator/elements/Measurements';
import {
  MeasurementUpdateData,
  WindowCreatorFormData,
  WindowElementType,
} from '@/components/WindowCreator/models/konva-model';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  clearSelectRelatedData,
  getHasSelectedNode,
  getMullionArea,
  getSelectedWindowNodeType,
  resetMullionArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import SelectWrapper from '@/components/WindowCreator/elements/SelectionArea';
import { WindowCreatorModes } from '@/models/shared.model';
import { FormInstance } from 'antd/es/form';
import { subscribe, unsubscribe } from '@/core/events';
import { SET_WINDOW_CONTEXT_MENU_POSITION } from '@/core/event-names';
import WindowMenu from '../elements/WindowMenu/WindowMenu';
import { MullionData } from '@/models/window-configurator.model';
import { useKonvaHandlers } from '@/shared/hooks/useKonvaHandlers';
import WindowView from '@/components/WindowView/WindowView';
import {
  KONVA_WDC_DEFAULT_LEVEL_WIDTH,
  WINDOW_CREATOR_STAGE_ID,
} from '@/shared/constants';
import { getWindowWidth } from '@/shared/helpers';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { usePrevious, useUpdate, useWindowSize } from 'react-use';
import { isEqual } from 'lodash';
import { MullionGenerator } from '@/components/WindowCreator/elements/MullionGenerator';
import WindowCreatorErrors from '@/components/WindowCreator/elements/WindowCreatorErrors';
import {
  INITIAL_SCALE,
  MEASUREMENT_PADDING,
  STAGE_HEIGHT_RATIO,
  STAGE_WIDTH_RATIO,
} from '../constants';
import KonvaHeightMark from '@/shared/components/KonvaHeightMark';
import { FLOOR_HEIGHT_MARK_NAME } from '@/shared/names';
import KonvaDashedLine from '@/shared/elements/KonvaDashedLine/KonvaDashedLine';

interface WindowCreatorContainerProps {
  data: WindowCreatorFormData;
  mode: WindowCreatorModes;
  onMeasurementSubmit: (data: MeasurementUpdateData) => void;
  onDeleteMullionSubmit: (data: MullionData) => void;
  onKonvaInit: (stage: Konva.Stage) => void;
  form: FormInstance<WindowCreatorFormData>;
}
export const WindowCreatorContainer = ({
  data,
  onMeasurementSubmit,
  onDeleteMullionSubmit,
  mode,
  form,
  onKonvaInit,
}: WindowCreatorContainerProps) => {
  const dispatch = useAppDispatch();
  const hasSelectedNode = useAppSelector(getHasSelectedNode);
  const selectedNodeType = useAppSelector(getSelectedWindowNodeType);
  const config = useFetchWindowConfigQuery().data!;
  const isSelectionMode = mode === WindowCreatorModes.Selection;
  const stageRef = useRef<Konva.Stage>(null!);
  const wheelEventEndTimeout = useRef(0);

  const { height, width } = useWindowSize();

  useEffect(() => {
    stageRef.current && onKonvaInit(stageRef.current);
  }, [stageRef.current]);

  Konva.dragButtons = [2];

  const [menuPosition, setMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const [scaleLimits, setScaleLimits] = useState<{
    minScale: number;
    maxScale: number;
  }>({
    minScale: 0.25,
    maxScale: 0.66,
  });

  const { wheelHandler } = useKonvaHandlers();

  const previousFramePoints = usePrevious(data.points);
  const mullionArea = useAppSelector(getMullionArea);
  const update = useUpdate();
  useEffect(() => {
    if (
      !data.points ||
      !previousFramePoints ||
      isEqual(previousFramePoints, data.points)
    )
      return;

    const windowWidth = getWindowWidth(data);

    const minScale = (0.25 / windowWidth) * config.baseWindow.width;

    setScaleLimits({
      minScale,
      maxScale: scaleLimits.maxScale,
    });
  }, [data.points, stageRef.current]);

  const handleWheel = (event: KonvaEventObject<WheelEvent>) => {
    clearTimeout(wheelEventEndTimeout.current);
    wheelEventEndTimeout.current = window.setTimeout(() => update(), 100);
    const scaleData = wheelHandler(event, scaleLimits);
    if (scaleData) {
      const stage = stageRef.current;
      stage.scale({ x: scaleData.scale, y: scaleData.scale });
      stage.position({ x: scaleData.x, y: scaleData.y });
    }
  };

  useEffect(() => {
    if (stageRef.current) {
      stageRef.current.scale({ x: INITIAL_SCALE, y: INITIAL_SCALE });
      stageRef.current.position({ x: 0, y: 0 });
      update();
    }
  }, [stageRef.current]);

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (event.target.nodeType === 'Stage') {
      dispatch(clearSelectRelatedData());
    }
  };

  useEffect(() => {
    subscribe(SET_WINDOW_CONTEXT_MENU_POSITION, (evt) => {
      setMenuPosition(evt.detail);
    });
    return () => {
      unsubscribe(SET_WINDOW_CONTEXT_MENU_POSITION, (evt) => {
        setMenuPosition(evt.detail);
      });
      dispatch(clearSelectRelatedData());
    };
  }, []);

  useEffect(() => {
    dispatch(resetMullionArea());
  }, [mode, data.points]);

  const showMenu =
    menuPosition &&
    hasSelectedNode &&
    selectedNodeType !== WindowElementType.Mullion;

  return (
    <Stage
      id={WINDOW_CREATOR_STAGE_ID}
      ref={stageRef}
      onContextMenu={(e) => e.evt.preventDefault()}
      width={width * STAGE_WIDTH_RATIO}
      height={height * STAGE_HEIGHT_RATIO}
      draggable
      onClick={handleClick}
      onWheel={handleWheel}
      onDragEnd={() => {}}
      onDragMove={() => {}}
    >
      {data.points && (
        <Layer>
          <WindowView
            data={data}
            scale={stageRef.current?.scaleX()}
            units={data.units}
            onDeleteMullionSubmit={onDeleteMullionSubmit}
            view={form.getFieldValue('view')}
            mode={mode}
          />
          <WindowCreatorMeasurements
            onMeasurementSubmit={onMeasurementSubmit}
            data={data}
            units={data.units}
            scale={stageRef.current?.scaleX()}
          />

          <Group
            x={
              data.points[0][0] -
              (KONVA_WDC_DEFAULT_LEVEL_WIDTH +
                MEASUREMENT_PADDING / stageRef.current?.scaleX())
            }
            y={data.points[2][1] + data.distanceToFloor}
          >
            <KonvaDashedLine
              points={[
                [0, 0],
                [
                  data.points[1][0] -
                    data.points[0][0] +
                    (KONVA_WDC_DEFAULT_LEVEL_WIDTH +
                      MEASUREMENT_PADDING / stageRef.current?.scaleX()),
                  0,
                ],
              ]}
            />
          </Group>
          <KonvaHeightMark
            width={200}
            name={FLOOR_HEIGHT_MARK_NAME}
            scale={stageRef.current?.scaleX()}
            yOffset={data.points[2][1] + data.distanceToFloor}
            xOffset={data.points[1][0]}
          />

          <WindowManContour
            outerFramePoints={data.points}
            units={data.units}
            scale={stageRef.current?.scaleX()}
          />

          {isSelectionMode && (
            <SelectWrapper scale={stageRef.current?.scaleX()} />
          )}
          {showMenu && (
            <WindowMenu
              form={form}
              scale={stageRef.current?.scaleX()}
              menuPosition={menuPosition}
              setMenuPosition={setMenuPosition}
              windowData={data}
            />
          )}
          {mode === WindowCreatorModes.MullionCreator &&
            !!mullionArea?.length && (
              <MullionGenerator
                points={mullionArea}
                scale={stageRef.current?.scaleX()}
                units={data.units}
                outerFramePoints={data.points}
              />
            )}
        </Layer>
      )}
      {stageRef.current && (
        <WindowCreatorErrors scale={stageRef.current.scaleX()} />
      )}
    </Stage>
  );
};
