import React, { useMemo } from 'react';
import * as THREE from 'three';
import { C_RoofMaterial, ROOF_OPACITY } from '@/shared/materials';
import Border from '@/routes/dashboard/projects/project/UserBuilding/components/Border';

interface GenericFloorProps {
  floorLevel: number;
  baseBuilding: THREE.Mesh;
  floorHeight: number;
}

const GenericFloor: React.FC<GenericFloorProps> = ({
  floorLevel,
  baseBuilding,
  floorHeight,
}) => {
  const newFloorGeometry = useMemo(() => {
    const geometry = baseBuilding.geometry.clone();
    const newFloorPosition = geometry.getAttribute('position');

    for (let i = 0; i < newFloorPosition.count; i++) {
      newFloorPosition.setY(
        i,
        newFloorPosition.getY(i) + floorHeight * floorLevel
      );
    }

    newFloorPosition.needsUpdate = true;
    geometry.computeVertexNormals();
    geometry.computeBoundingBox();

    return geometry;
  }, [baseBuilding, floorHeight]);

  const newFloorMaterial = useMemo(() => {
    const material = C_RoofMaterial.clone();
    material.opacity = 1;

    return material;
  }, []);

  const newCeilingGeometry = useMemo(() => {
    const roofGeometry = newFloorGeometry.clone();
    const roofPosition = roofGeometry.getAttribute('position');

    for (let i = 0; i < roofPosition.count; i++) {
      roofPosition.setY(i, roofPosition.getY(i) + floorHeight);
    }

    return roofGeometry;
  }, [newFloorGeometry]);

  const newCeilingMaterial = useMemo(() => {
    const roofMaterial = newFloorMaterial.clone();
    roofMaterial.opacity = ROOF_OPACITY;

    return roofMaterial;
  }, []);

  return (
    <>
      <mesh geometry={newFloorGeometry} material={newFloorMaterial}>
        <Border geometry={newFloorGeometry} />
      </mesh>
      <mesh geometry={newCeilingGeometry} material={newCeilingMaterial}>
        <Border geometry={newCeilingGeometry} />
      </mesh>
    </>
  );
};

export default GenericFloor;
