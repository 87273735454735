import React from 'react';

import { Trap } from '@/components/SvgDesigner/shared';
import SvgWindowBorders from './SvgWindowBorder';

import { generateSvgOperableBorders } from '@/components/SvgDesigner/helpers/generators';
import { LineColor, OperableWindow } from '@/components/SvgDesigner/models';
import { LINE_WIDTH } from '@/components/SvgDesigner/constants';
import { WINDOW_GLASS_COLOR } from '@/components/WindowCreator/elements/windows.constants';

const SvgOperableWindow: React.FC<Omit<OperableWindow, 'offset'>> = ({
  points,
  color,
  operableConfig,
}) => {
  const { outerBorders, sashBorders } = generateSvgOperableBorders(
    points,
    operableConfig
  );

  return (
    <>
      <Trap
        points={points}
        fill={{ hex: WINDOW_GLASS_COLOR, hasGlare: false }}
        stroke={LineColor.DEFAULT}
        strokeWidth={LINE_WIDTH}
      />

      <SvgWindowBorders color={color} borders={outerBorders} />
      <SvgWindowBorders color={color} borders={sashBorders} />
    </>
  );
};

export default SvgOperableWindow;
