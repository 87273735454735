import React from 'react';
import { UTCFormData } from '@/components/UTC/models';
import {
  MeasurementElementType,
  MeasurementUpdateData,
} from '@/components/WindowCreator/models';

import { generateMullionMeasurements } from '@/components/WindowCreator/helpers';
import { publish } from '@/core/events';
import { UTC_MEASUREMENT_SUBMIT } from '@/core/event-names';
import UTCUnitMeasurements from './UTCUnitMeasurements';
import UTCMullionMeasurements from './UTCMullionMeasurements';
import { Measurement } from '@/shared/components/MeasurementLine';
import { generateWidthMeasurements } from '../helpers/generators';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { UTCManContour } from './UTCManContour';
import { useAppSelector } from '@/store/hooks';
import { isUTCEditing } from '@/store/slices/windowsReducer/UTCSlice';
import UTCAxisLines from '@/components/UTC/components/UTCAxisLines';

interface UTCMeasurementsProps {
  formData: UTCFormData;
  scale: number;
}

const UTCMeasuringElements = ({ formData, scale }: UTCMeasurementsProps) => {
  const config = useFetchWindowConfigQuery().data!;
  const isEditing = useAppSelector(isUTCEditing);
  const mullionHorizontalMeasurements = generateMullionMeasurements(
    formData.points,
    formData.mullions,
    'x'
  );
  const hasInnerHorizontalMeasurements =
    mullionHorizontalMeasurements.length > 1;

  const mullionVerticalMeasurements = generateMullionMeasurements(
    formData.points,
    formData.mullions,
    'y'
  );
  const hasInnerVerticalMeasurements = mullionVerticalMeasurements.length > 1;

  const handleMeasurementSubmit = (data: MeasurementUpdateData) => {
    publish(UTC_MEASUREMENT_SUBMIT, data);
  };

  return (
    <>
      <UTCUnitMeasurements
        formData={formData}
        scale={scale}
        disabled={isEditing}
        hasInnerHorizontalMeasurements={hasInnerHorizontalMeasurements}
        hasInnerVerticalMeasurements={hasInnerVerticalMeasurements}
        handleMeasurementSubmit={handleMeasurementSubmit}
      />
      <UTCMullionMeasurements
        units={formData.units}
        scale={scale}
        hasInnerHorizontalMeasurements={hasInnerHorizontalMeasurements}
        hasInnerVerticalMeasurements={hasInnerVerticalMeasurements}
        handleMeasurementSubmit={handleMeasurementSubmit}
        mullionHorizontalMeasurements={mullionHorizontalMeasurements}
        mullionVerticalMeasurements={mullionVerticalMeasurements}
      />
      <Measurement
        type={MeasurementElementType.Unit}
        units={formData.units}
        scale={scale}
        points={generateWidthMeasurements(
          formData.points,
          config.panel.horizontalOffset / 2
        )}
        level={hasInnerHorizontalMeasurements ? 3 : 2}
        disabled
      ></Measurement>
      <UTCAxisLines
        scale={scale}
        framePoints={formData.points}
        hasInnerHorizontalMeasurements={hasInnerHorizontalMeasurements}
        hasInnerVerticalMeasurements={hasInnerVerticalMeasurements}
        panelConfig={config.panel}
        units={formData.units}
      />
      <UTCManContour
        outerFramePoints={formData.points}
        units={formData.units}
        scale={scale}
      />
    </>
  );
};

export default UTCMeasuringElements;
