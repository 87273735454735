import { CustomError } from '@/models';
import { FormInstance } from 'antd/es/form';

export const setFormErrorFromServer = (
  form: FormInstance,
  error: CustomError
) => {
  error?.data?.forEach((data) => {
    if (data.errorMessage) {
      const field = data?.propertyName?.toLowerCase() ?? '';
      field in form.getFieldsValue() &&
        form.setFields([
          {
            name: field,
            errors: [data.errorMessage],
          },
        ]);
    }
  });
};
