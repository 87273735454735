import React from 'react';

const QuoteFacade: React.FC = () => {
  return (
    <div className="h-full w-full flex relative flex-col">
      <div className="px-6 py-9 box-border relative bg-[#ECEEEE] flex-1 overflow-hidden">
        <div className="absolute bottom-0 left-0 bg-[#F9F9F9] px-2 py-1 font-light leading-4 text-[10px] text-dark-gray-100">
          Areas marked in red are out of scope and not included into quote
        </div>
      </div>
    </div>
  );
};

export default QuoteFacade;
