import React, { useMemo } from 'react';
import { UserBuildingPanel } from '@/models';
import UnitCardPopover from '@/shared/elements/UnitCard/UnitCardPopover';
import UnitCardContextMenu from '@/shared/elements/UnitCard/UnitCardContextMenu';
import { UTCInnerCornerIcon } from '@/shared/icons/UTCInnerCornerIcon';
import { UTCOuterCornerIcon } from '@/shared/icons/UTCOuterCornerIcon';
import { UTCStraightIcon } from '@/shared/icons/UTCStraightIcon';
import SystemUnitIcon from '@/shared/icons/SystemUnitIcon';
import { IntusTooltip } from '@/shared/elements';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useHighlightedStatus } from '@/shared/hooks/useHighlightedStatus';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { useDuplicateUnitMutation } from '@/store/apis/unitApi';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import SvgCanvas from '@/components/SvgDesigner/SvgCanvas';
import { useSvgData } from '@/components/SvgDesigner/hooks/useSvgData';
import { getPanelHeight } from '@/shared/helpers';
import { UI_INDEX } from '@/shared/constants';

interface UnitCardProps {
  tooltipPlacementParams?: {
    placement: TooltipPlacement;
    title: string;
  };
  popoverPlacement: TooltipPlacement;
  unitData: UserBuildingPanel;
  isSelected?: boolean;
  isDesignerMode: boolean;
  handleUTEOpen: () => void;
  disabled: boolean;
  isProjectLocked: boolean;
  handleSelectUnit?: () => void;
}

const UnitCard: React.FC<UnitCardProps> = ({
  unitData,
  isSelected,
  popoverPlacement,
  tooltipPlacementParams,
  handleUTEOpen,
  isDesignerMode,
  handleSelectUnit,
  disabled,
  isProjectLocked,
}) => {
  const { deletePanel } = useUpdateUserBuildingData();
  const [duplicateUnit] = useDuplicateUnitMutation();

  const { findDataForWallInfoViaPanel } = useFindNodeData();
  const { getUnitData } = useSvgData();

  const svgData = getUnitData({
    isNumbering: false,
    isUnitNumbering: false,
    unit: unitData,
    isReport: false,
  });

  const getUnitTypeIcon = () => {
    if (unitData.cornerSide) {
      return unitData.isInnerCorner ? (
        <UTCInnerCornerIcon fill={'#000'} />
      ) : (
        <UTCOuterCornerIcon fill={'#000'} />
      );
    }

    return <UTCStraightIcon fill={'#000'} />;
  };

  const getUnitTypePlate = () => {
    return (
      <div
        className={
          'w-6 h-6 rounded-full bg-[rgba(255,255,255,0.6)] absolute top-1 left-1 flex items-center justify-center'
        }
      >
        {getUnitTypeIcon()}
      </div>
    );
  };

  const getSystemUnitPlate = () => {
    if (unitData.isCustomUnit) return null;

    return (
      <div
        className={
          'bg-[rgba(255,255,255,0.6)] rounded-2xl p-1 absolute top-1 right-2 text-dark-gray-100 text-[11px] font-normal leading-4 flex items-center justify-center'
        }
      >
        <SystemUnitIcon />
        <span className={'ml-1'}>System unit</span>
      </div>
    );
  };

  const isAbleToDelete = useMemo(() => {
    return (
      !findDataForWallInfoViaPanel({ panelId: unitData.id }) &&
      unitData.isCustomUnit
    );
  }, [unitData]);

  const { isHighlighted } = useHighlightedStatus(unitData.createdAt);

  const getBorderClass = () => {
    if (isSelected) {
      return 'outline outline-2 outline-offset-[-1.5px] outline-light-green-100';
    }
    if (isHighlighted) {
      return 'outline outline-light-green-60 outline-offset-[-1.5px]';
    }
    return '';
  };

  const handleClickOnUnitCard = () => {
    !disabled && !isProjectLocked && handleSelectUnit && handleSelectUnit();
  };

  const handleDuplicateUnit = () => {
    duplicateUnit({ unitId: unitData.id });
  };

  const handleDeleteUnit = () => {
    deletePanel(unitData);
  };

  const isCardDisabled = isProjectLocked || disabled;

  return (
    <IntusTooltip
      placement={tooltipPlacementParams?.placement}
      title={tooltipPlacementParams?.title}
      mouseEnterDelay={0.5}
      open={isCardDisabled ? false : undefined}
      zIndex={UI_INDEX.TOOLTIP}
    >
      <div
        className={`h-[152px] ${isCardDisabled ? 'opacity-50 cursor-not-allowed' : ''} bg-light-gray-10 border-b-2 border-light-gray-20 w-full ${getBorderClass()}`}
        onClick={handleClickOnUnitCard}
      >
        <div
          className={`h-30 relative flex items-center justify-center ${isCardDisabled || isDesignerMode ? '' : 'cursor-grab'}`}
        >
          {getUnitTypePlate()}
          {getSystemUnitPlate()}
          <div className={'flex justify-center items-center py-1.5'}>
            <SvgCanvas
              elementData={svgData}
              scale={Number((getPanelHeight(unitData) / 100).toFixed(0))}
            />
          </div>
        </div>

        <div
          className={
            'h-8 text-xs leading-5 font-normal bg-white flex items-center px-2 justify-between'
          }
        >
          <div className={'flex items-center'}>
            <span className={'max-w-[145px] truncate'}>{unitData.name}</span>
            <span className={'ml-[5px]'}>
              <UnitCardPopover
                unitData={unitData}
                disabled={disabled}
                popoverPlacement={popoverPlacement}
              />
            </span>
          </div>
          <UnitCardContextMenu
            onEdit={handleUTEOpen}
            disabled={isCardDisabled}
            onDelete={isAbleToDelete ? handleDeleteUnit : undefined}
            onDuplicate={handleDuplicateUnit}
          />
        </div>
      </div>
    </IntusTooltip>
  );
};

export default UnitCard;
