import React from 'react';
import { UTCFormData } from '@/components/UTC/models';
import UTCMeasuringElements from '@/components/UTC/components/UTCMeasuringElements';
import UTCMullionGenerator from '@/components/UTC/components/UTCMullionGenerator';
import { UnitSystemTypes } from '@/models';
import { useAppSelector } from '@/store/hooks';

import { UnitTypeCreatorModes } from '@/models/shared.model';
import { getMullionArea } from '@/store/slices/windowsReducer/UTCSlice';
import { Group } from 'react-konva';
import { UTC_ID_NAME } from '@/components/UTC/helpers/constants';
import UTCUnitView from '@/components/UTC/components/UTCUnitView';

interface UTCUnitProps {
  formData: UTCFormData;
  scale: number;
  units: UnitSystemTypes;
  mode: UnitTypeCreatorModes;
}

const UTCUnit = ({ formData, scale, units, mode }: UTCUnitProps) => {
  const mullionArea = useAppSelector(getMullionArea);

  return (
    <Group id={UTC_ID_NAME}>
      <UTCUnitView
        utcData={formData}
        scale={scale}
        view={formData.view}
        showPanelCellNumbers={true}
        units={formData.units}
      />
      <UTCMeasuringElements formData={formData} scale={scale} />
      {!!mullionArea && mode === UnitTypeCreatorModes.MullionCreator && (
        <UTCMullionGenerator
          scale={scale}
          points={mullionArea}
          units={units}
          outerFramePoints={formData.points}
        />
      )}
    </Group>
  );
};

export default UTCUnit;
