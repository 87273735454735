import {
  useFetchWindowConfigQuery,
  useFetchWindowsQuery,
} from '@/store/apis/windowApi';
import { useMeasureWallsFacadeDesigner } from './useMeasureWallsFacadeDesigner';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { ExtendedMinMaxCoordinatesPairs } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { useParams } from 'react-router';
import { getWindowWidth } from '@/shared/helpers';
import { roundKonvaValue } from '@/shared/helpers/konva';
import { GridLineData } from '@/models';

const useFacadeDesignerSnaps = () => {
  const { getMeasureWall } = useMeasureWallsFacadeDesigner();
  const config = useFetchWindowConfigQuery().data!;
  const { id } = useParams();
  const windowsLibrary = useFetchWindowsQuery(id!).data;

  const calculateGripSnapZones = ({
    wallsData,
    scale,
    startPoint,
    minMaxWallsCoordinates,
  }: {
    wallsData: WallSearchResults[];
    scale: number;
    startPoint: {
      x: number;
      y: number;
      z: number;
    };
    minMaxWallsCoordinates: ExtendedMinMaxCoordinatesPairs;
  }) => {
    const windowGridSnapDistance =
      config.panel.sideWidth +
      config.panel.horizontalOffset / 2 -
      config.panel.glass.insertedFixed;

    return wallsData.flatMap((wallData) => {
      const { wallOffset } = getMeasureWall(
        wallData,
        startPoint,
        minMaxWallsCoordinates
      );
      return wallData.windowPlacements.flatMap((placedWindow) => {
        const windowData = windowsLibrary?.find(
          (windowData) => windowData.id === placedWindow.windowId
        );
        const windowWidth = getWindowWidth(windowData!);
        const snapDistance = 12 / scale;
        const offset = wallOffset.x + placedWindow.offsetFromLeftEdge;
        return [
          {
            snapZone: [
              offset - windowGridSnapDistance - snapDistance,
              offset + snapDistance + windowGridSnapDistance,
            ],
            snapTo: roundKonvaValue(offset - windowGridSnapDistance),
          },
          {
            snapZone: [
              offset + windowWidth - snapDistance,
              offset + windowWidth + windowGridSnapDistance + snapDistance,
            ],
            snapTo: roundKonvaValue(
              offset + windowWidth + windowGridSnapDistance
            ),
          },
        ];
      });
    });
  };

  const calculateWindowSnapZones = ({
    scale,
    windowWidth,
    gridLines,
  }: {
    scale: number;
    windowWidth: number;
    gridLines: GridLineData[];
  }) => {
    const snapPadding = 12 / scale;
    return gridLines.flatMap((gridLine) => {
      const gridOffset = gridLine.offsetFromLeftEdge;

      const configOffset =
        config.panel.sideWidth +
        config.panel.horizontalOffset / 2 -
        config.panel.glass.insertedFixed;

      const leftEdge = gridOffset + configOffset;
      const rightEdge = gridOffset - configOffset;

      return [
        {
          snapZone: [leftEdge - snapPadding, leftEdge + snapPadding],
          snapTo: roundKonvaValue(leftEdge),
        },
        {
          snapZone: [
            rightEdge - windowWidth - snapPadding,
            rightEdge - windowWidth + snapPadding,
          ],
          snapTo: roundKonvaValue(rightEdge - windowWidth),
        },
      ];
    });
  };

  return { calculateGripSnapZones, calculateWindowSnapZones };
};

export default useFacadeDesignerSnaps;
