import React from 'react';

export const ArrowRightIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.5 7.5C2.22386 7.5 2 7.72386 2 8C2 8.27614 2.22386 8.5 2.5 8.5L12.197 8.5L8.16552 12.1283C7.96026 12.3131 7.94362 12.6292 8.12835 12.8345C8.31308 13.0397 8.62923 13.0564 8.83448 12.8716L13.8345 8.37164C13.9398 8.27682 14 8.14174 14 8C14 7.85825 13.9398 7.72317 13.8345 7.62835L8.83448 3.12835C8.62923 2.94362 8.31308 2.96026 8.12835 3.16551C7.94362 3.37077 7.96026 3.68691 8.16552 3.87164L12.197 7.5L2.5 7.5Z"
      fill="white"
    />
  </svg>
);
