import React from 'react';
import { useAppSelector } from '@/store/hooks';
import {
  getMeasurementErrors,
  getMullionErrors,
} from '@/store/slices/sharedSlice';
import KonvaErrorList from '@/shared/components/KonvaErrorList';

interface WindowCreatorErrorsProps {
  scale: number;
}
const WindowCreatorErrors = ({ scale }: WindowCreatorErrorsProps) => {
  const measurementErrors = useAppSelector(getMeasurementErrors);
  const mullionErrors = useAppSelector(getMullionErrors);

  return (
    <KonvaErrorList
      errors={[...measurementErrors, ...mullionErrors]}
      scale={scale}
    />
  );
};

export default WindowCreatorErrors;
