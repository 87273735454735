import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const LogOutIcon: React.FC<BaseIconProps> = ({ fill = 'black' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="12"
      viewBox="0 0 13 12"
      fill="none"
    >
      <path
        d="M8.20002 12C8.53139 12 8.80002 11.7314 8.80002 11.4C8.80002 11.0686 8.53139 10.8 8.20002 10.8H3.20002C2.31637 10.8 1.60002 10.0837 1.60002 9.2V2.8C1.60002 1.91634 2.31637 1.2 3.20002 1.2H8.20002C8.53139 1.2 8.80002 0.931371 8.80002 0.6C8.80002 0.268629 8.53139 0 8.20002 0H3.20002C1.65363 0 0.400024 1.2536 0.400024 2.8V9.2C0.400024 10.7464 1.65363 12 3.20002 12H8.20002ZM8.99306 2.95912C9.23655 2.73436 9.61614 2.74954 9.84091 2.99304L12.2409 5.59303C12.4531 5.82287 12.4531 6.17713 12.2409 6.40697L9.84091 9.00697C9.61614 9.25046 9.23655 9.26565 8.99306 9.04088C8.74956 8.81612 8.73438 8.43653 8.95914 8.19304L10.4296 6.6H4.20002C3.86865 6.6 3.60002 6.33137 3.60002 6C3.60002 5.66863 3.86865 5.4 4.20002 5.4H10.4296L8.95914 3.80697C8.73438 3.56348 8.74956 3.18388 8.99306 2.95912Z"
        fill={fill}
      />
    </svg>
  );
};
