import { RefObject, useEffect } from 'react';
import { OrbitControls as OrbitControlsImpl } from 'three-stdlib/controls/OrbitControls';
import { CanvasCamera, CanvasCameraType } from '@/models';
import {
  CHANGE_SAVE_CAMERA_STATE,
  SAVE_CURRENT_CAMERA_VIEW,
} from '@/core/event-names';
import { publish, subscribe, unsubscribe } from '@/core/events';
import { getXYZ } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  useCreateNewCameraMutation,
  projectsApi,
} from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { useAppDispatch } from '@/store/hooks';
import useHandleError from '@/shared/hooks/useHandleError';

export const useSavedCameras = (
  cameraControlRef: RefObject<OrbitControlsImpl>
) => {
  const { id } = useParams();
  const [createCamera, { isLoading }] = useCreateNewCameraMutation();
  const dispatch = useAppDispatch();

  useEffect(() => {
    publish(CHANGE_SAVE_CAMERA_STATE, isLoading);
  }, [isLoading]);

  const saveCurrentCamera = async (
    cameraName: string,
    cameraType: CanvasCameraType
  ) => {
    if (!cameraControlRef.current) return;
    const { handleError } = useHandleError();

    const position = cameraControlRef.current.object.position.clone();
    const target = cameraControlRef.current.target.clone();

    const perspectiveCamera = cameraControlRef.current
      .object as THREE.PerspectiveCamera;

    const cameraObject: CanvasCamera = {
      position: getXYZ(position),
      target: getXYZ(target),
      zoom: cameraControlRef.current.object.zoom,
      name: cameraName,
      type: cameraType,
      fov:
        cameraType === CanvasCameraType.Perspective ? perspectiveCamera.fov : 0,
    };

    try {
      const result = await createCamera({
        data: cameraObject,
        projectId: id!,
      }).unwrap();

      dispatch(
        projectsApi.util.updateQueryData('fetchProject', id!, (projectData) => {
          if (projectData) {
            projectData.cameras = [...projectData.cameras, result];
          }
        })
      );
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    subscribe(SAVE_CURRENT_CAMERA_VIEW, (evt) =>
      saveCurrentCamera(evt.detail.name, evt.detail.type)
    );
    return () => {
      unsubscribe(SAVE_CURRENT_CAMERA_VIEW, (evt) =>
        saveCurrentCamera(evt.detail.name, evt.detail.type)
      );
    };
  }, []);
};
