import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import * as THREE from 'three';
import {
  getProcessingEntity,
  setDirectionalInputValues,
  setShowDirectionalInput,
} from '@/store/slices/canvasExternalElementsSlice';
import {
  getCursorCoordinatesOnOrthographicSystem,
  getObjectCoordinatesOnOrthographicSystem,
  isLeftClick,
  isWithoutClick,
} from '@/shared/helpers';
import { booleanClockwise } from '@turf/turf';
import { useThree } from '@react-three/fiber';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { subscribe, unsubscribe } from '@/core/events';
import GenericStorey from '@/routes/dashboard/projects/project/UserBuilding/components/generic/GenericStorey';
import { C_DashedFatLineContourMaterial } from '@/shared/materials';
import {
  convertPositionAttributeToVectors,
  DEFAULT_FLOOR_HEIGHT_IN_METERS,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  DIRECTIONAL_INPUT__SET,
  DIRECTIONAL_INPUT__UPDATE,
  SET_BUILDING_CREATION_PARAMETERS,
} from '@/core/event-names';
import {
  CanvasActionsModes,
  CreateStoreyCoordinates,
  CreateUserBuildingData,
  DistanceInput,
  DrawModes,
  FlatVector3,
  MetricLimits,
  NumberedInput,
  UnitSystemTypes,
} from '@/models';
import {
  convertBufferGeometryTo3DVectorList,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  setIsInDrawMode,
  setMode,
  setStoreyCreationStep,
  getIsStoreyCreationStep,
  getDrawMode,
} from '@/store/slices/canvasModesSlice';
import { useCreateUserBuildingMutation } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { calculateZoomFromPerspectiveCamera } from '@/shared/helpers/camera';
import { LineGeometry } from 'three-stdlib';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { getUniqArray } from '@/shared/helpers/array';
import { FLOORS_INITIAL_COUNT } from '@/shared/constants';
import { lineString } from '@turf/turf';
import {
  convertMetersToMillimeters,
  convertMillimetersToFtInch,
  convertMillimetersToMeters,
} from '@/shared/helpers/distance';
import { getMultiplyRate, getProjectUnits } from '@/store/slices/projectSlice';
import { Html } from '@react-three/drei';
import arrowUpImage from '@/images/ArrowUp.svg';
import { calculationMatrix2D } from '@/shared/helpers/matrix';
import useFrameProperties from '@/shared/hooks/useFrameProperties';

export const CREATE_USER_BUILDING_CACHE_KEY = 'CREATE_USER_BUILDING_CACHE_KEY';

const CreateUserBuilding: React.FC = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const drawMode = useAppSelector(getDrawMode);
  const [createUserBuilding] = useCreateUserBuildingMutation({
    fixedCacheKey: CREATE_USER_BUILDING_CACHE_KEY,
  });
  const isStoreyCreationStep = useAppSelector(getIsStoreyCreationStep);
  const { updateFloorHeightInBlock } = useFrameProperties();
  const [centerLineCoordinates, setCenterLineCoordinates] = useState<
    FlatVector3[] | null
  >(null);
  const [centerLineWidth, setCenterLineWidth] = useState<number>();

  useEffect(() => {
    !isStoreyCreationStep && resetBuilding();
  }, [isStoreyCreationStep]);

  const processingEntity = useAppSelector(getProcessingEntity)!;
  const isDirectionalInputActive = processingEntity.active;

  const { gl, camera, scene, size } = useThree();

  const [floorShape, setFloorShape] = useState<THREE.Mesh | null>(null);
  const [calculatedFloors, setCalculatedFloors] = useState(0);
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const [floorHeight, setFloorHeight] = useState(0);
  const [baseMesh, setBaseMesh] = useState<THREE.Mesh | null>(null);

  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  useEffect(() => {
    setFloorHeight(DEFAULT_FLOOR_HEIGHT_IN_METERS * multiplyRate);
  }, [multiplyRate]);

  const verticalsGroup = useMemo(() => new THREE.Group(), []);
  const groupRef = useRef<THREE.Group | null>(null);

  const userBuildingUtils = useUpdateUserBuildingData();

  const calculateFloors = (event: PointerEvent) => {
    if (!isWithoutClick(event) || isDirectionalInputActive) return;

    const mousePosition = getCursorCoordinatesOnOrthographicSystem(event, gl);

    const orthographicPosition = getObjectCoordinatesOnOrthographicSystem(
      floorShape!,
      camera,
      gl
    );

    let calculatedStoreys;

    if (camera.type === 'OrthographicCamera') {
      calculatedStoreys = Math.floor(
        (mousePosition.y - orthographicPosition.y) /
          (camera.zoom / 400) /
          floorHeight
      );
    } else {
      const perspectiveCamera = camera as THREE.PerspectiveCamera;

      const zoom = calculateZoomFromPerspectiveCamera(
        perspectiveCamera,
        size.height
      );

      calculatedStoreys = Math.floor(
        (mousePosition.y - orthographicPosition.y) / (zoom / 400) / floorHeight
      );
    }
    const storeysToInsert = Math.min(
      Math.max(calculatedStoreys, 1),
      MetricLimits.FloorsMax
    );

    dispatch(
      setDirectionalInputValues([
        {
          ...processingEntity,
          type: NumberedInput.Floors,
          value: storeysToInsert.toString(),
        },
      ])
    );

    setCalculatedFloors(storeysToInsert);
  };

  const generateVerticals = () => {
    verticalsGroup.clear();
    if (!floorShape) return;

    const position = floorShape.geometry.getAttribute('position');
    const points: THREE.Vector3[] = [];

    for (let i = 0; i < position.count - 1; i++) {
      points.push(new THREE.Vector3().fromBufferAttribute(position, i));
    }

    points.forEach((point) => {
      const geometry = new LineGeometry().setPositions([
        ...getXYZ(point),
        point.x,
        point.y + floorHeight * calculatedFloors,
        point.z,
      ]);

      const material = C_DashedFatLineContourMaterial.clone();
      material.depthTest = true;
      material.dashSize = 1.4;
      material.dashScale = 60;

      const line = new Line2(geometry, material);
      line.computeLineDistances();
      verticalsGroup.add(line);
    });
  };

  const applyFloorShapeAndCenterLine = (evt: {
    detail: {
      floorShape: THREE.Mesh;
      centerLine?: THREE.Line;
      centerLineWidth?: number;
    };
  }) => {
    const positionAttribute =
      evt.detail.floorShape.geometry.getAttribute('position');
    const vertices: THREE.Vector3[] = [];

    for (let i = 0; i < positionAttribute.count; i++) {
      const vertex = new THREE.Vector3().fromBufferAttribute(
        positionAttribute,
        i
      );
      vertices.push(vertex);
    }
    const xzPointsArray = vertices.map((vertex) => [vertex.x, vertex.z]);
    const clockwiseRing = lineString(xzPointsArray);
    const isBuildingClockwise = booleanClockwise(clockwiseRing);

    if (isBuildingClockwise) {
      vertices.reverse();
      const reversedPoints = vertices.flatMap((vertex) => [
        vertex.x,
        vertex.y,
        vertex.z,
      ]);
      evt.detail.floorShape.geometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(reversedPoints, 3)
      );
    }

    setFloorShape(evt.detail.floorShape.clone());
    setBaseMesh(evt.detail.floorShape.clone());
    setCalculatedFloors(FLOORS_INITIAL_COUNT);
    dispatch(setIsInDrawMode(true));
    dispatch(setStoreyCreationStep());
    scene.add(verticalsGroup);

    setCenterLineWidth(evt.detail.centerLineWidth);

    if (evt.detail.centerLine) {
      const centerLine = evt.detail.centerLine;
      const centerLine3DVectorsList = convertBufferGeometryTo3DVectorList(
        centerLine.geometry
      );
      setCenterLineCoordinates(
        getUniqArray(centerLine3DVectorsList.map((vector) => getXYZ(vector)))
      );
    }
  };

  const generateBuildingCoordinates = () => {
    if (!groupRef.current) return;

    const storeys: CreateStoreyCoordinates[] = [];

    for (
      let floorIndex = 0;
      floorIndex < groupRef.current.children.length;
      floorIndex++
    ) {
      const storeyGroupArray = groupRef.current.children[floorIndex].children;

      const currentFloorPosition = (
        storeyGroupArray[0] as THREE.Mesh
      ).geometry.getAttribute('position');

      const ceilingPosition = (
        storeyGroupArray[1] as THREE.Mesh
      ).geometry.getAttribute('position');

      const allWalls: FlatVector3[][] = [];

      for (let i = 0; i < currentFloorPosition.count - 1; i++) {
        const wall: THREE.Vector3[] = [];
        wall.push(
          new THREE.Vector3().fromBufferAttribute(currentFloorPosition, i)
        );
        wall.push(
          new THREE.Vector3().fromBufferAttribute(currentFloorPosition, i + 1)
        );

        wall.push(
          new THREE.Vector3().fromBufferAttribute(ceilingPosition, i + 1)
        );

        wall.push(new THREE.Vector3().fromBufferAttribute(ceilingPosition, i));
        allWalls.push(wall.map((w) => getXYZ(w)));
      }

      const storeyObject: CreateStoreyCoordinates = {
        floor: convertPositionAttributeToVectors(currentFloorPosition),
        ceiling: convertPositionAttributeToVectors(ceilingPosition),
        walls: allWalls,
        storeyNumber: floorIndex + 1,
      };

      storeys.push(storeyObject);
    }

    const userBuildingData: CreateUserBuildingData = {
      drawMode: drawMode,
      storeys,
    };

    if (drawMode === DrawModes.CenterLine && centerLineCoordinates) {
      userBuildingData.centerLineCoordinates = centerLineCoordinates;
      userBuildingData.centerLineWidth = centerLineWidth;
    }

    createUserBuilding({
      projectId: id!,
      data: userBuildingData,
    })
      .unwrap()
      .then((building) => {
        const updatedBuilding = {
          ...building,
          blocks: building.blocks.map((block) => {
            const heightInMillimeters = convertMetersToMillimeters(
              floorHeight / multiplyRate
            );
            const height = isImperialUnits
              ? convertMillimetersToFtInch(heightInMillimeters)
              : heightInMillimeters.toString();

            const updatedBlock = updateFloorHeightInBlock(height, block);

            userBuildingUtils.updateUserBuildingBlockStoreys({
              blockGUID: block.guid,
              updatedBlock,
            });

            return updatedBlock;
          }),
        };

        userBuildingUtils.createUserBuilding(updatedBuilding);
        setCenterLineCoordinates(null);
        setCenterLineWidth(undefined);
      });
  };

  const finishBuilding = () => {
    dispatch(setShowDirectionalInput({ isShow: false }));
    generateBuildingCoordinates();
    verticalsGroup.clear();
    dispatch(setMode(CanvasActionsModes.selection));
    dispatch(setIsInDrawMode(false));
    setFloorHeight(DEFAULT_FLOOR_HEIGHT_IN_METERS * multiplyRate);
  };

  const resetBuilding = () => {
    setFloorShape(null);
    groupRef.current?.clear();
    setCalculatedFloors(0);
    verticalsGroup.clear();
    dispatch(setIsInDrawMode(false));
  };

  const onPointerDown = (event: MouseEvent) => {
    if (isLeftClick(event)) {
      finishBuilding();
    }
  };

  const onKeyDown = (event: KeyboardEvent) => {
    switch (event.key) {
      case 'Enter': {
        finishBuilding();
        return;
      }
      case 'Escape': {
        resetBuilding();
        return;
      }
    }
  };

  const onInputUpdate = (evt: CustomEvent) => {
    switch (processingEntity.type) {
      case NumberedInput.Floors:
        updateFloorAmount(evt.detail);
        return;
      case DistanceInput.FloorHeight:
        updateFloorHeight(evt.detail);
        return;
    }
  };

  const updateFloorAmount = (floorAmount: string) => {
    const newFloorAmount = Number(floorAmount);
    if (newFloorAmount > MetricLimits.FloorsMax) {
      setCalculatedFloors(MetricLimits.FloorsMax);
      dispatch(
        setDirectionalInputValues([
          {
            ...processingEntity,
            value: MetricLimits.FloorsMax.toString(),
            active: true,
          },
        ])
      );
    } else {
      setCalculatedFloors(parseInt(floorAmount));
    }
    generateVerticals();
  };

  const updateFloorHeight = (floorHeight: string) => {
    setFloorHeight(
      Number(convertMillimetersToMeters(floorHeight)) * multiplyRate
    );
    generateVerticals();
  };

  const onInputSet = () => {
    finishBuilding();
  };

  const positionArrow = useMemo(() => {
    if (!baseMesh) return new THREE.Vector3(0, 0, 0);

    const center = baseMesh.geometry.boundingBox!.getCenter(
      new THREE.Vector3()
    );
    center.y += floorHeight * calculatedFloors + 0.042;

    return new THREE.Vector3(center.x, center.y, center.z);
  }, [calculatedFloors]);

  useEffect(() => {
    generateVerticals();
  }, [calculatedFloors, floorHeight]);

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    if (!canvas) return;
    if (floorShape) {
      canvas.addEventListener('pointermove', calculateFloors);
      canvas.addEventListener('pointerdown', onPointerDown);
      document.addEventListener('keydown', onKeyDown);
    } else {
      subscribe(SET_BUILDING_CREATION_PARAMETERS, applyFloorShapeAndCenterLine);
    }

    return () => {
      canvas.removeEventListener('pointermove', calculateFloors);
      canvas.removeEventListener('pointerdown', onPointerDown);
      document.removeEventListener('keydown', onKeyDown);
      unsubscribe(
        SET_BUILDING_CREATION_PARAMETERS,
        applyFloorShapeAndCenterLine
      );
    };
  }, [floorShape, isDirectionalInputActive, floorHeight]);

  useEffect(() => {
    floorShape && subscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
    floorShape && subscribe(DIRECTIONAL_INPUT__SET, onInputSet);
    return () => {
      unsubscribe(DIRECTIONAL_INPUT__UPDATE, onInputUpdate);
      unsubscribe(DIRECTIONAL_INPUT__SET, onInputSet);
    };
  }, [floorShape, processingEntity, floorHeight]);

  const generateStoreys = useCallback(() => {
    return Array.from(Array(calculatedFloors).keys()).map((i) => {
      return (
        floorShape && (
          <GenericStorey
            storeyLevel={i}
            storeyShape={floorShape}
            storeyHeight={floorHeight}
            key={i}
          />
        )
      );
    });
  }, [calculatedFloors, floorHeight]);

  return (
    <>
      <group
        ref={groupRef}
        position={new THREE.Vector3(0.0005, 0.0005, 0.0005)}
      >
        {generateStoreys()}
      </group>
      {floorShape && (
        <Html
          position={positionArrow}
          zIndexRange={[0, 0]}
          center
          calculatePosition={calculationMatrix2D(0, -10)}
          className="select-none w-6 h-6"
        >
          <img src={arrowUpImage} alt="ArrowUpIcon" width={24} height={24} />
        </Html>
      )}
    </>
  );
};
export default CreateUserBuilding;
