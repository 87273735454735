import React from 'react';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import {
  SurroundingBuildingSearchResults,
  useFindNodeData,
} from '@/shared/hooks/useFindNodeData';

const SurroundingBuildingFrame = ({
  envBuildingsGUID,
}: {
  envBuildingsGUID: string[];
}) => {
  const { id } = useParams();
  const { findDataForSurroundingBuilding } = useFindNodeData();

  const address = useFetchProjectQuery(id!).data!.address;

  const getCountry = (address: string) => {
    const words = address.split(', ');
    return words[words.length - 1].trim();
  };

  const selectedBuildingsData = envBuildingsGUID.map(
    (envBuildingGUID) => findDataForSurroundingBuilding(envBuildingGUID)!
  );

  const getBuildingName = (building: SurroundingBuildingSearchResults) =>
    building?.street
      ? `${building.street}, ${building.houseNumber}`
      : `Environment Building`;

  const getValue = (propertyName: keyof SurroundingBuildingSearchResults) => {
    const values = selectedBuildingsData.map(
      (building) => building[propertyName]
    );
    return values.every((value) => value && value === values[0])
      ? `${values[0]}`
      : '-';
  };

  return (
    <>
      <div className="flex px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden">
          {selectedBuildingsData.length > 1
            ? `Surrounding building (${selectedBuildingsData.length})`
            : getBuildingName(selectedBuildingsData[0])}
        </span>
      </div>
      <div
        className={
          'text-xs font-light border border-l-0 border-solid border-light-gray-20 flex-1 h-[calc(100%_-_2rem)] bg-white! overflow-y-auto'
        }
      >
        <PropertyList
          properties={[
            {
              name: 'Country',
              value: `${address ? getCountry(address) : '-'}`,
            },
            {
              name: 'Zip code',
              value: `${getValue('postCode')}`,
            },
            {
              name: 'State',
              value: `${getValue('state')}`,
            },
            {
              name: 'City',
              value: `${getValue('city')}`,
            },
            {
              name: 'Street',
              value: `${getValue('street')}`,
            },
            {
              name: 'House number',
              value: `${getValue('houseNumber')}`,
            },
            {
              name: 'Building type',
              value: `${getValue('building')}`,
            },
            {
              name: 'Building name',
              value: `${getValue('name')}`,
            },
          ]}
        />
      </div>
    </>
  );
};

export default SurroundingBuildingFrame;
