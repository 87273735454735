import React from 'react';

const InnerCornerEditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2549 0.569855C10.1097 0.483792 9.93082 0.476825 9.77934 0.551328L2.94694 3.91164C2.67333 4.0462 2.5 4.3246 2.5 4.62951V15.0215C2.5 15.6139 3.12147 16.0008 3.65307 15.7393L7.24631 13.9721C7.13553 13.652 7.05987 13.3154 7.02395 12.9671L3.5 14.7002V4.75403L9.5 1.80311V8.46776C9.81426 8.31159 10.1494 8.19107 10.5 8.11151V1.8775L12.5 3.06269V8.11151C12.8507 8.19108 13.1858 8.31159 13.5 8.46776V2.94873C13.5 2.66616 13.3509 2.40455 13.1078 2.2605L10.2549 0.569855Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.2549 0.569855C10.1097 0.483792 9.93082 0.476825 9.77934 0.551328L2.94694 3.91164C2.67333 4.0462 2.5 4.3246 2.5 4.62951V15.0215C2.5 15.6139 3.12147 16.0008 3.65307 15.7393L7.24631 13.9721C7.13553 13.652 7.05987 13.3154 7.02395 12.9671L3.5 14.7002V4.75403L9.5 1.80311V8.46776C9.81426 8.31159 10.1494 8.19107 10.5 8.11151V1.8775L12.5 3.06269V8.11151C12.8507 8.19108 13.1858 8.31159 13.5 8.46776V2.94873C13.5 2.66616 13.3509 2.40455 13.1078 2.2605L10.2549 0.569855Z"
        fill="black"
        fillOpacity="0.2"
      />
      <path
        d="M11.3717 9.80468L12.6953 11.1283M13.2259 9.27413C12.8604 8.90862 12.2678 8.90862 11.9023 9.27413L9.09171 12.0847C8.95811 12.2183 8.86284 12.3853 8.81587 12.5683L8.50561 13.7771C8.48992 13.8382 8.50767 13.9031 8.55229 13.9477C8.59692 13.9923 8.66178 14.0101 8.72291 13.9944L9.93168 13.6841C10.1147 13.6372 10.2817 13.5419 10.4153 13.4083L13.2259 10.5977C13.5914 10.2322 13.5914 9.63964 13.2259 9.27413Z"
        stroke="#343335"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default InnerCornerEditIcon;
