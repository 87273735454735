import React from 'react';
import { useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router';
import {
  getExtrudeData,
  getSelectedNodes,
} from '@/store/slices/canvasBuildingSlice';
import { getCanvasMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes } from '@/models';
import EditToolbar from '@/shared/elements/EditToolbar';
import { isCopyingProperties } from '@/store/slices/copyPropertiesSlice';

const CanvasInternalElements = () => {
  const { id } = useParams();
  const selectedNodes = Object.values(useAppSelector(getSelectedNodes));
  const extrudedNode = useAppSelector(getExtrudeData);
  const mode = useAppSelector(getCanvasMode);
  const isCopyingProcessing = useAppSelector(isCopyingProperties);

  const isDesignerMode = mode === CanvasActionsModes.facadeDesigner;

  const isAllNodesWithSameType =
    selectedNodes.length > 1 &&
    selectedNodes.every((node) => node.type === selectedNodes[0].type);

  const showEditToolbar =
    !extrudedNode &&
    (selectedNodes.length === 1 || isAllNodesWithSameType) &&
    !isCopyingProcessing &&
    !isDesignerMode;

  return (
    <>
      {showEditToolbar && (
        <EditToolbar projectId={id!} selectedNodes={selectedNodes} />
      )}
    </>
  );
};

export default CanvasInternalElements;
