import React from 'react';
import FacadeDesignerGridLine from '@/components/FacadeDesigner/elements/FacadeDesignerGridLine';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import { GridLineData, UnitSystemTypes } from '@/models';
import {
  setSelectedGridLines,
  getSelectedGridlines,
  setGridPlacementAbsoluteOffset,
  getHoveredGridLine,
  getIsSomeWindowSelected,
  getDragNode,
  getDragCopyOffset,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { useGridLines } from '../hooks';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import { getWallWidth } from '@/shared/helpers/metrics';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { round } from 'mathjs';
import { inRange } from 'lodash';
import { RootState } from '@/store';
import { useStore } from 'react-redux';

interface FacadeDesignerGridLinesProps {
  scale: number;
  unitSystem: UnitSystemTypes;
  wallHeight: number;
  wallWidth: number;
  wallData: WallSearchResults;
  xWallOffset: number;
}

const FacadeDesignerGridLines = ({
  scale,
  unitSystem,
  wallHeight,
  wallWidth,
  wallData,
  xWallOffset,
}: FacadeDesignerGridLinesProps) => {
  const { id } = useParams();
  const userBuildings = useFetchProjectQuery(id!).data!.buildings!;
  const buildingGUID = userBuildings[0]!.guid;
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const isSomeWindowSelected = useAppSelector(getIsSomeWindowSelected);
  const isDragElementActive = !!useAppSelector(getDragNode);
  const isDragCopyInProcess = !!useAppSelector(getDragCopyOffset);
  const store = useStore();

  const { updateBuildingPlacements } = useUpdateUserBuildingData();
  const { removeDuplicateGridLines } = useGridLines(buildingGUID);
  const { findDataForWall } = useFindNodeData();

  const dispatch = useAppDispatch();
  const hoveredGridLine = useAppSelector(getHoveredGridLine);

  const getSelectedGridLines = () =>
    getSelectedGridlines(store.getState() as RootState);

  const handleMeasurementSubmit = () => {
    updateBuildingPlacements({
      buildingGUID,
      placementData: getSelectedGridLines().map((selectedGridLine) => {
        const relatedWallData = findDataForWall(selectedGridLine.wallGUID)!;

        const wallWidthRelatedToGridLine = convertMetersToMillimeters(
          getWallWidth(relatedWallData.points, multiplyRate)
        );

        const gridLines = removeDuplicateGridLines(
          relatedWallData.gridLines
        ).filter((line) =>
          inRange(
            selectedGridLine.absoluteOffset,
            round(selectedGridLine.absoluteOffset - line.offsetFromLeftEdge, 2),
            round(
              wallWidthRelatedToGridLine +
                round(
                  selectedGridLine.absoluteOffset - line.offsetFromLeftEdge,
                  2
                )
            )
          )
        );

        return {
          wallGUID: relatedWallData.guid,
          wallPanels: [],
          gridLines,
        };
      }),
    });
    dispatch(setSelectedGridLines([]));
    dispatch(setGridPlacementAbsoluteOffset(null));
  };
  const handleMeasurementChange = (offsetDiff: number) => {
    dispatch(
      setGridPlacementAbsoluteOffset(
        getSelectedGridLines()[0].absoluteOffset + offsetDiff
      )
    );
    updateBuildingPlacements({
      buildingGUID,
      placementData: getSelectedGridLines().map((selectedGridLine) => {
        const relatedWallData = findDataForWall(selectedGridLine.wallGUID)!;
        return {
          wallGUID: relatedWallData.guid,
          wallPanels: relatedWallData.wallPanels,
          gridLines: relatedWallData.gridLines.map((wallGridLine) => {
            return wallGridLine.guid === selectedGridLine.guid
              ? {
                  ...wallGridLine,
                  offsetFromLeftEdge: round(
                    selectedGridLine.offsetFromLeftEdge + offsetDiff,
                    2
                  ),
                }
              : wallGridLine;
          }),
        };
      }),
      triggerBEUpdate: false,
    });
  };
  const handleMeasurementEscape = () => {
    dispatch(setSelectedGridLines([]));
    dispatch(setGridPlacementAbsoluteOffset(null));
    updateBuildingPlacements({
      buildingGUID: userBuildings[0].guid,
      placementData: getSelectedGridLines().map((selectedGridLine) => {
        const relatedWallData = findDataForWall(selectedGridLine.wallGUID)!;
        return {
          wallGUID: relatedWallData.guid,
          wallPanels: relatedWallData.wallPanels,
          wallPanelErrors: relatedWallData.wallPanelErrors,
          gridLines: relatedWallData.gridLines.map((wallGridLine) => {
            return {
              guid: wallGridLine.guid,
              cornerAlign: wallGridLine.cornerAlign,
              offsetFromLeftEdge:
                wallGridLine.guid === selectedGridLine.guid
                  ? selectedGridLine.offsetFromLeftEdge
                  : wallGridLine.offsetFromLeftEdge,
            };
          }),
        };
      }),
      triggerBEUpdate: false,
    });
  };

  const getIsHoveredOnLine = (gridLine: GridLineData) => {
    if (isNaN(Number(xWallOffset)) || !hoveredGridLine || gridLine.cornerAlign)
      return false;

    const absoluteOffset = round(
      (xWallOffset ?? 0) + gridLine.offsetFromLeftEdge,
      2
    );

    return hoveredGridLine.absoluteOffset === absoluteOffset;
  };

  return (
    <>
      {wallData.gridLines.map((gridLine, idx) => (
        <FacadeDesignerGridLine
          key={idx}
          gridLine={gridLine}
          scale={scale}
          unitSystem={unitSystem}
          wallHeight={wallHeight}
          wallWidth={wallWidth}
          wallOffset={xWallOffset}
          wallData={wallData}
          isSomeWindowSelected={isSomeWindowSelected}
          isDragElementActive={isDragElementActive}
          isDragCopyInProcess={isDragCopyInProcess}
          onMeasurementSubmit={handleMeasurementSubmit}
          onMeasurementEscape={handleMeasurementEscape}
          onMeasurementChange={handleMeasurementChange}
          isHoveredOnLine={getIsHoveredOnLine(gridLine)}
        />
      ))}
    </>
  );
};

export default FacadeDesignerGridLines;
