import React from 'react';
import { UserBuildingPanel } from '@/models';
import InnerFrameTable, {
  GroupedInnerFrameData,
} from '@/components/Report/Pages/PanelMetrics/InnerFrameTable/InnerFrameTable';

interface PanelMetricsProps {
  panelData: UserBuildingPanel;
  panelIndex: number;
  innerFramesData: GroupedInnerFrameData[];
  isSplittedTable: boolean;
}

const PanelMetricsTableContinue: React.FC<PanelMetricsProps> = ({
  panelData,
  panelIndex,
  innerFramesData,
  isSplittedTable,
}) => {
  return (
    <div className="h-full w-full flex flex-col">
      <div className="px-3 py-1 flex gap-6 text-[11px] leading-4 bg-light-gray-10 border-0 border-b border-solid border-light-gray-20">
        <div className="text-light-green-100"># {panelIndex + 1}</div>
        <div className="to-dark-gray-100">P {panelData.id}</div>
        <div className="to-dark-gray-100">
          {panelData.cornerSide ? 'Corner UCW Unit' : 'Straight UCW Unit'}
        </div>
      </div>
      <div className="flex flex-col px-3">
        <InnerFrameTable
          panelData={panelData}
          innerFramesData={innerFramesData}
          isSplittedTable={isSplittedTable}
        />
      </div>
    </div>
  );
};

export default PanelMetricsTableContinue;
