import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import {
  FlatVector2Axis,
  UTCElementType,
} from '@/components/WindowCreator/models/konva-model';
import { FlatVector2 } from '@/models';
import { UnitTypeCreatorModes } from '@/models/shared.model';
import { UTCFormData } from '@/components/UTC/models';

interface UTCState {
  selectedNodes: {
    [id: number]: { type: UTCElementType; points: FlatVector2[] };
  };
  validationError: {
    [id: number]: string[];
  };
  initialData?: UTCFormData;
  selectedAreas?: FlatVector2Axis[][];
  hoveredAreas?: FlatVector2Axis[][];
  mullionArea?: FlatVector2[];
  mode: UnitTypeCreatorModes;
  multiMeasurementActiveStatus: boolean;
  selectedPanelGuidForEditing?: string;
}

const initialState: UTCState = {
  selectedNodes: {},
  validationError: {},
  mode: UnitTypeCreatorModes.Selection,
  multiMeasurementActiveStatus: false,
};

export const unitTypeCreator = createSlice({
  name: 'units',
  initialState,
  reducers: {
    setUTCSelectedNode: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: number;
        type: UTCElementType;
        points: FlatVector2[];
      }>
    ) => {
      state.selectedNodes = {
        [payload.id]: { type: payload.type, points: payload.points },
      };
    },
    addUTCSelectedNode: (
      state,
      {
        payload,
      }: PayloadAction<{
        id: number;
        type: UTCElementType;
        points: FlatVector2[];
      }>
    ) => {
      state.selectedNodes = {
        ...state.selectedNodes,
        [payload.id]: { type: payload.type, points: payload.points },
      };
    },
    clearSelectedNodes: (state) => {
      state.selectedNodes = {};
    },
    setSelectArea: (state, action: PayloadAction<FlatVector2Axis[]>) => {
      state.selectedAreas = [action.payload];
    },
    setSelectedAreaForFrame: (
      state,
      action: PayloadAction<FlatVector2Axis[][]>
    ) => {
      state.selectedAreas = action.payload;
    },
    addSelectedArea: (state, action: PayloadAction<FlatVector2Axis[]>) => {
      state.selectedAreas = state.selectedAreas
        ? [...state.selectedAreas, action.payload]
        : [action.payload];
    },
    resetSelectedArea: (state) => {
      state.selectedAreas = undefined;
    },
    setHoverArea: (state, action: PayloadAction<FlatVector2Axis[]>) => {
      state.hoveredAreas = [action.payload];
    },
    setHoveredAreaForFrame: (
      state,
      action: PayloadAction<FlatVector2Axis[][]>
    ) => {
      state.hoveredAreas = action.payload;
    },
    resetHoverArea: (state) => {
      state.hoveredAreas = undefined;
    },

    resetUTCSlice: () => initialState,

    clearSelectRelatedData: (state) => {
      state.selectedNodes = {};
      state.selectedAreas = undefined;
      state.hoveredAreas = undefined;
    },
    setValidationError: (
      state,
      { payload }: PayloadAction<{ id: number; errors: string[] }>
    ) => {
      state.validationError = {
        ...state.validationError,
        [payload.id]: payload.errors,
      };
    },
    setUnitTypeCreatorMode: (
      state,
      action: PayloadAction<UnitTypeCreatorModes>
    ) => {
      state.mode = action.payload;
    },

    setMullionArea: (state, action: PayloadAction<FlatVector2[]>) => {
      state.mullionArea = action.payload;
    },
    setInitialData: (state, action: PayloadAction<UTCFormData>) => {
      state.initialData = action.payload;
    },
    setMultiMeasurementActiveStatus: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.multiMeasurementActiveStatus = action.payload;
    },
    resetMullionArea: (state) => {
      state.mullionArea = undefined;
    },
    setSelectedPanelGuidForEditing: (
      state,
      { payload }: PayloadAction<string | undefined>
    ) => {
      state.selectedPanelGuidForEditing = payload;
    },
  },
});

export const {
  setUTCSelectedNode,
  clearSelectedNodes,
  setSelectArea,
  setHoverArea,
  setInitialData,
  setValidationError,
  setUnitTypeCreatorMode,
  resetSelectedArea,
  resetHoverArea,
  clearSelectRelatedData,
  resetUTCSlice,
  setMullionArea,
  resetMullionArea,
  setMultiMeasurementActiveStatus,
  addUTCSelectedNode,
  addSelectedArea,
  setSelectedAreaForFrame,
  setSelectedPanelGuidForEditing,
  setHoveredAreaForFrame,
} = unitTypeCreator.actions;

const unitSlice = (state: RootState) => state.windowsReducer.units;
export const getIsUTCNodeSelected = (id?: number) => (state: RootState) =>
  id ? !!unitSlice(state).selectedNodes[id] : false;

export const getSelectedArea = (state: RootState) =>
  unitSlice(state).selectedAreas;

export const getHoveredAreas = (state: RootState) =>
  unitSlice(state).hoveredAreas;

export const getSomeFillerHaveValidationErrors = (state: RootState) =>
  Object.values(unitSlice(state).validationError).some((obj) => obj.length > 0);

export const getUnitTypeCreatorMode = (state: RootState) =>
  unitSlice(state).mode;

export const getSelectedUTCNodeType = (state: RootState) =>
  Object.values(unitSlice(state).selectedNodes)[0]?.type;

export const getAllSelectedUTCNodes = (state: RootState) =>
  unitSlice(state).selectedNodes;

export const getSelectedUTCNodePoints = (state: RootState) =>
  Object.values(unitSlice(state).selectedNodes)[0]?.points;

export const getHasSelectedNode = (state: RootState) =>
  Object.keys(unitSlice(state).selectedNodes).length > 0;

export const getValidationErrors = (id: number) => (state: RootState) =>
  unitSlice(state).validationError[id];

export const getInitialData = (state: RootState) =>
  unitSlice(state).initialData;

export const getMullionArea = (state: RootState) =>
  unitSlice(state).mullionArea;

export const getMultiMeasurementActiveStatus = (state: RootState) =>
  unitSlice(state).multiMeasurementActiveStatus;

export const isUTCEditing = (state: RootState) =>
  !!unitSlice(state).initialData;

export const getSelectedPanelGuidForEditing = (state: RootState) =>
  unitSlice(state).selectedPanelGuidForEditing;
