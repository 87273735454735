import React from 'react';
import { WINDOW_GLASS_SELECTED_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { Group, Line } from 'react-konva';
import { INITIAL_SCALE } from '@/components/WindowCreator/constants';
import { useAppSelector } from '@/store/hooks';
import {
  getHoveredArea,
  getSelectedArea,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { calculateAxis } from '@/shared/helpers/konva';

const SelectWrapper = ({ scale }: { scale: number }) => {
  const selectedArea = useAppSelector(getSelectedArea);
  const hoveredArea = useAppSelector(getHoveredArea);

  return (
    <Group>
      {!!hoveredArea &&
        hoveredArea?.map((point, i) => {
          const lineWithOffset = calculateAxis(point, i, 5);
          return (
            <Line
              points={lineWithOffset}
              strokeWidth={(INITIAL_SCALE * 8) / scale}
              stroke={WINDOW_GLASS_SELECTED_COLOR}
              closed
              opacity={1}
              key={i}
            />
          );
        })}
      {!!selectedArea && (
        <>
          {selectedArea?.map((point, i) => {
            const lineWithOffset = calculateAxis(point, i, 5);
            return (
              <Line
                points={lineWithOffset}
                strokeWidth={(INITIAL_SCALE * 8) / scale}
                stroke={WINDOW_GLASS_SELECTED_COLOR}
                closed
                opacity={1}
                key={i}
              />
            );
          })}
          <Line
            points={selectedArea?.flat(2)}
            fill={WINDOW_GLASS_SELECTED_COLOR}
            closed
            opacity={0.1}
            listening={false}
          />
        </>
      )}
    </Group>
  );
};

export default SelectWrapper;
