import React from 'react';
import Progress, { ProgressProps } from 'antd/es/progress';

import './AntdProgressBar.scss';

const IntusProgressBar: React.FC<ProgressProps> = ({ ...rest }) => {
  return <Progress {...rest} />;
};

export default IntusProgressBar;
