import React, { useMemo, useState } from 'react';
import { useParams } from 'react-router';
import { useGLTF } from '@react-three/drei';
import * as THREE from 'three';

import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { FlatVector3, NodeType, WindowPlacementData } from '@/models';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  getExtendedVector,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { RENDER_ORDERS } from '@/shared/constants';
import { getWindowHeight, getWindowWidth } from '@/shared/helpers';
import { scaleMesh } from '@/shared/helpers/canvas';

interface ViewerWindowProps {
  scale: number;
  placementData: WindowPlacementData;
  wallPoints: FlatVector3[];
  wallGuid: string;
}

const ViewerWindow: React.FC<ViewerWindowProps> = ({
  scale,
  placementData,
  wallPoints,
  wallGuid,
}) => {
  const { id } = useParams();

  const fetchedWindowData = useFetchWindowsQuery(id!).data;
  if (!fetchedWindowData) return null;

  const [loadAttempts, setLoadAttempts] = useState(0);

  const data = fetchedWindowData.find(
    (windowData) => windowData.id === placementData.windowId
  );

  const leftSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallPoints[1]),
    [wallPoints]
  );
  const rightSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallPoints[0]),
    [wallPoints]
  );

  const perpendicularForWall = useMemo(
    () =>
      getPerpendicularVectorToVectors([leftSideBottom, rightSideBottom], true),
    [leftSideBottom, rightSideBottom]
  );

  if (!data) return null;

  const windowWidth = getWindowWidth(data);
  const windowHeight = getWindowHeight(data);

  //window position is in the center of the window model
  const getWindowPosition = () => {
    const position = getExtendedVector(
      leftSideBottom,
      rightSideBottom,
      convertMillimetersToMeters(
        placementData.offsetFromLeftEdge + windowWidth / 2
      ) * scale
    );
    position.setY(
      position.y +
        convertMillimetersToMeters(data.distanceToFloor + windowHeight / 2) *
          scale
    );
    return getTranslatedVector(position, 0.0001, perpendicularForWall);
  };

  const getLookAtPosition = () => {
    return getTranslatedVector(getWindowPosition(), 100, perpendicularForWall);
  };

  let windowModel: THREE.Group;

  try {
    const gltf = useGLTF(data!.model);
    windowModel = gltf.scene.clone();
    windowModel.castShadow = true;
    windowModel.receiveShadow = true;
  } catch {
    if (loadAttempts <= 20) {
      setTimeout(() => setLoadAttempts(loadAttempts + 1), 250);
      return <></>;
    } else {
      return null;
    }
  }

  scaleMesh(windowModel, convertMillimetersToMeters(scale));

  windowModel.position.copy(getWindowPosition());
  windowModel.lookAt(getLookAtPosition());
  windowModel.renderOrder = RENDER_ORDERS.WINDOW_MODEL;
  windowModel.traverse(
    (child) => (child.userData = { guid: wallGuid, nodeType: NodeType.Wall })
  );

  return <primitive object={windowModel} />;
};

export default ViewerWindow;
