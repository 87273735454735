import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { UnitSystemTypes } from '@/models';
import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { Position } from '@turf/turf';
import { formatAreaValue } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import { Color } from 'three';
import {
  GENERATE_CAMERA_SNAPSHOTS,
  GENERATE_CAMERA_SNAPSHOTS_SUCCESS,
} from '@/core/event-names';
import { publish, subscribe, unsubscribe } from '@/core/events';
import { Side } from '@/models/camera.model';
import useBuildingMetrics from '@/shared/hooks/useBuildingMetrics';
import { uuidv7 } from 'uuidv7';
import { REPORT_BACKGROUND_COLOR } from '@/shared/constants';

const BuildingProperties = () => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const projectData = useFetchProjectQuery(id!).data!;
  const { data: panelsData } = useGetAllPanelsQuery(id!);
  const [snapshots, setSnapshots] = useState<string[]>([]);
  const constructionSite = projectData.environment.constructionSite;
  const {
    getBlockStoreysCountMetric,
    getBlockHeightMetric,
    getGrossInternalAreaForBlocks,
    getFacadesAreaMetricForBlocks,
    calculateConstructionSiteArea,
    getUsedConstructionSiteArea,
    getAreaUnit,
  } = useFrameProperties();

  const { calculateGlazedAreaData } = useBuildingMetrics();

  const constructionSiteArea = calculateConstructionSiteArea(
    constructionSite?.points as Position[]
  );
  const usedConstructionSiteArea = getUsedConstructionSiteArea(projectData);
  const glazedAreaData = calculateGlazedAreaData(panelsData!);
  const isReportRoute = location.pathname.endsWith('/report');

  useEffect(() => {
    if (isReportRoute) return;

    const processId = uuidv7();

    const handleSaveSnapshots = ({
      detail: { urls, processId: eventProcessId },
    }: {
      detail: { urls: string[]; processId: string };
    }) => {
      if (eventProcessId === processId) {
        setSnapshots([...urls]);
      }
    };

    subscribe(GENERATE_CAMERA_SNAPSHOTS_SUCCESS, handleSaveSnapshots);

    publish(GENERATE_CAMERA_SNAPSHOTS, {
      processId,
      filters: [
        {
          side: Side.FRONT,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
        {
          side: Side.LEFT,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
        {
          side: Side.RIGHT,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
        {
          side: Side.BACK,
          targetGUID: projectData.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 1 },
        },
        {
          side: Side.TOP,
          targetGUID: 'map_guid',
          includeEnvironment: [
            projectData.buildings![0].guid,
            projectData.environment.constructionSite.guid,
          ],
          background: { color: new Color('#000'), alpha: 0 },
        },
      ],
    });

    return () => {
      unsubscribe(GENERATE_CAMERA_SNAPSHOTS_SUCCESS, handleSaveSnapshots);
    };
  }, []);

  const [frontView, leftView, rightView, backView, topView] = snapshots;

  return (
    <div className="h-full w-full flex flex-col">
      <div
        className="p-6 box-border grid grid-cols-2 grid-rows-2 gap-4 flex-1 overflow-hidden"
        style={{ backgroundColor: '#ECEEEE' }}
      >
        <div className="overflow-hidden">
          <img className="h-full w-full" src={frontView} alt="First View" />
        </div>
        <div className="overflow-hidden">
          <img className="h-full w-full" src={leftView} alt="Second View" />
        </div>
        <div className="overflow-hidden">
          <img className="h-full w-full" src={rightView} alt="Third View" />
        </div>
        <div className="overflow-hidden">
          <img className="h-full w-full" src={backView} alt="Fourth View" />
        </div>
      </div>
      <div className="flex mt-auto text-[10px] ">
        <div className="w-[339px] box-border border-0 border-r border-solid border-light-gray-20 px-5 py-4 flex flex-col gap-1 leading-4">
          <div className="font-medium text-dark-gray-100 uppercase">
            building
          </div>

          <div className="flex justify-between">
            <div className="font-light">Floor count</div>
            <div className="pr-6">
              {getBlockStoreysCountMetric(projectData.buildings![0].blocks)}
            </div>
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Building height</div>

              <div className="flex gap-1">
                {getBlockHeightMetric(projectData.buildings![0].blocks)}
                <div className="text-dark-gray-60 font-light w-5">
                  {!isImperialUnits && 'mm'}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Gross internal area</div>
              <div className="flex gap-1">
                {getGrossInternalAreaForBlocks(
                  projectData.buildings![0].blocks
                )}
                <div className="text-dark-gray-60 font-light w-5">
                  {getAreaUnit()}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Facades area</div>
              <div className="flex gap-1">
                {getFacadesAreaMetricForBlocks(
                  projectData.buildings![0].blocks
                )}
                <div className="text-dark-gray-60 font-light w-5">
                  {getAreaUnit()}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Glazed facade area</div>
              <div className="flex gap-1">
                <div className="flex gap-1">
                  {glazedAreaData.glazedArea}
                  <div className="font-light text-dark-gray-60 w-5">
                    {' '}
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  {glazedAreaData.glazedAreaUsage}{' '}
                  <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-2">
              <div className="font-light whitespace-nowrap">
                Non-glazed facade area
              </div>
              <div className="flex gap-1">
                <div className="flex gap-1">
                  {glazedAreaData.nonGlazeArea}
                  <div className="font-light text-dark-gray-60 w-5">
                    {' '}
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  {glazedAreaData.nonGlazeAreaUsage}{' '}
                  <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="grow flex justify-between px-5 gap-3 leading-4 whitespace-nowrap">
          <div className="flex-1 py-4 flex flex-col gap-1">
            <div className="uppercase font-medium">site</div>
            <div className="flex flex-col gap-0.5">
              <div className="flex justify-between">
                <div className="font-light">Site area</div>
                <div className="flex gap-1">
                  {formatAreaValue(constructionSiteArea, isImperialUnits)}
                  <div className="text-dark-gray-60 font-light w-5">
                    {getAreaUnit()}
                  </div>
                </div>
              </div>
              <div className="flex justify-between gap-2">
                <div className="font-light">Area used</div>
                <div className="flex gap-1">
                  <div className="flex gap-1">
                    {formatAreaValue(
                      usedConstructionSiteArea.occupiedArea,
                      isImperialUnits
                    )}
                    <div className="font-light text-dark-gray-60 w-5">
                      {' '}
                      {getAreaUnit()}
                    </div>
                  </div>
                  <div className="flex gap-1">
                    {usedConstructionSiteArea.usageRate}
                    <div className="font-light text-dark-gray-60 w-5">%</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="py-[15px]">
            <img className="w-[130px] h-[130px]" src={topView} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default BuildingProperties;
