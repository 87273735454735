import React, { useState } from 'react';
import Space from 'antd/es/space';
import UserSettings from '@/components/UserSettings';
import { Avatar, IntusDropdown } from '@/shared/elements';
import { useAuth0 } from '@auth0/auth0-react';
import { MenuProps } from 'antd/es/menu';
import { LogOutIcon, SettingsIcon, VectorDownIcon } from '@/shared/icons';
import { getUserName } from '@/shared/helpers/user-data';

const menuItems: MenuProps['items'] = [
  {
    label: 'Settings',
    key: 'settings',
    icon: <SettingsIcon />,
  },
  {
    label: 'Log out',
    key: 'logout',
    icon: <LogOutIcon />,
  },
];

const Account = () => {
  const [isSettingsOpened, setIsSettingsOpened] = useState(false);

  const { logout, user } = useAuth0();

  const onMenuClick = ({ key }: { key: string }) => {
    switch (key) {
      case 'settings':
        setIsSettingsOpened(true);
        break;
      case 'logout':
        logout({
          logoutParams: { returnTo: `${window.location.origin}` },
        });
        break;
    }
  };

  return (
    <>
      <div className="text-black mr-2.5 cursor-pointer flex items-center group">
        <IntusDropdown
          customMenuProps={{
            items: menuItems,
            onClick: onMenuClick,
          }}
          menuType="simplified"
        >
          <Space size={'small'}>
            <Avatar imgSrc={user?.picture} initial={getUserName(user!).at(0)} />
            <div className={'max-w-[150px] truncate text-sm leading-unset'}>
              {getUserName(user!)}
            </div>
            <VectorDownIcon
              stroke={'#000'}
              className={'transition-all duration-100 group-hover:mt-[6px]'}
            />
          </Space>
        </IntusDropdown>
      </div>
      <UserSettings isOpen={isSettingsOpened} setIsOpen={setIsSettingsOpened} />
    </>
  );
};
export default Account;
