import { round } from 'mathjs';

import { Background, Color } from '../models';
import { RALColors, WindowColor } from '@/models/window-configurator.model';
import { FillerType } from '@/components/UTC/models';
import {
  CornerGridAlign,
  FlatVector2,
  PanelPlacementData,
  UserBuildingPanel,
} from '@/models';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import { WINDOW_GLASS_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { HANDLE_SCALE } from '../constants';

export const getAdjustedScale = (scale: number) =>
  scale < 1 ? round(scale * HANDLE_SCALE, 2) : HANDLE_SCALE;

export const getMaterialColorByCriteria = (
  material: FillerType,
  color: RALColors,
  configColors: WindowColor[]
): Color => {
  const isGlass = material === FillerType.Glass;
  const isEnamelGlass = material === FillerType.EnamelGlass;

  return {
    hex: isGlass
      ? WINDOW_GLASS_COLOR
      : (getHexByName(configColors, color) ?? Background.DEFAULT),
    hasGlare: isEnamelGlass,
  };
};

export function normalizeSvgPoints(
  points: FlatVector2[][],
  normalizePoint: FlatVector2
): FlatVector2[][];

export function normalizeSvgPoints(
  points: FlatVector2[],
  normalizePoint: FlatVector2
): FlatVector2[];

export function normalizeSvgPoints(
  points: FlatVector2[][] | FlatVector2[],
  normalizePoint: FlatVector2
): FlatVector2[][] | FlatVector2[] {
  if (Array.isArray(points[0][0])) {
    return (points as FlatVector2[][]).map((point) =>
      point.map(([x, y]) => [
        round(x - normalizePoint[0], 2),
        round(y - normalizePoint[1], 2),
      ])
    ) as FlatVector2[][];
  }

  return (points as FlatVector2[]).map(([x, y]) => [
    round(x - normalizePoint[0], 2),
    round(y - normalizePoint[1], 2),
  ]);
}

export const getUniqBuildingUnits = (
  buildingUnits: PanelPlacementData[],
  libraryUnits: UserBuildingPanel[]
) => {
  const uniqueUnits = buildingUnits.reduce<Record<number, UserBuildingPanel>>(
    (acc, buildingUnit) => {
      if (!acc[buildingUnit.panelId]) {
        const unit = libraryUnits.find(
          (libraryUnit) => libraryUnit.id === buildingUnit.panelId
        );
        if (unit) {
          acc[buildingUnit.panelId] = unit;
        }
      }
      return acc;
    },
    {}
  );

  return Object.values(uniqueUnits);
};

export const getNumberedUnits = (
  units: UserBuildingPanel[]
): Map<number, UserBuildingPanel & { unitNumber: number }> => {
  const sortedUnits = units.slice().sort((a, b) => a.id - b.id);

  const unitsNumbered = new Map();
  let unitIndex = 1;

  sortedUnits.forEach((unit) => {
    unitsNumbered.set(unit.id, {
      ...unit,
      unitNumber: unitIndex,
    });

    if (unit.cornerSide !== CornerGridAlign.Right) unitIndex += 1;
  });

  sortedUnits.forEach((unit) => {
    if (unit.cornerSide === CornerGridAlign.Right) {
      const leftCorner = unitsNumbered.get(unit.cornerSideUnitId);
      if (leftCorner) {
        unitsNumbered.set(unit.id, {
          ...unit,
          unitNumber: leftCorner.unitNumber,
        });
      }
    }
  });

  return unitsNumbered;
};
