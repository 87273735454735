import Form, { FormInstance } from 'antd/es/form';
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FillerType, UTCFormData } from '../models';
import { Html } from 'react-konva-utils';
import { useAppSelector } from '@/store/hooks';
import {
  getAllSelectedUTCNodes,
  getSelectedUTCNodePoints,
  getSelectedUTCNodeType,
} from '@/store/slices/windowsReducer/UTCSlice';
import { isEqual } from 'lodash';
import {
  UTCElementType,
  UTCNestedMenu,
  View,
} from '@/components/WindowCreator/models';
import {
  getColorDescriptionByName,
  getHexByName,
  getMaterialImage,
} from '@/components/WindowCreator/helpers/config';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { IntusIconButton } from '@/shared/elements';
import ArrowLeftMenuIcon from '@/shared/icons/ArrowLeftMenuIcon';
import {
  handleChangeFillerType,
  handleChangeFillerTypeForMultipleInnerFrames,
  handleChangeWindowIGU,
  handleChangeWindowType,
} from '../helpers/form-data';
import { useClickAway } from 'react-use';
import { getWindowTypeBlock } from '@/components/WindowCreator/elements/WindowMenu/WindowMenu';
import { OperationType, RALColors } from '@/models/window-configurator.model';
import { SET_WINDOW_CONTEXT_MENU_POSITION } from '@/core/event-names';
import { publish } from '@/core/events';
import IguSelect, {
  IguValidationData,
} from '@/shared/elements/IguSelect/IguSelect';
import {
  getMaterialAdditionalInfo,
  getMaterialDescription,
} from '@/models/panelsConfig';
import {
  STAGE_HEIGHT,
  STAGE_WIDTH,
} from '@/components/WindowCreator/constants';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import useUnitIGuValidation from '../hooks/useUnitIguValidation';

interface UTCMenuProps {
  menuPosition: { x: number; y: number };
  setMenuPosition: Dispatch<SetStateAction<{ x: number; y: number } | null>>;
  scale: number;
  form: FormInstance<UTCFormData>;
  // Added as prop in order to avoid circular dependencies
  formData: UTCFormData;
}

const UTCMenu: React.FC<UTCMenuProps> = ({
  menuPosition,
  scale,
  setMenuPosition,
  form,
  formData,
}) => {
  const config = useFetchWindowConfigQuery().data!;
  const ref = useRef<HTMLDivElement>(null!);
  const nestedMenuRef = useRef<HTMLDivElement>(null!);
  const selectedNodeType = useAppSelector(getSelectedUTCNodeType);
  const selectedNodePoints = useAppSelector(getSelectedUTCNodePoints);
  const allSelectedNodes = useAppSelector(getAllSelectedUTCNodes);
  const [validationData, setValidationData] = useState<IguValidationData>();
  const multipleFillersAreSelected = Object.keys(allSelectedNodes).length > 1;
  const IGUData = useFetchIguQuery().data!;

  useClickAway(nestedMenuRef, () => {
    setActiveNestedMenu(null);
  });

  const innerFrameData = formData.innerFrames.find((filler) => {
    return isEqual(selectedNodePoints, filler.points);
  });

  const selectedInnerFrames = useMemo(
    () =>
      formData.innerFrames.filter((filler) => {
        return Object.values(allSelectedNodes).some((selectedNode) => {
          return isEqual(selectedNode.points, filler.points);
        });
      }),
    [formData, allSelectedNodes]
  );

  const everyFillerHasSameType = selectedInnerFrames.every((filler) => {
    return filler.fillerType === selectedInnerFrames[0].fillerType;
  });

  const { collectUnitIguValidationData, validateFillerMenuOptions } =
    useUnitIGuValidation();

  const menuOptionsForSingleFiller = useMemo(() => {
    if (!innerFrameData) return null;

    return validateFillerMenuOptions(innerFrameData, formData.points);
  }, [innerFrameData, formData]);

  const multipleMenuOptions = useMemo(() => {
    const fillerValidationResults = selectedInnerFrames.map((frame) =>
      frame ? validateFillerMenuOptions(frame, formData.points) : null
    );

    return {
      fixedGlassFillerType: fillerValidationResults.every(
        (result) => result?.fixedGlassIsValid
      ),
      operableGlassFillerType: fillerValidationResults.every(
        (result) => result?.operableGlassIsValid
      ),
    };
  }, [selectedInnerFrames, formData]);

  const showFillerTypeMenuForMultipleSelectedInnerFrames = useMemo(() => {
    return (
      (multipleMenuOptions &&
        Object.values(multipleMenuOptions).some(Boolean)) ||
      !everyFillerHasSameType ||
      (everyFillerHasSameType &&
        selectedInnerFrames[0].fillerType !== FillerType.Aluminium)
    );
  }, [multipleFillersAreSelected, selectedInnerFrames, multipleMenuOptions]);

  const showFillerTypeMenuForSingleInnerFrame =
    (menuOptionsForSingleFiller &&
      Object.values(menuOptionsForSingleFiller).some(Boolean)) ||
    innerFrameData?.fillerType !== FillerType.Aluminium;

  const iguItem = useMemo(() => {
    if (
      !innerFrameData ||
      innerFrameData.fillerType === FillerType.Aluminium ||
      !IGUData
    )
      return null;

    return IGUData.find((item) => item.id === innerFrameData.iguId);
  }, [innerFrameData]);

  useEffect(() => {
    if (innerFrameData && iguItem) {
      setValidationData(
        collectUnitIguValidationData({
          fillerData: innerFrameData,
          framePoints: innerFrameData.points,
          glassType: iguItem.glassType,
        })
      );
    }
  }, [innerFrameData, iguItem]);

  const showOuterColor =
    innerFrameData?.outsideColor &&
    innerFrameData?.fillerType !== FillerType.Glass;

  const showInnerColor =
    innerFrameData?.insideColor &&
    innerFrameData?.fillerType !== FillerType.Glass;

  const showIGUMenu =
    innerFrameData?.fillerType === FillerType.Glass ||
    innerFrameData?.fillerType === FillerType.OperableWindow ||
    innerFrameData?.fillerType === FillerType.EnamelGlass;

  const innerFrameIndex = formData.innerFrames.findIndex((filler) => {
    return isEqual(selectedNodePoints, filler.points);
  });

  const handleResetMenu = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.stopPropagation();
    setActiveNestedMenu(null);
  };

  const [activeNestedMenu, setActiveNestedMenu] =
    useState<UTCNestedMenu | null>(null);

  const isFillerSelected =
    selectedNodeType === UTCElementType.Filler && !multipleFillersAreSelected;

  const handleKeyup = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();
      setMenuPosition(null);
      publish(SET_WINDOW_CONTEXT_MENU_POSITION, null);
    }
  };

  useEffect(() => {
    document.addEventListener('keyup', handleKeyup);
    return () => {
      document.removeEventListener('keyup', handleKeyup);
    };
  }, [menuPosition]);

  const getFillerTypeBlock = (fillerType: FillerType) => {
    return (
      <>
        {getMaterialImage(fillerType)}
        <div className="flex justify-center flex-col flex-1">
          <div className="font-normal leading-5 text-xs">
            {getMaterialDescription(fillerType, config.fillerTypes)}
          </div>
          <div className="font-light leading-5 text-xs">
            {getMaterialAdditionalInfo(fillerType, config.fillerTypes)}
          </div>
        </div>
      </>
    );
  };

  const handleFillerTypeChange = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    fillerType: FillerType
  ) => {
    handleChangeFillerType({
      fillerPoints: innerFrameData?.points ?? [],
      type: fillerType,
      form,
      config: config.baseUnit,
      defaultIGU: config.baseUnit.iguId,
    });
    handleResetMenu(event);
  };

  const handleFillerTypeChangeForMultipleFillers = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    fillerType: FillerType
  ) => {
    const selectedInnerFramesPoints = Object.values(allSelectedNodes).map(
      (selectedNode) => selectedNode.points
    );
    handleChangeFillerTypeForMultipleInnerFrames({
      fillerPoints: selectedInnerFramesPoints,
      type: fillerType,
      form,
      config: config.baseUnit,
      defaultIGU: config.baseUnit.iguId,
    });
    handleResetMenu(event);
  };

  const handleChangeOperationType = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    type: OperationType
  ) => {
    handleChangeWindowType(innerFrameData?.points ?? [], type, form);
    handleResetMenu(event);
  };

  const handleChangeColor = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    view: View,
    color: RALColors
  ) => {
    const colorSide = view === View.Outside ? 'outsideColor' : 'insideColor';
    form.setFieldValue(['innerFrames', innerFrameIndex, colorSide], color);
    handleResetMenu(event);
  };

  const handleIGUSelect = (id: number) => {
    handleChangeWindowIGU(innerFrameData?.points ?? [], id, form);
  };

  const operationTypeMenu = () => (
    <div className="py-1">
      <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
        Operation type
      </div>
      <div
        className={`flex cursor-pointer hover:bg-light-gray-10 relative ${activeNestedMenu === UTCNestedMenu.OperationType ? 'bg-light-gray-10' : 'bg-white'} mb-1`}
        onClick={() => setActiveNestedMenu(UTCNestedMenu.OperationType)}
        id="operation-type-menu-outer"
      >
        <div className="px-2 py-1 flex gap-2 min-w-[202px] box-border">
          <div className="flex items-center gap-2 text-xs font-normal leading-5">
            {getWindowTypeBlock(
              config.operationTypes.find(
                (operationType) =>
                  operationType.name === innerFrameData?.operableWindowType
              )!
            )}
          </div>
        </div>
        <div className="flex items-center">
          <IntusIconButton
            simplified
            size="small"
            transparent
            icon={<ArrowLeftMenuIcon />}
            id="operation-type-menu-arrow-left"
          />
        </div>

        {activeNestedMenu === UTCNestedMenu.OperationType && (
          <Form.Item
            name={['innerFrames', innerFrameIndex, 'operableWindowType']}
          >
            <div
              ref={nestedMenuRef}
              className="absolute top-0 pt-1 ml-2 w-[220px] left-full bg-white context-menu__wrapper font-normal box-border"
            >
              {config.operableWindowTypes.map((operationType) => {
                return (
                  <div
                    className={`h-12 px-2 py-1 flex gap-2 mb-1 box-border hover:bg-light-gray-10`}
                    key={operationType.name}
                    onClick={(e) =>
                      handleChangeOperationType(e, operationType.name)
                    }
                  >
                    {getWindowTypeBlock(operationType)}
                  </div>
                );
              })}
            </div>
          </Form.Item>
        )}
      </div>
    </div>
  );

  const getColorsNestedMenu = (view: View) => {
    const colorSide = view === View.Outside ? 'outsideColor' : 'insideColor';
    return (
      <div
        ref={nestedMenuRef}
        className="absolute top-0 pt-1 ml-2 w-[220px] left-full bg-white context-menu__wrapper font-normal box-border"
      >
        {config.colors
          .filter(
            (color) =>
              form.getFieldValue([
                'innerFrames',
                innerFrameIndex,
                colorSide,
              ]) !== color.name
          )
          .map((color) => {
            return (
              <div
                className={`h-8 px-2 py-1 flex gap-2 mb-1 box-border hover:bg-light-gray-10`}
                key={color.name}
                onClick={(e) => {
                  handleChangeColor(e, view, color.name);
                }}
              >
                <div
                  className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                  style={{
                    backgroundColor: getHexByName(config.colors, color.name),
                  }}
                />
                <div className="flex items-center leading-5 font-normal text-xs">
                  {getColorDescriptionByName(config.colors, color.name)}
                </div>
              </div>
            );
          })}
      </div>
    );
  };

  const iguMenu = (
    <>
      <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
        IGU configuration
      </div>
      <Form.Item
        className="mb-1"
        name={['innerFrames', innerFrameIndex, 'iguId']}
      >
        <IguSelect
          value={innerFrameData?.iguId}
          onSelect={handleIGUSelect}
          isIguForPanel
          materialIsGlass={innerFrameData?.fillerType === FillerType.Glass}
          validationData={validationData}
        />
      </Form.Item>
    </>
  );

  const fillerMenu = (
    <div className="py-1">
      {innerFrameData?.fillerType && (
        <>
          <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
            Filler type
          </div>
          <div
            className={`flex relative ${activeNestedMenu === UTCNestedMenu.FillerType ? 'bg-light-gray-10' : 'bg-white'} ${showFillerTypeMenuForSingleInnerFrame ? 'opacity-100 hover:bg-light-gray-10 cursor-pointer' : 'opacity-50 cursor-not-allowed'} mb-1`}
            onClick={() =>
              showFillerTypeMenuForSingleInnerFrame &&
              setActiveNestedMenu(UTCNestedMenu.FillerType)
            }
            id="operation-type-menu"
          >
            <div className="px-2 py-1 flex gap-2 min-w-[202px] box-border">
              <div className="flex items-center gap-2 text-xs font-normal leading-5">
                {getFillerTypeBlock(innerFrameData?.fillerType)}
              </div>
            </div>
            {showFillerTypeMenuForSingleInnerFrame && (
              <div className="flex items-center">
                <IntusIconButton
                  simplified
                  size="small"
                  transparent
                  icon={<ArrowLeftMenuIcon />}
                  id="operation-type-menu"
                />
              </div>
            )}
            {activeNestedMenu === UTCNestedMenu.FillerType &&
              showFillerTypeMenuForSingleInnerFrame && (
                <Form.Item name={['innerFrames', innerFrameIndex, 'iguId']}>
                  <div
                    ref={nestedMenuRef}
                    className="absolute top-0 pt-1 ml-2 w-[220px] left-full bg-white context-menu__wrapper font-normal box-border"
                  >
                    {menuOptionsForSingleFiller &&
                      config.fillerTypes
                        .filter((fillerType) => {
                          if (
                            fillerType.name === FillerType.Glass ||
                            fillerType.name === FillerType.EnamelGlass
                          ) {
                            return (
                              fillerType.name !== innerFrameData?.fillerType &&
                              menuOptionsForSingleFiller.fixedGlassIsValid
                            );
                          } else if (
                            fillerType.name === FillerType.OperableWindow
                          ) {
                            return (
                              fillerType.name !== innerFrameData?.fillerType &&
                              menuOptionsForSingleFiller.operableGlassIsValid
                            );
                          } else {
                            return (
                              fillerType.name !== innerFrameData?.fillerType
                            );
                          }
                        })
                        .map((fillerType) => {
                          return (
                            <div
                              className={`h-12 px-2 py-1 flex gap-2 mb-1 box-border hover:bg-light-gray-10`}
                              key={fillerType.name}
                              onClick={(e) =>
                                handleFillerTypeChange(e, fillerType.name)
                              }
                            >
                              {getFillerTypeBlock(fillerType.name)}
                            </div>
                          );
                        })}
                  </div>
                </Form.Item>
              )}
          </div>
        </>
      )}
      {showOuterColor && (
        <>
          <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
            {innerFrameData?.fillerType === FillerType.OperableWindow
              ? 'Outside window frame color'
              : 'Outside filler color'}
          </div>
          <div
            onClick={() => setActiveNestedMenu(UTCNestedMenu.OuterColor)}
            id="outer-color-menu"
            className={`flex cursor-pointer hover:bg-light-gray-10 relative ${activeNestedMenu === UTCNestedMenu.OuterColor ? 'bg-light-gray-10' : 'bg-white'} mb-1`}
          >
            <div className="h-8 box-border py-1 px-2 flex gap-2 min-w-[202px]">
              <div
                className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                style={{
                  backgroundColor: getHexByName(
                    config.colors,
                    innerFrameData.outsideColor
                  ),
                }}
              />
              <div className="flex items-center text-xs font-normal leading-5">
                {getColorDescriptionByName(
                  config.colors,
                  innerFrameData.outsideColor
                )}
              </div>
            </div>
            <div className="flex items-center">
              <IntusIconButton
                simplified
                size="small"
                transparent
                icon={<ArrowLeftMenuIcon />}
                id="outer-color-menu-arrow-left"
              />
            </div>
            {activeNestedMenu === UTCNestedMenu.OuterColor && (
              <Form.Item
                name={['innerFrames', innerFrameIndex, 'outsideColor']}
              >
                {getColorsNestedMenu(View.Outside)}
              </Form.Item>
            )}
          </div>
        </>
      )}
      {showInnerColor && (
        <>
          <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
            {innerFrameData?.fillerType === FillerType.OperableWindow
              ? 'Inside window frame color'
              : 'Inside filler color'}
          </div>
          <div
            onClick={() => setActiveNestedMenu(UTCNestedMenu.InnerColor)}
            id="outer-color-menu"
            className={`flex cursor-pointer hover:bg-light-gray-10 relative ${activeNestedMenu === UTCNestedMenu.InnerColor ? 'bg-light-gray-10' : 'bg-white'} mb-1`}
          >
            <div className="h-8 box-border py-1 px-2 flex gap-2 min-w-[202px]">
              <div
                className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                style={{
                  backgroundColor: getHexByName(
                    config.colors,
                    innerFrameData.insideColor
                  ),
                }}
              />
              <div className="flex items-center text-xs font-normal leading-5">
                {getColorDescriptionByName(
                  config.colors,
                  innerFrameData?.insideColor
                )}
              </div>
            </div>
            <div className="flex items-center">
              <IntusIconButton
                simplified
                size="small"
                transparent
                icon={<ArrowLeftMenuIcon />}
                id="inner-color-menu-arrow-left"
              />
            </div>
            {activeNestedMenu === UTCNestedMenu.InnerColor && (
              <Form.Item name={['innerFrames', innerFrameIndex, 'insideColor']}>
                {getColorsNestedMenu(View.Inside)}
              </Form.Item>
            )}
          </div>
        </>
      )}
      {innerFrameData?.fillerType === FillerType.OperableWindow &&
        operationTypeMenu()}
      {showIGUMenu && iguMenu}
    </div>
  );

  const getMultipleFillersMenu = () => {
    return (
      <>
        <div className="font-light text-xs leading-5 px-2 py-[2px] text-dark-gray-100 mb-1">
          Filler type
        </div>
        <div
          className={`flex ${showFillerTypeMenuForMultipleSelectedInnerFrames ? 'hover:bg-light-gray-10 cursor-pointer' : 'opacity-50 cursor-not-allowed'}  relative ${activeNestedMenu === UTCNestedMenu.FillerType ? 'bg-light-gray-10' : 'bg-white'} mb-1`}
          onClick={() =>
            showFillerTypeMenuForMultipleSelectedInnerFrames &&
            setActiveNestedMenu(UTCNestedMenu.FillerType)
          }
          id="operation-type-menu"
        >
          <div className="px-2 py-1 flex gap-2 min-w-[202px] box-border">
            <div className="flex items-center gap-2 text-xs font-normal leading-5">
              {getFillerTypeBlock(
                everyFillerHasSameType
                  ? selectedInnerFrames[0].fillerType
                  : FillerType.Multiple
              )}
            </div>
          </div>
          <div className="flex items-center">
            {showFillerTypeMenuForMultipleSelectedInnerFrames && (
              <IntusIconButton
                simplified
                size="small"
                transparent
                icon={<ArrowLeftMenuIcon />}
                id="operation-type-menu-arrow-left"
              />
            )}
          </div>
          {activeNestedMenu === UTCNestedMenu.FillerType &&
            showFillerTypeMenuForMultipleSelectedInnerFrames && (
              <Form.Item
                name={['innerFrames', innerFrameIndex, 'operationType']}
              >
                <div
                  ref={nestedMenuRef}
                  className="absolute top-0 pt-1 ml-2 w-[220px] left-full bg-white context-menu__wrapper font-normal box-border"
                >
                  {multipleMenuOptions &&
                    config.fillerTypes
                      .filter((fillerType) => {
                        if (fillerType.name === FillerType.Glass) {
                          return everyFillerHasSameType &&
                            selectedInnerFrames[0].fillerType ===
                              FillerType.Glass
                            ? false
                            : multipleMenuOptions.fixedGlassFillerType;
                        } else if (fillerType.name === FillerType.EnamelGlass) {
                          return everyFillerHasSameType &&
                            selectedInnerFrames[0].fillerType ===
                              FillerType.EnamelGlass
                            ? false
                            : multipleMenuOptions.fixedGlassFillerType;
                        } else if (
                          fillerType.name === FillerType.OperableWindow
                        ) {
                          return everyFillerHasSameType &&
                            selectedInnerFrames[0].fillerType ===
                              FillerType.OperableWindow
                            ? false
                            : multipleMenuOptions.operableGlassFillerType;
                        } else if (fillerType.name === FillerType.Aluminium) {
                          return !(
                            everyFillerHasSameType &&
                            fillerType.name ===
                              selectedInnerFrames[0].fillerType
                          );
                        }
                      })
                      .map((fillerType) => {
                        return (
                          <div
                            className={`h-12 px-2 py-1 flex gap-2 mb-1 box-border hover:bg-light-gray-10`}
                            key={fillerType.name}
                            onClick={(e) =>
                              handleFillerTypeChangeForMultipleFillers(
                                e,
                                fillerType.name
                              )
                            }
                          >
                            {getFillerTypeBlock(fillerType.name)}
                          </div>
                        );
                      })}
                </div>
              </Form.Item>
            )}
        </div>
      </>
    );
  };

  const adjustMenuPosition = () => {
    const modalSideMargin = 20;
    const menuWidth = 220;
    const maxMenuHeight = 300;
    const maxNestedMenuHeight = 264;
    const totalMenuWidth = menuWidth * 2 + modalSideMargin * 2;
    const totalMenuHeight = maxMenuHeight + maxNestedMenuHeight;

    let newXPosition = menuPosition.x;
    let newYPosition = menuPosition.y;

    if (newXPosition + totalMenuWidth > STAGE_WIDTH) {
      newXPosition = STAGE_WIDTH - totalMenuWidth;
    }
    if (newYPosition + totalMenuHeight > STAGE_HEIGHT) {
      newYPosition = STAGE_HEIGHT - totalMenuHeight;
    }

    if (newXPosition !== menuPosition.x || newYPosition !== menuPosition.y) {
      setMenuPosition({ x: newXPosition, y: newYPosition });
    }
    setPositionIsAdjusted(true);
  };

  const [positionIsAdjusted, setPositionIsAdjusted] = useState(false);

  useEffect(() => {
    adjustMenuPosition();
  }, [ref.current, menuPosition]);

  return positionIsAdjusted ? (
    <Html
      divProps={{
        style: {
          position: 'absolute',
          left: `${menuPosition.x + 5}px`,
          top: `${menuPosition.y + 5}px`,
          scale: 1 / scale,
          zIndex: 30,
          transform: `translate(0px, 0px) rotate(0deg) scaleX(${scale}) scaleY(${scale})`,
        },
      }}
    >
      <div
        ref={ref}
        className={`w-[230px] bg-white context-menu__wrapper font-normal text-xs`}
      >
        {isFillerSelected && fillerMenu}
        {multipleFillersAreSelected && getMultipleFillersMenu()}
      </div>
    </Html>
  ) : null;
};

export default UTCMenu;
