import React from 'react';
import { FlatVector2 } from '@/models';
import { COMMON_LINE_STROKE_WIDTH } from '@/shared/constants';
import { Line } from 'react-konva';

const KonvaDashedLine = ({ points }: { points: FlatVector2[] }) => {
  return (
    <Line
      points={points.flat()}
      stroke="#B3B2B4"
      strokeWidth={COMMON_LINE_STROKE_WIDTH}
      strokeScaleEnabled={false}
      dash={[12, 3, 4, 3]}
    />
  );
};

export default KonvaDashedLine;
