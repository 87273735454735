import React from 'react';
import FacadeDesignerWallPanelsView from '@/components/FacadeDesigner/elements/FacadeDesignerWallPanelsView';
import FacadeDesignerGridLines from '@/components/FacadeDesigner/elements/FacadeDesignerGridLines';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import FacadeDesignerWindows from './FacadeDesignerWindows';
import { useParams } from 'react-router';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { FacadeDesignerModes } from '@/models/shared.model';
import { WindowPlacementDataForFD } from '@/components/FacadeDesigner/models';
import { getSelectedWindowFromLibrary } from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { PanelConfig } from '@/models/window-configurator.model';

interface FacadeDesignerConstructionElementsProps {
  scale: number;
  wallData: WallSearchResults;
  wallHeight: number;
  wallWidth: number;
  facadeDesignerMode: FacadeDesignerModes;
  placedWindows: WindowPlacementDataForFD[];
  wallOffset: { x: number; y: number };
  config: PanelConfig;
  isProjectLocked: boolean;
}

const FacadeDesignerConstructionElements = ({
  scale,
  wallData,
  wallHeight,
  wallWidth,
  facadeDesignerMode,
  placedWindows,
  wallOffset,
  config,
  isProjectLocked,
}: FacadeDesignerConstructionElementsProps) => {
  const { id } = useParams();
  const windowsData = useFetchWindowsQuery(id!).data!;
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );

  const isWindowPlacementProcessing = !!selectedWindowFromLibrary;
  const hasPanels = wallData.wallPanels.length > 0;
  return (
    <>
      <FacadeDesignerWallPanelsView
        wallData={wallData}
        scale={scale}
        wallHeight={wallHeight}
        unitSystem={unitSystem}
        wallOffset={wallOffset.x}
        config={config}
        isProjectLocked={isProjectLocked}
      />
      {!hasPanels && (
        <FacadeDesignerWindows
          placedWindows={placedWindows}
          scale={scale}
          windowsData={windowsData}
          unitSystem={unitSystem}
          wallData={wallData}
          isWindowPlacementProcessing={isWindowPlacementProcessing}
          wallHeight={wallHeight}
          wallWidth={wallWidth}
          facadeDesignerMode={facadeDesignerMode}
        />
      )}
      <FacadeDesignerGridLines
        scale={scale}
        unitSystem={unitSystem}
        wallHeight={wallHeight}
        wallWidth={wallWidth}
        wallData={wallData}
        xWallOffset={wallOffset.x}
      />
    </>
  );
};

export default FacadeDesignerConstructionElements;
