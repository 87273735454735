import React, { createContext, useContext, useMemo } from 'react';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { UserBuildingPanel } from '@/models';

const PanelsSubscriptionContext = createContext<{
  data: UserBuildingPanel[] | undefined;
}>({
  data: [],
});

export const PanelsSubscriptionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { id } = useParams();
  const { data, error } = useGetAllPanelsQuery(id!, { skip: !id });

  const value = useMemo(() => ({ data, error }), [data, error]);

  return (
    <PanelsSubscriptionContext.Provider value={value}>
      {children}
    </PanelsSubscriptionContext.Provider>
  );
};

export const usePanelsSubscription = () =>
  useContext(PanelsSubscriptionContext);
