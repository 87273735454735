import Konva from 'konva';
import { FlatVector2 } from '@/models';
import { MathUtils } from 'three';
import { round } from 'mathjs';
import { EDGE_POSITION } from '@/components/WindowCreator/elements/creator-windows.helpers';
import { FlatVector2Axis } from '@/components/WindowCreator/models';

export const rotateAroundPoint = (
  shape: Konva.Shape,
  angleDegrees: number,
  point: FlatVector2,
  x?: number,
  y?: number
) => {
  if (!shape) return;

  const angleRadians = (angleDegrees * Math.PI) / 180;

  const _x = x || shape.x();
  const _y = y ?? shape.y();

  const xPosition =
    point[0] +
    (_x - point[0]) * Math.cos(angleRadians) -
    (_y - point[1]) * Math.sin(angleRadians);
  const yPosition =
    point[1] +
    (_x - point[0]) * Math.sin(angleRadians) +
    (_y - point[1]) * Math.cos(angleRadians);

  // move the rotated shape in relation to the rotation point.
  shape.position({ x: xPosition, y: yPosition });

  // rotate the shape in place around its natural rotation point
  if (shape.rotation() !== angleDegrees) {
    shape.rotation(angleDegrees);
  }
};

export const get2DAngle = (
  p0: FlatVector2,
  p1: FlatVector2,
  p2: FlatVector2
) => {
  const a = Math.pow(p1[0] - p0[0], 2) + Math.pow(p1[1] - p0[1], 2),
    b = Math.pow(p1[0] - p2[0], 2) + Math.pow(p1[1] - p2[1], 2),
    c = Math.pow(p2[0] - p0[0], 2) + Math.pow(p2[1] - p0[1], 2);

  if (!a || !b) return 0;
  return MathUtils.radToDeg(
    Math.acos(round((a + b - c) / Math.sqrt(4 * a * b), 3))
  );
};

export const get2DCenter = (p1: FlatVector2, p2: FlatVector2): FlatVector2 => {
  return [round((p1[0] + p2[0]) / 2, 2), round((p1[1] + p2[1]) / 2, 2)];
};

export const get2DDistance = (p1: FlatVector2, p2: FlatVector2): number => {
  const a = p1[0] - p2[0];
  const b = p1[1] - p2[1];
  return round(Math.sqrt(Math.pow(a, 2) + Math.pow(b, 2)), 2);
};

export const roundKonvaValue = (value: number) => round(value, 2);

export const calculateAxis = (
  points: FlatVector2Axis,
  index: number,
  offset: number = 0
): number[] => {
  let edgePoints: number[] = [];

  switch (index) {
    case EDGE_POSITION.TOP: {
      edgePoints = [
        points[0][0] - offset,
        points[0][1] - offset,
        points[1][0] + offset,
        points[1][1] - offset,
      ];
      break;
    }

    case EDGE_POSITION.RIGHT: {
      edgePoints = [
        points[0][0] + offset,
        points[0][1] - offset,
        points[1][0] + offset,
        points[1][1] + offset,
      ];
      break;
    }

    case EDGE_POSITION.BOTTOM: {
      edgePoints = [
        points[0][0] + offset,
        points[0][1] + offset,
        points[1][0] - offset,
        points[1][1] + offset,
      ];
      break;
    }

    case EDGE_POSITION.LEFT: {
      edgePoints = [
        points[0][0] - offset,
        points[0][1] + offset,
        points[1][0] - offset,
        points[1][1] - offset,
      ];
      break;
    }
  }
  return edgePoints;
};
