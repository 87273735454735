import React from 'react';
import { Line } from 'react-konva';
import { GridLineConfig } from '../models';

interface WallViewProps {
  wallWidth: number;
  wallHeight: number;
  isContour?: boolean;
}

const WallView: React.FC<WallViewProps> = ({
  wallWidth,
  wallHeight,
  isContour = false,
}) => {
  const wallPoints = [
    [0, 0],
    [wallWidth, 0],
    [wallWidth, wallHeight],
    [0, wallHeight],
  ].flat();

  return (
    <>
      <Line
        listening
        points={wallPoints}
        fill={isContour ? undefined : '#fff'}
        closed
        stroke={GridLineConfig.placed.stroke}
        strokeWidth={1.3}
        shadowForStrokeEnabled={false}
        strokeScaleEnabled={false}
      />
    </>
  );
};

export default WallView;
