import React from 'react';

import { SvgGlazingBeads, SvgUnitFrame, SvgWindowFrame } from '../';

import { Background } from '../../models';
import { FlatVector2, TextColor, UserBuildingPanel } from '@/models';
import { WindowConfigurator } from '@/models/window-configurator.model';
import { FillerType, UnitInnerFrame } from '@/components/UTC/models';
import {
  getAdjustedScale,
  getMaterialColorByCriteria,
  normalizeSvgPoints,
} from '../../helpers';
import { extractPointsFromClosedContour } from '@/components/WindowCreator/elements/creator-windows.helpers';
import { calculateActualFillerZone } from '@/components/UTC/helpers/area-calculation';
import {
  getInnerFrameHeight,
  getInnerFrameWidth,
  getPanelHeight,
  getPanelWidth,
  getTextColorByCriteria,
} from '@/shared/helpers';
import { getHexByName } from '@/components/WindowCreator/helpers/config';

interface SvgFramesProps {
  config: WindowConfigurator;
  frames: UnitInnerFrame[];
  unit: UserBuildingPanel;
  isSideCorner: boolean;
  isCorner: boolean;
  offset: number;
  isNumbering: boolean;
}

const SvgFrames: React.FC<SvgFramesProps> = ({
  config,
  frames,
  unit,
  isSideCorner,
  isCorner,
  isNumbering,
  offset,
}) => {
  const glazingBeadsColor = {
    hex: getHexByName(config.colors, unit.outsideColor) ?? Background.DEFAULT,
    hasGlare: false,
  };

  const unitHeight = unit.points ? getPanelHeight(unit) : 0;
  const unitWidth = unit.points ? getPanelWidth(unit) : 0;

  return (
    <>
      {frames.map((frame, index) => {
        const width = getInnerFrameWidth(frame, unit, config.panel);
        const height = getInnerFrameHeight(frame, unit, config.panel);
        const handleScale = getAdjustedScale(height / width);

        const operableConfig = {
          outerWidth: config.panel.operableFrame.windowFrameWidth,
          sashWidth: config.panel.operableFrame.sashWidth,
        };
        const operableBorderColor = getMaterialColorByCriteria(
          FillerType.Aluminium,
          frame.outsideColor,
          config.colors
        );
        const fillerColor = getMaterialColorByCriteria(
          frame.fillerType,
          frame.outsideColor,
          config.colors
        );
        const textColor = frame.operableWindowType
          ? TextColor.DARK
          : getTextColorByCriteria(frame.fillerType, frame.outsideColor);

        const points = normalizeSvgPoints(
          extractPointsFromClosedContour(
            calculateActualFillerZone(frame.points, unit.points, config.panel)
          ),
          unit.points[0]
        );

        const shiftPoints = isSideCorner
          ? (points.map(([x, y]) => [x + offset, y]) as FlatVector2[])
          : points;

        return (
          <React.Fragment key={index}>
            {frame.operableWindowType ? (
              <SvgWindowFrame
                config={operableConfig}
                handleScale={handleScale}
                color={operableBorderColor}
                points={shiftPoints}
                operationType={frame.operableWindowType}
                frameNumber={frame.number}
                textColor={textColor}
                isNumbering={isNumbering}
              />
            ) : (
              <SvgUnitFrame
                points={shiftPoints}
                color={fillerColor}
                textColor={textColor}
                frameNumber={frame.number}
                isNumbering={isNumbering}
              />
            )}

            <SvgGlazingBeads
              points={shiftPoints}
              color={glazingBeadsColor}
              unitWidth={unitWidth}
              unitHeight={unitHeight}
              offset={offset}
              config={config.panel}
              isCorner={isCorner}
              isSideCorner={isSideCorner}
            />
          </React.Fragment>
        );
      })}
    </>
  );
};

export default SvgFrames;
