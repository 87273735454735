import { FlatVector3, PanelPlacementData } from '@/models';
import React, { useMemo, useState } from 'react';
import { convertFlatVector3ToVector } from '../../user-building.helpers';
import {
  getExtendedVector,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
} from '../../../project-canvas.helpers';
import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { getWallHeight } from '@/shared/helpers/metrics';
import { useGLTF } from '@react-three/drei';
import { scaleMesh } from '@/shared/helpers/canvas';
import * as THREE from 'three';
import { WallUserData } from '../Wall';
import { usePanelsSubscription } from '../../providers/PanelsSubscriptionProvider';
import { getPanelWidth } from '@/shared/helpers';

interface ViewerPanelProps {
  scale: number;
  placementData: PanelPlacementData;
  wallUserData: WallUserData;
  wallPoints: FlatVector3[];
}

const ViewerPanel: React.FC<ViewerPanelProps> = ({
  scale,
  placementData,
  wallUserData,
  wallPoints,
}) => {
  const { data: fetchedPanels } = usePanelsSubscription();
  if (!fetchedPanels) return null;

  const data = useMemo(
    () => fetchedPanels.find((panel) => panel.id === placementData.panelId),
    [fetchedPanels, placementData.panelId]
  );

  const panelHeight = useMemo(
    () => Number(getWallHeight(wallPoints, scale)),
    [wallPoints, scale]
  );

  const leftSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallPoints[1]),
    [wallPoints]
  );
  const rightSideBottom = useMemo(
    () => convertFlatVector3ToVector(wallPoints[0]),
    [wallPoints]
  );
  const perpendicularForWall = useMemo(
    () =>
      getPerpendicularVectorToVectors([leftSideBottom, rightSideBottom], true),
    [leftSideBottom, rightSideBottom]
  );

  const getPanelPosition = () => {
    const position = getExtendedVector(
      leftSideBottom,
      rightSideBottom,
      convertMillimetersToMeters(
        placementData.offsetFromLeftEdge + getPanelWidth(data!) / 2
      ) * scale
    );
    position.setY(position.y + (panelHeight / 2) * scale);
    //TODO: Temporary constant, should read from BE
    return getTranslatedVector(
      position,
      (-0.1778 * scale) / 2,
      perpendicularForWall
    );
  };

  const getLookAtPosition = () => {
    return getTranslatedVector(getPanelPosition(), 90, perpendicularForWall);
  };

  let panelModel: THREE.Group;

  const [loadAttempts, setLoadAttempts] = useState(0);

  try {
    const gltf = useGLTF(data!.model);
    panelModel = gltf.scene.clone();
    panelModel.castShadow = true;
    panelModel.receiveShadow = true;
  } catch {
    if (loadAttempts <= 20) {
      setTimeout(() => setLoadAttempts(loadAttempts + 1), 250);
      return <></>;
    } else {
      return null;
    }
  }

  scaleMesh(panelModel, convertMillimetersToMeters(scale));

  panelModel.position.copy(getPanelPosition());
  panelModel.lookAt(getLookAtPosition());
  panelModel.traverse((child) => (child.userData = wallUserData));

  return <primitive object={panelModel} />;
};

export default ViewerPanel;
