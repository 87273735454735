import React from 'react';
import ReportWrapper from '@/components/Report/Pages/ReportWrapper';
import ReportPanelMetrics from '@/components/Report/Pages/PanelMetrics/ReportPanelMetrics';
import { CornerGridAlign, UserBuildingPanel } from '@/models';
import PanelMetricsTableContinue from '@/components/Report/Pages/PanelMetrics/PanelMetricsTableContinue';
import { getGroupedInnerFrameData } from '@/components/Report/helpers/report-panels-helper';
import { PanelConfig } from '@/models/window-configurator.model';
import { chunk } from 'lodash';
import IguConfigurationPage from '@/components/Report/Pages/PanelMetrics/IguConfigurationPage';
import { PageNames } from '../../models/report';
import { ReportPageAmountByPanelId } from '@/components/Report/Report';

interface PanelPageProps {
  fetchedPanels: UserBuildingPanel[];
  totalPages: number;
  creationDate: string;
  panelCount: {
    [key: number]: number;
  };
  panelConfig: PanelConfig;
  panelPagesAmount: ReportPageAmountByPanelId;
}

export const ROWS_AMOUNT_WITHOUT_IGU = 5;
export const ROWS_AMOUNT_AT_ONE_PAGE = 11;

const ReportPanelPage: React.FC<PanelPageProps> = ({
  fetchedPanels,
  panelCount,
  totalPages,
  creationDate,
  panelConfig,
  panelPagesAmount,
}) => {
  const getStartingPageNumber = (panelId: number) => {
    return panelPagesAmount[panelId]?.start ?? 0;
  };
  return (
    <>
      {fetchedPanels
        .filter((panel) =>
          panel.cornerSide ? panel.cornerSide === CornerGridAlign.Left : true
        )
        .map((panel, index) => {
          const groupedInnerFrameData = getGroupedInnerFrameData(
            panel,
            panelConfig,
            fetchedPanels
          );
          const isNeedToSplitTable =
            groupedInnerFrameData.length > ROWS_AMOUNT_WITHOUT_IGU;

          const groupedInnerFrameDataChunks = chunk(
            groupedInnerFrameData.slice(ROWS_AMOUNT_WITHOUT_IGU),
            ROWS_AMOUNT_AT_ONE_PAGE
          );

          return (
            <React.Fragment key={`panel-${index}`}>
              <ReportWrapper
                id={`${PageNames.UCW_UNIT_LIST_MAIN} ${panel.id}`}
                key={panel.id}
                pageIndex={getStartingPageNumber(panel.id)}
                countOfPages={totalPages}
                reportCreationDate={creationDate}
                pageName={PageNames.UCW_UNIT_LIST}
              >
                <ReportPanelMetrics
                  panelIndex={index}
                  panelData={panel}
                  panelCount={panelCount}
                  innerFramesData={
                    isNeedToSplitTable
                      ? groupedInnerFrameData.slice(0, ROWS_AMOUNT_WITHOUT_IGU)
                      : groupedInnerFrameData
                  }
                  isSplittedTable={isNeedToSplitTable}
                />
              </ReportWrapper>
              {isNeedToSplitTable &&
                groupedInnerFrameDataChunks.map(
                  (groupedInnerFrameDataChunk, chunkIndex) => (
                    <ReportWrapper
                      key={chunkIndex}
                      pageIndex={
                        getStartingPageNumber(panel.id) + chunkIndex + 1
                      }
                      countOfPages={totalPages}
                      reportCreationDate={creationDate}
                      pageName={PageNames.UCW_UNIT_LIST}
                    >
                      <PanelMetricsTableContinue
                        panelData={panel}
                        panelIndex={index + 0.1}
                        innerFramesData={groupedInnerFrameDataChunk}
                        isSplittedTable={isNeedToSplitTable}
                      />
                    </ReportWrapper>
                  )
                )}
              <IguConfigurationPage
                panelData={panel}
                panelIndex={index + (isNeedToSplitTable ? 0.2 : 0.1)}
                totalPages={totalPages}
                currentPageIndex={
                  getStartingPageNumber(panel.id) +
                  (panelPagesAmount[panel.id]?.amountOfInnerFramePages ?? 0)
                }
                creationDate={creationDate}
              />
            </React.Fragment>
          );
        })}
    </>
  );
};

export default ReportPanelPage;
