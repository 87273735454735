import React from 'react';

import { LineColor } from '../../models';
import { LINE_WIDTH } from '../../constants';

interface PathProps {
  points: { x: number; y: number }[];
  stroke?: string;
  strokeWidth?: number;
  strokeDasharray?: string;
}

const Path: React.FC<PathProps> = ({
  points,
  stroke = LineColor.DEFAULT,
  strokeWidth = LINE_WIDTH,
  strokeDasharray,
}) => {
  if (points.length < 2) return null;

  const pathData = points
    .map(({ x, y }, index) => (index === 0 ? `M${x},${y}` : `L${x},${y}`))
    .join(' ');

  return (
    <path
      d={pathData}
      stroke={stroke}
      strokeWidth={strokeWidth}
      strokeDasharray={strokeDasharray}
      fill="none"
    />
  );
};

export default Path;
