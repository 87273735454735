import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '@/middlewares/http-interceptors';
import { UTCData } from '@/components/UTC/models';
import { projectsApi } from '@/store/apis/projectsApi';

const unitApi = createApi({
  reducerPath: 'unit',
  tagTypes: ['Unit'],
  baseQuery,
  endpoints: (build) => ({
    createUnit: build.mutation<null, { projectId: string; data: UTCData }>({
      query: ({ projectId, data }) => ({
        url: `/projects/${projectId}/units`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted(arg, api): Promise<void> | void {
        api.queryFulfilled.then(() =>
          api.dispatch(projectsApi.util?.invalidateTags(['Panels']))
        );
      },
    }),
    updateUnit: build.mutation<null, { data: UTCData }>({
      query: ({ data }) => ({
        url: `/projects/units`,
        method: 'PUT',
        body: data,
      }),
      onQueryStarted(arg, api): Promise<void> | void {
        api.queryFulfilled.then(() =>
          api.dispatch(projectsApi.util?.invalidateTags(['Panels']))
        );
      },
    }),
    deleteUnit: build.mutation<void, { unitId: number }>({
      query: ({ unitId }) => ({
        url: `/projects/units/${unitId}`,
        method: 'DELETE',
      }),
    }),
    duplicateUnit: build.mutation<null, { unitId: number }>({
      query: ({ unitId }) => ({
        url: `/projects/units/duplicate/${unitId}`,
        method: 'POST',
      }),
      onQueryStarted(arg, api): Promise<void> | void {
        api.queryFulfilled.then(() =>
          api.dispatch(projectsApi.util?.invalidateTags(['Panels']))
        );
      },
    }),
  }),
});

export const {
  useUpdateUnitMutation,
  useCreateUnitMutation,
  useDuplicateUnitMutation,
  useDeleteUnitMutation,
} = unitApi;

export { unitApi };
