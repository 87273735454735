import {
  CanvasActionsModes,
  EditModes,
  NodeType,
  SelectedNodeSource,
} from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getExtrudeData,
  resetHoveredNode as resetHoveredNodesReducer,
  setHoveredNode as setHoveredNodeReducer,
} from '@/store/slices/canvasBuildingSlice';
import { ThreeEvent } from '@react-three/fiber';
import { getCanvasMode, getEditMode } from '@/store/slices/canvasModesSlice';
import { getDraggedWindowFromLibrary } from '@/store/slices/windowsReducer/facadeDesignerSlice';

interface SetHoveredNodeProps {
  nodeGUID: string;
  isLocked?: boolean;
  source?: SelectedNodeSource;
  nodeType?: NodeType;
  event?: ThreeEvent<PointerEvent>;
}

export const useHoveredNode = () => {
  const dispatch = useAppDispatch();
  const canvasMode = useAppSelector(getCanvasMode);
  const isSelectionMode =
    canvasMode === CanvasActionsModes.selection ||
    canvasMode === CanvasActionsModes.facadeDesigner;
  const draggedWindowFromLibrary = useAppSelector(getDraggedWindowFromLibrary);
  const isWindowCardDragging = !!draggedWindowFromLibrary;

  const isExtrudeMode = !!useAppSelector(getExtrudeData);
  const isEditMode = useAppSelector(getEditMode) !== EditModes.Unset;

  const setHoveredNode = ({
    nodeGUID,
    isLocked,
    source,
    nodeType,
    event,
  }: SetHoveredNodeProps) => {
    const shouldPreventNodeHover =
      nodeType !== NodeType.Wall && isWindowCardDragging;

    const isLockedToHover = source
      ? isLocked && source === SelectedNodeSource.Viewer
      : isLocked;

    event?.stopPropagation();
    if (isLockedToHover || shouldPreventNodeHover) return;

    isSelectionMode &&
      nodeGUID &&
      !isExtrudeMode &&
      dispatch(setHoveredNodeReducer(nodeGUID));
  };

  const resetHoveredNode = (event?: ThreeEvent<PointerEvent>) => {
    event?.stopPropagation();
    !isEditMode && dispatch(resetHoveredNodesReducer());
  };

  return {
    setHoveredNode,
    resetHoveredNode,
  };
};
