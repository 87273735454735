import React from 'react';

const StraightUnitEditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_20684_342696)">
        <path
          d="M11.3717 9.80468L12.6953 11.1283M13.2259 9.27413C12.8604 8.90862 12.2678 8.90862 11.9023 9.27413L9.09171 12.0847C8.95811 12.2183 8.86284 12.3853 8.81587 12.5683L8.50561 13.7771C8.48992 13.8382 8.50767 13.9031 8.55229 13.9477C8.59692 13.9923 8.66178 14.0101 8.72291 13.9944L9.93168 13.6841C10.1147 13.6372 10.2817 13.5419 10.4153 13.4083L13.2259 10.5977C13.5914 10.2322 13.5914 9.63964 13.2259 9.27413Z"
          stroke="#343335"
          strokeLinejoin="round"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 0.492504C12.5 -0.106734 11.8655 -0.493244 11.333 -0.21835L3.93301 3.60207C3.66706 3.73937 3.5 4.01363 3.5 4.31292V14.5075C3.5 15.1067 4.13453 15.4932 4.667 15.2184L7.22183 13.8994C7.1156 13.5744 7.04537 13.2331 7.01584 12.8803L4.5 14.1792V4.43475L11.5 0.820841V8C11.8437 8 12.1784 8.03853 12.5 8.11151V0.492504Z"
          fill="#414042"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 0.492504C12.5 -0.106734 11.8655 -0.493244 11.333 -0.21835L3.93301 3.60207C3.66706 3.73937 3.5 4.01363 3.5 4.31292V14.5075C3.5 15.1067 4.13453 15.4932 4.667 15.2184L7.22183 13.8994C7.1156 13.5744 7.04537 13.2331 7.01584 12.8803L4.5 14.1792V4.43475L11.5 0.820841V8C11.8437 8 12.1784 8.03853 12.5 8.11151V0.492504Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_20684_342696">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default StraightUnitEditIcon;
