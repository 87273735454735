import React, { useEffect, useRef, useState } from 'react';
import Konva from 'konva';
import { UTCFormData } from '@/components/UTC/models';
import { UTC_CREATOR_STAGE_ID } from '@/shared/constants';
import {
  INITIAL_UTC_SCALE,
  STAGE_HEIGHT_RATIO,
  STAGE_WIDTH_RATIO,
} from '@/components/WindowCreator/constants';
import { Layer, Stage } from 'react-konva';
import { useUpdate, useWindowSize } from 'react-use';
import { KonvaEventObject } from 'konva/lib/Node';
import { useKonvaHandlers } from '@/shared/hooks/useKonvaHandlers';
import {
  DEFAULT_UNIT_WIDTH,
  UTC_ID_NAME,
} from '@/components/UTC/helpers/constants';
import UTCUnit from '@/components/UTC/components/UTCUnit';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { UnitTypeCreatorModes } from '@/models/shared.model';
import {
  clearSelectRelatedData,
  getHasSelectedNode,
  getSelectedUTCNodeType,
  resetMullionArea,
  resetSelectedArea,
  clearSelectedNodes,
  resetHoverArea,
} from '@/store/slices/windowsReducer/UTCSlice';
import UTCSelectionWrapper from './components/UTCSelectionWrapper';
import { SET_WINDOW_CONTEXT_MENU_POSITION } from '@/core/event-names';
import { subscribe, unsubscribe } from '@/core/events';
import { FormInstance } from 'antd/es/form';
import UTCMenu from './components/UTCMenu';
import { UTCElementType } from '../WindowCreator/models';

interface UTCModalContainerProps {
  mode: UnitTypeCreatorModes;
  form: FormInstance<UTCFormData>;
}

const UTCModalContainer = ({ mode, form }: UTCModalContainerProps) => {
  const data = form.getFieldsValue(true);
  const dispatch = useAppDispatch();
  const stageRef = useRef<Konva.Stage>(null!);
  const { height, width } = useWindowSize();
  const wheelEventEndTimeout = useRef(0);
  const update = useUpdate();
  const { wheelHandler, reCenterElement } = useKonvaHandlers();
  Konva.dragButtons = [2];
  const isSelectionMode = mode === UnitTypeCreatorModes.Selection;
  const [menuPosition, setMenuPosition] = useState<{
    x: number;
    y: number;
  } | null>(null);

  const hasSelectedNode = useAppSelector(getHasSelectedNode);
  const selectedNodeType = useAppSelector(getSelectedUTCNodeType);
  const showMenu =
    hasSelectedNode &&
    isSelectionMode &&
    !!menuPosition &&
    selectedNodeType === UTCElementType.Filler;

  const [scaleLimits, setScaleLimits] = useState<{
    minScale: number;
    maxScale: number;
  }>({
    minScale: 0.1,
    maxScale: 0.66,
  });

  useEffect(() => {
    subscribe(SET_WINDOW_CONTEXT_MENU_POSITION, (evt) => {
      setMenuPosition(evt.detail);
    });
    return () => {
      unsubscribe(SET_WINDOW_CONTEXT_MENU_POSITION, (evt) => {
        setMenuPosition(evt.detail);
      });
      dispatch(clearSelectRelatedData());
    };
  }, []);

  useEffect(() => {
    if (!data.points) return;

    const minScale = (0.1 / DEFAULT_UNIT_WIDTH) * DEFAULT_UNIT_WIDTH;

    setScaleLimits({
      minScale,
      maxScale: scaleLimits.maxScale,
    });
  }, [data.points, stageRef.current]);

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (event.target.nodeType === 'Stage') {
      dispatch(clearSelectRelatedData());
    }
  };
  const handleWheel = (event: KonvaEventObject<WheelEvent>) => {
    clearTimeout(wheelEventEndTimeout.current);
    wheelEventEndTimeout.current = window.setTimeout(() => update(), 100);
    const scaleData = wheelHandler(event, scaleLimits);
    if (scaleData) {
      const stage = stageRef.current;
      stage.scale({ x: scaleData.scale, y: scaleData.scale });
      stage.position({ x: scaleData.x, y: scaleData.y });
    }
  };

  useEffect(() => {
    if (stageRef.current) {
      stageRef.current.scale({ x: INITIAL_UTC_SCALE, y: INITIAL_UTC_SCALE });

      const unit = stageRef.current.findOne(`#${UTC_ID_NAME}`);
      if (!unit) return;
      reCenterElement(stageRef.current, unit, data.points, INITIAL_UTC_SCALE);
      update();
    }
  }, [stageRef.current]);

  useEffect(() => {
    dispatch(resetMullionArea());
    dispatch(resetHoverArea());
    dispatch(resetSelectedArea());
    dispatch(clearSelectedNodes());
  }, [mode, data.points]);

  return (
    <Stage
      id={UTC_CREATOR_STAGE_ID}
      ref={stageRef}
      onContextMenu={(e) => e.evt.preventDefault()}
      width={width * STAGE_WIDTH_RATIO}
      height={height * STAGE_HEIGHT_RATIO}
      draggable
      onClick={handleClick}
      onWheel={handleWheel}
      onDragEnd={() => {}}
      onDragMove={() => {}}
    >
      {stageRef?.current && (
        <Layer>
          <UTCUnit
            mode={mode}
            units={data.units}
            formData={data}
            scale={stageRef.current.scaleX()}
          />
          {isSelectionMode && <UTCSelectionWrapper />}

          {showMenu && (
            <UTCMenu
              menuPosition={menuPosition!}
              setMenuPosition={setMenuPosition}
              scale={stageRef.current.scaleX()}
              form={form}
              formData={data}
            />
          )}
        </Layer>
      )}
    </Stage>
  );
};

export default UTCModalContainer;
