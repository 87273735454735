import React, { useEffect, useMemo } from 'react';
import { UserBuildingBlock } from '@/models';
import { createLine2 } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import * as polyclip from 'polyclip-ts';
import {
  generateVerticalBlockContour,
  getBlockContour,
  getBlockContourMaterial,
  getEdgePoints,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  BUILDING_BORDER_COLOR,
  BUILDING_SELECTED_CONTOUR_COLOR,
  DEFAULT_BORDER_CONTOUR_FAT_LINE_WIDTH,
} from '@/shared/materials';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { getStatusIsolateMode } from '@/store/slices/canvasModesSlice';
import { useAppSelector } from '@/store/hooks';
import { getExtrudeData } from '@/store/slices/canvasBuildingSlice';

const BlockContour = ({
  block,
  isInteracted,
}: {
  block: UserBuildingBlock;
  isInteracted: boolean;
}) => {
  polyclip.setPrecision(1e-9);

  const isIsolateModeEnabled = useAppSelector(getStatusIsolateMode);
  const isExtrudeMode = !!useAppSelector(getExtrudeData);

  const showContour = !isIsolateModeEnabled && !isExtrudeMode;

  const configureLineMaterial = (line: Line2, isInteracted: boolean): Line2 => {
    line.material.linewidth = DEFAULT_BORDER_CONTOUR_FAT_LINE_WIDTH;
    line.material.color = isInteracted
      ? BUILDING_SELECTED_CONTOUR_COLOR
      : BUILDING_BORDER_COLOR;

    return line;
  };

  const verticalContour = useMemo(() => {
    return block.storeys
      .map((storey) =>
        getEdgePoints(storey).map((point) =>
          configureLineMaterial(
            generateVerticalBlockContour(point, storey.ceiling.points[0]),
            isInteracted
          )
        )
      )
      .flat();
  }, [block, isInteracted]);

  const contour = useMemo(() => {
    //TODO: investigate why polyclip crashing after some changes
    try {
      const contourPoints = getBlockContour(block);

      return contourPoints
        .map((edges) =>
          edges?.map((edge) =>
            configureLineMaterial(
              createLine2(edge.flat(), getBlockContourMaterial()),
              isInteracted
            )
          )
        )
        .flat(2);
    } catch {
      return [];
    }
  }, [block, isInteracted]);

  useEffect(() => {
    return () => {
      verticalContour.forEach((line) => line.geometry.dispose());
      contour.forEach((line) => line.geometry.dispose());
    };
  }, [block]);

  return (
    <group visible={showContour}>
      {verticalContour.map((point, i) => (
        <primitive object={point} key={`vertical-contour_${i}`} />
      ))}

      {contour.map((edge) => (
        <primitive object={edge} key={`contour-point_${edge.id}`} />
      ))}
    </group>
  );
};

export default BlockContour;
