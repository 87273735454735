import { SearchResults } from '@/shared/hooks/useFindNodeData';
import {
  FlatVector3,
  GridLineData,
  NodeType,
  SelectedNode,
  UserBuildingCoordinatesObject,
  UserBuildingWall,
  CornerGridAlign,
} from '@/models';
import { flatten, round } from 'lodash';
import { DEFAULT_CORNER_GRID_WIDTH } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';

export const constructionElementTypes = [
  NodeType.Building,
  NodeType.Block,
  NodeType.Storey,
  NodeType.Wall,
];
export const getStatusByNodeOrParents = (
  node: SearchResults,
  propertyName: 'isHidden' | 'isLocked'
) => {
  return [
    node.userData?.[propertyName],
    ...node.parentNodes.map(
      (parentNode) => parentNode.userData?.[propertyName]
    ),
  ].some((hidden) => hidden);
};

export const getSiblings = (
  node: SearchResults,
  parentNodeType: NodeType,
  finder: ({
    guid,
    nodeType,
  }: {
    guid: string;
    nodeType: NodeType;
  }) => SearchResults | null
): SelectedNode[] => {
  const parentNodeData = finder({
    guid: node.getParentNode(parentNodeType)?.guid || '',
    nodeType: parentNodeType,
  });
  return (
    parentNodeData?.childNodes.filter(
      (childNode) => childNode.type === node.nodeType
    ) || []
  );
};

export const getBuildingsCoordinates = (
  buildings: UserBuildingCoordinatesObject[]
): FlatVector3[][] => {
  return buildings.map((building) =>
    flatten(building.blocks.map((block) => block.storeys[0].floor.points))
  );
};

export const getDefaultGridLines = (
  wall: UserBuildingWall,
  wallWidth: number
): GridLineData[] => {
  return wall.gridLines
    .filter((gridLine) => gridLine.cornerAlign)
    .map((gridLine) => ({
      offsetFromLeftEdge:
        CornerGridAlign.Left === gridLine.cornerAlign
          ? DEFAULT_CORNER_GRID_WIDTH
          : round(wallWidth - DEFAULT_CORNER_GRID_WIDTH, 2),
      cornerAlign: gridLine.cornerAlign,
      guid: gridLine.guid,
    }));
};
