import React from 'react';
import { useParams } from 'react-router';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import useFrameProperties from '@/shared/hooks/useFrameProperties';

interface EnvironmentFrameProps {
  siteArea: string;
  siteUse: {
    areaUsed: string;
    usageRate: number;
  };
}

const EnvironmentFrame = ({
  siteArea,
  siteUse: { areaUsed, usageRate },
}: EnvironmentFrameProps) => {
  const { id } = useParams();
  const { getAreaUnit } = useFrameProperties();

  const surroundingBuildings = useFetchProjectQuery(id!).data?.environment
    ?.surroundingBuildings.surroundings;

  return (
    <>
      <div className="flex px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden">
          Environment
        </span>
      </div>
      <div
        className={
          'text-xs font-light border border-l-0 border-solid border-light-gray-20 flex-1 h-[calc(100%_-_2rem)] bg-white! overflow-y-auto'
        }
      >
        <PropertyList
          properties={[
            {
              name: 'Site area',
              value: `${siteArea}`,
              units: getAreaUnit(),
            },
            {
              name: 'Area used',
              value: `${areaUsed}`,
              units: getAreaUnit(),
            },
            {
              name: 'Percentage of area used',
              value: `${usageRate}`,
              units: '%',
            },
            {
              name: 'Surrounding buildings',
              value: `${surroundingBuildings?.length}`,
            },
          ]}
        />
      </div>
    </>
  );
};

export default EnvironmentFrame;
