import React, { RefObject } from 'react';
import { FormInstance } from 'antd/es/form';
import { InputRef } from 'antd/es/input';
import {
  convertFtInchToMillimeters,
  convertMillimetersToFtInch,
} from './distance';
import { abs, round } from 'mathjs';
import { MULTIPLE_VALUES_SYMBOL } from '@/shared/constants';

export function addSpacesToThousands(
  numberStr: string,
  isImperialUnits: boolean
) {
  return isImperialUnits
    ? numberStr.replace(/(\d)(?=(\d{3})+(?='))/g, '$1 ')
    : numberStr.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
}

export const convertInputValueToMm = (
  val: string,
  isImperialUnits: boolean
): number => {
  const truncatedValue = removeSpacesFromThousands(val, isImperialUnits);
  const convertedValue = isImperialUnits
    ? round(Number(convertFtInchToMillimeters(truncatedValue)), 2)
    : round(Number(truncatedValue), 2);
  return isNaN(convertedValue) ? 0 : convertedValue;
};

export function removeSpacesFromThousands(
  value: string,
  isImperialUnits: boolean
) {
  return isImperialUnits
    ? value.replace(/\s+(?=.*')/g, '')
    : value.replace(/\s/g, '');
}

export function getAreaUnit(isImperialUnits: boolean) {
  return isImperialUnits ? 'ft²' : 'm²';
}

export function limitValue(value: number, min: number, max: number): number {
  return round(Math.min(Math.max(value, min), max), 2);
}

export function sanitizeInputMetricValue(
  value: string,
  isImperialUnits: boolean,
  digitsAmount: number = 2,
  staticValue: boolean = false
) {
  if (staticValue) {
    return value.replace(/\D/g, '');
  }
  const sanitizedValue = isImperialUnits
    ? value
        .replace(/[‘’]/g, "'")
        .replace(/[“”]/g, '"')
        .replace(/[^\d\s/'"-.]/g, '')
    : value.replace(/(?!^-)[^\d.]/g, '');

  return sanitizedValue.replace(/\.(\d*)/, (match, digitsAfterDot) => {
    return digitsAmount ? `.${digitsAfterDot.slice(0, digitsAmount)}` : '';
  });
}

export const formatLimitValue = ({
  limit,
  isImperialUnits,
  staticValue,
  decimalPlaces = 0,
}: {
  limit: number;
  isImperialUnits: boolean;
  staticValue: boolean;
  decimalPlaces?: number;
}): string => {
  const formattedLimit =
    isImperialUnits && !staticValue
      ? convertMillimetersToFtInch(limit)
          .toString()
          .replace(/"/g, '\u2033')
          .replace(/'/g, '\u2032')
      : // Use Unicode because with style direction: rtl; regular double and single quotes are not displayed correctly
        round(limit, decimalPlaces).toString();

  return addSpacesToThousands(formattedLimit, isImperialUnits);
};

export const getAlphabetIndex = (i: number) =>
  String.fromCharCode('A'.charCodeAt(0) + i);

export const generateInputChangeHandlerForMetric = ({
  name,
  form,
  isImperialUnits,
  staticValue,
  ref,
}: {
  name: string;
  form: FormInstance;
  isImperialUnits: boolean;
  staticValue: boolean;
  ref: RefObject<InputRef>;
}) => {
  return () => {
    const input = ref.current?.input;

    if (!input) return;

    const formattedValue = processInputChange(
      input,
      isImperialUnits,
      staticValue
    );

    form.setFieldsValue({
      [name]: formattedValue,
    });
  };
};

export const compareNumbersWithPrecision = (
  value1: number,
  value2: number,
  precision: number
) => {
  return (
    Math.round(parseFloat(value1.toString()) / precision) * precision ===
    Math.round(parseFloat(value2.toString()) / precision) * precision
  );
};

export const dataURLtoFile = (dataurl: string, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0]!.match(/:(.*?);/)![1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export function roundIfWithinTolerance(value: number, tolerance: number = 0.1) {
  return abs(value - round(value)) < tolerance ? round(value) : round(value, 2);
}

export const blobToBase64 = (blob: Blob) => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise((resolve) => {
    reader.onloadend = () => {
      resolve(reader.result);
    };
  });
};

export const isMultipleValue = (value: string) =>
  MULTIPLE_VALUES_SYMBOL === value;

export const highlightTextAfterSearch = (text: string, searchValue: string) =>
  searchValue
    ? text.split(new RegExp(`(${searchValue})`, 'gi')).map((part, index) =>
        part.toLowerCase() === searchValue.toLowerCase() ? (
          <span key={index} className="text-dark-green-100 font-medium">
            {part}
          </span>
        ) : (
          part
        )
      )
    : text;

export const processInputChange = (
  input: HTMLInputElement,
  isImperialUnits: boolean,
  staticValue?: boolean,
  precision: number = 2
) => {
  const cursorPosition = input.selectionStart!;
  const valueBeforeCursor = input.value.slice(0, cursorPosition);

  const sanitizedValueWithoutSpaces = sanitizeInputMetricValue(
    removeSpacesFromThousands(input.value, isImperialUnits),
    isImperialUnits,
    precision,
    staticValue
  );

  const formattedValue = addSpacesToThousands(
    sanitizedValueWithoutSpaces,
    isImperialUnits
  );

  const spacesBeforeCursor = (valueBeforeCursor.match(/\s/g) || []).length;
  const spacesInFormattedValue = (
    formattedValue.slice(0, cursorPosition).match(/\s/g) || []
  ).length;
  const addedSpaces = spacesInFormattedValue - spacesBeforeCursor;

  const newCursorPosition = cursorPosition + addedSpaces;

  setTimeout(() => {
    input.setSelectionRange(newCursorPosition, newCursorPosition);
  });

  return formattedValue;
};
