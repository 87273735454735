import React from 'react';

const AwningIcon = ({ size = '40' }: { size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 36L4 4L36 4L36 36L4 36ZM7.2 32.8L32.8 32.8L32.8 7.2L7.2 7.2L7.2 32.8Z"
        fill="white"
      />
      <path d="M7.2 32.8L7.2 7.2L32.8 7.2L32.8 32.8L7.2 32.8Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 36L4 4L36 4L36 36L4 36ZM7.2 32.8L32.8 32.8L32.8 7.2L7.2 7.2L7.2 32.8Z"
        stroke="#B3B2B4"
      />
      <path
        d="M7.2 32.8L7.2 7.2L32.8 7.2L32.8 32.8L7.2 32.8Z"
        stroke="#B3B2B4"
      />
      <path d="M7 33L20 8.5L33 33" stroke="#054D80" />
      <path
        d="M7 33L7 7L20 7L33 7L33 20L33 33L7 33Z"
        fill="#CAD7FF"
        fillOpacity="0.3"
        stroke="#B3B2B4"
      />
    </svg>
  );
};

export default AwningIcon;
