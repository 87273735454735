import React from 'react';
import { GradientType } from '../../models';

const Gradients: React.FC = () => {
  return (
    <defs>
      <linearGradient id={GradientType.GLARE} x1="0.14" y1="1" x2="0.86" y2="0">
        <stop offset="0%" stopColor="rgba(0, 0, 0, 0.2)" />
        <stop offset="100%" stopColor="rgba(255, 255, 255, 0.2)" />
      </linearGradient>
    </defs>
  );
};

export default Gradients;
