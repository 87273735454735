import React from 'react';

const HopperIcon = ({ size = '40' }: { size?: string }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 4L4 4L4 36H36L36 4ZM32.8 7.2L7.2 7.2L7.2 32.8H32.8V7.2Z"
        fill="white"
      />
      <path d="M7.2 7.2L32.8 7.2V32.8H7.2L7.2 7.2Z" fill="white" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36 4L4 4L4 36H36L36 4ZM32.8 7.2L7.2 7.2L7.2 32.8H32.8V7.2Z"
        stroke="#B3B2B4"
      />
      <path d="M7.2 7.2L32.8 7.2V32.8H7.2L7.2 7.2Z" stroke="#B3B2B4" />
      <path d="M33 7L20 31.5L7 7" stroke="#054D80" />
      <path
        d="M33 7L33 33L20 33L7 33L7 20L7 7L33 7Z"
        fill="#CAD7FF"
        fillOpacity="0.3"
        stroke="#B3B2B4"
      />
    </svg>
  );
};

export default HopperIcon;
