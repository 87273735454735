import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const UTCInnerCornerIcon: React.FC<BaseIconProps> = ({
  fill = '#fff',
}) => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_20098_684)">
        <path
          d="M10.5 12.0607V1.00012M10.5 12.0607L3.9324 15.2908C3.73305 15.3888 3.5 15.2437 3.5 15.0216V4.62963C3.5 4.51529 3.565 4.41089 3.6676 4.36043L10.5 1.00012M10.5 12.0607L13.0471 13.5701C13.247 13.6886 13.5 13.5445 13.5 13.312V2.94885C13.5 2.84289 13.4441 2.74478 13.3529 2.69076L10.5 1.00012"
          stroke={fill}
          strokeLinejoin="round"
        />
        <path
          d="M10.5 12.0607V1.00012M10.5 12.0607L3.9324 15.2908C3.73305 15.3888 3.5 15.2437 3.5 15.0216V4.62963C3.5 4.51529 3.565 4.41089 3.6676 4.36043L10.5 1.00012M10.5 12.0607L13.0471 13.5701C13.247 13.6886 13.5 13.5445 13.5 13.312V2.94885C13.5 2.84289 13.4441 2.74478 13.3529 2.69076L10.5 1.00012"
          stroke={fill}
          strokeOpacity="0.2"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};
