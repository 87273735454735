import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const Checkmark: React.FC<BaseIconProps> = ({
  fill = '#64BC50',
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      className={className}
    >
      <path
        d="M10.3979 2.74207C10.5399 2.89278 10.5329 3.13011 10.3822 3.27216L4.43482 8.87758C4.25679 9.04537 3.97733 9.04021 3.80563 8.86594L1.79538 6.8257C1.65002 6.67817 1.65178 6.44074 1.79931 6.29538C1.94683 6.15002 2.18426 6.15178 2.32962 6.29931L4.13394 8.13055L9.8678 2.72637C10.0185 2.58432 10.2558 2.59135 10.3979 2.74207Z"
        fill={fill}
      />
    </svg>
  );
};
