import React from 'react';
import { Group, Rect } from 'react-konva';
import {
  DefaultHandleProps,
  getDashArray,
  getStrokeWidth,
  OPERABLE_WINDOW_HANDLE_COLOR,
} from '@/components/WindowCreator/elements/InnerWindow/components/handle-helpers';

const OperableWindowHandle: React.FC<DefaultHandleProps> = ({
  x,
  y,
  scale,
  outsideView,
}) => {
  return (
    <Group scaleX={1} scaleY={1} x={x} y={y}>
      <Rect
        x={0.5}
        y={0.5}
        width={24}
        height={59}
        cornerRadius={12}
        stroke={OPERABLE_WINDOW_HANDLE_COLOR}
        dash={getDashArray(scale, !!outsideView)}
        strokeWidth={getStrokeWidth(scale)}
      />
      <Rect
        x={8.5}
        y={24.5}
        width={9}
        height={105}
        cornerRadius={4.5}
        stroke={OPERABLE_WINDOW_HANDLE_COLOR}
        dash={getDashArray(scale, !!outsideView)}
        strokeWidth={getStrokeWidth(scale)}
      />
    </Group>
  );
};

export default OperableWindowHandle;
