import React, { useEffect, useMemo, useRef } from 'react';
import { FillerType, UnitInnerFrame } from '@/components/UTC/models';
import { Group, Line, Text } from 'react-konva';
import Konva from 'konva';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getMultiMeasurementActiveStatus,
  getUnitTypeCreatorMode,
  getValidationErrors,
  resetHoverArea,
  setHoverArea,
  setMullionArea,
  setValidationError,
} from '@/store/slices/windowsReducer/UTCSlice';
import { useUTCSelect } from '@/components/UTC/hooks/useUTCSelect';
import { KonvaEventObject } from 'konva/lib/Node';
import { UTCElementType, View } from '@/components/WindowCreator/models';
import { UnitTypeCreatorModes } from '@/models/shared.model';
import { calculateActualFillerZone } from '@/components/UTC/helpers/area-calculation';
import { FlatVector2, TextColor, UnitSystemTypes } from '@/models';
import { PanelConfig } from '@/models/window-configurator.model';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { WINDOW_GLASS_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import OperableWindow from '@/components/WindowCreator/elements/InnerWindow/OperableWindow';
import { generateFillerNumberPosition } from '../helpers/generators';
import { getTextColorByCriteria } from '@/shared/helpers';
import useUnitIGuValidation from '../hooks/useUnitIguValidation';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import PlacementElementError from '@/components/WindowCreator/elements/InnerWindow/PlacementElementError';
import { INVALID_IGU_CONFIG_TITLE } from '@/components/WindowCreator/constants';

interface UTCFillerProps {
  fillerData: UnitInnerFrame;
  framePoints: FlatVector2[];
  unitConfig: PanelConfig;
  view: View;
  scale: number;
  fillerNumber: string | null;
  showValidationErrors: boolean;
  units?: UnitSystemTypes;
}

const UTCFiller = ({
  fillerData,
  unitConfig,
  framePoints,
  view,
  scale,
  fillerNumber,
  showValidationErrors,
  units,
}: UTCFillerProps) => {
  const { colors: configColors, baseUnit } = useFetchWindowConfigQuery().data!;
  const fillerRef = useRef<Konva.Group>(null);
  const IGUData = useFetchIguQuery().data!;
  const dispatch = useAppDispatch();
  const mode = useAppSelector(getUnitTypeCreatorMode);
  const multiMeasurementStatus = useAppSelector(
    getMultiMeasurementActiveStatus
  );
  const isSelectionMode = mode === UnitTypeCreatorModes.Selection;
  const validationErrors = useAppSelector(
    getValidationErrors(fillerRef?.current?._id ?? 0)
  );

  const { selectElementInUTC } = useUTCSelect(
    fillerRef?.current?._id,
    fillerData.points
  );

  const fillerContour = useMemo(
    () => calculateActualFillerZone(fillerData.points, framePoints, unitConfig),
    [fillerData.points]
  );

  const iguItem = useMemo(() => {
    if (fillerData.fillerType === FillerType.Aluminium || !IGUData) return null;

    return IGUData.find((item) => item.id === fillerData.iguId);
  }, [fillerData]);

  const { validateIguFiller, collectUnitIguValidationData } =
    useUnitIGuValidation();

  useEffect(() => {
    if (
      fillerData.fillerType === FillerType.Aluminium ||
      !iguItem ||
      !showValidationErrors ||
      !units
    )
      return;

    const id = fillerRef?.current?._id ?? 0;
    const validationData = collectUnitIguValidationData({
      fillerData,
      framePoints,
      glassType: iguItem.glassType,
    });
    const iguErrors: string[] = validateIguFiller(
      validationData,
      iguItem.limitations,
      iguItem.thickness,
      units === UnitSystemTypes.Imperial
    );

    if (iguErrors.length > 0)
      dispatch(setValidationError({ id, errors: iguErrors }));

    return () => {
      dispatch(setValidationError({ id, errors: [] }));
    };
  }, [framePoints, fillerData, iguItem, showValidationErrors, units]);

  const handleSelect = (event?: KonvaEventObject<MouseEvent>) => {
    selectElementInUTC(UTCElementType.Filler, fillerContour, event);
  };

  const handleMouseOver = () => {
    dispatch(setHoverArea(fillerContour));
    mode === UnitTypeCreatorModes.MullionCreator &&
      !multiMeasurementStatus &&
      fillerData.fillerType !== FillerType.OperableWindow &&
      dispatch(setMullionArea(fillerData.points));
  };

  const handleMouseLeave = () => {
    isSelectionMode && dispatch(resetHoverArea());
  };

  const fillerColor = useMemo(() => {
    if (fillerData?.fillerType === FillerType.Glass) return WINDOW_GLASS_COLOR;
    return getHexByName(
      configColors,
      view === View.Outside ? fillerData.outsideColor : fillerData.insideColor
    );
  }, [configColors, fillerData, view]);

  const showOperableWindow =
    fillerData.fillerType === FillerType.OperableWindow &&
    fillerData.operableWindowType;

  const numberPosition = useMemo(
    () =>
      generateFillerNumberPosition({
        fillerContour,
        scale,
        unitConfig,
        isOperableWindow: !!fillerData.operableWindowType,
      }),
    [fillerData, unitConfig, scale]
  );

  const numberColor = useMemo(() => {
    if (fillerData.operableWindowType) return TextColor.DARK;
    return getTextColorByCriteria(
      fillerData.fillerType,
      view === View.Outside ? fillerData.outsideColor : fillerData.insideColor
    );
  }, [fillerData, view]);

  return (
    <>
      <Group
        onMouseOver={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        ref={fillerRef}
        onClick={handleSelect}
      >
        {showOperableWindow ? (
          <OperableWindow
            points={fillerData.points}
            scale={scale}
            outerFramePoints={fillerData.points}
            operationType={fillerData.operableWindowType!}
            frameColor={
              view === View.Outside
                ? fillerData.outsideColor
                : fillerData.insideColor
            }
            view={view}
            iguId={fillerData.iguId ?? baseUnit.iguId}
            windowContour={fillerContour}
          />
        ) : (
          <Line
            points={fillerContour.flat().flat()}
            fill={fillerColor}
            closed
          />
        )}
        {fillerNumber && (
          <Text
            text={fillerNumber}
            x={numberPosition.positionX}
            y={numberPosition.positionY}
            fontSize={12 / scale}
            fontStyle="500"
            fill={numberColor}
            fontFamily="Rubik"
          />
        )}
      </Group>
      {validationErrors?.length > 0 && (
        <PlacementElementError
          errorsTitle={INVALID_IGU_CONFIG_TITLE}
          position={[fillerContour[0][1][0], fillerContour[0][1][1]]}
          errorList={validationErrors}
          hoverArea={fillerContour}
          scale={scale}
        />
      )}
    </>
  );
};

export default UTCFiller;
