import { Group, Path } from 'react-konva';
import React from 'react';
import {
  STATIC_SCALE,
  MAN_CONTOUR_PARAMS,
  INITIAL_UTC_SCALE,
} from '@/components/WindowCreator/constants';
import { Measurement } from '@/components/WindowCreator/elements/Measurements';
import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models/konva-model';
import { FlatVector2, UnitSystemTypes } from '@/models';
import { COMMON_LINE_STROKE_WIDTH } from '@/shared/constants';
import { MAN_CONTOUR_PATH } from '@/components/WindowCreator/elements';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';

interface UTCManContourProps {
  scale: number;
  units: UnitSystemTypes;
  outerFramePoints: FlatVector2[];
}

export const UTCManContour = ({
  scale,
  units,
  outerFramePoints,
}: UTCManContourProps) => {
  const config = useFetchWindowConfigQuery().data!;
  const xOffset =
    outerFramePoints[0][0] -
    (MAN_CONTOUR_PARAMS.offsetFromWindowFrame + MAN_CONTOUR_PARAMS.width) /
      INITIAL_UTC_SCALE;

  const points: FlatVector2Axis = [
    [xOffset, outerFramePoints[2][1] + config.panel.verticalOffset / 2],
    [xOffset, outerFramePoints[2][1] + config.panel.verticalOffset / 2 - 1750],
  ];

  const manContourPoints = {
    x: xOffset,
    y: outerFramePoints[2][1] - 1750 + config.panel.verticalOffset / 2,
  };

  return (
    <Group listening={false}>
      <Path
        data={MAN_CONTOUR_PATH}
        stroke="#B3B2B4"
        strokeWidth={COMMON_LINE_STROKE_WIDTH}
        strokeScaleEnabled={false}
        fill={'#F2F3F3'}
        x={manContourPoints.x}
        y={manContourPoints.y}
        scaleX={1 / STATIC_SCALE}
        scaleY={1 / STATIC_SCALE}
      />
      <Measurement
        type={MeasurementElementType.Contour}
        units={units}
        scale={scale}
        disabled
        key={`measurement_offset`}
        points={points}
      ></Measurement>
    </Group>
  );
};
