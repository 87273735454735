export enum UnitSystemTypes {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

export const unitSystems = [
  { value: UnitSystemTypes.Metric, label: 'Metric' },
  { value: UnitSystemTypes.Imperial, label: 'Imperial' },
];

export enum DistanceInput {
  Distance = 'Distance',
  Width = 'Width',
  Length = 'Length',
  BuildingWidth = 'Building width',
  LeftEdgeDistance = 'Left Edge Distance',
  RightEdgeDistance = 'Right Edge Distance',
  FloorHeight = 'Floor height',
}

export enum Orientation {
  HORIZONTAL = 'Horizontal',
  VERTICAL = 'Vertical',
}

export enum NumberedInput {
  Floors = 'Floors',
}

export enum MetricLimits {
  // Rectangle modeling and Free form modeling
  WidthLengthMin = 500,
  WidthLengthMax = 900000,

  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  CenterLineBuildingWidthMin = 8000,
  CenterLineBuildingWidthLengthMax = 100000,

  FloorsMin = 1,
  FloorsMax = 50,

  FloorHeightMin = 508,
  FloorHeightMax = 6096,

  CornerPanelMaxWidth = 2300,
  CornerPanelMinWidth = 250,
}

export enum Sides {
  North = 'North',
  South = 'South',
  East = 'East',
  West = 'West',
}

export enum Placement {
  Top = 'Top',
  Bottom = 'Bottom',
  Left = 'Left',
  Right = 'Right',
}
