import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { removeSpacesFromThousands } from '@/shared/helpers/format-data';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { round } from 'mathjs';
import { convertMetersToMillimeters } from '@/shared/helpers/distance';
import {
  convert2DPointsToDistanceInMeters,
  getWallHeight,
} from '@/shared/helpers/metrics';
import { useAppSelector } from '@/store/hooks';
import { getMultiplyRate } from '@/store/slices/projectSlice';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { useParams } from 'react-router';
import {
  ExtendedMinMaxCoordinatesPairs,
  getMinMaxCoordinatesAtVector3,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { FlatVector2, UserBuildingWall } from '@/models';

export const useMeasureWallsFacadeDesigner = () => {
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));
  const { getWallWidthMetric } = useFrameProperties({
    metricOnly: true,
  });

  const getMeasureWalls = (wallsData: UserBuildingWall[]) => {
    const minMaxWallsCoordinates = getMinMaxCoordinatesAtVector3(
      wallsData.map((wall) => convertFlatVector3ToVectors(wall.points)).flat()
    );

    const xDirection = wallsData[0].points[0][0] <= wallsData[0].points[1][0];
    const zDirection = wallsData[0].points[0][2] <= wallsData[0].points[1][2];

    const wallsTotalHeight = round(
      Number(
        convertMetersToMillimeters(
          (minMaxWallsCoordinates.max.y - minMaxWallsCoordinates.min.y) /
            multiplyRate
        )
      ),
      2
    );

    const wallsTotalWidth = round(
      Number(
        convertMetersToMillimeters(
          convert2DPointsToDistanceInMeters(
            [minMaxWallsCoordinates.max.x, minMaxWallsCoordinates.max.z],
            [minMaxWallsCoordinates.min.x, minMaxWallsCoordinates.min.z],
            multiplyRate
          )
        )
      ),
      2
    );

    const startPoint = {
      x: minMaxWallsCoordinates[xDirection ? 'max' : 'min'].x,
      y: minMaxWallsCoordinates.min.y,
      z: minMaxWallsCoordinates[zDirection ? 'max' : 'min'].z,
    };

    const endPoint = {
      x: minMaxWallsCoordinates[xDirection ? 'min' : 'max'].x,
      y: minMaxWallsCoordinates.min.y,
      z: minMaxWallsCoordinates[zDirection ? 'min' : 'max'].z,
    };

    return {
      wallsTotalHeight,
      wallsTotalWidth,
      minMaxWallsCoordinates,
      startPoint,
      endPoint,
    };
  };

  const getMeasureWall = (
    wallData: WallSearchResults,
    startPoint: {
      x: number;
      z: number;
    },
    minMaxWallsCoordinates: ExtendedMinMaxCoordinatesPairs
  ) => {
    const wallWidth = Number(
      removeSpacesFromThousands(getWallWidthMetric([wallData]), false)
    );
    const wallHeight = convertMetersToMillimeters(
      getWallHeight(wallData.points, multiplyRate)
    );

    const wallCoordinates = getMinMaxCoordinatesAtVector3(
      convertFlatVector3ToVectors(wallData.points)
    );

    const wallBottomLeftPoint: FlatVector2 = [
      wallData.points[1][0],
      wallData.points[1][2],
    ];

    const wallOffset = {
      x: convertMetersToMillimeters(
        convert2DPointsToDistanceInMeters(
          wallBottomLeftPoint,
          [startPoint.x, startPoint.z],
          multiplyRate
        )
      ),
      y: convertMetersToMillimeters(
        (wallCoordinates.max.y - minMaxWallsCoordinates.max.y) / multiplyRate
      ),
    };

    return { wallHeight, wallWidth, wallOffset };
  };

  return {
    getMeasureWall,
    getMeasureWalls,
  };
};
