import React from 'react';
import { CornerGridAlign, UnitSystemTypes, UserBuildingPanel } from '@/models';
import IntusTable from '@/shared/elements/IntusTable/IntusTable';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import { addSpacesToThousands } from '@/shared/helpers/format-data';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import {
  getPanelGlazedArea,
  getPanelHeight,
  getPanelWidth,
  getSideCornerPanel,
} from '@/shared/helpers';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import { roundKonvaValue } from '@/shared/helpers/konva';

const PanelMetricsTable = ({
  panelData,
  panelCount,
}: {
  panelData: UserBuildingPanel;
  panelCount: {
    [key: number]: number;
  };
}) => {
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const { panel: panelConfig } = useFetchWindowConfigQuery().data!;
  const { data: panelsData } = useGetAllPanelsQuery(id!);

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const {
    calculatePanelArea,
    calculateNonGlazedAreaInPanel,
    getAreaUnit,
    getUnit,
  } = useFrameProperties();

  const getCornerDataSource = () => {
    if (!panelsData || !panelData.cornerSide) return {};
    const sideCorner = getSideCornerPanel(panelData, panelsData);

    return {
      rightWidth: (
        <div className="text-end">
          {addSpacesToThousands(
            isImperialUnits
              ? convertMillimetersToFtInch(getPanelWidth(sideCorner))
              : getPanelWidth(sideCorner).toString(),
            isImperialUnits
          )}
        </div>
      ),
    };
  };

  const dataSource = [
    {
      key: `panel-metrics-${panelData.id}`,
      width: (
        <div className="text-end">
          {addSpacesToThousands(
            isImperialUnits
              ? convertMillimetersToFtInch(getPanelWidth(panelData))
              : getPanelWidth(panelData).toString(),
            isImperialUnits
          )}
        </div>
      ),
      ...getCornerDataSource(),
      height: (
        <div className="text-end">
          {addSpacesToThousands(
            isImperialUnits
              ? convertMillimetersToFtInch(getPanelHeight(panelData))
              : getPanelHeight(panelData).toString(),
            isImperialUnits
          )}
        </div>
      ),
      totalArea: (
        <div className="text-end">{calculatePanelArea(panelData)}</div>
      ),
      glazedArea: (
        <div className="text-end">
          {panelData.cornerSide && panelsData
            ? roundKonvaValue(
                getPanelGlazedArea(panelData, panelConfig) +
                  getPanelGlazedArea(
                    getSideCornerPanel(panelData, panelsData),
                    panelConfig
                  )
              )
            : getPanelGlazedArea(panelData, panelConfig)}
        </div>
      ),
      nonGlazedArea: (
        <div className="text-end">
          {calculateNonGlazedAreaInPanel(panelData)}
        </div>
      ),
      count: <div className="text-end">{panelCount[panelData.id]}</div>,
    },
  ];

  let columns = [
    {
      key: `panel-width-column-${panelData.id}`,
      title: (
        <div className="text-end">
          {panelData.cornerSide &&
          panelData.cornerSide === CornerGridAlign.Left ? (
            <div>
              Left wing <br /> width
            </div>
          ) : (
            <div>
              Width{' '}
              <span className="text-[8px] text-dark-gray-60">
                ({getUnit()})
              </span>
            </div>
          )}
        </div>
      ),
      dataIndex: 'width',
      width: panelData.cornerSide ? '82px' : '79px',
    },
    {
      key: `panel-height-column-${panelData.id}`,
      title: (
        <div className="text-end">
          Height{' '}
          <span className="text-[8px] text-dark-gray-60">({getUnit()})</span>
        </div>
      ),
      dataIndex: 'height',
      width: panelData.cornerSide ? '82px' : '79px',
    },
    {
      key: `panel-total-area-column-${panelData.id}`,
      title: (
        <div className="text-end">
          Total area{' '}
          <span className="text-[8px] text-dark-gray-60">{getAreaUnit()}</span>
        </div>
      ),
      dataIndex: 'totalArea',
      width: panelData.cornerSide ? '82px' : '79px',
    },
    {
      key: `panel-glazed-area-column-${panelData.id}`,
      title: (
        <div className="text-end">
          Glazed area{' '}
          <span className="text-[8px] text-dark-gray-60">{getAreaUnit()}</span>
        </div>
      ),
      dataIndex: 'glazedArea',
      width: panelData.cornerSide ? '82px' : '79px',
    },
    {
      key: `panel-non-glazed-area-column-${panelData.id}`,
      title: (
        <div className="text-end">
          <div className="whitespace-nowrap">Non-glazed area</div>
          <div className="text-dark-gray-60">{getAreaUnit()}</div>
        </div>
      ),
      dataIndex: 'nonGlazedArea',
      width: panelData.cornerSide ? '82px' : '79px',
    },
    {
      key: `panel-count-column-${panelData.id}`,
      title: <div className="text-end">Count</div>,
      dataIndex: 'count',
    },
  ];

  if (panelData.cornerSide) {
    columns = [
      ...columns.slice(0, 1),
      {
        key: `panel-right-width-column-${panelData.id}`,
        title: (
          <div className="text-end">
            Right wing <br />
            width
          </div>
        ),
        dataIndex: 'rightWidth',
        width: '82px',
      },
      ...columns.slice(1),
    ];
  }

  return (
    <IntusTable
      dataSource={dataSource}
      columns={columns}
      bordered={false}
      pagination={false}
    />
  );
};

export default PanelMetricsTable;
