import React, { useMemo } from 'react';
import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getExtrudeData,
  getSelectedNodes,
  setExtrudeData,
} from '@/store/slices/canvasBuildingSlice';
import { NodeType } from '@/models';
import {
  getCenterFromVectorsArray,
  getPerpendicularVectorToVectors,
  getTranslatedVector,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { compact } from 'lodash';
import ExtrudeDotHandler, {
  EXTRUDE_DOT_MARGIN,
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';

interface MultipleExtrudeHandlersProps {}

const MultipleExtrudeHandlers: React.FC<MultipleExtrudeHandlersProps> = () => {
  const dispatch = useAppDispatch();

  const { facadesData } = useFacadeData();
  const { findDataForWall } = useFindNodeData();
  const extrudeDataObject = useAppSelector(getExtrudeData);
  const selectedNodes = Object.values(useAppSelector(getSelectedNodes)).filter(
    (node) => node.type === NodeType.Wall
  );

  const isAvailable = useMemo(() => {
    const isOneFacade = facadesData.some((facade) =>
      selectedNodes.every((node) =>
        facade.find((wall) => wall.guid === node.guid)
      )
    );

    return isOneFacade && selectedNodes.length > 1;
  }, [selectedNodes, facadesData]);

  const extrudeData = useMemo((): ExtrudeHandlerData | null => {
    const wallsPoints = compact(
      selectedNodes?.map((node) => findDataForWall(node?.guid)?.points)
    );
    if (wallsPoints.length === 0) return null;
    const normal = getPerpendicularVectorToVectors(
      convertFlatVector3ToVectors(wallsPoints[0]),
      true
    );

    const centerPosition = getCenterFromVectorsArray(
      convertFlatVector3ToVectors(wallsPoints.flat())
    );

    return {
      perpendicularDirection: getXYZ(normal),
      defaultCenter: getXYZ(
        getTranslatedVector(centerPosition, -EXTRUDE_DOT_MARGIN, normal)
      ),
      facadeWallsGuids: selectedNodes.map((node) => node.guid),
    };
  }, [selectedNodes]);

  if (!isAvailable || !extrudeData || extrudeDataObject || !extrudeData)
    return null;

  return (
    <>
      <ExtrudeDotHandler
        extrudeHandlerData={extrudeData}
        clickAction={() => extrudeData && dispatch(setExtrudeData(extrudeData))}
      />
    </>
  );
};

export default MultipleExtrudeHandlers;
