import { UnitInnerFrame } from '@/components/UTC/models';
import {
  FlatVector2,
  GridLineData,
  UserBuildingPanel,
  WallPanelError,
} from '@/models';
import {
  MullionData,
  OperationType,
  WindowConfigurator,
} from '@/models/window-configurator.model';

export enum Background {
  DEFAULT = '#FFFFFF',
  ERROR = '#D23736',
}

export enum LineColor {
  DEFAULT = '#8D8B8F',
}

export enum GradientType {
  GLARE = 'glare',
}

export interface FacadeData {
  wallsTotalHeight: number;
  wallsTotalWidth: number;
  walls: {
    wallUnits: (ElementData & {
      offsetFromLeftEdge: number;
    })[];
    gridLines: GridLineData[];
    wallUnitErrors: WallPanelError[];
    wallHeight: number;
    wallOffset: {
      x: number;
      y: number;
    };
    wallWidth: number;
  }[];
}

export interface ElementData {
  id: number;
  parentWidth: number;
  width: number;
  height: number;
  sideWidth: number;
  unitNumber?: number;
  isCorner: boolean;
  borders: FlatVector2[][];
  mullions: MullionData[];
  frames: UnitInnerFrame[];
  config: WindowConfigurator;
  sideCorner: UserBuildingPanel;
  unit: UserBuildingPanel;
  isUnitNumbering: boolean;
  isNumbering: boolean;
  isReport: boolean | undefined;
}
export interface Frame {
  frameNumber: string;
  textColor: string;
  operationType: OperationType;
}
export interface CustomOperableWindow extends OperableWindow {
  handleScale: number;
}
export interface OperableWindow {
  operableConfig: OperableConfig;
  offset: number;
  color: Color;
  points: FlatVector2[];
}

export interface OperableConfig {
  sashWidth: number;
  outerWidth: number;
}

export interface Color {
  hex: string;
  hasGlare: boolean;
}

export interface Dimensions {
  x: number;
  y: number;
  width: number;
  height: number;
}
