import React, { useEffect, useMemo, useRef } from 'react';
import { useLoader, useThree } from '@react-three/fiber';
import * as THREE from 'three';
import {
  convertBoundingBoxToCanvasCoordinates,
  getBuildingMaterials,
} from './project-canvas.helpers';
import { NodeType, ProjectStructure } from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import {
  getCursorCoordinatesOnOrthographicSystem,
  isLeftClick,
} from '@/shared/helpers';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import {
  getIsSelectionModeEnabled,
  getStatusIsolateMode,
} from '@/store/slices/canvasModesSlice';
import { setMapUUID } from '@/store/slices/canvasMapSlice';

const MAP_GEOMETRY_HEIGHT = 0.1;

const ProjectCanvasMap = ({
  map,
  project,
}: {
  map: string;
  project: ProjectStructure;
}) => {
  const isIsolateModeEnabled = useAppSelector(getStatusIsolateMode);
  const isSelectionModeEnabled = useAppSelector(getIsSelectionModeEnabled);

  const texture = useLoader(THREE.TextureLoader, map);
  texture.colorSpace = THREE.SRGBColorSpace;
  const { raycaster, gl, camera, scene } = useThree();
  const dispatch = useAppDispatch();

  const { clearAllSelectedNodes } = useSelectedNodes();

  const mapRef = useRef<THREE.Mesh>(null);
  const materials = getBuildingMaterials(texture);

  const handlePointerDown = (event: PointerEvent) => {
    if (!isLeftClick(event)) return;
    const cursorCoordinates = getCursorCoordinatesOnOrthographicSystem(
      event,
      gl
    );

    raycaster.setFromCamera(cursorCoordinates, camera);

    const intersectsFirst = raycaster.intersectObjects(scene.children, true);
    const isClickedOnMapOrOutsideOfObjects =
      !intersectsFirst.length ||
      intersectsFirst[0]?.object.uuid === mapRef.current?.uuid;

    isClickedOnMapOrOutsideOfObjects && clearAllSelectedNodes();
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    if (!canvas) return;

    isSelectionModeEnabled &&
      canvas.addEventListener('pointerdown', handlePointerDown);
    return () => {
      canvas.removeEventListener('pointerdown', handlePointerDown);
    };
  }, [isSelectionModeEnabled]);

  useEffect(() => {
    mapRef.current && dispatch(setMapUUID(mapRef.current?.uuid));
  }, [mapRef.current, isIsolateModeEnabled]);

  const canvasMap = () => {
    const canvasCoordinates = convertBoundingBoxToCanvasCoordinates(
      project.boundingBox
    );

    const mapGeometry = useMemo(
      () =>
        new THREE.BoxGeometry(
          canvasCoordinates.max.x - canvasCoordinates.min.x,
          MAP_GEOMETRY_HEIGHT,
          canvasCoordinates.max.z - canvasCoordinates.min.z
        ),
      []
    );

    // For some reason coordinates inverted. Not sure why. Feel you self free to investigate & correct if needed.
    const xWidthCenter =
      (canvasCoordinates.min.z + canvasCoordinates.max.z) / 2;
    const zDepthCenter =
      (canvasCoordinates.min.x + canvasCoordinates.max.x) / 2;

    if (isIsolateModeEnabled) return null;

    return (
      <mesh
        receiveShadow
        ref={mapRef}
        position={[-xWidthCenter, -MAP_GEOMETRY_HEIGHT / 2, -zDepthCenter]}
        geometry={mapGeometry}
        material={materials}
        userData={{ guid: 'map_guid', nodeType: NodeType.Environment }}
      />
    );
  };

  return <>{canvasMap()}</>;
};

export default ProjectCanvasMap;
