import { PanelConfig, WindowFrame } from '@/models/window-configurator.model';

export const getWindowWidth = (window: WindowFrame): number =>
  window.points[1][0] - window.points[0][0];

export const getWindowHeight = (window: WindowFrame): number =>
  window.points[2][1] - window.points[0][1];

export const getOperableFrameWidth = (panelConfig: PanelConfig) => {
  return (
    panelConfig.operableFrame.windowFrameWidth +
    panelConfig.operableFrame.sashWidth
  );
};
