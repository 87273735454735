import React, { useState } from 'react';
import { getTranslatedVector } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import * as THREE from 'three';
import { CanvasActionsModes, FlatVector3 } from '@/models';
import { convertFlatVector3ToVector } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { Html } from '@react-three/drei';
import { ExtrudeDotIcon } from '@/shared/icons/ExtrudeDotIcon';
import { calculationMatrix2D } from '@/shared/helpers/matrix';
import { useAppDispatch } from '@/store/hooks';
import { setMode } from '@/store/slices/canvasModesSlice';

export interface ExtrudeHandlerData {
  defaultCenter: FlatVector3;
  perpendicularDirection: FlatVector3;
  facadeWallsGuids: string[];
}

interface ExtrudeHandlersProps {
  extrudeHandlerData: ExtrudeHandlerData;
  active?: boolean;
  clickAction?: () => void;
  position?: THREE.Vector3;
}

export const EXTRUDE_DOT_MARGIN = 0.001;

const ExtrudeDotHandler: React.FC<ExtrudeHandlersProps> = ({
  extrudeHandlerData,
  active,
  clickAction,
  position,
}) => {
  const dispatch = useAppDispatch();
  const { defaultCenter, perpendicularDirection } = extrudeHandlerData;

  const [hovered, setHovered] = useState(false);

  const getPosition = (
    positiveMargin?: boolean
  ): FlatVector3 | THREE.Vector3 => {
    if (position) return [position.x, position.y, position.z];
    const normal = convertFlatVector3ToVector(perpendicularDirection);
    return getTranslatedVector(
      convertFlatVector3ToVector(defaultCenter),
      positiveMargin ? EXTRUDE_DOT_MARGIN : -EXTRUDE_DOT_MARGIN,
      normal
    );
  };
  return (
    <Html
      center
      occlude={!active}
      position={getPosition()}
      zIndexRange={[0, 0]}
      className="select-none"
      calculatePosition={calculationMatrix2D(0, 0)}
    >
      <button
        className="border-none bg-transparent outline-hidden select-none p-0"
        onPointerDown={(event) => {
          event.stopPropagation();
          clickAction && clickAction();
          clickAction && dispatch(setMode(CanvasActionsModes.edit));
        }}
        onPointerEnter={() => setHovered(true)}
        onPointerLeave={() => setHovered(false)}
        onPointerMove={(event) => !active && event.stopPropagation()}
      >
        <ExtrudeDotIcon
          className={`w-2 h-2`}
          fill={active || hovered ? '#05299E' : '#758DDB'}
        />
      </button>
    </Html>
  );
};

export default ExtrudeDotHandler;
