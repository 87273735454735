import {
  INITIAL_SCALE,
  SAFE_AREA_WIDTH,
  STAGE_BOTTOM_PADDING_TO_FLOOR_0,
  STAGE_HEIGHT,
  STAGE_WIDTH,
} from '../constants';
import { FlatVector2 } from '@/models';
import { FlatVector2Axis } from '@/components/WindowCreator/models/konva-model';
import {
  InnerWindowData,
  MullionData,
  OperationType,
  WindowFrame,
} from '@/models/window-configurator.model';
import {
  isHorizontal,
  mirrorPointAcrossX,
} from '@/components/WindowCreator/helpers/direction.helpers';
import { get2DDistance, roundKonvaValue } from '@/shared/helpers/konva';
import { OUTER_BORDER_THICKNESS } from '../elements/creator-windows.helpers';

export const generateDefaultWindow = (
  width: number,
  height: number,
  offset: number
): FlatVector2[] =>
  [
    [
      STAGE_WIDTH / 2 / INITIAL_SCALE - width / 2,
      (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE -
        height -
        offset,
    ],
    [
      STAGE_WIDTH / 2 / INITIAL_SCALE + width / 2,
      (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE -
        height -
        offset,
    ],
    [
      STAGE_WIDTH / 2 / INITIAL_SCALE + width / 2,
      (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE - offset,
    ],
    [
      STAGE_WIDTH / 2 / INITIAL_SCALE - width / 2,
      (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE - offset,
    ],
  ].map((v): FlatVector2 => [roundKonvaValue(v[0]), roundKonvaValue(v[1])]);

export const generateFrameMeasurements = (
  data: WindowFrame
): [FlatVector2Axis, FlatVector2Axis] => {
  // Sizes following first two points (TopLeft, TopRight, BottomRight)
  return [
    [
      [data.points[0][0], data.points[0][1]],
      [data.points[1][0], data.points[1][1]],
    ],
    [
      [data.points[1][0], data.points[1][1]],
      [data.points[2][0], data.points[2][1]],
    ],
  ];
};

export const generateOffsetMeasurement = (
  data: WindowFrame
): FlatVector2Axis => {
  // Distance from BottomRight point to Floor (using offset characteristic)
  return [
    [data.points[2][0], data.points[2][1]],
    [data.points[2][0], data.points[2][1] + data.distanceToFloor],
  ];
};

export const generateMullionMeasurements = (
  framePoints: FlatVector2[],
  mullions: MullionData[],
  direction: 'x' | 'y'
) => {
  const directionRelatedIdx = direction === 'x' ? 0 : 1;
  const mullionAxisPoints = mullions
    .map((mullion) => [
      mullion.points[0][directionRelatedIdx],
      mullion.points[1][directionRelatedIdx],
    ])
    .flat();
  const frameAxisPoints = framePoints
    .map((measurement) => measurement[directionRelatedIdx])
    .flat();

  const uniqueAxisPoints = [
    ...new Set(
      [...mullionAxisPoints, ...frameAxisPoints].sort((a, b) => a - b)
    ),
  ];

  // Based on rectangle form of Window frame. Should be adjusted if frame will not follow rectangle shape
  const edgeFramePoint =
    framePoints[direction === 'x' ? 0 : 1][direction === 'x' ? 1 : 0];
  const points: FlatVector2[] = uniqueAxisPoints.map((point) =>
    direction === 'x' ? [point, edgeFramePoint] : [edgeFramePoint, point]
  );

  const measurementPoints: FlatVector2Axis[] = [];
  for (let i = 0; i < points.length - 1; i++) {
    measurementPoints.push([points[i], points[i + 1]]);
  }
  return measurementPoints;
};

export const generateMullion = (points: FlatVector2Axis): MullionData => {
  return {
    points,
  };
};

export const generateSplitWindows = (
  axis: FlatVector2Axis,
  // Contour has 4 points only
  existingWindow: InnerWindowData,
  defaultIguId: number | null
): InnerWindowData[] => {
  const isAxisHorizontal = isHorizontal(axis);
  let windowContours: FlatVector2[][];
  const contour = existingWindow.points;
  if (isAxisHorizontal) {
    windowContours = [
      [contour[0], contour[1], axis[1], axis[0]],
      [axis[0], axis[1], contour[2], contour[3]],
    ];
  } else {
    windowContours = [
      [contour[0], axis[0], axis[1], contour[3]],
      [axis[0], contour[1], contour[2], axis[1]],
    ];
  }
  return windowContours.map(
    (windowContour): InnerWindowData => ({
      points: windowContour,
      operationType: OperationType.Fixed,
      iguId: defaultIguId,
    })
  );
};

export const mirrorOperationType = (
  operationType: OperationType
): OperationType => {
  switch (operationType) {
    case OperationType.DualActionLeftTop:
      return OperationType.DualActionRightTop;
    case OperationType.DualActionRightTop:
      return OperationType.DualActionLeftTop;
    case OperationType.CasementLeft:
      return OperationType.CasementRight;
    case OperationType.CasementRight:
      return OperationType.CasementLeft;
    default:
      return operationType;
  }
};

export const swapDiagonalCorners = (points: FlatVector2[]): FlatVector2[] => {
  return [points[1], points[0], points[3], points[2]];
};

export const mirrorFlatVector2Axis = (
  [point1, point2]: FlatVector2Axis,
  frameCenter: number
): FlatVector2Axis => {
  return isHorizontal([point1, point2])
    ? [
        mirrorPointAcrossX(point2, frameCenter),
        mirrorPointAcrossX(point1, frameCenter),
      ]
    : [
        mirrorPointAcrossX(point1, frameCenter),
        mirrorPointAcrossX(point2, frameCenter),
      ];
};

export const generateMirroredWindow = (
  windowData: WindowFrame,
  mirrorOperationTypes: boolean = true
): WindowFrame => {
  const frameCenter = roundKonvaValue(
    (windowData.points[0][0] + windowData.points[1][0]) / 2
  );

  const mirroredInnerWindows = windowData.innerFrames.map((innerWindow) => ({
    ...innerWindow,
    operationType: mirrorOperationTypes
      ? mirrorOperationType(innerWindow.operationType)
      : innerWindow.operationType,
    points: swapDiagonalCorners(
      innerWindow.points.map((point) => mirrorPointAcrossX(point, frameCenter))
    ),
  }));

  const mirroredMullions = windowData.mullions.map((mullion) => ({
    ...mullion,
    points: mirrorFlatVector2Axis(mullion.points, frameCenter),
  }));

  return {
    ...windowData,
    innerFrames: mirroredInnerWindows,
    mullions: mirroredMullions,
  };
};

export const generateMullionSafeArea = ({
  innerFramePoints,
  outerFramePoints,
  isVerticalDirection,
  mullionWidth,
}: {
  innerFramePoints: FlatVector2[];
  outerFramePoints: FlatVector2[];
  isVerticalDirection: boolean;
  mullionWidth: number;
}) => {
  const [topLeft, topRight, bottomRight, bottomLeft] = innerFramePoints;
  const frameDimensions = {
    width: roundKonvaValue(get2DDistance(topLeft, topRight)),
    height: roundKonvaValue(get2DDistance(topRight, bottomRight)),
  };

  const isBorderFrame = {
    left:
      topLeft[0] === outerFramePoints[0][0] &&
      bottomLeft[0] === outerFramePoints[3][0],
    right:
      topRight[0] === outerFramePoints[1][0] &&
      bottomRight[0] === outerFramePoints[2][0],
    top:
      topLeft[1] === outerFramePoints[0][1] &&
      topRight[1] === outerFramePoints[1][1],
    bottom:
      bottomLeft[1] === outerFramePoints[3][1] &&
      bottomRight[1] === outerFramePoints[2][1],
  };

  const baseOffset = roundKonvaValue(mullionWidth / 2 + SAFE_AREA_WIDTH);
  const borderOffset = roundKonvaValue(baseOffset + OUTER_BORDER_THICKNESS);
  const mullionOffset = roundKonvaValue(baseOffset + mullionWidth / 2);
  const getSafeAreaOffset = (isFrame: boolean) =>
    isFrame ? borderOffset : mullionOffset;

  if (isVerticalDirection) {
    return [
      {
        x: topLeft[0],
        y: topLeft[1],
        width: getSafeAreaOffset(isBorderFrame.left),
        height: frameDimensions.height,
      },
      {
        x: roundKonvaValue(
          topRight[0] - getSafeAreaOffset(isBorderFrame.right)
        ),
        y: topRight[1],
        width: getSafeAreaOffset(isBorderFrame.right),
        height: frameDimensions.height,
      },
    ];
  } else {
    return [
      {
        x: topLeft[0],
        y: topLeft[1],
        width: frameDimensions.width,
        height: getSafeAreaOffset(isBorderFrame.top),
      },
      {
        x: bottomLeft[0],
        y: roundKonvaValue(
          bottomLeft[1] - getSafeAreaOffset(isBorderFrame.bottom)
        ),
        width: frameDimensions.width,
        height: getSafeAreaOffset(isBorderFrame.bottom),
      },
    ];
  }
};

export const checkSafeAreasIntersection = (
  safeAreaA: { x: number; y: number; width: number; height: number },
  safeAreaB: { x: number; y: number; width: number; height: number },
  isVerticalDirection: boolean
) => {
  if (isVerticalDirection) {
    return (
      safeAreaA.x + safeAreaA.width > safeAreaB.x + safeAreaB.width ||
      safeAreaB.x < safeAreaA.x
    );
  } else {
    return (
      safeAreaA.y + safeAreaA.height > safeAreaB.y + safeAreaB.height ||
      safeAreaB.y < safeAreaA.y
    );
  }
};
