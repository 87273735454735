import React, { useEffect, useMemo, useState } from 'react';
import WindowCard from '@/shared/elements/WindowCard/WindowCard';
import { useParams } from 'react-router';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getCanvasMode, setUTCMode } from '@/store/slices/canvasModesSlice';
import {
  CanvasActionsModes,
  CornerGridAlign,
  FilterType,
  UserBuildingPanel,
  UTCModes,
} from '@/models';
import {
  getFacadeDesignerMode,
  getModifiedWalls,
  getSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  resetSelectedWindowFromLibrary,
  setSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import {
  SavedWindow,
  View,
} from '@/components/WindowCreator/models/konva-model';
import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import UnitCard from '@/shared/elements/UnitCard/UnitCard';
import { sortByDates } from '@/shared/helpers';
import { setInitialData } from '@/store/slices/windowsReducer/UTCSlice';
import { TooltipPlacement } from 'antd/es/tooltip';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { LEFT_PANEL_DEFAULT_WIDTH } from '@/shared/constants';
import { IntusTooltip } from '@/shared/elements';

const LibraryPanel = ({ filter }: { filter: FilterType }) => {
  const { id } = useParams();
  const projectData = useFetchProjectQuery(id!).data!;
  const dispatch = useAppDispatch();
  const windowsData = useFetchWindowsQuery(id!).data!;
  const unitsData = useGetAllPanelsQuery(id!).data!;
  const isDesignerMode =
    useAppSelector(getCanvasMode) === CanvasActionsModes.facadeDesigner;
  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const modifiedWallsInFacadeDesigner = useAppSelector(getModifiedWalls);
  const layout = useFetchProjectQuery(id!).data?.layout;
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );
  const { findDataForWall } = useFindNodeData();
  const [showWindowTooltip, setShowWindowTooltip] = useState(true);

  const leftPanelWidth = layout?.leftPanelWidth ?? LEFT_PANEL_DEFAULT_WIDTH;

  const disabled = useMemo(() => {
    return isDesignerMode && !modifiedWallsInFacadeDesigner.length;
  }, [facadeDesignerMode, isDesignerMode, modifiedWallsInFacadeDesigner]);

  const isUnitPlacement =
    facadeDesignerMode === FacadeDesignerModes.UnitPlacement;

  const isWindowPlacement =
    facadeDesignerMode === FacadeDesignerModes.WindowPlacement;

  const isWindowPlacementProcessing = !!selectedWindowFromLibrary;

  const hasPlacedWindows = modifiedWallsInFacadeDesigner
    .map((guid) => !!findDataForWall(guid)?.windowPlacements?.length)
    .some((v) => v);

  const unitTooltipPlacementData: {
    placement: TooltipPlacement;
    title: string;
  } = {
    placement: 'right',
    title: 'Click to apply unit',
  };

  const windowTooltipTitle = (
    <span>
      Select a window draft <br />
      from the Library to place
    </span>
  );

  useEffect(() => {
    facadeDesignerMode !== FacadeDesignerModes.WindowPlacement &&
      dispatch(resetSelectedWindowFromLibrary());
  }, [facadeDesignerMode]);

  const handleSelect = (window: SavedWindow) => {
    dispatch(setSelectedWindowFromLibrary(window));
  };

  const handleUTEOpen = (unit: UserBuildingPanel) => {
    dispatch(
      setInitialData({
        id: unit.id,
        points: unit.points,
        name: unit.name,
        innerFrames: unit.innerFrames,
        view: View.Outside,
        mullions: unit.mullions,
        units: projectData.unitSystem,
        insideColor: unit.insideColor,
        outsideColor: unit.outsideColor,
      })
    );

    const utcMode = unit.cornerSide
      ? unit.isInnerCorner
        ? UTCModes.InnerCorner
        : UTCModes.OuterCorner
      : UTCModes.Straight;

    dispatch(setUTCMode(utcMode));
  };

  const filteredWindows = useMemo(() => {
    return filter === FilterType.Windows || filter === FilterType.All
      ? windowsData
      : [];
  }, [filter, windowsData]);

  const filteredUnits = useMemo(() => {
    return filter === FilterType.Units || filter === FilterType.All
      ? unitsData.filter((unit) => unit.cornerSide !== CornerGridAlign.Right)
      : [];
  }, [filter, unitsData]);

  const shuffledItems = useMemo(() => {
    const combined = [
      ...filteredWindows.map((item) => ({
        type: FilterType.Windows as const,
        data: item,
      })),
      ...filteredUnits.map((item) => ({
        type: FilterType.Units as const,
        data: item,
      })),
    ];

    return combined.sort((a, b) =>
      sortByDates(b.data.updatedAt, a.data.updatedAt)
    );
  }, [filteredWindows, filteredUnits]);

  const showTooltip =
    !hasPlacedWindows &&
    isDesignerMode &&
    facadeDesignerMode === FacadeDesignerModes.WindowPlacement &&
    !isWindowPlacementProcessing;

  useEffect(() => {
    if (showTooltip) {
      const timer = setTimeout(() => setShowWindowTooltip(false), 2000);

      return () => clearTimeout(timer);
    } else {
      setShowWindowTooltip(true);
    }
  }, [showTooltip]);

  return (
    <>
      {showTooltip && (
        <IntusTooltip
          title={windowTooltipTitle}
          placement={'right'}
          open={showWindowTooltip}
          styles={{
            body: {
              padding: '4px 8px',
              textAlign: 'start',
            },
          }}
        >
          <div
            className="absolute top-6"
            style={{
              left: leftPanelWidth - 3,
            }}
          />
        </IntusTooltip>
      )}
      <div className="overflow-hidden flex flex-col">
        {shuffledItems.map(({ type, data }) =>
          type === FilterType.Windows ? (
            <WindowCard
              isDesignerMode={isDesignerMode}
              isProjectLocked={projectData.locked}
              disabled={disabled || isUnitPlacement}
              key={data.id}
              windowData={data}
              onSelect={handleSelect}
            />
          ) : (
            <UnitCard
              key={data.id}
              unitData={data}
              popoverPlacement="rightTop"
              isDesignerMode={isDesignerMode}
              tooltipPlacementParams={unitTooltipPlacementData}
              disabled={disabled || isWindowPlacement}
              isProjectLocked={projectData.locked}
              handleUTEOpen={() => handleUTEOpen(data)}
            />
          )
        )}
      </div>
    </>
  );
};

export default LibraryPanel;
