import React, { useMemo, useRef } from 'react';
import { WindowPlacementData } from '@/models';
import { WallSearchResults } from '@/shared/hooks/useFindNodeData';
import { FacadeDesignerModes, PointerPosition } from '@/models/shared.model';
import {
  getDragNode,
  resetSelectedGridLines,
  setHoveredWall,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { KonvaEventObject } from 'konva/lib/Node';
import { isLeftClick } from '@/shared/helpers';
import { Group } from 'react-konva';
import WallView from '@/components/FacadeDesigner/elements/WallView';
import { WindowPlacementDataForFD } from '../models';
import FacadeDesignerConstructionElements from '@/components/FacadeDesigner/elements/FacadeDesignerConstructionElements';
import FacadeDesignerElementPlacement from '@/components/FacadeDesigner/elements/FacadeDesignerElementPlacement';
import { PanelConfig } from '@/models/window-configurator.model';

interface FacadeDesignerFloorViewProps {
  wallOffset: { x: number; y: number };
  wallData: WallSearchResults;
  wallHeight: number;
  wallWidth: number;
  scale: number;
  onMeasurementUpdate: (data: WindowPlacementData[]) => void;
  onAddWindow: (data: WindowPlacementData[]) => void;
  facadeDesignerMode: FacadeDesignerModes;
  onGridPlacement: () => void;
  handleWindowDragEnd?: (newOffset: number) => void;
  config: PanelConfig;
  isProjectLocked: boolean;
}
const FacadeDesignerWallView = ({
  wallOffset,
  wallData,
  scale,
  wallHeight,
  wallWidth,
  onAddWindow,
  onGridPlacement,
  handleWindowDragEnd,
  facadeDesignerMode,
  config,
  isProjectLocked,
}: FacadeDesignerFloorViewProps) => {
  const dispatch = useAppDispatch();

  const dragNode = useAppSelector(getDragNode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;
  const isElementEditingMode =
    [
      FacadeDesignerModes.GridLinePlacement,
      FacadeDesignerModes.WindowPlacement,
    ].includes(facadeDesignerMode) || dragNode;

  const placedWindows: WindowPlacementDataForFD[] = useMemo(() => {
    return (
      wallData.windowPlacements.map((windowData) => ({
        ...windowData,
        wallGUID: wallData.guid,
      })) || []
    );
  }, [wallData]);

  const resetSelectedWindow = (e: KonvaEventObject<MouseEvent>) => {
    if (!isSelectionMode || !isLeftClick(e.evt)) return;
    e.cancelBubble = true;
    dispatch(setSelectedPlacedWindow([]));
    dispatch(resetSelectedGridLines());
  };

  const pointerPositionRef = useRef<PointerPosition | null>(null);

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    const pointerPosition = event.currentTarget?.getRelativePointerPosition();

    if (!pointerPosition) return;
    pointerPositionRef.current = pointerPosition;
  };

  const handleMouseEnter = () => {
    dispatch(setHoveredWall(wallData.guid));
  };

  return (
    <Group
      onMouseEnter={handleMouseEnter}
      offsetX={-wallOffset.x}
      offsetY={wallOffset.y}
      onMouseMove={handleMouseMove}
    >
      <Group onClick={resetSelectedWindow}>
        <WallView wallWidth={wallWidth} wallHeight={wallHeight} />
      </Group>

      <FacadeDesignerConstructionElements
        wallData={wallData}
        wallHeight={wallHeight}
        wallWidth={wallWidth}
        scale={scale}
        facadeDesignerMode={facadeDesignerMode}
        wallOffset={wallOffset}
        placedWindows={placedWindows}
        config={config}
        isProjectLocked={isProjectLocked}
      />
      {isElementEditingMode && (
        <FacadeDesignerElementPlacement
          initialPointerPosition={pointerPositionRef.current}
          wallData={wallData}
          wallWidth={wallWidth}
          wallHeight={wallHeight}
          wallOffset={wallOffset}
          scale={scale}
          facadeDesignerMode={facadeDesignerMode}
          onAddWindow={onAddWindow}
          onGridPlacement={onGridPlacement}
          handleWindowDragEnd={handleWindowDragEnd}
          placedWindows={placedWindows}
        />
      )}
    </Group>
  );
};

export default FacadeDesignerWallView;
