import React from 'react';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { IntusButton } from '@/shared/elements';
import { useUpdateProjectEnvironmentData } from '@/shared/hooks/updateProjectDataHooks/useUpdateProjectEnvironmentData';

const SurroundingBuildingsFrame = () => {
  const { id } = useParams();

  const surroundingBuildings = useFetchProjectQuery(id!).data?.environment
    ?.surroundingBuildings;

  const { surroundingBuildingsUtils } = useUpdateProjectEnvironmentData();

  return (
    <>
      <div className="flex px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden">
          Surrounding buildings
        </span>
      </div>
      <div
        className={
          'text-xs font-light border border-l-0 border-solid border-light-gray-20 flex-1 h-[calc(100%_-_2rem)] bg-white! overflow-y-auto'
        }
      >
        <PropertyList
          properties={[
            {
              name: 'Count',
              value: `${surroundingBuildings?.surroundings?.length}`,
            },
          ]}
        />
        <div className="px-3">
          <IntusButton
            block
            outline
            borderless={false}
            id="propertyPanel__restore-all-surrounding-buildings-button"
            onClick={() => {
              surroundingBuildingsUtils.restoreDeletedSurroundingBuildings(
                surroundingBuildings!.guid
              );
            }}
          >
            Restore all
          </IntusButton>
        </div>
      </div>
    </>
  );
};

export default SurroundingBuildingsFrame;
