import { FillerType, UnitInnerFrame } from '../models';
import { FlatVector2 } from '@/models';
import { FlatVector2Axis } from '@/components/WindowCreator/models';
import { get2DDistance, roundKonvaValue } from '@/shared/helpers/konva';
import { calculateRealFillerGlassPoints } from '../helpers/generators';
import { calculateGlassRatio, validateGlassRatio } from '@/shared/helpers/Igu';
import { convertSquareMillimetersToSquareMeters } from '@/shared/helpers/distance';
import { getOperableFrameWidth } from '@/shared/helpers';
import { generateInnerFramePoints } from '@/components/WindowCreator/elements/creator-windows.helpers';
import { calculateActualFillerZone } from '../helpers/area-calculation';
import { IguValidationData } from '@/shared/elements/IguSelect/IguSelect';
import { GlassTypeValue, IGU_ERRORS, IguLimitations } from '@/models/webcalc';
import { inRange } from 'lodash';
import { useContext, useMemo } from 'react';
import { ProjectConfigContext } from '@/routes/dashboard/projects/project/ProjectConfigProvider';

const useUnitIguValidation = () => {
  const configProviderValue = useContext(ProjectConfigContext)!;
  const { IGUData, config } = configProviderValue;

  const minMaxLimitsIGU: IguLimitations = useMemo(() => {
    if (!IGUData) return;
    return {
      maxRatio: Math.max(...IGUData.map((igu) => igu.limitations.maxRatio)),
      minArea: Math.min(...IGUData.map((igu) => igu.limitations.minArea)),
      maxArea: Math.max(...IGUData.map((igu) => igu.limitations.maxArea)),
      minWidth: Math.min(...IGUData.map((igu) => igu.limitations.minWidth)),
      maxWidth: Math.max(...IGUData.map((igu) => igu.limitations.maxWidth)),
      minHeight: Math.min(...IGUData.map((igu) => igu.limitations.minHeight)),
      maxHeight: Math.max(...IGUData.map((igu) => igu.limitations.maxHeight)),
    };
  }, [IGUData])!;

  const panelsConfig = config.panel;
  const limitations = config.limitations;

  const validateFillerMenuOptions = (
    fillerData: UnitInnerFrame,
    framePoints: FlatVector2[]
  ) => {
    const validationDataForGlassFrame = collectUnitIguValidationData({
      fillerData: {
        ...fillerData,
        fillerType: FillerType.Glass,
      },
      framePoints,
    });

    const validationDataForOperableFrame = collectUnitIguValidationData({
      fillerData: {
        ...fillerData,
        fillerType: FillerType.OperableWindow,
      },
      framePoints,
    });
    const glassFrameErrors = validateIguFiller(
      validationDataForGlassFrame,
      minMaxLimitsIGU
    );
    const operableFrameErrors = validateIguFiller(
      validationDataForOperableFrame,
      minMaxLimitsIGU
    );

    return {
      fixedGlassIsValid: glassFrameErrors.length === 0,
      operableGlassIsValid: operableFrameErrors.length === 0,
    };
  };

  const calculateGlassPointsForValidation = (
    fillerContourPoints: FlatVector2Axis[],
    fillerType: FillerType
  ) => {
    let realWindowGlassPoints: FlatVector2[] = [];
    if (
      fillerType === FillerType.Glass ||
      fillerType === FillerType.EnamelGlass
    ) {
      realWindowGlassPoints = fillerContourPoints.map((p, i) =>
        calculateRealFillerGlassPoints(
          p[0],
          i,
          panelsConfig.glass.insertedFixed
        )
      );
    } else if (fillerType === FillerType.OperableWindow) {
      const operableBorderThickness = getOperableFrameWidth(panelsConfig);
      const visibleGlassPoints = fillerContourPoints.map((p, i) =>
        generateInnerFramePoints(p, i, operableBorderThickness)
      );
      realWindowGlassPoints = visibleGlassPoints.map((p, i) =>
        calculateRealFillerGlassPoints(
          p[1],
          i,
          panelsConfig.glass.insertedOperable
        )
      );
    }
    return realWindowGlassPoints;
  };

  const collectUnitIguValidationData = ({
    fillerData,
    framePoints,
    glassType,
  }: {
    fillerData: UnitInnerFrame;
    framePoints: FlatVector2[];
    glassType?: GlassTypeValue;
  }): IguValidationData => {
    const fillerContourPoints = calculateActualFillerZone(
      fillerData.points,
      framePoints,
      panelsConfig
    );
    const realWindowGlassPoints = calculateGlassPointsForValidation(
      fillerContourPoints,
      fillerData.fillerType
    );

    const horizontalGlassLine = [
      realWindowGlassPoints[0],
      realWindowGlassPoints[1],
    ];

    const verticalGlassLine = [
      realWindowGlassPoints[3],
      realWindowGlassPoints[0],
    ];

    const glassHeight = get2DDistance(
      verticalGlassLine[0],
      verticalGlassLine[1]
    );
    const glassWidth = get2DDistance(
      horizontalGlassLine[0],
      horizontalGlassLine[1]
    );
    const ratio = calculateGlassRatio(glassHeight, glassWidth);
    const windowArea = Number(
      convertSquareMillimetersToSquareMeters(glassHeight * glassWidth)
    );

    const unitHeight = get2DDistance(framePoints[1], framePoints[2]);

    const isBottomBorderIsOnFrame =
      fillerData.points[2][1] === framePoints[2][1] ||
      fillerData.points[3][1] === framePoints[3][1];
    const distanceToFloor = isBottomBorderIsOnFrame
      ? panelsConfig.bottomWidth / 2 + panelsConfig.verticalOffset / 2
      : roundKonvaValue(
          framePoints[2][1] +
            panelsConfig.mullion.width / 2 -
            fillerData.points[2][1] +
            panelsConfig.verticalOffset / 2
        );
    const isTopBorderIsOnFrame =
      fillerData.points[0][1] === framePoints[0][1] ||
      fillerData.points[1][1] === framePoints[1][1];
    const topBorderLevel = isTopBorderIsOnFrame
      ? unitHeight - panelsConfig.topWidth / 2 + panelsConfig.verticalOffset / 2
      : roundKonvaValue(
          framePoints[3][1] +
            panelsConfig.verticalOffset / 2 -
            fillerData.points[0][1] -
            panelsConfig.mullion.width / 2
        );

    return {
      isOperableType: fillerData.fillerType === FillerType.OperableWindow,
      glassType,
      glassHeight,
      glassWidth,
      ratio,
      windowArea,
      topBorderLevel,
      distanceToFloor,
    };
  };

  const validateIguFiller = (
    validationData: IguValidationData,
    limits: IguLimitations,
    iguThickness?: number,
    isImperialUnits: boolean = false
  ) => {
    const ratioError = validateGlassRatio(
      validationData.glassHeight,
      validationData.glassWidth,
      limits.maxRatio
    );

    const windowArea = Number(
      convertSquareMillimetersToSquareMeters(
        validationData.glassHeight * validationData.glassWidth
      )
    );

    const temperedGlassError =
      validationData.glassType &&
      validationData.glassType !== GlassTypeValue.T &&
      validationData.distanceToFloor <
        limitations.temperedGlassUnit.bottomHeight &&
      validationData.topBorderLevel > limitations.temperedGlassUnit.topHeight &&
      windowArea >
        Number(
          convertSquareMillimetersToSquareMeters(
            limitations.temperedGlassUnit.area
          )
        );

    const thicknessError =
      iguThickness &&
      !validationData.isOperableType &&
      !inRange(
        iguThickness,
        limitations.iguThickness.min,
        limitations.iguThickness.max
      );

    return [
      ratioError ? IGU_ERRORS.maxRatioError : [],
      windowArea < limits.minArea ? IGU_ERRORS.minAreaError : [],
      windowArea > limits.maxArea ? IGU_ERRORS.maxAreaError : [],
      validationData.glassWidth < limits.minWidth
        ? IGU_ERRORS.minWidthError
        : [],
      validationData.glassWidth > limits.maxWidth
        ? IGU_ERRORS.maxWidthError
        : [],
      validationData.glassHeight < limits.minHeight
        ? IGU_ERRORS.minHeightError
        : [],
      validationData.glassHeight > limits.maxHeight
        ? IGU_ERRORS.maxHeightError
        : [],
      temperedGlassError ? IGU_ERRORS.temperedGlassError : [],
      thicknessError
        ? IGU_ERRORS.thicknessError(
            limitations.iguThickness.min,
            limitations.iguThickness.max,
            isImperialUnits
          )
        : [],
    ].flat();
  };

  return {
    validateIguFiller,
    collectUnitIguValidationData,
    validateFillerMenuOptions,
  };
};

export default useUnitIguValidation;
