import React, { useEffect, useMemo, useState } from 'react';
import { NodeType, SelectedNodeSource, UserBuildingSurface } from '@/models';
import { C_FloorMaterial, C_SurfaceSelectedMaterial } from '@/shared/materials';
import {
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
  updateGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { GenericChildSurface } from '@/models/building-nodes.model';
import { useUnmount } from 'react-use';
import { BufferGeometry } from 'three';

interface FloorProps extends GenericChildSurface {
  data: UserBuildingSurface;
  isLowestFloor?: boolean;
  blockGUID: string;
  isCeiling?: boolean;
}

const Surface: React.FC<FloorProps> = ({
  data,
  isParentSelected,
  isParentLocked,
  blockGUID,
  isCeiling,
}) => {
  const { points } = data;
  const coordinates = useMemo(
    () => convertFlatVector3ToVectors(points),
    [data]
  );
  const [surfaceGeometry, setSurfaceGeometry] = useState<BufferGeometry>();

  const surfaceMaterial = useMemo(() => {
    return isParentSelected
      ? C_SurfaceSelectedMaterial.clone()
      : C_FloorMaterial.clone();
  }, [isParentSelected]);

  const userData = {
    userData: {
      ...data.userData,
      guid: blockGUID,
      isLocked: isParentLocked,
      nodeType: NodeType.Block,
      source: SelectedNodeSource.Viewer,
      originalBuildingBlock: {
        guid: blockGUID,
      },
    },
  };

  useEffect(() => {
    if (!surfaceGeometry) {
      setSurfaceGeometry(
        createGeometryFromVectorList(coordinates, 'horizontal')
      );
    } else {
      updateGeometryFromVectorList(surfaceGeometry, coordinates, 'horizontal');
    }
  }, [points, surfaceGeometry]);

  useUnmount(() => {
    surfaceGeometry?.dispose();
    surfaceMaterial.dispose();
  });

  return (
    <>
      {surfaceGeometry && (
        <mesh
          geometry={surfaceGeometry}
          material={surfaceMaterial}
          onPointerEnter={(e) => e.stopPropagation()}
          onPointerDown={(e) => e.stopPropagation()}
          {...(isCeiling ? userData : {})}
        />
      )}
    </>
  );
};

export default Surface;
