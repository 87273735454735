import React, { useEffect, useMemo, useState } from 'react';
import { NodeType, UserBuildingBlock } from '@/models';
import ExtrudeDotHandler, {
  EXTRUDE_DOT_MARGIN,
  ExtrudeHandlerData,
} from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeDotHandler';
import * as THREE from 'three';
import Storey from '@/routes/dashboard/projects/project/UserBuilding/components/Storey';
import { useExtrudeHandlers } from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/useExtrudeHandlers';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
  getEdgePoints,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { C_WallMaterial } from '@/shared/materials';
import Border from '@/routes/dashboard/projects/project/UserBuilding/components/Border';

import ExtrudeStorey from '@/routes/dashboard/projects/project/UserBuilding/components/ExtrudeTool/ExtrudeStorey';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { useUnmount } from 'react-use';
import { getTranslatedVector } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import BlockSnapSkeleton from '@/shared/components/BlockSnapSkeleton/BlockSnapSkeleton';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import ResizeCursor from '@/images/ResizeCursor.svg';
import { Vector3 } from 'three';
import { compact, isEqual } from 'lodash';

interface ExtrudeBlockProps {
  block: UserBuildingBlock;
  extrudeData: ExtrudeHandlerData;
  buildingGuid: string;
}

const ExtrudeTool: React.FC<ExtrudeBlockProps> = ({
  block,
  extrudeData,
  buildingGuid,
}) => {
  const [snapPosition, setSnapPosition] = useState<THREE.Vector3>();

  const {
    maxNegativeDistance,
    maxPositiveDistance,
    extrudeHandlerPosition,
    mousePosition,
    handleMaxPositiveDistance,
    handleMaxNegativeDistance,
    setExtrudeHandlerPosition,
  } = useExtrudeHandlers({ mouseTrackingYPos: extrudeData.defaultCenter[1] });
  const { findDataForWall } = useFindNodeData();

  const staticStoreys = useMemo(() => {
    return block.storeys.filter(
      (storey) =>
        !storey.walls.find((wall) =>
          extrudeData.facadeWallsGuids?.some(
            (facadeWall) => facadeWall === wall.guid
          )
        )
    );
  }, [block, extrudeData]);

  const generateStaticWall = (points: THREE.Vector3[], key?: string) => {
    const geometry = createGeometryFromVectorList(
      [...points, points[0]],
      'vertical'
    );

    const material = C_WallMaterial.clone();
    return (
      <mesh
        geometry={geometry}
        material={material}
        key={key}
        onPointerOver={(e) => e.stopPropagation()}
      >
        <Border geometry={geometry} />
      </mesh>
    );
  };

  const wallsAffectedByExtrude = useMemo(() => {
    const affectedWallsGuids = compact(
      block.storeys.flatMap((storey) =>
        storey.walls.flatMap((wall, i, walls) => {
          const edgePoints = getEdgePoints(storey);
          const isIncludedInExtrude = extrudeData.facadeWallsGuids.some(
            (guid) => guid === wall.guid
          );
          if (isIncludedInExtrude) {
            const isNextWallNotFlat = edgePoints.some((point) =>
              isEqual(wall.points[1], point)
            );
            const isPrevWallNotFlat = edgePoints.some((point) =>
              isEqual(wall.points[0], point)
            );
            const isLastIndex = i === walls.length - 1;
            return [
              wall.guid,
              isNextWallNotFlat
                ? walls[!isLastIndex ? i + 1 : 0].guid
                : undefined,
              isPrevWallNotFlat
                ? walls[i === 0 ? walls.length - 1 : i - 1].guid
                : undefined,
            ];
          }
          return [];
        })
      )
    );
    return new Set<string>(affectedWallsGuids);
  }, [block]);

  const staticWalls = useMemo(() => {
    const walls = block.storeys
      .map((storey) =>
        staticStoreys.some((staticStorey) => staticStorey.guid === storey.guid)
          ? []
          : storey.walls.filter(
              (wall) =>
                !wallsAffectedByExtrude.has(wall.guid) &&
                (extrudeData?.facadeWallsGuids
                  ? !extrudeData?.facadeWallsGuids?.includes(wall.guid)
                  : true)
            )
      )
      .flat(1);

    return walls.map((wall) =>
      generateStaticWall(convertFlatVector3ToVectors(wall.points), wall.guid)
    );
  }, [block, staticStoreys, wallsAffectedByExtrude.size]);

  const wallsByStorey = useMemo(() => {
    return extrudeData.facadeWallsGuids?.reduce(
      (acc: { [key: string]: string[] }, curr) => {
        const storeyGuid = findDataForWall(curr)?.getParentNode(
          NodeType.Storey
        )?.guid;
        if (!storeyGuid) return acc;

        if (acc[storeyGuid]) {
          return { ...acc, [storeyGuid]: [...acc[storeyGuid], curr] };
        }

        return { ...acc, [storeyGuid]: [curr] };
      },
      {}
    );
  }, [extrudeData.facadeWallsGuids, block]);

  const handleExtrudeHandlerPosition = (distance: number) => {
    if (!extrudeHandlerPosition) return;
    const translatedVector = getTranslatedVector(
      convertFlatVector3ToVector(extrudeData.defaultCenter),
      -EXTRUDE_DOT_MARGIN - distance,
      convertFlatVector3ToVector(extrudeData.perpendicularDirection)
    );
    setExtrudeHandlerPosition(translatedVector);
  };

  const handleSnapIntersection = (point: Vector3) => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    canvas.style.cursor = 'unset';
    setSnapPosition(point);
  };

  const handleSnapIntersectionStop = () => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    canvas.style.cursor = `url('${ResizeCursor}'), auto`;
    setSnapPosition(undefined);
  };

  useEffect(() => {
    if (!extrudeHandlerPosition) {
      setExtrudeHandlerPosition(
        convertFlatVector3ToVector(extrudeData.defaultCenter)
      );
    }
  }, [extrudeData]);

  useUnmount(() => {
    staticWalls.forEach((staticWall) => staticWall.props.geometry.dispose());
  });

  return (
    <>
      <BlockSnapSkeleton
        block={block}
        ignoredWallGuids={[
          ...Array.from(wallsAffectedByExtrude),
          ...extrudeData.facadeWallsGuids,
        ]}
        onSnapIntersection={handleSnapIntersection}
        onSnapIntersectionStop={handleSnapIntersectionStop}
      />
      {staticStoreys.map((storey) => {
        return (
          <Storey
            storey={storey}
            isolateMode={false}
            isProjectLocked={false}
            buildingGUID={buildingGuid}
            blockGUID={block.guid}
            multiplyRate={0}
            isDesignerMode={false}
            isEditToolsAvailable={false}
            isParentEdited={false}
            isParentHovered={false}
            isParentLocked={false}
            isParentSelected={false}
            isSingleNodeIsolated={false}
            isSingleNodeSelected={false}
            isWindowCardDragging={false}
            key={storey.guid}
          />
        );
      })}
      {staticWalls.map((wall) => wall)}
      {wallsByStorey &&
        Object.keys(wallsByStorey).length > 0 &&
        Object.keys(wallsByStorey).map((storeyGuid, i, arr) => (
          <ExtrudeStorey
            extrudeData={extrudeData}
            storeyGuid={storeyGuid}
            key={storeyGuid}
            blockGuid={block.guid}
            buildingGuid={buildingGuid}
            facadeWallsGuids={wallsByStorey[storeyGuid]}
            wallsAffectedByExtrude={wallsAffectedByExtrude}
            maxNegativeDistance={maxNegativeDistance}
            maxPositiveDistance={maxPositiveDistance}
            setMaxPositiveDistance={handleMaxPositiveDistance}
            setMaxNegativeDistance={handleMaxNegativeDistance}
            handleExtrudeHandlerPosition={
              i === arr.length - 1 ? handleExtrudeHandlerPosition : undefined
            }
            cursorPosition={snapPosition ?? mousePosition}
            allowSave={i === arr.length - 1}
          />
        ))}
      <ExtrudeDotHandler
        extrudeHandlerData={extrudeData}
        active
        position={extrudeHandlerPosition?.setY(extrudeData.defaultCenter[1])}
      />
    </>
  );
};

export default ExtrudeTool;
