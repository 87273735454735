import React from 'react';
import { round } from 'mathjs';

import { SvgElement, SvgUnit, SvgUnitNumber } from '../';
import { Path, Rect, Gradients } from '../../shared';

import { Background, FacadeData, LineColor } from '../../models';
import { LINE_WIDTH, OPACITY, TAKE_OFF_SCALE } from '../../constants';

const SvgFacade: React.FC<FacadeData> = ({ ...props }) => {
  const { walls, wallsTotalHeight, wallsTotalWidth } = props;

  return (
    <SvgElement
      scale={TAKE_OFF_SCALE}
      width={wallsTotalWidth}
      height={wallsTotalHeight}
    >
      <Gradients />
      {walls.map((wall, index) => {
        const offsetY = round(wall.wallHeight * index, 2);

        return (
          <React.Fragment key={index}>
            <Rect
              width={wall.wallWidth}
              height={wall.wallHeight}
              stroke={LineColor.DEFAULT}
              strokeWidth={LINE_WIDTH}
              fill={Background.DEFAULT}
              x={wall.wallOffset.x}
              y={offsetY}
            />
            {wall.wallUnits.map((unit, index) => {
              return (
                <SvgElement
                  id={unit.id}
                  key={index}
                  x={round(unit.offsetFromLeftEdge + wall.wallOffset.x, 2)}
                  y={offsetY}
                  width={unit.width}
                  height={unit.height}
                  scale={1}
                >
                  <SvgUnit {...unit} />;
                  {unit.isUnitNumbering && unit.unitNumber && (
                    <SvgUnitNumber
                      height={unit.height}
                      index={index}
                      text={
                        unit.isReport ? `P${unit.id}` : `P${unit.unitNumber}`
                      }
                    />
                  )}
                </SvgElement>
              );
            })}
            {wall.gridLines.map((gridLine, index) => {
              const offsetX = round(
                gridLine.offsetFromLeftEdge + wall.wallOffset.x,
                2
              );

              return (
                <Path
                  key={index}
                  stroke={LineColor.DEFAULT}
                  strokeWidth={LINE_WIDTH * 2}
                  points={[
                    {
                      x: offsetX,
                      y: offsetY,
                    },
                    {
                      x: offsetX,
                      y: round(offsetY + wall.wallHeight, 2),
                    },
                  ]}
                />
              );
            })}
            {wall.wallUnitErrors.map((unitError, index) => {
              const offsetX = round(
                unitError.offsetFromLeftEdge + wall.wallOffset.x,
                2
              );

              return (
                <Rect
                  key={index}
                  fill={Background.ERROR}
                  opacity={OPACITY}
                  x={offsetX}
                  y={offsetY}
                  width={unitError.width}
                  height={wall.wallHeight}
                />
              );
            })}
          </React.Fragment>
        );
      })}
    </SvgElement>
  );
};

export default SvgFacade;
