import React from 'react';
import { FlatVector3, PanelPlacementData } from '@/models';
import ViewerPanel from './ViewerPanel';
import { WallUserData } from '@/routes/dashboard/projects/project/UserBuilding/components/Wall';

export interface ViewerPanelProps {
  panels: PanelPlacementData[];
  multiplyRate: number;
  wallUserData: WallUserData;
  wallPoints: FlatVector3[];
}

const ViewerPanels: React.FC<ViewerPanelProps> = ({
  panels,
  wallUserData,
  multiplyRate,
  wallPoints,
}) => {
  return (
    <>
      {panels.map((panel, i) => (
        <ViewerPanel
          scale={multiplyRate}
          placementData={panel}
          wallUserData={wallUserData}
          wallPoints={wallPoints}
          key={`placed_panel-${wallUserData.guid}-${i}`}
        />
      ))}
    </>
  );
};

export default ViewerPanels;
