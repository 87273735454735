import { MINIMAL_DISTANCE_BETWEEN_BUILDING_CORNER } from '@/components/FacadeDesigner/hooks';

export const GRID_LINE_CROSS_CORNER =
  'The grid line crosses the corner UCW unit zone';

export const GRID_LINE_CROSS_WINDOW = 'The grid line crosses the window';
export const WINDOW_CROSS_GRID_LINE = 'The window crosses the grid line';
export const WINDOW_CROSS_WINDOW = 'The windows overlap';
export const MULLION_CROSS_SAFE_AREA = 'Mullion intersects safe area';
export const WINDOW_NEAR_BUILDING_CORNER = `Distance from window draft to building corner is less than ${MINIMAL_DISTANCE_BETWEEN_BUILDING_CORNER}mm`;

export const ALLOWED_ERRORS_TO_UPDATE_INPUT = [
  GRID_LINE_CROSS_CORNER,
  GRID_LINE_CROSS_WINDOW,
  WINDOW_CROSS_WINDOW,
  WINDOW_CROSS_GRID_LINE,
  MULLION_CROSS_SAFE_AREA,
  WINDOW_NEAR_BUILDING_CORNER,
];
