import React from 'react';

import { REPORT_BACKGROUND_COLOR } from '@/shared/constants';
import { TextColor } from '@/models';
import { TEXT_SIZE_UNIT } from '../../constants';

interface SvgUnitNumber {
  height: number;
  text: string;
  index: number;
}

const SvgUnitNumber: React.FC<SvgUnitNumber> = ({ height, index, text }) => {
  const isEven = index % 2 === 0;
  const heightLabel = 180;
  const offsetY = 45;

  return (
    <>
      <rect
        x={-10}
        y={isEven ? height - heightLabel : 0}
        width={text.length * 110}
        height={heightLabel}
        fill={REPORT_BACKGROUND_COLOR}
      />
      <text
        fill={TextColor.GRAY}
        fontSize={TEXT_SIZE_UNIT}
        x={15}
        y={isEven ? height - offsetY : heightLabel - offsetY}
      >
        {text}
      </text>
    </>
  );
};

export default SvgUnitNumber;
