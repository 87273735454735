import React from 'react';
import { UserBuildingPanel } from '@/models';
import IntusTable from '@/shared/elements/IntusTable/IntusTable';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import { chunk } from 'lodash';
import ReportWrapper from '@/components/Report/Pages/ReportWrapper';
import { getGroupedInnerFrameIguConfiguration } from '@/components/Report/helpers/report-panels-helper';
import { ROWS_AMOUNT_AT_ONE_PAGE } from '@/components/Report/Pages/ReportPanelPages/ReportPanelPage';

const IguConfigurationPage = ({
  panelData,
  creationDate,
  panelIndex,
  totalPages,
  currentPageIndex,
}: {
  panelData: UserBuildingPanel;
  currentPageIndex: number;
  totalPages: number;
  panelIndex: number;
  creationDate: string;
}) => {
  const IGUData = useFetchIguQuery().data!;

  const dataSource = getGroupedInnerFrameIguConfiguration(panelData)
    .map((frame) => ({
      key: `frame-${panelData.id}_${frame.points.toString()}_${frame.iguId}_igu`,
      iguFramesNames: frame.names,
      iguName: IGUData.find((igu) => igu.id === frame.iguId)?.name ?? '-',
    }))
    .filter((data) => data.iguName !== '-');

  const columns = [
    {
      key: `igu-configuration-column-${panelData.id}`,
      title: '',
      dataIndex: 'iguFramesNames',
      rowScope: 'row' as const, //   For some reason without it the engine throws an error Type 'string' is not assignable to type 'RowScopeType | undefined'
      width: '65px',
    },
    {
      key: `igu-configuration-column-${panelData.id}__value`,
      title: '',
      dataIndex: 'iguName',
      width: '610px',
    },
  ];
  if (dataSource.length === 0) return null;

  const chunkedDataSource = chunk(dataSource, ROWS_AMOUNT_AT_ONE_PAGE);

  return (
    <>
      {chunkedDataSource.map((dataSource, i) => {
        return (
          <ReportWrapper
            key={`${panelData.id}_iguTable_${i}`}
            pageIndex={currentPageIndex}
            countOfPages={totalPages}
            reportCreationDate={creationDate}
            pageName="igu configuration"
          >
            <div className="h-full w-full flex flex-col">
              <div className="px-3 py-1 flex gap-6 text-[11px] leading-4 bg-light-gray-10 border-0 border-b border-solid border-light-gray-20">
                <div className="text-light-green-100">
                  # {panelIndex + 1 + i / 10}
                </div>
                <div className="to-dark-gray-100">P {panelData.id}</div>
                <div className="to-dark-gray-100">
                  {panelData.cornerSide
                    ? 'Corner UCW Unit'
                    : 'Straight UCW Unit'}
                </div>
              </div>
              <div className="flex flex-col px-3">
                <div>
                  <div
                    className={
                      'uppercase font-medium text-[10px] leading-4 mb-2 mt-3'
                    }
                  >
                    igu configuration
                  </div>
                  <IntusTable
                    dataSource={dataSource}
                    columns={columns}
                    bordered={false}
                    pagination={false}
                    showHeader={false}
                  />
                </div>
              </div>
            </div>
          </ReportWrapper>
        );
      })}
    </>
  );
};

export default IguConfigurationPage;
