import { FlatVector2 } from '@/models';
import {
  INITIAL_UTC_SCALE,
  MEASUREMENT_PADDING,
  STAGE_BOTTOM_PADDING_TO_FLOOR_0,
  STAGE_HEIGHT,
  STAGE_WIDTH,
} from '@/components/WindowCreator/constants';
import { roundKonvaValue } from '@/shared/helpers/konva';
import {
  PanelConfig,
  BaseUnit,
  WindowConfigurator,
} from '@/models/window-configurator.model';
import { FlatVector2Axis } from '@/components/WindowCreator/models';
import {
  isHorizontal,
  isPointOnContourPoints,
  mirrorPointAcrossX,
} from '@/components/WindowCreator/helpers/direction.helpers';
import { FillerType, UnitInnerFrame, UTCData } from '@/components/UTC/models';
import {
  mirrorFlatVector2Axis,
  mirrorOperationType,
  swapDiagonalCorners,
} from '@/components/WindowCreator/helpers';
import { EDGE_POSITION } from '@/components/WindowCreator/elements/creator-windows.helpers';

export const generateDefaultUnit = (
  width: number,
  height: number
): FlatVector2[] => [
  [
    roundKonvaValue((STAGE_WIDTH / INITIAL_UTC_SCALE - width) / 2),
    roundKonvaValue(
      (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_UTC_SCALE -
        height
    ),
  ],
  [
    roundKonvaValue((STAGE_WIDTH / INITIAL_UTC_SCALE + width) / 2),
    roundKonvaValue(
      (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_UTC_SCALE -
        height
    ),
  ],
  [
    roundKonvaValue((STAGE_WIDTH / INITIAL_UTC_SCALE + width) / 2),
    roundKonvaValue(
      (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_UTC_SCALE
    ),
  ],
  [
    roundKonvaValue((STAGE_WIDTH / INITIAL_UTC_SCALE - width) / 2),
    roundKonvaValue(
      (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_UTC_SCALE
    ),
  ],
];

export const generateUTCBorders = (
  points: FlatVector2[],
  config: PanelConfig
): FlatVector2[][] => {
  const topBorder: FlatVector2[] = [
    [points[0][0], points[0][1]],
    [points[1][0], points[1][1]],
    [points[1][0], points[1][1] + config.topWidth],
    [points[0][0], points[0][1] + config.topWidth],
  ];
  const rightBorder: FlatVector2[] = [
    [points[1][0], points[1][1] + config.topWidth],
    [points[2][0], points[2][1] - config.bottomWidth],
    [points[1][0] - config.sideWidth, points[2][1] - config.bottomWidth],
    [points[1][0] - config.sideWidth, points[1][1] + config.topWidth],
  ];

  const bottomBorder: FlatVector2[] = [
    [points[2][0], points[2][1]],
    [points[3][0], points[3][1]],
    [points[3][0], points[3][1] - config.bottomWidth],
    [points[2][0], points[2][1] - config.bottomWidth],
  ];

  const leftBorder: FlatVector2[] = [
    [points[3][0], points[3][1] - config.bottomWidth],
    [points[0][0], points[0][1] + config.topWidth],
    [points[0][0] + config.sideWidth, points[0][1] + config.topWidth],
    [points[3][0] + config.sideWidth, points[3][1] - config.bottomWidth],
  ];

  return [topBorder, rightBorder, bottomBorder, leftBorder];
};

export const generateUnitMeasurements = (
  points: FlatVector2[]
): [FlatVector2Axis, FlatVector2Axis] => {
  return [
    [
      [points[0][0], points[0][1]],
      [points[1][0], points[1][1]],
    ],
    [
      [points[1][0], points[1][1]],
      [points[2][0], points[2][1]],
    ],
  ];
};

export const generateWidthMeasurements = (
  points: FlatVector2[],
  offset: number
): FlatVector2Axis => [
  [points[0][0] - offset, points[0][1]],
  [points[1][0] + offset, points[1][1]],
];

export const generateHeightMeasurements = (
  points: FlatVector2[],
  offset: number
): FlatVector2Axis => [
  [points[1][0], points[1][1] - offset],
  [points[2][0], points[2][1] + offset],
];

export const generatePointsForWidthMeasurementsLines = ({
  points,
  scale,
  currentLevel,
  config,
}: {
  points: FlatVector2[];
  scale: number;
  currentLevel: number;
  config: WindowConfigurator;
}): FlatVector2[][] => {
  const horizontalOffset = config.panel.horizontalOffset / 2;
  const verticalOffset = config.panel.verticalOffset / 2;
  return [
    [
      [
        points[0][0] - horizontalOffset,
        points[0][1] - (currentLevel * MEASUREMENT_PADDING) / scale,
      ],
      [points[3][0] - horizontalOffset, points[3][1] + verticalOffset],
    ],
    [
      [
        points[1][0] + horizontalOffset,
        points[1][1] - (currentLevel * MEASUREMENT_PADDING) / scale,
      ],
      [points[2][0] + horizontalOffset, points[2][1] + verticalOffset],
    ],
  ];
};

export const generateSplitFrames = (
  axis: FlatVector2Axis,
  existingFrame: UnitInnerFrame
): UnitInnerFrame[] => {
  const isAxisHorizontal = isHorizontal(axis);
  let frameContours: FlatVector2[][];
  const contour = existingFrame.points;

  if (isAxisHorizontal) {
    frameContours = [
      [contour[0], contour[1], axis[1], axis[0]],
      [axis[0], axis[1], contour[2], contour[3]],
    ];
  } else {
    frameContours = [
      [contour[0], axis[0], axis[1], contour[3]],
      [axis[0], contour[1], contour[2], axis[1]],
    ];
  }

  return frameContours.map(
    (frameContour): UnitInnerFrame => ({
      ...existingFrame,
      points: frameContour,
    })
  );
};

export const generateDefaultFrame = (
  unitPoints: FlatVector2[],
  config: BaseUnit
): UnitInnerFrame => {
  return {
    points: unitPoints,
    fillerType: FillerType.Aluminium,
    outsideColor: config.outsideColor,
    insideColor: config.insideColor,
    number: '1.1',
    operableWindowType: null,
  };
};

export const generateUTCMullion = (
  axis: FlatVector2Axis,
  framePoints: FlatVector2[],
  config: PanelConfig
) => {
  const fistPointOnContour = isPointOnContourPoints(axis[0], framePoints);
  const secondPointOnContour = isPointOnContourPoints(axis[1], framePoints);
  const updatedAxis: FlatVector2Axis = isHorizontal(axis)
    ? [
        [
          fistPointOnContour
            ? axis[0][0] + config.sideWidth
            : roundKonvaValue(axis[0][0] + config.mullion.width / 2),
          axis[0][1],
        ],
        [
          secondPointOnContour
            ? axis[1][0] - config.sideWidth
            : roundKonvaValue(axis[1][0] - config.mullion.width / 2),
          axis[1][1],
        ],
      ]
    : [
        [
          axis[0][0],
          fistPointOnContour
            ? axis[0][1] + config.topWidth
            : roundKonvaValue(axis[0][1] + config.mullion.width / 2),
        ],
        [
          axis[1][0],
          secondPointOnContour
            ? axis[1][1] - config.bottomWidth
            : roundKonvaValue(axis[1][1] - config.mullion.width / 2),
        ],
      ];

  const points: FlatVector2[] = isHorizontal(axis)
    ? [
        [
          updatedAxis[0][0],
          roundKonvaValue(updatedAxis[0][1] - config.mullion.width / 2),
        ],
        [
          updatedAxis[1][0],
          roundKonvaValue(updatedAxis[1][1] - config.mullion.width / 2),
        ],
        [
          updatedAxis[1][0],
          roundKonvaValue(updatedAxis[0][1] + config.mullion.width / 2),
        ],
        [
          updatedAxis[0][0],
          roundKonvaValue(updatedAxis[1][1] + config.mullion.width / 2),
        ],
      ]
    : [
        [
          roundKonvaValue(updatedAxis[0][0] - config.mullion.width / 2),
          updatedAxis[0][1],
        ],
        [
          roundKonvaValue(updatedAxis[0][0] + config.mullion.width / 2),
          updatedAxis[0][1],
        ],
        [
          roundKonvaValue(updatedAxis[1][0] + config.mullion.width / 2),
          updatedAxis[1][1],
        ],
        [
          roundKonvaValue(updatedAxis[1][0] - config.mullion.width / 2),
          updatedAxis[1][1],
        ],
      ];
  return points;
};

export const generateFillerNumberPosition = ({
  fillerContour,
  isOperableWindow,
  unitConfig,
  scale,
}: {
  fillerContour: FlatVector2Axis[];
  isOperableWindow: boolean;
  unitConfig: PanelConfig;
  scale: number;
}) => {
  const [basePositionX, basePositionY] = fillerContour[3][0];
  const { sashWidth, windowFrameWidth } = unitConfig.operableFrame;
  const offsetX = isOperableWindow ? sashWidth + windowFrameWidth : 0;
  const offsetY = isOperableWindow ? -(sashWidth + windowFrameWidth) : 0;

  return {
    positionX: roundKonvaValue(basePositionX + offsetX + 9 / scale),
    positionY: roundKonvaValue(basePositionY + offsetY - 18 / scale),
  };
};

export const generateMirroredStraightUnit = (
  unitData: UTCData,
  mirrorOperationTypes: boolean = true
): UTCData => {
  const frameCenter = roundKonvaValue(
    (unitData.points[0][0] + unitData.points[1][0]) / 2
  );

  const mirroredInnerFrames: UnitInnerFrame[] = unitData.innerFrames.map(
    (innerFrame) => ({
      ...innerFrame,
      operableWindowType:
        mirrorOperationTypes && innerFrame.operableWindowType
          ? mirrorOperationType(innerFrame.operableWindowType)
          : innerFrame.operableWindowType,
      points: swapDiagonalCorners(
        innerFrame.points.map((point) => mirrorPointAcrossX(point, frameCenter))
      ),
    })
  );

  const mirroredMullions = unitData.mullions.map((mullion) => ({
    ...mullion,
    points: mirrorFlatVector2Axis(mullion.points, frameCenter),
  }));

  return {
    ...unitData,
    innerFrames: mirroredInnerFrames,
    mullions: mirroredMullions,
  };
};

export const calculateRealFillerGlassPoints = (
  points: FlatVector2,
  index: number,
  offset: number
): FlatVector2 => {
  let borderPoints: FlatVector2 = [0, 0];

  switch (index) {
    case EDGE_POSITION.TOP: {
      borderPoints = [points[0] - offset, points[1] - offset];
      break;
    }

    case EDGE_POSITION.RIGHT: {
      borderPoints = [points[0] + offset, points[1] - offset];
      break;
    }

    case EDGE_POSITION.BOTTOM: {
      borderPoints = [points[0] + offset, points[1] + offset];
      break;
    }

    case EDGE_POSITION.LEFT: {
      borderPoints = [points[0] - offset, points[1] + offset];
      break;
    }
  }
  return borderPoints;
};
