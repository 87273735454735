import React from 'react';
import Button, { ButtonProps } from 'antd/es/button';
import './AntdIconButton.scss';
import { IntusTooltip } from '@/shared/elements';

interface IntusIconButtonProps extends ButtonProps {
  isActive?: boolean;
  simplified?: boolean;
  tooltipText?: string;
  tooltipSubText?: string;
  transparent?: boolean;
}
const IntusIconButton: React.FC<IntusIconButtonProps> = ({
  icon,
  isActive = false,
  simplified = false,
  transparent = false,
  tooltipText,
  tooltipSubText,
  className,
  ...rest
}) => {
  const tooltip = tooltipText ? (
    <>
      <div>{tooltipText}</div>
      {tooltipSubText && (
        <div className="text-xs text-light-gray-100">{tooltipSubText}</div>
      )}
    </>
  ) : (
    ''
  );
  return (
    <IntusTooltip title={tooltip} placement={'top'}>
      <Button
        className={
          `
        ${isActive ? 'bg-light-green-100! hover:bg-dark-green-100! text-white hover:text-white! border-0! ' : 'text-black hover:text-black! '} 
        ${simplified ? 'border-0! leading-none shadow-none! ' : ''}
        ${transparent ? 'bg-transparent! ' : ''}
        ` + className
        }
        rootClassName={'intus-icon-button'}
        {...rest}
      >
        {icon}
      </Button>
    </IntusTooltip>
  );
};

export default IntusIconButton;
