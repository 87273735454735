import {
  FlatVector2Axis,
  UTCElementType,
} from '@/components/WindowCreator/models/konva-model';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { UnitTypeCreatorModes } from '@/models/shared.model';
import { useContextMenu } from '@/shared/hooks/useWindowMenu';
import { KonvaEventObject } from 'konva/lib/Node';
import { FlatVector2 } from '@/models';
import {
  addSelectedArea,
  addUTCSelectedNode,
  getIsUTCNodeSelected,
  getUnitTypeCreatorMode,
  setSelectArea,
  setSelectedAreaForFrame,
  setUTCSelectedNode,
} from '@/store/slices/windowsReducer/UTCSlice';
import { UTCData } from '../models';
import { generateUTCBorders, generateUTCMullion } from '../helpers/generators';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { generateClosedContourPointsFromPointArray } from '@/components/WindowCreator/elements/creator-windows.helpers';

export const useUTCSelect = (
  id: number | undefined,
  elementPoints: FlatVector2[]
) => {
  const dispatch = useAppDispatch();
  const mode = useAppSelector(getUnitTypeCreatorMode);
  const config = useFetchWindowConfigQuery().data!;

  const isSelected = useAppSelector(getIsUTCNodeSelected(id));
  const isSelectionMode = mode === UnitTypeCreatorModes.Selection;

  const { handleContextMenu } = useContextMenu(isSelected);

  const selectElementInUTC = (
    type: UTCElementType,
    selectionPoints: FlatVector2Axis[],
    event?: KonvaEventObject<MouseEvent>,
    formData?: UTCData
  ) => {
    if (!isSelectionMode || !id) return;
    if (event?.evt.button !== 0) return;

    event && handleContextMenu(event);
    if (type === UTCElementType.Frame && formData) {
      const utcBorders = generateUTCBorders(formData.points, config.panel);
      const utcBordersWithCloseContour: FlatVector2Axis[][] = utcBorders.map(
        (border) => generateClosedContourPointsFromPointArray(border)
      );

      const mullions: FlatVector2Axis[][] = formData.mullions
        .map((mullion) =>
          generateUTCMullion(mullion.points, formData.points, config.panel)
        )
        .map((mullion) => generateClosedContourPointsFromPointArray(mullion));

      const commonPoints = [...utcBordersWithCloseContour, ...mullions];

      dispatch(
        setUTCSelectedNode({
          id,
          type,
          points: elementPoints,
        })
      );
      dispatch(setSelectedAreaForFrame(commonPoints));
    } else if (event.evt.shiftKey && type === UTCElementType.Filler) {
      dispatch(
        addUTCSelectedNode({
          id,
          type,
          points: elementPoints,
        })
      );
      dispatch(addSelectedArea(selectionPoints));
    } else {
      dispatch(
        setUTCSelectedNode({
          id,
          type,
          points: elementPoints,
        })
      );
      dispatch(setSelectArea(selectionPoints));
    }
  };

  return {
    selectElementInUTC,
  };
};
