import React, { useEffect, useState } from 'react';
import Form from 'antd/es/form';
import './CreateProject.scss';
import { useNavigate } from 'react-router';
import {
  CustomError,
  ProjectCreateData,
  ProjectCreateFormData,
} from '@/models';
import { DismissIcon } from '@/shared/icons';
import { useCreateProjectMutation } from '@/store/apis/projectsApi';
import {
  IntusLoader,
  IntusInput,
  IntusTextArea,
  IntusButton,
  CheckItem,
} from '@/shared/elements';
import ProjectMap from '@/routes/dashboard/projects/project/ProjectMap';
import { setFormErrorFromServer } from '@/shared/form/error-handlers';
import {
  maxLengthValidator,
  requiredValidator,
} from '@/shared/form/validators';
import { IntusSelect } from '@/shared/elements';
import { unitSystems } from '@/models';
import { useFetchUserQuery } from '@/store/apis/userApi';
import { convertBoundingBoxToCanvasCoordinates } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { BBox, distance } from '@turf/turf';
import * as THREE from 'three';
import { useSearchParams } from 'react-router-dom';
import useHandleError from '@/shared/hooks/useHandleError';

export interface CreateProjectForm extends ProjectCreateFormData {
  zoomCheck?: boolean;
  boundariesCheck?: boolean;
}

const CreateProject = () => {
  const [submitDisabled, setSubmitDisabled] = useState(true);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [form] = Form.useForm<CreateProjectForm>();
  useEffect(() => {
    const draftId = decodeURI(searchParams.get('draftId') || '');
    form.setFieldsValue({
      name: decodeURI(searchParams.get('name') || ''),
      description: decodeURI(searchParams.get('description') || ''),
      draftId: Number(draftId) || null,
    });
  }, []);
  const { handleError } = useHandleError();

  const projectName = Form.useWatch(['name'], form);
  const boundariesCheck = Form.useWatch(['boundariesCheck'], {
    form,
    preserve: true,
  });
  const zoomCheck = Form.useWatch(['zoomCheck'], { form, preserve: true });
  const boundingBox = Form.useWatch(['boundingBox'], { form, preserve: true });
  Form.useWatch(['constructionSite'], {
    form,
    preserve: true,
  });

  const [createProject, { isLoading: isCreateProjectLoading }] =
    useCreateProjectMutation();

  const settings = useFetchUserQuery().data;

  const handleDismiss = () => navigate('/dashboard/projects');

  const calculateMultiplyRate = (boundingBox: BBox) => {
    const canvasCoordinates =
      convertBoundingBoxToCanvasCoordinates(boundingBox);
    const normalMapDistance = distance(
      [boundingBox[0], boundingBox[1]],
      [boundingBox[2], boundingBox[3]],
      { units: 'meters' }
    );
    const canvasMapDistance = new THREE.Vector2(
      canvasCoordinates.min.x,
      canvasCoordinates.min.z
    ).distanceTo(
      new THREE.Vector2(canvasCoordinates.max.x, canvasCoordinates.max.z)
    );

    return canvasMapDistance / normalMapDistance;
  };

  const handleCreate = async (content: ProjectCreateFormData) => {
    try {
      const projectData: ProjectCreateData = {
        ...content,
        multiplyRate: calculateMultiplyRate(boundingBox),
      };
      const projectStructure = await createProject(projectData).unwrap();
      navigate(`/dashboard/projects/${projectStructure.id}`, {
        relative: 'path',
      });
    } catch (err) {
      setFormErrorFromServer(form, err as CustomError);
    }
  };

  const handleSubmit = async () => {
    await form.validateFields();
    try {
      await form.validateFields();
      const values: ProjectCreateFormData = form.getFieldsValue(true);
      await handleCreate(values);
    } catch (e) {
      handleError(e);
    }
  };

  const checkmarkItems = [
    {
      text: 'The proper zoom is set',
      isChecked: zoomCheck,
    },
    {
      text: 'Site boundaries are outlined',
      isChecked: boundariesCheck,
    },
    {
      text: 'Project name is filled in',
      isChecked: !!projectName,
    },
  ];

  useEffect(() => {
    setSubmitDisabled(checkmarkItems.some((item) => !item.isChecked));
  }, [checkmarkItems]);

  return (
    <div className={'flex h-full w-full overflow-hidden'}>
      <div className={'h-full w-9/12'}>
        <ProjectMap
          form={form}
          initialAddress={decodeURI(searchParams.get('address') || '')}
        />
      </div>
      <div
        className={
          'h-full w-3/12 border-solid border-x-0 border-y-0 border-l border-light-gray-20'
        }
      >
        <div
          className={
            'pl-8 py-3 pr-3.5 flex justify-between ' +
            'border-solid border-x-0 border-t-0 border-b border-light-gray-20'
          }
        >
          <span className={'font-medium'}>New project</span>
          <button
            className={'border-none bg-transparent m-0 p-0 cursor-pointer h-4'}
            onClick={handleDismiss}
          >
            <DismissIcon />
          </button>
        </div>
        <div className={'mt-6 mx-8'}>
          <Form form={form} layout={'vertical'} onFinish={handleSubmit}>
            <Form.Item
              name="name"
              rules={[
                requiredValidator('Project Name is required'),
                maxLengthValidator('Max 50 characters', 50),
              ]}
              label={'Project name'}
              className={'mb-6'}
            >
              <IntusInput />
            </Form.Item>
            <Form.Item
              name={'unitSystem'}
              className={'w-40'}
              label={'Units'}
              initialValue={settings?.unitSystem}
            >
              <IntusSelect options={unitSystems} />
            </Form.Item>
            <Form.Item
              name="description"
              label={'Project description'}
              rules={[maxLengthValidator('Max 450 characters', 450)]}
              className={'mb-6'}
            >
              <IntusTextArea rows={4} className={'rounded-lg'} />
            </Form.Item>
            <div className={'mb-6'}>
              {checkmarkItems.map((item) => (
                <CheckItem
                  text={item.text}
                  isChecked={item.isChecked}
                  key={item.text}
                />
              ))}
            </div>
            <IntusButton
              className={'w-full'}
              htmlType="submit"
              disabled={submitDisabled}
              id="create-project__submit-button"
            >
              Create project
            </IntusButton>
          </Form>
        </div>
      </div>
      {isCreateProjectLoading && <IntusLoader absolutePosition />}
    </div>
  );
};
export default CreateProject;
