import React from 'react';

import { PanelPlacementData, UserBuildingPanel } from '@/models';
import { Group, Rect } from 'react-konva';
import { Html } from 'react-konva-utils';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  addToHoveredPlacedPanels,
  getDragNode,
  getIsOnlyOnePanelSelected,
  getIsPanelHovered,
  getIsPanelSelected,
  isItemSelectedOnWall,
  isWallMeasurementActive,
  setDragCopyOffset,
  setHoveredPlacedPanels,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useFacadeDesignerSelection } from '@/components/FacadeDesigner/hooks';
import { KonvaEventObject } from 'konva/lib/Node';

import { getPanelHeight } from '@/shared/helpers';

import UTCUnitView from '@/components/UTC/components/UTCUnitView';
import { View } from '@/components/WindowCreator/models';
import { PanelConfig } from '@/models/window-configurator.model';
import { isEqual } from 'lodash';
import { publish } from '@/core/events';
import { DRAG_N_DROP_START_COPYING_EVENT } from '@/components/FacadeDesigner/elements/handlers/useDragNDropCopyHandlers';
import { roundKonvaValue } from '@/shared/helpers/konva';

interface PanelViewProps {
  panelPlacementData: PanelPlacementData;
  panelInfo: UserBuildingPanel;
  scale: number;
  wallGUID?: string;
  panelWidth: number;
  showPanelCellNumbers?: boolean;
  config: PanelConfig;
  dragCopyOffset?: number;
  wallOffset: number;
  isProjectLocked: boolean;
}

const PanelView: React.FC<PanelViewProps> = ({
  panelPlacementData,
  panelInfo,
  panelWidth,
  scale,
  wallGUID,
  config,
  showPanelCellNumbers,
  dragCopyOffset,
  wallOffset,
  isProjectLocked,
}) => {
  const dispatch = useAppDispatch();
  const dragNode = useAppSelector(getDragNode);
  const isMeasurementActiveWall = useAppSelector(isWallMeasurementActive);
  const isSelectedWall = useAppSelector(isItemSelectedOnWall(wallGUID!));
  const isOnlyOnePanelSelected = useAppSelector(getIsOnlyOnePanelSelected);

  const isHovered = panelPlacementData
    ? useAppSelector(getIsPanelHovered(panelPlacementData.guid))
    : false;
  const isSelected = panelPlacementData
    ? useAppSelector(getIsPanelSelected(panelPlacementData.guid))
    : false;

  const { handleSelectPanel, isSelectionMode } = useFacadeDesignerSelection();

  const isDragNodeActive = !!dragNode;
  const isAbleToHover = isSelectionMode && !isDragNodeActive;
  const isHidden = isSelectedWall && (dragNode || isMeasurementActiveWall);

  const handleSelect = (event: KonvaEventObject<PointerEvent>) => {
    if (!panelPlacementData || !wallGUID || !isAbleToHover || dragCopyOffset)
      return;

    handleSelectPanel({
      panelData: {
        guid: panelPlacementData.guid,
        wallGUID,
        isInitialCorner: !!panelPlacementData.isInitialCorner,
        panelId: panelInfo.id,
      },
      multiSelect: event.evt.shiftKey,
      isSelected,
    });
  };

  if (!panelInfo) return null;

  const offsetX =
    (panelPlacementData ? -panelPlacementData.offsetFromLeftEdge : 0) +
    panelInfo.points[0][0];

  const offsetY = panelInfo.points[0][1] - config.verticalOffset / 2;

  const offsetForDragCopy = roundKonvaValue(
    panelPlacementData.offsetFromLeftEdge + wallOffset
  );

  const handleHoverPanel = () => {
    if (!panelPlacementData) return;

    if (dragCopyOffset) {
      dragCopyOffset === offsetForDragCopy &&
        dispatch(
          addToHoveredPlacedPanels({
            ...panelPlacementData,
            wallGUID: wallGUID ?? '',
          })
        );
      return;
    }

    dispatch(
      setHoveredPlacedPanels({
        ...panelPlacementData,
        wallGUID: wallGUID ?? '',
      })
    );
  };

  const handleRemoveHover = () => {
    if (dragCopyOffset) return;

    dispatch(setHoveredPlacedPanels(null));
  };

  const handleStartDragCopy = () => {
    dispatch(setDragCopyOffset(offsetForDragCopy));
    publish(DRAG_N_DROP_START_COPYING_EVENT);
  };

  const showCopyDragHandle =
    isOnlyOnePanelSelected && isSelected && !isProjectLocked;

  return (
    <Group onPointerDown={handleSelect} listening={isAbleToHover}>
      {!isHidden && (
        <Group
          offsetX={offsetX}
          offsetY={offsetY}
          onPointerEnter={handleHoverPanel}
        >
          <UTCUnitView
            showPanelCellNumbers={showPanelCellNumbers}
            utcData={panelInfo}
            view={View.Outside}
            scale={scale}
          />
          {showCopyDragHandle && (
            <Html
              groupProps={{
                x: panelInfo.points[2][0] - 10 / scale / 2,
                y: panelInfo.points[2][1] - 10 / scale / 2,
                scaleX: 1 / scale,
                scaleY: 1 / scale,
              }}
            >
              <div
                onPointerDown={handleStartDragCopy}
                className={`text-white bg-[#0094FF] w-2 h-2 rounded-full border-2 cursor-crosshair
            border-white border-solid flex items-center justify-center select-none`}
              />
            </Html>
          )}
        </Group>
      )}
      {(isHovered || isSelected) && (
        <Group
          offsetX={
            panelPlacementData ? -panelPlacementData.offsetFromLeftEdge : 0
          }
          offsetY={panelPlacementData ? -config.verticalOffset / 2 : 0}
        >
          {isSelected && (
            <Rect
              width={panelWidth}
              height={getPanelHeight(panelInfo)}
              fill={'#65BD51'}
              opacity={0.2}
              onPointerLeave={handleRemoveHover}
            />
          )}
          <Rect
            width={panelWidth}
            height={getPanelHeight(panelInfo)}
            stroke={'#65BD51'}
            strokeWidth={1.2}
            strokeScaleEnabled={false}
            shadowForStrokeEnabled={false}
            onPointerLeave={handleRemoveHover}
          />
        </Group>
      )}
    </Group>
  );
};

export default React.memo(PanelView, isEqual);
