import React, { useMemo } from 'react';
import { PanelConfig, RALColors } from '@/models/window-configurator.model';
import { Group, Line } from 'react-konva';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import { UnitInnerFrame } from '@/components/UTC/models';
import { calculateActualFillerZone } from '../helpers/area-calculation';
import { FlatVector2 } from '@/models';
import { checkIsPointOnFrame } from '@/components/UTC/helpers/common';
import { get2DCenter } from '@/shared/helpers/konva';
import { generateGlazingBeadsPoints } from '@/shared/helpers';

interface UTCGlazingBeadsProps {
  innerFrames: UnitInnerFrame[];
  framePoints: FlatVector2[];
  unitConfig: PanelConfig;
  frameColor: RALColors;
}

const UTCGlazingBeads: React.FC<UTCGlazingBeadsProps> = ({
  innerFrames,
  framePoints,
  unitConfig,
  frameColor,
}) => {
  const { colors: configColors } = useFetchWindowConfigQuery().data!;

  const color = getHexByName(configColors, frameColor);
  const generateRubberFillerPoints = (
    glazingBeadsPoints: FlatVector2[][],
    framePoints: FlatVector2[],
    isBottomFrame: boolean
  ): FlatVector2[][] => {
    const checkIsBeadOnFrame = (bead: FlatVector2[]) => {
      return (
        checkIsPointOnFrame(bead[2], framePoints) &&
        checkIsPointOnFrame(bead[3], framePoints) &&
        checkIsPointOnFrame(get2DCenter(bead[2], bead[3]), framePoints)
      );
    };

    const rubberWidth = unitConfig.mullion.width - unitConfig.sideWidth * 2;
    const topBead = glazingBeadsPoints[0];
    const topRubberPoints: FlatVector2[] = [
      [topBead[0][0], topBead[0][1] - rubberWidth],
      [topBead[1][0], topBead[1][1] - rubberWidth],
      topBead[1],
      topBead[0],
    ];

    const rightBead = glazingBeadsPoints[1];
    const rightRubberPoints: FlatVector2[] = [
      [rightBead[0][0] + rubberWidth, rightBead[0][1]],
      [
        rightBead[1][0] + rubberWidth,
        rightBead[1][1] + unitConfig.horizontalOffset,
      ],
      [rightBead[1][0], rightBead[1][1] + unitConfig.horizontalOffset],
      rightBead[0],
    ];

    const bottomBead = glazingBeadsPoints[2];

    const bottomRubberPoints: FlatVector2[] = [
      [bottomBead[2][0], bottomBead[2][1] + rubberWidth],
      [bottomBead[3][0], bottomBead[3][1] + rubberWidth],
      bottomBead[3],
      bottomBead[2],
    ];
    const leftBead = glazingBeadsPoints[3];
    const leftRubberPoints: FlatVector2[] = [
      [leftBead[2][0] - rubberWidth, leftBead[2][1]],
      [
        leftBead[3][0] - rubberWidth,
        leftBead[3][1] + unitConfig.horizontalOffset,
      ],
      [leftBead[3][0], leftBead[3][1] + unitConfig.horizontalOffset],
      leftBead[2],
    ];

    const result = [];

    !checkIsBeadOnFrame(topRubberPoints) && result.push(topRubberPoints);
    !checkIsBeadOnFrame(rightRubberPoints) && result.push(rightRubberPoints);
    !checkIsBeadOnFrame(bottomRubberPoints) &&
      !isBottomFrame &&
      result.push(bottomRubberPoints);
    !checkIsBeadOnFrame(leftRubberPoints) && result.push(leftRubberPoints);

    return result;
  };

  const glazingBeadsData = useMemo(() => {
    const fillerZones = innerFrames.map((frame) =>
      calculateActualFillerZone(frame.points, framePoints, unitConfig)
    );
    return fillerZones
      .map((zone) => {
        const points = [
          //TOP LEFT
          zone[0][0],
          //TOP RIGHT
          zone[0][1],
          //BOTTOM RIGHT
          zone[1][1],
          //BOTTOM LEFT
          zone[2][1],
        ];

        const glazingBeadsPoints = generateGlazingBeadsPoints(
          framePoints[3][1],
          points,
          unitConfig
        );

        const isBottomFrame =
          framePoints[3][1] === points[3][1] + unitConfig.bottomWidth;

        const rubberFillerPoints = generateRubberFillerPoints(
          glazingBeadsPoints,
          framePoints,
          isBottomFrame
        );

        return {
          glazingBeadsPoints,
          rubberFillerPoints,
        };
      })
      .flat();
  }, [innerFrames, framePoints]);

  return (
    <Group>
      {glazingBeadsData.map((data, i) => {
        return (
          <Group key={'glazing_beads_' + i}>
            {data.rubberFillerPoints.map((points, i) => (
              <Line
                key={`rubber_${i}`}
                points={points.flat()}
                closed
                stroke={'#000'}
                fill={'#000'}
                listening={false}
              />
            ))}
            {data.glazingBeadsPoints.map((points) => (
              <Line
                points={points.flat()}
                fill={color ?? '#fff'}
                key={`glazingBeads_${i}_${points.toString()}`}
                closed
                stroke={'#000000'}
                listening={false}
              />
            ))}
          </Group>
        );
      })}
    </Group>
  );
};

export default UTCGlazingBeads;
