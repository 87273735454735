import React from 'react';
import { BaseIconProps } from '@/models';

const FilterIcon: React.FC<BaseIconProps> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={className}
    >
      <rect width="24" height="24" rx="4" fill="none" />
      <path
        d="M10 13C10.9319 13 11.715 13.6374 11.937 14.5L17.5 14.5C17.7761 14.5 18 14.7239 18 15C18 15.2455 17.8231 15.4496 17.5899 15.4919L17.5 15.5L11.9367 15.501C11.7144 16.3631 10.9316 17 10 17C9.06845 17 8.28565 16.3631 8.06327 15.501L6.5 15.5C6.22386 15.5 6 15.2761 6 15C6 14.7545 6.17688 14.5504 6.41012 14.5081L6.5 14.5L8.06301 14.5C8.28504 13.6374 9.06809 13 10 13ZM10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14ZM14 7C14.9319 7 15.715 7.63738 15.937 8.49998L17.5 8.5C17.7761 8.5 18 8.72386 18 9C18 9.24546 17.8231 9.44961 17.5899 9.49194L17.5 9.5L15.9367 9.50102C15.7144 10.3631 14.9316 11 14 11C13.0684 11 12.2856 10.3631 12.0633 9.50102L6.5 9.5C6.22386 9.5 6 9.27614 6 9C6 8.75454 6.17688 8.55039 6.41012 8.50806L6.5 8.5L12.063 8.49998C12.285 7.63738 13.0681 7 14 7ZM14 8C13.4477 8 13 8.44772 13 9C13 9.55228 13.4477 10 14 10C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8Z"
        fill="#414042"
      />
      <path
        d="M10 13C10.9319 13 11.715 13.6374 11.937 14.5L17.5 14.5C17.7761 14.5 18 14.7239 18 15C18 15.2455 17.8231 15.4496 17.5899 15.4919L17.5 15.5L11.9367 15.501C11.7144 16.3631 10.9316 17 10 17C9.06845 17 8.28565 16.3631 8.06327 15.501L6.5 15.5C6.22386 15.5 6 15.2761 6 15C6 14.7545 6.17688 14.5504 6.41012 14.5081L6.5 14.5L8.06301 14.5C8.28504 13.6374 9.06809 13 10 13ZM10 14C9.44772 14 9 14.4477 9 15C9 15.5523 9.44772 16 10 16C10.5523 16 11 15.5523 11 15C11 14.4477 10.5523 14 10 14ZM14 7C14.9319 7 15.715 7.63738 15.937 8.49998L17.5 8.5C17.7761 8.5 18 8.72386 18 9C18 9.24546 17.8231 9.44961 17.5899 9.49194L17.5 9.5L15.9367 9.50102C15.7144 10.3631 14.9316 11 14 11C13.0684 11 12.2856 10.3631 12.0633 9.50102L6.5 9.5C6.22386 9.5 6 9.27614 6 9C6 8.75454 6.17688 8.55039 6.41012 8.50806L6.5 8.5L12.063 8.49998C12.285 7.63738 13.0681 7 14 7ZM14 8C13.4477 8 13 8.44772 13 9C13 9.55228 13.4477 10 14 10C14.5523 10 15 9.55228 15 9C15 8.44772 14.5523 8 14 8Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};

export default FilterIcon;
