import React from 'react';
import { round } from 'mathjs';

import { SvgWindowEntity } from '../OperableWindows';
import { Text } from '../../shared';

import { OperableConfig, Color, Frame } from '../../models';
import { OperationType } from '@/models/window-configurator.model';
import { FlatVector2 } from '@/models';

interface SvgWindowFrameProps extends Frame {
  handleScale: number;
  color: Color;
  points: FlatVector2[];
  config: OperableConfig;
  isNumbering: boolean;
}

const SvgWindowFrame: React.FC<SvgWindowFrameProps> = ({
  color,
  points,
  config,
  operationType,
  frameNumber,
  handleScale,
  textColor,
  isNumbering,
}) => {
  const { sashWidth, outerWidth } = config;
  const offset = round(outerWidth + sashWidth, 2);

  const adjustedX =
    operationType === OperationType.Fixed
      ? points[0][0]
      : round(points[0][0] + offset, 2);
  const adjustedY =
    operationType === OperationType.Fixed
      ? points[2][1]
      : round(points[2][1] - offset, 2);

  return (
    <>
      <SvgWindowEntity
        operationType={operationType}
        operableConfig={config}
        handleScale={handleScale}
        offset={offset}
        color={color}
        points={points}
      />
      {isNumbering && (
        <Text
          x={adjustedX}
          y={adjustedY}
          text={frameNumber}
          color={textColor}
        />
      )}
    </>
  );
};

export default SvgWindowFrame;
