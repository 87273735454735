import React from 'react';
import { FlatVector2, UnitSystemTypes } from '@/models';
import { generatePointsForWidthMeasurementsLines } from '../helpers/generators';
import { Group } from 'react-konva';

import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import KonvaDashedLine from '@/shared/elements/KonvaDashedLine/KonvaDashedLine';
import UTCUnitFloorLines from '@/components/UTC/components/UTCUnitFloorLines';
import { PanelConfig } from '@/models/window-configurator.model';

const UTCAxisLines = ({
  framePoints,
  hasInnerHorizontalMeasurements,
  hasInnerVerticalMeasurements,
  panelConfig,
  scale,
  units,
}: {
  framePoints: FlatVector2[];
  hasInnerHorizontalMeasurements: boolean;
  hasInnerVerticalMeasurements: boolean;
  scale: number;
  panelConfig: PanelConfig;
  units: UnitSystemTypes;
}) => {
  const config = useFetchWindowConfigQuery().data!;

  const currentLevelOfHorizontalWidthMeasurement =
    hasInnerHorizontalMeasurements ? 3 : 2;

  const verticalAxisPoints = generatePointsForWidthMeasurementsLines({
    points: framePoints,
    config,
    scale,
    currentLevel: currentLevelOfHorizontalWidthMeasurement,
  });

  return (
    <Group listening={false}>
      {verticalAxisPoints.map((axisPoint, index) => (
        <KonvaDashedLine key={index} points={axisPoint} />
      ))}
      <UTCUnitFloorLines
        scale={scale}
        framePoints={framePoints}
        panelConfig={panelConfig}
        hasInnerVerticalMeasurements={hasInnerVerticalMeasurements}
        units={units}
      />
    </Group>
  );
};

export default UTCAxisLines;
