import React, { ReactNode } from 'react';

import { PDF_HEIGHT, PDF_WIDTH } from '@/shared/constants';

const QuoteWrapper = ({ children }: { children: ReactNode }) => {
  return (
    <div
      className={`flex justify-between bg-white`}
      style={{
        width: `${PDF_WIDTH}px`,
        height: `${PDF_HEIGHT}px`,
      }}
    >
      {children}
    </div>
  );
};

export default QuoteWrapper;
