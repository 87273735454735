import React from 'react';
import UTCFiller from '@/components/UTC/components/UTCFiller';
import UTCMullions from '@/components/UTC/components/UTCMullions';
import UTCBorders from '@/components/UTC/components/UTCBorders';
import { UTCData } from '@/components/UTC/models';
import { getNumberedInnerFrames } from '@/components/UTC/helpers/form-data';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { View } from '@/components/WindowCreator/models';
import UTCGlazingBeads from '@/components/UTC/components/UTCGlazingBeads';
import { UnitSystemTypes } from '@/models';

interface UTCUnitViewProps {
  utcData: UTCData;
  scale: number;
  view: View;
  showPanelCellNumbers?: boolean;
  units?: UnitSystemTypes;
}

const UTCUnitView = ({
  utcData,
  view,
  scale,
  showPanelCellNumbers,
  units,
}: UTCUnitViewProps) => {
  const config = useFetchWindowConfigQuery().data!;
  const numberedFillers = getNumberedInnerFrames(utcData.innerFrames);

  return (
    <>
      {numberedFillers.map((frame, index) => (
        <UTCFiller
          key={`frame_${index}`}
          fillerData={frame}
          framePoints={utcData.points}
          unitConfig={config.panel}
          view={view}
          scale={scale}
          fillerNumber={showPanelCellNumbers ? frame.number : null}
          showValidationErrors={!!showPanelCellNumbers}
          units={units}
        />
      ))}
      <UTCMullions
        framePoints={utcData.points}
        mullionData={utcData.mullions}
        color={config.baseUnit.insideColor}
      />
      <UTCBorders
        color={config.baseUnit.insideColor}
        utcData={utcData}
        unitConfig={config.panel}
      />
      {view === View.Outside && (
        <UTCGlazingBeads
          innerFrames={utcData.innerFrames}
          framePoints={utcData.points}
          unitConfig={config.panel}
          frameColor={utcData.outsideColor}
        />
      )}
    </>
  );
};

export default UTCUnitView;
