import { Group, Path } from 'react-konva';
import React from 'react';
import {
  STATIC_SCALE,
  INITIAL_SCALE,
  MAN_CONTOUR_PARAMS,
  STAGE_BOTTOM_PADDING_TO_FLOOR_0,
  STAGE_HEIGHT,
} from '@/components/WindowCreator/constants';
import { Measurement } from '@/components/WindowCreator/elements/Measurements';
import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models/konva-model';
import { FlatVector2, UnitSystemTypes } from '@/models';
import { COMMON_LINE_STROKE_WIDTH } from '@/shared/constants';

interface WindowManContourProps {
  scale: number;
  units: UnitSystemTypes;
  outerFramePoints: FlatVector2[];
}

export const MAN_CONTOUR_PATH = `
  M139.773 218.426C136.299 222.064 140.597 226.695 133.978 228.579C131.732 229.219 129.792 229.194 
  128.206 228.88C126.184 231.513 123.816 233.882 121.174 235.895C119.513 242.683 117.195 259.052 115.223 265.211
  C111.692 276.234 111.913 308.427 105.294 339.517C98.6731 370.607 95.807 391.995 93.1574 399.049C90.5104 406.107 
  90.5104 410.956 90.2888 414.264C90.0697 417.571 88.6901 419.775 88.6901 419.775C96.1929 432.235 105.073 436.423 
  106.729 439.401C108.382 442.377 107.279 445.575 107.279 445.575L108.273 446.347V450.646C108.273 450.646 
  101.873 452.52 94.923 451.749C87.973 450.977 82.1261 447.337 82.1261 447.337C78.2639 442.487 73.5202 438.739 
  73.5202 438.739C71.7547 438.187 70.0963 440.613 70.0963 440.613C67.1131 439.511 62.1577 436.535 62.1577 436.535
  C61.9361 431.794 62.8201 430.58 62.8201 430.58C63.0393 423.856 64.9143 421.652 64.9143 421.652C63.9233 416.028 
  64.8048 398.831 68.4453 390.34C72.0859 381.852 72.4171 347.564 71.8668 338.855C71.314 330.146 74.845 324.081 
  74.845 324.081C74.845 324.081 71.9763 313.608 72.6387 299.717C73.2986 285.827 71.0924 276.787 70.8732 269.18
  C70.6541 261.685 72.5815 257.4 72.6362 257.275C72.559 257.313 70.0814 258.679 68.7765 270.85C67.4518 283.212 
  59.3987 294.537 57.7452 309.42C56.0893 324.303 51.124 337.531 49.9113 351.203C48.6986 364.874 47.5954 364.764 
  42.6301 391.664C37.6648 418.564 34.5397 424.553 34.0068 431.463C33.4739 438.371 27.7739 448.589 27.7739 448.589
  C27.7739 448.589 28.4338 452.851 28.14 459.906C27.8461 466.963 26.1927 472.034 26.1927 472.034C26.1927 472.034
  26.8177 472.768 26.8177 473.724V476.809C26.8177 476.809 24.9053 481 14.0184 481C3.13405 481 1 477.693 1 477.693
  C1 477.693 1 475.56 1 474.164C1 472.768 1.59016 472.106 1.59016 472.106C0.999999 464.022 4.38409 459.025 
  4.38409 459.025C5.85576 452.704 7.91511 446.235 7.91511 446.235C6.88419 428.744 9.5337 428.303 9.68062 415.072
  C9.82754 401.844 11.5183 368.181 13.0622 355.612C14.6086 343.046 15.5997 346.131 17.6964 334.446C18.2218 331.517 
  23.9665 266.938 24.5168 262.088C25.0696 257.236 26.5239 252.532 25.0896 247.13C23.2245 240.105 23.7275 235.895 
  23.7275 235.895C21.7428 237.448 20.9335 238.09 18.7995 237.502C16.6679 236.915 17.6241 235.445 17.1087 232.578
  C16.5932 229.711 10.9307 228.681 10.9307 228.681C9.53369 226.623 10.4152 220.009 9.97445 216.555C9.5337 213.101
  10.5522 206.982 8.43057 201.782C-9.33158 158.272 15.4901 128.506 18.0275 98.4084C18.6077 91.5279 17.6964 85.73 
  20.0147 82.5324C22.3305 79.3348 41.527 76.4681 46.0491 74.8159C50.5736 73.1611 47.4834 73.6015 53.2206 71.7277
  C58.9579 69.854 62.0457 65.3326 63.3704 62.9088C64.6952 60.4827 67.011 60.7041 67.011 60.7041C67.011 60.7041 
  67.011 59.3803 67.1206 57.947C67.1206 57.947 65.2455 57.7827 63.5373 51.1113C63.5373 51.1113 60.3375 52.7114 
  59.2343 49.79C58.1312 46.8661 57.9644 45.821 56.8064 41.521C55.6485 37.2211 55.7581 33.1426 59.6203 35.5116
  C59.6203 35.5116 57.7452 32.4259 56.8612 27.5188C55.9797 22.6142 55.7581 16.4405 57.414 12.4715C59.0675 8.50246 
  62.5985 7.4001 63.7016 5.63582C64.8048 3.87154 68.281 2.21676 71.3688 2.43822C74.459 2.6572 74.4042 0.895416 
  78.8167 1.00491C83.2293 1.1144 83.8169 0.691367 87.348 2.82393C90.879 4.954 93.1574 4.29457 94.4822 6.27783
  C95.807 8.26357 99.5571 11.4238 99.9256 16.9356C100.292 22.4475 98.8947 30.3855 98.8947 30.3855C98.8947 30.3855 
  100.991 28.126 102.59 30.3307C104.191 32.5354 103.143 34.7402 103.088 37.4973C103.031 40.252 103.86 47.4186 
  98.1776 46.4257C98.1776 46.4257 98.0132 49.2376 97.2961 51.2208L97.8464 60.2612C97.8464 60.2612 99.5023 61.1446 
  100.053 63.6255C100.605 66.1064 106.948 71.7277 106.948 71.7277C106.948 71.7277 135.744 81.3205 138.612 85.73
  C141.479 90.1394 138.231 103.467 138.612 109.872C139.603 126.632 140.096 130.437 137.118 160.203C134.139 189.969
  139.272 201.558 139.773 208.505C140.271 215.45 143.244 214.788 139.773 218.426Z
`;

export const WindowManContour = ({
  scale,
  units,
  outerFramePoints,
}: WindowManContourProps) => {
  const xOffset =
    outerFramePoints[0][0] -
    (MAN_CONTOUR_PARAMS.offsetFromWindowFrame + MAN_CONTOUR_PARAMS.width) /
      INITIAL_SCALE;

  const points: FlatVector2Axis = [
    [xOffset, (STAGE_HEIGHT - STAGE_BOTTOM_PADDING_TO_FLOOR_0) / INITIAL_SCALE],
    [xOffset, (STAGE_HEIGHT - MAN_CONTOUR_PARAMS.height) / INITIAL_SCALE],
  ];

  return (
    <Group listening={false}>
      <Path
        data={MAN_CONTOUR_PATH}
        stroke="#B3B2B4"
        strokeWidth={COMMON_LINE_STROKE_WIDTH}
        strokeScaleEnabled={false}
        fill={'#F2F3F3'}
        x={xOffset}
        y={(STAGE_HEIGHT - MAN_CONTOUR_PARAMS.height) / INITIAL_SCALE}
        scaleX={1 / STATIC_SCALE}
        scaleY={1 / STATIC_SCALE}
      />
      <Measurement
        type={MeasurementElementType.Contour}
        units={units}
        scale={scale}
        disabled
        key={`measurement_offset`}
        points={points}
      ></Measurement>
    </Group>
  );
};
