import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { useMemo } from 'react';
import { UserBuildingPanel } from '@/models';
import {
  getIguPanelArea,
  getPanelHeight,
  getPanelWidth,
  getSideCornerPanel,
} from '../helpers';
import { convertMillimetersToMeters } from '../helpers/distance';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { compact } from 'lodash';
import {
  getColorDescriptionByName,
  getHexByName,
} from '@/components/WindowCreator/helpers/config';
import { FillerType, UnitInnerFrame } from '@/components/UTC/models';
import { getFillerData } from '@/models/panelsConfig';

export const useUnitProperties = (unitData: UserBuildingPanel) => {
  const { id } = useParams();
  const userBuildings = useFetchProjectQuery(id!).data?.buildings;
  const config = useFetchWindowConfigQuery().data!;
  const libraryUnits = useGetAllPanelsQuery(id!).data!;

  const sideUnitData = getSideCornerPanel(unitData, libraryUnits);
  const isCorner = unitData.cornerSide && sideUnitData?.innerFrames;
  const unitUsageAmount = useMemo(() => {
    let amount = 0;

    userBuildings?.forEach((building) => {
      building.blocks.forEach((block) => {
        block.storeys.forEach((storey) => {
          storey.walls.forEach((wall) => {
            const amountAtStorey = wall.wallPanels?.reduce(
              (acc, unit) => (unit.panelId === unitData.id ? acc + 1 : acc),
              0
            );
            if (amountAtStorey) {
              amount += amountAtStorey;
            }
          });
        });
      });
    });

    return amount;
  }, [userBuildings]);

  const unitHeight = getPanelHeight(unitData);
  const unitWidth = getPanelWidth(unitData);
  const unitArea =
    convertMillimetersToMeters(unitWidth) *
    convertMillimetersToMeters(unitHeight);
  const iguArea = getIguPanelArea(unitData, config.panel);

  const usedIguIds = compact(
    Array.from(
      new Set([
        ...(unitData.innerFrames?.flatMap((innerFrame) => innerFrame.iguId) ??
          []),
        ...(sideUnitData?.innerFrames?.flatMap(
          (innerFrame) => innerFrame.iguId
        ) ?? []),
      ])
    )
  );

  const innerColor = {
    hex: getHexByName(config.colors, unitData.insideColor),
    description: getColorDescriptionByName(config.colors, unitData.insideColor),
  };
  const outerColor = {
    hex: getHexByName(config.colors, unitData.outsideColor),
    description: getColorDescriptionByName(
      config.colors,
      unitData.outsideColor
    ),
  };

  const getColorData = (
    frames: UnitInnerFrame[],
    colorKey: 'insideColor' | 'outsideColor',
    isOperableWindow: boolean | null = null
  ) => {
    const result = new Map<string, string>();

    frames.forEach((frame) => {
      const isOperable = frame.fillerType === FillerType.OperableWindow;

      if (
        (isOperableWindow === null || isOperableWindow === isOperable) &&
        frame[colorKey]
      ) {
        result.set(
          getHexByName(config.colors, frame[colorKey]) ?? '',
          getColorDescriptionByName(config.colors, frame[colorKey]) ?? ''
        );
      }
    });

    return result;
  };

  const insideFillerColorsData = Array.from(
    new Map([
      ...getColorData(unitData.innerFrames, 'insideColor', false),
      ...(isCorner
        ? getColorData(sideUnitData.innerFrames, 'insideColor', false)
        : []),
    ])
  );

  const outsideFillerColorsData = Array.from(
    new Map([
      ...getColorData(unitData.innerFrames, 'outsideColor', false),
      ...(isCorner
        ? getColorData(sideUnitData.innerFrames, 'outsideColor', false)
        : []),
    ])
  );

  const outsideWindowFrameColorsData = Array.from(
    new Map([
      ...getColorData(unitData.innerFrames, 'outsideColor', true),
      ...(isCorner
        ? getColorData(sideUnitData.innerFrames, 'outsideColor', true)
        : []),
    ])
  );

  const insideWindowFrameColorsData = Array.from(
    new Map([
      ...getColorData(unitData.innerFrames, 'insideColor', true),
      ...(isCorner
        ? getColorData(sideUnitData.innerFrames, 'insideColor', true)
        : []),
    ])
  );

  const filler = useMemo(() => {
    const uniqueTypes = new Set<FillerType>();

    unitData.innerFrames.forEach((frame) => uniqueTypes.add(frame.fillerType));

    isCorner &&
      sideUnitData.innerFrames.forEach((frame) =>
        uniqueTypes.add(frame.fillerType)
      );

    if (uniqueTypes.size > 1)
      return {
        type: FillerType.Multiple,
        description: FillerType.Multiple,
      };

    const [type] = uniqueTypes;

    return {
      type,
      description: getFillerData(type, config.fillerTypes)?.description ?? type,
    };
  }, [unitData]);

  const sideUnitWidth = isCorner && getPanelWidth(sideUnitData);
  const sideUnitHeight = isCorner && getPanelHeight(sideUnitData);
  const sideUnitIguIds =
    isCorner &&
    compact(
      sideUnitData.innerFrames?.flatMap((innerFrame) => innerFrame.iguId)
    );
  const sideUnitArea =
    isCorner &&
    sideUnitWidth &&
    sideUnitHeight &&
    convertMillimetersToMeters(sideUnitWidth) *
      convertMillimetersToMeters(sideUnitHeight);

  const sideIguArea = isCorner && getIguPanelArea(sideUnitData, config.panel);

  const sideInnerColor = isCorner && {
    hex: getHexByName(config.colors, sideUnitData.insideColor),
    description: getColorDescriptionByName(
      config.colors,
      sideUnitData.insideColor
    ),
  };

  const sideOuterColor = isCorner && {
    hex: getHexByName(config.colors, sideUnitData.outsideColor),
    description: getColorDescriptionByName(
      config.colors,
      sideUnitData.outsideColor
    ),
  };

  return {
    unitUsageAmount,
    unitHeight,
    unitWidth,
    sideUnitWidth,
    unitArea,
    sideUnitArea,
    iguArea,
    sideIguArea,
    usedIguIds,
    sideUnitIguIds,
    innerColor,
    sideInnerColor,
    outerColor,
    sideOuterColor,
    outsideFillerColorsData,
    insideFillerColorsData,
    outsideWindowFrameColorsData,
    insideWindowFrameColorsData,
    filler,
  };
};
