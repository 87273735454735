import React from 'react';
import { Rect } from 'react-konva';
import {
  WINDOW_GLASS_ERROR_COLOR,
  WINDOW_GLASS_SELECTED_COLOR,
} from '@/components/WindowCreator/elements/windows.constants';
import { SavedWindow } from '@/components/WindowCreator/models';
import { getWindowHeight, getWindowWidth } from '@/shared/helpers';
import { ACTIVE_ELEMENT_COLOR } from '@/components/WindowCreator/constants';

interface HighlightedRectProps {
  data: SavedWindow;
  isHovered: boolean;
  isSelected?: boolean;
  hasError?: boolean;
  offsetX: number;
  offsetY: number;
  hasBeenSnapped?: boolean;
}

const PlacedWindowStates: React.FC<HighlightedRectProps> = ({
  data,
  isHovered,
  isSelected,
  hasError,
  offsetX,
  offsetY,
  hasBeenSnapped,
}) => {
  const windowWidth = getWindowWidth(data);
  const windowHeight = getWindowHeight(data);

  const getStrokeColor = (): string => {
    if (hasBeenSnapped) {
      return ACTIVE_ELEMENT_COLOR;
    }

    if (isHovered || isSelected) {
      return WINDOW_GLASS_SELECTED_COLOR;
    }

    if (hasError) {
      return WINDOW_GLASS_ERROR_COLOR;
    }

    return 'transparent';
  };

  return (
    <Rect
      x={offsetX - 1}
      y={offsetY - 1 - windowHeight}
      width={windowWidth + 2}
      height={windowHeight + 2}
      stroke={getStrokeColor()}
      strokeScaleEnabled={false}
      shadowForStrokeEnabled={false}
      strokeWidth={2}
      fill={isSelected ? '#0094FF1A' : 'transparent'}
    />
  );
};

export default PlacedWindowStates;
