import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const CreateUnitTypeIcon: React.FC<BaseIconProps> = ({
  className,
  stroke = '#414042',
  fill = '#414042',
}) => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect x="4" y="2.5" width="13" height="15" rx="0.5" stroke={stroke} />
      <rect
        x="4"
        y="2.5"
        width="13"
        height="15"
        rx="0.5"
        stroke={stroke}
        strokeOpacity="0.2"
      />
      <path
        d="M7.5 5.5H13.5001V13.5H7.5L7.5 5.5Z"
        fill={fill}
        fillOpacity="0.4"
      />
      <path
        d="M7.5 2L7.5 5.5M7.5 18V13.5M13.5001 2V5.5M13.5001 18V13.5M7.5 5.5H13.5001M7.5 5.5L7.5 13.5M13.5001 5.5V13.5M7.5 13.5H13.5001"
        stroke={stroke}
      />
      <path
        d="M7.5 2L7.5 5.5M7.5 18V13.5M13.5001 2V5.5M13.5001 18V13.5M7.5 5.5H13.5001M7.5 5.5L7.5 13.5M13.5001 5.5V13.5M7.5 13.5H13.5001"
        stroke={stroke}
        strokeOpacity="0.2"
      />
    </svg>
  );
};
