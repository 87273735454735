import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const VectorDownIcon: React.FC<BaseIconProps> = ({
  stroke = 'black',
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={className}
      stroke={stroke}
    >
      <path
        d="M3.33337 5.66663L8.00004 10.3333L12.6667 5.66663"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
