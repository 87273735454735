import { FlatVector2 } from '@/models';
import { booleanPointOnLine, lineString, point } from '@turf/turf';
import { generateClosedContourPointsFromPointArray } from '@/components/WindowCreator/elements/creator-windows.helpers';

export const checkIsPointOnFrame = (
  checkedPoint: FlatVector2,
  framePoints: FlatVector2[]
) => {
  const formattedPoint = point([checkedPoint[0], checkedPoint[1]]);
  return generateClosedContourPointsFromPointArray(framePoints).some((edge) =>
    booleanPointOnLine(formattedPoint, lineString(edge))
  );
};
