import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getBuildingFacadeData,
  getExtrudeData,
  getHoveredNode,
  getSelectedNodes,
  resetHoveredNode,
  selectNodes,
} from '@/store/slices/canvasBuildingSlice';
import {
  getAffectedNodes,
  getNodesToCopy,
  resetCopyProperties,
  setAffectedNodes,
  setNodesToCopy,
} from '@/store/slices/copyPropertiesSlice';
import { NodeType, UserBuildingWall } from '@/models';
import { inRange } from '@/shared/helpers/distance';
import { useParams } from 'react-router';
import {
  useCopyWallsMutation,
  useFetchProjectQuery,
} from '@/store/apis/projectsApi';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import CopyPropertiesHandler, {
  CopyPropertiesHandlerData,
} from '@/routes/dashboard/projects/project/ModifyTools/CopyPropertiesHandler';
import {
  getPerpendicularVectorToVectors,
  getXYZ,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { compact, isEqual } from 'lodash';
import { useMeasureWallsFacadeDesigner } from '@/components/FacadeDesigner/hooks/useMeasureWallsFacadeDesigner';
import { convertFlatVector3ToVectors } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
export const COPE_WALLS_CACHE_KEY = 'CREATE_USER_BUILDING_CACHE_KEY';

const CopyPropertiesTool = () => {
  const dispatch = useAppDispatch();
  const hoveredNodeGUID = useAppSelector(getHoveredNode);
  const selectedNodes = useAppSelector(getSelectedNodes);
  const facadesData = useAppSelector(getBuildingFacadeData);
  const [copyWallsMutation] = useCopyWallsMutation({
    fixedCacheKey: COPE_WALLS_CACHE_KEY,
  });

  const nodesToCopy = useAppSelector(getNodesToCopy);
  const wallsToCopy = Object.values(nodesToCopy);
  const affectedNodes = useAppSelector(getAffectedNodes);
  const { getMeasureWalls } = useMeasureWallsFacadeDesigner();
  const { id } = useParams();

  const [selectedWallsData, setSelectedWallsData] = useState<
    UserBuildingWall[]
  >([]);

  const [facadeWalls, setFacadeWalls] = useState<UserBuildingWall[]>([]);

  // as we already have selected walls guids -> user buildings should be available at the start
  const projectData = useFetchProjectQuery(id!).data!;
  const userBuildings = projectData.buildings!;

  const getWallFromFacadeByGUID = (
    guid: string
  ): UserBuildingWall | undefined => {
    return facadeWalls?.find((wall) => wall.guid === guid);
  };

  const handleAffectedWallsChange = useCallback(() => {
    if (
      !Object.keys(nodesToCopy).length ||
      !hoveredNodeGUID ||
      !facadeWalls.length
    ) {
      return;
    }
    const hoveredWall = getWallFromFacadeByGUID(hoveredNodeGUID);
    if (!selectedWallsData.length || !hoveredWall) {
      return;
    }

    const allSimilarWallsIncludedInVerticalRange = facadeWalls.filter(
      (wall) => {
        const heightToCompare = wall.points[0][1];

        const affectedHeight = {
          from: wallsToCopy[0].points[0][1],
          to: hoveredWall.points[0][1],
        };
        const isVerticallyInRange = inRange(
          heightToCompare,
          affectedHeight.from,
          affectedHeight.to
        );
        const isHorizontallyAligned = wallsToCopy.some(
          (wallToCopy) =>
            wallToCopy.points[0][0] === wall.points[0][0] &&
            wallToCopy.points[0][2] === wall.points[0][2] &&
            wallToCopy.points[1][0] === wall.points[1][0] &&
            wallToCopy.points[1][2] === wall.points[1][2]
        );

        return isVerticallyInRange && isHorizontallyAligned;
      }
    );

    const similarWallsSortedByHeight =
      allSimilarWallsIncludedInVerticalRange.reduce(
        (
          acc: {
            [height: number]: UserBuildingWall[];
          },
          cur: UserBuildingWall
        ) => {
          acc[cur.points[0][1]]
            ? acc[cur.points[0][1]].push(cur)
            : (acc[cur.points[0][1]] = [cur]);
          return acc;
        },
        {}
      );

    // Only storeys, that have same horizontal align, and have same amount of affected walls as on initial storey
    const affectedWalls = Object.values(similarWallsSortedByHeight)
      .filter((arr) => arr.length === wallsToCopy.length)
      .flat();

    dispatch(setAffectedNodes(affectedWalls));
  }, [hoveredNodeGUID, nodesToCopy, facadeWalls]);

  useEffect(() => {
    handleAffectedWallsChange();
  }, [handleAffectedWallsChange]);

  const handlePointerUp = useCallback(() => {
    const affectedWalls = Object.values(affectedNodes);

    if (!affectedWalls.length) return;

    const copiedWallsData = wallsToCopy.map((wall) => {
      const wallGuids = affectedWalls
        .filter(
          (affectedWall) =>
            isEqual(wall.points[0][0], affectedWall.points[0][0]) &&
            isEqual(wall.points[0][2], affectedWall.points[0][2]) &&
            affectedWall.guid !== wall.guid
        )
        .map((wall) => wall.guid);

      return {
        originWallGuid: wall.guid,
        wallGuids,
      };
    });

    copyWallsMutation({
      projectId: id!,
      data: {
        items: copiedWallsData,
      },
    });

    dispatch(resetCopyProperties());
    dispatch(
      selectNodes(
        affectedWalls.map((wall) => ({ guid: wall.guid, type: NodeType.Wall }))
      )
    );
  }, [userBuildings, affectedNodes]);

  const isExtrudeMode = !!useAppSelector(getExtrudeData);
  const isNoBlockingProcesses = !isExtrudeMode;

  const isWallsCanBeCopied = useMemo(() => {
    const selectedGUIDs = Object.keys(selectedNodes);
    if (!selectedGUIDs.length) return false;

    const onlyWallsSelected = Object.values(selectedNodes).every(
      (node) => node.type === NodeType.Wall
    );
    if (!onlyWallsSelected) return false;

    const facadeIdx = facadesData.findIndex((walls) =>
      Object.keys(selectedNodes).every((selectedGUID) =>
        walls.some((wall) => selectedGUID === wall.guid)
      )
    );
    if (facadeIdx === -1) return false;

    setFacadeWalls(facadesData[facadeIdx]);

    const wallsData: UserBuildingWall[] = compact(
      selectedGUIDs.map((selectedGUID) =>
        facadesData[facadeIdx].find(
          (facadeWall) => facadeWall.guid === selectedGUID
        )
      )
    );
    if (!wallsData) return false;

    setSelectedWallsData(wallsData);

    const isWallsOnSameFloor = wallsData.every(
      (wall) => wall.points[0][1] === wallsData[0].points[0][1]
    );

    return isWallsOnSameFloor;
  }, [facadesData, selectedNodes]);

  const copyPropertiesHandlerData =
    useMemo((): CopyPropertiesHandlerData | null => {
      if (!selectedWallsData?.length) {
        return null;
      }

      const { endPoint } = getMeasureWalls(selectedWallsData);
      const mostRightWall = selectedWallsData.find((wall) =>
        wall.points.some(
          (point) =>
            isEqual(point[0], endPoint.x) && isEqual(point[2], endPoint.z)
        )
      );
      if (!mostRightWall) {
        return null;
      }

      const wallCoordinates = convertFlatVector3ToVectors(mostRightWall.points);
      const perpendicularDirection = getPerpendicularVectorToVectors(
        wallCoordinates,
        true
      );
      return {
        defaultCenter: mostRightWall.points[0],
        perpendicularDirection: getXYZ(perpendicularDirection),
        wallGUID: mostRightWall.guid,
      };
    }, [selectedWallsData]);

  const handleClick = () => {
    dispatch(resetHoveredNode());
    dispatch(setNodesToCopy(selectedWallsData));
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID)!;
    canvas.addEventListener('pointerup', handlePointerUp);
    return () => {
      canvas.removeEventListener('pointerup', handlePointerUp);
    };
  }, [handlePointerUp]);

  return (
    <>
      {!projectData.locked &&
      isWallsCanBeCopied &&
      copyPropertiesHandlerData &&
      isNoBlockingProcesses ? (
        <CopyPropertiesHandler
          handleClick={handleClick}
          copyPropertiesHandlerData={copyPropertiesHandlerData}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default CopyPropertiesTool;
