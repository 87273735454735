import {
  GENERATE_CAMERA_SNAPSHOTS,
  GENERATE_CAMERA_SNAPSHOTS_SUCCESS,
} from '@/core/event-names';
import { publish, subscribe, unsubscribe } from '@/core/events';
import { EditModes, ProjectStructure } from '@/models';
import { Side } from '@/models/camera.model';
import { REPORT_BACKGROUND_COLOR } from '@/shared/constants';
import { useLazyUpdateProjectMutation } from '@/store/apis/projectsApi';
import { useAppSelector } from '@/store/hooks';
import {
  getHoveredNode,
  getSelectedNodes,
} from '@/store/slices/canvasBuildingSlice';
import { getEditMode } from '@/store/slices/canvasModesSlice';
import React, { useEffect, useRef } from 'react';
import { useParams } from 'react-router';
import { Color, Vector3 } from 'three';
import { uuidv7 } from 'uuidv7';
import useHandleError from '@/shared/hooks/useHandleError';

interface ProjectCoverProps {
  project: ProjectStructure;
}

const ProjectCover: React.FC<ProjectCoverProps> = ({ project }) => {
  const { id } = useParams();
  const selectedNodes = useAppSelector(getSelectedNodes);
  const editMode = useAppSelector(getEditMode);
  const hoveredNode = useAppSelector(getHoveredNode);
  const [updateProject] = useLazyUpdateProjectMutation();
  const projectCover = useRef<string>('');
  const { handleError } = useHandleError();
  const timeUpdateCover = 90000;
  const timePicture = timeUpdateCover / 60;

  const takeSnapshot = () => {
    const isAbleToTakeScreenshot =
      Object.keys(selectedNodes).length === 0 &&
      !project.locked &&
      !hoveredNode &&
      ![EditModes.Split, EditModes.Cut].includes(editMode);

    if (!isAbleToTakeScreenshot) return;

    const processId = uuidv7();

    const onSnapshotSuccess = (res: {
      detail: { urls: string[]; processId: string };
    }) => {
      const { processId: eventProcessId, urls } = res.detail;

      if (eventProcessId === processId) {
        projectCover.current = urls[0];
      }
    };

    subscribe(GENERATE_CAMERA_SNAPSHOTS_SUCCESS, onSnapshotSuccess);

    publish(GENERATE_CAMERA_SNAPSHOTS, {
      filters: [
        {
          side: Side.FRONT,
          position: new Vector3(20, 20, 20),
          targetGUID: project.buildings![0].guid,
          background: { color: new Color(REPORT_BACKGROUND_COLOR), alpha: 0 },
        },
      ],
      processId,
    });

    setTimeout(
      () => unsubscribe(GENERATE_CAMERA_SNAPSHOTS_SUCCESS, onSnapshotSuccess),
      1000
    );
  };

  const updateProjectCover = async (url: string) => {
    try {
      await updateProject({
        id: Number(id),
        image: url,
      }).unwrap();
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      takeSnapshot();
    }, timePicture);

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [
    selectedNodes,
    project.buildings?.length,
    hoveredNode,
    project.locked,
    editMode,
  ]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (projectCover.current && !project.locked) {
        updateProjectCover(projectCover.current).finally(
          () => (projectCover.current = '')
        );
      }
    }, timeUpdateCover);

    return () => clearInterval(intervalId);
  }, [project.locked]);

  return null;
};

export default ProjectCover;
