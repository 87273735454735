import { useFacadeData } from '@/shared/hooks/useFacadeData';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import { useMeasureWallsFacadeDesigner } from '@/components/FacadeDesigner/hooks/useMeasureWallsFacadeDesigner';
import {
  getPanelHeight,
  getPanelWidth,
  getSideCornerPanel,
} from '@/shared/helpers';
import {
  generateSvgCornerBorders,
  getUniqBuildingUnits,
  getNumberedUnits,
  normalizeSvgPoints,
} from '../helpers';
import { generateUTCBorders } from '@/components/UTC/helpers/generators';
import { CornerGridAlign, NodeType, UserBuildingPanel } from '@/models';
import { ElementData } from '../models';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { round } from 'mathjs';

interface UnitsDataProps {
  isNumbering: boolean;
  isUnitNumbering: boolean;
  useAllUnits?: boolean;
  isReport?: boolean;
}
interface UnitDataProps {
  isNumbering: boolean;
  isUnitNumbering: boolean;
  unit: UserBuildingPanel;
  isReport?: boolean;
}

interface FacadesDataProps {
  useAllUnits: boolean;
  isNumbering: boolean;
  isUnitNumbering: boolean;
  isReport?: boolean | undefined;
}

export const useSvgData = () => {
  const { id } = useParams();
  const libraryUnits = useGetAllPanelsQuery(id!).data!;
  const buildingGuid = useFetchProjectQuery(id!).data!.buildings![0].guid;
  const config = useFetchWindowConfigQuery().data!;
  const { facadesData: facades } = useFacadeData();
  const { getMeasureWall, getMeasureWalls } = useMeasureWallsFacadeDesigner();
  const { findDataForWall, findDataForBuilding } = useFindNodeData();
  const buildingData = findDataForBuilding(buildingGuid);
  const wallsNodes =
    buildingData?.childNodes.filter((node) => node.type === NodeType.Wall) ??
    [];
  const allUnits = wallsNodes.flatMap(
    (node) => findDataForWall(node.guid)?.wallPanels ?? []
  );
  const uniqBuildingUnits = getUniqBuildingUnits(allUnits, libraryUnits);
  const uniqUnitsNumbered = getNumberedUnits(uniqBuildingUnits);

  const getUnitData = ({
    isNumbering,
    isUnitNumbering,
    isReport,
    unit,
  }: UnitDataProps): ElementData => {
    const sideCorner = getSideCornerPanel(unit, libraryUnits);
    const sideWidth = sideCorner ? getPanelWidth(sideCorner) : 0;
    const width = getPanelWidth(unit);
    const height = getPanelHeight(unit);
    const borders = normalizeSvgPoints(
      sideCorner
        ? generateSvgCornerBorders(unit.points, sideCorner.points, config.panel)
        : generateUTCBorders(unit.points, config.panel),
      unit.points[0]
    );
    const parentWidth = unit.cornerSide
      ? round(width + sideWidth - config.panel.sideWidth * 2, 2)
      : width;

    return {
      id: unit.id,
      width,
      height,
      parentWidth,
      isCorner: !!unit.cornerSide,
      sideWidth,
      borders,
      isUnitNumbering,
      isNumbering,
      isReport,
      sideCorner,
      mullions: unit.mullions,
      frames: unit.innerFrames,
      config,
      unit,
    };
  };

  const getUnitsData = ({
    isNumbering,
    isUnitNumbering,
    isReport,
    useAllUnits,
  }: UnitsDataProps): ElementData[] => {
    const selectedUnits = useAllUnits ? libraryUnits : uniqBuildingUnits;

    return selectedUnits
      .filter((unit) => unit.cornerSide !== CornerGridAlign.Right)
      .map((unit) =>
        getUnitData({
          isNumbering,
          isUnitNumbering,
          isReport,
          unit,
        })
      );
  };

  const getFacadesData = ({
    isNumbering,
    isUnitNumbering,
    useAllUnits,
    isReport,
  }: FacadesDataProps) => {
    const filteredFacades = useAllUnits
      ? facades
      : facades.filter((facade) =>
          facade.some((wall) => wall.wallPanels.length > 0)
        );

    return filteredFacades.map((facade) => {
      const wallsData = facade
        .map((wall) => findDataForWall(wall.guid))
        .filter(Boolean) as WallSearchResults[];

      const {
        minMaxWallsCoordinates,
        startPoint,
        wallsTotalHeight,
        wallsTotalWidth,
      } = getMeasureWalls(wallsData);

      return {
        wallsTotalHeight,
        wallsTotalWidth,
        walls: wallsData
          .map((wallData) => {
            const { wallHeight, wallOffset, wallWidth } = getMeasureWall(
              wallData,
              startPoint,
              minMaxWallsCoordinates
            );

            const wallUnits = wallData.wallPanels.reduce<
              (ElementData & { offsetFromLeftEdge: number })[]
            >((acc, wallUnit) => {
              const unitData = uniqUnitsNumbered.get(wallUnit.panelId);

              if (!unitData) return acc;

              const sideCorner = getSideCornerPanel(
                unitData,
                uniqBuildingUnits
              );
              const width = getPanelWidth(unitData);
              const height = getPanelHeight(unitData);
              const borders = normalizeSvgPoints(
                generateUTCBorders(unitData.points, config.panel),
                unitData.points[0]
              );

              acc.push({
                id: unitData.id,
                unitNumber: unitData.unitNumber,
                offsetFromLeftEdge: wallUnit.offsetFromLeftEdge,
                width,
                parentWidth: width,
                height,
                isCorner: false,
                isNumbering,
                sideCorner,
                isUnitNumbering,
                isReport,
                sideWidth: 0,
                borders,
                mullions: unitData.mullions,
                frames: unitData.innerFrames,
                unit: unitData,
                config,
              });

              return acc;
            }, []);

            return {
              wallUnits,
              gridLines: wallData.gridLines,
              wallUnitErrors: wallData.wallPanelErrors,
              wallHeight,
              wallOffset,
              wallWidth,
            };
          })
          .reverse(),
      };
    });
  };

  return { getUnitsData, getFacadesData, uniqBuildingUnits, getUnitData };
};
