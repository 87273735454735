import React from 'react';

import {
  SvgFixedWindow,
  SvgDualActionLeftTopWindow,
  SvgDualActionRightTopWindow,
  SvgCasementLeftWindow,
  SvgCasementRightWindow,
  SvgHopperWindow,
  SvgAwningWindow,
} from './';

import { OperationType } from '@/models/window-configurator.model';
import { Color, CustomOperableWindow, OperableConfig } from '../../models';
import { FlatVector2 } from '@/models';

interface SvgWindowEntityProps {
  operationType: OperationType;
  operableConfig: OperableConfig;
  handleScale: number;
  offset: number;
  color: Color;
  points: FlatVector2[];
}

const SvgWindowEntity: React.FC<SvgWindowEntityProps> = ({
  operationType,
  operableConfig,
  handleScale,
  color,
  points,
  offset,
  ...props
}) => {
  const windowComponents: Record<
    OperationType,
    React.FC<CustomOperableWindow>
  > = {
    [OperationType.Fixed]: SvgFixedWindow,
    [OperationType.DualActionLeftTop]: SvgDualActionLeftTopWindow,
    [OperationType.DualActionRightTop]: SvgDualActionRightTopWindow,
    [OperationType.CasementLeft]: SvgCasementLeftWindow,
    [OperationType.CasementRight]: SvgCasementRightWindow,
    [OperationType.Hopper]: SvgHopperWindow,
    [OperationType.Awning]: SvgAwningWindow,
  };

  if (!operationType) return null;
  const WindowComponent = windowComponents[operationType];

  return WindowComponent ? (
    <WindowComponent
      {...props}
      offset={offset}
      operableConfig={operableConfig}
      handleScale={handleScale}
      color={color}
      points={points}
    />
  ) : null;
};

export default SvgWindowEntity;
