import { SCALE_SPEED } from '@/components/WindowCreator/constants';
import { KonvaEventObject, Node } from 'konva/lib/Node';
import { Stage } from 'konva/lib/Stage';
import { FlatVector2 } from '@/models';

export const useKonvaHandlers = () => {
  const getCenterPosition = ({
    pointPositionToCenter,
    stageRelative,
    oldScale,
    newScale,
  }: {
    pointPositionToCenter: { x: number; y: number };
    stageRelative: { x: number; y: number };
    oldScale: number;
    newScale: number;
  }) => {
    const mousePointTo = {
      x: pointPositionToCenter.x / oldScale - stageRelative.x / oldScale,
      y: pointPositionToCenter.y / oldScale - stageRelative.y / oldScale,
    };

    return {
      scale: newScale,
      x: (pointPositionToCenter.x / newScale - mousePointTo.x) * newScale,
      y: (pointPositionToCenter.y / newScale - mousePointTo.y) * newScale,
    };
  };
  const wheelHandler = (
    e: KonvaEventObject<WheelEvent>,
    {
      maxScale,
      minScale,
    }: {
      maxScale: number;
      minScale: number;
    }
  ) => {
    e.evt.preventDefault();
    const stage = e.target.getStage()!;

    // MIN_ZOOM
    if (stage.scaleX() <= minScale && e.evt.deltaY > 0) {
      return;
    }

    // MAX_ZOOM
    if (stage.scaleX() >= maxScale && e.evt.deltaY < 0) {
      return;
    }
    const oldScale = stage.scaleX();
    const newScale =
      e.evt.deltaY < 0 ? oldScale * SCALE_SPEED : oldScale / SCALE_SPEED;

    return getCenterPosition({
      pointPositionToCenter: stage.getPointerPosition()!,
      oldScale,
      newScale,
      stageRelative: { x: stage.x(), y: stage.y() },
    });
  };

  const reCenterElement = (
    stage: Stage,
    element: Node,
    elementCoordinates: FlatVector2[],
    scale: number
  ) => {
    const elementCenter = {
      x:
        element.position().x +
        (elementCoordinates[1][0] + elementCoordinates[0][0]) / 2,
      y:
        element.position().y +
        (elementCoordinates[2][1] + elementCoordinates[1][1]) / 2,
    };

    const viewportCenter = {
      x: stage.width() / 2,
      y: stage.height() / 2,
    };

    const newPosition = {
      x: viewportCenter.x - elementCenter.x * scale,
      y: viewportCenter.y - elementCenter.y * scale,
    };

    stage.position(newPosition);
  };

  return {
    wheelHandler,
    getCenterPosition,
    reCenterElement,
  };
};
