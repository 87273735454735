import React from 'react';

import { Text, Trap } from '../../shared';

import { LineColor, Frame, Color } from '../../models';
import { FlatVector2 } from '@/models';
import { LINE_WIDTH } from '../../constants';

interface SvgUnitFramesProps extends Omit<Frame, 'operationType'> {
  color: Color;
  points: FlatVector2[];
  isNumbering: boolean;
}

const SvgUnitFrame: React.FC<SvgUnitFramesProps> = ({
  color,
  frameNumber,
  textColor,
  isNumbering,
  points,
}) => {
  return (
    <>
      <Trap
        points={points}
        fill={color}
        stroke={LineColor.DEFAULT}
        strokeWidth={LINE_WIDTH}
      />
      {isNumbering && (
        <Text
          x={points[0][0]}
          y={points[2][1]}
          text={frameNumber}
          color={textColor}
        />
      )}
    </>
  );
};

export default SvgUnitFrame;
