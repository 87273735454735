import React from 'react';

import { Color, GradientType } from '../../models';
import { FlatVector2 } from '@/models';
import { OPACITY } from '../../constants';

interface SvgTrapProps {
  points: FlatVector2[];
  fill: Color;
  stroke: string;
  strokeWidth: number;
}

const Trap: React.FC<SvgTrapProps> = ({
  points,
  fill,
  stroke,
  strokeWidth,
}) => {
  return (
    <>
      <polygon
        points={points.map(([x, y]) => `${x},${y}`).join(' ')}
        fill={fill.hex}
        stroke={stroke}
        strokeWidth={strokeWidth}
      />
      {fill.hasGlare && (
        <polygon
          points={points.join(' ')}
          opacity={OPACITY}
          fill={`url(#${GradientType.GLARE})`}
          stroke={stroke}
          strokeWidth={strokeWidth}
        />
      )}
    </>
  );
};

export default Trap;
