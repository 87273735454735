import { convertFtInchToMillimeters } from '@/shared/helpers/distance';
import {
  formatLimitValue,
  removeSpacesFromThousands,
} from '@/shared/helpers/format-data';
import { Rule } from 'antd/es/form';

export const requiredValidator = (message: string): Rule => ({
  required: true,
  validator: (_, value) =>
    !value?.trim()?.length ? Promise.reject(message) : Promise.resolve(),
});

export const maxLengthValidator = (
  message: string,
  length: number = 30
): Rule => ({
  max: length,
  message,
});

export const limitsValidator = ({
  min,
  max,
  isImperialUnits,
  staticValue = false,
  customValidationMessage,
  decimalPlaces = 0,
}: {
  min: number;
  max: number;
  isImperialUnits: boolean;
  staticValue?: boolean;
  customValidationMessage?: string | null;
  decimalPlaces?: number;
}): Rule => ({
  validator: (_, value) => {
    const sanitizedValue = removeSpacesFromThousands(value, isImperialUnits);
    const numericValue = Number(
      isImperialUnits && !staticValue
        ? convertFtInchToMillimeters(sanitizedValue)
        : sanitizedValue
    ).toFixed(2);

    const minValue = formatLimitValue({
      limit: min,
      isImperialUnits,
      staticValue,
      decimalPlaces,
    });
    const maxValue = formatLimitValue({
      limit: max,
      isImperialUnits,
      staticValue,
      decimalPlaces,
    });

    const validationMessage = customValidationMessage
      ? customValidationMessage
      : `Limits ${minValue} - ${maxValue}`;

    return Number(numericValue) >= min && Number(numericValue) <= max
      ? Promise.resolve()
      : Promise.reject(validationMessage);
  },
});
