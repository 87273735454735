import React, { ReactNode } from 'react';
import {
  OperationType,
  OperationTypeDescription,
  WindowColor,
} from '@/models/window-configurator.model';
import FixedOperationTypeIcon from '../icons/OperationTypes/FixedIcon';
import DualActionRightTopIcon from '../icons/OperationTypes/DualActionRightTopIcon';
import DualActionLeftTopIcon from '../icons/OperationTypes/DualActionLeftTopIcon';
import CasementLeftIcon from '../icons/OperationTypes/CasementLeftIcon';
import CasementRightIcon from '../icons/OperationTypes/CasementRightIcon';
import AwningIcon from '../icons/OperationTypes/AwningIcon';
import HopperIcon from '../icons/OperationTypes/Hopper';
import aluminumMaterial from '@/images/aluminiumMaterial.png';
import enamelGlassMaterial from '@/images/enamelGlass.svg';
import glassMaterial from '@/images/glassMaterial.svg';
import operableWindow from '@/images/operableWindow.png';
import multipleFillerType from '@/images/multipleFillerType.svg';
import { FillerType } from '@/components/UTC/models';

export const getHexByName = (colors: WindowColor[], name: string) => {
  return colors.find((color) => color.name === name)?.hex;
};

export const getColorDescriptionByName = (
  colors: WindowColor[],
  name: string
) => {
  return colors.find((color) => color.name === name)?.description;
};

export const getOperationTypeData = (
  operationType: OperationType,
  operationTypes: OperationTypeDescription[]
) => {
  return operationTypes.find((operation) => operation.name === operationType);
};

export const getOperationTypeIcon = (
  operationType: OperationType,
  size: string = '40'
) => {
  switch (operationType) {
    case OperationType.Fixed:
      return <FixedOperationTypeIcon size={size} />;
    case OperationType.DualActionRightTop:
      return <DualActionRightTopIcon size={size} />;
    case OperationType.DualActionLeftTop:
      return <DualActionLeftTopIcon size={size} />;
    case OperationType.CasementLeft:
      return <CasementLeftIcon size={size} />;
    case OperationType.CasementRight:
      return <CasementRightIcon size={size} />;
    case OperationType.Awning:
      return <AwningIcon size={size} />;
    case OperationType.Hopper:
      return <HopperIcon size={size} />;
  }
};

export const getColorData = (colorId: string, colors: WindowColor[]) => {
  return colors.find((color) => color.name === colorId);
};

export const getMaterialImage = (
  materialName: string,
  height: number = 32,
  width: number = 32
): ReactNode => {
  switch (materialName) {
    case FillerType.Aluminium:
      return (
        <img
          height={height}
          width={width}
          src={aluminumMaterial}
          alt={FillerType.Aluminium}
        />
      );
    case FillerType.EnamelGlass:
      return (
        <img
          height={height}
          width={width}
          src={enamelGlassMaterial}
          alt={FillerType.EnamelGlass}
        />
      );
    case FillerType.Glass:
      return (
        <img
          height={height}
          width={width}
          src={glassMaterial}
          alt={FillerType.Glass}
        />
      );
    case FillerType.OperableWindow:
      return (
        <img
          height={height}
          width={width}
          src={operableWindow}
          alt={FillerType.OperableWindow}
        />
      );

    default:
      return <img src={multipleFillerType} alt={FillerType.Multiple} />;
  }
};
