import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';
import { UserBuildingWall } from '@/models';

interface CopyProperties {
  nodesToCopy: { [key: string]: UserBuildingWall };
  affectedNodes: { [key: string]: UserBuildingWall };
}

const initialState: CopyProperties = {
  nodesToCopy: {},
  affectedNodes: {},
};

export const copyPropertiesSlice = createSlice({
  name: 'copyProperties',
  initialState,
  reducers: {
    setNodesToCopy: (state, action: PayloadAction<UserBuildingWall[]>) => {
      state.nodesToCopy = action.payload.reduce(
        (acc: { [key: string]: UserBuildingWall }, cur) => ({
          ...acc,
          [cur.guid]: cur,
        }),
        {}
      );
    },
    setAffectedNodes: (state, action: PayloadAction<UserBuildingWall[]>) => {
      state.affectedNodes = action.payload.reduce(
        (acc: { [key: string]: UserBuildingWall }, cur) => ({
          ...acc,
          [cur.guid]: cur,
        }),
        {}
      );
    },
    resetCopyProperties: (state) => {
      state.nodesToCopy = initialState.nodesToCopy;
      state.affectedNodes = initialState.affectedNodes;
    },
  },
});

export const { setNodesToCopy, setAffectedNodes, resetCopyProperties } =
  copyPropertiesSlice.actions;

export const getNodesToCopy = (state: RootState) =>
  state.canvasReducer.copyProperties.nodesToCopy;

export const getAffectedNodes = (state: RootState) =>
  state.canvasReducer.copyProperties.affectedNodes;

export const getIsNodeAffectedByCopying =
  (guid: string) =>
  (state: RootState): boolean =>
    !!state.canvasReducer.copyProperties.affectedNodes[guid];

export const isCopyingProperties = (state: RootState): boolean =>
  Object.keys(state.canvasReducer.copyProperties.nodesToCopy).length > 0;
