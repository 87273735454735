import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/store';

interface SharedState {
  measurementErrors: string[];
  mullionErrors: string[];
}

const initialState: SharedState = {
  measurementErrors: [],
  mullionErrors: [],
};

export const sharedSlice = createSlice({
  name: 'shared',
  initialState,
  reducers: {
    setMeasurementErrors: (state, action: PayloadAction<string[]>) => {
      state.measurementErrors = action.payload;
    },
    setMullionErrors: (state, action: PayloadAction<string[]>) => {
      state.mullionErrors = action.payload;
    },
  },
});

export const { setMeasurementErrors, setMullionErrors } = sharedSlice.actions;

export const getMeasurementErrors = (state: RootState) =>
  state.canvasReducer.shared.measurementErrors;

export const getMullionErrors = (state: RootState) =>
  state.canvasReducer.shared.mullionErrors;

export const isMullionPlacementError = (state: RootState) =>
  state.canvasReducer.shared.mullionErrors.length > 0;
