import React, { useEffect, useMemo, useRef, useState } from 'react';
import { MullionData } from '@/models/window-configurator.model';
import { Line } from 'react-konva';
import { FlatVector2 } from '@/models';
import { generateClosedContourPointsFromPointArray } from '@/components/WindowCreator/elements/creator-windows.helpers';
import { UnitTypeCreatorModes } from '@/models/shared.model';
import { KonvaEventObject } from 'konva/lib/Node';
import { UTCElementType } from '@/components/WindowCreator/models/konva-model';
import Konva from 'konva';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';

import { COMMON_LINE_STROKE_WIDTH } from '@/shared/constants';
import {
  clearSelectRelatedData,
  getIsUTCNodeSelected,
  getUnitTypeCreatorMode,
  resetHoverArea,
  setHoverArea,
} from '@/store/slices/windowsReducer/UTCSlice';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import { generateUTCMullion } from '@/components/UTC/helpers/generators';
import { publish } from '@/core/events';
import { DELETE_UTC_MULLION } from '@/core/event-names';
import { useUTCSelect } from '@/components/UTC/hooks/useUTCSelect';
interface UTCMullionProps {
  framePoints: FlatVector2[];
  mullionData: MullionData;
  color: string;
}
const UTCMullion = ({ mullionData, color, framePoints }: UTCMullionProps) => {
  const { colors: configColors, panel: panelConfig } =
    useFetchWindowConfigQuery().data!;
  const mullionRef = useRef<Konva.Line>(null);
  const dispatch = useAppDispatch();

  const mode = useAppSelector(getUnitTypeCreatorMode);
  const isSelectionMode = mode === UnitTypeCreatorModes.Selection;

  const axis = mullionData.points;

  const [mullionPointsToDraw, setMullionPointsToDraw] = useState<FlatVector2[]>(
    []
  );

  const isSelected = useAppSelector(
    getIsUTCNodeSelected(mullionRef.current?._id)
  );

  const handleKeyDown = (event: KeyboardEvent) => {
    event.preventDefault();
    event.stopPropagation();
    if (event.key === 'Backspace' || event.key === 'Delete') {
      publish(DELETE_UTC_MULLION, mullionData);
      dispatch(clearSelectRelatedData());
    }
  };

  useEffect(() => {
    isSelected && document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [isSelected]);

  const mullionBorderPoints = useMemo(
    () => generateClosedContourPointsFromPointArray(mullionPointsToDraw),
    [mullionPointsToDraw]
  );

  const { selectElementInUTC } = useUTCSelect(
    mullionRef?.current?._id ?? 0,
    mullionPointsToDraw
  );

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    selectElementInUTC(UTCElementType.Mullion, mullionBorderPoints, event);
  };

  useEffect(() => {
    const mullionPoints = generateUTCMullion(axis, framePoints, panelConfig);
    setMullionPointsToDraw(mullionPoints);
  }, [mullionData]);

  const handlePointerEnter = () => {
    isSelectionMode && dispatch(setHoverArea(mullionBorderPoints));
  };

  return (
    <Line
      ref={mullionRef}
      onClick={handleClick}
      onMouseOver={handlePointerEnter}
      onMouseLeave={() => isSelectionMode && dispatch(resetHoverArea())}
      points={mullionPointsToDraw.flat()}
      closed
      fill={getHexByName(configColors, color)}
      stroke="#8D8B8F"
      strokeWidth={COMMON_LINE_STROKE_WIDTH}
      strokeScaleEnabled={false}
    />
  );
};

export default UTCMullion;
