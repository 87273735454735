import {
  addSelectedPlacedWindow,
  addToSelectedGridLines,
  addToSelectedPlacedPanel,
  getFacadeDesignerMode,
  removeFromSelectedGridLines,
  removeFromSelectedPlacedPanels,
  removeFromSelectedPlacedWindow,
  resetSelectedGridLines,
  resetSelectedPlacedPanels,
  setSelectedGridLines,
  setSelectedPlacedPanel,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FacadeDesignerModes } from '@/models/shared.model';
import {
  GridLineDataForFD,
  PanelPlacementDataForFD,
  WindowPlacementDataForFD,
} from '@/components/FacadeDesigner/models';

type CommonDataForSelection<T> = T & {
  multiSelect: boolean;
  isSelected: boolean;
};

export const useFacadeDesignerSelection = () => {
  const dispatch = useAppDispatch();

  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  const handleSelectWindow = ({
    multiSelect,
    data,
    isSelected,
  }: CommonDataForSelection<{
    data: WindowPlacementDataForFD;
  }>) => {
    if (!isSelectionMode) return;

    if (!multiSelect) {
      dispatch(setSelectedPlacedWindow([data]));
      dispatch(resetSelectedGridLines());
      dispatch(resetSelectedPlacedPanels());
    } else {
      isSelected
        ? dispatch(removeFromSelectedPlacedWindow(data))
        : dispatch(addSelectedPlacedWindow(data));
    }
  };
  const handleSelectGridLine = ({
    multiSelect,
    data,
    isSelected,
  }: CommonDataForSelection<{
    data: GridLineDataForFD;
  }>) => {
    if (!isSelectionMode) return;

    if (!multiSelect) {
      dispatch(setSelectedGridLines([data]));
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedPlacedPanels());
    } else {
      isSelected
        ? dispatch(removeFromSelectedGridLines(data))
        : dispatch(addToSelectedGridLines(data));
    }
  };

  const handleSelectPanel = ({
    multiSelect,
    panelData,
    isSelected,
  }: CommonDataForSelection<{
    panelData: PanelPlacementDataForFD;
  }>) => {
    if (!isSelectionMode) return;

    if (!multiSelect) {
      dispatch(setSelectedPlacedPanel(panelData));
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
    } else {
      isSelected
        ? dispatch(removeFromSelectedPlacedPanels(panelData.guid))
        : dispatch(addToSelectedPlacedPanel(panelData));
    }
  };

  return {
    handleSelectGridLine,
    handleSelectWindow,
    handleSelectPanel,
    isSelectionMode,
  };
};
