import React, { useEffect, useMemo, useRef } from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Group } from 'react-konva';

import { calculateInnerWindowPoints } from '@/components/WindowCreator/elements/creator-windows.helpers';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getIsWindowNodeSelected,
  getMultiMeasurementActiveStatus,
  getValidationErrors,
  resetHoverArea,
  setHoverArea,
  setMullionArea,
  setValidationError,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { FlatVector2, UnitSystemTypes } from '@/models';
import { WindowElementType } from '@/components/WindowCreator/models/konva-model';
import WindowGlass from '@/components/WindowCreator/elements/WindowGlass';
import { WindowCreatorModes } from '@/models/shared.model';
import { useWindowCreatorSelect } from '@/components/WindowCreator/elements/shared/useWindowCreatorSelect';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import PlacementElementError from './PlacementElementError';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import useIguWindowValidation from '../../hooks/useWindowIguValidation';
import { OperationType } from '@/models/window-configurator.model';
import { INVALID_IGU_CONFIG_TITLE } from '../../constants';
import useValidateWindowErrors from '../../hooks/useValidateWindowErrors';

interface FixedWindowProps {
  points: FlatVector2[];
  outerFramePoints: FlatVector2[];
  independent?: boolean;
  distanceToFloor?: number;
  iguId: number | null;
  scale: number;
  units?: UnitSystemTypes;
  mode?: WindowCreatorModes;
}

export const FixedWindow = ({
  points,
  independent,
  outerFramePoints,
  distanceToFloor,
  iguId,
  scale,
  units,
  mode,
}: FixedWindowProps) => {
  const { panel: panelsConfig } = useFetchWindowConfigQuery().data!;
  const glassRef = useRef<Konva.Line>(null);
  const validationErrors = useAppSelector(
    getValidationErrors(glassRef?.current?._id ?? 0)
  );

  const glassId = useMemo(() => glassRef.current?._id, [glassRef.current?._id]);
  const dispatch = useAppDispatch();
  const multiMeasurementStatus = useAppSelector(
    getMultiMeasurementActiveStatus
  );
  const isSelected = useAppSelector(getIsWindowNodeSelected(glassId ?? 0));
  const IGUData = useFetchIguQuery().data!;

  const { selectElementInWindowCreator } = useWindowCreatorSelect(
    glassId ?? 0,
    points
  );
  const iguItem = useMemo(() => {
    if (!iguId || !mode) return null;
    return IGUData.find((item) => item.id === iguId);
  }, [IGUData, iguId]);

  const isSelectionMode = mode === WindowCreatorModes.Selection;

  const glassPoints = useMemo(
    () =>
      calculateInnerWindowPoints(
        points,
        outerFramePoints,
        panelsConfig.mullion.width
      ),
    [points, outerFramePoints]
  );

  const { collectIguValidationData, validateIguWindow } =
    useIguWindowValidation();
  const { validateWindowErrors } = useValidateWindowErrors();

  useEffect(() => {
    if (!iguItem || !distanceToFloor || !mode || !glassId) return;

    const validationData = collectIguValidationData({
      operationType: OperationType.Fixed,
      innerFramePoints: points,
      outerFramePoints,
      distanceToFloor,
      glassType: iguItem.glassType,
      isOperableType: false,
    });

    const iguErrors: string[] = validateIguWindow(
      validationData,
      iguItem.limitations,
      iguItem.thickness,
      units === UnitSystemTypes.Imperial
    );

    const commonErrors = validateWindowErrors({
      innerFramePoints: points,
      outerFramePoints,
    });

    const errors = [...iguErrors, ...commonErrors];

    dispatch(
      setValidationError({
        id: glassId,
        errors,
      })
    );

    isSelected && handleSelect();
    return () => {
      dispatch(
        setValidationError({
          id: glassId,
          errors: [],
        })
      );
    };
  }, [points, distanceToFloor, iguItem, units, glassId]);

  const handleSelect = (event?: KonvaEventObject<MouseEvent>) => {
    if (!independent) return;
    selectElementInWindowCreator(WindowElementType.Window, glassPoints, event);
  };

  useEffect(() => {
    isSelected && handleSelect();
  }, [glassPoints]);

  const handleMouseOver = () => {
    independent && isSelectionMode && dispatch(setHoverArea(glassPoints));
    mode === WindowCreatorModes.MullionCreator &&
      !multiMeasurementStatus &&
      dispatch(setMullionArea(points));
  };

  const handleMouseLeave = () => {
    independent && isSelectionMode && dispatch(resetHoverArea());
  };

  return (
    <>
      <Group onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
        <WindowGlass
          points={glassPoints.flat()}
          onClick={handleSelect}
          ref={glassRef}
        />
      </Group>
      {validationErrors?.length > 0 && (
        <PlacementElementError
          position={[glassPoints[0][1][0], glassPoints[0][1][1]]}
          errorsTitle={INVALID_IGU_CONFIG_TITLE}
          errorList={validationErrors}
          hoverArea={glassPoints}
          scale={scale}
        />
      )}
    </>
  );
};
