import React, { useCallback, useEffect, useMemo, useState } from 'react';
import * as THREE from 'three';
import {
  convertFlatVector3ToVectors,
  createGeometryFromVectorList,
  isValidSplitPosition,
  updateGeometryFromVectorList,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  C_FatLineBorderMaterial,
  C_LeftSplitFaceMaterial,
  C_RightSplitFaceMaterial,
  LEFT_SPLIT_FACE_COLOR,
  RIGHT_SPLIT_FACE_COLOR,
} from '@/shared/materials';
import { FlatVector3 } from '@/models';
import {
  createLine2,
  ExtendedMinMaxCoordinatesPairs,
  isPointsInOneLine,
  updateLine2Position,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { BufferGeometry } from 'three';
import { disposeNode } from '@/shared/helpers/canvas';

const SplitFaces = ({
  mousePosition,
  minMaxCoordinates,
  wallPoints,
  isBuildingClockwise,
}: {
  mousePosition: THREE.Vector3;
  minMaxCoordinates: ExtendedMinMaxCoordinatesPairs;
  wallPoints: FlatVector3[];
  isBuildingClockwise?: boolean;
}) => {
  const [leftLine, setLeftLine] = useState<Line2>();
  const [rightLine, setRightLine] = useState<Line2>();
  const [leftFaceGeometry, setLeftFaceGeometry] = useState<BufferGeometry>();
  const [rightFaceGeometry, setRightFaceGeometry] = useState<BufferGeometry>();
  const [bottomLeftLine, setBottomLeftLine] = useState<Line2>();
  const [bottomRightLine, setBottomRightLine] = useState<Line2>();

  const mousePointOnWall = useMemo(() => {
    const vectorWallPoints = convertFlatVector3ToVectors(wallPoints);
    const mouseOnWall = new THREE.Vector3();
    new THREE.Line3(
      vectorWallPoints[0],
      vectorWallPoints[1]
    ).closestPointToPoint(mousePosition, true, mouseOnWall);
    return mouseOnWall;
  }, [mousePosition, wallPoints]);

  const positionMinY: FlatVector3 = [
    mousePointOnWall.x,
    minMaxCoordinates.min.y,
    mousePointOnWall.z,
  ];

  const positionMaxY: FlatVector3 = [
    mousePointOnWall.x,
    minMaxCoordinates.max.y,
    mousePointOnWall.z,
  ];
  const faceCoordinates1 = useMemo(
    () =>
      convertFlatVector3ToVectors([
        [wallPoints[1][0], minMaxCoordinates.min.y, wallPoints[1][2]],
        [wallPoints[2][0], minMaxCoordinates.max.y, wallPoints[2][2]],
        positionMaxY,
        positionMinY,
      ]),
    [mousePosition]
  );

  const faceCoordinates2 = useMemo(
    () =>
      convertFlatVector3ToVectors([
        positionMinY,
        positionMaxY,
        [wallPoints[3][0], minMaxCoordinates.max.y, wallPoints[3][2]],
        [wallPoints[0][0], minMaxCoordinates.min.y, wallPoints[0][2]],
      ]),
    [mousePosition]
  );

  const checkIsMouseAndWallInLine = () =>
    isPointsInOneLine(
      [wallPoints[1][0], wallPoints[1][2]],
      [mousePointOnWall.x, mousePointOnWall.z],
      [wallPoints[0][0], wallPoints[0][2]]
    );

  const handleGeometriesChange = (
    points: THREE.Vector3[],
    geometry: THREE.BufferGeometry | undefined,
    setter: (geometry: THREE.BufferGeometry) => void
  ) => {
    if (!checkIsMouseAndWallInLine()) return;

    if (!geometry) {
      setter(createGeometryFromVectorList(points, 'vertical'));
    } else {
      updateGeometryFromVectorList(geometry, points, 'vertical');
    }
  };

  const updateLines = useCallback(
    (cursorPosition: THREE.Vector3) => {
      if (!checkIsMouseAndWallInLine()) return;

      const leftLinePoints = [
        wallPoints[2][0],
        minMaxCoordinates.max.y,
        wallPoints[2][2],
        cursorPosition.x,
        minMaxCoordinates.max.y,
        cursorPosition.z,
      ];
      const bottomLeftLinePoints = [
        wallPoints[2][0],
        minMaxCoordinates.min.y,
        wallPoints[2][2],
        cursorPosition.x,
        minMaxCoordinates.min.y,
        cursorPosition.z,
      ];
      const rightLinePoints = [
        cursorPosition.x,
        minMaxCoordinates.max.y,
        cursorPosition.z,
        wallPoints[3][0],
        minMaxCoordinates.max.y,
        wallPoints[3][2],
      ];
      const bottomRightLinePoints = [
        cursorPosition.x,
        minMaxCoordinates.min.y,
        cursorPosition.z,
        wallPoints[3][0],
        minMaxCoordinates.min.y,
        wallPoints[3][2],
      ];

      if (!leftLine) {
        const material = C_FatLineBorderMaterial.clone();
        material.color = LEFT_SPLIT_FACE_COLOR;
        material.linewidth = material.linewidth * 3;
        material.depthTest = false;
        setLeftLine(createLine2(leftLinePoints, material));
        setBottomLeftLine(createLine2(bottomLeftLinePoints, material));
      }
      if (!rightLine) {
        const material = C_FatLineBorderMaterial.clone();
        material.color = RIGHT_SPLIT_FACE_COLOR;
        material.linewidth = material.linewidth * 3;
        material.depthTest = false;
        setRightLine(createLine2(rightLinePoints, material));
        setBottomRightLine(createLine2(bottomRightLinePoints, material));
      }

      leftLine && updateLine2Position(leftLine, leftLinePoints);
      rightLine && updateLine2Position(rightLine, rightLinePoints);

      bottomLeftLine &&
        updateLine2Position(bottomLeftLine, bottomLeftLinePoints);
      bottomRightLine &&
        updateLine2Position(bottomRightLine, bottomRightLinePoints);
    },
    [wallPoints, minMaxCoordinates]
  );

  useEffect(() => {
    if (!isValidSplitPosition(mousePosition, wallPoints)) return;

    const pointsForLeftGeometry = isBuildingClockwise
      ? faceCoordinates2
      : faceCoordinates1;
    handleGeometriesChange(
      pointsForLeftGeometry,
      leftFaceGeometry,
      setLeftFaceGeometry
    );

    const pointsForRightGeometry = isBuildingClockwise
      ? faceCoordinates1
      : faceCoordinates2;
    handleGeometriesChange(
      pointsForRightGeometry,
      rightFaceGeometry,
      setRightFaceGeometry
    );
    updateLines(mousePointOnWall);
  }, [mousePointOnWall, isBuildingClockwise]);

  useEffect(() => {
    return () => {
      leftLine && disposeNode(leftLine);
      rightLine && disposeNode(rightLine);
      bottomLeftLine && disposeNode(bottomLeftLine);
      bottomRightLine && disposeNode(bottomRightLine);
      leftFaceGeometry && leftFaceGeometry.dispose();
      rightFaceGeometry && rightFaceGeometry.dispose();
    };
  }, [
    leftLine,
    rightLine,
    bottomLeftLine,
    bottomRightLine,
    leftFaceGeometry,
    rightFaceGeometry,
  ]);

  return (
    <group>
      {leftFaceGeometry && (
        <mesh geometry={leftFaceGeometry} material={C_LeftSplitFaceMaterial} />
      )}
      {rightFaceGeometry && (
        <mesh
          geometry={rightFaceGeometry}
          material={C_RightSplitFaceMaterial}
        />
      )}
      {leftLine && <primitive object={leftLine} position={[0, 0.0001, 0]} />}
      {rightLine && <primitive object={rightLine} position={[0, 0.0001, 0]} />}
      {bottomLeftLine && <primitive object={bottomLeftLine} />}
      {bottomRightLine && <primitive object={bottomRightLine} />}
    </group>
  );
};

export default SplitFaces;
