import React from 'react';
import { IntusIconButton } from '@/shared/elements';
import { ButtonProps } from 'antd/es/button';

interface ToolbarButtonProps extends ButtonProps {
  tooltipText?: string;
  tooltipSubText?: string;
  isActive?: boolean;
}

const ToolbarButton: React.FC<ToolbarButtonProps> = ({
  tooltipText,
  tooltipSubText,
  isActive = false,
  ...rest
}) => {
  return (
    <IntusIconButton
      tooltipText={tooltipText}
      tooltipSubText={tooltipSubText}
      simplified
      size={'large'}
      isActive={isActive}
      {...rest}
    />
  );
};

export default ToolbarButton;
