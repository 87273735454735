import get from 'lodash/get';
import notification from 'antd/es/notification';
import { ArgsProps } from 'antd/es/notification';
import { CustomError } from '@/models';
import '../elements/ShadowNotification/AntdShadowNotification.scss';

export const extractErrorMessage = (err: CustomError | unknown): string => {
  return get(err, 'data[0].errorMessage', 'Something Went wrong');
};

const ignoredTexts = [
  'Project name already exists',
  'Window draft name already exists',
];

export const NotificationService = {
  error: (error: CustomError | unknown) => {
    const errorText = extractErrorMessage(error);
    return (
      !ignoredTexts.includes(errorText) &&
      notification.error({
        message: 'Error',
        description: extractErrorMessage(error),
      })
    );
  },
  success: (argsProps: ArgsProps) =>
    notification.success({
      message: 'Success',
      description: argsProps.message,
    }),
  info: (argsProps: ArgsProps) =>
    notification.info({ message: argsProps.message }),
  warning: (argsProps: ArgsProps) =>
    notification.warning({ message: argsProps.message }),
  shadow: (argsProps: ArgsProps) =>
    notification.open({
      ...argsProps,
      placement: 'top',
      className: 'shadow-notification',
    }),
  close: (key?: string) => notification.destroy(key),
};
