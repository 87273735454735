import React, { useEffect, useRef, useState } from 'react';

import { Resizable } from 're-resizable';
import { publish, subscribe, unsubscribe } from '@/core/events';
import { MAKE_SCREENSHOT, OPEN_WINDOW_CREATOR } from '@/core/event-names';
import { makeScreenshotDelay } from '@/shared/helpers/camera';
import { sleep } from '@/shared/helpers/sleep';
import BrowserPanel from './BrowserPanel';
import LibraryPanel from './LibraryPanel/LibraryPanel';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getCanvasMode, setUTCMode } from '@/store/slices/canvasModesSlice';
import { CanvasActionsModes, FilterType, UTCModes } from '@/models';
import { LEFT_PANEL_DEFAULT_WIDTH } from '@/shared/constants';

import {
  useFetchProjectQuery,
  useUpdateProjectMutation,
} from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { PlusIcon } from '@/shared/icons/PlusIcon';
import { IntusButton, IntusIconButton } from '@/shared/elements';
import { UTCInnerCornerIcon } from '@/shared/icons/UTCInnerCornerIcon';
import { UTCOuterCornerIcon } from '@/shared/icons/UTCOuterCornerIcon';
import { UTCStraightIcon } from '@/shared/icons/UTCStraightIcon';
import { useOutsideMouseDown } from '@/shared/hooks';
import { Checkmark, WindowLibraryIcon } from '@/shared/icons';
import FilterIcon from '@/shared/icons/FilterIcon';
import { CreateUnitTypeIcon } from '@/shared/icons/CreateUnitTypeIcon';

enum LeftPanelViews {
  Browser,
  WindowLibrary,
}

const LeftPanel = () => {
  const { id } = useParams();
  const mode = useAppSelector(getCanvasMode);
  const isLocked = useFetchProjectQuery(id!).data?.locked;
  const isPlacementMode = mode === CanvasActionsModes.facadeDesigner;
  const [updateProject] = useUpdateProjectMutation();
  const dispatch = useAppDispatch();
  const [view, setView] = useState(LeftPanelViews.Browser);
  const [previousView, setPreviousView] = useState(LeftPanelViews.Browser);
  const [isAnimating, setIsAnimating] = useState(false);
  const [showListCreators, setShowListCreators] = useState(false);
  const [showFilterOptions, setShowFilterOptions] = useState(false);
  const [currentFilter, setCurrentFilter] = useState(FilterType.All);
  const typeDesignerRef = useRef<HTMLDivElement>(null!);
  const filterOptionsRef = useRef<HTMLDivElement>(null!);

  const creatorButtons = [
    {
      mode: UTCModes.Straight,
      icon: <UTCStraightIcon fill="#414042" />,
      label: 'Create straight unit',
    },
    {
      mode: UTCModes.InnerCorner,
      icon: <UTCInnerCornerIcon fill="#414042" />,
      label: 'Create inner corner unit',
    },
    {
      mode: UTCModes.OuterCorner,
      icon: <UTCOuterCornerIcon fill="#414042" />,
      label: 'Create outer corner unit',
    },
    {
      mode: CanvasActionsModes.createWindow,
      icon: <WindowLibraryIcon fill="#414042" className="w-[17px] h-[16px]" />,
      label: 'Create window draft',
    },
  ];

  const filterButtons = [
    {
      mode: FilterType.All,
      label: 'All',
    },
    {
      mode: FilterType.Units,
      label: 'UCW Unit Type',
    },
    {
      mode: FilterType.Windows,
      label: 'Window Draft',
    },
  ];

  const getFilterIcon = (mode: FilterType, color: string) => {
    switch (mode) {
      case FilterType.All:
        return <Checkmark fill={color} className="w-4 h-4 px-0.5" />;
      case FilterType.Units:
        return (
          <CreateUnitTypeIcon
            stroke={color}
            fill={color}
            className="w-[18px] h-[19.5px]"
          />
        );
      case FilterType.Windows:
        return <WindowLibraryIcon fill={color} className="w-[17px] h-[16px]" />;
    }
  };

  useEffect(() => {
    subscribe(MAKE_SCREENSHOT, toggleAnimation);
    return () => {
      unsubscribe(MAKE_SCREENSHOT, toggleAnimation);
    };
  }, []);

  const handleOpenCreator = (mode: UTCModes | CanvasActionsModes) => {
    switch (mode) {
      case CanvasActionsModes.createWindow:
        publish(OPEN_WINDOW_CREATOR);
        break;
      case UTCModes.Straight:
      case UTCModes.InnerCorner:
      case UTCModes.OuterCorner:
        dispatch(setUTCMode(mode));
        break;
      default:
        break;
    }
    setShowListCreators(false);
  };

  const handleClickFilter = (mode: FilterType) => {
    setCurrentFilter(mode);
    setShowFilterOptions(false);
  };

  const toggleAnimation = async () => {
    setIsAnimating(true);
    await sleep(makeScreenshotDelay);
    setIsAnimating(false);
  };

  useEffect(() => {
    if (isPlacementMode) {
      setPreviousView(view);
      setView(LeftPanelViews.WindowLibrary);
    } else {
      setView(previousView);
    }
  }, [isPlacementMode]);

  const layout = useFetchProjectQuery(id!).data?.layout;

  const panelWidth = layout?.leftPanelWidth ?? LEFT_PANEL_DEFAULT_WIDTH;
  const isWindowLibrary = view === LeftPanelViews.WindowLibrary;

  useOutsideMouseDown(typeDesignerRef, () => setShowListCreators(false));
  useOutsideMouseDown(filterOptionsRef, () => setShowFilterOptions(false));

  return (
    <div
      className={`absolute left-0 top-0 h-full z-20 flex flex-col max-h-full border border-light-gray-20 border-solid border-t-0 duration-500 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}
    >
      <Resizable
        enable={{
          top: false,
          right: true,
          bottom: false,
          left: false,
          topRight: false,
          bottomRight: false,
          bottomLeft: false,
          topLeft: false,
        }}
        minWidth={LEFT_PANEL_DEFAULT_WIDTH}
        maxWidth={500}
        defaultSize={{ width: panelWidth, height: '100%' }}
        className="h-full!"
        onResizeStop={(_, __, elementRef) => {
          updateProject({
            id: Number(id),
            layout: {
              leftPanelWidth: elementRef.offsetWidth,
              leftPanelHeight: layout?.leftPanelHeight || 0,
            },
          });
        }}
      >
        <div className="flex bg-white font-medium text-xs items-center px-3 py-1 justify-between border-0 border-b border-solid border-light-gray-20">
          <div className="flex items-center gap-2">
            <div
              className={`py-1 ${isPlacementMode ? 'cursor-not-allowed opacity-20!' : ''} ${view === LeftPanelViews.WindowLibrary && !isPlacementMode ? 'font-normal opacity-40 cursor-pointer' : 'cursor-default'}`}
              onClick={() =>
                !isPlacementMode && setView(LeftPanelViews.Browser)
              }
            >
              Browser
            </div>
            <div
              className={`py-1 ${view === LeftPanelViews.Browser ? 'font-normal opacity-40 cursor-pointer' : 'cursor-default'}`}
              onClick={() =>
                !isPlacementMode && setView(LeftPanelViews.WindowLibrary)
              }
            >
              Library
            </div>
          </div>
          {isWindowLibrary && (
            <div className="relative" ref={typeDesignerRef}>
              <IntusIconButton
                onClick={() => setShowListCreators((prev) => !prev)}
                simplified
                size={'small'}
                disabled={isLocked}
                icon={<PlusIcon fill="#000" stroke="#fff" />}
                id="left-panel__plus-button"
              />
              {showListCreators && (
                <div className="modal-shadow actions absolute bottom-[-153px] left-[-76px] flex flex-col gap-1 p-0.5 z-50 justify-center box-border bg-white items-center rounded-lg">
                  {creatorButtons.map(({ mode, icon, label }) => (
                    <IntusButton
                      key={mode}
                      small
                      block
                      type="default"
                      extraStyles="justify-start!"
                      icon={icon}
                      onClick={() => handleOpenCreator(mode)}
                      id={`left-panel__${mode}-button`}
                    >
                      {label}
                    </IntusButton>
                  ))}
                </div>
              )}
            </div>
          )}
        </div>
        {isWindowLibrary && (
          <div
            ref={filterOptionsRef}
            className="flex bg-white font-medium text-xs items-center px-3 py-1 justify-end border-0 border-b border-solid border-light-gray-20"
          >
            <div className="relative">
              <IntusIconButton
                onClick={() => setShowFilterOptions((prev) => !prev)}
                simplified
                size={'small'}
                icon={
                  <FilterIcon className="fill-dark-gray-100 hover:fill-light-gray-10" />
                }
                id="left-panel__filter-button"
              />
              {showFilterOptions && (
                <div className="modal-shadow absolute bottom-[-113px] left-[-60px] flex flex-col gap-1 p-0.5 z-50 justify-center box-border bg-white items-center rounded-lg">
                  {filterButtons.map(({ mode, label }) => (
                    <IntusButton
                      key={mode}
                      small
                      onClick={() => handleClickFilter(mode)}
                      block
                      type="default"
                      isActive={mode === currentFilter}
                      extraStyles="justify-start!"
                      icon={getFilterIcon(
                        mode,
                        mode === currentFilter ? '#fff' : '#414042'
                      )}
                      id={`left-panel__filter-${mode}-button`}
                    >
                      {label}
                    </IntusButton>
                  ))}
                </div>
              )}
            </div>
          </div>
        )}
        <div
          className={`text-xs font-light border-0 border-r border-b border-solid border-light-gray-20 flex-1 ${isWindowLibrary ? 'h-[calc(100%_-_4rem)]' : 'h-[calc(100%_-_2rem)]'} bg-white! [&_.ant-collapse]:border-none! [&_.ant-collapse-header-text]:w-[90%] overflow-y-auto`}
        >
          {view === LeftPanelViews.Browser && <BrowserPanel />}
          {isWindowLibrary && <LibraryPanel filter={currentFilter} />}
        </div>
      </Resizable>
    </div>
  );
};

export default LeftPanel;
