import React from 'react';
import { Rect } from 'react-konva';
import PlacementElementError from '@/components/WindowCreator/elements/InnerWindow/PlacementElementError';
import { UnitSystemTypes, WallPanelError } from '@/models';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import { useAppSelector } from '@/store/hooks';
import {
  getDragNode,
  isItemSelectedOnWall,
  isWallMeasurementActive,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';

interface PanelErrorProps {
  errorData: WallPanelError;
  wallHeight: number;
  scale: number;
  wallGUID: string;
  unitSystem: UnitSystemTypes;
}

const ErrorPanel: React.FC<PanelErrorProps> = ({
  scale,
  errorData,
  wallHeight,
  unitSystem,
  wallGUID,
}) => {
  const dragNode = useAppSelector(getDragNode);
  const isSelectedWall = useAppSelector(isItemSelectedOnWall(wallGUID!));
  const isMeasurementActiveWall = useAppSelector(isWallMeasurementActive);
  const isHidden = isSelectedWall && (dragNode || isMeasurementActiveWall);

  if (isHidden) return null;

  const getErrorMessage = () => {
    if (errorData.message.length === 0) return undefined;
    return [
      errorData.message.replace(
        '${value}',
        unitSystem == UnitSystemTypes.Metric
          ? errorData.value.toString()
          : convertMillimetersToFtInch(errorData.value)
      ),
    ];
  };

  return (
    <>
      <Rect
        width={errorData.width}
        height={wallHeight}
        x={errorData.offsetFromLeftEdge}
        fill="#D23736"
        opacity={0.2}
        listening={false}
      />
      <PlacementElementError
        position={[errorData.offsetFromLeftEdge + errorData.width - 50, 50]}
        errorList={getErrorMessage()}
        scale={scale}
      />
    </>
  );
};

export default ErrorPanel;
