import { OperationType } from '@/models/window-configurator.model';
import { FlatVector2 } from '@/models';
import {
  calculateInnerWindowPoints,
  calculateRealWindowGlassPoints,
  generateInnerFramePoints,
} from '@/components/WindowCreator/elements/creator-windows.helpers';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { getOperableFrameWidth } from '@/shared/helpers';
import { get2DDistance } from '@/shared/helpers/konva';
import { convertSquareMillimetersToSquareMeters } from '@/shared/helpers/distance';
import { inRange, round } from 'lodash';
import { IguValidationData } from '@/shared/elements/IguSelect/IguSelect';
import { GlassTypeValue, IGU_ERRORS, IguLimitations } from '@/models/webcalc';
import { calculateGlassRatio, validateGlassRatio } from '@/shared/helpers/Igu';

const useIguWindowValidation = () => {
  const { panel: panelsConfig, limitations } =
    useFetchWindowConfigQuery().data!;

  const operableBorderThickness = getOperableFrameWidth(panelsConfig);

  const calculateGlassPointsForValidation = ({
    operationType,
    innerFramePoints,
    outerFramePoints,
  }: {
    operationType: OperationType;
    innerFramePoints: FlatVector2[];
    outerFramePoints: FlatVector2[];
  }) => {
    let realWindowGlassPoints: FlatVector2[] = [];

    const outerContourPoints = calculateInnerWindowPoints(
      innerFramePoints,
      outerFramePoints,
      panelsConfig.mullion.width
    );

    if (operationType === OperationType.Fixed) {
      const visibleGlassPoints = outerContourPoints.map((p, i) =>
        generateInnerFramePoints(p, i, 0)
      );
      realWindowGlassPoints = visibleGlassPoints.map((p, i) =>
        calculateRealWindowGlassPoints(
          p[0],
          i,
          panelsConfig.glass.insertedFixed
        )
      );
    } else {
      const visibleGlassPoints = outerContourPoints.map((p, i) =>
        generateInnerFramePoints(p, i, operableBorderThickness)
      );

      realWindowGlassPoints = visibleGlassPoints.map((p, i) =>
        calculateRealWindowGlassPoints(
          p[0],
          i,
          panelsConfig.glass.insertedOperable
        )
      );
    }

    return realWindowGlassPoints;
  };

  const collectIguValidationData = ({
    operationType,
    innerFramePoints,
    outerFramePoints,
    distanceToFloor,
    glassType,
    isOperableType,
  }: {
    operationType: OperationType;
    innerFramePoints: FlatVector2[];
    outerFramePoints: FlatVector2[];
    distanceToFloor: number;
    glassType: GlassTypeValue;
    isOperableType: boolean;
  }) => {
    const realWindowGlassPoints = calculateGlassPointsForValidation({
      operationType,
      innerFramePoints,
      outerFramePoints,
    });
    const horizontalGlassLine = [
      realWindowGlassPoints[3],
      realWindowGlassPoints[0],
    ];

    const verticalGlassLine = [
      realWindowGlassPoints[0],
      realWindowGlassPoints[1],
    ];

    const glassHeight = get2DDistance(
      verticalGlassLine[0],
      verticalGlassLine[1]
    );

    const glassWidth = get2DDistance(
      horizontalGlassLine[0],
      horizontalGlassLine[1]
    );

    const ratio = calculateGlassRatio(glassHeight, glassWidth);
    const windowArea = Number(
      convertSquareMillimetersToSquareMeters(glassHeight * glassWidth)
    );

    const windowHeight = round(
      outerFramePoints[2][1] - outerFramePoints[0][1],
      2
    );

    const topBorderLevel = distanceToFloor + windowHeight;

    return {
      isOperableType,
      glassType,
      glassHeight,
      glassWidth,
      ratio,
      windowArea,
      topBorderLevel,
      distanceToFloor,
    };
  };

  const validateIguWindow = (
    validationData: IguValidationData,
    limits: IguLimitations,
    iguThickness: number,
    isImperialUnits: boolean = false
  ) => {
    const ratioError = validateGlassRatio(
      validationData.glassHeight,
      validationData.glassWidth,
      limits.maxRatio
    );

    const windowArea = Number(
      convertSquareMillimetersToSquareMeters(
        validationData.glassHeight * validationData.glassWidth
      )
    );
    const temperedGlassError =
      validationData.glassType !== GlassTypeValue.T &&
      validationData.distanceToFloor <
        limitations.temperedGlassWindow.bottomHeight &&
      validationData.topBorderLevel >
        limitations.temperedGlassWindow.topHeight &&
      windowArea >
        Number(
          convertSquareMillimetersToSquareMeters(
            limitations.temperedGlassWindow.area
          )
        );

    const thicknessError =
      !validationData.isOperableType &&
      !inRange(
        iguThickness,
        limitations.iguThickness.min,
        limitations.iguThickness.max
      );

    return [
      ratioError ? IGU_ERRORS.maxRatioError : [],
      windowArea < limits.minArea ? IGU_ERRORS.minAreaError : [],
      windowArea > limits.maxArea ? IGU_ERRORS.maxAreaError : [],
      validationData.glassWidth < limits.minWidth
        ? IGU_ERRORS.minWidthError
        : [],
      validationData.glassWidth > limits.maxWidth
        ? IGU_ERRORS.maxWidthError
        : [],
      validationData.glassHeight < limits.minHeight
        ? IGU_ERRORS.minHeightError
        : [],
      validationData.glassHeight > limits.maxHeight
        ? IGU_ERRORS.maxHeightError
        : [],
      temperedGlassError ? IGU_ERRORS.temperedGlassError : [],
      thicknessError
        ? IGU_ERRORS.thicknessError(
            limitations.iguThickness.min,
            limitations.iguThickness.max,
            isImperialUnits
          )
        : [],
    ].flat();
  };

  return { collectIguValidationData, validateIguWindow };
};

export default useIguWindowValidation;
