import React from 'react';
import Popover from 'antd/es/popover';
import { PopoverProps } from 'antd/es/popover';
import './AntdPopover.scss';

const IntusPopover: React.FC<PopoverProps> = ({ ...restProps }) => {
  return <Popover rootClassName={'intus-popover'} {...restProps} />;
};

export default IntusPopover;
