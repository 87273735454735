import React, { useState, useEffect, useRef } from 'react';
import { InputRef } from 'antd/es/input';
import Form from 'antd/es/form';
import { InputProps } from 'antd/es/input';

import IntusInput from '../Input/Input';
import { processInputChange } from '@/shared/helpers/format-data';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { UnitSystemTypes } from '@/models';
import { useAppSelector } from '@/store/hooks';
import { useParams } from 'react-router';
import { limitsValidator } from '@/shared/form/validators';
import './EditableMetric.scss';
import { useUnmount } from 'react-use';
import { convertFtInchToMillimeters } from '@/shared/helpers/distance';
import { round } from 'mathjs';
import useHandleError from '@/shared/hooks/useHandleError';

interface EditableMetricProps {
  onEdit: (value: string) => void;
  onSubmit: () => void;
  value: string;
  inputProps?: InputProps;
  min?: number;
  max?: number;
  staticValue?: boolean;
  validationValueDecimalPlaces?: number;
}

export const EditableMetric: React.FC<EditableMetricProps> = ({
  onEdit,
  onSubmit,
  value,
  min,
  max,
  inputProps = {},
  staticValue,
  validationValueDecimalPlaces,
}) => {
  const { handleError } = useHandleError();
  const [isEditing, setIsEditing] = useState(false);
  const [originalValue, setOriginalValue] = useState(value);
  const [form] = Form.useForm<{ name: string }>();
  const [lastValidValue, setLastValidValue] = useState(value);
  const inputRef = useRef<InputRef>(null);
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  useEffect(() => {
    if (inputRef.current?.input !== document.activeElement) {
      form.setFieldValue('name', value);
    }
  }, [value]);

  const handleEditMetric = () => {
    form
      .validateFields()
      .then(() => {
        setLastValidValue(form.getFieldValue('name'));
        onEdit(form.getFieldValue('name'));
      })
      .catch((err) => handleError(err));
  };

  const validateAndBlur = () => {
    form
      .validateFields()
      .then(() => {
        setIsEditing(false);
        onSubmit();
        if (!inputRef.current) return;
        inputRef.current.blur();
      })
      .then(() => form.setFieldValue('name', value))
      .catch((e) => handleError(e));
  };

  const submitValidatedValue = () => {
    form
      .validateFields()
      .then(() => {
        const lastValidValueInMillimeters = isImperialUnits
          ? round(+convertFtInchToMillimeters(lastValidValue), 2)
          : lastValidValue;

        const originalValueInMillimeters = isImperialUnits
          ? round(+convertFtInchToMillimeters(originalValue), 2)
          : originalValue;

        lastValidValueInMillimeters !== originalValueInMillimeters &&
          onSubmit();
      })
      .then(() => form.setFieldValue('name', value))
      .catch(() => {
        onEdit(lastValidValue);
        form.setFieldsValue({ name: lastValidValue });
        onSubmit();
      })
      .finally(() => {
        setIsEditing(false);
        inputRef.current?.blur();
      });
  };

  const handleResetInput = () => {
    if (!inputRef.current) return;
    setIsEditing(false);
    inputRef.current.blur();
    onEdit(originalValue);
    form.setFieldsValue({ name: originalValue });
    setLastValidValue(originalValue);
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === 'Enter') {
      validateAndBlur();
    }
    if (event.key === 'Escape') {
      handleResetInput();
    }
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      inputRef.current?.input &&
      !inputRef.current.input?.contains(event.target as Node)
    ) {
      submitValidatedValue();
    }
  };

  const handleMouseLeave = () => {
    if (inputRef.current?.input !== document.activeElement) {
      setIsEditing(false);
    }
  };

  const handleMouseMove = () => {
    if (!isEditing) {
      setOriginalValue(form.getFieldValue('name'));
      setIsEditing(true);
    }
  };

  useEffect(() => {
    form.setFieldsValue({ name: value?.toString() });
  }, [isImperialUnits]);

  useEffect(() => {
    if (staticValue) {
      form.setFieldsValue({ name: value?.toString() });
    }
  }, [value]);

  useEffect(() => {
    isEditing &&
      inputRef.current?.input?.addEventListener('keydown', handleKeyDown);
    isEditing && document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      inputRef.current?.input?.removeEventListener('keydown', handleKeyDown);
    };
  }, [isEditing, value, handleClickOutside, lastValidValue]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.blur();
    }
  }, [isEditing]);

  useUnmount(() => {
    isEditing && submitValidatedValue();
  });

  const handleChange = () => {
    const input = inputRef.current?.input;

    if (!input) return;

    const formattedValue = processInputChange(
      input,
      isImperialUnits,
      staticValue
    );

    form.setFieldsValue({
      name: formattedValue,
    });

    handleEditMetric();
  };

  return (
    <Form form={form} className="max-w-full">
      <Form.Item
        name="name"
        className="custom-form-item mb-0"
        rules={
          min && max
            ? [
                limitsValidator({
                  min,
                  max,
                  isImperialUnits,
                  staticValue,
                  customValidationMessage: null,
                  decimalPlaces: validationValueDecimalPlaces,
                }),
              ]
            : []
        }
      >
        <IntusInput
          value={value}
          ref={inputRef}
          size="small"
          autoComplete="off"
          className={isEditing ? '' : 'transparent-border'}
          onChange={handleChange}
          onMouseLeave={handleMouseLeave}
          onMouseMove={handleMouseMove}
          {...inputProps}
        />
      </Form.Item>
    </Form>
  );
};
