import React, { useState } from 'react';
import { CornerGridAlign, UserBuildingPanel } from '@/models';
import ColorsIcon from '@/shared/icons/ColorsIcon';
import PickerPopover from '@/shared/elements/PickerPopover/PickerPopover';
import {
  getHexByName,
  getMaterialImage,
} from '@/components/WindowCreator/helpers/config';
import MaterialIcon from '@/shared/icons/MaterialIcon';
import { IntusLoader } from '@/shared/elements';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { calculateCommonArea } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import { NoColorIcon } from '@/shared/icons/NoColorIcon';
import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
  useUpdatePanelsMutation,
} from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import IguSelect from '@/shared/elements/IguSelect/IguSelect';
import { RALColors } from '@/models/window-configurator.model';
import {
  getIguPanelArea,
  getPanelHeight,
  getPanelWidth,
  getSideCornerPanel,
} from '@/shared/helpers';
import { FillerType } from '@/components/UTC/models';
import UnitHeader from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/frames/Units/UnitHeader';
import { useAppDispatch } from '@/store/hooks';
import { resetSelectedPlacedPanels } from '@/store/slices/windowsReducer/facadeDesignerSlice';

export interface UpdatedUserBuildingPanelData extends UserBuildingPanel {
  guid: string;
}

interface PanelFrameProps {
  selectedPanels: UpdatedUserBuildingPanelData[];
  isImperialUnits: boolean;
  areSettingsDisabled: boolean;
}

const MULTIPLE_COLORS_ID = RALColors.MultipleColors;
const MULTIPLE_MATERIALS_ID = 'multipleMaterials';

const PanelFrame: React.FC<PanelFrameProps> = ({
  selectedPanels,
  isImperialUnits,
  areSettingsDisabled,
}) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const { getFormattedValue, getAreaUnit } = useFrameProperties();
  const [updatePanels] = useUpdatePanelsMutation();
  const { data: panelsData } = useGetAllPanelsQuery(id!);
  const projectData = useFetchProjectQuery(id!).data!;
  const IGUData = useFetchIguQuery().data!;
  const configData = useFetchWindowConfigQuery().data!;

  const [isUnitsLibraryOpened, setIsUnitsLibraryOpened] = useState(false);

  const getSideCornerPart = (panel: UserBuildingPanel) =>
    getSideCornerPanel(panel, panelsData!);

  const getWingsWidth = (
    panel: UpdatedUserBuildingPanelData,
    wing: 'left' | 'right'
  ): number => {
    if (wing === 'left' && panel.cornerSide === CornerGridAlign.Left)
      return getPanelWidth(panel);

    if (wing === 'right' && panel.cornerSide === CornerGridAlign.Right)
      return getPanelWidth(panel);

    if (wing === 'left' && panel.cornerSide !== CornerGridAlign.Left)
      return getPanelWidth(getSideCornerPart(panel));

    if (wing === 'right' && panel.cornerSide !== CornerGridAlign.Right)
      return getPanelWidth(getSideCornerPart(panel));

    return getPanelWidth(panel);
  };

  const getCornerMetrics = () => {
    return [
      {
        name: 'Right wing width',
        value: getFormattedValue(
          selectedPanels.map((panel) =>
            getWingsWidth(panel, 'right').toString()
          )
        ),
        isDisabled: false,
        isEditable: false,
      },
      {
        name: 'Left wing width',
        value: getFormattedValue(
          selectedPanels.map((panel) => getWingsWidth(panel, 'left').toString())
        ),
        isDisabled: false,
        isEditable: false,
      },
    ];
  };

  const getStraightMetrics = () => {
    return [
      {
        name: 'Width',
        value: getFormattedValue(
          selectedPanels.map((panel) => getPanelWidth(panel).toString())
        ),
        isDisabled: false,
        isEditable: false,
      },
    ];
  };

  const getPanelsMetrics = () => {
    if (selectedPanels.length > 1) return [];
    return selectedPanels[0].cornerSide
      ? getCornerMetrics()
      : getStraightMetrics();
  };

  const getAvailableColors = (multipleColors?: boolean) => {
    const colorsData = configData.colors.map((color) => ({
      id: color.name,
      name: color.description,
      preview: (
        <div
          className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
          style={{
            backgroundColor: getHexByName(configData.colors, color.name),
          }}
        />
      ),
    }));

    multipleColors &&
      colorsData.push({
        id: MULTIPLE_COLORS_ID,
        name: 'Multiple colors',
        preview: (
          <div
            className={`w-6 h-6 box-border rounded-full`}
            style={{
              backgroundColor: '#fff',
            }}
          >
            <NoColorIcon />
          </div>
        ),
      });
    return colorsData;
  };

  const getAvailableMaterials = (multipleMaterials?: boolean) => {
    const materials = configData.panelMaterials.map((material) => ({
      name: material.description,
      id: material.name,
      description: material.additionalInfo,
      preview: getMaterialImage(material.name),
    }));

    multipleMaterials &&
      materials.push({
        id: MULTIPLE_MATERIALS_ID,
        name: 'Multiple materials',
        description: '',
        preview: (
          <div
            className={`w-6 h-6 box-border rounded-full`}
            style={{
              backgroundColor: '#fff',
            }}
          >
            <NoColorIcon />
          </div>
        ),
      });

    return materials;
  };

  const isPanelsHasDifferentOutsideColors = () =>
    !selectedPanels.every(
      (panel) => panel.outsideColor === selectedPanels[0].outsideColor
    );

  const isPanelsHasDifferentInsideColors = () =>
    !selectedPanels.every(
      (panel) => panel.insideColor === selectedPanels[0].insideColor
    );

  const isPanelsHasDifferentMaterials = () =>
    !selectedPanels.every((panel) =>
      panel.innerFrames.every(
        (frame) =>
          frame.fillerType === selectedPanels[0].innerFrames[0].fillerType
      )
    );

  const showIGUSelect = () =>
    selectedPanels.some((panel) =>
      panel.innerFrames.some((frame) =>
        frame.fillerType.toLowerCase().includes('glass')
      )
    );

  const hasPanelDifferentGlassMaterial = !selectedPanels.every((panel) =>
    panel.innerFrames.every(
      (frame) =>
        frame.fillerType === selectedPanels[0].innerFrames[0].fillerType
    )
  );
  const hasPanelDifferentIgu = !selectedPanels.every((panel) =>
    panel.innerFrames.every(
      (frame) => frame.iguId === selectedPanels[0].innerFrames[0].iguId
    )
  );

  const handleChangeInsideColor = (color: string) => {
    updatePanels({
      projectId: id!,
      data: {
        wallPanelGuids: selectedPanels.map((panel) => panel.guid),
        panelInnerColor: color,
      },
    });
    dispatch(resetSelectedPlacedPanels());
  };
  const handleChangeOutsideColor = (color: string) => {
    updatePanels({
      projectId: id!,
      data: {
        wallPanelGuids: selectedPanels.map((panel) => panel.guid),
        panelOuterColor: color,
      },
    });
    dispatch(resetSelectedPlacedPanels());
  };

  const handleChangeIgu = (iguId: number) => {
    updatePanels({
      projectId: id!,
      data: {
        wallPanelGuids: selectedPanels.map((panel) => panel.guid),
        panelIguId: iguId,
      },
    });
    dispatch(resetSelectedPlacedPanels());
  };
  const handleChangeMaterial = (material: string) => {
    let newIguId;

    if (material.toLowerCase().includes('glass')) {
      newIguId = IGUData.filter((val) =>
        material === 'Glass'
          ? !val.name.includes('_EN')
          : val.name.includes('_EN')
      )[0]?.id;
    }

    updatePanels({
      projectId: id!,
      data: {
        wallPanelGuids: selectedPanels.map((panel) => panel.guid),
        panelMaterial: material,
        panelIguId: newIguId,
      },
    });
    dispatch(resetSelectedPlacedPanels());
  };

  const getIguMetrics = () => {
    if (
      selectedPanels.every((panel) =>
        panel.innerFrames.every((frame) => frame.iguId === 0)
      )
    )
      return [];

    return [
      {
        name: 'IGU area',
        value: calculateCommonArea({
          nodes: selectedPanels,
          isImperialUnits,
          calculateAreaFunction: (panel) =>
            getIguPanelArea(panel, configData.panel),
        }),
        isDisabled: false,
        isEditable: false,
        units: getAreaUnit(),
      },
    ];
  };

  return (
    <IntusLoader loading={false}>
      <div className="text-xs overflow-hidden h-[inherit]">
        <UnitHeader
          units={panelsData}
          selectedUnits={selectedPanels}
          unitSystem={projectData.unitSystem}
          isUnitsLibraryOpened={isUnitsLibraryOpened}
          setIsUnitsLibraryOpened={setIsUnitsLibraryOpened}
          isProjectLocked={projectData.locked}
        />
        <div
          className={
            'flex flex-col border border-l-0 border-r-0 border-b-0 border-solid border-light-gray-20 text-dark-gray-100 overflow-y-auto'
          }
          style={{
            height: isUnitsLibraryOpened ? 'calc(100% - 420px)' : '100%',
          }}
        >
          <div
            className={
              'flex flex-col text-xs border-solid border-0 border-light-gray-20 border-b bg-white! text-dark-gray-100'
            }
          >
            <MetricsHeader />
            <PropertyList
              properties={[
                {
                  name: 'Height',
                  value: getFormattedValue(
                    selectedPanels.map((panel) =>
                      getPanelHeight(panel).toString()
                    )
                  ),
                  isDisabled: false,
                  isEditable: false,
                },
                ...getPanelsMetrics(),
                {
                  name: 'Area',
                  value: calculateCommonArea({
                    nodes: selectedPanels,
                    isImperialUnits,
                    calculateAreaFunction: (panel) =>
                      convertMillimetersToMeters(getPanelWidth(panel)) *
                      convertMillimetersToMeters(getPanelHeight(panel)),
                  }),

                  isDisabled: false,
                  isEditable: false,
                  units: getAreaUnit(),
                },
                ...getIguMetrics(),
              ]}
            />
          </div>
          <div className="text-dark-gray-100 pb-3 border-0 border-b border-solid border-light-gray-20 border-box">
            <div className="flex gap-1 mb-2 px-3 pt-3">
              <ColorsIcon />
              <div className="font-medium leading-5">COLORS</div>
            </div>
            <div className="font-light leading-5 mb-1 px-3">Outside color</div>
            <PickerPopover
              items={getAvailableColors(isPanelsHasDifferentOutsideColors())}
              onChange={handleChangeOutsideColor}
              initialValue={
                isPanelsHasDifferentOutsideColors()
                  ? MULTIPLE_COLORS_ID
                  : selectedPanels[0].outsideColor
              }
              disabled={areSettingsDisabled}
              contentHeight={184}
              contentWidth={210}
            />
            <div className="font-light leading-5 my-1 px-3">Inside color</div>
            <PickerPopover
              items={getAvailableColors(isPanelsHasDifferentInsideColors())}
              initialValue={
                isPanelsHasDifferentInsideColors()
                  ? MULTIPLE_COLORS_ID
                  : selectedPanels[0].insideColor
              }
              onChange={handleChangeInsideColor}
              disabled={areSettingsDisabled}
              contentHeight={184}
              contentWidth={210}
            />
          </div>
          <div className="flex flex-col py-3 gap-2 border-0 mb-1">
            <div className="flex gap-1 px-3">
              <MaterialIcon />
              <span className="font-medium leading-5">MATERIAL</span>
            </div>
            <PickerPopover
              items={getAvailableMaterials(isPanelsHasDifferentMaterials())}
              initialValue={
                isPanelsHasDifferentMaterials()
                  ? MULTIPLE_MATERIALS_ID
                  : selectedPanels[0].innerFrames[0].fillerType
              }
              onChange={handleChangeMaterial}
              searchable={false}
              disabled={areSettingsDisabled}
              contentWidth={226}
            />
            {showIGUSelect() && (
              <>
                <div className="px-3 font-light leading-5 mt-1">
                  IGU configuration
                </div>
                <IguSelect
                  value={selectedPanels[0].innerFrames[0].iguId}
                  onSelect={handleChangeIgu}
                  disabled={
                    hasPanelDifferentGlassMaterial || hasPanelDifferentIgu
                  }
                  materialIsGlass={
                    selectedPanels[0].innerFrames[0].fillerType ===
                    FillerType.Glass
                  }
                />
              </>
            )}
          </div>
        </div>
      </div>
    </IntusLoader>
  );
};

export default PanelFrame;
