import React from 'react';
import CopyPropertiesTool from './CopyPropertiesTool';

const ModifyTools = () => {
  return (
    <>
      <CopyPropertiesTool />
    </>
  );
};

export default ModifyTools;
