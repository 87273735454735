import React from 'react';

const OuterCornerEditIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clipPath="url(#clip0_20684_342721)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 0.745533C13.5 0.15312 12.8785 -0.233793 12.3469 0.0276573L6.02213 3.13832L3.70784 1.76689C3.17456 1.45087 2.5 1.83524 2.5 2.45513V12.8183C2.5 13.1008 2.64907 13.3624 2.89216 13.5065L5.7451 15.1971C5.89033 15.2832 6.06918 15.2902 6.22067 15.2157L7.50947 14.5818C7.35556 14.2874 7.23327 13.9738 7.14713 13.6456L6.5 13.9639V4.01769L12.5 1.06678V8.11151C12.8506 8.19108 13.1857 8.31159 13.5 8.46776V0.745533ZM3.5 2.80612L5.5 3.99131V13.8895L3.5 12.7043V2.80612Z"
          fill="#414042"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5 0.745533C13.5 0.15312 12.8785 -0.233793 12.3469 0.0276573L6.02213 3.13832L3.70784 1.76689C3.17456 1.45087 2.5 1.83524 2.5 2.45513V12.8183C2.5 13.1008 2.64907 13.3624 2.89216 13.5065L5.7451 15.1971C5.89033 15.2832 6.06918 15.2902 6.22067 15.2157L7.50947 14.5818C7.35556 14.2874 7.23327 13.9738 7.14713 13.6456L6.5 13.9639V4.01769L12.5 1.06678V8.11151C12.8506 8.19108 13.1857 8.31159 13.5 8.46776V0.745533ZM3.5 2.80612L5.5 3.99131V13.8895L3.5 12.7043V2.80612Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          d="M11.3717 9.80468L12.6953 11.1283M13.2259 9.27413C12.8604 8.90862 12.2678 8.90862 11.9023 9.27413L9.09171 12.0847C8.95811 12.2183 8.86284 12.3853 8.81587 12.5683L8.50561 13.7771C8.48992 13.8382 8.50767 13.9031 8.55229 13.9477C8.59692 13.9923 8.66178 14.0101 8.72291 13.9944L9.93168 13.6841C10.1147 13.6372 10.2817 13.5419 10.4153 13.4083L13.2259 10.5977C13.5914 10.2322 13.5914 9.63964 13.2259 9.27413Z"
          stroke="#343335"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_20684_342721">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OuterCornerEditIcon;
