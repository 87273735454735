import React, { useEffect, useState } from 'react';
import { FlatVector3, GridLineData } from '@/models';
import ViewerGridLine from '@/routes/dashboard/projects/project/UserBuilding/components/PanelizationItems/ViewerGridLine';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { createLine2 } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  BUILDING_GRID_LINE_COLOR,
  C_FatLineBorderMaterial,
} from '@/shared/materials';
import { disposeNode } from '@/shared/helpers/canvas';

export interface ViewerGridLinesProps {
  gridLines: GridLineData[];
  wallPoints: FlatVector3[];
  wallGuid: string;
  multiplyRate: number;
}

const ViewerGridLines: React.FC<ViewerGridLinesProps> = ({
  gridLines,
  wallPoints,
  wallGuid,
  multiplyRate,
}) => {
  const [gridLineMesh, setGridLineMesh] = useState<Line2>();

  const generateGridLine = (): Line2 => {
    const material = C_FatLineBorderMaterial.clone();
    material.color = BUILDING_GRID_LINE_COLOR;
    return createLine2([wallPoints[2], wallPoints[1]].flat(), material);
  };

  useEffect(() => {
    if (!gridLineMesh) {
      setGridLineMesh(generateGridLine());
    }
    return () => {
      gridLineMesh && disposeNode(gridLineMesh);
    };
  }, []);

  return (
    <>
      {gridLineMesh &&
        gridLines.map((placement, i) => (
          <ViewerGridLine
            scale={multiplyRate}
            placementData={placement}
            wallData={wallPoints}
            gridLine={gridLineMesh}
            key={`placed_grid-line-${wallGuid}-${i}`}
          />
        ))}
    </>
  );
};

export default ViewerGridLines;
