import { isEqual } from 'lodash';
import moment from 'moment/moment';

export const isEqualForMemo = (
  prevProps: Readonly<{ [key: string]: unknown }>,
  nextProps: Readonly<{ [key: string]: unknown }>
) => {
  return Object.keys(nextProps).every((prop) => {
    if (typeof prevProps[prop] === 'function') return true;

    return isEqual(prevProps[prop], nextProps[prop]);
  });
};

export const sortByDates = (date1: string, date2: string) =>
  moment(date1).isAfter(date2) ? 1 : -1;

export const downloadFile = (blobData: Blob, fileName: string) => {
  const url = URL.createObjectURL(blobData);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;

  a.click();
  URL.revokeObjectURL(url);
};
