import { IntusButton } from '@/shared/elements';
import { CanvasActionsModes, DrawModes, UTCModes } from '@/models';
import {
  CubeIcon,
  CursorIcon,
  FacadeDesignerIcon,
  FreeDrawIcon,
  WindowLibraryIcon,
} from '@/shared/icons';
import { RectangleDrawIcon } from '@/shared/icons/RectangleDrawIcon';
import { CenterLineIcon } from '@/shared/icons/CenterLineIcon';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  setDrawMode,
  setIsInDrawMode,
  setMode,
  setUTCMode,
} from '@/store/slices/canvasModesSlice';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { getIsMapLoaded } from '@/store/slices/canvasMapSlice';
import { useOutsideMouseDown } from '@/shared/hooks';
import WindowModal from '@/components/WindowCreator/WindowModal';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { subscribe, unsubscribe } from '@/core/events';
import { OPEN_WINDOW_CREATOR } from '@/core/event-names';
import { clearSelectedNodes } from '@/store/slices/canvasBuildingSlice';
import { resetExternalElementsState } from '@/store/slices/canvasExternalElementsSlice';
import { UTCStraightIcon } from '@/shared/icons/UTCStraightIcon';
import { UTCInnerCornerIcon } from '@/shared/icons/UTCInnerCornerIcon';
import { UTCOuterCornerIcon } from '@/shared/icons/UTCOuterCornerIcon';
import { CreateUnitTypeIcon } from '@/shared/icons/CreateUnitTypeIcon';
import UTCModal from '@/components/UTC/UTCModal';

interface ProjectHeaderToolbarProps {
  mode: CanvasActionsModes;
  drawMode: DrawModes;
  utcMode: UTCModes;
  projectId: string;
}

const ProjectHeaderToolbar = ({
  mode,
  drawMode,
  utcMode,
  projectId,
}: ProjectHeaderToolbarProps) => {
  const data = useFetchProjectQuery(projectId).data;
  const loadedWindowConfig = !!useFetchWindowConfigQuery().data;
  const isMapLoaded = useAppSelector(getIsMapLoaded);
  const isDesignerMode = mode === CanvasActionsModes.facadeDesigner;
  const buildingModesRef = useRef<HTMLDivElement>(null!);
  const optionsUTCRef = useRef<HTMLDivElement>(null!);
  const dispatch = useAppDispatch();

  const [showBuildingModeOptions, setShowBuildingModeOptions] = useState(false);
  const [showWindowCreator, setShowWindowCreator] = useState(false);
  const [showUTCOptions, setShowUTCOptions] = useState(false);

  const isUserBuildingExist = !!data?.buildings?.length;
  const isLocked = data?.locked;

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [mode]);

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key === 'Escape') {
      if (
        mode === CanvasActionsModes.createWindow ||
        mode === CanvasActionsModes.facadeDesigner
      )
        return;
      setShowBuildingModeOptions(false);
      setShowUTCOptions(false);
      dispatch(setMode(CanvasActionsModes.selection));
    }
  };

  useOutsideMouseDown(buildingModesRef, () =>
    setShowBuildingModeOptions(false)
  );
  useOutsideMouseDown(optionsUTCRef, () => setShowUTCOptions(false));

  const showUTC = useMemo(() => utcMode !== UTCModes.Unset, [utcMode]);

  const noModalsOpened = useMemo(
    () => !showUTC && !showWindowCreator,
    [showUTC, showWindowCreator]
  );

  const getIconFillColor = (buttonMode: CanvasActionsModes) =>
    mode === buttonMode && noModalsOpened ? '#fff' : '#414042';

  const getActiveDrawModeFill = (expectedMode: DrawModes): string =>
    drawMode === expectedMode && noModalsOpened ? '#fff' : '#414042';

  const getActiveUTCModeFill = (expectedMode: UTCModes): string => {
    return utcMode === expectedMode ? '#fff' : '#414042';
  };

  const getDrawModeIcon = () => {
    switch (drawMode) {
      case DrawModes.Unset:
        return <CubeIcon fill="#414042" className="animate-fastFadeInIcon" />;
      case DrawModes.FreeDraw:
        return (
          <FreeDrawIcon fill={getActiveDrawModeFill(DrawModes.FreeDraw)} />
        );
      case DrawModes.Rectangle:
        return (
          <RectangleDrawIcon
            fill={getActiveDrawModeFill(DrawModes.Rectangle)}
          />
        );
      case DrawModes.CenterLine:
        return (
          <CenterLineIcon fill={getActiveDrawModeFill(DrawModes.CenterLine)} />
        );
    }
  };

  const getUTCIcon = () => {
    switch (utcMode) {
      case UTCModes.Unset:
        return (
          <CreateUnitTypeIcon
            fill="#414042"
            className="animate-fastFadeInIcon"
          />
        );
      case UTCModes.Straight:
        return (
          <UTCStraightIcon fill={getActiveUTCModeFill(UTCModes.Straight)} />
        );
      case UTCModes.InnerCorner:
        return (
          <UTCInnerCornerIcon
            fill={getActiveUTCModeFill(UTCModes.InnerCorner)}
          />
        );
      case UTCModes.OuterCorner:
        return (
          <UTCOuterCornerIcon
            fill={getActiveUTCModeFill(UTCModes.OuterCorner)}
          />
        );
    }
  };
  const handleDrawModeActivation = (mode: DrawModes) => {
    dispatch(resetExternalElementsState());
    dispatch(setIsInDrawMode(true));
    dispatch(setDrawMode(mode));
    setShowBuildingModeOptions(false);
  };

  const handleUTCActivation = (mode: UTCModes) => {
    setShowUTCOptions(false);
    dispatch(setUTCMode(mode));
  };

  const handleWindowCreator = (show: boolean) => {
    setShowWindowCreator(show);
  };

  const handleUTCCreator = (show: boolean) => {
    !show && dispatch(setUTCMode(UTCModes.Unset));
  };

  const onModeChange = (mode: CanvasActionsModes) => dispatch(setMode(mode));

  const handleClickBuildingMode = () => {
    setShowBuildingModeOptions(true);
  };

  const handleClickUTC = () => {
    setShowUTCOptions(true);
  };

  const buttonClassNames = (buttonMode?: CanvasActionsModes) => {
    const isWindowCreatorMode = buttonMode === CanvasActionsModes.createWindow;
    const isUTCMode = buttonMode === CanvasActionsModes.UTC;
    return `${
      (mode === buttonMode && !showWindowCreator && !showUTC) ||
      (isWindowCreatorMode && showWindowCreator) ||
      (isUTCMode && showUTC)
        ? 'bg-light-green-100! hover:bg-dark-green-100!'
        : 'hover:bg-light-gray-1!'
    } w-12! h-12! rounded-none! border-none! leading-[0px] disabled:hover:!bg-white`;
  };

  const handleFacadeDesigner = () => {
    if (mode !== CanvasActionsModes.facadeDesigner) {
      dispatch(clearSelectedNodes());
      dispatch(setMode(CanvasActionsModes.facadeDesigner));
    }
  };

  useEffect(() => {
    subscribe(OPEN_WINDOW_CREATOR, () => handleWindowCreator(true));
    return () => {
      unsubscribe(OPEN_WINDOW_CREATOR, () => handleWindowCreator(true));
    };
  }, []);

  return (
    <div className="flex">
      <IntusButton
        extraStyles={buttonClassNames(CanvasActionsModes.selection)}
        onClick={() => onModeChange(CanvasActionsModes.selection)}
        icon={
          <CursorIcon fill={getIconFillColor(CanvasActionsModes.selection)} />
        }
        type="default"
        id="project-header__selection-mode"
      />
      <div className="relative">
        <IntusButton
          extraStyles={buttonClassNames(CanvasActionsModes.building)}
          onClick={handleClickBuildingMode}
          icon={getDrawModeIcon()}
          type="default"
          disabled={isUserBuildingExist || !isMapLoaded || isDesignerMode}
          id="project-header__building-mode"
        />
        {showBuildingModeOptions && (
          <div
            ref={buildingModesRef}
            className="modal-shadow absolute bottom-[-112px] left-[-36px] flex flex-col gap-1 p-0.5 z-50 justify-center box-border bg-white items-center rounded-lg"
          >
            <div className="w-full">
              <IntusButton
                small
                isActive={drawMode === DrawModes.Rectangle}
                onClick={() => handleDrawModeActivation(DrawModes.Rectangle)}
                block
                type="default"
                icon={
                  <RectangleDrawIcon
                    fill={getActiveDrawModeFill(DrawModes.Rectangle)}
                  />
                }
                id="project-header__buildingMode-rectangle"
              >
                Rectangle
              </IntusButton>
            </div>
            <div className="w-full">
              <IntusButton
                small
                isActive={drawMode === DrawModes.CenterLine}
                onClick={() => handleDrawModeActivation(DrawModes.CenterLine)}
                block
                type="default"
                icon={
                  <CenterLineIcon
                    fill={getActiveDrawModeFill(DrawModes.CenterLine)}
                  />
                }
                id="project-header__buildingMode-centerLine"
              >
                Center Line
              </IntusButton>
            </div>
            <div className="w-full">
              <IntusButton
                small
                isActive={drawMode === DrawModes.FreeDraw}
                onClick={() => handleDrawModeActivation(DrawModes.FreeDraw)}
                block
                type="default"
                icon={
                  <FreeDrawIcon
                    fill={getActiveDrawModeFill(DrawModes.FreeDraw)}
                  />
                }
                id="project-header__buildingMode-freeDraw"
              >
                Free Form
              </IntusButton>
            </div>
          </div>
        )}
      </div>

      <IntusButton
        type="default"
        disabled={!loadedWindowConfig || isLocked}
        extraStyles={buttonClassNames(CanvasActionsModes.createWindow)}
        onClick={() => handleWindowCreator(true)}
        icon={
          <WindowLibraryIcon fill={showWindowCreator ? '#fff' : '#414042'} />
        }
        id="project-header__create-window"
      />
      <div className="relative">
        <IntusButton
          extraStyles={buttonClassNames(CanvasActionsModes.UTC)}
          onClick={handleClickUTC}
          icon={getUTCIcon()}
          type="default"
          disabled={isLocked}
          id="project-header__unit-type-creator"
        />
        {showUTCOptions && (
          <div
            ref={optionsUTCRef}
            className="modal-shadow absolute bottom-[-112px] left-[-76px] flex flex-col gap-1 p-0.5 z-50 justify-center box-border bg-white items-center rounded-lg"
          >
            <div className="w-full">
              <IntusButton
                small
                onClick={() => handleUTCActivation(UTCModes.Straight)}
                extraStyles={'justify-start!'}
                block
                type="default"
                icon={
                  <UTCStraightIcon
                    fill={getActiveUTCModeFill(UTCModes.Straight)}
                  />
                }
                id="project-header__utc-mode__straight"
              >
                Create straight unit
              </IntusButton>
            </div>
            <div className="w-full">
              <IntusButton
                small
                onClick={() => handleUTCActivation(UTCModes.InnerCorner)}
                block
                type="default"
                icon={
                  <UTCInnerCornerIcon
                    fill={getActiveUTCModeFill(UTCModes.InnerCorner)}
                  />
                }
                id="project-header__utc-mode__inner-corner"
              >
                Create inner corner unit
              </IntusButton>
            </div>
            <div className="w-full">
              <IntusButton
                small
                onClick={() => handleUTCActivation(UTCModes.OuterCorner)}
                block
                type="default"
                icon={
                  <UTCOuterCornerIcon
                    fill={getActiveUTCModeFill(UTCModes.OuterCorner)}
                  />
                }
                id="project-header__utc-mode__outer-corner"
              >
                Create outer corner unit
              </IntusButton>
            </div>
          </div>
        )}
      </div>

      <IntusButton
        type="default"
        disabled={!isUserBuildingExist}
        extraStyles={buttonClassNames(CanvasActionsModes.facadeDesigner)}
        onClick={handleFacadeDesigner}
        icon={
          <FacadeDesignerIcon
            fill={isDesignerMode && noModalsOpened ? '#fff' : '#414042'}
          />
        }
      />
      {showWindowCreator && (
        <WindowModal
          isOpen={showWindowCreator}
          setIsOpen={handleWindowCreator}
          projectId={projectId}
        />
      )}
      {showUTC && (
        <UTCModal
          isOpen={showUTC}
          projectId={projectId}
          setIsOpen={handleUTCCreator}
        />
      )}
    </div>
  );
};

export default ProjectHeaderToolbar;
