import React from 'react';
import { UTCFormData } from '../models';
import {
  FlatVector2Axis,
  MeasurementElementType,
  MeasurementUpdateData,
} from '@/components/WindowCreator/models';
import { generateUnitMeasurements } from '../helpers/generators';
import { Measurement } from '@/shared/components/MeasurementLine';

const UTCUnitMeasurements = ({
  formData,
  scale,
  disabled,
  hasInnerHorizontalMeasurements,
  hasInnerVerticalMeasurements,
  handleMeasurementSubmit,
}: {
  formData: UTCFormData;
  scale: number;
  disabled: boolean;
  hasInnerHorizontalMeasurements: boolean;
  hasInnerVerticalMeasurements: boolean;
  handleMeasurementSubmit: (data: MeasurementUpdateData) => void;
}) => {
  const unitMeasurementPoints: [FlatVector2Axis, FlatVector2Axis] =
    generateUnitMeasurements(formData.points);

  return (
    <>
      <Measurement
        type={MeasurementElementType.Unit}
        units={formData.units}
        scale={scale}
        disabled={disabled}
        points={unitMeasurementPoints[0]}
        level={hasInnerHorizontalMeasurements ? 2 : 1}
        onMeasurementSubmit={handleMeasurementSubmit}
      ></Measurement>
      <Measurement
        type={MeasurementElementType.Unit}
        units={formData.units}
        scale={scale}
        disabled={disabled}
        points={unitMeasurementPoints[1]}
        level={hasInnerVerticalMeasurements ? 2 : 1}
        onMeasurementSubmit={handleMeasurementSubmit}
      ></Measurement>
    </>
  );
};

export default UTCUnitMeasurements;
