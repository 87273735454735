import {
  BuildingFacadeData,
  NodeType,
  UserBuildingBlock,
  UserBuildingWall,
} from '@/models';
import { isEqual } from 'lodash';
import * as THREE from 'three';

import {
  createLine2,
  getFlatXZ,
  isPointsInOneLine,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { FlatVector2Axis } from '@/components/WindowCreator/models';
import { get2DDistance } from '@/shared/helpers/konva';
import { booleanPointOnLine, lineString, point } from '@turf/turf';
import { compareNumbersWithPrecision } from '@/shared/helpers/format-data';
import { useParams } from 'react-router';
import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useAppSelector } from '@/store/hooks';
import { getBuildingFacadeData } from '@/store/slices/canvasBuildingSlice';
import {
  convertFlatVector3ToVector,
  generateVerticalBlockContour,
  getBlockContour,
  getBlockContourMaterial,
  getEdgePoints,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import {
  BlockSearchResults,
  useFindNodeData,
} from '@/shared/hooks/useFindNodeData';

const PRECISION = 1e-3;
const PRECISION_FOR_WALL_DIRECTION = 1e-1;

export const useFacadeData = () => {
  const facadesData = useAppSelector(getBuildingFacadeData);

  const { id } = useParams();
  const userBuildings = useFetchProjectQuery(id!).data?.buildings;

  const { getNodeData } = useFindNodeData();

  const checkLinesOverlapping = (
    line1: FlatVector2Axis,
    line2: FlatVector2Axis
  ) => {
    const isFirstPointOnLine = booleanPointOnLine(
      point(line1[0]),
      lineString(line2),
      {
        epsilon: PRECISION,
      }
    );
    const isSecondPointOnLine = booleanPointOnLine(
      point(line1[1]),
      lineString(line2),
      {
        epsilon: PRECISION,
      }
    );

    if (isSecondPointOnLine && isFirstPointOnLine) {
      return true;
    } else if (isFirstPointOnLine) {
      return isPointsInOneLine(line2[0], line2[1], line1[1], true);
    } else if (isSecondPointOnLine) {
      return isPointsInOneLine(line2[0], line2[1], line1[0], true);
    } else {
      return false;
    }
  };

  const getIsWallInOneLineAndIntersects = (
    wall1: UserBuildingWall,
    wall2: UserBuildingWall
  ) => {
    const isWallJoined =
      compareNumbersWithPrecision(
        wall1.points[0][1],
        wall2.points[0][1],
        PRECISION
      ) ||
      compareNumbersWithPrecision(
        wall1.points[2][1],
        wall2.points[0][1],
        PRECISION
      ) ||
      compareNumbersWithPrecision(
        wall1.points[2][1],
        wall2.points[2][1],
        PRECISION
      ) ||
      compareNumbersWithPrecision(
        wall1.points[0][1],
        wall2.points[2][1],
        PRECISION
      );

    const wall1Direction = new THREE.Vector3()
      .subVectors(
        convertFlatVector3ToVector(wall1.points[0]),
        convertFlatVector3ToVector(wall1.points[1])
      )
      .normalize();
    const wall2Direction = new THREE.Vector3()
      .subVectors(
        convertFlatVector3ToVector(wall2.points[0]),
        convertFlatVector3ToVector(wall2.points[1])
      )
      .normalize();

    if (
      !compareNumbersWithPrecision(
        wall1Direction.x,
        wall2Direction.x,
        PRECISION_FOR_WALL_DIRECTION
      ) ||
      !compareNumbersWithPrecision(
        wall1Direction.z,
        wall2Direction.z,
        PRECISION_FOR_WALL_DIRECTION
      )
    )
      return false;
    if (!isWallJoined) return false;

    const isWallUpper =
      isEqual(getFlatXZ(wall1.points[0]), getFlatXZ(wall2.points[0])) &&
      isEqual(getFlatXZ(wall1.points[1]), getFlatXZ(wall2.points[1]));

    const line1: FlatVector2Axis = [
      [wall1.points[0][0], wall1.points[0][2]],
      [wall1.points[1][0], wall1.points[1][2]],
    ];
    const line2: FlatVector2Axis = [
      [wall2.points[0][0], wall2.points[0][2]],
      [wall2.points[1][0], wall2.points[1][2]],
    ];
    const line1Distance = get2DDistance(line1[0], line1[1]);
    const line2Distance = get2DDistance(line2[0], line2[1]);

    const isLinesOverlapping =
      line1Distance < line2Distance
        ? checkLinesOverlapping(line1, line2)
        : checkLinesOverlapping(line2, line1);

    return isWallUpper || isLinesOverlapping;
  };

  const getJoinedFacadeWalls = (
    block: UserBuildingBlock
  ): UserBuildingWall[][] => {
    const allWalls = block.storeys.map((storey) => storey.walls).flat();
    const facades: UserBuildingWall[][] = [];
    for (const wall of allWalls) {
      const isAlreadyAdded = facades.find((facade) =>
        facade.find((facadeWall) => facadeWall.guid === wall.guid)
      );
      if (isAlreadyAdded) continue;
      const index = facades.findIndex((facade) =>
        facade.find((facadeWall) =>
          getIsWallInOneLineAndIntersects(facadeWall, wall)
        )
      );

      if (index === -1) {
        facades.push([wall]);
      } else {
        facades[index] = [...facades[index], wall];
      }
    }
    return facades;
  };

  const mergeJoinedFacades = (facades: UserBuildingWall[][]) => {
    const mergedFacades: UserBuildingWall[][] = [facades[0]];
    for (let i = 1; i < facades.length; i++) {
      const facade = facades[i];
      const isAlreadyAdded = mergedFacades.find((mergedFacade) =>
        mergedFacade.find((mergedWall) =>
          facade.some((facadeWall) => facadeWall.guid === mergedWall.guid)
        )
      );
      if (isAlreadyAdded) continue;

      const joinedFacadeIndex = mergedFacades.findIndex((mergedFacade) => {
        return mergedFacade.some((mergedWall) =>
          facade.find((facadeWall) =>
            getIsWallInOneLineAndIntersects(facadeWall, mergedWall)
          )
        );
      });

      if (joinedFacadeIndex === -1) {
        mergedFacades.push(facade);
      } else {
        mergedFacades[joinedFacadeIndex] = [
          ...mergedFacades[joinedFacadeIndex],
          ...facade,
        ];
      }
    }
    return mergedFacades;
  };

  const getBuildingFacadesData = (): BuildingFacadeData | undefined => {
    const merge = (data: UserBuildingWall[][]) => {
      const facade = mergeJoinedFacades(data);
      if (data.length !== facade.length) {
        return mergeJoinedFacades(facade);
      } else {
        return facade;
      }
    };

    return userBuildings
      ?.map((building) =>
        building.blocks.map((block) => merge(getJoinedFacadeWalls(block)))
      )
      .flat(2);
  };

  const getFacadeWallsByWallGuid = (
    wallGuid: string
  ): UserBuildingWall[] | undefined =>
    facadesData.find((facade) => facade.find((wall) => wall.guid === wallGuid));

  const isAllWallsIncludeInOneFacade = (wallGuids: string[]) =>
    facadesData.some((facade) =>
      wallGuids.every((wallGuid) =>
        facade.find((wall) => wallGuid === wall.guid)
      )
    );

  const getFacadeContourLines = (wallGuid: string) => {
    const wallData = getNodeData({ guid: wallGuid, nodeType: NodeType.Wall });

    const block = getNodeData({
      guid: wallData?.getParentNode(NodeType.Block)?.guid || '',
      nodeType: NodeType.Block,
    }) as BlockSearchResults;

    if (!block) return [];

    const facadeWalls = getFacadeWallsByWallGuid(wallGuid)!
      .map((wall) => wall.points)
      .flat();
    const contourLines: Line2[] = [];
    block.storeys.forEach((storey) =>
      getEdgePoints(storey).forEach((point) => {
        if (facadeWalls.some((wall) => isEqual(point, wall))) {
          contourLines.push(
            generateVerticalBlockContour(point, storey.ceiling.points[0])
          );
        }
      })
    );

    getBlockContour(block)
      .filter((arr) => arr.length)
      .flat()
      .map((contour) =>
        contour.filter((contourPoint) =>
          facadeWalls.some((fw) => isEqual(contourPoint, fw))
        )
      )
      .forEach((contour) => {
        if (contour.length) {
          contourLines.push(
            createLine2(contour.flat(), getBlockContourMaterial())
          );
        }
      });

    return contourLines;
  };

  return {
    facadesData,
    getFacadeWallsByWallGuid,
    isAllWallsIncludeInOneFacade,
    getFacadeContourLines,
    getBuildingFacadesData,
  };
};
