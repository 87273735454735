import React, { useMemo } from 'react';
import { UTCData } from '@/components/UTC/models';
import {
  generateUTCBorders,
  generateUTCMullion,
} from '@/components/UTC/helpers/generators';
import { PanelConfig } from '@/models/window-configurator.model';
import { Group, Line } from 'react-konva';
import { generateClosedContourPointsFromPointArray } from '@/components/WindowCreator/elements/creator-windows.helpers';
import Konva from 'konva';
import { useUTCSelect } from '../hooks/useUTCSelect';
import { UTCElementType } from '@/components/WindowCreator/models';
import { KonvaEventObject } from 'konva/lib/Node';
import {
  getUnitTypeCreatorMode,
  resetHoverArea,
  setHoveredAreaForFrame,
} from '@/store/slices/windowsReducer/UTCSlice';
import { UnitTypeCreatorModes } from '@/models/shared.model';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import { COMMON_LINE_STROKE_WIDTH } from '@/shared/constants';

interface UTCBordersProps {
  utcData: UTCData;
  unitConfig: PanelConfig;
  color: string;
}

const UTCBorders = ({ utcData, unitConfig, color }: UTCBordersProps) => {
  const dispatch = useAppDispatch();
  const borders = generateUTCBorders(utcData.points, unitConfig);
  const frameClosedContour = generateClosedContourPointsFromPointArray(
    utcData.points
  );
  const config = useFetchWindowConfigQuery().data!;

  const pointsToDrawOnFrameHover = useMemo(() => {
    const bordersClosedContours = borders.map((border) =>
      generateClosedContourPointsFromPointArray(border)
    );

    const mullionsClosedContours = utcData.mullions.map((mullion) => {
      const mullionPoints = generateUTCMullion(
        mullion.points,
        utcData.points,
        config.panel
      );

      return generateClosedContourPointsFromPointArray(mullionPoints);
    });

    return [...bordersClosedContours, ...mullionsClosedContours];
  }, [utcData]);

  const mode = useAppSelector(getUnitTypeCreatorMode);
  const isSelectionMode = mode === UnitTypeCreatorModes.Selection;
  const groupRef = React.useRef<Konva.Group>(null);

  const { selectElementInUTC } = useUTCSelect(
    groupRef?.current?._id ?? 0,
    utcData.points
  );

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    selectElementInUTC(
      UTCElementType.Frame,
      frameClosedContour,
      event,
      utcData
    );
  };

  const handlePointerEnter = () => {
    if (!isSelectionMode) return;
    dispatch(setHoveredAreaForFrame(pointsToDrawOnFrameHover));
  };

  const handleMouseLeave = () => {
    if (!isSelectionMode) return;
    dispatch(resetHoverArea());
  };

  return (
    <Group
      ref={groupRef}
      onClick={handleClick}
      onMouseOver={handlePointerEnter}
      onMouseLeave={handleMouseLeave}
    >
      {borders.map((border, idx) => (
        <Line
          key={idx}
          points={border.flat()}
          fill={getHexByName(config.colors, color)}
          closed
          stroke="#8D8B8F"
          strokeWidth={COMMON_LINE_STROKE_WIDTH}
          strokeScaleEnabled={false}
        />
      ))}
    </Group>
  );
};

export default UTCBorders;
