import React from 'react';
import { Html } from '@react-three/drei';

import ScalableDotIcon from '@/images/ScalableDot.svg';
import { calculationMatrix2D } from '@/shared/helpers/matrix';

interface ScalableDotProps {
  dotPosition: THREE.Vector3;
}

const ScalableDot: React.FC<ScalableDotProps> = ({ dotPosition }) => {
  return (
    <Html
      position={dotPosition}
      center
      zIndexRange={[0, 0]}
      className="select-none w-4 h-4"
      calculatePosition={calculationMatrix2D(0, 0)}
    >
      <img src={ScalableDotIcon} alt="ScalableDotIcon" width={16} height={16} />
    </Html>
  );
};

export default ScalableDot;
