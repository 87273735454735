import React from 'react';

interface SvgRectProps {
  x: number;
  y: number;
  width: number;
  height: number;
  fill: string;
  stroke?: string;
  strokeWidth?: number;
  opacity?: number;
}

const Rect: React.FC<SvgRectProps> = ({
  x,
  y,
  width,
  height,
  fill,
  stroke,
  strokeWidth,
  opacity = 1,
}) => (
  <>
    <rect
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      opacity={opacity}
      stroke={stroke}
      strokeWidth={strokeWidth}
    />
  </>
);

export default Rect;
