import React, { useState, useEffect } from 'react';
import Form from 'antd/es/form';
import IntusPopover from '@/shared/elements/Popover/Popover';
import ToolbarButton from '@/components/Toolbar/ToolbarButton';
import CameraIcon from '@/components/Toolbar/icons/CameraIcon';
import {
  useDeleteCameraMutation,
  useFetchProjectQuery,
  useUpdateCameraPropertiesMutation,
} from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { SavedCamera } from '@/models';
import NoSavedCamerasIcon from '../icons/NoSavedCamerasIcon';
import IntusMenu from '@/shared/elements/IntusMenu/IntusMenu';
import IntusSpin from '@/shared/elements/Spin/IntusSpin';
import { requiredValidator } from '@/shared/form/validators';
import { publish, subscribe, unsubscribe } from '@/core/events';
import {
  CHANGE_SAVE_CAMERA_STATE,
  OPEN_CAMERAS_POPOVER,
  SAVE_CURRENT_CAMERA_VIEW,
} from '@/core/event-names';
import EditableField from '@/shared/elements/EditableField';
import SavedCameraItem from './SavedCameraItem';
import { useUpdateProjectCameras } from '@/shared/hooks/updateProjectDataHooks/useUpdateProjectCameras';
import { useAppSelector } from '@/store/hooks';
import { getCameraType } from '@/store/slices/canvasCamerasSlice';
import useHandleError from '@/shared/hooks/useHandleError';

const SavedCameras = () => {
  const [tooltipVisible, setTooltipVisible] = useState(true);
  const { id } = useParams();
  const savedCameras = useFetchProjectQuery(id!).data!.cameras;
  const { handleError } = useHandleError();

  const [form] = Form.useForm<{ name: string }>();
  const [deleteCamera, { isLoading: isDeleting }] = useDeleteCameraMutation();
  const [updateCameraProperties, { isLoading: isUpdating }] =
    useUpdateCameraPropertiesMutation();
  const projectCamerasUtils = useUpdateProjectCameras();
  const [isOpened, setIsOpened] = useState(false);
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [showSpin, setShowSpin] = useState(false);
  const cameraType = useAppSelector(getCameraType);

  useEffect(() => {
    const handleOpenPopover = (evt: CustomEvent) => {
      setIsOpened(true);
      setIsEditing(true);
      form.setFieldsValue({ name: evt.detail });
    };

    subscribe(OPEN_CAMERAS_POPOVER, handleOpenPopover);

    return () => {
      unsubscribe(OPEN_CAMERAS_POPOVER, handleOpenPopover);
    };
  }, []);

  useEffect(() => {
    subscribe(CHANGE_SAVE_CAMERA_STATE, (evt) => {
      setShowSpin(evt.detail);
    });

    return () => {
      unsubscribe(CHANGE_SAVE_CAMERA_STATE, (evt) => {
        setShowSpin(evt.detail);
      });
    };
  }, []);

  const createNewCamera = () => {
    if (isOpened) {
      publish(SAVE_CURRENT_CAMERA_VIEW, {
        name: form.getFieldValue('name'),
        type: cameraType,
      });
      form.resetFields();
      setIsEditing(false);
    }
  };

  const deleteCameraItem = async (cameraId: number) => {
    try {
      await deleteCamera({
        cameraId,
      }).unwrap();

      projectCamerasUtils.deleteCamera(cameraId);
    } catch (error) {
      handleError(error);
    }
  };

  const editCameraItem = async (camera: SavedCamera, newCameraName: string) => {
    try {
      await updateCameraProperties({
        projectId: id!,
        data: { ...camera, name: newCameraName },
      }).unwrap();

      projectCamerasUtils.updateCameraName(camera.id, newCameraName);
    } catch (error) {
      handleError(error);
    }
  };

  const handlePopoverOpenChange = (e: boolean) => {
    setIsOpened(e);
  };

  const spinner = (
    <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-80 z-10">
      <IntusSpin />
    </div>
  );

  const menuTitle = (
    <div className="pt-2 pl-3 pb-2">
      <span>Saved camera positions</span>
    </div>
  );

  const savedCamerasMenuContent = () => (
    <div className="max-h-[220px] w-[196px] overflow-y-auto">
      {(showSpin || isDeleting || isUpdating) && spinner}
      <IntusMenu>
        {savedCameras.map((camera) => (
          <SavedCameraItem
            key={camera.id}
            camera={camera}
            deleteCamera={deleteCameraItem}
            editCamera={editCameraItem}
          />
        ))}
        {isEditing && (
          <IntusMenu.Item className="hover:bg-white!" key="new_camera_item">
            <Form
              form={form}
              className="flex-1 my-1 mx-1"
              onFinish={createNewCamera}
            >
              <Form.Item
                name="name"
                noStyle
                rules={[requiredValidator('Name is required')]}
              >
                <EditableField
                  isEditing={isEditing}
                  onClickOutside={createNewCamera}
                  onEscape={createNewCamera}
                  size="small"
                  textSize="xs"
                  className="h-6! leading-5! font-normal"
                />
              </Form.Item>
            </Form>
          </IntusMenu.Item>
        )}
      </IntusMenu>
    </div>
  );

  const noSavedCamerasMenuContent = (
    <div className="flex-column gap-1 pl-2 pr-2 pb-3 text-[11px] text-[#00000099] font-light leading-[16px]">
      {(showSpin || isDeleting || isUpdating) && spinner}
      <div className="flex justify-center my-1">
        <NoSavedCamerasIcon />
      </div>
      <span>
        To save a camera, click the &quot;Save camera position&quot; button
      </span>
    </div>
  );

  return (
    <IntusPopover
      title={menuTitle}
      className={
        'w-[44px]! h-[44px]! border-0! leading-none shadow-none! text-black hover:text-black! p-0'
      }
      content={
        savedCameras?.length || isEditing
          ? savedCamerasMenuContent
          : noSavedCamerasMenuContent
      }
      trigger={['click']}
      open={isOpened}
      onOpenChange={handlePopoverOpenChange}
    >
      <ToolbarButton
        id="toolbar-button-saved-cameras"
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
        icon={<CameraIcon />}
        tooltipText={
          tooltipVisible && !isOpened ? 'Switch between camera positions' : ''
        }
      />
    </IntusPopover>
  );
};

export default SavedCameras;
