import React from 'react';
import { FlatVector3, WindowPlacementData } from '@/models';
import ViewerWindow from '@/routes/dashboard/projects/project/UserBuilding/components/PanelizationItems/ViewerWindow';

export interface ViewerWindowsProps {
  windows: WindowPlacementData[];
  multiplyRate: number;
  wallPoints: FlatVector3[];
  wallGuid: string;
}

const ViewerWindows: React.FC<ViewerWindowsProps> = ({
  windows,
  wallPoints,
  multiplyRate,
  wallGuid,
}) => {
  return (
    <>
      {windows?.map((placement, i) => (
        <ViewerWindow
          wallGuid={wallGuid}
          scale={multiplyRate}
          placementData={placement}
          wallPoints={wallPoints}
          key={`placed_window-${placement.windowId}_${wallPoints[0][0]}-${i}`}
        />
      ))}
    </>
  );
};

export default ViewerWindows;
