import React, { forwardRef, Ref, useMemo } from 'react';
import moment from 'moment';
import ReportCover from './Pages/ReportCover/ReportCover';
import BuildingRenders from './Pages/BuildingRenders/BuildingRenders';
import BuildingProperties from './Pages/BuildingProperties/BuildingProperties';
import ReportWrapper from './Pages/ReportWrapper';
import PanelProperties from './Pages/PanelProperties/PanelProperties';
import useBuildingMetrics from '@/shared/hooks/useBuildingMetrics';
import { CornerGridAlign } from '@/models';
import { chunk } from 'lodash';
import ReportPanelPage, {
  ROWS_AMOUNT_AT_ONE_PAGE,
  ROWS_AMOUNT_WITHOUT_IGU,
} from '@/components/Report/Pages/ReportPanelPages/ReportPanelPage';
import {
  getGroupedInnerFrameData,
  getGroupedInnerFrameIguConfiguration,
} from '@/components/Report/helpers/report-panels-helper';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { PageNames } from './models/report';
import { useSvgData } from '../SvgDesigner/hooks/useSvgData';

export type ReportPageAmountByPanelId = {
  [key: number]: {
    start: number;
    amountOfIGUPages: number;
    amountOfInnerFramePages: number;
  };
};

interface ReportProps {
  generatedImages: string[];
}

const Report = forwardRef<HTMLDivElement, ReportProps>(
  ({ generatedImages }: ReportProps, ref: Ref<HTMLDivElement>) => {
    const creationDate = moment(new Date()).format('MM/DD/YYYY hh:mm A');
    const isReportRoute = location.pathname.endsWith('/report');
    const config = useFetchWindowConfigQuery().data!;
    const { panelCount } = useBuildingMetrics();

    const { uniqBuildingUnits, getFacadesData } = useSvgData();
    const facadesData = getFacadesData({
      isNumbering: false,
      isUnitNumbering: false,
      useAllUnits: true,
    });

    const facadeSnapshotsChunks = chunk(facadesData, 4);

    const startingPage = 2;

    const facadesPageAmount = useMemo(() => {
      return chunk(facadesData, 4).length;
    }, [facadesData]);

    const panelPagesAmount = useMemo(() => {
      const pagesById: ReportPageAmountByPanelId = {};
      const amount = uniqBuildingUnits?.reduce((sum, panel) => {
        if (panel.cornerSide === CornerGridAlign.Right) return sum;
        const groupedData = getGroupedInnerFrameData(
          panel,
          config.panel,
          uniqBuildingUnits
        );
        const amountOfInnerFramePages =
          groupedData.length > ROWS_AMOUNT_WITHOUT_IGU
            ? chunk(
                groupedData.slice(ROWS_AMOUNT_WITHOUT_IGU),
                ROWS_AMOUNT_AT_ONE_PAGE
              ).length + 1
            : 1;
        const amountOfIGUPages = panel.innerFrames.some((frame) => frame.iguId)
          ? chunk(
              getGroupedInnerFrameIguConfiguration(panel),
              ROWS_AMOUNT_AT_ONE_PAGE
            ).length
          : 0;
        pagesById[panel.id] = {
          start: sum + startingPage + facadesPageAmount + 1,
          amountOfInnerFramePages,
          amountOfIGUPages,
        };
        return sum + amountOfIGUPages + amountOfInnerFramePages;
      }, 0);
      return { pagesById, amount };
    }, [uniqBuildingUnits, facadesData]);

    const totalPages = useMemo(() => {
      const basicPagesAmount = startingPage + facadesPageAmount;

      if (!uniqBuildingUnits || !panelPagesAmount.amount)
        return basicPagesAmount;

      return basicPagesAmount + panelPagesAmount.amount;
    }, [uniqBuildingUnits, facadesData, panelPagesAmount]);

    return (
      <div
        className={`left-0 right-0 top-0 mr-auto ml-auto w-fit z-[-9999] ${
          isReportRoute ? 'flex flex-col gap-5' : 'fixed bg-light-gray-20'
        }`}
        ref={ref}
      >
        <ReportCover backgroundImageUrl={generatedImages[0]} />
        <ReportWrapper
          pageIndex={1}
          reportCreationDate={creationDate}
          countOfPages={totalPages}
          pageName={PageNames.RENDERS}
        >
          <BuildingRenders renderLinks={generatedImages} />
        </ReportWrapper>

        <ReportWrapper
          pageIndex={2}
          reportCreationDate={creationDate}
          countOfPages={totalPages}
          pageName={PageNames.BUILDING_PROPERTIES}
        >
          <BuildingProperties />
        </ReportWrapper>
        {facadeSnapshotsChunks.map((_, index) => (
          <ReportWrapper
            id={PageNames.UCW_UNIT_SETTINGS}
            pageIndex={startingPage + index + 1}
            key={index}
            reportCreationDate={creationDate}
            countOfPages={totalPages}
            pageName={PageNames.UCW_UNIT_SETTINGS}
          >
            <PanelProperties />
          </ReportWrapper>
        ))}
        {uniqBuildingUnits && (
          <ReportPanelPage
            fetchedPanels={uniqBuildingUnits}
            panelCount={panelCount}
            panelPagesAmount={panelPagesAmount.pagesById}
            creationDate={creationDate}
            totalPages={totalPages}
            panelConfig={config.panel}
          />
        )}
      </div>
    );
  }
);

Report.displayName = 'Report';

export default Report;
