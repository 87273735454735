import { Html } from 'react-konva-utils';

import React from 'react';
import { Layer } from 'react-konva';

interface MeasurementErrorProps {
  scale: number;
  errors: string[];
}
const KonvaErrorList = ({ scale, errors }: MeasurementErrorProps) => {
  return (
    <Layer listening={false}>
      {errors.length > 0 && (
        <Html
          divProps={{
            style: {
              position: 'absolute',
              top: `${60}px`,
              left: `0`,
              scale: 1 / scale,
              display: 'flex',
              justifyContent: 'center',
              transform: `translate(0px, 0px) rotate(0deg) scaleX(${scale}) scaleY(${scale})`,
              width: `100%`,
            },
          }}
        >
          <div className="leading-5 py-0.5 px-2 text-white bg-red rounded-sm flex flex-col text-center">
            {errors.map((err, idx) => (
              <span key={idx}>{err}</span>
            ))}
          </div>
        </Html>
      )}
    </Layer>
  );
};

export default KonvaErrorList;
