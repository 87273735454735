import { FlatVector2 } from '@/models';
import {
  generateClosedContourPointsFromPointArray,
  POINT_POSITION,
} from '@/components/WindowCreator/elements/creator-windows.helpers';
import { PanelConfig } from '@/models/window-configurator.model';
import { roundKonvaValue } from '@/shared/helpers/konva';

const calculateActualFillerPoint = (
  points: FlatVector2,
  index: number,
  isPointOnMullionX: boolean,
  isPointOnMullionY: boolean,
  config: PanelConfig
): FlatVector2 => {
  let newPoints: FlatVector2 = [0, 0];

  const thicknessX = isPointOnMullionY
    ? roundKonvaValue(config.mullion.width / 2)
    : config.sideWidth;

  const thicknessY = isPointOnMullionX
    ? roundKonvaValue(config.mullion.width / 2)
    : [POINT_POSITION.BottomRight, POINT_POSITION.BottomLeft].includes(index)
      ? config.bottomWidth
      : config.topWidth;

  switch (index) {
    case POINT_POSITION.TopLeft: {
      newPoints = [points[0] + thicknessX, points[1] + thicknessY];
      break;
    }

    case POINT_POSITION.TopRight: {
      newPoints = [points[0] - thicknessX, points[1] + thicknessY];
      break;
    }

    case POINT_POSITION.BottomRight: {
      newPoints = [points[0] - thicknessX, points[1] - thicknessY];
      break;
    }

    case POINT_POSITION.BottomLeft: {
      newPoints = [points[0] + thicknessX, points[1] - thicknessY];
      break;
    }
  }
  return newPoints;
};

export const calculateActualFillerZone = (
  fillerPoints: FlatVector2[],
  framePoints: FlatVector2[],
  config: PanelConfig
) => {
  const maxX = Math.max(...framePoints.map((wp) => wp[0]));
  const minX = Math.min(...framePoints.map((wp) => wp[0]));
  const maxY = Math.max(...framePoints.map((wp) => wp[1]));
  const minY = Math.min(...framePoints.map((wp) => wp[1]));

  const contourPoints = fillerPoints.map((point, idx) => {
    const isPointAtHorizontalEdge = point[1] === minY || point[1] === maxY;

    const isPointAtVerticalEdge = point[0] === minX || point[0] === maxX;

    return calculateActualFillerPoint(
      point,
      idx,
      !isPointAtHorizontalEdge,
      !isPointAtVerticalEdge,
      config
    );
  });
  return generateClosedContourPointsFromPointArray(contourPoints);
};
