import { IGUFilterGroups } from '@/models/webcalc';

export function getIGULabel(filterGroup: IGUFilterGroups, IGUFilter: number) {
  switch (filterGroup) {
    case IGUFilterGroups.Spacers:
      switch (IGUFilter) {
        case 2:
          return 'WARM';
        case 4:
          return 'PHIUS';
        case 8:
          return 'PHI';
        default:
          return 'All';
      }

    case IGUFilterGroups.Coatings:
      switch (IGUFilter) {
        case 1:
          return 'Prem';
        case 2:
          return 'CG';
        case 3:
          return 'SN51';
        case 4:
          return 'Bird 1st UV SNX 51/23';
        case 5:
          return 'No Coating';
        case 6:
          return 'NU';
        case 7:
          return 'Avisafe Suncool 70/40';
        case 8:
          return 'Bird 1st UV SNX 62/72';
        case 30:
          return 'Bird 1st Etch';
        default:
          return 'All';
      }

    case IGUFilterGroups.GlassTypes:
      switch (IGUFilter) {
        case 0:
          return 'HS';
        case 1:
          return 'T';
        case 2:
          return 'AN';
        case 3:
          return 'HST';
        default:
          return 'All';
      }
    case IGUFilterGroups.GlassFilters:
      switch (IGUFilter) {
        case 0:
          return 'Regular';
        case 1:
          return 'Enamel';
        case 2:
          return 'Bird safe';
        default:
          return 'All';
      }
    default:
      return 'All';
  }
}

export const getIGUFilterLabel = (key: IGUFilterGroups) => {
  switch (key) {
    case IGUFilterGroups.Spacers:
      return 'Spacer';
    case IGUFilterGroups.Coatings:
      return 'Coating';
    case IGUFilterGroups.GlassTypes:
      return 'Glass type';
    case IGUFilterGroups.GlassFilters:
      return 'Glass filter';
  }
};

export const calculateGlassRatio = (
  verticalDistance: number,
  horizontalDistance: number
): number =>
  verticalDistance > horizontalDistance
    ? verticalDistance / horizontalDistance
    : horizontalDistance / verticalDistance;

export const validateGlassRatio = (
  verticalDistance: number,
  horizontalDistance: number,
  allowedRatio: number
): boolean => {
  const ratio = calculateGlassRatio(verticalDistance, horizontalDistance);

  return ratio >= allowedRatio;
};
