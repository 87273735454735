import React, { useState } from 'react';
import { IntusButton } from '@/shared/elements';
import { UTCStraightIconSettings } from '@/shared/icons';
import { UTCModes } from '@/models';
import UTCInnerCornerUnitSettings from '@/shared/icons/UTCInnerCornerUnitSettings';
import UTCOuterCornerUnitSettings from '@/shared/icons/UTCOuterCornerUnitSettings';
import IntusPopover from '@/shared/elements/Popover/Popover';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import {
  getHexByName,
  getMaterialImage,
} from '@/components/WindowCreator/helpers/config';
import PickerPopover from '@/shared/elements/PickerPopover/PickerPopover';
import { WindowColor } from '@/models/window-configurator.model';
import IguSelect from '@/shared/elements/IguSelect/IguSelect';
import { FillerType } from '../models';

interface ColorPickerFieldProps {
  label: string;
  value: string;
  onChange: (color: string) => void;
  colors: WindowColor[];
  contentWidth?: number;
  contentHeight?: number;
}

export const ColorPickerField: React.FC<ColorPickerFieldProps> = ({
  label,
  value,
  onChange,
  colors,
  contentWidth = 210,
  contentHeight = 292,
}) => {
  return (
    <div className="flex flex-col gap-1">
      <div className="py-0.5 px-3 text-dark-gray-100 text-xs font-light leading-5">
        {label}
      </div>
      <PickerPopover
        items={colors.map((color) => ({
          id: color.name,
          name: color.description,
          preview: (
            <div
              className="w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full"
              style={{
                backgroundColor: getHexByName(colors, color.name),
              }}
            />
          ),
        }))}
        placement="right"
        onChange={onChange}
        initialValue={value}
        disabled={false}
        contentWidth={contentWidth}
        contentHeight={contentHeight}
        searchable={false}
      />
    </div>
  );
};

const GlobalUnitSettings = ({ mode }: { mode: UTCModes }) => {
  const configData = useFetchWindowConfigQuery().data!;
  const [settings, setSettings] = useState({
    fillerColorOutSide: configData.baseWindow.outerColor,
    fillerColorInside: configData.baseWindow.innerColor,
    leftWingFillerColorOutside: configData.baseWindow.outerColor,
    leftWingFillerColorInside: configData.baseWindow.innerColor,
    rightWingFillerColorOutside: configData.baseWindow.outerColor,
    rightWingFillerColorInside: configData.baseWindow.innerColor,
    frameAndMullionsOutsideColor: configData.baseWindow.outerColor,
    frameAndMullionsInsideColor: configData.baseWindow.innerColor,
    fillerType: configData.fillerTypes[0].name,
    panelIguId: configData.baseUnit.iguId,
  });

  const isCornerUnit =
    mode === UTCModes.InnerCorner || mode === UTCModes.OuterCorner;
  const straightUnit = mode === UTCModes.Straight;

  const showIguSelect = settings.fillerType !== FillerType.Aluminium;

  const getUTCmodeSettingsIcon = () => {
    switch (mode) {
      case UTCModes.Straight:
        return <UTCStraightIconSettings />;
      case UTCModes.InnerCorner:
        return <UTCInnerCornerUnitSettings />;
      case UTCModes.OuterCorner:
        return <UTCOuterCornerUnitSettings />;
      default:
        return <UTCStraightIconSettings />;
    }
  };

  const getUTCmodeText = () => {
    switch (mode) {
      case UTCModes.Straight:
        return 'Straight unit settings';
      case UTCModes.InnerCorner:
        return 'Inner corner unit settings';
      case UTCModes.OuterCorner:
        return 'Outer corner unit settings';
    }
  };

  const popoverContent = (
    <div className="w-[220px] max-h-[408px] py-3 flex flex-col gap-0.5 overflow-auto">
      {straightUnit && (
        <>
          <ColorPickerField
            label="Filler color - Outside"
            value={settings.fillerColorOutSide}
            onChange={(color) =>
              setSettings({ ...settings, fillerColorOutSide: color })
            }
            colors={configData.colors}
          />
          <ColorPickerField
            label="Filler color - Inside"
            value={settings.fillerColorInside}
            onChange={(color) =>
              setSettings({ ...settings, fillerColorInside: color })
            }
            colors={configData.colors}
          />
        </>
      )}
      {isCornerUnit && (
        <>
          <ColorPickerField
            label="Left wing filler color - Outside"
            value={settings.leftWingFillerColorOutside}
            onChange={(color) =>
              setSettings({ ...settings, leftWingFillerColorOutside: color })
            }
            colors={configData.colors}
          />
          <ColorPickerField
            label="Left wing filler color - Inside"
            value={settings.leftWingFillerColorInside}
            onChange={(color) =>
              setSettings({ ...settings, leftWingFillerColorInside: color })
            }
            colors={configData.colors}
          />
          <ColorPickerField
            label="Right wing filler color - Outside"
            value={settings.rightWingFillerColorOutside}
            onChange={(color) =>
              setSettings({ ...settings, rightWingFillerColorOutside: color })
            }
            colors={configData.colors}
          />
          <ColorPickerField
            label="Right wing filler color - Inside"
            value={settings.rightWingFillerColorInside}
            onChange={(color) =>
              setSettings({ ...settings, rightWingFillerColorInside: color })
            }
            colors={configData.colors}
          />
        </>
      )}
      <ColorPickerField
        label="Frame and mullions color - Outside"
        value={settings.frameAndMullionsOutsideColor}
        onChange={(color) =>
          setSettings({ ...settings, frameAndMullionsOutsideColor: color })
        }
        colors={configData.colors}
      />
      <ColorPickerField
        label="Frame and mullions color - Inside"
        value={settings.frameAndMullionsInsideColor}
        onChange={(color) =>
          setSettings({ ...settings, frameAndMullionsInsideColor: color })
        }
        colors={configData.colors}
      />
      <div className="flex gap-1 flex-col">
        <div className="py-0.5 px-3 text-dark-gray-100 text-xs font-light leading-5">
          Filler type
        </div>
        <PickerPopover
          initialValue={settings.fillerType}
          items={configData.fillerTypes.map((fillerType) => ({
            name: fillerType.description,
            id: fillerType.name,
            description: fillerType.additionalInfo,
            preview: getMaterialImage(fillerType.name),
          }))}
          onChange={(fillerType) =>
            // Will be changed after form logic is implemented, added fot testing purposes
            setSettings({ ...settings, fillerType: fillerType as FillerType })
          }
          searchable={false}
          disabled={false}
          contentWidth={226}
          placement="right"
        />
      </div>
      {showIguSelect && (
        <div className="flex gap-1 flex-col">
          <div className="py-0.5 px-3 text-dark-gray-100 text-xs font-light leading-5">
            IGU configuration
          </div>
          <IguSelect
            value={settings.panelIguId}
            onSelect={(value) =>
              setSettings({ ...settings, panelIguId: value })
            }
            materialIsGlass={settings.fillerType === FillerType.Glass}
            isIguForPanel
          />
        </div>
      )}
    </div>
  );

  return (
    <IntusPopover
      trigger={['click']}
      placement="topLeft"
      content={popoverContent}
      title={
        <div className="py-2 px-3 text-black text-[11px] leading-4 font-medium">
          Global unit settings
        </div>
      }
    >
      <IntusButton
        id="unit-settings"
        icon={getUTCmodeSettingsIcon()}
        className="!bg-[#FFFFFF] text-dark-gray-100 border-light-gray-40"
      >
        {getUTCmodeText()}
      </IntusButton>
    </IntusPopover>
  );
};

export default GlobalUnitSettings;
