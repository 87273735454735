import React, { ReactNode } from 'react';

import { PDF_HEIGHT, PDF_WIDTH } from '@/shared/constants';
import ReportSidebar from './ReportSidebar';

const ReportWrapper = ({
  id,
  children,
  pageIndex,
  reportCreationDate,
  countOfPages,
  pageName,
}: {
  children: ReactNode;
  pageIndex?: number;
  reportCreationDate?: string;
  countOfPages?: number;
  pageName?: string;
  id?: string;
}) => {
  const isReportRoute = location.pathname.endsWith('/report');
  const hasSidebar =
    !!pageIndex && !!reportCreationDate && !!countOfPages && !!pageName;
  return (
    <div
      id={id}
      className={`${isReportRoute ? 'box-content border border-solid border-black' : ''}  flex justify-between bg-white`}
      style={{
        width: `${PDF_WIDTH}px`,
        height: `${PDF_HEIGHT}px`,
      }}
    >
      {children}
      {hasSidebar && (
        <ReportSidebar
          pageIndex={pageIndex}
          reportCreationDate={reportCreationDate}
          countOfPages={countOfPages}
          pageName={pageName}
        />
      )}
    </div>
  );
};

export default ReportWrapper;
