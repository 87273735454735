import { booleanPointOnLine, lineString, point } from '@turf/turf';
import { compact, difference, isEqual } from 'lodash';
import * as polyclip from 'polyclip-ts';
import { useMemo } from 'react';

import {
  BlockSearchResults,
  SearchResults,
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import {
  FlatVector2,
  FlatVector3,
  NodeType,
  UserBuildingBlock,
  UserBuildingStorey,
  UserBuildingWall,
} from '@/models';
import {
  is2dPointInsideArea,
  isPointsInOneLine,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  convertFlatVector3ToVectors,
  generateBlock,
  generateStorey,
  generateWalls,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useSelectedNodes } from '@/shared/hooks/useSelectedNodes';
import { useAppDispatch } from '@/store/hooks';
import { removeFromSelectedNodeArray } from '@/store/slices/canvasBuildingSlice';
import {
  getAlphabetIndex,
  isMultipleValue,
} from '@/shared/helpers/format-data';
import useFrameProperties from '@/shared/hooks/useFrameProperties';

export const useMerge = (buildingGUID: string) => {
  const { updateBuildingBlocks } = useUpdateUserBuildingData();
  const { addNodesToSelectedNodes } = useSelectedNodes();
  const { getFloorHeightInBlock } = useFrameProperties();
  const {
    getNodeData,
    findDataForWall,
    findDataForStorey,
    findDataForBlock,
    findDataForBuilding,
  } = useFindNodeData();
  const dispatch = useAppDispatch();

  const newGUIDs = useMemo((): string[] => {
    return [];
  }, []);

  //method for checking the possibility of joining two walls
  const isWallsInOneLine = (
    wall: WallSearchResults,
    otherWall: WallSearchResults
  ) => {
    if (
      otherWall?.guid === wall?.guid ||
      otherWall?.points[0][1] !== wall?.points[0][1]
    )
      return false;

    const wallLine = lineString([
      [wall!.points[0][0], wall!.points[0][2]],
      [wall!.points[1][0], wall!.points[1][2]],
    ]);
    const otherWallPoint1 = point([
      otherWall!.points[0][0],
      otherWall!.points[0][2],
    ]);
    const otherWallPoint2 = point([
      otherWall!.points[1][0],
      otherWall!.points[1][2],
    ]);
    return (
      (booleanPointOnLine(otherWallPoint1, wallLine) &&
        isPointsInOneLine(
          [wall!.points[0][0], wall!.points[0][2]],
          [wall!.points[1][0], wall!.points[1][2]],
          [otherWall!.points[1][0], otherWall!.points[1][2]],
          true
        )) ||
      (booleanPointOnLine(otherWallPoint2, wallLine) &&
        isPointsInOneLine(
          [wall!.points[0][0], wall!.points[0][2]],
          [wall!.points[1][0], wall!.points[1][2]],
          [otherWall!.points[0][0], otherWall!.points[0][2]],
          true
        ))
    );
  };

  //method for checking the possibility of joining two blocks
  const isBlocksCanBeMerged = (
    block: UserBuildingBlock,
    otherBlock: UserBuildingBlock
  ) => {
    const biggestBlock =
      block.storeys.length >= otherBlock.storeys.length ? block : otherBlock;

    const smallestBlock =
      block.storeys.length < otherBlock.storeys.length ? block : otherBlock;

    return (
      biggestBlock.storeys.reduce((acc, curr, currentIndex) => {
        const smallestBlockStorey = smallestBlock.storeys[currentIndex];
        if (!smallestBlockStorey) return acc;

        return Math.max(
          polyclip.union(
            [curr.floor.points.map((p): FlatVector2 => [p[0], p[2]])],
            [
              smallestBlockStorey.floor.points.map(
                (p): FlatVector2 => [p[0], p[2]]
              ),
            ]
          ).length,
          acc
        );
      }, 0) === 1
    );
  };

  //method for validating the possibility of joining array of walls
  const validateMergeAvailabilityForWalls = (wallsGuids: string[]) => {
    const wallData = wallsGuids
      .map((guid) => findDataForWall(guid))
      .filter((wallData) => !getIsElementLockedForMerge(wallData!));

    return wallData.some((wall) => {
      return wallData.find(
        (otherWall) =>
          isWallsInOneLine(wall!, otherWall!) &&
          wall?.getParentNode(NodeType.Block)?.guid ===
            otherWall?.getParentNode(NodeType.Block)?.guid
      );
    });
  };

  //method for validating the possibility of joining array for storeys and walls
  const validateMergeAvailabilityForWallsAndStoreys = (
    storeysGuid: string[],
    nodeType: NodeType
  ) => {
    const elementData = storeysGuid.map((guid) =>
      getNodeData({ guid, nodeType })
    );
    return elementData.some(
      (storey) =>
        storey &&
        validateMergeAvailabilityForWalls(
          storey.childNodes
            .filter((node) => node.type === NodeType.Wall)
            .map((node) => node?.guid || '')
        )
    );
  };

  //pre-check merge availability to show merge button
  const validateMergeAvailability = (guids: string[], type: NodeType) => {
    switch (type) {
      case NodeType.Wall: {
        return validateMergeAvailabilityForWalls(guids);
      }
      case NodeType.Storey:
        return validateMergeAvailabilityForWallsAndStoreys(guids, type);
      case NodeType.Block: {
        if (guids.length === 1)
          return validateMergeAvailabilityForWallsAndStoreys(guids, type);
        if (guids.length > 1) {
          const blocksData = compact(
            guids
              .map(
                (guid) =>
                  getNodeData({
                    guid,
                    nodeType: NodeType.Block,
                  }) as BlockSearchResults
              )
              .filter((block) => !getIsElementLockedForMerge(block))
          );

          //is all blocks have same height
          const storeysHeight = getFloorHeightInBlock(blocksData);
          if (isMultipleValue(storeysHeight)) return false;

          const isSomeBlocksCanBeMerged = blocksData.some((block) =>
            blocksData.some(
              (otherBlock) =>
                block?.guid !== otherBlock?.guid &&
                isBlocksCanBeMerged(block, otherBlock)
            )
          );
          const isBlockHaveWallsForMerge =
            validateMergeAvailabilityForWallsAndStoreys(guids, type);

          return isSomeBlocksCanBeMerged || isBlockHaveWallsForMerge;
        }
        break;
      }
      case NodeType.Building: {
        if (guids.length !== 1) return false;

        const buildingBlocksNodes = guids
          .map((guid) =>
            getNodeData({
              guid,
              nodeType: NodeType.Building,
            })?.childNodes.filter((node) => node.type === NodeType.Block)
          )
          .flat();

        const blocksData = compact(
          buildingBlocksNodes.map((node) => findDataForBlock(node?.guid || ''))
        );

        if (
          blocksData.some((block) => block && getIsElementLockedForMerge(block))
        )
          return false;

        //is all blocks have same height
        const storeysHeight = getFloorHeightInBlock(blocksData);
        if (isMultipleValue(storeysHeight)) return false;

        return blocksData.some((block) =>
          blocksData.some(
            (otherBlock) =>
              block?.guid !== otherBlock?.guid &&
              isBlocksCanBeMerged(block, otherBlock)
          )
        );
      }
      default:
        return false;
    }
  };

  const mergeStorey = (
    storeyData: UserBuildingStorey,
    wallData: UserBuildingWall[]
  ): UserBuildingStorey => {
    const floorPoints = storeyData.floor.points;
    const newFloorPoints = [floorPoints[0]];
    let prevPoint = floorPoints[0];
    for (let i = 1; i < floorPoints.length; i++) {
      const currPoint = floorPoints[i];
      const nextPoint =
        floorPoints.length - 1 === i ? floorPoints[0] : floorPoints[i + 1];
      const isInLine = isPointsInOneLine(
        [prevPoint[0], prevPoint[2]],
        [currPoint[0], currPoint[2]],
        [nextPoint[0], nextPoint[2]]
      );
      const wallsFloorPoints = wallData
        .map((p) => [p?.points[0], p?.points[1]])
        .flat();
      const mergedPoints = wallsFloorPoints.filter((item, index) =>
        wallsFloorPoints.some(
          (elem, idx) => isEqual(elem, item) && idx !== index
        )
      );
      const isJoinedPoint = mergedPoints.some((point) =>
        isEqual(point, currPoint)
      );
      if (!isInLine || !isJoinedPoint) {
        newFloorPoints.push(currPoint);
      }
      prevPoint = currPoint;
    }
    const newCeilingPoints: FlatVector3[] = newFloorPoints.map((p) => [
      p[0],
      storeyData.ceiling.points[0][1],
      p[2],
    ]);
    const newWalls = generateWalls(
      convertFlatVector3ToVectors(newFloorPoints),
      convertFlatVector3ToVectors(newCeilingPoints),
      storeyData.walls,
      'Merged Wall'
    );

    newWalls.forEach((wall) => {
      const isExistBefore = storeyData.walls.find(
        (sWall) => sWall.guid === wall.guid
      );
      !isExistBefore && newGUIDs.push(wall.guid);
    });

    return {
      guid: storeyData.guid,
      userData: storeyData.userData,
      storeyNumber: storeyData.storeyNumber,
      name: storeyData.name,
      ceiling: {
        ...storeyData.ceiling,
        points: newCeilingPoints,
      },
      floor: {
        ...storeyData.floor,
        points: newFloorPoints,
      },
      walls: newWalls,
    };
  };

  //method for merging all walls for storey and return new solid storey
  const mergeWallsForStorey = (
    wallData: WallSearchResults[]
  ): UserBuildingStorey | null => {
    const storeyGuid = wallData[0]?.getParentNode(NodeType.Storey)?.guid;
    if (!storeyGuid || !wallData) return null;

    const storeyData = findDataForStorey(storeyGuid);
    if (!storeyData) return null;

    return mergeStorey(storeyData, wallData);
  };

  //method for merging all walls for all walls in block and return new solid block
  const mergeWallsForBlock = (
    wallsData: WallSearchResults[]
  ): UserBuildingBlock | null => {
    const sortedByStorey = wallsData.reduce(
      (acc: { [point: number]: WallSearchResults[] }, curr) => {
        const groundPoint = curr?.points[0][1];

        if (!groundPoint) return acc;

        return {
          ...acc,
          [groundPoint]: acc[groundPoint]
            ? [...acc[groundPoint], curr]
            : [curr],
        };
      },
      {}
    );
    const blockGuid = wallsData[0].getParentNode(NodeType.Block)?.guid;
    const blockData = findDataForBlock(blockGuid || '');
    if (!blockData) return null;

    const newStoreys = Object.values(sortedByStorey).map((wallData) => {
      return mergeWallsForStorey(wallData);
    });
    return {
      guid: blockData.guid,
      name: blockData.name,
      userData: blockData.userData,
      storeys: blockData.storeys.map((storey) => {
        const updatedStorey = newStoreys.find(
          (newStorey) => newStorey?.guid === storey.guid
        );
        return updatedStorey || storey;
      }),
    };
  };
  //method for merging all walls that was selected
  const mergeWalls = (wallsGUIDsForMerge: string[], nodeType: NodeType) => {
    const wallData = wallsGUIDsForMerge
      .map((guid) => findDataForWall(guid))
      .filter((wallData) => !getIsElementLockedForMerge(wallData!))
      .reduce((acc: { [point: string]: WallSearchResults[] }, curr) => {
        const blockGuid = curr?.getParentNode(NodeType.Block)?.guid;

        if (!blockGuid) return acc;

        return {
          ...acc,
          [blockGuid]: acc[blockGuid] ? [...acc[blockGuid], curr] : [curr],
        };
      }, {});
    const newBlocks = Object.values(wallData).map((wallData) => {
      return mergeWallsForBlock(wallData);
    });

    const updatedBlocks = findDataForBuilding(buildingGUID)?.blocks.map(
      (block) => {
        const newBlock = newBlocks.find(
          (newBlock) => newBlock?.guid === block.guid
        );
        return newBlock ?? block;
      }
    );

    if (nodeType === NodeType.Wall) {
      // check if the previously selected guid has been merged, so that it can be removed after
      const isMergedGuids = wallsGUIDsForMerge.map((guid) =>
        wallsGUIDsForMerge.some((wallGUID) =>
          isWallsInOneLine(findDataForWall(wallGUID)!, findDataForWall(guid)!)
        )
      );

      dispatch(
        removeFromSelectedNodeArray(
          wallsGUIDsForMerge.filter((_, i) => isMergedGuids[i])
        )
      );
      addNodesToSelectedNodes(
        newGUIDs.map((guid) => ({ guid, type: NodeType.Wall }))
      );
    }
    newGUIDs.length = 0;
    updateBuildingBlocks({
      buildingGUID,
      newBlocks: compact(updatedBlocks),
    });
  };

  //method for merging blocks between each other
  const mergeBlocks = (blocksGUID: string[]) => {
    const blocksData = blocksGUID.map((guid) => findDataForBlock(guid));

    const solidBlocksForMerge = compact(
      blocksData
        //remove blocks, that can't be merged (not adjoined or locked)
        .filter(
          (block) =>
            blocksData.some(
              (otherBlock) =>
                block?.guid !== otherBlock?.guid &&
                isBlocksCanBeMerged(block!, otherBlock!)
            ) ||
            validateMergeAvailabilityForWallsAndStoreys(
              [block?.guid || ''],
              NodeType.Block
            )
        )
        // merge all split walls into one block to remove unnecessary split walls
        .map((block) => {
          const blockWalls = block?.childNodes
            .filter((node) => node.type === NodeType.Wall)
            .map((node) => findDataForWall(node.guid));
          return mergeWallsForBlock(compact(blockWalls));
        })
    );

    const newBlocksShapes: FlatVector2[][][] = [];
    const maxAmountOfFloors = solidBlocksForMerge.reduce(
      (acc, curr) => (curr.storeys.length > acc ? curr.storeys.length : acc),
      0
    );

    for (let i = 0; i < maxAmountOfFloors; i++) {
      //collect all shapes at the same storey level
      const blocksToMerge: polyclip.Geom[] = compact(
        solidBlocksForMerge.map((block) =>
          block.storeys[i]
            ? [
                [
                  block.storeys[i]?.floor?.points?.map(
                    (p): [number, number] => [p[0], p[2]]
                  ),
                ],
              ]
            : undefined
        )
      );

      const union = polyclip.union(
        blocksToMerge[0],
        ...blocksToMerge.slice(1, blocksToMerge.length)
      );
      //sorting shapes after merging to place them in correct arrays one under other
      union
        .map((poly) => poly[0])
        .forEach((poly) => {
          const indexOfExisting = newBlocksShapes.findIndex((blockShapes) =>
            blockShapes.some((shape) =>
              poly.some((point) => is2dPointInsideArea(point, shape))
            )
          );
          if (indexOfExisting === -1) {
            newBlocksShapes.push([poly]);
          } else {
            newBlocksShapes[indexOfExisting] = [
              ...newBlocksShapes[indexOfExisting],
              poly,
            ];
          }
        });
    }
    const allPreviousWalls = solidBlocksForMerge
      .map((block) => block.storeys.map((storey) => storey.walls))
      .flat(2);

    //collect all storey Y values to add it to union shapes
    const floorHeights = solidBlocksForMerge.reduce(
      (acc: { [key: number]: { floorY: number; ceilingY: number } }, curr) => {
        curr.storeys.forEach((storey, i) => {
          if (!acc[i]) {
            acc[i] = {
              floorY: storey.floor.points[0][1],
              ceilingY: storey.ceiling.points[0][1],
            };
          }
        });
        return acc;
      },
      {}
    );

    const newBlocks: UserBuildingBlock[] = newBlocksShapes
      .map((block) =>
        block.map((storeyShape, index) => {
          const floorPoints: FlatVector3[] = storeyShape.map((point) => [
            point[0],
            floorHeights[index].floorY,
            point[1],
          ]);
          const ceilingPoints: FlatVector3[] = storeyShape.map((point) => [
            point[0],
            floorHeights[index].ceilingY,
            point[1],
          ]);
          const newWalls = generateWalls(
            convertFlatVector3ToVectors(floorPoints),
            convertFlatVector3ToVectors(ceilingPoints),
            allPreviousWalls,
            'Merged Wall'
          );
          return generateStorey(
            convertFlatVector3ToVectors(floorPoints),
            index + 1,
            convertFlatVector3ToVectors(ceilingPoints),
            { walls: newWalls }
          );
        })
      )
      .map((blockStoreys) =>
        blockStoreys.map((storey) => mergeStorey(storey, storey.walls))
      )
      .map((blockStoreys, index) =>
        generateBlock(blockStoreys, `Merged Block ${getAlphabetIndex(index)}`)
      );

    const allBuildingBlocks = getNodeData({
      guid: buildingGUID,
      nodeType: NodeType.Building,
    })
      ?.childNodes.filter((node) => node.type === NodeType.Block)
      .map((node) => findDataForBlock(node.guid) as UserBuildingBlock);

    //block guids, that was not merged
    const restBlocksGuids = difference(
      allBuildingBlocks?.map((b) => b?.guid),
      solidBlocksForMerge.map((b) => b.guid)
    );

    updateBuildingBlocks({
      buildingGUID,
      newBlocks: compact([
        allBuildingBlocks?.filter((block) =>
          restBlocksGuids.some((guid) => guid === block?.guid)
        ),
        newBlocks,
      ]).flat(),
    });

    // check if the previously selected guid has been merged, so that it can be removed after
    const isMergedGuids = blocksGUID.map((guid) =>
      solidBlocksForMerge.some((block) => block.guid === guid)
    );

    dispatch(
      removeFromSelectedNodeArray(blocksGUID.filter((_, i) => isMergedGuids[i]))
    );
    addNodesToSelectedNodes(
      newBlocks.map((block) => ({ guid: block.guid, type: NodeType.Block }))
    );
  };

  //checking for locked element in child nodes or parent nodes
  const getIsElementLockedForMerge = (nodeData: SearchResults): boolean => {
    return (
      nodeData.userData?.isLocked ||
      !!nodeData.parentNodes.find((node) => node.userData?.isLocked) ||
      !!nodeData.childNodes.find((node) => node.userData?.isLocked)!
    );
  };

  return { mergeWalls, mergeBlocks, validateMergeAvailability };
};
