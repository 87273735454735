import React, { useMemo } from 'react';

import { NodeType } from '@/models';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import TotalSurface from '@/shared/elements/TotalSurface/TotalSurface';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';

import useFrameProperties from '@/shared/hooks/useFrameProperties';
import PanelMetrics from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/frames/PanelMetrics';
import WindowsMetrics from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/frames/WindowsMetrics';

const WallFrame = ({ wallsGUID }: { wallsGUID: string[] }) => {
  const { findDataForWall, findDataForStorey } = useFindNodeData();

  const selectedWallsData = wallsGUID.map(
    (wallGUID) => findDataForWall(wallGUID)!
  );
  const storeysOfSelectedWalls = selectedWallsData.map(
    (wall) => findDataForStorey(wall.getParentNode(NodeType.Storey)!.guid)!
  );

  const getWallName = (wall: WallSearchResults) => {
    return wall.name;
  };

  const {
    getFloorHeightMetric,
    getWallWidthMetric,
    getFacadesAreaMetricForWalls,
  } = useFrameProperties();

  const panelPlacementData = useMemo(
    () => selectedWallsData.flatMap((wallData) => wallData.wallPanels),
    [selectedWallsData]
  );

  const windowIds = useMemo(() => {
    return selectedWallsData.flatMap((wall) =>
      wall.windowPlacements.flatMap((window) => window.windowId)
    );
  }, [selectedWallsData]);

  return (
    <>
      <div className="flex justify-between  px-3 bg-white font-medium text-xs min-h-8 items-center width-[210px] border-box border-solid border border-t-0 border-x-0 border-light-gray-20">
        <span className="whitespace-nowrap text-ellipsis w-full">
          {selectedWallsData.length > 1
            ? `Wall (${selectedWallsData.length})`
            : getWallName(selectedWallsData[0])}
        </span>
      </div>
      <div className={'overflow-y-auto'}>
        <div
          className={
            'flex flex-col text-xs border border-x-0 border-t-0 border-solid border-light-gray-20 bg-white! overflow-y-auto text-dark-gray-100'
          }
        >
          <MetricsHeader />
          <PropertyList
            alignValueLeft
            properties={[
              {
                name: 'Height',
                value: getFloorHeightMetric(storeysOfSelectedWalls),
              },
              { name: 'Width', value: getWallWidthMetric(selectedWallsData) },
            ]}
          />
        </div>

        <TotalSurface
          facadesArea={getFacadesAreaMetricForWalls(selectedWallsData)}
        />
        {panelPlacementData.length > 0 && (
          <PanelMetrics panelsPlacementData={panelPlacementData} />
        )}
        {windowIds.length > 0 && <WindowsMetrics windowIds={windowIds} />}
      </div>
    </>
  );
};

export default WallFrame;
