import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  addToSelectedPlacedPanel,
  getHoveredPanels,
  setDragCopyOffset,
  setHoveredPlacedPanels,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { subscribe, unsubscribe } from '@/core/events';
import { PanelPlacementDataForFD } from '@/components/FacadeDesigner/models';
import { NodeType, PanelPlacementData, UserBuildingPanel } from '@/models';
import { Stage } from 'konva/lib/Stage';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { isUnitsAreSimilar } from '@/shared/helpers';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { useMount, useUnmount } from 'react-use';

export const DRAG_N_DROP_START_COPYING_EVENT = 'drag-n-drop__start_copy_event';

interface UseDragNDropCopyHandlersProps {
  selectedUnit: PanelPlacementDataForFD;
  isOnlyOnePanelSelected: boolean;
  unitsData: UserBuildingPanel[] | undefined;
  stageRef: MutableRefObject<Stage>;
}

export const useDragNDropCopyHandlers = ({
  unitsData,
  isOnlyOnePanelSelected,
  selectedUnit,
  stageRef,
}: UseDragNDropCopyHandlersProps) => {
  const dispatch = useAppDispatch();

  const hoveredPanels = Object.values(useAppSelector(getHoveredPanels));
  const lastHoveredUnit = hoveredPanels[hoveredPanels.length - 1];

  const [startCollectingUnits, setStartCollectingUnits] = useState(false);
  const collectedUnits = useRef<Set<string>>(new Set());

  const { updateBuildingPlacements } = useUpdateUserBuildingData();
  const { findDataForWallInfoViaPanel } = useFindNodeData();

  const applyUnits = useCallback(() => {
    if (collectedUnits.current.size === 0) return;
    const wall = findDataForWallInfoViaPanel({
      panelGuid: selectedUnit.guid,
    });

    const placementData: {
      wallGUID: string;
      wallPanels?: PanelPlacementData[];
    }[] = Array.from(collectedUnits.current).map((unitGuid) => {
      const replacedWall = findDataForWallInfoViaPanel({
        panelGuid: unitGuid,
      })!;

      return {
        wallGUID: replacedWall.guid,
        wallPanels: replacedWall.wallPanels.map((panel) =>
          collectedUnits.current.has(panel.guid)
            ? { ...panel, panelId: selectedUnit.panelId }
            : panel
        ),
      };
    });

    if (!wall) return;

    updateBuildingPlacements({
      buildingGUID: wall.getParentNode(NodeType.Building)!.guid,
      triggerBEUpdate: true,
      placementData,
    });

    Array.from(collectedUnits.current).forEach((unitGuid) => {
      const wall = findDataForWallInfoViaPanel({
        panelGuid: unitGuid,
      })!;
      dispatch(
        addToSelectedPlacedPanel({
          guid: unitGuid,
          wallGUID: wall.guid,
          panelId: selectedUnit.panelId,
        })
      );
    });
  }, [collectedUnits.current, selectedUnit, findDataForWallInfoViaPanel]);

  const resetDrag = () => {
    setStartCollectingUnits(false);
    dispatch(setDragCopyOffset(undefined));
    dispatch(setHoveredPlacedPanels(null));
    stageRef.current.container().style.cursor = 'default';
  };

  const handlePointerUp = useCallback(() => {
    applyUnits();
    resetDrag();
  }, [applyUnits, resetDrag]);

  const startDrag = () => {
    collectedUnits.current.clear();
    setStartCollectingUnits(true);
    stageRef.current.container().style.cursor = 'crosshair';
  };

  const getIsAbleToReplaceUnit = (panelId: number): boolean => {
    const selectedUnitData = unitsData?.find(
      (elem) => elem.id === selectedUnit.panelId
    );
    const hoveredUnitData = unitsData?.find((elem) => elem.id === panelId);

    if (!hoveredUnitData || !selectedUnitData || !unitsData) return false;

    return isUnitsAreSimilar(selectedUnitData, hoveredUnitData, unitsData);
  };

  useMount(() => {
    subscribe(DRAG_N_DROP_START_COPYING_EVENT, startDrag);
  });

  useUnmount(() => {
    unsubscribe(DRAG_N_DROP_START_COPYING_EVENT, startDrag);
  });

  useEffect(() => {
    selectedUnit &&
      isOnlyOnePanelSelected &&
      startCollectingUnits &&
      document.addEventListener('pointerup', handlePointerUp);

    return () => {
      document.removeEventListener('pointerup', handlePointerUp);
    };
  }, [
    selectedUnit,
    isOnlyOnePanelSelected,
    startCollectingUnits,
    handlePointerUp,
  ]);

  useEffect(() => {
    const isAbleToReplaceUnit =
      lastHoveredUnit &&
      startCollectingUnits &&
      getIsAbleToReplaceUnit(lastHoveredUnit.panelId);

    isAbleToReplaceUnit && collectedUnits.current.add(lastHoveredUnit.guid);
  }, [lastHoveredUnit, startCollectingUnits]);
};
