import { BaseIconProps } from '@/models';
import React from 'react';

export const PlusIcon: React.FC<BaseIconProps> = ({
  fill,
  stroke,
  className,
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      className={className}
    >
      <rect width="24" height="24" rx="4" fill="none" />
      <path
        d="M12.5 7.5C12.5 7.22386 12.2761 7 12 7C11.7239 7 11.5 7.22386 11.5 7.5V12H7C6.72386 12 6.5 12.2239 6.5 12.5C6.5 12.7761 6.72386 13 7 13H11.5V17.5C11.5 17.7761 11.7239 18 12 18C12.2761 18 12.5 17.7761 12.5 17.5V13H17C17.2761 13 17.5 12.7761 17.5 12.5C17.5 12.2239 17.2761 12 17 12H12.5V7.5Z"
        fill={fill}
      />
      <path
        d="M12.5 7.5C12.5 7.22386 12.2761 7 12 7C11.7239 7 11.5 7.22386 11.5 7.5V12H7C6.72386 12 6.5 12.2239 6.5 12.5C6.5 12.7761 6.72386 13 7 13H11.5V17.5C11.5 17.7761 11.7239 18 12 18C12.2761 18 12.5 17.7761 12.5 17.5V13H17C17.2761 13 17.5 12.7761 17.5 12.5C17.5 12.2239 17.2761 12 17 12H12.5V7.5Z"
        fill={stroke}
        fillOpacity="0.2"
      />
    </svg>
  );
};
