import { useFetchProjectQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import {
  CustomUserData,
  DrawModes,
  FlatVector3,
  NodeType,
  SelectedNode,
  SurroundingBuilding,
  UserBuildingBlock,
  UserBuildingCoordinatesObject,
  UserBuildingStorey,
  UserBuildingWall,
} from '@/models';
import { flattenDeep } from 'lodash';

export interface SurroundingBuildingSearchResults
  extends SurroundingBuilding,
    SearchResults {}

export interface SearchResults {
  name: string;
  guid: string;
  userData?: CustomUserData | null;
  nodeType: NodeType;
  //TODO: Refactor parent nodes from array to dictionary
  parentNodes: SelectedNode[];
  childNodes: SelectedNode[];
  getParentNode: (node: NodeType) => SelectedNode | undefined;
  getChildNode: (node: NodeType) => SelectedNode | undefined;
  isParentLocked?: boolean;
}

export interface StoreySearchResults
  extends SearchResults,
    UserBuildingStorey {}

export interface BuildingSearchResults
  extends SearchResults,
    UserBuildingCoordinatesObject {
  roofPoints: FlatVector3[];
  drawMode: DrawModes;
  groundFloorPoints: FlatVector3[];
}

export interface BlockSearchResults extends SearchResults, UserBuildingBlock {
  roofPoints: FlatVector3[];
  storeysCount: number;
  groundFloorPoints: FlatVector3[];
}

export interface WallSearchResults extends SearchResults, UserBuildingWall {}

export const useFindNodeData = () => {
  const { id } = useParams();
  const { data } = useFetchProjectQuery(id!);

  const findNodeByType = (type: NodeType, nodes: SelectedNode[]) =>
    nodes.find((node) => node.type === type);

  const findDataForWall = (guid: string): WallSearchResults | null => {
    const building = data?.buildings?.find((building) =>
      building.blocks.some((block) =>
        block.storeys.some((storey) =>
          storey.walls.some((wall) => wall.guid === guid)
        )
      )
    );
    if (!building) return null;

    const block = building.blocks.find((block) =>
      block.storeys.find((storey) =>
        storey.walls.find((wall) => wall.guid === guid)
      )
    );

    if (!block) return null;

    const storey = block?.storeys.find((storey) =>
      storey.walls.find((wall) => wall.guid === guid)
    );

    if (!storey) return null;

    const wall = storey?.walls.find((wall) => wall.guid === guid);

    if (!wall) return null;

    const parentNodes = [
      {
        type: NodeType.Storey,
        guid: storey.guid,
        userData: storey.userData,
      },
      {
        type: NodeType.Building,
        guid: building.guid,
        userData: building.userData,
      },
      {
        type: NodeType.Block,
        guid: block.guid,
        userData: block.userData,
      },
    ];

    return {
      ...wall,
      nodeType: NodeType.Wall,
      parentNodes,
      childNodes: [],
      getParentNode: (type: NodeType) => findNodeByType(type, parentNodes),
      getChildNode: () => undefined,
    };
  };

  const findDataForBlock = (guid: string): BlockSearchResults | null => {
    const building = data?.buildings?.find((building) =>
      building.blocks.some((block) => block.guid === guid)
    );
    if (!building) return null;

    const block = building.blocks.find((block) => block.guid === guid);

    if (!block) return null;

    const childNodes: SelectedNode[] = flattenDeep(
      block.storeys.map((storey) => [
        {
          type: NodeType.Storey,
          guid: storey.guid,
          userData: storey.userData,
        },
        ...storey.walls.map((wall) => ({
          type: NodeType.Wall,
          guid: wall.guid,
          userData: wall.userData,
        })),
      ])
    );
    const parentNodes = [
      {
        type: NodeType.Building,
        guid: building.guid,
        userData: building.userData,
      },
    ];
    return {
      ...block,
      nodeType: NodeType.Block,
      parentNodes,
      childNodes: childNodes,
      storeysCount: block.storeys.length,
      roofPoints: block.storeys[block.storeys.length - 1].ceiling.points,
      groundFloorPoints: block.storeys[0].floor.points,
      isParentLocked: building.userData?.isLocked,
      getChildNode: (node: NodeType) => findNodeByType(node, childNodes),
      getParentNode: (node: NodeType) => findNodeByType(node, parentNodes),
    };
  };

  const findDataForStorey = (guid: string): StoreySearchResults | null => {
    const building = data?.buildings?.find((building) =>
      building.blocks.some((block) =>
        block.storeys.some((storey) => storey.guid === guid)
      )
    );
    if (!building) return null;

    const block = building.blocks.find((block) =>
      block.storeys.find((storey) => storey.guid === guid)
    );

    if (!block) return null;

    const storey = block?.storeys.find((storey) => storey.guid === guid);

    if (!storey) return null;
    const parentNodes = [
      {
        type: NodeType.Building,
        guid: building.guid,
        userData: building.userData,
      },
      {
        type: NodeType.Block,
        guid: block.guid,
        userData: block.userData,
      },
    ];
    const childNodes = storey.walls.map((wall) => ({
      type: NodeType.Wall,
      guid: wall.guid,
    }));
    return {
      ...storey,
      nodeType: NodeType.Storey,
      parentNodes,
      childNodes,
      getChildNode: (node: NodeType) => findNodeByType(node, childNodes),
      getParentNode: (node: NodeType) => findNodeByType(node, parentNodes),
    };
  };

  const findDataForBuilding = (guid: string): BuildingSearchResults | null => {
    const building = data?.buildings?.find(
      (building) => building.guid === guid
    );
    if (!building) return null;

    const childNodes: SelectedNode[] = flattenDeep(
      building.blocks.map((block) => [
        { type: NodeType.Block, guid: block.guid, userData: block.userData },
        ...block.storeys.map((storey) => [
          {
            type: NodeType.Storey,
            guid: storey.guid,
            userData: storey.userData,
          },
          ...storey.walls.map((wall) => ({
            type: NodeType.Wall,
            guid: wall.guid,
            userData: wall.userData,
          })),
        ]),
      ])
    );

    return {
      ...building,
      nodeType: NodeType.Building,
      centerLineCoordinates: building.centerLineCoordinates,
      parentNodes: [],
      childNodes,
      roofPoints:
        building.blocks[0].storeys[building.blocks[0].storeys.length - 1]
          .ceiling.points,
      groundFloorPoints: building.blocks[0].storeys[0].floor.points,
      getChildNode: (node: NodeType) => findNodeByType(node, childNodes),
      getParentNode: () => undefined,
    };
  };

  const findDataForSurroundingBuilding = (
    guid: string
  ): SurroundingBuildingSearchResults | null => {
    if (!data) return null;

    if (data.environment.surroundingBuildings.guid === guid) {
      const childNodes = data.environment.surroundingBuildings.surroundings.map(
        (sb) => ({
          id: sb.id,
          type: NodeType.SurroundingBuilding,
          guid: sb.guid,
        })
      );
      const parentNodes = [
        {
          type: NodeType.Environment,
          guid: data.environment.guid,
        },
      ];

      return {
        guid: data.environment.surroundingBuildings.guid,
        nodeType: NodeType.SurroundingBuilding,
        name: data.environment.surroundingBuildings.name,
        childNodes,
        parentNodes,
        userData: data.environment.surroundingBuildings.userData,
        getChildNode: (node: NodeType) => findNodeByType(node, childNodes),
        getParentNode: (node: NodeType) => findNodeByType(node, parentNodes),
      };
    } else {
      const surroundingBuilding =
        data.environment.surroundingBuildings.surroundings.find(
          (b) => b.guid === guid
        );
      if (!surroundingBuilding) return null;

      const parentNodes = [
        {
          guid: data.environment.surroundingBuildings.guid,
          type: NodeType.SurroundingBuilding,
        },
        {
          type: NodeType.Environment,
          guid: data.environment.guid,
        },
      ];
      return {
        guid,
        nodeType: NodeType.SurroundingBuilding,
        childNodes: [],
        parentNodes,
        userData: surroundingBuilding.userData,
        postCode: surroundingBuilding.postCode,
        state: surroundingBuilding.state,
        city: surroundingBuilding.city,
        street: surroundingBuilding.street,
        houseNumber: surroundingBuilding.houseNumber,
        building: surroundingBuilding.building,
        name: surroundingBuilding.name,
        isParentLocked:
          data.environment.surroundingBuildings.userData?.isLocked ||
          data.environment.userData?.isLocked,
        getChildNode: () => undefined,
        getParentNode: (node: NodeType) => findNodeByType(node, parentNodes),
      };
    }
  };

  const findDataForEnvironment = (guid: string): SearchResults | null => {
    if (!data) return null;

    if (data.environment.guid === guid) {
      const childNodes = [
        {
          type: NodeType.SurroundingBuilding,
          guid: data.environment.surroundingBuildings.guid,
        },
        ...data.environment.surroundingBuildings.surroundings.map((sb) => ({
          type: NodeType.SurroundingBuilding,
          guid: sb.guid,
        })),
        {
          type: NodeType.ConstructionSite,
          guid: data.environment.constructionSite.guid,
        },
      ];

      return {
        guid,
        name: data.environment.name,
        nodeType: NodeType.Environment,
        childNodes,
        parentNodes: [],
        userData: data.environment.userData,
        isParentLocked: data.environment.userData?.isLocked,
        getChildNode: (node: NodeType) => findNodeByType(node, childNodes),
        getParentNode: () => undefined,
      };
    }
    return null;
  };

  const findDataForConstructionSite = (guid: string): SearchResults | null => {
    if (!data) return null;

    if (data.environment.constructionSite.guid === guid) {
      const parentNodes = [
        {
          type: NodeType.Environment,
          guid: data.environment.guid,
        },
      ];
      return {
        ...data.environment.constructionSite,
        nodeType: NodeType.ConstructionSite,
        childNodes: [],
        parentNodes,
        userData: data.environment.constructionSite.userData,
        isParentLocked: data.environment.userData?.isLocked,
        getChildNode: () => undefined,
        getParentNode: (node: NodeType) => findNodeByType(node, parentNodes),
      };
    }
    return null;
  };

  const findDataForWallInfoViaPanel = ({
    panelId,
    panelGuid,
  }: {
    panelGuid?: string;
    panelId?: number;
  }): WallSearchResults | null => {
    let wallGuid;
    data?.buildings?.find((building) =>
      building.blocks.find((block) =>
        block.storeys.find((storey) =>
          storey.walls.find((wall) => {
            if (
              wall.wallPanels.some(
                (panel) => panel.guid === panelGuid || panel.panelId === panelId
              )
            ) {
              wallGuid = wall.guid;
              return true;
            } else return false;
          })
        )
      )
    );
    if (wallGuid) {
      return findDataForWall(wallGuid);
    } else return null;
  };

  const getNodeData = ({
    guid,
    nodeType,
  }: {
    guid: string;
    nodeType: NodeType;
  }) => {
    switch (nodeType) {
      case NodeType.Wall: {
        return findDataForWall(guid);
      }
      case NodeType.Storey: {
        return findDataForStorey(guid);
      }
      case NodeType.Block: {
        return findDataForBlock(guid);
      }
      case NodeType.Building: {
        return findDataForBuilding(guid);
      }
      case NodeType.SurroundingBuilding: {
        return findDataForSurroundingBuilding(guid);
      }
      case NodeType.Environment: {
        return findDataForEnvironment(guid);
      }
      case NodeType.ConstructionSite: {
        return findDataForConstructionSite(guid);
      }
      default: {
        return null;
      }
    }
  };

  return {
    getNodeData,
    findDataForBlock,
    findDataForBuilding,
    findDataForEnvironment,
    findDataForStorey,
    findDataForSurroundingBuilding,
    findDataForConstructionSite,
    findDataForWall,
    findDataForWallInfoViaPanel,
  };
};
