import React from 'react';
import { round } from 'mathjs';

import { SvgMullions, SvgBorders, SvgCornerUnit, SvgFrames } from '../';

import { Background, ElementData } from '../../models';
import { getHexByName } from '@/components/WindowCreator/helpers/config';

interface SvgUnitProps extends ElementData {
  width: number;
  height: number;
}

const SvgUnit: React.FC<SvgUnitProps> = ({ ...props }) => {
  const {
    borders,
    mullions,
    frames,
    isCorner,
    config,
    unit,
    sideCorner,
    width,
    isNumbering,
  } = props;

  const glazingBeadsColor = {
    hex: getHexByName(config.colors, unit.outsideColor) ?? Background.DEFAULT,
    hasGlare: false,
  };
  const offset = round(width - config.panel.sideWidth * 2, 2);

  return (
    <>
      <SvgMullions
        config={config.panel}
        mullions={mullions}
        color={glazingBeadsColor}
        unitPoints={unit.points}
      />
      <SvgBorders borders={borders} color={glazingBeadsColor} />
      <SvgFrames
        config={config}
        frames={frames}
        unit={unit}
        offset={0}
        isSideCorner={false}
        isCorner={isCorner}
        isNumbering={isNumbering}
      />

      {isCorner && (
        <SvgCornerUnit
          {...sideCorner}
          isNumbering={isNumbering}
          config={config}
          offset={offset}
        />
      )}
    </>
  );
};

export default SvgUnit;
