import React from 'react';

export const WindowIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3C17 2.44772 16.5523 2 16 2H4C3.44772 2 3 2.44772 3 3V17C3 17.5523 3.44772 18 4 18H16C16.5523 18 17 17.5523 17 17V3ZM9.375 3.25H4.25V10.1545H9.375V3.25ZM10.625 10.1545H15.75V3.25H10.625V10.1545ZM4.25 11.4045H15.75V16.75H4.25V11.4045Z"
        fill="#414042"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17 3C17 2.44772 16.5523 2 16 2H4C3.44772 2 3 2.44772 3 3V17C3 17.5523 3.44772 18 4 18H16C16.5523 18 17 17.5523 17 17V3ZM9.375 3.25H4.25V10.1545H9.375V3.25ZM10.625 10.1545H15.75V3.25H10.625V10.1545ZM4.25 11.4045H15.75V16.75H4.25V11.4045Z"
        fill="black"
        fillOpacity="0.2"
      />
    </svg>
  );
};
