import React from 'react';
import { Group, Path } from 'react-konva';
import {
  DefaultHandleProps,
  getDashArray,
  getStrokeWidth,
  OPERABLE_WINDOW_HANDLE_COLOR,
} from '@/components/WindowCreator/elements/InnerWindow/components/handle-helpers';

const RotatedOperableWindowHandle: React.FC<DefaultHandleProps> = ({
  x,
  y,
  outsideView,
  scale,
}) => {
  // 135 and 26 are the width and height obtained from the original SVG
  const posX = (x ?? 0) + (outsideView ? 0 : 135);
  const posY = (y ?? 0) + (outsideView ? 0 : 26);

  return (
    <Group x={posX} y={posY} rotation={outsideView ? 0 : 180}>
      <Path
        data={
          'M122 25C128.627 25 134 19.6274 134 13C134 6.37258 128.627 1 122 1L87 1C80.3726 1 75 6.37258 75 13C75 19.6274 80.3726 25 87 25L122 25Z'
        }
        stroke={OPERABLE_WINDOW_HANDLE_COLOR}
        dash={getDashArray(scale, !!outsideView)}
        strokeWidth={getStrokeWidth(scale)}
      />
      <Path
        data={
          'M105.5 18C107.985 18 110 15.9853 110 13.5C110 11.0147 107.985 9 105.5 9L5.5 9C3.01472 9 1 11.0147 1 13.5C1 15.9853 3.01472 18 5.5 18L105.5 18Z'
        }
        stroke={OPERABLE_WINDOW_HANDLE_COLOR}
        dash={getDashArray(scale, !!outsideView)}
        strokeWidth={getStrokeWidth(scale)}
      />
    </Group>
  );
};

export default RotatedOperableWindowHandle;
