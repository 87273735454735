import React from 'react';
import './AntdDropdown.scss';
import Dropdown from 'antd/es/dropdown';
import { MenuProps } from 'antd/es/menu';
import { DropdownProps as AntDropdownProps } from 'antd/es/dropdown';

interface IntusDropdownProps extends AntDropdownProps {
  children: React.ReactNode;
  customMenuProps: MenuProps;
  menuType?: 'default' | 'simplified';
}

const IntusDropdown: React.FC<IntusDropdownProps> = ({
  children,
  customMenuProps,
  menuType = 'default',
  ...rest
}) => {
  const enhancedMenuProps = {
    ...customMenuProps,
    className:
      menuType === 'simplified'
        ? 'simplified-dropdown-menu'
        : 'default-dropdown-menu ',
  };

  return (
    <Dropdown menu={enhancedMenuProps} {...rest}>
      {children}
    </Dropdown>
  );
};

export default IntusDropdown;
