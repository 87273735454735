import React from 'react';

import SvgMullion from './SvgMullion';

import { Color } from '../../models';
import { FlatVector2 } from '@/models';
import { MullionData, PanelConfig } from '@/models/window-configurator.model';

interface SvgMullionsProps {
  mullions: MullionData[];
  unitPoints: FlatVector2[];
  color: Color;
  config: PanelConfig;
}

const SvgMullions: React.FC<SvgMullionsProps> = ({
  mullions,
  color,
  unitPoints,
  config,
}) => {
  return (
    <>
      {mullions.map((mullion, index) => (
        <SvgMullion
          key={index}
          config={config}
          unitPoints={unitPoints}
          mullionData={mullion}
          color={color}
        />
      ))}
    </>
  );
};

export default SvgMullions;
