import { TextColor } from '@/models';
import { RALColors } from '@/models/window-configurator.model';
import { FillerType } from '@/components/UTC/models';

export const getTextColorByCriteria = (
  material: FillerType,
  color: RALColors
): string => {
  const isLightCanvas =
    color === RALColors.RAL9006 || color === RALColors.RAL9016;
  const isGlass = material === FillerType.Glass;

  return isLightCanvas || isGlass ? TextColor.DARK : TextColor.LIGHT;
};
