import React, { useEffect, useMemo, useRef } from 'react';
import Konva from 'konva';
import { KonvaEventObject } from 'konva/lib/Node';
import { Group, Line } from 'react-konva';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { FlatVector2, UnitSystemTypes } from '@/models';
import {
  calculateInnerWindowPoints,
  EDGE_POSITION,
  generateInnerFramePoints,
  generateWindowSashPoints,
  getWindowOperationTypeViewPoints,
  OUTER_BORDER_THICKNESS,
} from '@/components/WindowCreator/elements/creator-windows.helpers';
import { WindowBorder } from '@/components/WindowCreator/elements';
import WindowGlass from '@/components/WindowCreator/elements/WindowGlass';
import {
  WindowElementType,
  View,
  FlatVector2Axis,
} from '@/components/WindowCreator/models/konva-model';
import {
  getIsWindowNodeSelected,
  getValidationErrors,
  resetHoverArea,
  setHoverArea,
  setValidationError as setValidationErrorReducer,
} from '@/store/slices/windowsReducer/windowCreatorSlice';
import { OperationType } from '@/models/window-configurator.model';
import { OPERABLE_WINDOW_TYPE_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import {
  INVALID_IGU_CONFIG_TITLE,
  WINDOW_HANDLE_HEIGHT,
  WINDOW_HANDLE_WIDTH,
} from '@/components/WindowCreator/constants';
import { get2DCenter, roundKonvaValue } from '@/shared/helpers/konva';
import PlacementElementError from '@/components/WindowCreator/elements/InnerWindow/PlacementElementError';
import { useWindowCreatorSelect } from '@/components/WindowCreator/elements/shared/useWindowCreatorSelect';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { getOperableFrameWidth } from '@/shared/helpers';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import useIguWindowValidation from '../../hooks/useWindowIguValidation';
import useValidateWindowErrors from '../../hooks/useValidateWindowErrors';
import WindowSash from './WindowSash';
import OperableWindowHandle from '@/components/WindowCreator/elements/InnerWindow/components/OperableWindowHandle';
import RotatedOperableWindowHandle from '@/components/WindowCreator/elements/InnerWindow/components/RotatedOperableWindowHandle';
import { WindowCreatorModes } from '@/models/shared.model';

interface OperableWindowProps {
  points: FlatVector2[];
  outerFramePoints: FlatVector2[];
  operationType: OperationType;
  frameColor: string;
  independent?: boolean;
  scale: number;
  units?: UnitSystemTypes;
  view: View;
  distanceToFloor?: number;
  iguId: number | null;
  windowContour?: FlatVector2Axis[];
  mode?: WindowCreatorModes;
}

const OPERABLE_LINE_WIDTH = 4.25;

const OperableWindow = ({
  points,
  operationType,
  outerFramePoints,
  frameColor,
  independent,
  scale,
  units,
  view,
  distanceToFloor,
  iguId,
  windowContour,
  mode,
}: OperableWindowProps) => {
  const { panel: panelsConfig } = useFetchWindowConfigQuery().data!;
  const dispatch = useAppDispatch();
  const isImperialSystem = units === UnitSystemTypes.Imperial;
  const IGUData = useFetchIguQuery().data!;

  const glassRef = useRef<Konva.Line>(null);
  const glassId = useMemo(() => glassRef.current?._id, [glassRef.current?._id]);
  const isSelected = useAppSelector(getIsWindowNodeSelected(glassId ?? 0));
  const validationErrors = useAppSelector(getValidationErrors(glassId ?? 0));

  const iguItem = useMemo(() => {
    if (!iguId || !mode) return null;
    if (!IGUData) return;
    return IGUData.find((item) => item.id === iguId);
  }, [IGUData, iguId]);

  const { selectElementInWindowCreator } = useWindowCreatorSelect(
    glassId ?? 0,
    points
  );
  const operableBorderThickness = getOperableFrameWidth(panelsConfig);

  const outerContourPoints = useMemo(
    () =>
      windowContour ??
      calculateInnerWindowPoints(
        points,
        outerFramePoints,
        panelsConfig.mullion.width
      ),
    [points, outerFramePoints]
  );

  const handleSelect = (event?: KonvaEventObject<MouseEvent>) => {
    selectElementInWindowCreator(
      WindowElementType.Window,
      outerContourPoints,
      event
    );
  };

  const windowGlassPoints = useMemo(() => {
    return outerContourPoints.map((p, i) =>
      generateInnerFramePoints(p, i, operableBorderThickness)
    );
  }, [outerContourPoints]);

  const windowFramePoints = useMemo(() => {
    return outerContourPoints.map((point, i) =>
      generateInnerFramePoints(
        point,
        i,
        panelsConfig.operableFrame.windowFrameWidth
      )
    );
  }, [outerContourPoints]);

  const windowSashPoints = useMemo(() => {
    return generateWindowSashPoints(windowFramePoints, windowGlassPoints);
  }, [windowGlassPoints, windowFramePoints]);

  const windowOperationTypeViewPoints = useMemo(
    (): FlatVector2[][] | null =>
      getWindowOperationTypeViewPoints(windowGlassPoints, operationType, view),
    [operationType, outerContourPoints]
  );

  const windowHandlePoints = useMemo(() => {
    const offset = panelsConfig.operableFrame.sashWidth / 2;
    switch (operationType) {
      case OperationType.DualActionLeftTop:
      case OperationType.CasementLeft: {
        const borderCenter =
          view === View.Outside
            ? get2DCenter(
                windowSashPoints[EDGE_POSITION.RIGHT][0],
                windowSashPoints[EDGE_POSITION.RIGHT][1]
              )
            : get2DCenter(
                windowSashPoints[EDGE_POSITION.LEFT][0],
                windowSashPoints[EDGE_POSITION.LEFT][1]
              );

        return {
          x: roundKonvaValue(
            view === View.Outside
              ? borderCenter[0] - offset - WINDOW_HANDLE_WIDTH / 2
              : borderCenter[0] +
                  OUTER_BORDER_THICKNESS / 2 -
                  WINDOW_HANDLE_WIDTH / 2
          ),
          y: roundKonvaValue(borderCenter[1] - WINDOW_HANDLE_HEIGHT / 6),
        };
      }
      case OperationType.DualActionRightTop:
      case OperationType.CasementRight: {
        const borderCenter =
          view === View.Outside
            ? get2DCenter(
                windowSashPoints[EDGE_POSITION.LEFT][0],
                windowSashPoints[EDGE_POSITION.LEFT][1]
              )
            : get2DCenter(
                windowSashPoints[EDGE_POSITION.RIGHT][0],
                windowSashPoints[EDGE_POSITION.RIGHT][1]
              );

        return {
          x: roundKonvaValue(
            view === View.Outside
              ? borderCenter[0] + offset - WINDOW_HANDLE_WIDTH / 2
              : borderCenter[0] - offset - WINDOW_HANDLE_WIDTH / 2
          ),
          y: roundKonvaValue(borderCenter[1] - WINDOW_HANDLE_HEIGHT / 6),
        };
      }
      case OperationType.Hopper: {
        const borderCenter = get2DCenter(
          windowSashPoints[EDGE_POSITION.TOP][0],
          windowSashPoints[EDGE_POSITION.TOP][1]
        );
        return {
          x: roundKonvaValue(
            view === View.Outside
              ? borderCenter[0] - WINDOW_HANDLE_HEIGHT / 6 - offset
              : borderCenter[0] - WINDOW_HANDLE_HEIGHT / 6
          ),
          y: roundKonvaValue(
            borderCenter[1] + offset - WINDOW_HANDLE_WIDTH / 2
          ),
        };
      }
      case OperationType.Awning: {
        const borderCenter = get2DCenter(
          windowSashPoints[EDGE_POSITION.BOTTOM][0],
          windowSashPoints[EDGE_POSITION.BOTTOM][1]
        );

        return {
          x: roundKonvaValue(
            borderCenter[0] - offset - WINDOW_HANDLE_WIDTH / 2
          ),
          y: roundKonvaValue(
            borderCenter[1] + offset - WINDOW_HANDLE_HEIGHT / 2
          ),
        };
      }
      default:
        return null;
    }
  }, [windowSashPoints, operationType, view]);

  const windowHandler = useMemo(() => {
    if (!windowHandlePoints) return null;
    switch (operationType) {
      case OperationType.Hopper:
      case OperationType.Awning: {
        return (
          <RotatedOperableWindowHandle
            scale={scale}
            outsideView={view === View.Outside}
            x={windowHandlePoints.x}
            y={windowHandlePoints.y}
          />
        );
      }
      default:
        return (
          <OperableWindowHandle
            scale={scale}
            outsideView={view === View.Outside}
            x={windowHandlePoints.x}
            y={windowHandlePoints.y}
          />
        );
    }
  }, [operationType, view, windowHandlePoints, scale]);

  const { collectIguValidationData, validateIguWindow } =
    useIguWindowValidation();

  const { validateWindowErrors } = useValidateWindowErrors();

  useEffect(() => {
    if (!iguItem || !distanceToFloor || !mode || !glassId) return;

    const validationData = collectIguValidationData({
      operationType,
      innerFramePoints: points,
      outerFramePoints,
      distanceToFloor,
      glassType: iguItem.glassType,
      isOperableType: true,
    });

    const iguErrors: string[] = validateIguWindow(
      validationData,
      iguItem.limitations,
      iguItem.thickness,
      units === UnitSystemTypes.Imperial
    );

    const commonErrors = validateWindowErrors({
      innerFramePoints: points,
      outerFramePoints,
    });

    const errors = [...iguErrors, ...commonErrors];

    dispatch(
      setValidationErrorReducer({
        id: glassId,
        errors,
      })
    );

    isSelected && handleSelect();
    return () => {
      dispatch(
        setValidationErrorReducer({
          id: glassId,
          errors: [],
        })
      );
    };
  }, [isImperialSystem, points, outerContourPoints]);

  return (
    <>
      <Group
        onPointerClick={handleSelect}
        onMouseOver={() =>
          independent && dispatch(setHoverArea(outerContourPoints))
        }
        onMouseLeave={() => independent && dispatch(resetHoverArea())}
      >
        <WindowGlass
          points={[
            windowGlassPoints[0][0],
            windowGlassPoints[1][0],
            windowGlassPoints[2][0],
            windowGlassPoints[3][0],
          ]}
          ref={glassRef}
        />
        {windowOperationTypeViewPoints &&
          windowOperationTypeViewPoints.map((operationPoints, i) => (
            <Line
              points={operationPoints.flat()}
              stroke={OPERABLE_WINDOW_TYPE_COLOR}
              strokeWidth={OPERABLE_LINE_WIDTH}
              dash={view === View.Outside ? [20, 10] : undefined}
              key={`operationType_${i}`}
            />
          ))}
        {outerContourPoints.length > 0 &&
          outerContourPoints.map((framePoint, i) => (
            <WindowBorder
              idx={i}
              points={framePoint}
              key={`operableWindowFrame_${i}`}
              borderWidth={panelsConfig.operableFrame.windowFrameWidth}
              color={frameColor}
            />
          ))}
        {windowSashPoints.map((sashPoint, i) => (
          <WindowSash
            points={sashPoint}
            color={frameColor}
            key={`operableWindowSash_${i}`}
          />
        ))}
        {windowHandler}
      </Group>
      {validationErrors?.length > 0 && (
        <PlacementElementError
          errorsTitle={INVALID_IGU_CONFIG_TITLE}
          position={[outerContourPoints[0][1][0], outerContourPoints[0][1][1]]}
          errorList={validationErrors}
          hoverArea={outerContourPoints}
          scale={scale}
        />
      )}
    </>
  );
};

export default OperableWindow;
