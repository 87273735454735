import React, { useMemo, useState } from 'react';
import PanelWidthIcon from '@/shared/icons/PanelWidthIcon';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import ColorsIcon from '@/shared/icons/ColorsIcon';
import PickerPopover from '@/shared/elements/PickerPopover/PickerPopover';
import MaterialIcon from '@/shared/icons/MaterialIcon';
import Form from 'antd/es/form';
import Radio from 'antd/es/radio';
import IntusRadio from '@/shared/elements/Radio/IntusRadio';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import {
  getHexByName,
  getMaterialImage,
} from '@/components/WindowCreator/helpers/config';
import { IntusButton, IntusLoader } from '@/shared/elements';

import { DEFAULT_CORNER_GRID_WIDTH } from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import {
  CornerGridAlign,
  MetricLimits,
  NodeType,
  UnitSystemTypes,
} from '@/models';
import { useGridLines } from '@/components/FacadeDesigner/hooks/useGridLines';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import {
  convertInputValueToMm,
  removeSpacesFromThousands,
} from '@/shared/helpers/format-data';
import { round } from 'mathjs';
import { ResetCornerGrid } from '@/shared/icons/ResetCornerGrid';
import { uniq } from 'lodash';

import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { useCreatePanelsMutation } from '@/store/apis/projectsApi';
import { convertMillimetersToFtInch } from '@/shared/helpers/distance';
import { useFetchIguQuery } from '@/store/apis/webCalcApi';
import IguSelect from '@/shared/elements/IguSelect/IguSelect';
import { PanelMaterials } from '@/models/window-configurator.model';
import useHandleError from '@/shared/hooks/useHandleError';

interface PanelizationSettingsProps {
  areSettingsDisabled: boolean;
  selectedWalls: string[];
}
const PanelizationSettings = ({
  areSettingsDisabled,
  selectedWalls,
}: PanelizationSettingsProps) => {
  const IGUData = useFetchIguQuery().data!;
  const [isLoading, setIsLoading] = useState(false);
  const configData = useFetchWindowConfigQuery().data!;
  const [createPanels] = useCreatePanelsMutation();
  const { getFormattedValue } = useFrameProperties();
  const { findDataForWall } = useFindNodeData();
  const { facadesData } = useFacadeData();
  const { handleError } = useHandleError();
  const buildingWallsGuids = facadesData.flatMap((facade) =>
    facade.map((wall) => wall.guid)
  );
  const selectedWallData = useMemo(
    () => selectedWalls.map((wall) => findDataForWall(wall)!),
    [selectedWalls]
  );

  const buildingGUID =
    useMemo(
      () => selectedWallData[0]?.getParentNode(NodeType.Building)?.guid,
      [selectedWalls]
    ) || '';

  const [form] = Form.useForm();

  const getDefaultPanelIguID = (material: string): number | null => {
    if (material === 'Aluminium') return null;
    return IGUData.filter((val) =>
      material === 'Glass'
        ? !val.name.includes('_EN')
        : val.name.includes('_EN')
    )[0].id;
  };

  const [panelizationSettings, setPanelizationSettings] = useState({
    panelInnerColor: configData.baseWindow.outerColor,
    panelOuterColor: configData.baseWindow.innerColor,
    panelMaterial: 'Aluminium',
    panelIguId: getDefaultPanelIguID('Aluminium'),
  });

  const { getWallWidthMetric } = useFrameProperties({
    metricOnly: true,
  });
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;

  const { updateBuildingPlacements } = useGridLines(buildingGUID);

  const getOffsetValues = (align: CornerGridAlign): number[] => {
    return selectedWallData
      .map((wallData) => ({
        wallWidth: Number(
          removeSpacesFromThousands(
            getWallWidthMetric([wallData]),
            isImperialUnits
          )
        ),
        offset: wallData.gridLines.find((line) => line.cornerAlign === align)
          ?.offsetFromLeftEdge,
      }))
      .filter((offsetData) => !!offsetData.offset)
      .map((offsetData) =>
        align === CornerGridAlign.Left
          ? //should be available due to filter on previous call
            offsetData.offset!
          : round(offsetData.wallWidth - offsetData!.offset!, 2)
      );
  };

  const getCommonCornerGridOffset = (align: CornerGridAlign) => {
    const wallOffsetValues = getOffsetValues(align);
    return wallOffsetValues?.length
      ? getFormattedValue(wallOffsetValues.map((val) => val.toString()))
      : '-';
  };

  const handleCornerEdit = (
    val: string,
    align: CornerGridAlign,
    save: boolean = false
  ) => {
    updateBuildingPlacements({
      buildingGUID,
      triggerBEUpdate: save,
      placementData: selectedWallData.map((wall) => ({
        wallGUID: wall.guid,
        gridLines: wall.gridLines.map((gridLine) => ({
          ...gridLine,
          offsetFromLeftEdge:
            gridLine.cornerAlign === align
              ? align === CornerGridAlign.Left
                ? convertInputValueToMm(val, isImperialUnits)
                : round(
                    Number(
                      removeSpacesFromThousands(
                        getWallWidthMetric([wall]),
                        isImperialUnits
                      )
                    ) - convertInputValueToMm(val, isImperialUnits),
                    2
                  )
              : gridLine.offsetFromLeftEdge,
        })),
      })),
    });
  };

  const handleCornerSubmit = () => {
    updateBuildingPlacements({ buildingGUID });
  };

  const handlePanelize = async () => {
    try {
      setIsLoading(true);
      await createPanels({
        projectId: Number(id),
        data: {
          wallGuids:
            form.getFieldValue('area') === 'selectedArea'
              ? selectedWalls
              : buildingWallsGuids!,
          ...panelizationSettings,
        },
      });
    } catch (error) {
      handleError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const allowCornerReset = (align: CornerGridAlign): boolean => {
    const offsets = getOffsetValues(align);
    const uniqueOffsets = uniq(offsets);
    return (
      uniqueOffsets.length > 0 &&
      uniqueOffsets.some((offset) => offset !== DEFAULT_CORNER_GRID_WIDTH)
    );
  };

  const handleCornerReset = (align: CornerGridAlign) => {
    handleCornerEdit(
      isImperialUnits
        ? convertMillimetersToFtInch(DEFAULT_CORNER_GRID_WIDTH)
        : DEFAULT_CORNER_GRID_WIDTH.toString(),
      align,
      true
    );
  };

  return (
    <IntusLoader loading={isLoading}>
      <div className="text-xs">
        <div className="flex justify-between px-3 min-h-8 items-center">
          <span className="font-medium leading-4">UCW settings</span>
        </div>
        <div
          className={
            'flex flex-col border border-l-0 border-solid border-light-gray-20  overflow-y-auto text-dark-gray-100'
          }
        >
          <div className="flex items-center justify-start gap-1 px-3 pt-3">
            <PanelWidthIcon />
            <span className="font-medium leading-5">UCW CORNER UNIT WIDTH</span>
          </div>
          <PropertyList
            alignValueLeft
            properties={[
              {
                name: 'Left',
                value:
                  getCommonCornerGridOffset(CornerGridAlign.Left) ||
                  DEFAULT_CORNER_GRID_WIDTH.toString(),
                isDisabled: areSettingsDisabled,
                isEditable: getOffsetValues(CornerGridAlign.Left).length > 0,
                onEdit: (val: string) =>
                  handleCornerEdit(val, CornerGridAlign.Left),
                onSubmit: handleCornerSubmit,
                min: MetricLimits.CornerPanelMinWidth,
                max: MetricLimits.CornerPanelMaxWidth,
                validationValueDecimalPlaces: 2,
                inputActionProps: {
                  display: allowCornerReset(CornerGridAlign.Left),
                  icon: <ResetCornerGrid />,
                  id: 'panelization-settings__reset-left-corner',
                  onActionClick: () => handleCornerReset(CornerGridAlign.Left),
                },
              },
              {
                name: 'Right',
                value:
                  getCommonCornerGridOffset(CornerGridAlign.Right) ||
                  DEFAULT_CORNER_GRID_WIDTH.toString(),
                isDisabled: areSettingsDisabled,
                isEditable: getOffsetValues(CornerGridAlign.Right).length > 0,
                onEdit: (val: string) =>
                  handleCornerEdit(val, CornerGridAlign.Right),
                onSubmit: handleCornerSubmit,
                min: MetricLimits.CornerPanelMinWidth,
                max: MetricLimits.CornerPanelMaxWidth,
                validationValueDecimalPlaces: 2,
                inputActionProps: {
                  display: allowCornerReset(CornerGridAlign.Right),
                  icon: <ResetCornerGrid />,
                  id: 'panelization-settings__reset-right-corner',
                  onActionClick: () => handleCornerReset(CornerGridAlign.Right),
                },
              },
            ]}
          />
        </div>
        <div className="text-dark-gray-100 pb-3 border-0 border-b border-solid border-light-gray-20 border-box">
          <div className="flex gap-1 mb-2 px-3 pt-3">
            <ColorsIcon />
            <div className="font-medium leading-5">COLORS</div>
          </div>
          <div className="font-light leading-5 mb-1 px-3">Outside color</div>
          <PickerPopover
            items={configData.colors.map((color) => ({
              id: color.name,
              name: color.description,
              preview: (
                <div
                  className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                  style={{
                    backgroundColor: getHexByName(
                      configData.colors,
                      color.name
                    ),
                  }}
                />
              ),
            }))}
            onChange={(color) =>
              setPanelizationSettings({
                ...panelizationSettings,
                panelOuterColor: color,
              })
            }
            initialValue={panelizationSettings.panelOuterColor}
            disabled={areSettingsDisabled}
            contentHeight={184}
            contentWidth={210}
          />
          <div className="font-light leading-5 my-1 px-3">Inside color</div>
          <PickerPopover
            items={configData.colors.map((color) => ({
              id: color.name,
              name: color.description,
              preview: (
                <div
                  className={`w-6 h-6 box-border border-solid border border-light-gray-20 rounded-full`}
                  style={{
                    backgroundColor: getHexByName(
                      configData.colors,
                      color.name
                    ),
                  }}
                />
              ),
            }))}
            initialValue={panelizationSettings.panelInnerColor}
            onChange={(color) =>
              setPanelizationSettings({
                ...panelizationSettings,
                panelInnerColor: color,
              })
            }
            disabled={areSettingsDisabled}
            contentHeight={184}
            contentWidth={210}
          />
        </div>
        <div className="flex flex-col py-3 gap-2 border-0 border-b border-solid border-light-gray-20 mb-1">
          <div className="flex gap-1 px-3">
            <MaterialIcon />
            <span className="font-medium leading-5">MATERIAL</span>
          </div>
          <PickerPopover
            initialValue={panelizationSettings.panelMaterial}
            items={configData.panelMaterials.map((material) => ({
              name: material.description,
              id: material.name,
              description: material.additionalInfo,
              preview: getMaterialImage(material.name),
            }))}
            onChange={(material) =>
              setPanelizationSettings({
                ...panelizationSettings,
                panelMaterial: material,
                panelIguId: getDefaultPanelIguID(material),
              })
            }
            searchable={false}
            disabled={areSettingsDisabled}
            contentWidth={226}
          />
          {panelizationSettings.panelMaterial
            .toLowerCase()
            .includes('glass') && (
            <>
              <div className="font-light leading-5 px-3 my-1">
                IGU configuration
              </div>
              <IguSelect
                value={panelizationSettings.panelIguId}
                onSelect={(value) =>
                  setPanelizationSettings({
                    ...panelizationSettings,
                    panelIguId: value,
                  })
                }
                materialIsGlass={
                  panelizationSettings.panelMaterial === PanelMaterials.Glass
                }
                isIguForPanel
              />
            </>
          )}
        </div>
        <div className="pt-3 px-3">
          <div className="leading-5 mb-2">Apply to</div>
          <Form form={form} initialValues={{ area: 'selectedArea' }}>
            <Form.Item name="area">
              <Radio.Group
                disabled={areSettingsDisabled}
                className="flex flex-col gap-1 mb-3"
              >
                <IntusRadio value="selectedArea">
                  <span className="text-xs font-light leading-5">
                    Selected area
                  </span>
                </IntusRadio>
                <IntusRadio value="fullBuilding">
                  <span className="text-xs font-light leading-5">
                    Full building
                  </span>
                </IntusRadio>
              </Radio.Group>
            </Form.Item>
          </Form>
          <IntusButton
            disabled={areSettingsDisabled}
            block
            onClick={handlePanelize}
            className="rounded-lg"
            id="panelizationSettings__PanelizeButton"
          >
            Create UCW Units
          </IntusButton>
        </div>
      </div>
    </IntusLoader>
  );
};

export default PanelizationSettings;
