import { FlatVector2 } from '@/models';
import { PanelConfig } from '@/models/window-configurator.model';
import { OperableConfig } from '../models';

export const generateSvgCornerBorders = (
  unitPoints: FlatVector2[],
  sideUnitPoints: FlatVector2[],
  config: PanelConfig
): FlatVector2[][] => {
  const topLeftBorder: FlatVector2[] = [
    [unitPoints[0][0], unitPoints[0][1]],
    [unitPoints[1][0] - config.sideWidth, unitPoints[1][1]],
    [unitPoints[1][0] - config.topWidth, unitPoints[1][1] + config.topWidth],
    [unitPoints[0][0], unitPoints[0][1] + config.topWidth],
  ];

  const topRightBorder: FlatVector2[] = [
    [sideUnitPoints[0][0] - config.sideWidth, sideUnitPoints[0][1]],
    [sideUnitPoints[1][0] - config.sideWidth * 2, sideUnitPoints[1][1]],
    [
      sideUnitPoints[1][0] - config.sideWidth * 2,
      sideUnitPoints[1][1] + config.topWidth,
    ],
    [
      sideUnitPoints[0][0] - config.sideWidth,
      sideUnitPoints[0][1] + config.topWidth,
    ],
  ];

  const rightBorder: FlatVector2[] = [
    [
      sideUnitPoints[1][0] - config.sideWidth * 3,
      sideUnitPoints[1][1] + config.topWidth,
    ],
    [
      sideUnitPoints[2][0] - config.sideWidth * 3,
      sideUnitPoints[2][1] - config.bottomWidth,
    ],
    [
      sideUnitPoints[1][0] - config.sideWidth * 2,
      sideUnitPoints[2][1] - config.bottomWidth,
    ],
    [
      sideUnitPoints[1][0] - config.sideWidth * 2,
      sideUnitPoints[1][1] + config.topWidth,
    ],
  ];

  const bottomRightBorder: FlatVector2[] = [
    [sideUnitPoints[2][0] - config.sideWidth * 2, sideUnitPoints[2][1]],
    [sideUnitPoints[3][0] - config.sideWidth, sideUnitPoints[3][1]],
    [
      sideUnitPoints[3][0] - config.sideWidth,
      sideUnitPoints[3][1] - config.bottomWidth,
    ],
    [
      sideUnitPoints[2][0] - config.sideWidth * 2,
      sideUnitPoints[2][1] - config.bottomWidth,
    ],
  ];

  const bottomLeftBorder: FlatVector2[] = [
    [unitPoints[2][0] - config.sideWidth, unitPoints[2][1]],
    [unitPoints[3][0], unitPoints[3][1]],
    [unitPoints[3][0], unitPoints[3][1] - config.bottomWidth],
    [
      unitPoints[2][0] - config.sideWidth,
      unitPoints[2][1] - config.bottomWidth,
    ],
  ];

  const leftBorder: FlatVector2[] = [
    [unitPoints[3][0], unitPoints[3][1] - config.bottomWidth],
    [unitPoints[0][0], unitPoints[0][1] + config.topWidth],
    [unitPoints[0][0] + config.sideWidth, unitPoints[0][1] + config.topWidth],
    [
      unitPoints[3][0] + config.sideWidth,
      unitPoints[3][1] - config.bottomWidth,
    ],
  ];

  return [
    topLeftBorder,
    topRightBorder,
    rightBorder,
    bottomLeftBorder,
    bottomRightBorder,
    leftBorder,
  ];
};

export const generateSvgOperableBorders = (
  points: FlatVector2[],
  config: OperableConfig
): {
  outerBorders: FlatVector2[][];
  sashBorders: FlatVector2[][];
} => {
  const commonWidth = config.outerWidth + config.sashWidth;

  // Outer Borders
  const topOuterBorder: FlatVector2[] = [
    [points[0][0], points[0][1]],
    [points[1][0], points[1][1]],
    [points[1][0] - config.outerWidth, points[1][1] + config.outerWidth],
    [points[0][0] + config.outerWidth, points[0][1] + config.outerWidth],
  ];

  const rightOuterBorder: FlatVector2[] = [
    [points[1][0], points[1][1]],
    [points[2][0], points[2][1]],
    [points[1][0] - config.outerWidth, points[2][1] - config.outerWidth],
    [points[1][0] - config.outerWidth, points[1][1] + config.outerWidth],
  ];

  const bottomOuterBorder: FlatVector2[] = [
    [points[2][0] - config.outerWidth, points[2][1] - config.outerWidth],
    [points[3][0] + config.outerWidth, points[3][1] - config.outerWidth],
    [points[3][0], points[3][1]],
    [points[2][0], points[2][1]],
  ];

  const leftOuterBorder: FlatVector2[] = [
    [points[3][0] + config.outerWidth, points[3][1] - config.outerWidth],
    [points[0][0] + config.outerWidth, points[0][1] + config.outerWidth],
    [points[0][0], points[0][1]],
    [points[3][0], points[3][1]],
  ];

  // Sash Borders
  const topSashBorder: FlatVector2[] = [
    [points[0][0] + config.outerWidth, points[0][1] + config.outerWidth],
    [points[1][0] - config.outerWidth, points[1][1] + config.outerWidth],
    [points[1][0] - commonWidth, points[1][1] + commonWidth],
    [points[0][0] + commonWidth, points[0][1] + commonWidth],
  ];

  const rightSashBorder: FlatVector2[] = [
    [points[1][0] - config.outerWidth, points[1][1] + config.outerWidth],
    [points[2][0] - config.outerWidth, points[2][1] - config.outerWidth],
    [points[1][0] - commonWidth, points[2][1] - commonWidth],
    [points[1][0] - commonWidth, points[1][1] + commonWidth],
  ];

  const bottomSashBorder: FlatVector2[] = [
    [points[2][0] - commonWidth, points[2][1] - commonWidth],
    [points[3][0] + commonWidth, points[3][1] - commonWidth],
    [points[3][0] + config.outerWidth, points[3][1] - config.outerWidth],
    [points[2][0] - config.outerWidth, points[2][1] - config.outerWidth],
  ];

  const leftSahsBorder: FlatVector2[] = [
    [points[3][0] + commonWidth, points[3][1] - commonWidth],
    [points[0][0] + commonWidth, points[0][1] + commonWidth],
    [points[0][0] + config.outerWidth, points[0][1] + config.outerWidth],
    [points[3][0] + config.outerWidth, points[3][1] - config.outerWidth],
  ];

  return {
    outerBorders: [
      topOuterBorder,
      rightOuterBorder,
      bottomOuterBorder,
      leftOuterBorder,
    ],
    sashBorders: [
      topSashBorder,
      rightSashBorder,
      bottomSashBorder,
      leftSahsBorder,
    ],
  };
};
