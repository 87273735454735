import React from 'react';

interface QuoteBuildingProps {
  snapshots: string[];
}

const QuoteBuilding: React.FC<QuoteBuildingProps> = ({ snapshots }) => {
  const [frontView, leftView, rightView, backView] = snapshots;

  return (
    <div className="h-full w-full flex flex-col">
      <div className="p-6 box-border grid grid-cols-2 grid-rows-2 gap-4 bg-[#ECEEEE] flex-1 overflow-hidden">
        <div className="overflow-hidden">
          <img className="h-full w-full" src={frontView} alt="First View" />
        </div>
        <div className="overflow-hidden">
          <img className="h-full w-full" src={leftView} alt="Second View" />
        </div>
        <div className="overflow-hidden">
          <img className="h-full w-full" src={rightView} alt="Third View" />
        </div>
        <div className="overflow-hidden">
          <img className="h-full w-full" src={backView} alt="Fourth View" />
        </div>
      </div>
    </div>
  );
};

export default QuoteBuilding;
