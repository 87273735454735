import React, { useEffect, useMemo, useState } from 'react';
import { ThreeEvent, useThree } from '@react-three/fiber';
import { Vector3, Line3 } from 'three';
import { flatten } from 'lodash';

import {
  CanvasActionsModes,
  DrawModes,
  EditModes,
  FlatVector3,
  NodeType,
  SelectedNode,
  UserBuildingBlock,
  UserBuildingStorey,
  UserBuildingWall,
} from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getEditedNode,
  selectNodes,
  setEditedNode,
  switchIsolatedFlags,
} from '@/store/slices/canvasBuildingSlice';
import {
  findFacadePointsHelper,
  generateTopBorderArray,
  generateWallMesh,
  getExtendedMouseVector,
} from '@/routes/dashboard/projects/project/UserBuilding/helpers/editing-tools.helpers';
import {
  getProcessingEntity,
  resetExternalElementsState,
} from '@/store/slices/canvasExternalElementsSlice';
import SplitFaces from '@/routes/dashboard/projects/project/UserBuilding/components/SplitTool/SplitFaces';
import SplitLine from '@/routes/dashboard/projects/project/UserBuilding/components/SplitTool/SplitLine';
import {
  ExtendedMinMaxCoordinatesPairs,
  getMinMaxCoordinatesAtVector3,
  getXYZ,
  isPointsInOneLine,
} from '@/routes/dashboard/projects/project/project-canvas.helpers';
import {
  convertFlatVector3ToVector,
  convertFlatVector3ToVectors,
  generateWalls,
} from '@/routes/dashboard/projects/project/UserBuilding/user-building.helpers';
import useMouseOutsideMesh from '@/shared/hooks/useMouseOutsideMesh';
import { useSplitCutDirectionalInput } from '@/routes/dashboard/projects/project/UserBuilding/helpers/useSplitCutDirectionalInput';
import { PROJECT_CANVAS_ID } from '@/shared/helpers/canvas-verifiers';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import {
  getStatusIsolateMode,
  setEditMode,
  setMode,
} from '@/store/slices/canvasModesSlice';
import { isRightClick } from '@/shared/helpers';
import { getIsCameraRotating } from '@/store/slices/canvasCamerasSlice';
import { useUnmount } from 'react-use';
import { disposeNode } from '@/shared/helpers/canvas';
import { useFacadeData } from '@/shared/hooks/useFacadeData';
import { useParams } from 'react-router';
import { getMultiplyRate } from '@/store/slices/projectSlice';

interface SplitToolProps {
  block: UserBuildingBlock;
  buildingGUID: string;
}

const SplitTool: React.FC<SplitToolProps> = ({ block, buildingGUID }) => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  const multiplyRate = useAppSelector(getMultiplyRate(id!));

  const editedNode = useAppSelector(getEditedNode);
  const processingEntity = useAppSelector(getProcessingEntity);
  const isIsolateModeEnabled = useAppSelector(getStatusIsolateMode);
  const isCameraRotating = useAppSelector(getIsCameraRotating);
  const { scene } = useThree();
  const [isAllowedToSplit, setIsAllowedToSplit] = useState(false);
  const [mousePosition, setMousePosition] = useState<Vector3 | null>(null);
  const [currentFacePoints, setCurrentFacePoints] = useState<FlatVector3[]>();
  const [minMax, setMinMax] = useState<ExtendedMinMaxCoordinatesPairs>();
  const [currentWall, setCurrentWall] = useState<UserBuildingWall>();
  const [isMouseOnWall, setIsMouseOnWall] = useState(false);

  const {
    updateBlockStoreys,
    updateUserBuildingStoreyData,
    updateUserBuildingDrawMode,
  } = useUpdateUserBuildingData();

  const { facadesData } = useFacadeData();
  const availableWalls = useMemo(() => {
    if (!editedNode?.childrenEditedNodes) return facadesData;

    return facadesData.filter((facade) =>
      facade.some((wall) =>
        editedNode.childrenEditedNodes!.some((guid) => guid === wall.guid)
      )
    );
  }, [facadesData, editedNode]);

  const findWall = () => {
    let wallData: UserBuildingWall | undefined;
    const storeyData = block.storeys.find((storey) => {
      const wall = storey.walls.find((wall) => wall.guid === editedNode?.guid);
      if (wall) {
        wallData = wall;
      }

      return wall;
    });
    return { storeyData, wallData };
  };

  const findStorey = () => {
    let wallData: UserBuildingWall | undefined;
    const storeyData = block.storeys.find((storey) => {
      if (storey.guid === editedNode?.guid) {
        const wall = storey.walls.find(
          (wall) => wall.guid === currentWall?.guid
        );
        if (wall) {
          wallData = wall;
        }
        return true;
      }

      return false;
    });
    return { storeyData, wallData };
  };
  const findWallOrStorey = () => {
    return editedNode?.type === NodeType.Wall ? findWall() : findStorey();
  };

  const isBlockSplitted =
    editedNode?.type === NodeType.Block ||
    editedNode?.type === NodeType.Building;
  const isWallSplitted = editedNode?.type === NodeType.Wall;
  const isStoreySplitted = editedNode?.type === NodeType.Storey;

  const wallArray = useMemo(() => {
    if (!editedNode) return [];
    if (isBlockSplitted) {
      return availableWalls.flatMap((walls) => {
        return walls.flatMap((wall) => {
          const storey = block.storeys.find((storey) =>
            storey.walls.some((storeyWall) => wall.guid === storeyWall.guid)
          );
          if (!storey) return [];
          return generateWallMesh(wall, storey.floor.points);
        });
      });
    }
    if (isStoreySplitted) {
      const storey = block.storeys.find(
        (storey) => storey.guid === editedNode?.guid
      );
      if (!storey) return [];
      return storey.walls.map((wall) =>
        generateWallMesh(wall, storey.floor.points)
      );
    }
    if (isWallSplitted) {
      const { storeyData, wallData } = findWall();
      if (!storeyData || !wallData) return [];
      return [generateWallMesh(wallData, storeyData.floor.points)];
    }
    return [];
  }, [block, availableWalls]);

  const topBorderEdgeArray = useMemo(
    () => generateTopBorderArray(block, isMouseOnWall),
    [block, isMouseOnWall]
  );

  const topBorderArray = useMemo(() => {
    const handleMousePosition = (wall: UserBuildingWall, mouse: Vector3) => {
      if (processingEntity?.active) return;
      const start = convertFlatVector3ToVector(wall.points[0]);
      const end = convertFlatVector3ToVector(wall.points[1]);
      const closestPoint = new Vector3();
      new Line3(start, end).closestPointToPoint(mouse, true, closestPoint);
      handlePointerMove(closestPoint, wall);
    };

    const handlePointerEnter = (wall: UserBuildingWall) => {
      if (editedNode?.childrenEditedNodes) {
        editedNode.childrenEditedNodes.some((guid) => guid === wall.guid) &&
          setIsAllowedToSplit(true);
      } else {
        setIsAllowedToSplit(true);
      }
    };

    return topBorderEdgeArray.map((edge) =>
      edge ? (
        <primitive
          object={edge.border}
          onPointerMove={(e: ThreeEvent<PointerEvent>) => {
            if (isMouseOnWall) return;
            e.stopPropagation();
            edge.wall && handleMousePosition(edge.wall, e.point);
          }}
          onPointerEnter={() => edge.wall && handlePointerEnter(edge.wall)}
          key={edge.border.id}
          position={[0, 0.001, 0]}
          visible={false}
        />
      ) : null
    );
  }, [block, isMouseOnWall, editedNode]);

  const findFacePoints = (wall: UserBuildingWall, mousePosition: Vector3) => {
    if (isBlockSplitted) {
      const facadeData = findFacadePointsHelper(
        mousePosition,
        block,
        wall,
        editedNode.childrenEditedNodes
      );
      if (!facadeData) return;

      setMinMax(facadeData.minMax);
      setCurrentFacePoints(facadeData.points);
    }
    if ((isWallSplitted || isStoreySplitted) && wall) {
      const minMax = getMinMaxCoordinatesAtVector3(
        convertFlatVector3ToVectors(wall.points)
      );
      setMinMax(minMax);
      setCurrentFacePoints(wall.points);
    }
  };

  const handlePointerMove = (position: Vector3, wall: UserBuildingWall) => {
    if (
      editedNode?.childrenEditedNodes &&
      !editedNode.childrenEditedNodes.some((guid) => guid === wall.guid)
    ) {
      setIsAllowedToSplit(false);
      return;
    }
    if (isCameraRotating) return;
    !isAllowedToSplit && setIsAllowedToSplit(true);
    if (processingEntity?.active) return;
    setMousePosition(position);
    setCurrentWall(wall);
    findFacePoints(wall, position);
  };

  const updateShapePoints = (
    floorPoints: FlatVector3[],
    ceilingPoints: FlatVector3[],
    insertPoint: Vector3
  ) => {
    let insertionIndex = 0;
    const insertionPoint = new Vector3(
      insertPoint.x,
      floorPoints[0][1],
      insertPoint.z
    );
    for (let i = 0; i < floorPoints.length - 1; i++) {
      const p = isPointsInOneLine(
        [floorPoints[i][0], floorPoints[i][2]],
        [insertPoint.x, insertPoint.z],
        [floorPoints[i + 1][0], floorPoints[i + 1][2]]
      );
      if (p) {
        const newInsertionPoint = new Vector3();
        new Line3(
          convertFlatVector3ToVector(floorPoints[i]),
          convertFlatVector3ToVector(floorPoints[i + 1])
        ).closestPointToPoint(insertionPoint, true, newInsertionPoint);

        insertionPoint.copy(newInsertionPoint);

        insertionIndex = i + 1;
      }
    }

    if (insertionIndex === 0) {
      return {
        newFloorPoints: floorPoints,
        newCeilingPoints: ceilingPoints,
      };
    }
    const newPoints: FlatVector3[] = [
      ...floorPoints.slice(0, insertionIndex),
      getXYZ(insertionPoint),
      ...floorPoints.slice(insertionIndex),
    ];

    return {
      newFloorPoints: newPoints,
      newCeilingPoints: newPoints.map(
        (point): FlatVector3 => [point[0], ceilingPoints[0][1], point[2]]
      ),
    };
  };

  const generateSplittedStorey = (
    storey: UserBuildingStorey,
    wallData: UserBuildingWall,
    splitPosition: Vector3
  ): UserBuildingStorey => {
    if (
      editedNode?.childrenEditedNodes &&
      !editedNode.childrenEditedNodes?.some((guid) => guid === wallData.guid)
    )
      return storey;

    const splitPositionCopy = splitPosition.clone();

    const { newFloorPoints, newCeilingPoints } = updateShapePoints(
      storey.floor.points,
      storey.ceiling.points,
      splitPositionCopy
    );

    const newWalls = generateWalls(
      convertFlatVector3ToVectors(newFloorPoints),
      convertFlatVector3ToVectors(newCeilingPoints),
      storey.walls,
      wallData.name
    );

    return {
      ...storey,
      ceiling: { ...storey.ceiling, points: newCeilingPoints },
      floor: { ...storey.floor, points: newFloorPoints },
      walls: newWalls,
    };
  };

  const generateSplittedBlock = (mousePosition: Vector3) => {
    return block.storeys.map((storey) => {
      const wall = storey.walls.find((wall) =>
        isPointsInOneLine(
          [wall.points[0][0], wall.points[0][2]],
          [mousePosition.x, mousePosition.z],
          [wall.points[1][0], wall.points[1][2]]
        )
      );
      if (!wall) return storey;
      return generateSplittedStorey(storey, wall, mousePosition);
    });
  };

  const finishSplit = (mousePosition: Vector3, event?: PointerEvent) => {
    if (event && isRightClick(event)) return;
    if (!mousePosition || !minMax) return;

    if (isWallSplitted || isStoreySplitted) {
      const { storeyData, wallData } = findWallOrStorey();
      if (!storeyData || !wallData) return;

      const newStorey = generateSplittedStorey(
        storeyData,
        wallData,
        mousePosition
      );

      updateUserBuildingStoreyData({
        buildingGuid: buildingGUID,
        blockGuid: block.guid,
        storeyGuid: storeyData.guid,
        newStorey,
      });

      updateUserBuildingDrawMode({
        buildingGuid: buildingGUID,
        drawMode: DrawModes.FreeDraw,
      });

      const newGuidsToIsolate = newStorey.walls
        .filter(
          (wall) =>
            !storeyData.walls.find(
              (storeyWall) => storeyWall.guid === wall.guid
            )
        )
        .map((wall) => wall.guid);

      isIsolateModeEnabled && dispatch(switchIsolatedFlags(newGuidsToIsolate));

      if (isWallSplitted) {
        const newSelectedNodes: SelectedNode[] = newGuidsToIsolate.map(
          (guid) => ({
            type: NodeType.Wall,
            guid: guid,
          })
        );
        dispatch(selectNodes(newSelectedNodes));
      }

      if (isStoreySplitted) {
        dispatch(selectNodes([editedNode]));
      }
    }
    if (isBlockSplitted) {
      const newStoreys = generateSplittedBlock(mousePosition);

      updateBlockStoreys({
        buildingGUID,
        blockGUID: block.guid,
        newStoreys,
      });
      updateUserBuildingDrawMode({
        buildingGuid: buildingGUID,
        drawMode: DrawModes.FreeDraw,
      });

      if (editedNode) {
        dispatch(selectNodes([editedNode]));
      }

      const newGuidsToIsolate = flatten(
        newStoreys.map((storey) =>
          storey.walls
            .filter(
              (wall) =>
                !block.storeys.find((storey) =>
                  storey.walls.find(
                    (storeyWall) => storeyWall.guid === wall.guid
                  )
                )
            )
            .map((wall) => wall.guid)
        )
      );
      isIsolateModeEnabled && dispatch(switchIsolatedFlags(newGuidsToIsolate));
    }

    resetEditMode();
  };

  const resetEditMode = () => {
    dispatch(setEditMode(EditModes.Unset));
    dispatch(setEditedNode(undefined));
    dispatch(resetExternalElementsState());
    dispatch(setMode(CanvasActionsModes.selection));
  };

  const keydownEvent = (event: KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSplitAction();
      resetEditMode();
    }
  };

  useMouseOutsideMesh({
    meshes: [wallArray, topBorderEdgeArray.map((edge) => edge!.border)].flat(),
    onMouseNotIntersectMeshes: () =>
      !isCameraRotating && setIsAllowedToSplit(false),
  });

  useMouseOutsideMesh({
    meshes: [...wallArray],
    onMouseNotIntersectMeshes: () =>
      !isCameraRotating && setIsMouseOnWall(false),
    onMouseIntersectMeshes: () => !isCameraRotating && setIsMouseOnWall(true),
  });

  useSplitCutDirectionalInput({
    facePoints: currentFacePoints,
    mousePosition,
    isAllowed: isAllowedToSplit,
    setMousePosition,
  });

  const handleSplitAction = () => {
    if (
      !currentFacePoints?.length ||
      !processingEntity?.value ||
      !mousePosition
    )
      return;

    const extendedVector = getExtendedMouseVector(
      currentFacePoints,
      mousePosition,
      multiplyRate
    );

    finishSplit(extendedVector);
  };

  useEffect(() => {
    const canvas = document.getElementById(PROJECT_CANVAS_ID);
    const isAbleToSplit = isAllowedToSplit && !isCameraRotating;
    isAbleToSplit && canvas?.addEventListener('pointerdown', handleSplitAction);
    isAbleToSplit && document.addEventListener('keydown', keydownEvent);
    return () => {
      canvas?.removeEventListener('pointerdown', handleSplitAction);
      document.removeEventListener('keydown', keydownEvent);
    };
  }, [mousePosition, isAllowedToSplit, isCameraRotating]);

  useUnmount(() => {
    wallArray.forEach((wall) => {
      disposeNode(wall, scene);
    });
    topBorderEdgeArray.forEach(
      (edgeData) => edgeData && disposeNode(edgeData.border, scene)
    );
  });

  if (!wallArray) return null;

  return (
    <>
      {isBlockSplitted && topBorderArray}
      {wallArray.map((edge) => (
        <primitive
          object={edge}
          onPointerMove={(event: ThreeEvent<PointerEvent>) => {
            event.stopPropagation();
            const position = event.pointOnLine ?? event.point;
            handlePointerMove(position, event.object.userData.wallData);
          }}
          key={edge.uuid}
          onPointerEnter={() => setIsAllowedToSplit(true)}
          visible={false}
        />
      ))}
      {isAllowedToSplit && mousePosition && minMax && currentFacePoints && (
        <>
          <SplitFaces
            mousePosition={mousePosition}
            minMaxCoordinates={minMax}
            wallPoints={currentFacePoints}
          />
          <SplitLine
            position={mousePosition}
            minMaxCoordinates={minMax}
            wallPoints={currentFacePoints}
          />
        </>
      )}
    </>
  );
};

export default SplitTool;
