import React from 'react';
import { IntusCollapse } from '@/shared/elements';
import type { CollapseProps } from 'antd/es/collapse';
import BrowserPanelItem from '../BrowserPanelItem';
import { EnvironmentStructure, HeaderPanelItemProps, NodeType } from '@/models';
import ConstructionSitePanelItem from './ConstructionSitePanelItem';
import SurroundingBuildingsPanelItem from './SurroundingBuildingsPanelItem';
import { useUpdateProjectEnvironmentData } from '@/shared/hooks/updateProjectDataHooks/useUpdateProjectEnvironmentData';
import { useIsolationHandlers } from '@/shared/hooks/useIsolationHandlers';
import { useAppSelector } from '@/store/hooks';
import { getIsNodeSelected } from '@/store/slices/canvasBuildingSlice';

interface EnvironmentPanelItemProps extends HeaderPanelItemProps {
  environment: EnvironmentStructure;
}

const EnvironmentPanelItem: React.FC<EnvironmentPanelItemProps> = ({
  depthLevel,
  environment,
  onCollapseChange,
  keys,
}) => {
  const { environmentUtils } = useUpdateProjectEnvironmentData();

  const { isIsolateModeEnabled } = useIsolationHandlers();
  const isNodeSelected = useAppSelector(getIsNodeSelected(environment.guid));

  const handleShowHideEnvironment = () => {
    environmentUtils.updateEnvironmentUserData(
      'isHidden',
      !environment.userData?.isHidden
    );
  };

  const handleLockUnlockEnvironment = () => {
    environmentUtils.updateEnvironmentUserData(
      'isLocked',
      !environment.userData?.isLocked
    );
  };

  const collapseItems: CollapseProps['items'] = [
    {
      key: environment.guid,
      label: (
        <BrowserPanelItem
          depthLevel={depthLevel}
          text="Environment"
          nodeType={NodeType.Environment}
          elementGUID={environment.guid}
          showHideAction={
            !isIsolateModeEnabled ? handleShowHideEnvironment : undefined
          }
          lockUnlockAction={handleLockUnlockEnvironment}
          isHidden={!!environment.userData?.isHidden}
          isLocked={!!environment.userData?.isLocked}
        />
      ),
      children: (
        <div>
          <SurroundingBuildingsPanelItem
            depthLevel={depthLevel + 1}
            surroundingBuildings={environment.surroundingBuildings}
            isParentSelected={isNodeSelected}
            isParentLocked={environment.userData?.isLocked}
            isParentHidden={environment.userData?.isHidden}
          />
          <ConstructionSitePanelItem
            depthLevel={depthLevel + 1}
            isParentLocked={environment.userData?.isLocked}
            isParentHidden={environment.userData?.isHidden}
            isParentSelected={isNodeSelected}
            constructionSite={environment.constructionSite}
          />
        </div>
      ),
    },
  ];

  return (
    <IntusCollapse
      items={collapseItems}
      collapsible={'icon'}
      activeKey={keys}
      onChange={onCollapseChange}
    />
  );
};

export default EnvironmentPanelItem;
