import { Group, Line } from 'react-konva';
import React, { useEffect, useMemo, useState } from 'react';

import { PointerPosition } from '@/models/shared.model';
import { FlatVector2, UnitSystemTypes } from '@/models';
import { getCenterFrom2DFlatVectorsArray } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { Vector2 } from 'three';
import { Cross } from '@/components/WindowCreator/elements/shared/Cross';
import {
  ACTIVE_ELEMENT_COLOR,
  INITIAL_SCALE,
  PROHIBITED_ELEMENT_COLOR,
} from '@/components/WindowCreator/constants';

import {
  findShapeClosestDistanceToAxis,
  isPointIntersectLine,
} from '@/components/WindowCreator/helpers/direction.helpers';
import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models/konva-model';
import { KonvaEventObject } from 'konva/lib/Node';
import { publish } from '@/core/events';
import { CREATE_UTC_MULLION } from '@/core/event-names';
import { round } from 'mathjs';

import { useAppDispatch, useAppSelector } from '@/store/hooks';
import { isLeftClick } from '@/shared/helpers';
import MultiMeasurementLine from '@/shared/components/MultiMeasurementLine/MultiMeasurementLine';
import { useUnmount, useUpdate } from 'react-use';

import {
  isMullionPlacementError,
  setMullionErrors,
} from '@/store/slices/sharedSlice';
import { MULLION_CROSS_SAFE_AREA } from '@/shared/form/validators';
import {
  getMultiMeasurementActiveStatus,
  resetMullionArea,
  setMultiMeasurementActiveStatus,
} from '@/store/slices/windowsReducer/UTCSlice';
import { COMMON_LINE_STROKE_WIDTH } from '@/shared/constants';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';

const MIN_MULLION_DISTANCE = 130; //60 from frame, 60 from mullion and 10 more. Should be rewritten after BE updates default values

interface MullionGeneratorProps {
  scale: number;
  points: FlatVector2[];
  units: UnitSystemTypes;
  outerFramePoints: FlatVector2[];
}
const UTCMullionGenerator = ({
  scale,
  points,
  units,
  outerFramePoints,
}: MullionGeneratorProps) => {
  const { panel } = useFetchWindowConfigQuery().data!;

  const dispatch = useAppDispatch();
  const [isMullionVertical, setIsMullionVertical] = useState(true);
  const [pointerPosition, setPointerPosition] =
    useState<PointerPosition | null>(null);
  const inputActive = useAppSelector(getMultiMeasurementActiveStatus);
  const [mullionAxis, setMullionAxis] = useState<FlatVector2Axis | null>(null);
  const [visibleMullionAxis, setVisibleMullionAxis] =
    useState<FlatVector2Axis | null>(null);
  const [measurementPoints, setMeasurementPoints] = useState<FlatVector2Axis[]>(
    []
  );
  const isMullionInvalid = useAppSelector(isMullionPlacementError);
  const update = useUpdate();

  const center: Vector2 = useMemo(
    () => getCenterFrom2DFlatVectorsArray(points),
    [points]
  );

  const [isSnapToCenter, setIsSnapToCenter] = useState<boolean>(false);

  useEffect(() => {
    if (pointerPosition) {
      const axisBasedDistanceToCenter = isMullionVertical
        ? Math.abs(center.x - pointerPosition.x)
        : Math.abs(center.y - pointerPosition.y);
      setIsSnapToCenter(
        center.distanceTo(new Vector2(pointerPosition.x, pointerPosition.y)) <
          50 || axisBasedDistanceToCenter < 25
      );

      const distanceToVerticalBorder = findShapeClosestDistanceToAxis(
        points,
        'x',
        pointerPosition.x
      );
      const distanceToHorizontalBorder = findShapeClosestDistanceToAxis(
        points,
        'y',
        pointerPosition.y
      );
      if (distanceToHorizontalBorder < MIN_MULLION_DISTANCE) {
        setIsMullionVertical(true);
      } else if (distanceToVerticalBorder < MIN_MULLION_DISTANCE) {
        setIsMullionVertical(false);
      }
    } else {
      setIsSnapToCenter(false);
    }
  }, [pointerPosition]);

  const isPointsOnContour = useMemo(() => {
    return {
      vertical: {
        top: isPointIntersectLine(
          [points[0][0], points[0][1]],
          [outerFramePoints[0], outerFramePoints[1]]
        ),
        bottom: isPointIntersectLine(
          [points[0][0], points[2][1]],
          [outerFramePoints[2], outerFramePoints[3]]
        ),
      },
      horizontal: {
        left: isPointIntersectLine(
          [points[0][0], points[0][1]],
          [outerFramePoints[0], outerFramePoints[3]]
        ),
        right: isPointIntersectLine(
          [points[2][0], points[0][1]],
          [outerFramePoints[1], outerFramePoints[2]]
        ),
      },
    };
  }, [points, outerFramePoints]);

  useEffect(() => {
    if (!pointerPosition || !measurementPoints?.length) {
      setMullionAxis(null);
      setVisibleMullionAxis(null);
      return;
    }

    const frameId = requestAnimationFrame(() => {
      let axis: FlatVector2Axis;
      let visibleAxis: FlatVector2Axis;

      if (isMullionVertical) {
        const x = measurementPoints[0][1][0];
        axis = [
          [x, points[0][1]],
          [x, points[2][1]],
        ];

        const topY = isPointsOnContour.vertical.top
          ? points[0][1] + panel.topWidth
          : points[0][1] + panel.mullion.width / 2;

        const bottomY = isPointsOnContour.vertical.bottom
          ? points[2][1] - panel.bottomWidth
          : points[2][1] - panel.mullion.width / 2;

        visibleAxis = [
          [x, topY],
          [x, bottomY],
        ];
      } else {
        const y = measurementPoints[0][1][1];
        axis = [
          [points[0][0], y],
          [points[2][0], y],
        ];

        const leftX = isPointsOnContour.horizontal.left
          ? points[0][0] + panel.sideWidth
          : points[0][0] + panel.mullion.width / 2;

        const rightX = isPointsOnContour.horizontal.right
          ? points[2][0] - panel.sideWidth
          : points[2][0] - panel.mullion.width / 2;

        visibleAxis = [
          [leftX, y],
          [rightX, y],
        ];
      }

      setVisibleMullionAxis(visibleAxis);
      setMullionAxis(axis);
    });

    return () => {
      cancelAnimationFrame(frameId);
    };
  }, [
    pointerPosition,
    isSnapToCenter,
    isMullionVertical,
    measurementPoints,
    isPointsOnContour,
    panel,
    points,
  ]);

  useEffect(() => {
    if (!mullionAxis) return;

    const isAxisOnContour = mullionAxis.some(([mullionAxisX, mullionAxisY]) =>
      points.some(([px, py]) =>
        isMullionVertical ? mullionAxisX === px : mullionAxisY === py
      )
    );

    if (isAxisOnContour) {
      dispatch(setMullionErrors([MULLION_CROSS_SAFE_AREA]));
    } else {
      dispatch(setMullionErrors([]));
    }
  }, [mullionAxis]);

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    const pos = event.currentTarget?.getRelativePointerPosition();

    if (!pos) {
      return;
    }

    updatePointerPosition({ x: round(pos.x, 0), y: round(pos.y, 0) });
  };

  useUnmount(() => {
    dispatch(setMultiMeasurementActiveStatus(false));
    dispatch(setMullionErrors([]));
  });

  const updatePointerPosition = (position: PointerPosition | null) => {
    if (!position || inputActive) return;
    setPointerPosition(position);
  };

  const createMullion = () => {
    if (isMullionInvalid) return;
    setIsSnapToCenter(false);
    setMullionAxis(null);
    publish(CREATE_UTC_MULLION, {
      axis: mullionAxis,
      framePoints: points,
    });
    dispatch(resetMullionArea());
  };

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (!isLeftClick(event.evt)) return;
    event.cancelBubble = true;
    createMullion();
  };

  useEffect(() => {
    if (!pointerPosition) {
      setMeasurementPoints([]);
    } else if (isMullionVertical) {
      setMeasurementPoints([
        [
          [points[0][0], center.y],
          [isSnapToCenter ? center.x : pointerPosition.x, center.y],
        ],
        [
          [isSnapToCenter ? center.x : pointerPosition.x, center.y],
          [points[1][0], center.y],
        ],
      ]);
    } else {
      setMeasurementPoints([
        [
          [center.x, points[0][1]],
          [center.x, isSnapToCenter ? center.y : pointerPosition.y],
        ],
        [
          [center.x, isSnapToCenter ? center.y : pointerPosition.y],
          [center.x, points[3][1]],
        ],
      ]);
    }
  }, [pointerPosition, isSnapToCenter, isMullionVertical]);

  const centerSign = () => (
    <Group>
      <Cross
        scale={scale}
        center={[center.x, center.y]}
        lineLength={11 * Math.sqrt(2)}
        degAngle={45}
      />
      <Line
        dash={[15, 15]}
        stroke={ACTIVE_ELEMENT_COLOR}
        strokeWidth={(INITIAL_SCALE * 2) / scale}
        points={
          isMullionVertical
            ? [center.x - 15 / scale, center.y, center.x + 15 / scale, center.y]
            : [center.x, center.y - 15 / scale, center.x, center.y + 15 / scale]
        }
      ></Line>
    </Group>
  );

  const handleActiveMeasurementStatus = (isActive: boolean) => {
    dispatch(setMultiMeasurementActiveStatus(isActive));
  };

  const handleMeasurementEscape = () => {
    dispatch(setMultiMeasurementActiveStatus(false));
  };

  const handleMeasurementChange = (points: FlatVector2Axis[]) => {
    const measurementCenter = [points[0][1][0], points[0][1][1]];
    setIsSnapToCenter(
      center.x === measurementCenter[0] && center.y === measurementCenter[1]
    );

    setMeasurementPoints(points);
    update();
  };
  const handleMeasurementSubmit = () => {
    createMullion();
  };

  return (
    <Group>
      <Line
        onClick={handleClick}
        points={points.flat()}
        closed
        onMouseMove={handleMouseMove}
      ></Line>
      {mullionAxis && (
        <Line
          points={visibleMullionAxis?.flat()}
          dash={[15, 15]}
          stroke={
            isMullionInvalid ? PROHIBITED_ELEMENT_COLOR : ACTIVE_ELEMENT_COLOR
          }
          strokeScaleEnabled={false}
          strokeWidth={COMMON_LINE_STROKE_WIDTH}
        ></Line>
      )}
      {isSnapToCenter && centerSign()}

      {!!measurementPoints?.length && (
        <MultiMeasurementLine
          multiPoints={measurementPoints}
          scale={scale}
          units={units}
          type={MeasurementElementType.MullionPlacement}
          onActiveStatusChange={handleActiveMeasurementStatus}
          onEscape={handleMeasurementEscape}
          onSubmit={handleMeasurementSubmit}
          onChange={handleMeasurementChange}
          horizontal={isMullionVertical}
          customErrorMessage={
            isMullionInvalid ? MULLION_CROSS_SAFE_AREA : undefined
          }
        />
      )}
    </Group>
  );
};

export default UTCMullionGenerator;
