import {
  getColorData,
  getOperationTypeData,
  getOperationTypeIcon,
} from '@/components/WindowCreator/helpers/config';
import { UserBuildingPanel } from '@/models';
import {
  OperationType,
  PanelMaterial,
  PanelMaterials,
  WindowColor,
  WindowConfigurator,
} from '@/models/window-configurator.model';
import IntusTable from '@/shared/elements/IntusTable/IntusTable';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import React from 'react';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import { formatAreaValue } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { round } from 'mathjs';
import InfoIcon from '@/shared/icons/InfoIcon';
import { getInnerFrameHeight, getInnerFrameWidth } from '@/shared/helpers';
import { UnitInnerFrame } from '@/components/UTC/models';
import { getMaterialAdditionalInfo } from '@/models/panelsConfig';

export interface GroupedInnerFrameData extends UnitInnerFrame {
  names: string;
}

const InnerFrameTable = ({
  innerFramesData,
  panelData,
  isSplittedTable,
  includeFrameAndMullions,
}: {
  panelData: UserBuildingPanel;
  innerFramesData: GroupedInnerFrameData[];
  isSplittedTable: boolean;
  includeFrameAndMullions?: boolean;
}) => {
  const keyPanelId = isSplittedTable ? panelData.id : panelData.id + 0.1;
  const configData = useFetchWindowConfigQuery().data!;
  const { getAreaUnit, getUnit, getFormattedValue, isImperialUnits } =
    useFrameProperties();

  const getColumnTitle = (text: string, units?: string) => (
    <div className={`${units ? 'text-end' : 'text-start'}`}>
      {text}{' '}
      {units && (
        <span className={'text-dark-gray-60 text-[8px]'}>({units})</span>
      )}
    </div>
  );

  const getColumnText = (text: string) => (
    <div className={'text-end'}>{text}</div>
  );

  const frameAndMullionsSource = [
    {
      key: `panel-${keyPanelId}__frameAndMullions`,
      panelElement: 'Frame and mullions',
      insideColor: (
        <ColorDisplay
          colorName={panelData.insideColor}
          colors={configData.colors}
        />
      ),
      outsideColor: (
        <ColorDisplay
          colorName={panelData.outsideColor}
          colors={configData.colors}
        />
      ),
      fillerMaterial: (
        <MaterialDisplay
          materialName={PanelMaterials.Aluminium}
          materials={configData.panelMaterials}
        />
      ),
      operationType: (
        <div className="flex flex-col justify-center items-start">-</div>
      ),
      height: getColumnText('-'),
      width: getColumnText('-'),
      area: getColumnText('-'),
    },
  ];

  const innerFramesSource = innerFramesData.map((innerFrame) => ({
    key: `panel-${keyPanelId}-${innerFrame.points.toString()}-colors-innerFrame`,
    panelElement: innerFrame.names,
    insideColor: (
      <ColorDisplay
        colorName={innerFrame.insideColor}
        colors={configData.colors}
      />
    ),
    outsideColor: (
      <ColorDisplay
        colorName={innerFrame.outsideColor}
        colors={configData.colors}
      />
    ),
    fillerMaterial: (
      <MaterialDisplay
        materialName={innerFrame.fillerType}
        materials={configData.panelMaterials}
      />
    ),
    operationType: (
      <div className="flex flex-col justify-center items-start">
        {innerFrame.operableWindowType ? (
          <WindowOperationTypeDisplay
            operationTypeId={innerFrame.operableWindowType}
            configData={configData}
          />
        ) : (
          '-'
        )}
      </div>
    ),
    height: getColumnText(
      getFormattedValue([
        getInnerFrameHeight(innerFrame, panelData, configData.panel).toString(),
      ])
    ),
    width: getColumnText(
      getFormattedValue([
        getInnerFrameWidth(innerFrame, panelData, configData.panel).toString(),
      ])
    ),
    area: getColumnText(
      formatAreaValue(
        round(
          convertMillimetersToMeters(
            getInnerFrameHeight(innerFrame, panelData, configData.panel)
          ) *
            convertMillimetersToMeters(
              getInnerFrameWidth(innerFrame, panelData, configData.panel)
            ),
          2
        ),
        isImperialUnits
      )
    ),
  }));

  const dataSource = [
    includeFrameAndMullions ? frameAndMullionsSource : [],
    innerFramesSource,
  ].flat();

  const columns = [
    {
      key: `panel-element-column-${keyPanelId}`,
      title: '',
      dataIndex: 'panelElement',
      rowScope: 'row' as const, //   For some reason without it the engine throws an error Type 'string' is not assignable to type 'RowScopeType | undefined'
      width: '65px',
    },
    {
      key: `panel-element-column-${keyPanelId}__filler-material`,
      title: getColumnTitle('Filler / Material'),
      dataIndex: 'fillerMaterial',
      width: '16.5%',
    },
    {
      key: `panel-element-column-${keyPanelId}__operationType`,
      title: getColumnTitle('Operation type'),
      dataIndex: 'operationType',
    },
    {
      key: `panel-element-column-${keyPanelId}__insideColor`,
      title: getColumnTitle('Inside color'),
      dataIndex: 'insideColor',
      width: '14.2%',
    },
    {
      key: `panel-element-column-${keyPanelId}__outsideColor`,
      title: getColumnTitle('Outside color'),
      dataIndex: 'outsideColor',
      width: '14.2%',
    },
    {
      key: `panel-element-column-${keyPanelId}__height`,
      title: getColumnTitle('Height', getUnit()),
      dataIndex: 'height',
      width: '9%',
    },

    {
      key: `panel-element-column-${keyPanelId}__width`,
      title: getColumnTitle('Width', getUnit()),
      dataIndex: 'width',
      width: '9%',
    },
    {
      key: `panel-element-column-${keyPanelId}__area`,
      title: getColumnTitle('Area', getAreaUnit()),
      dataIndex: 'area',
      width: '9%',
    },
  ];
  if (dataSource.length === 0) return null;

  return (
    <div className="mb-4">
      {!isSplittedTable && (
        <div className="flex justify-between items-center mb-2">
          <div className="uppercase font-medium text-[10px] leading-4">
            UCW Unit structure
          </div>
          <div className="flex leading-4">
            <span className="mr-2">
              <InfoIcon />
            </span>
            <span className="text-[8px] font-normal">
              Aluminium: measurements for visible area. Enamel glass, Glass,
              Operable window: measurements for IGU.
            </span>
          </div>
        </div>
      )}
      <IntusTable
        dataSource={dataSource}
        columns={columns}
        bordered={false}
        pagination={false}
      />
    </div>
  );
};

export default InnerFrameTable;

export const ColorDisplay = ({
  colorName,
  colors,
}: {
  colorName: string;
  colors: WindowColor[];
}) => {
  return (
    <div className="flex items-center h-full w-full gap-2">
      {colorName ? (
        <div
          className="w-3 h-3 leading-4 box-border border-solid border border-light-gray-20 rounded-full"
          style={{
            backgroundColor: getColorData(colorName, colors)?.hex,
          }}
        />
      ) : (
        <div>-</div>
      )}
      <div className="flex flex-col text-[10px]">
        <div className="font-light leading-4">{colorName}</div>
        <div className="font-light text-light-gray-60 leading-[14px]">
          {getColorData(colorName, colors)?.description.split(' - ')[1]}
        </div>
      </div>
    </div>
  );
};

const MaterialDisplay = ({
  materialName,
  materials,
}: {
  materialName: string;
  materials: PanelMaterial[];
}) => {
  const materialDescription = getMaterialAdditionalInfo(
    materialName,
    materials
  );
  return (
    <div className="flex flex-col font-light text-[10px]">
      <div className="leading-4">{materialName}</div>
      {materialDescription && (
        <div className="text-dark-gray-60 leading-[14px]">
          {materialDescription}
        </div>
      )}
    </div>
  );
};

const WindowOperationTypeDisplay = ({
  operationTypeId,
  configData,
}: {
  operationTypeId: OperationType;
  configData: WindowConfigurator;
}) => {
  return (
    <div className="flex gap-2 h-[30px] items-center justify-center text-[10px]">
      {getOperationTypeIcon(operationTypeId, '20')}
      <div className="flex flex-col">
        <div>
          {
            getOperationTypeData(operationTypeId, configData.operationTypes)
              ?.description
          }
        </div>

        <div className="leading-[14px] text-dark-gray-60">
          {
            getOperationTypeData(operationTypeId, configData.operationTypes)
              ?.swing
          }
        </div>
      </div>
    </div>
  );
};
