import React from 'react';
import { round } from 'mathjs';

import { SvgMullions, SvgFrames } from '../';

import { WindowConfigurator } from '@/models/window-configurator.model';
import { UserBuildingPanel } from '@/models';
import { Background } from '../../models';
import { getHexByName } from '@/components/WindowCreator/helpers/config';
import { FlatVector2Axis } from '@/components/WindowCreator/models';

interface SvgCornerUnitProps extends UserBuildingPanel {
  config: WindowConfigurator;
  offset: number;
  isNumbering: boolean;
}

const SvgCornerUnit: React.FC<SvgCornerUnitProps> = ({
  config,
  isNumbering,
  ...props
}) => {
  const { mullions, innerFrames, points, cornerSide, offset } = props;

  const glazingBeadsColor = {
    hex: getHexByName(config.colors, props.outsideColor) ?? Background.DEFAULT,
    hasGlare: false,
  };

  const shiftMullions = mullions?.map((mullion) => {
    return {
      ...mullion,
      points: mullion.points.map(([x, y]) => [
        round(x + offset, 2),
        y,
      ]) as FlatVector2Axis,
    };
  });

  return (
    <>
      <SvgMullions
        config={config.panel}
        mullions={shiftMullions ?? []}
        color={glazingBeadsColor}
        unitPoints={points}
      />
      <SvgFrames
        config={config}
        isNumbering={isNumbering}
        frames={innerFrames ?? []}
        unit={props}
        offset={offset}
        isSideCorner={true}
        isCorner={!!cornerSide}
      />
    </>
  );
};

export default SvgCornerUnit;
