import React, { useEffect, useMemo, useState } from 'react';

import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { subscribe, unsubscribe } from '@/core/events';
import { MAKE_SCREENSHOT } from '@/core/event-names';
import { makeScreenshotDelay } from '@/shared/helpers/camera';
import { sleep } from '@/shared/helpers/sleep';
import ZeroStateFrame from './frames/ZeroStateFrame';
import EnvironmentFrame from './frames/EnvironmentFrame';
import SurroundingBuildingFrame from './frames/SurroundingBuildingFrame';
import SurroundingBuildingsFrame from './frames/SurroundingBuildingsFrame';
import ConstructionSiteFrame from './frames/ConstructionSiteFrame';
import {
  NodeType,
  ProjectStructure,
  SelectedNode,
  UnitSystemTypes,
} from '@/models';
import { Position } from '@turf/turf';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useAppSelector } from '@/store/hooks';
import BuildingFrame from './frames/BuildingFrame';
import WallFrame from './frames/WallFrame';
import StoreyFrame from './frames/StoreyFrame';
import { formatAreaValue } from './propertyPanel-helpers';
import BlockFrame from './frames/BlockFrame';
import { getIsFacadeDesignerModeEnabled } from '@/store/slices/canvasModesSlice';
import PanelizationSettings from './frames/PanelizationSettings';
import {
  getSelectedPlacedPanels,
  getSelectedPlacedWindows,
  getSelectedWindowFromLibrary,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import WindowFrame from './frames/WindowFrame';
import { useFetchWindowsQuery } from '@/store/apis/windowApi';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import PanelFrame from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/frames/PanelFrame';
import { compact } from 'lodash';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { PanelPlacementDataForFD } from '@/components/FacadeDesigner/models';

const PropertyPanel = ({
  selectedNodes,
}: {
  selectedNodes: SelectedNode[];
}) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const isFacadeModeEnabled = useAppSelector(getIsFacadeDesignerModeEnabled);
  const { id } = useParams();
  const windowsInLibrary = useFetchWindowsQuery(id!).data!;
  const selectedPlacedWindows = useAppSelector(getSelectedPlacedWindows);
  const selectedPlacedPanels = useAppSelector(getSelectedPlacedPanels);
  const selectedPlacedWindowsData = selectedPlacedWindows.map(
    (window) => windowsInLibrary.find((w) => w.id === window.windowId)!
  );
  const selectedWindowFromLibrary = useAppSelector(
    getSelectedWindowFromLibrary
  );

  const nodes = Object.values(selectedNodes);
  const areSelectedNodesTypesAreTheSame =
    nodes.length > 0 && nodes.every((node) => node.type === nodes[0].type);

  const unitSystem = useAppSelector(getProjectUnits(id!));

  const isImperial = unitSystem === UnitSystemTypes.Imperial;

  const projectData = useFetchProjectQuery(id!).data!;

  const selectedNode = Object.values(selectedNodes)[0];
  const selectedType = selectedNode?.type;

  const showConstructionPartsFrame =
    areSelectedNodesTypesAreTheSame && !isFacadeModeEnabled;
  const showWindowFrame =
    !showConstructionPartsFrame &&
    (!!selectedWindowFromLibrary || selectedPlacedWindowsData.length > 0);
  const showFacadeDesignerFrame =
    !showConstructionPartsFrame && !showWindowFrame && isFacadeModeEnabled;

  const showZeroStateFrame =
    !showConstructionPartsFrame && !showWindowFrame && !isFacadeModeEnabled;

  useEffect(() => {
    subscribe(MAKE_SCREENSHOT, toggleAnimation);
    return () => {
      unsubscribe(MAKE_SCREENSHOT, toggleAnimation);
    };
  }, []);

  const toggleAnimation = async () => {
    setIsAnimating(true);
    await sleep(makeScreenshotDelay);
    setIsAnimating(false);
  };

  return (
    <div
      className={`absolute right-0 top-0 h-full z-20 flex flex-col overflow-x-hidden overflow-y-hidden max-h-full w-[220px] border bg-white border-light-gray-20 border-solid border-t-0 duration-500 ${isAnimating ? 'opacity-0' : 'opacity-100'}`}
    >
      {showConstructionPartsFrame && (
        <ConstructionPartsFrame
          selectedType={selectedType}
          isImperial={isImperial}
          selectedNodes={selectedNodes}
          projectData={projectData}
        />
      )}
      {showWindowFrame && (
        <WindowFrame
          selectedWindows={
            selectedWindowFromLibrary
              ? [selectedWindowFromLibrary]
              : selectedPlacedWindowsData
          }
        />
      )}
      {showFacadeDesignerFrame && (
        <FrameForNodesFromFacadeDesigner
          selectedPlacedPanels={Object.values(selectedPlacedPanels)}
          isLocked={projectData.locked}
          wallsGuids={selectedNodes
            .filter((node) => node.type === NodeType.Wall)
            .map((wall) => wall.guid)}
          isImperial={isImperial}
          areSettingsDisabled={
            !areSelectedNodesTypesAreTheSame ||
            selectedType !== NodeType.Wall ||
            projectData.locked
          }
        />
      )}
      {showZeroStateFrame && <ZeroStateFrame />}
    </div>
  );
};

export default PropertyPanel;

const FrameForNodesFromFacadeDesigner: React.FC<{
  selectedPlacedPanels: PanelPlacementDataForFD[];
  isImperial: boolean;
  isLocked: boolean;
  wallsGuids: string[];
  areSettingsDisabled: boolean;
}> = ({
  selectedPlacedPanels,
  isImperial,
  isLocked,
  wallsGuids,
  areSettingsDisabled,
}) => {
  const { id } = useParams();

  const { findDataForWall } = useFindNodeData();

  const panelsInfo = useGetAllPanelsQuery(id!).data;

  const selectedPanelsData = useMemo(() => {
    return compact(
      selectedPlacedPanels.map((panel) => {
        const wallPanelData = findDataForWall(panel.wallGUID)?.wallPanels.find(
          (wallPanel) => wallPanel.guid === panel.guid
        );
        const data = panelsInfo?.find(
          (panelInfo) => panelInfo?.id === wallPanelData?.panelId
        );
        if (!data) return null;

        return {
          ...data,
          guid: panel.guid,
        };
      })
    );
  }, [selectedPlacedPanels, panelsInfo]);

  return (
    <>
      {selectedPanelsData.length > 0 && (
        <PanelFrame
          selectedPanels={selectedPanelsData}
          isImperialUnits={isImperial}
          areSettingsDisabled={isLocked}
        />
      )}

      {selectedPlacedPanels.length === 0 && (
        <PanelizationSettings
          selectedWalls={wallsGuids}
          areSettingsDisabled={areSettingsDisabled}
        />
      )}
    </>
  );
};

const ConstructionPartsFrame: React.FC<{
  selectedNodes: SelectedNode[];
  selectedType: NodeType;
  projectData: ProjectStructure;
  isImperial: boolean;
}> = ({ selectedType, projectData, selectedNodes, isImperial }) => {
  const selectedNodesGUID = selectedNodes.map((node) => node.guid);
  const constructionSite = projectData.environment.constructionSite;

  const { calculateConstructionSiteArea, getUsedConstructionSiteArea } =
    useFrameProperties();

  const constructionSiteArea = calculateConstructionSiteArea(
    constructionSite?.points as Position[]
  );

  const usedConstructionSiteArea = getUsedConstructionSiteArea(projectData);

  switch (selectedType) {
    case NodeType.Environment:
      return (
        <EnvironmentFrame
          siteArea={formatAreaValue(constructionSiteArea, isImperial)}
          siteUse={{
            areaUsed: formatAreaValue(
              usedConstructionSiteArea.occupiedArea,
              isImperial
            ),
            usageRate: usedConstructionSiteArea.usageRate,
          }}
        />
      );
    case NodeType.SurroundingBuilding:
      return <SurroundingBuildingFrame envBuildingsGUID={selectedNodesGUID} />;
    case NodeType.SurroundingBuildings:
      return <SurroundingBuildingsFrame />;
    case NodeType.ConstructionSite:
      return (
        <ConstructionSiteFrame
          area={formatAreaValue(constructionSiteArea, isImperial)}
          siteUse={{
            siteArea: formatAreaValue(
              usedConstructionSiteArea.occupiedArea,
              isImperial
            ),
            usageRate: usedConstructionSiteArea.usageRate,
          }}
        />
      );
    case NodeType.Building:
      return (
        <BuildingFrame
          buildingsGUID={selectedNodesGUID}
          isProjectLocked={projectData.locked}
        />
      );
    case NodeType.Block:
      return (
        <BlockFrame
          isProjectLocked={projectData.locked}
          blocksGUIDs={selectedNodesGUID}
        />
      );
    case NodeType.Storey:
      return (
        <StoreyFrame
          storeysGUID={selectedNodesGUID}
          isProjectLocked={projectData.locked}
        />
      );
    case NodeType.Wall:
      return <WallFrame wallsGUID={selectedNodesGUID} />;
    default:
      return <ZeroStateFrame />;
  }
};
