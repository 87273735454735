export const BUILDING_WIDTH_IN_METERS = 20;
export const FLOORS_INITIAL_COUNT = 2;
export const INITIAL_FLOOR_HEIGHT_IN_MILLIMETERS = 3048;

export enum RENDER_ORDERS {
  DOT = 10,
  DOT_BACKGROUND = 15,
  WINDOW_MODEL = 16,
  EDIT_NODE_LINE = 20,
}

export const POLYGON_OFFSET_FACTOR_LEVELS = {
  LOW: -1,
  MEDIUM: -3,
  HIGH: -5,
};

export const KONVA_UTC_DEFAULT_LEVEL_WIDTH = 2700;
export const KONVA_WDC_DEFAULT_LEVEL_WIDTH = 1400;

export const UTC_CREATOR_STAGE_ID = 'konva__utc-creator__stage';
export const WINDOW_CREATOR_STAGE_ID = 'konva__window-creator__stage';
export const WINDOW_CREATOR_VIEW = '2d-konva__window-creator__window-view';
export const FACADE_VIEW = '2d-konva__facade-view';
export const UNIT_CREATOR_VIEW = '2d-konva__unit-creator-view';
export const LEFT_PANEL_DEFAULT_WIDTH = 210;

export const MULTIPLE_VALUES_SYMBOL = '-';
export const COMMON_LINE_STROKE_WIDTH = 1;

export const PDF_WIDTH = 914;
export const PDF_HEIGHT = 610.01;
export const REPORT_FACADE_WIDTH = 320;
export const REPORT_FACADE_HEIGHT = 190;
export const REPORT_UNIT_WIDTH = 140;
export const REPORT_UNIT_HEIGHT = 140;
export const REPORT_SIDEBAR_WIDTH = 215;
export const COVER_WIDTH = 2480;
export const COVER_HEIGHT = 2268;

export const REPORT_BACKGROUND_COLOR = '#ECEEEE';

export enum UI_INDEX {
  TOOLTIP = 50,
  OVERLAY_TOOLTIP = 1500,
}
