import React from 'react';

import { Trap } from '../../shared';

import { Color } from '../../models';
import { FlatVector2 } from '@/models';
import { LINE_WIDTH } from '../../constants';

interface SvgBordersProps {
  borders: FlatVector2[][];
  color: Color;
}

const SvgBorders: React.FC<SvgBordersProps> = ({ borders, color }) => {
  return (
    <>
      {borders.map((points, index) => {
        return (
          <Trap
            points={points}
            key={index}
            fill={{ ...color, hasGlare: false }}
            stroke={color.hex}
            strokeWidth={LINE_WIDTH}
          />
        );
      })}
    </>
  );
};

export default SvgBorders;
