import React, { ReactNode, useEffect, useState } from 'react';

import IntusPopover from '../Popover/Popover';
import IntusSearch from '../Search/Search';
import Form from 'antd/es/form';
import IntusTooltip from '../Tooltip/Tooltip';
import { TooltipPlacement } from 'antd/es/tooltip';

interface Item {
  id: string;
  name: string;
  description?: string | null;
  preview: ReactNode;
}

interface PickerPopoverProps {
  items: Item[];
  initialValue: string;
  disabled: boolean;
  onChange?: (id: string) => void;
  searchable?: boolean;
  contentHeight?: number;
  contentWidth?: number;
  placement?: TooltipPlacement;
}

const PickerPopover: React.FC<PickerPopoverProps> = ({
  items,
  initialValue,
  onChange,
  searchable = true,
  disabled,
  contentHeight,
  contentWidth,
  placement = 'left',
}) => {
  const [availableItems, setAvailableItems] = useState(items);
  const [selectedItemId, setSelectedItemId] = useState(initialValue);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [form] = Form.useForm();

  const handleSearch = () => {
    const filteredItems = items.filter((item) => {
      return item.name
        .toLowerCase()
        .includes(form.getFieldValue('itemName').toLowerCase());
    });
    setAvailableItems(filteredItems);
  };

  const getItemPropertyById = (id: string, property: keyof Item) =>
    items.find((item) => item.id === id)?.[property];

  const resetToDefaultItems = () => {
    form.resetFields();
    setAvailableItems(items);
  };

  const handleItemClick = (id: string) => {
    setIsPopoverOpen(false);
    setSelectedItemId(id);
    resetToDefaultItems();
    onChange && onChange(id);
  };

  const pickerTitle = (
    <div className={`w-[${contentWidth}px]`}>
      <Form form={form} className={`p-2 `}>
        <Form.Item noStyle name="itemName">
          <IntusSearch onChange={handleSearch} autoComplete="off" />
        </Form.Item>
      </Form>
    </div>
  );

  const pickerContent = (
    <div
      className={`box-border overflow-auto  flex flex-col gap-1 ${availableItems.length ? 'py-1' : 'px-2.5 py-2'}`}
      style={{
        width: contentWidth,
        maxHeight: contentHeight,
      }}
    >
      {availableItems.length ? (
        availableItems
          .filter((item) => item.id !== selectedItemId)
          .map((item) => (
            <IntusTooltip
              title={item.name}
              key={item.name}
              placement="left"
              mouseEnterDelay={0.5}
            >
              <div
                onClick={() => handleItemClick(item.id)}
                className={`px-2 py-1 flex gap-2 box-border items-center hover:bg-light-gray-10 cursor-pointer`}
              >
                <div className="shrink-0">{item.preview}</div>
                <div className="flex flex-col justify-center min-w-0">
                  <div className="font-normal text-xs leading-5 truncate ...">
                    {item.name}
                  </div>
                  {item.description && (
                    <div className="text-[11px] font-light leading-5 truncate">
                      {item.description}
                    </div>
                  )}
                </div>
              </div>
            </IntusTooltip>
          ))
      ) : (
        <div className="box-border text-center font-light text-[11px] leading-4">
          Nothing was found for “{form.getFieldValue('itemName')}” query, please
          try again
        </div>
      )}
    </div>
  );

  const handlePopoverChange = (isOpen: boolean) => {
    if (disabled) return;
    setIsPopoverOpen(isOpen);
    if (!isOpen) resetToDefaultItems();
  };

  useEffect(() => {
    setSelectedItemId(initialValue);
  }, [initialValue]);

  return (
    <IntusPopover
      onOpenChange={handlePopoverChange}
      open={!disabled && isPopoverOpen}
      title={searchable ? pickerTitle : undefined}
      content={pickerContent}
      trigger="click"
      placement={placement}
    >
      <IntusTooltip
        placement="left"
        title={getItemPropertyById(selectedItemId, 'name')}
        mouseEnterDelay={0.5}
      >
        <div
          className={`${isPopoverOpen && !disabled ? 'bg-light-gray-10' : 'bg-white'} ${disabled ? 'opacity-50' : 'opacity-100'} px-3 py-1 flex items-center gap-2 ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`}
        >
          <div className="shrink-0">
            {getItemPropertyById(selectedItemId, 'preview')}
          </div>
          <div
            className={`flex flex-col justify-center min-w-0 ${getItemPropertyById(selectedItemId, 'description') !== undefined ? 'min-h-10' : ''}`}
          >
            <div className="text-xs font-normal leading-5 truncate ...">
              {getItemPropertyById(selectedItemId, 'name')}
            </div>
            {getItemPropertyById(selectedItemId, 'description') && (
              <div className="text-[11px] font-light leading-5">
                {getItemPropertyById(selectedItemId, 'description')}
              </div>
            )}
          </div>
        </div>
      </IntusTooltip>
    </IntusPopover>
  );
};

export default PickerPopover;
