import React from 'react';
import { useParams } from 'react-router';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import {
  useFetchProjectQuery,
  useGetAllPanelsQuery,
} from '@/store/apis/projectsApi';
import {
  useFetchWindowConfigQuery,
  useFetchWindowsQuery,
} from '@/store/apis/windowApi';
import useBuildingMetrics from '@/shared/hooks/useBuildingMetrics';
import { calculateCommonArea } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import { OperationType } from '@/models/window-configurator.model';
import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { getInnerFrameHeight, getInnerFrameWidth } from '@/shared/helpers';

const PanelProperties = () => {
  const { id } = useParams();
  const { data: panelsData } = useGetAllPanelsQuery(id!);
  const { panel: panelConfig } = useFetchWindowConfigQuery().data!;

  const { getFacadesAreaMetricForBlocks, getAreaUnit, isImperialUnits } =
    useFrameProperties();
  const { countPanelsInBuildings, countKindOfWindows, countPanelizedAreaData } =
    useBuildingMetrics();

  const panelTypeCounts = countPanelsInBuildings(panelsData ?? []);

  const projectData = useFetchProjectQuery(id!).data!;
  const windowsData = useFetchWindowsQuery(id!).data!;

  const windowsTypeCounts = countKindOfWindows(windowsData);
  const panelizedAreaData = countPanelizedAreaData(panelsData!);

  const windowsTotalArea = () =>
    calculateCommonArea({
      nodes: panelsData!,
      isImperialUnits,
      calculateAreaFunction: (panel) =>
        panel.innerFrames.reduce((sum, panelFrame) => {
          const windowArea =
            panelFrame.operableWindowType &&
            panelFrame.operableWindowType !== OperationType.Fixed
              ? convertMillimetersToMeters(
                  getInnerFrameWidth(panelFrame, panel, panelConfig)
                ) *
                convertMillimetersToMeters(
                  getInnerFrameHeight(panelFrame, panel, panelConfig)
                )
              : 0;
          return sum + windowArea;
        }, 0),
    });

  return (
    <div className="h-full w-full flex relative flex-col ">
      <div
        className="px-6 py-9 box-border grid grid-cols-2 grid-rows-2 relative gap-3  flex-1 overflow-hidden"
        style={{
          backgroundColor: '#ECEEEE',
        }}
      >
        <div className="absolute bottom-0 left-0 bg-[#F9F9F9] px-2 py-1 font-light leading-4 text-[10px] text-dark-gray-100">
          Areas marked in red are out of scope and not included into quote
        </div>
      </div>
      <div className="flex mt-auto text-[10px] px-5 leading-4 gap-5">
        <div className="flex-1 py-4 flex flex-col gap-1">
          <div className="uppercase text-dark-gray-100 font-medium">
            UCW UNIT METRICS
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">UCW Unit count</div>
              <div>{panelTypeCounts.panelsCount}</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Straight UCW Units</div>
              <div>{panelTypeCounts.straight}</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Corner UCW Units</div>
              <div>{panelTypeCounts.corner}</div>
            </div>
          </div>
        </div>
        <div className="flex-1 py-4 flex flex-col gap-1">
          <div className="uppercase text-dark-gray-100 font-medium">
            UCW UNIT area metrics
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Facades area</div>
              <div className="flex gap-1">
                <div>
                  {getFacadesAreaMetricForBlocks(
                    projectData.buildings![0].blocks
                  )}
                </div>
                <div className="font-light text-dark-gray-60 w-5">
                  {getAreaUnit()}
                </div>
              </div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">UCW Unit area</div>
              <div className="flex gap-1">
                <div className="flex gap-1 whitespace-nowrap">
                  {panelizedAreaData.panelizedArea}
                  <div className="font-light text-dark-gray-60 w-5">
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  {panelizedAreaData.panelizedAreaUsage}
                  <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
            <div className="flex justify-between gap-2">
              <div className="font-light whitespace-nowrap">
                UCW Unit-free area
              </div>
              <div className="flex gap-1">
                <div className="inline-flex gap-1 whitespace-nowrap">
                  {panelizedAreaData.nonPanelizedArea}
                  <div className="font-light text-dark-gray-60 w-5">
                    {getAreaUnit()}
                  </div>
                </div>
                <div className="flex gap-1">
                  {panelizedAreaData.nonPanelizedAreaUsage}{' '}
                  <div className="font-light text-dark-gray-60 w-5">%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-px h-full bg-light-gray-20" />
        <div className="flex-1 py-4 flex flex-col gap-1">
          <div className="uppercase text-dark-gray-100 font-medium">
            Operable Window metrics
          </div>
          <div className="flex flex-col gap-0.5">
            <div className="flex justify-between">
              <div className="font-light">Count</div>
              <div>{windowsTypeCounts.operable}</div>
            </div>
            <div className="flex justify-between">
              <div className="font-light">Total area</div>
              <div>
                {windowsTotalArea()} {getAreaUnit()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PanelProperties;
