import React, { useEffect, useMemo, useState } from 'react';
import {
  CanvasActionsModes,
  NodeType,
  PanelPlacementData,
  UnitSystemTypes,
  UserBuildingPanel,
  UTCModes,
} from '@/models';
import UnitCard from '@/shared/elements/UnitCard/UnitCard';
import { IntusIconButton, IntusTooltip } from '@/shared/elements';
import { VectorDownIcon } from '@/shared/icons';
import {
  setInitialData,
  setSelectedPanelGuidForEditing,
} from '@/store/slices/windowsReducer/UTCSlice';
import { View } from '@/components/WindowCreator/models';
import { getCanvasMode, setUTCMode } from '@/store/slices/canvasModesSlice';
import { useAppDispatch, useAppSelector } from '@/store/hooks';

import { UpdatedUserBuildingPanelData } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/frames/PanelFrame';
import StraightUnitEditIcon from '@/shared/icons/StraightUnitEditIcon';
import InnerCornerEditIcon from '@/shared/icons/InnerCornerEditIcon';
import OuterCornerEditIcon from '@/shared/icons/OuterCornerEditIcon';
import { isUnitsAreSimilar, sortByDates } from '@/shared/helpers';
import { TooltipPlacement } from 'antd/es/tooltip';
import {
  getModifiedWalls,
  updateSelectedPanelId,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { UI_INDEX } from '@/shared/constants';

interface UnitHeaderProps {
  units?: UserBuildingPanel[];
  selectedUnits: UpdatedUserBuildingPanelData[];
  unitSystem: UnitSystemTypes;
  isUnitsLibraryOpened: boolean;
  setIsUnitsLibraryOpened: (isOpen: boolean) => void;
  isProjectLocked: boolean;
}

const UnitHeader: React.FC<UnitHeaderProps> = ({
  units,
  selectedUnits,
  unitSystem,
  isUnitsLibraryOpened,
  setIsUnitsLibraryOpened,
  isProjectLocked,
}) => {
  const dispatch = useAppDispatch();

  const { findDataForWallInfoViaPanel } = useFindNodeData();
  const { updateBuildingPlacements } = useUpdateUserBuildingData();

  const modifiedWallsInFacadeDesigner = useAppSelector(getModifiedWalls);
  const isDesignerMode =
    useAppSelector(getCanvasMode) === CanvasActionsModes.facadeDesigner;

  //extra flag to not animate for the first appearance
  const [isWasOpened, setIsWasOpened] = useState(false);

  const isAllPanelsSameType = selectedUnits.every(
    (panel) => panel.id === selectedUnits[0].id
  );

  const getPanelFrameName = () => {
    if (isAllPanelsSameType) {
      return selectedUnits[0].name;
    }

    if (selectedUnits.length > 1) {
      return `UCW Units (${selectedUnits.length})`;
    }

    return 'UCW Unit';
  };
  const getPanelIcon = () => {
    if (!isAllPanelsSameType) return null;
    if (selectedUnits[0].cornerSide) {
      return selectedUnits[0].isInnerCorner ? (
        <InnerCornerEditIcon />
      ) : (
        <OuterCornerEditIcon />
      );
    }

    return <StraightUnitEditIcon />;
  };

  const getTooltipTitle = () => {
    if (!isAllPanelsSameType) return null;
    if (selectedUnits[0].cornerSide) {
      return selectedUnits[0].isInnerCorner
        ? 'Edit inner UCW corner unit'
        : 'Edit outer UCW corner unit';
    }
    return 'Edit UCW straight unit';
  };

  const handleUTCEditorOpen = (unit: UserBuildingPanel) => {
    dispatch(
      setInitialData({
        id: unit.id,
        points: unit.points,
        name: unit.name,
        innerFrames: unit.innerFrames,
        view: View.Outside,
        mullions: unit.mullions,
        units: unitSystem,
        insideColor: unit.insideColor,
        outsideColor: unit.outsideColor,
      })
    );
    if (selectedUnits[0].id === unit.id) {
      dispatch(setSelectedPanelGuidForEditing(selectedUnits[0].guid));
    }

    if (unit.isInnerCorner) {
      dispatch(setUTCMode(UTCModes.InnerCorner));
    } else if (unit.cornerSide) {
      dispatch(setUTCMode(UTCModes.OuterCorner));
    } else dispatch(setUTCMode(UTCModes.Straight));
  };

  const handleOpenUnitsLibrary = () => {
    setIsWasOpened(true);
    setIsUnitsLibraryOpened(!isUnitsLibraryOpened);
  };

  const disabled = isDesignerMode && !modifiedWallsInFacadeDesigner.length;

  const similarUnits = useMemo(() => {
    const selectedUnit = selectedUnits[0];
    if (!units) return [];
    const filteredUnits = units?.filter((unit) =>
      isUnitsAreSimilar(selectedUnit, unit, units)
    );
    return filteredUnits?.sort((a, b) => sortByDates(b.updatedAt, a.updatedAt));
  }, [units, selectedUnits]);

  const currentUnit = useMemo(() => {
    return units?.find((unit) => unit.id === selectedUnits[0].id);
  }, [units, selectedUnits]);

  const tooltipPlacementData: {
    placement: TooltipPlacement;
    title: string;
  } = {
    placement: 'left',
    title: 'Click to switch unit',
  };

  const handleApplyUnit = (unit: UserBuildingPanel) => {
    if (isAllPanelsSameType) {
      let buildingGUID = '';
      const placementData: {
        wallGUID: string;
        wallPanels: PanelPlacementData[];
      }[] = selectedUnits.map((selectedUnit) => {
        const wall = findDataForWallInfoViaPanel({
          panelGuid: selectedUnit.guid,
        })!;

        buildingGUID = wall.getParentNode(NodeType.Building)!.guid;

        return {
          wallGUID: wall.guid,
          wallPanels: wall.wallPanels.map((wallPanel) =>
            selectedUnits.some((su) => su.guid === wallPanel.guid)
              ? { ...wallPanel, panelId: unit.id }
              : wallPanel
          ),
        };
      });

      selectedUnits.forEach((selectedUnit) =>
        dispatch(
          updateSelectedPanelId({
            panelGuid: selectedUnit.guid,
            panelId: unit.id,
          })
        )
      );

      updateBuildingPlacements({
        placementData,
        triggerBEUpdate: true,
        buildingGUID,
      });
    }
  };

  useEffect(() => {
    selectedUnits.length > 1 &&
      !isAllPanelsSameType &&
      isUnitsLibraryOpened &&
      setIsUnitsLibraryOpened(false);
  }, [selectedUnits, isUnitsLibraryOpened]);

  return (
    <>
      <div
        className={
          'flex justify-between w-full bg-white font-medium text-xs items-center h-8'
        }
      >
        <div className="flex flex-1 justify-between px-3">
          <div className={'flex gap-2 leading-8 group'}>
            <span
              className={`text-[#343335] max-w-[130px]  ${isAllPanelsSameType ? 'group-hover:text-black' : ''} truncate`}
            >
              {getPanelFrameName()}
            </span>
            {isAllPanelsSameType && !disabled && (
              <span
                className={'flex items-center'}
                onClick={handleOpenUnitsLibrary}
              >
                <VectorDownIcon
                  className={`transition-all stroke-black-40 group-hover:stroke-black ${isUnitsLibraryOpened ? 'rotate-180' : ''}`}
                />
              </span>
            )}
          </div>

          <IntusTooltip
            title={!isProjectLocked ? getTooltipTitle() : undefined}
            placement="left"
            styles={{
              root: {
                marginLeft: '4px',
              },
            }}
            zIndex={UI_INDEX.TOOLTIP}
          >
            <div className="flex items-center">
              <IntusIconButton
                simplified
                icon={getPanelIcon()}
                onClick={() => handleUTCEditorOpen(selectedUnits[0])}
                disabled={disabled || isProjectLocked}
              />
            </div>
          </IntusTooltip>
        </div>
      </div>
      <div className=" bg-white font-medium text-xs width-[210px] border-box overflow-y-scroll">
        <div
          className={`w-full h-0 transition-all ${isUnitsLibraryOpened ? 'animate-slideDownUnitLibrary' : isWasOpened ? 'animate-slideUpUnitLibrary' : ''}`}
        >
          {currentUnit && (
            <UnitCard
              unitData={currentUnit}
              isSelected
              popoverPlacement="leftTop"
              handleUTEOpen={() => handleUTCEditorOpen(currentUnit)}
              disabled={disabled}
              isProjectLocked={isProjectLocked}
              isDesignerMode={isDesignerMode}
            />
          )}
          {isAllPanelsSameType &&
            similarUnits?.map((unit) => (
              <UnitCard
                key={unit.id}
                unitData={unit}
                isDesignerMode={isDesignerMode}
                tooltipPlacementParams={tooltipPlacementData}
                popoverPlacement="leftTop"
                handleUTEOpen={() => handleUTCEditorOpen(unit)}
                isProjectLocked={isProjectLocked}
                disabled={disabled}
                handleSelectUnit={() => handleApplyUnit(unit)}
              />
            ))}
        </div>
      </div>
    </>
  );
};

export default UnitHeader;
