import {
  FlatVector2Axis,
  MeasurementElementType,
} from '@/components/WindowCreator/models/konva-model';
import { isHorizontal } from '@/components/WindowCreator/helpers/direction.helpers';
import { WindowConfigurator } from '@/models/window-configurator.model';

// given in millimeters
const MIN_HEIGHT_FROM_FLOOR = 0;
const MAX_HEIGHT_FROM_FLOOR = 2000;

export const getMeasurementLimits = (
  points: FlatVector2Axis,
  type: MeasurementElementType,
  config: WindowConfigurator
): { min: number; max: number } => {
  switch (type) {
    case MeasurementElementType.Offset:
      return { min: MIN_HEIGHT_FROM_FLOOR, max: MAX_HEIGHT_FROM_FLOOR };

    case MeasurementElementType.Frame:
      if (isHorizontal(points)) {
        return {
          min: config.limitations.outerFrame.minWidth,
          max: config.limitations.outerFrame.maxWidth,
        };
      } else
        return {
          min: config.limitations.outerFrame.minHeight,
          max: config.limitations.outerFrame.maxHeight,
        };

    default:
      return { min: 0, max: Infinity };
  }
};
