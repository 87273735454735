import React from 'react';
import {
  calculateHeightFromFloor,
  calculateWindowDimensions,
} from '@/components/WindowCreator/helpers/windowMetric';
import { SavedWindow } from '@/components/WindowCreator/models';
import { UnitSystemTypes } from '@/models';
import MetricsHeader from '@/shared/elements/MetricsHeader/MetricsHeader';
import PropertyList from '@/shared/elements/PropertyList/PropertyList';
import ColorsIcon from '@/shared/icons/ColorsIcon';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { useAppSelector } from '@/store/hooks';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useParams } from 'react-router';
import { calculateCommonArea } from '@/routes/dashboard/projects/project/CanvasExternalElements/PropertyPanel/propertyPanel-helpers';
import useFrameProperties from '@/shared/hooks/useFrameProperties';
import IguConfiguratorView from '@/shared/elements/IguConfiguratorView';
import { compact, uniq } from 'lodash';
import UsedColorsPalette from '@/shared/elements/UsedColorsPalette/UsedColorsPalette';

const WindowFrame = ({
  selectedWindows,
}: {
  selectedWindows: SavedWindow[];
}) => {
  const configData = useFetchWindowConfigQuery().data!;
  const { id } = useParams();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const { getAreaUnit, getFormattedValue } = useFrameProperties();

  const isImperialUnits = unitSystem === UnitSystemTypes.Imperial;
  const windowDimensions = selectedWindows.map((selectedWindow) =>
    calculateWindowDimensions(selectedWindow)
  );
  const heightFromFloor = selectedWindows.map((selectedWindow) =>
    calculateHeightFromFloor(selectedWindow).toString()
  );

  const getHeight = () => {
    return getFormattedValue(
      windowDimensions.map((dimension) => dimension.height.toString())
    );
  };
  const getWidth = () => {
    return getFormattedValue(
      windowDimensions.map((dimension) => dimension.width.toString())
    );
  };

  const getHeightFromFloor = () => {
    return getFormattedValue(heightFromFloor);
  };
  return (
    <div className="text-xs">
      <div className="flex justify-between  px-3  font-medium items-center border-box border-b border-0 border-solid border-light-gray-20 h-8">
        <span className="whitespace-nowrap text-ellipsis overflow-hidden w-full">
          {selectedWindows.length > 1
            ? `Window drafts (${selectedWindows.length})`
            : selectedWindows[0].name}
        </span>
      </div>
      <div
        className={
          'flex flex-col border-b border-0 border-solid border-light-gray-20 bg-white! overflow-y-auto text-dark-gray-100'
        }
      >
        <MetricsHeader />
        <PropertyList
          properties={[
            {
              name: 'Height',
              value: getHeight(),
              units: isImperialUnits ? '' : 'mm',
            },
            {
              name: 'Width',
              value: getWidth(),
              units: isImperialUnits ? '' : 'mm',
            },
            {
              name: 'Height from floor',
              value: getHeightFromFloor(),
              units: isImperialUnits ? '' : 'mm',
            },
          ]}
        />
      </div>
      <div className="border-0 border-b border-solid border-light-gray-20 py-3 mb-3">
        <div className="flex gap-1 mb-2 px-3">
          <ColorsIcon />
          <div className="font-medium leading-5 uppercase">colors</div>
        </div>
        <div>
          <UsedColorsPalette
            paletteName={'Outside color'}
            colors={uniq(
              selectedWindows.map((selectedWindow) => selectedWindow.outerColor)
            )}
            colorsData={configData.colors}
          />
          <UsedColorsPalette
            paletteName={'Inner color'}
            colors={uniq(
              selectedWindows.map((selectedWindow) => selectedWindow.innerColor)
            )}
            colorsData={configData.colors}
          />
        </div>
        <div>
          <PropertyList
            properties={[
              {
                name: 'IGU area',
                value: calculateCommonArea({
                  nodes: selectedWindows,
                  isImperialUnits,
                  calculateAreaFunction: (window) =>
                    window.innerFrames.reduce(
                      (sum, window) => (window?.iguArea ?? 0) + sum,
                      0
                    ),
                }),
                isDisabled: false,
                isEditable: false,
                units: getAreaUnit(),
              },
            ]}
          />
          <IguConfiguratorView
            iguIds={compact(
              selectedWindows.flatMap((window) =>
                window?.innerFrames.flatMap((innerWindow) => innerWindow?.iguId)
              )
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default WindowFrame;
