import { convertMillimetersToFtInch } from '@/shared/helpers/distance';

export interface IGU {
  id: number;
  name: string;
  description: string;
  glassType: GlassTypeValue;
  limitations: IguLimitations;
  thickness: number;
}

export interface IguLimitations {
  maxArea: number;
  maxHeight: number;
  maxRatio: number;
  maxWidth: number;
  minArea: number;
  minHeight: number;
  minWidth: number;
}

export enum IGUFilterGroups {
  Spacers = 'spacers',
  Coatings = 'coatings',
  GlassTypes = 'glassTypes',
  GlassFilters = 'glassFilters',
}

// We use number for the values of the enums because the values are used as filter values in the API
export enum Spacer {
  PHI = 8,
  PHIUS = 4,
  Warm = 2,
}

export enum Coating {
  NoCoating = 5,
  AvisafeSuncool7040 = 7,
  Bird1stEtch = 30,
  Bird1stUVSNX5123 = 4,
  Bird1stUVSNX6272 = 8,
  CG = 2,
  NU = 6,
  Prem = 1,
  SN51 = 3,
}

export enum GlassType {
  AN = 2,
  HS = 0,
  HST = 3,
  T = 1,
}

export enum GlassTypeValue {
  AN = 'AN',
  HS = 'HS',
  HST = 'HST',
  T = 'T',
}

export enum GlassFilter {
  BirdSafe = 2,
  Enamel = 1,
  Regular = 0,
}

export interface IguFilters {
  spacers: {
    [key in Spacer]: boolean;
  };
  coatings: {
    [key in Coating]: boolean;
  };
  glassTypes: {
    [key in GlassType]: boolean;
  };
  glassFilters: {
    [key in GlassFilter]: boolean;
  };
}

export const IGU_ERRORS = {
  maxWidthError: 'IGU width doesn’t match current IGU configuration max width',
  minWidthError: 'IGU width doesn’t match current IGU configuration min width',
  maxHeightError:
    'IGU height doesn’t match current IGU configuration max height',
  minHeightError:
    'IGU height doesn’t match current IGU configuration min height',
  maxRatioError: 'IGU ratio doesn’t match current IGU configuration max ratio',
  maxAreaError: 'IGU area doesn’t match current IGU configuration max area',
  minAreaError: 'IGU area doesn’t match current IGU configuration min area',
  temperedGlassError: 'Tempered glass is required',
  thicknessError: (
    minLimit: number,
    maxLimit: number,
    isImperialUnits: boolean
  ) =>
    `IGU thickness limits: ${isImperialUnits ? convertMillimetersToFtInch(minLimit) : minLimit}-${isImperialUnits ? convertMillimetersToFtInch(maxLimit) : maxLimit} ${isImperialUnits ? '' : 'mm'}`,
};
