import { FlatVector2, Orientation, UserBuildingPanel } from '@/models';
import { get2DCenter, roundKonvaValue } from '@/shared/helpers/konva';
import { OperationType, PanelConfig } from '@/models/window-configurator.model';
import { calculateActualFillerZone } from '@/components/UTC/helpers/area-calculation';
import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { FillerType, UnitInnerFrame } from '@/components/UTC/models';

export const getPanelWidth = (panel: UserBuildingPanel) => {
  return roundKonvaValue(panel.points[1][0] - panel.points[0][0]);
};

export const getPanelHeight = (panel: UserBuildingPanel) => {
  return roundKonvaValue(panel.points[2][1] - panel.points[0][1]);
};

export const getSideCornerPanel = (
  panel: UserBuildingPanel,
  panelsData: UserBuildingPanel[]
) => {
  return panelsData.find(
    (panelData) => panelData.id === panel.cornerSideUnitId
  )!;
};

export const getInnerFrameWidth = (
  frame: UnitInnerFrame,
  panel: UserBuildingPanel,
  config: PanelConfig
) => {
  const innerFramePoints = calculateActualFillerZone(
    frame.points,
    panel.points,
    config
  );
  return roundKonvaValue(innerFramePoints[0][1][0] - innerFramePoints[0][0][0]);
};

export const getInnerFrameHeight = (
  frame: UnitInnerFrame,
  panel: UserBuildingPanel,
  config: PanelConfig
) => {
  const innerFramePoints = calculateActualFillerZone(
    frame.points,
    panel.points,
    config
  );
  return roundKonvaValue(innerFramePoints[1][1][1] - innerFramePoints[1][0][1]);
};

export const getPanelGlazedArea = (
  panel: UserBuildingPanel,
  config: PanelConfig
) => {
  return panel.innerFrames.reduce((acc, curr) => {
    const isGlassMaterial = curr.fillerType !== FillerType.Aluminium;
    return isGlassMaterial
      ? roundKonvaValue(
          convertMillimetersToMeters(getInnerFrameHeight(curr, panel, config)) *
            convertMillimetersToMeters(
              getInnerFrameWidth(curr, panel, config)
            ) +
            acc
        )
      : acc;
  }, 0);
};

export const getIguPanelArea = (
  panel: UserBuildingPanel,
  config: PanelConfig
) => {
  return panel.innerFrames.reduce((acc, curr) => {
    const isGlassMaterial = curr.fillerType !== FillerType.Aluminium;
    const offset =
      curr.operableWindowType === OperationType.Fixed
        ? config.glass.insertedFixed
        : config.glass.insertedOperable;
    return isGlassMaterial
      ? convertMillimetersToMeters(
          getInnerFrameHeight(curr, panel, config) + offset * 2
        ) *
          convertMillimetersToMeters(
            getInnerFrameWidth(curr, panel, config) + offset * 2
          ) +
          acc
      : acc;
  }, 0);
};

export const getMullionParams = (
  mullionPoints: FlatVector2[]
): {
  centerWidth: number;
  centerHeight: number;
  length: number;
  orientation: Orientation;
} => {
  const isVerticalMullion = mullionPoints[0][0] === mullionPoints[1][0];

  const center = get2DCenter(mullionPoints[0], mullionPoints[1]);

  const length = isVerticalMullion
    ? mullionPoints[1][1] - mullionPoints[0][1]
    : mullionPoints[1][0] - mullionPoints[0][0];
  return {
    orientation: isVerticalMullion
      ? Orientation.VERTICAL
      : Orientation.HORIZONTAL,
    centerWidth: center[0],
    centerHeight: center[1],
    length,
  };
};

export const getFrameXY = (
  frame: UnitInnerFrame,
  panel: UserBuildingPanel,
  panelConfig: PanelConfig
) => {
  const areaZonePoints = calculateActualFillerZone(
    frame.points,
    panel.points,
    panelConfig
  );

  return {
    x: roundKonvaValue((areaZonePoints[0][0][0] + areaZonePoints[1][0][0]) / 2),
    y: roundKonvaValue((areaZonePoints[1][0][1] + areaZonePoints[1][0][1]) / 2),
  };
};

export const generateGlazingBeadsPoints = (
  unitHeight: number,
  points: FlatVector2[],
  config: PanelConfig
) => {
  const topBead: FlatVector2[] = [
    [points[0][0] - config.sideWidth, points[0][1] - config.sideWidth],
    [points[1][0] + config.sideWidth, points[1][1] - config.sideWidth],
    [points[1][0], points[1][1]],
    [points[0][0], points[0][1]],
  ];

  const rightBead: FlatVector2[] = [
    [points[1][0] + config.sideWidth, points[1][1] - config.sideWidth],
    [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
    [points[1][0], points[2][1]],
    [points[1][0], points[1][1]],
  ];

  const isBottomBead = unitHeight === points[3][1] + config.bottomWidth;

  const bottomBead: FlatVector2[] = isBottomBead
    ? [
        [points[2][0] + config.sideWidth, points[2][1] + config.bottomWidth],
        [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
        [points[2][0], points[2][1]],
        [points[3][0], points[3][1]],
        [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
        [points[3][0] - config.sideWidth, points[3][1] + config.bottomWidth],
      ]
    : [
        [points[2][0], points[2][1]],
        [points[3][0], points[3][1]],
        [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
        [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
      ];

  const leftBead: FlatVector2[] = [
    [points[3][0], points[3][1]],
    [points[0][0], points[0][1]],
    [points[0][0] - config.sideWidth, points[0][1] - config.sideWidth],
    [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
  ];

  return [topBead, rightBead, bottomBead, leftBead];
};

export const generateCornerGlazingBeadsPoints = (
  unitHeight: number,
  unitWidth: number,
  offset: number,
  points: FlatVector2[],
  config: PanelConfig,
  isSideCorner: boolean
) => {
  const isJoint = isSideCorner
    ? offset + config.sideWidth === points[0][0]
    : unitWidth === points[1][0] + config.sideWidth;

  const isBottomBead = unitHeight === points[3][1] + config.bottomWidth;

  const topBead: FlatVector2[] = isJoint
    ? [
        [points[0][0] - config.sideWidth, points[0][1] - config.sideWidth],
        [points[1][0], points[1][1] - config.sideWidth],
        [points[1][0], points[1][1]],
        [points[0][0], points[0][1]],
      ]
    : [
        [points[0][0] - config.sideWidth, points[0][1] - config.sideWidth],
        [points[1][0] + config.sideWidth, points[1][1] - config.sideWidth],
        [points[1][0], points[1][1]],
        [points[0][0], points[0][1]],
      ];

  const topSideBead: FlatVector2[] = isJoint
    ? [
        [points[0][0], points[0][1] - config.sideWidth],
        [points[1][0] + config.sideWidth, points[1][1] - config.sideWidth],
        [points[1][0], points[1][1]],
        [points[0][0], points[0][1]],
      ]
    : [
        [points[0][0] - config.sideWidth, points[0][1] - config.sideWidth],
        [points[1][0] + config.sideWidth, points[1][1] - config.sideWidth],
        [points[1][0], points[1][1]],
        [points[0][0], points[0][1]],
      ];

  const rightBead: FlatVector2[] = [
    [points[1][0] + config.sideWidth, points[1][1] - config.sideWidth],
    [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
    [points[1][0], points[2][1]],
    [points[1][0], points[1][1]],
  ];

  const bottomBead: FlatVector2[] = isBottomBead
    ? isJoint
      ? [
          [points[2][0], points[2][1] + config.bottomWidth],
          [points[2][0], points[2][1]],
          [points[3][0], points[3][1]],
          [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
          [points[3][0] - config.sideWidth, points[3][1] + config.bottomWidth],
        ]
      : [
          [points[2][0] + config.sideWidth, points[2][1] + config.bottomWidth],
          [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
          [points[2][0], points[2][1]],
          [points[3][0], points[3][1]],
          [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
          [points[3][0] - config.sideWidth, points[3][1] + config.bottomWidth],
        ]
    : isJoint
      ? [
          [points[2][0], points[2][1]],
          [points[3][0], points[3][1]],
          [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
          [points[2][0], points[2][1] + config.sideWidth],
        ]
      : [
          [points[2][0], points[2][1]],
          [points[3][0], points[3][1]],
          [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
          [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
        ];

  const bottomSideBead: FlatVector2[] = isBottomBead
    ? isJoint
      ? [
          [points[2][0] + config.sideWidth, points[2][1] + config.bottomWidth],
          [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
          [points[2][0], points[2][1]],
          [points[3][0], points[3][1]],
          [points[3][0], points[3][1] + config.bottomWidth],
        ]
      : [
          [points[2][0] + config.sideWidth, points[2][1] + config.bottomWidth],
          [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
          [points[2][0], points[2][1]],
          [points[3][0], points[3][1]],
          [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
          [points[3][0] - config.sideWidth, points[3][1] + config.bottomWidth],
        ]
    : isJoint
      ? [
          [points[2][0], points[2][1]],
          [points[3][0], points[3][1]],
          [points[3][0], points[3][1] + config.sideWidth],
          [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
        ]
      : [
          [points[2][0], points[2][1]],
          [points[3][0], points[3][1]],
          [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
          [points[2][0] + config.sideWidth, points[2][1] + config.sideWidth],
        ];

  const leftBead: FlatVector2[] = [
    [points[3][0], points[3][1]],
    [points[0][0], points[0][1]],
    [points[0][0] - config.sideWidth, points[0][1] - config.sideWidth],
    [points[3][0] - config.sideWidth, points[3][1] + config.sideWidth],
  ];

  return isSideCorner
    ? [topSideBead, rightBead, bottomSideBead, isJoint ? [] : leftBead]
    : [topBead, isJoint ? [] : rightBead, bottomBead, leftBead];
};

export const checkIsCornerUnitIsSimilar = (
  unit: UserBuildingPanel,
  unit2: UserBuildingPanel,
  allUnits: UserBuildingPanel[]
) => {
  if (unit.cornerSide !== unit2.cornerSide) return false;
  if (unit.isInnerCorner !== unit2.isInnerCorner) return false;

  if (unit.cornerSide === unit2.cornerSide) {
    const sideCorner1 = getSideCornerPanel(unit, allUnits);
    const sideCorner2 = getSideCornerPanel(unit2, allUnits);
    if (!sideCorner1 || !sideCorner2) return false;

    return (
      getPanelWidth(sideCorner1) === getPanelWidth(sideCorner2) &&
      getPanelHeight(sideCorner2) === getPanelHeight(sideCorner1)
    );
  }
  return false;
};

export const isUnitsAreSimilar = (
  unit1: UserBuildingPanel,
  unit2: UserBuildingPanel,
  allUnits: UserBuildingPanel[],
  ignoreSelf?: boolean
) => {
  return (
    getPanelWidth(unit1) === getPanelWidth(unit2) &&
    getPanelHeight(unit1) === getPanelHeight(unit2) &&
    (ignoreSelf ? true : unit1.id !== unit2.id) &&
    (unit2.cornerSide && unit1.cornerSide
      ? checkIsCornerUnitIsSimilar(unit1, unit2, allUnits)
      : true)
  );
};
