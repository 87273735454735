import React, { useEffect, useMemo, useRef } from 'react';
import { Group, Layer, Line, Stage } from 'react-konva';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { useKonvaHandlers } from '@/shared/hooks/useKonvaHandlers';
import {
  GridLineData,
  NodeType,
  SelectedNode,
  WindowPlacementData,
} from '@/models';
import { useAppDispatch, useAppSelector } from '@/store/hooks';
import {
  getFacadeDesignerMode,
  getIsSomePanelHovered,
  getModifiedWalls,
  getSelectedGridlines,
  getSelectedPlacedWindows,
  SnapZone,
  resetSelectedGridLines,
  resetSelectedPlacedPanels,
  setGridSnapZones,
  setHoveredPlacedPanels,
  setHoveredPlacedWindow,
  setSelectedPlacedWindow,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { FacadeDesignerModes } from '@/models/shared.model';
import FacadeDesignerWallView from '@/components/FacadeDesigner/elements/FacadeDesignerWallView';
import {
  useFindNodeData,
  WallSearchResults,
} from '@/shared/hooks/useFindNodeData';
import { getProjectUnits } from '@/store/slices/projectSlice';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import FacadeDesignerBackground from '@/components/FacadeDesigner/elements/FacadeDesignerBackground';
import { useGridLines } from '@/components/FacadeDesigner/hooks/useGridLines';
import { useParams } from 'react-router';

import { FACADE_VIEW } from '@/shared/constants';
import { useUpdate } from 'react-use';
import { GRID_INFORMATION_PADDING } from './constants';
import Clipping from 'polygon-clipping';
import { useMeasureWallsFacadeDesigner } from './hooks/useMeasureWallsFacadeDesigner';
import { GridLineConfig } from './models';
import FacadeDesignerMeasurements from '@/components/FacadeDesigner/elements/FacadeDesignerMeasurements';
import FacadeDesignerErrors from '@/components/FacadeDesigner/elements/FacadeDesignerErrors';
import { round } from 'mathjs';
import { useFdDragHandlers } from '@/components/FacadeDesigner/hooks/useFdDragHandlers';
import { useStore } from 'react-redux';
import { RootState } from '@/store';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import useFacadeDesignerSnaps from './hooks/useFacadeDesignerSnaps';
import FacadeDesignerActionHandlers from '@/components/FacadeDesigner/elements/FacadeDesignerActionHandlers';

interface FacadeDesignerContainerProps {
  buildingGUID: string;
  selectedWalls: SelectedNode[];
  placementHeight: number;
  placementWidth: number;
  isProjectLocked?: boolean;
  onKonvaInit?: (stage: Konva.Stage) => void;
  showPanelCellNumbers?: boolean;
}

const FacadeDesignerContainer: React.FC<FacadeDesignerContainerProps> = ({
  buildingGUID,
  selectedWalls,
  placementHeight,
  placementWidth,
  isProjectLocked,
  onKonvaInit,
}) => {
  Konva.dragButtons = [2];
  const stageRef = useRef<Konva.Stage>(null!);
  const { id } = useParams();
  const { panel: panelsConfig } = useFetchWindowConfigQuery().data!;

  const update = useUpdate();

  const dispatch = useAppDispatch();
  const unitSystem = useAppSelector(getProjectUnits(id!));
  const modifiedWalls = useAppSelector(getModifiedWalls);
  const { updateWallWindowPlacements, updateBuildingPlacements } =
    useUpdateUserBuildingData();
  const store = useStore();

  const { findDataForWall } = useFindNodeData();
  const { generateCustomGrids } = useGridLines(buildingGUID);
  const { getMeasureWall, getMeasureWalls } = useMeasureWallsFacadeDesigner();

  const facadeDesignerMode = useAppSelector(getFacadeDesignerMode);
  const isSelectionMode = facadeDesignerMode === FacadeDesignerModes.Selection;

  const isSomePanelHovered = useAppSelector(getIsSomePanelHovered);

  useEffect(() => {
    if (!isSelectionMode) {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
      dispatch(resetSelectedPlacedPanels());
    }
  }, [isSelectionMode]);

  const wallsData: WallSearchResults[] = useMemo(
    () => selectedWalls.map((wall) => findDataForWall(wall.guid)!),
    [selectedWalls]
  );

  const {
    minMaxWallsCoordinates,
    startPoint,
    wallsTotalHeight,
    wallsTotalWidth,
  } = getMeasureWalls(wallsData);

  const scaleCoefficient = 0.68;

  const scaleX = (placementWidth * scaleCoefficient) / wallsTotalWidth;
  const scaleY =
    (placementHeight - 100) / (wallsTotalHeight + GRID_INFORMATION_PADDING * 2);
  const scale = Math.min(scaleX, scaleY);

  const { wheelHandler } = useKonvaHandlers();

  const wheelEventEndTimeout = useRef(0);

  useEffect(() => {
    if (stageRef.current) {
      stageRef.current.scale({ x: scale, y: scale });
      stageRef.current.position({
        x: placementWidth / 2 - (wallsTotalWidth * scale) / 2,
        y: placementHeight / 2 - (wallsTotalHeight * scale) / 2,
      });
      update();
    }
  }, [modifiedWalls, stageRef.current]);

  const handleWheel = (event: KonvaEventObject<WheelEvent>) => {
    clearTimeout(wheelEventEndTimeout.current);
    wheelEventEndTimeout.current = window.setTimeout(() => update(), 100);
    const scaleData = wheelHandler(event, {
      maxScale: 1,
      minScale: Math.min(0.01, scale),
    });

    if (scaleData) {
      stageRef.current.scale({ x: scaleData.scale, y: scaleData.scale });
      stageRef.current.position({
        x: scaleData.x,
        y: scaleData.y,
      });
    }
  };

  const handleGridPlacement = () => {
    generateCustomGrids({ wallsData, startPoint });
  };

  const handleSaveWindowPlacements = (
    placements: WindowPlacementData[],
    wallData: WallSearchResults
  ) => {
    updateWallWindowPlacements({
      buildingGUID: wallData.getParentNode(NodeType.Building)!.guid,
      blockGUID: wallData.getParentNode(NodeType.Block)!.guid,
      storeyGUID: wallData.getParentNode(NodeType.Storey)!.guid,
      wallGUID: wallData.guid,
      windowPlacements: placements,
    });
  };

  const handleSaveWallPlacements = (
    newPlacements: {
      wallGUID: string;
      gridLines?: GridLineData[];
      windowPlacements?: WindowPlacementData[];
    }[]
  ) => {
    updateBuildingPlacements({
      buildingGUID,
      placementData: newPlacements,
    });
  };
  const onKeydown = (event: KeyboardEvent) => {
    if (event.key === 'Delete' || event.key === 'Backspace') {
      const selectedPlacedGridLines = getSelectedGridlines(
        store.getState() as RootState
      );
      const selectedPlacedWindows = getSelectedPlacedWindows(
        store.getState() as RootState
      );
      if (
        facadeDesignerMode === FacadeDesignerModes.Selection &&
        !isProjectLocked &&
        (selectedPlacedWindows.length || selectedPlacedGridLines.length)
      ) {
        const newPlacements: {
          wallGUID: string;
          gridLines?: GridLineData[];
          windowPlacements?: WindowPlacementData[];
        }[] = modifiedWalls.map((wallGUID) => {
          const newGridLines = wallsData
            .find((data) => data.guid === wallGUID)
            ?.gridLines.filter(
              (line) =>
                line.cornerAlign ||
                !selectedPlacedGridLines.some(
                  (selectedGridLine) => selectedGridLine.guid === line.guid
                )
            );
          const newWindowPlacements = wallsData
            .find((data) => data.guid === wallGUID)
            ?.windowPlacements.filter(
              (window) =>
                !selectedPlacedWindows.some(
                  (selectedWindow) => selectedWindow.guid === window.guid
                )
            );
          return {
            wallGUID,
            windowPlacements: newWindowPlacements,
            gridLines: newGridLines,
          };
        });

        handleSaveWallPlacements(newPlacements);

        dispatch(setSelectedPlacedWindow([]));
        dispatch(setHoveredPlacedWindow(null));
        dispatch(resetSelectedGridLines());
      }
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', onKeydown);
    return () => {
      document.removeEventListener('keydown', onKeydown);
    };
  }, [wallsData, modifiedWalls, facadeDesignerMode, isProjectLocked]);

  useEffect(() => {
    stageRef.current && onKonvaInit && onKonvaInit(stageRef.current);
  }, [stageRef.current]);

  const contours = useMemo(() => {
    const metricsWall = wallsData.map((wall) => {
      const { wallHeight, wallOffset, wallWidth } = getMeasureWall(
        wall,
        startPoint,
        minMaxWallsCoordinates
      );
      const offsetX = wallOffset.x;
      const offsetY = round(-1 * wallOffset.y, 2);
      const adjustableHeight = round(offsetY + wallHeight, 2);

      return [
        [
          [offsetX, offsetY],
          [offsetX + wallWidth, offsetY],
          [offsetX + wallWidth, adjustableHeight],
          [offsetX, adjustableHeight],
        ],
      ] as Clipping.Polygon;
    });

    return Clipping.union(metricsWall).flat(1);
  }, [wallsData]);

  const handleClick = (event: KonvaEventObject<MouseEvent>) => {
    if (event.target.nodeType === 'Stage') {
      dispatch(setSelectedPlacedWindow([]));
      dispatch(resetSelectedGridLines());
      dispatch(resetSelectedPlacedPanels());
    }
  };

  const handleMouseMove = (event: KonvaEventObject<MouseEvent>) => {
    const hoveredElementIsStage = event.target?.nodeType === 'Stage';
    if (isSomePanelHovered && hoveredElementIsStage) {
      dispatch(setHoveredPlacedPanels(null));
    }
  };

  const { handleDragStart, handleWindowDragEnd, handleDragEnd } =
    useFdDragHandlers({
      handleSaveWallPlacements,
      wallsData,
      startPoint,
      minMaxWallsCoordinates,
      isProjectLocked: !!isProjectLocked,
      stageRef,
      generateCustomGrids,
    });

  const { calculateGripSnapZones } = useFacadeDesignerSnaps();

  const gridSnapZones: SnapZone[] = useMemo(() => {
    if (!wallsData || !stageRef.current?.scaleX()) return [];
    return calculateGripSnapZones({
      wallsData,
      scale: stageRef.current.scaleX(),
      startPoint,
      minMaxWallsCoordinates,
    });
  }, [wallsData, stageRef.current?.scaleX()]);

  useEffect(() => {
    dispatch(setGridSnapZones(gridSnapZones));

    return () => {
      dispatch(setGridSnapZones([]));
    };
  }, [gridSnapZones]);

  return (
    <Stage
      onContextMenu={(e) => e.evt.preventDefault()}
      width={placementWidth}
      height={placementHeight}
      onClick={handleClick}
      draggable
      onWheel={handleWheel}
      onDragEnd={() => {}}
      onDragMove={() => {}}
      ref={stageRef}
      onPointerDown={handleDragStart}
      onPointerUp={handleDragEnd}
      onMouseMove={handleMouseMove}
    >
      {stageRef.current && (
        <>
          <Layer>
            <FacadeDesignerBackground
              scale={stageRef.current.scaleX()!}
              totalHeight={wallsTotalHeight}
              totalWidth={wallsTotalWidth}
              unitSystem={unitSystem}
            />

            <Group id={FACADE_VIEW}>
              {wallsData.map((wallData) => {
                const { wallHeight, wallOffset, wallWidth } = getMeasureWall(
                  wallData,
                  startPoint,
                  minMaxWallsCoordinates
                );

                return (
                  <FacadeDesignerWallView
                    config={panelsConfig}
                    wallHeight={wallHeight}
                    wallWidth={wallWidth}
                    wallOffset={wallOffset}
                    facadeDesignerMode={facadeDesignerMode}
                    scale={stageRef.current.scaleX()!}
                    wallData={wallData}
                    key={wallData.guid}
                    onAddWindow={(data) =>
                      handleSaveWindowPlacements(data, wallData)
                    }
                    onMeasurementUpdate={(data) =>
                      handleSaveWindowPlacements(data, wallData)
                    }
                    onGridPlacement={handleGridPlacement}
                    handleWindowDragEnd={handleWindowDragEnd}
                    isProjectLocked={!!isProjectLocked}
                  />
                );
              })}
              {contours.map((contour, index) => (
                <Line
                  key={`wall-contour-${index}`}
                  points={contour.flat()}
                  stroke={GridLineConfig.placedSelected.stroke}
                  strokeWidth={1.3}
                  shadowForStrokeEnabled={false}
                  strokeScaleEnabled={false}
                />
              ))}
            </Group>
            <>
              <FacadeDesignerMeasurements
                projectId={id!}
                wallsData={wallsData}
                startPoint={startPoint}
                scale={stageRef.current.scale()!.x}
                minMaxWallsCoordinates={minMaxWallsCoordinates}
                selectedWalls={selectedWalls}
                wallsTotalWidth={wallsTotalWidth}
                wallsTotalHeight={wallsTotalHeight}
              />
            </>
          </Layer>
        </>
      )}
      <FacadeDesignerErrors scale={scale} />
      {!isProjectLocked && <FacadeDesignerActionHandlers stageRef={stageRef} />}
    </Stage>
  );
};

export default FacadeDesignerContainer;
