import React, { useEffect } from 'react';
import ViewerPanels, {
  ViewerPanelProps,
} from '@/routes/dashboard/projects/project/UserBuilding/components/PanelizationItems/ViewerPanels';
import ViewerWindows, {
  ViewerWindowsProps,
} from '@/routes/dashboard/projects/project/UserBuilding/components/PanelizationItems/ViewerWindows';
import ViewerGridLines, {
  ViewerGridLinesProps,
} from '@/routes/dashboard/projects/project/UserBuilding/components/PanelizationItems/ViewerGridLines';
import { useGetAllPanelsQuery } from '@/store/apis/projectsApi';
import { useParams } from 'react-router';
import { Mesh } from 'three';

interface WallPlacementProps
  extends ViewerPanelProps,
    ViewerWindowsProps,
    ViewerGridLinesProps {
  meshRef: React.RefObject<Mesh>;
}

const WallPlacementComponents: React.FC<WallPlacementProps> = ({
  wallPoints,
  wallGuid,
  wallUserData,
  panels,
  windows,
  multiplyRate,
  gridLines,
  meshRef,
}) => {
  const { id } = useParams();
  const fetchedPanels = useGetAllPanelsQuery(id!).data;

  const hasPanels =
    panels.filter(
      (panel) =>
        !fetchedPanels?.find((fp) => fp.id === panel.panelId)?.cornerSide
    ).length > 0;

  useEffect(() => {
    if (meshRef.current) {
      meshRef.current.visible = !hasPanels;
    }
  }, [hasPanels]);

  return (
    <>
      {hasPanels ? (
        <ViewerPanels
          panels={panels}
          multiplyRate={multiplyRate}
          wallUserData={wallUserData}
          wallPoints={wallPoints}
        />
      ) : (
        <>
          <ViewerWindows
            windows={windows}
            multiplyRate={multiplyRate}
            wallPoints={wallPoints}
            wallGuid={wallGuid}
          />
          <ViewerGridLines
            gridLines={gridLines}
            wallPoints={wallPoints}
            wallGuid={wallGuid}
            multiplyRate={multiplyRate}
          />
        </>
      )}
    </>
  );
};

export default WallPlacementComponents;
