export const TEXT_SIZE_FRAME = 80;
export const TEXT_SIZE_UNIT = 150;
export const TEXT_PADDING = 20;
export const SCALE = 6;
export const LINE_WIDTH = 5;
export const HANDLE_SCALE = 1.4;
export const TAKE_OFF_SCALE = 40;
export const HANDLE_OFFSET = 56;
export const STROKE_DASH = '10,10';
export const OPACITY = 0.2;

export const SVG_ID = 'svg-element';
