import { useState } from 'react';

import { NodeType, UserBuildingPanel } from '@/models';
import { useFindNodeData } from '@/shared/hooks/useFindNodeData';
import { useUpdateUserBuildingData } from '@/shared/hooks/updateProjectDataHooks/useUpdateUserBuildingData';
import { isUnitsAreSimilar } from '@/shared/helpers';
import { useKey } from 'react-use';
import { PanelPlacementDataForFD } from '@/components/FacadeDesigner/models';
import { useAppDispatch } from '@/store/hooks';
import {
  resetPlacementErrors,
  setPlacementError,
  updateSelectedPanelId,
} from '@/store/slices/windowsReducer/facadeDesignerSlice';
import { compact } from 'lodash';

interface UseCopyPasteHandlersProps {
  selectedPanels: PanelPlacementDataForFD[];
  isOnlyOnePanelSelected: boolean;
  unitsData: UserBuildingPanel[] | undefined;
}

interface ReplacedPanels extends UserBuildingPanel, PanelPlacementDataForFD {
  buildingGUID: string;
  blockGUID: string;
  storeyGUID: string;
  wallGUID: string;
  offsetFromLeftEdge: number;
}

const useCopyPasteHandlers = ({
  isOnlyOnePanelSelected,
  selectedPanels,
  unitsData,
}: UseCopyPasteHandlersProps) => {
  const dispatch = useAppDispatch();

  const [copiedUnit, setCopiedUnit] = useState<UserBuildingPanel>();

  const { findDataForWallInfoViaPanel } = useFindNodeData();
  const { updateWallPanels } = useUpdateUserBuildingData();

  const handleCopyUnit = (event: KeyboardEvent) => {
    const ctrlKey = event.ctrlKey || event.metaKey;

    if (!selectedPanels || !isOnlyOnePanelSelected || !ctrlKey) return;

    const panel = unitsData?.find(
      (panel) => panel.id === selectedPanels[0].panelId
    );
    setCopiedUnit(panel);
  };

  const handleSetCopiedUnit = (event: KeyboardEvent) => {
    const ctrlKey = event.ctrlKey || event.metaKey;

    if (!unitsData || !ctrlKey || !copiedUnit) return;

    const panelsToReplace: ReplacedPanels[] = compact(
      selectedPanels.map((panel) => {
        const wall = findDataForWallInfoViaPanel({
          panelGuid: panel.guid,
        });

        const insertedUnit = unitsData.find(
          (unit) => unit.id === panel.panelId
        );
        if (!wall || !copiedUnit || !insertedUnit) return null;

        if (isUnitsAreSimilar(copiedUnit, insertedUnit, unitsData, true)) {
          return {
            ...insertedUnit,
            ...panel,
            offsetFromLeftEdge: wall.wallPanels.find(
              (wallPanel) => wallPanel.guid === panel.guid
            )!.offsetFromLeftEdge,
            buildingGUID: wall.getParentNode(NodeType.Building)!.guid,
            blockGUID: wall.getParentNode(NodeType.Block)!.guid,
            storeyGUID: wall.getParentNode(NodeType.Storey)!.guid,
            wallGUID: wall.guid,
          };
        } else return null;
      })
    );

    const needToShowError = panelsToReplace.length !== selectedPanels.length;

    for (let i = 0; i < panelsToReplace.length; i++) {
      const selectedPanel = panelsToReplace[i];
      updateWallPanels({
        buildingGUID: selectedPanel.buildingGUID,
        blockGUID: selectedPanel.blockGUID,
        storeyGUID: selectedPanel.storeyGUID,
        wallGUID: selectedPanel.wallGUID,
        panelPlacement: {
          guid: selectedPanel.guid,
          panelId: copiedUnit.id,
          isInitialCorner: selectedPanel.isInitialCorner,
          offsetFromLeftEdge: selectedPanel.offsetFromLeftEdge,
        },
        triggerBEUpdate: i === panelsToReplace.length - 1,
      });
      dispatch(
        updateSelectedPanelId({
          panelGuid: selectedPanel.guid,
          panelId: copiedUnit.id,
        })
      );
    }

    if (needToShowError) {
      dispatch(
        setPlacementError({
          key: 'copy_paste__error',
          message:
            'UCW Unit Type can be applied only to UCW Units with the same size and type',
          state: true,
        })
      );

      setTimeout(() => dispatch(resetPlacementErrors()), 2000);
    }
  };

  useKey('c', handleCopyUnit, {}, [
    copiedUnit,
    unitsData,
    isOnlyOnePanelSelected,
    selectedPanels,
  ]);

  useKey('v', handleSetCopiedUnit, {}, [
    copiedUnit,
    unitsData,
    isOnlyOnePanelSelected,
    selectedPanels,
  ]);
};

export default useCopyPasteHandlers;
