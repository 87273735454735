import React from 'react';
import { FlatVector2 } from '@/models';
import { useFetchWindowConfigQuery } from '@/store/apis/windowApi';
import { getHexByName } from '../../helpers/config';
import { COMMON_LINE_STROKE_WIDTH } from '@/shared/constants';
import { Line } from 'react-konva';

interface WindowSashProps {
  points: FlatVector2[];
  color?: string;
}

const WindowSash: React.FC<WindowSashProps> = ({ points, color }) => {
  const configColors = useFetchWindowConfigQuery().data!.colors;
  return (
    <Line
      points={points.flat()}
      closed
      fill={color ? getHexByName(configColors, color) : '#F0EEE6'}
      stroke="#8D8B8F"
      strokeWidth={COMMON_LINE_STROKE_WIDTH}
      strokeScaleEnabled={false}
    />
  );
};

export default WindowSash;
