import React from 'react';
import KonvaDashedLine from '@/shared/elements/KonvaDashedLine/KonvaDashedLine';
import { Html } from 'react-konva-utils';
import { Group, Image } from 'react-konva';
import useImage from 'use-image';
import ElevationIcon from '@/images/ElevationIcon.svg';

const KonvaHeightMark = ({
  width,
  name,
  xOffset,
  yOffset,
  scale,
}: {
  width: number;
  name: string;
  xOffset: number;
  yOffset: number;
  scale: number;
}) => {
  const [elevationIcon] = useImage(ElevationIcon);
  return (
    <>
      <Group x={xOffset} y={yOffset} scaleX={1 / scale} scaleY={1 / scale}>
        <KonvaDashedLine
          points={[
            [0, 0],
            [width, 0],
          ]}
        />
        <Html
          groupProps={{
            x: 0,
            y: -14,
          }}
        >
          <div
            style={{
              width,
            }}
            className={`flex justify-end text-[11px] text-dark-gray-80 font-normal `}
          >
            <div className="flex-1 text-right truncate ... pl-0.5 pr-0.5">
              {name}
            </div>
          </div>
        </Html>
        {elevationIcon && (
          <Image
            image={elevationIcon}
            width={8}
            height={9}
            x={width}
            y={-9 / 2}
          />
        )}
      </Group>
    </>
  );
};

export default KonvaHeightMark;
