import React from 'react';
import Spin from 'antd/es/spin';
import { SpinProps } from 'antd/es/spin';

import TreeLoader from './TreeLoader';

interface IntusLoaderProps extends SpinProps {
  loading?: boolean;
  embedded?: boolean;
  absolutePosition?: boolean;
}

const IntusLoader: React.FC<IntusLoaderProps> = ({
  embedded = true,
  loading = true,
  absolutePosition,
  ...rest
}) => {
  return (
    <Spin
      spinning={loading}
      style={{
        width: '100%',
        height: '100%',
        position: absolutePosition ? 'absolute' : undefined,
        display: loading ? 'contents' : undefined,
      }}
      indicator={<TreeLoader isMinimal={embedded} />}
      {...rest}
    />
  );
};

export default IntusLoader;
