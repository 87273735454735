import React from 'react';
import { WINDOW_GLASS_SELECTED_COLOR } from '@/components/WindowCreator/elements/windows.constants';
import { Group, Line } from 'react-konva';

import { useAppSelector } from '@/store/hooks';

import { calculateAxis } from '@/shared/helpers/konva';
import {
  getHoveredAreas,
  getSelectedArea,
} from '@/store/slices/windowsReducer/UTCSlice';

const UTCSelectionWrapper = () => {
  const selectedAreas = useAppSelector(getSelectedArea);
  const hoveredAreas = useAppSelector(getHoveredAreas);

  return (
    <Group>
      {!!hoveredAreas &&
        hoveredAreas.map((hoveredArea, areaIndex) => (
          <React.Fragment key={areaIndex}>
            {hoveredArea.map((point, i) => {
              const lineWithOffset = calculateAxis(point, i);
              return (
                <Line
                  points={lineWithOffset}
                  strokeWidth={2}
                  strokeScaleEnabled={false}
                  stroke={WINDOW_GLASS_SELECTED_COLOR}
                  opacity={1}
                  key={`selected-${areaIndex}-${i}`}
                  listening={false}
                />
              );
            })}
          </React.Fragment>
        ))}
      {!!selectedAreas &&
        selectedAreas.map((selectedArea, areaIndex) => (
          <React.Fragment key={areaIndex}>
            {selectedArea.map((point, i) => {
              const lineWithOffset = calculateAxis(point, i);
              return (
                <Line
                  points={lineWithOffset}
                  strokeWidth={2}
                  strokeScaleEnabled={false}
                  stroke={WINDOW_GLASS_SELECTED_COLOR}
                  opacity={1}
                  key={`selected-${areaIndex}-${i}`}
                  listening={false}
                />
              );
            })}
            <Line
              points={selectedArea.flat(2)}
              fill={WINDOW_GLASS_SELECTED_COLOR}
              closed
              opacity={0.1}
              listening={false}
            />
          </React.Fragment>
        ))}
    </Group>
  );
};

export default UTCSelectionWrapper;
