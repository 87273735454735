import React from 'react';
import {
  FlatVector2Axis,
  MeasurementElementType,
  MeasurementUpdateData,
} from '@/components/WindowCreator/models';
import { Measurement } from '@/shared/components/MeasurementLine';
import { UnitSystemTypes } from '@/models';

const UTCMullionMeasurements = ({
  units,
  scale,
  hasInnerHorizontalMeasurements,
  hasInnerVerticalMeasurements,
  handleMeasurementSubmit,
  mullionHorizontalMeasurements,
  mullionVerticalMeasurements,
}: {
  units: UnitSystemTypes;
  scale: number;
  hasInnerHorizontalMeasurements: boolean;
  hasInnerVerticalMeasurements: boolean;
  handleMeasurementSubmit: (data: MeasurementUpdateData) => void;
  mullionHorizontalMeasurements: FlatVector2Axis[];
  mullionVerticalMeasurements: FlatVector2Axis[];
}) => {
  return (
    <>
      {hasInnerHorizontalMeasurements &&
        mullionHorizontalMeasurements.map((points, idx) => (
          <Measurement
            type={MeasurementElementType.Mullion}
            units={units}
            scale={scale}
            key={`measurement_horizontal_mullion_${idx}`}
            points={points}
            onMeasurementSubmit={handleMeasurementSubmit}
          ></Measurement>
        ))}

      {hasInnerVerticalMeasurements &&
        mullionVerticalMeasurements.map((points, idx) => (
          <Measurement
            type={MeasurementElementType.Mullion}
            units={units}
            scale={scale}
            key={`measurement_vertical_mullion_${idx}`}
            points={points}
            onMeasurementSubmit={handleMeasurementSubmit}
          ></Measurement>
        ))}
    </>
  );
};

export default UTCMullionMeasurements;
