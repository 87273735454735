import React from 'react';

import { FixedWindow } from '@/components/WindowCreator/elements/InnerWindow/FixedWindow';
import OperableWindow from '@/components/WindowCreator/elements/InnerWindow/OperableWindow';
import {
  InnerWindowData,
  OperationType,
} from '@/models/window-configurator.model';
import { FlatVector2, UnitSystemTypes } from '@/models';
import { View } from '../../models/konva-model';
import { WindowCreatorModes } from '@/models/shared.model';

interface InnerWindowProps {
  windowData: InnerWindowData;
  outerFramePoints: FlatVector2[];
  frameColor: string;
  scale: number;
  units?: UnitSystemTypes;
  independent?: boolean;
  view: View;
  distanceToFloor?: number;
  mode?: WindowCreatorModes;
}

const InnerWindow = ({
  scale,
  independent,
  windowData,
  outerFramePoints,
  frameColor,
  units,
  view,
  distanceToFloor,
  mode,
}: InnerWindowProps) => {
  const windowPoints = windowData.points;
  if (windowData?.operationType === OperationType.Fixed) {
    return (
      <FixedWindow
        outerFramePoints={outerFramePoints}
        points={windowPoints}
        independent={independent ?? true}
        distanceToFloor={distanceToFloor}
        iguId={windowData.iguId}
        scale={scale}
        units={units}
        mode={mode}
      />
    );
  }

  return (
    <OperableWindow
      points={windowPoints}
      scale={scale}
      outerFramePoints={outerFramePoints}
      operationType={windowData.operationType}
      independent={true}
      units={units}
      frameColor={frameColor}
      view={view}
      distanceToFloor={distanceToFloor}
      iguId={windowData.iguId}
      mode={mode}
    />
  );
};

export default InnerWindow;
