/* eslint-disable no-console */
const useHandleError = () => {
  // In future Some Sentry may be added here
  const handleError = (err: unknown) => {
    console.error(err);
  };

  return { handleError };
};

export default useHandleError;
