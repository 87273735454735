import React, { useMemo } from 'react';
import { ContextAction, ContextMenu, IntusIconButton } from '@/shared/elements';
import { MoreIcon } from '@/shared/icons';
import { ContextActionTypes } from '@/shared/elements/ContextMenu/ContextMenu';

interface UnitCardContextMenuProps {
  onEdit?: () => void;
  onDuplicate?: () => void;
  onDelete?: () => void;
  disabled?: boolean;
}

const UnitCardContextMenu: React.FC<UnitCardContextMenuProps> = ({
  onEdit,
  onDuplicate,
  onDelete,
  disabled,
}) => {
  const contextMenuActions: ContextAction[] = useMemo(() => {
    const actions: ContextAction[] = [];

    if (onDuplicate) {
      actions.push({
        label: 'Duplicate',
        action: () => onDuplicate && onDuplicate(),
      });
    }
    if (onEdit) {
      actions.push({
        label: 'Edit',
        action: () => {
          onEdit && onEdit();
        },
        divider: !!onDelete,
      });
      if (onDelete) {
        actions.push({
          label: 'Delete',
          type: ContextActionTypes.Danger,
          action: () => onDelete && onDelete(),
        });
      }
    }
    return actions;
  }, [onDuplicate, onEdit, onDelete]);

  return (
    <>
      <ContextMenu actions={contextMenuActions} openMenuByLeftClick>
        <IntusIconButton
          simplified
          size={'small'}
          icon={<MoreIcon />}
          id="unitCardMenu__more-button"
          disabled={disabled}
        />
      </ContextMenu>
    </>
  );
};

export default UnitCardContextMenu;
