import React from 'react';
import { BaseIconProps } from '@/models/ui.model';

export const UTCStraightIconSettings: React.FC<BaseIconProps> = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_19740_211811)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.54064 9.89734C9.78472 10.8176 9.26483 11.7699 8.38927 12.0065L8.01823 12.1067C8.00618 12.2361 8 12.3673 8 12.5001C8 12.6598 8.00893 12.8173 8.02628 12.9719L8.3076 13.0422C9.2085 13.2675 9.74447 14.2481 9.47943 15.1862L9.37775 15.5461C9.58473 15.7191 9.80985 15.8673 10.0495 15.9869L10.311 15.694C10.9466 14.9822 12.0176 14.9828 12.6525 15.6953L12.924 16C13.1594 15.8852 13.381 15.7427 13.5854 15.5764L13.4598 15.1027C13.2157 14.1824 13.7356 13.2301 14.6111 12.9935L14.9818 12.8934C14.9938 12.7641 15 12.6329 15 12.5001C15 12.3403 14.9911 12.1827 14.9737 12.028L14.6928 11.9578C13.7919 11.7325 13.2559 10.7519 13.521 9.81377L13.6225 9.45433C13.4155 9.2813 13.1904 9.13297 12.9507 9.01337L12.6894 9.30598C12.0538 10.0178 10.9828 10.0172 10.3479 9.30468L10.0764 9C9.84105 9.11481 9.61942 9.25721 9.41494 9.42344L9.54064 9.89734ZM10.6952 12.5001C10.6952 12.9702 11.0555 13.3513 11.5 13.3513C11.9445 13.3513 12.3048 12.9702 12.3048 12.5001C12.3048 12.03 11.9445 11.6489 11.5 11.6489C11.0555 11.6489 10.6952 12.03 10.6952 12.5001Z"
          fill="#414042"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.54064 9.89734C9.78472 10.8176 9.26483 11.7699 8.38927 12.0065L8.01823 12.1067C8.00618 12.2361 8 12.3673 8 12.5001C8 12.6598 8.00893 12.8173 8.02628 12.9719L8.3076 13.0422C9.2085 13.2675 9.74447 14.2481 9.47943 15.1862L9.37775 15.5461C9.58473 15.7191 9.80985 15.8673 10.0495 15.9869L10.311 15.694C10.9466 14.9822 12.0176 14.9828 12.6525 15.6953L12.924 16C13.1594 15.8852 13.381 15.7427 13.5854 15.5764L13.4598 15.1027C13.2157 14.1824 13.7356 13.2301 14.6111 12.9935L14.9818 12.8934C14.9938 12.7641 15 12.6329 15 12.5001C15 12.3403 14.9911 12.1827 14.9737 12.028L14.6928 11.9578C13.7919 11.7325 13.2559 10.7519 13.521 9.81377L13.6225 9.45433C13.4155 9.2813 13.1904 9.13297 12.9507 9.01337L12.6894 9.30598C12.0538 10.0178 10.9828 10.0172 10.3479 9.30468L10.0764 9C9.84105 9.11481 9.61942 9.25721 9.41494 9.42344L9.54064 9.89734ZM10.6952 12.5001C10.6952 12.9702 11.0555 13.3513 11.5 13.3513C11.9445 13.3513 12.3048 12.9702 12.3048 12.5001C12.3048 12.03 11.9445 11.6489 11.5 11.6489C11.0555 11.6489 10.6952 12.03 10.6952 12.5001Z"
          fill="black"
          fillOpacity="0.2"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 0.492504C12.5 -0.106734 11.8655 -0.493244 11.333 -0.21835L3.93301 3.60207C3.66706 3.73937 3.5 4.01363 3.5 4.31292V14.5075C3.5 15.1067 4.13453 15.4932 4.667 15.2184L7.22183 13.8994C7.1156 13.5744 7.04537 13.2331 7.01584 12.8803L4.5 14.1792V4.43475L11.5 0.820841V8C11.8437 8 12.1784 8.03853 12.5 8.11151V0.492504Z"
          fill="#414042"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.5 0.492504C12.5 -0.106734 11.8655 -0.493244 11.333 -0.21835L3.93301 3.60207C3.66706 3.73937 3.5 4.01363 3.5 4.31292V14.5075C3.5 15.1067 4.13453 15.4932 4.667 15.2184L7.22183 13.8994C7.1156 13.5744 7.04537 13.2331 7.01584 12.8803L4.5 14.1792V4.43475L11.5 0.820841V8C11.8437 8 12.1784 8.03853 12.5 8.11151V0.492504Z"
          fill="black"
          fillOpacity="0.2"
        />
      </g>
      <defs>
        <clipPath id="clip0_19740_211811">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
