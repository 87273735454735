import React, { useMemo, useState } from 'react';
import { IntusInput } from '@/shared/elements';
import { InputProps } from 'antd/es/input';
import CloseIcon from '@/shared/icons/CloseIcon';
import { SearchIcon } from '@/shared/icons/SearchIcon';

const IntusSearch: React.FC<InputProps> = ({
  placeholder = 'Search',
  value,
  ...rest
}) => {
  const [inFocus, setInFocus] = useState(false);
  const filled = !!value;

  const color = useMemo(() => (inFocus ? '#64BC50' : '#000000'), [inFocus]);

  return (
    <IntusInput
      placeholder={placeholder}
      value={value}
      allowClear={{ clearIcon: <CloseIcon /> }}
      prefix={<SearchIcon fill={color} className={'transition duration-200'} />}
      onFocus={() => setInFocus(true)}
      onBlur={() => setInFocus(false)}
      style={{
        borderColor: filled ? '#000' : '',
      }}
      onKeyDown={(e) => e.stopPropagation()}
      {...rest}
    />
  );
};

export default IntusSearch;
