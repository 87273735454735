export enum QuoteStatus {
  DEFAULT = 'default',
  PROCESSING_EXPORT = 'processing_export',
  READY = 'ready',
  ERROR = 'error',
}

export interface QuoteRequestData {
  panelImages: {
    [panelId: string]: string;
  };
  takeoff: string;
  buildingImages: string[] | null;
  coverImage: string | null;
}
