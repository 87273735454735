import React, { useMemo } from 'react';
import { Line2 } from 'three/examples/jsm/lines/Line2';
import { useUnmount } from 'react-use';
import { Vector3 } from 'three';

import { convertMillimetersToMeters } from '@/shared/helpers/distance';
import { FlatVector3, GridLineData } from '@/models';
import { getCenterFromFlatVectorsArray } from '@/routes/dashboard/projects/project/project-canvas.helpers';
import { disposeNode } from '@/shared/helpers/canvas';

interface ViewerGridLineProps {
  scale: number;
  placementData: GridLineData;
  wallData: FlatVector3[];
  gridLine: Line2;
}

const ViewerGridLine: React.FC<ViewerGridLineProps> = ({
  scale,
  placementData,
  wallData,
  gridLine,
}) => {
  const line = useMemo(() => {
    const newLine = gridLine.clone();
    const leftCenter = getCenterFromFlatVectorsArray([
      wallData[1],
      wallData[2],
    ]);
    const rightCenter = getCenterFromFlatVectorsArray([
      wallData[3],
      wallData[0],
    ]);

    const direction = new Vector3()
      .subVectors(rightCenter, leftCenter)
      .normalize();

    newLine.translateOnAxis(
      direction,
      convertMillimetersToMeters(placementData.offsetFromLeftEdge) * scale
    );
    return newLine;
  }, [gridLine, placementData, wallData]);

  useUnmount(() => {
    disposeNode(line);
  });

  return <primitive object={line} />;
};

export default ViewerGridLine;
