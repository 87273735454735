import React from 'react';
import BuildingRenderItem from './RenderItem.tsx/RenderItem';

export interface RenderData {
  imageUrl: string;
  renderDateTime: string;
}

interface BuildingRendersProps {
  renderLinks: string[];
}

const BuildingRenders: React.FC<BuildingRendersProps> = ({ renderLinks }) => {
  const renders: RenderData[] = [
    { imageUrl: renderLinks[0], renderDateTime: '10 AM - Summer' },
    { imageUrl: renderLinks[1], renderDateTime: '10 PM - Summer' },
    { imageUrl: renderLinks[2], renderDateTime: '10 AM - Winter' },
    { imageUrl: renderLinks[3], renderDateTime: '10 PM - Winter' },
  ];
  return (
    <div
      className="p-6 grid grid-cols-2 gap-3 w-full"
      style={{
        backgroundColor: '#ECEEEE',
      }}
    >
      {renders?.map((render, idx) => {
        return <BuildingRenderItem key={idx} renderData={render} />;
      })}
    </div>
  );
};

export default BuildingRenders;
